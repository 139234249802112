import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";

export default function Arrow(props) {
  const [arrowValue, setArrowValue] = useState("up");

  const setArrowValueFunc = (title) => {
    if (arrowValue === "down") {
      setArrowValue("up");
    } else if (arrowValue === "up") {
      setArrowValue("down");
    }
    props?.handleArrowClick({ position: arrowValue, title: title });
  };

  return (
    <>
      {props?.single ? (
        <div className={"outline  bold-text"} style={{ paddingTop: 13 , justifyContent: props?.justify}}>
          {props?.data}
        </div>
      ) : (
        <div className="outline text-align" style={{ cursor: "pointer", justifyContent: props?.justify }} onClick={() => setArrowValueFunc(props?.data)}>
          <div className="bold-text">{props?.data}</div>
          <div className="flex-center">{arrowValue === "up" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</div>
        </div>
      )}
    </>
  );
}
