import * as React from "react";
import { Grid } from "@mui/material";
import "./style.scss";
import SortIcon from "@mui/icons-material/Sort";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ticketList } from "@reducers/api";
import Stack from "@mui/material/Stack";

const validation = Yup.object({
  primary: Yup.object().required("Required"),
});

export default function TicketFilter(props) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });
  const onSubmit = (data) => {
    props?.handleFormValues(data);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValues] = React.useState(null);
  const [value1, setValue1] = React.useState(null);
  const [priority, setPriority] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [thirdVal, setthirdVal] = React.useState(null);
  const [fourVal, setfourVal] = React.useState(null);

  const dispatch = useDispatch();

  const handleClose = () => {
    setAnchorEl(null);
    reset();
    setValues(null)
    setValue1(null)
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSearcch = (search) => {

    props?.handleFormValues(value, value1, priority, status, thirdVal, fourVal)
    setAnchorEl(null);
  };

  const handleResetForm = (search) => {
    props?.handleResetClick()
    setAnchorEl(null);
    reset();
    setValues("")
    setValue1("")
    setPriority("")
    setStatus("")
    setthirdVal("")
    setfourVal("")
  };

  const open = Boolean(anchorEl);

  return (
    <div className="">

      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={4} md={1}>
          <Button
            id="demo-customized-button"
            aria-controls="demo-customized-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
          //endIcon={<KeyboardArrowDownIcon />}
          >
            {" "}
            <SortIcon className="sortcolor" />
            Filters
          </Button>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        {
          props?.isAddOn ? (
            <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            className="searchbyfilter"
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            Search by Filter
  
            <Grid item xs={12} className="filterHeight" style={{ padding: '15px' }}>
              <label htmlFor="lastName" className="createForm">
                {props?.labelPrimary}
              </label>
              <Controller
                control={control}
                name="primary"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                      setPriority(item)
                    }}
                    value={value || ""}
                    options={props?.PrimaryOption}
                    isOptionEqualToValue={(option) => option.label== value?.label  || ""}
                    getOptionLabel={(item) => (item.label ? item.label : "")}
                    getOptionSelected={(option, value) =>
                      value === undefined ||
                      value === "" ||
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Option"
                        size="small"
                      />
                    )}
                  />
                )}
              />
              <div className="field-error">{errors.primary?.message}</div>
            </Grid>

            {
              props?.thirdOption && (
                <Grid item xs={12} className="filterHeight" style={{ padding: '15px' }}>
              <label htmlFor="lastName" className="createForm">
                {props?.thirdOption }
              </label>
              <Controller
                control={control}
                name="third"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                      setthirdVal(item)
                    }}
                    value={value || ""}
                    options={props?.thirdList}
                    isOptionEqualToValue={(option) => option.label== value?.label  || ""}
                    getOptionLabel={(item) => (item.label ? item.label : "")}
                    getOptionSelected={(option, value) =>
                      value === undefined ||
                      value === "" ||
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Option"
                        size="small"
                      />
                    )}
                  />
                )}
              />
              <div className="field-error">{errors.primary?.message}</div>
            </Grid>
              )  
            }
         
  
            <Grid item xs={12} className=" txtright">
              <Stack direction="row" className="flex-end " spacing={2}>
              <Button
                type="button"
                variant="contained"
                className="bg-blue"
                style={{ textTransform: "none" }}
                size="small"
                onClick={handleClickSearcch}
              >
                Search
              </Button>
              <Button
                type="button"
                variant="contained"
                className="bg-blue"
                style={{ textTransform: "none" }}
                size="small"
                onClick={handleResetForm}
              >
                Reset
              </Button>
              </Stack>
            </Grid>
      
          </Menu>
          )
          :
          (
            <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            className="searchbyfilter"
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            Search by date
  
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="filterStyle" style={{ padding: '10px' }}>
  
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField
                          variant="standard"
                          className="filterHeightDate"
                          {...props}
                        />
                      )}
                      label="From"
                      value={value}
                      name="fromdate"
                      onChange={(newValue) => {
                        setValues(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField
                          variant="standard"
                          className="filterHeightDate"
                          {...props}
                        />
                      )}
                      label="To"
                      value={value1}
                      name="todate"
                      onChange={(newValue) => {
                        setValue1(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
  
              </Grid>
            </Grid>
            <Grid item xs={12} className="filterHeight" style={{ padding: '15px' }}>
              <label htmlFor="lastName" className="createForm">
                {props?.labelPrimary}
              </label>
              <Controller
                control={control}
                name="primary"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                      setPriority(item)
                    }}
                    value={value || ""}
                    options={props?.PrimaryOption}
                    isOptionEqualToValue={(option) => option.label== value?.label  || ""}
                    getOptionLabel={(item) => (item.label ? item.label : "")}
                    getOptionSelected={(option, value) =>
                      value === undefined ||
                      value === "" ||
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Option"
                        size="small"
                      />
                    )}
                  />
                )}
              />
              <div className="field-error">{errors.primary?.message}</div>
            </Grid>
            {
              props?.thirdOption && (
                <Grid item xs={12} className="filterHeight" style={{ padding: '15px' }}>
              <label htmlFor="lastName" className="createForm">
                {props?.thirdOption }
              </label>
              <Controller
                control={control}
                name="third"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                      setthirdVal(item)
                    }}
                    value={value || ""}
                    options={props?.thirdList}
                    isOptionEqualToValue={(option) => option.label== value?.label  || ""}
                    getOptionLabel={(item) => (item.label ? item.label : "")}
                    getOptionSelected={(option, value) =>
                      value === undefined ||
                      value === "" ||
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Option"
                        size="small"
                      />
                    )}
                  />
                )}
              />
              <div className="field-error">{errors.primary?.message}</div>
            </Grid>
              )  
            }
             {
              props?.fourthOption && (
                <Grid item xs={12} className="filterHeight" style={{ padding: '15px' }}>
              <label htmlFor="lastName" className="createForm">
                {props?.fourthOption }
              </label>
              <Controller
                control={control}
                name="four"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                      setfourVal(item)
                    }}
                    value={value || ""}
                    options={props?.fourthList}
                    isOptionEqualToValue={(option) => option.label== value?.label  || ""}
                    getOptionLabel={(item) => (item.label ? item.label : "")}
                    getOptionSelected={(option, value) =>
                      value === undefined ||
                      value === "" ||
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Option"
                        size="small"
                      />
                    )}
                  />
                )}
              />
           
            </Grid>
              )  
            }
            {
              props?.SecondaryOption && (
                <Grid item xs={12} className="filterHeight" style={{ padding: '15px' }}>
                  <label htmlFor="lastName" className="createForm">
                    {props?.labelSecondary}
                  </label>
                  <Controller
                    control={control}
                    name="secondary"
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, item) => {
                          onChange(item);
                          setStatus(item)
                        }}
                        value={value || ""}
                        options={props?.SecondaryOption}
                        isOptionEqualToValue={(option) => option?.label== value?.label  || ""}
                        getOptionLabel={(item) => (item?.label || item ? item?.label || item : "")}
                        getOptionSelected={(option, value) =>
                          value === undefined ||
                          value === "" ||
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    )}
                  />
                </Grid>
              )
              
            }
  
            <Grid item xs={12} className=" txtright">
             

              <Stack direction="row" className="flex-end btns-stack" spacing={2}>
              <Button
                type="button"
                variant="contained"
                className="bg-blue"
                style={{ textTransform: "none" }}
                size="small"
                onClick={handleClickSearcch}
              >
                Search
              </Button>
              <Button
                type="button"
                variant="contained"
                className="bg-blue"
                style={{ textTransform: "none" }}
                size="small"
                onClick={handleResetForm}
              >
                Reset
              </Button>
              </Stack>
            </Grid>
           
          </Menu>
          )
        }
       
      </form>
    </div>
  );
}
