import * as React from "react";
import { Grid } from "@mui/material";
import "./style.scss";
import SortIcon from "@mui/icons-material/Sort";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ticketList } from "@reducers/api";
import Stack from "@mui/material/Stack";
import { subscriberList } from "@reducers/api";
import moment from "moment";


const validation = Yup.object({
  primary: Yup.object().required("Required"),
});

export default function TicketFilter(props) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });
  const onSubmit = () => {
    props?.handleFormValues(value, value1, status, validity, serviceType, billingType, billgen, paymentValidity, area, subArea);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [status, setStatus] = React.useState(null);
  const [validity, setValidity] = React.useState(null);
  const [serviceType, setServiceType] = React.useState(null);
  const [billingType, setBillingType] = React.useState(null);
  const [value, setValues] = React.useState(null);
  const [value1, setValue1] = React.useState(null);
  const [billgen, setBillgen] = React.useState(null);
  const [paymentValidity, setPaymentValidity] = React.useState(null);
  const [area, setArea] = React.useState(null);
  const [subArea, setSubArea] = React.useState(null);
  const dispatch = useDispatch();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSearcch = (search) => {
    props?.handleFormValues(value, value1, status, validity, serviceType, billingType, billgen, paymentValidity, area, subArea);
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  const handleResetForm = (search) => {
    props?.handleResetClick()
    setAnchorEl(null);
    reset();
    setStatus("")
    setValidity("")
    setServiceType("")
    setBillingType("")
    setValues("")
    setValue1("")
    setBillgen("")
    setPaymentValidity("")
    setArea("")
    setSubArea("")
  };

  return (
    <div className="">

      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={4} md={1}>
          <Button
            id="demo-customized-button"
            aria-controls="demo-customized-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
          //endIcon={<KeyboardArrowDownIcon />}
          >
            {" "}
            <SortIcon className="sortcolor" />
            Filters
          </Button>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          className="searchbyfilter"
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div>Search by filter</div>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  renderInput={(props) => (
                    <TextField
                      variant="standard"
                      className="filterHeightDate"
                      {...props}
                    />
                  )}
                  label="From"
                  value={value}
                  name="fromdate"
                  onChange={(newValue) => {
                    setValues(newValue);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  renderInput={(props) => (
                    <TextField
                      variant="standard"
                      className="filterHeightDate"
                      {...props}
                    />
                  )}
                  label="To"
                  value={value1}
                  name="todate"
                  onChange={(newValue) => {
                    setValue1(newValue);
                  }}
                />
              </LocalizationProvider>
            </Grid>

          </Grid>

          <Grid item xs={12} className="filterHeight p-10">
            <label htmlFor="lastName" className="createForm">
              {props?.labelPrimary}
            </label>
            <Controller
              control={control}
              name="primary"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                    setStatus(item)
                  }}
                  value={value || ""}
                  options={props?.PrimaryOption}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Option"
                      size="small"
                    />
                  )}
                />
              )}
            />
            <div className="field-error">{errors.primary?.message}</div>
          </Grid>





          <Grid item xs={12} className="filterHeight p-10">
            <label htmlFor="lastName" className="createForm">
              {props?.billingType}
            </label>
            <Controller
              control={control}
              name="billingtype"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                    setBillingType(item)
                  }}
                  value={value || ""}
                  options={props?.billingTypeOption}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Option"
                      size="small"
                    />
                  )}
                />
              )}
            />
            <div className="field-error">{errors.primary?.message}</div>
          </Grid>


          <Grid item xs={12} className="filterHeight p-10">
            <label htmlFor="lastName" className="createForm">
              {props?.serviceType}
            </label>
            <Controller
              control={control}
              name="servicetype"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                    setServiceType(item)
                  }}
                  value={value || ""}
                  options={props?.serviceTypeOption}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Option"
                      size="small"
                    />
                  )}
                />
              )}
            />
            <div className="field-error">{errors.primary?.message}</div>
          </Grid>

          <Grid item xs={12} className="filterHeight p-10">
            <label htmlFor="lastName" className="createForm">
              {props?.billgen}
            </label>
            <Controller
              control={control}
              name="billinggen"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                    setBillgen(item)
                  }}
                  value={value || ""}
                  options={props?.billgenOption}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Option"
                      size="small"
                    />
                  )}
                />
              )}
            />
            <div className="field-error">{errors.primary?.message}</div>
          </Grid>

          <Grid item xs={12} className="filterHeight p-10">
            <label htmlFor="lastName" className="createForm">
              {props?.paymentValidity}
            </label>
            <Controller
              control={control}
              name="paymentValidity"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                    setPaymentValidity(item)
                  }}
                  value={value || ""}
                  options={props?.payValidOption}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Option"
                      size="small"
                    />
                  )}
                />
              )}
            />
            <div className="field-error">{errors.primary?.message}</div>
          </Grid>

          <Grid item xs={12} className="filterHeight p-10">
            <label htmlFor="lastName" className="createForm">
              {props?.area}
            </label>
            <Controller
              control={control}
              name="area"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                    setArea(item)
                  }}
                  value={value || ""}
                  options={props?.areaData?.area || []}
                  isOptionEqualToValue={(option, value) => option.label === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Option"
                      size="small"
                    />
                  )}
                />
              )}
            />
            <div className="field-error">{errors.primary?.message}</div>
          </Grid>
          <Grid item xs={12} className="filterHeight p-10">
            <label htmlFor="lastName" className="createForm">
              {props?.subArea}
            </label>
            <Controller
              control={control}
              name="subArea"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                    setSubArea(item)
                  }}
                  value={value || ""}
                  options={props?.areaData?.subarea || []}
                  isOptionEqualToValue={(option, value) => option.label === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Option"
                      size="small"
                    />
                  )}
                />
              )}
            />
            <div className="field-error">{errors.primary?.message}</div>
          </Grid>



          <Grid item xs={12} className=" txtright p-10">


            <Stack direction="row" className="flex-end btns-stack" spacing={2}>
              <Button
                type="button"
                variant="contained"
                className="bg-blue"
                style={{ textTransform: "none" }}
                size="small"
                onClick={handleClickSearcch}
              >
                Search
              </Button>
              <Button
                type="button"
                variant="contained"
                className="bg-blue"
                style={{ textTransform: "none" }}
                size="small"
                onClick={handleResetForm}
              >
                Reset
              </Button>
            </Stack>
          </Grid>

          {/* <Grid container className="padding-btw-tiles flex-end">
              <Stack direction="row" className="flex-end btns-stack" spacing={2}>
                <Button size="small" variant="outlined" className="btn-outlined" onClick={handleClose} style={{ textTransform: "none", color: "#00249b" }}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" className="bg-blue" style={{ textTransform: "none" }} size="small">
                  Search
                </Button>
              </Stack>
            </Grid> */}
        </Menu>
      </form>
    </div>
  );
}
