import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  plugins: {
    legend: {
      maxHeight: 15,
      display: false,
      position: 'right',
      onClick: null
    },
    datalabels: {
      clamp: true,
      display: false,
      color: "black",
      align: "end",
      anchor: "end",
    },
  },
  barThickness: 55,
  scales: {
    x: {
      grid: { display: false, drawBorder: false },
    },
    y: {
      display: false,
    },
  },
};

export default function PaymentCollectionBar(props) {
  let chartData = props?.data?.bills?.filter((data) => !data.billing_status);
  const navigate = useNavigate();

  return (
    <Bar
    
      height={240}
      options={options}
     
      getElementAtEvent={(elements, event) => {
       if(props?.serviceType) {
        if (event.type === "click") {
          if(elements[0]?.index==1)
          {
            navigate(`/distributor/paid-list?dateFilter=1&serviceType=${props?.serviceType}&billingType=${props?.billingType}`);
          }
          if(elements[0]?.index==0)
          {
            navigate(`/distributor/paid-list?dateFilter=2&serviceType=${props?.serviceType}&billingType=${props?.billingType}`);
          }
         
        }
       }
      
      }}
      data={{
        labels: [`Today's Collection -  ${props?.data?.bills[0]?.total_coll_amount || 0}`, `Yesterday's Collection -  ${props?.yesterdayData?.bills && props?.yesterdayData?.bills[0]?.total_coll_amount || 0}`],
        datasets: [
          {
            label: "",
            data: [(props?.data?.bills && props?.data?.bills[0]?.total_coll_amount) || 0, (props?.yesterdayData?.bills && props?.yesterdayData?.bills[0]?.total_coll_amount) || 0],
            backgroundColor: ["#10B6FF", "#00249B"], cursor: "pointer"
          },
         
        ],

        
      }}
/>
  );
}
