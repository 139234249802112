import { createSlice } from "@reduxjs/toolkit";
import { expensesAdd, expenseList, deleteExpense,getExpenses, expensesUpdate} from "./api";


const initialState = {
  expensesAddData: [],
  expenseListData: [],
  expenseDeleted: false,
  getExpensesData:{}
};

export const expensesSlice = createSlice({
  name: "expenses",
  initialState,
  reducers: {
    reset: (state) => {
      state.expenseDeleted = false;
      return state;
    },
  },


  extraReducers: {

    [expensesAdd.pending]: (state) => { },
    [expensesAdd.fulfilled]: (state, { payload }) => {
      state.expensesAddData = payload.data.data;
    },
    [expensesAdd.rejected]: (state, { payload }) => { },

    [expensesUpdate.pending]: (state) => { },
    [expensesUpdate.fulfilled]: (state, { payload }) => {
      state.expensesAddData = payload.data.data;
    },
    [expensesUpdate.rejected]: (state, { payload }) => { },

    [expenseList.pending]: (state) => {},
    [expenseList.fulfilled]: (state, { payload }) => {
      state.expenseListData = payload.data.data;
    },
    [expenseList.rejected]: (state, { payload }) => {},
    
    [getExpenses.pending]: (state) => {},
    [getExpenses.fulfilled]: (state, { payload }) => {
      state.getExpensesData = payload.data.data;
    },
    [getExpenses.rejected]: (state, { payload }) => {},

    [deleteExpense.pending]: (state) => {},
    [deleteExpense.fulfilled]: (state, { payload }) => {
      state.expenseDeleted = payload.data.status;
    },
    [deleteExpense.rejected]: (state, { payload }) => {},
  }

});

export default expensesSlice.reducer;