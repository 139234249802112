import "./styles.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchWithButtons from "@components/SearchWithButtons";
import { useNavigate } from "react-router-dom";
import { useComponentDidMount, useComponentDidUpdate } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { expenseList, deleteExpense, getExpenses } from "@reducers/api";
import { useState } from "react";
import Pagination from "@mui/material/Pagination";
import { useSnackbar } from "notistack";
import moment from "moment";
import TicketFilter from "@components/TicketFilter";
import Grid from "@mui/material/Grid";
import TicketStatus from "@components/TicketStatus";
import BreadCrumb from "@components/Breadcrumb";

const ticketStatusData = [
  {
    id: 1,
    title: "Transport",
    count: 30,
    icon: "",
  },
  {
    id: 2,
    title: "Advance",
    count: 28,
    icon: "",
  },
  {
    id: 3,
    title: "Splicing",
    count: 13,
    icon: "",
  },
  {
    id: 4,
    title: "Others",
    count: 8,
    icon: "",
  },
  {
    id: 5,
    title: "Total Expense",
    count: 8,
    icon: "",
  },
];


export default function EmployeeList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { expenseListData, expenseDeleted, getExpensesData } = useSelector((state) => state.expenses);

  const [expList, setexpList] = useState([]);
  const [expenseListExport, setExpenseListExport] = useState([]);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [dateFrom, setDateFromQuery] = useState("");
  const [dateTo, setDateToQuery] = useState("");
  const [filterOne, setFirstQuery] = useState("");

  let user = JSON.parse(localStorage.getItem("user"));

  const queryGenerator = (value) => {
    let users = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${users?.distributor_ref_id}&limit=10&offset=0`;
    if (value?.type === "search") {
      query += `&limit=10&offset=0&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };


  const handleRedirect = () => {
    navigate("/distributor/create-expense");
  };



  useComponentDidMount(() => {
    let countquery = `distributor_ref_id=${user?.distributor_ref_id}`;

    let query = queryGenerator();
    dispatch(getExpenses(countquery));
    dispatch(expenseList(query));
  }, []);

  useComponentDidUpdate(() => {
    if (expenseDeleted) {
      enqueueSnackbar("Expense Deleted !", {
        variant: "success",
      });
      let query = queryGenerator();
      dispatch(expenseList(query));
      dispatch(getExpenses(`distributor_ref_id=${user?.distributor_ref_id}`))
    }
  }, [expenseDeleted]);

  useComponentDidUpdate(() => {
    let list = [];
    let listForExport = [];
    if (expenseListData?.data?.length > 0) {
      expenseListData?.data?.map((data, index) => {
        list.push({
          sNo: (page - 1) * 10 + index + 1,
          employeeId: data?.staff_email,
          employeeName: data?.staff_name,
          accountId: data?.expense_name,
          email: data?.expense_amount,
          type : data?.expense_type,
          role: data?.description,
          status: moment(data?.created_at).format("MMMM Do YYYY, h:mm a"),
          action: (
            <div className="flex-row justify-center">
              <div onClick={() => navigate(`/distributor/create-expense?id=${data._id}`)} className="cursor-pointer">
                <EditIcon />
              </div>
              <div
                className="cursor-pointer"
                onClick={() =>
                  dispatch(
                    deleteExpense({
                      _id: data?._id,
                    })
                  )
                }
              >
                <DeleteIcon />
              </div>
            </div>
          ),
        });
        listForExport.push({
          sNo: (page - 1) * 10 + index + 1,
          "Employee Email": data?.staff_email,
          "Employee Name": data?.staff_name,
          "Expense Name": data?.expense_name,
          "Expense Type": data?.expense_type,
          "Expense Amount": data?.expense_amount,
          "Description": data?.description,
          "Created Date": moment(data?.created_at).format("ll"),

        });
      });
    }



    setexpList(list);
    setExpenseListExport(listForExport);
  }, [expenseListData]);

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    dispatch(expenseList(`distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&value=${search}`));
  };

  const handleChange = (value) => {
    let users = JSON.parse(localStorage.getItem("user"));
    dispatch(expenseList(`distributor_ref_id=${users?.distributor_ref_id}&limit=10&offset=${value - 1}0${searchQuery ? `&value=${searchQuery}` : ""}${dateFrom? `&startDate=${moment(dateFrom).format("YYYY-MM-DDTHH:mm:ss")}`: ""}${ dateTo ? `&endDate=${moment(dateTo).format("YYYY-MM-DDTHH:mm:ss")}` : ""}${filterOne? `&expense_type=${filterOne}`: ""}`));

  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  const handleClickSearcch = (value, value1, priorityval) => {
    let users = JSON.parse(localStorage.getItem("user"));
    setPage(1);
    let query = `distributor_ref_id=${users?.distributor_ref_id}&limit=10&offset=0`;
    if (value) {
      query += `&startDate=${moment(value).format("YYYY-MM-DDTHH:mm:ss")}`;
    }
    if (value1) {
      query += `&endDate=${moment(value1).format("YYYY-MM-DDTHH:mm:ss")}`;
    }
    if (priorityval) {
      query += `&expense_type=${priorityval?.value}`;
    }
   
    dispatch(expenseList(`${query}`));
    dispatch(getExpenses(`${query}`));
    setDateFromQuery(value);
    setDateToQuery(value1);
    setFirstQuery(priorityval?.value);
  };

  const handleReset = () => {
    let users = JSON.parse(localStorage.getItem("user"));
    let query = queryGenerator();
    dispatch(expenseList(query));
    dispatch(getExpenses(`distributor_ref_id=${users?.distributor_ref_id}`))
    setDateFromQuery("");
    setDateToQuery("");
    setFirstQuery("");
    setPage(1);
  };

  const type = [
    {
      label: "TRANSPORT",
      value: "transport",
    },
    {
      label: "ADVANCE",
      value: "advance",
    },
    {
      label: "SPLICING",
      value: "splicing",
    },
    {
      label: "OTHERS",
      value: "others",
    },
  ];

  return (
    <div className="employeeList-container ticketList-container">


      <Grid container spacing={2}>
        <Grid item xs={6} md={8}>
          <div className="employeelist-title">Expense</div>
        </Grid>
        <Grid item xs={6} md={4} className="flex-end">
          <div className="employeelist-title">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}

              activeHeading="Expense List"
              handleActiveClick={() => handleBreadCrumbRedirect("expenses-list")}
            />
          </div>

        </Grid>
      </Grid>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <TicketStatus className=" ticketCardBlue" title={ticketStatusData[0].title} count={getExpensesData?.transport || "0"} icon={ticketStatusData[0].icon} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <TicketStatus className=" ticketCardGreen" title={ticketStatusData[1].title} count={getExpensesData?.advance || "0"} icon={ticketStatusData[1].icon} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <TicketStatus className=" ticketCardRed" title={ticketStatusData[2].title} count={getExpensesData?.splicing || "0"} icon={ticketStatusData[2].icon} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <TicketStatus className=" ticketCardPink" title={ticketStatusData[3].title} count={getExpensesData?.others || "0"} icon={ticketStatusData[3].icon} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <TicketStatus className=" ticketCardPurple" title={ticketStatusData[4].title} count={getExpensesData?.totalExpenseAmount || "0"} icon={ticketStatusData[4].icon} />
          </Grid>
        </Grid>
      </div>
      
      <div className="ticket-list-table-cntr">
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={4} md={3}>
          <div className="planHeading">Expense List</div>
        </Grid>
        <Grid item xs={0} sm={4} md={6}></Grid>
        <Grid item xs={4} sm={4} md={3} className="flex-end">
          <div className="">
            <TicketFilter
              labelPrimary={"Expense Type"}
              PrimaryOption={type}
              handleFormValues={(data1, data2, data3) =>
                handleClickSearcch(data1, data2, data3)
              }
              handleResetClick={handleReset}
            />
          </div>
        </Grid>
      </Grid>
        <div className="search-btns-padding">
          <SearchWithButtons
            primaryButton={{
              title: "Add Expense",
              type: "contained",
              color: "white",
              bgColor: COLORS.primary,
            }}
            secondaryButton={{
              title: "Export",
              type: "outlined",
              color: COLORS.primary,
              filename: "Expense list",
              data: expenseListExport
            }}
            handlePrimaryClick={handleRedirect}
            // handleSecondaryClick={}
            handleChange={handleSearch}
          />
        </div>
        <div style={{ fontWeight: 'bold', fontSize: '15px', paddingBottom: '10px' }}>Showing entries {(page - 1) * 10 + 1} - {Math.ceil(expenseListData?.totalCount / 10) == page ? expenseListData?.totalCount : page + "0"} of {expenseListData?.totalCount}</div>
        <TableView rows={tableRows} data={expList} color={COLORS.primary} />
        <div style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}>
          <Pagination
            count={Math.ceil(expenseListData?.totalCount / 10) || 0}
            defaultPage={1}
            page={page}
            onChange={(event, value) => {
              setPage(value);
              handleChange(value);
            }}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
}
