const addOnserviceTable = [
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },
  {
    title: "Inventory name",
    key: "serviceType",
    align: "left",
  },
  {
    title: "STB no",
    key: "stb_no",
    align: "left",
  },
  {
    title: "Amount",
    key: "serviceAmount",
    align: "left",
  },
  {
    title: "Date",
    key: "date",
    align: "left",
  },
  {
    title: "Status",
    key: "status",
    align: "left",
  },
  {
    title: "Action",
    key: "action",
    align: "left",
  },
];

const addOnTvTable = [
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },
  {
    title: "STB No",
    key: "stbNo",
    align: "left",
  },
  {
    title: "Type",
    key: "type",
    align: "left",
  },
  {
    title: "Action",
    key: "action",
    align: "left",
  },
];

export { addOnserviceTable, addOnTvTable };
