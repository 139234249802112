import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import DownloadIcon from "@mui/icons-material/Download";
import { Grid } from "@mui/material";
import "./styles.scss";

const SubTile = React.memo(function SubTileComp(props) {
  console.log(props?.data?.countTwo, "twooo")
  return (
    <div className="sub-plan-ctr">
      <div className="flex-row">
        <PersonIcon style={{ color: props?.color }} sx={{ fontSize: 50 }} />
        <Grid container>
          <Grid item xs={8} md={11} className="fnt-size-14">
            {props?.data?.countOne?.length || 0} {props?.data?.titleOne || 0}
          </Grid>

          {
            props?.paymentDownload && (
              <Grid item xs={4} md={1} className="flex-end downloadTile">
                <DownloadIcon className="downloadPayment" sx={{ fontSize: 20 }} onClick={() => {
                  props?.paymentDownload(props?.data?.titleOne);
                }} />
              </Grid>
            )
          }


          <Grid item xs={8} md={11} className="fnt-size-14">
            { props?.data?.countTwo?.count ||  0 } {props?.data?.titleTwo}
          </Grid>
          {
            props?.paymentDownload && (
              <Grid item md={1} xs={4} className="flex-end downloadTile">
                <DownloadIcon className="downloadPayment" onClick={() => {
                  props?.paymentDownload(props?.data?.titleTwo);
                }} sx={{ fontSize: 20 }} />
              </Grid>
            )
          }

        </Grid>
      </div>
    </div>
  );
});

export default SubTile;
