
import "./style.scss";
import Grid from "@mui/material/Grid";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import SearchWithButtons from "@components/SearchWithButtons";
import { useNavigate } from "react-router-dom";
import { useComponentDidMount, useComponentDidUpdate } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { HardwareList } from "@reducers/api";
import { useState } from "react";
import Pagination from "@mui/material/Pagination";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BreadCrumb from "@components/Breadcrumb";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function Ticketing(props) {

  const [open, setOpen] = useState(false);
  const [popuplist, setpopuplist] = useState([]);

  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hardwareData } = useSelector((state) => state.inventory);
  const [enqList, setEnqList] = useState([]);
  const [hardwareList, setHardList] = useState([]);
  const [hardwareListExport, setHardwareListExport] = useState([]);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const defaultPlanQuery = {
    serviceType: "cable",
  };

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}`;
    if (value?.type === "search") {
      query += `&limit=10&offset=0&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
    let query = queryGenerator();
    dispatch(HardwareList(query));
  }, []);

  const amountdetail = (data) => {
    let totalChild = data.reduce((acc, curr) => acc + curr.plan_amount, 0); // 6
    return (
      <div >
        {totalChild}
      </div>
    );
  };


  const hardwareCount = (data) => {
    return (
      <div >
        {data?.length}
      </div>

    );
  };

  useComponentDidUpdate(() => {
    let list = [];
    let listForExport=[];
    if (hardwareData?.data?.length > 0) {
      hardwareData?.data.map((data, index) => {
        list.push({
          sno: (page - 1) * 10 + index + 1,
          subsName: data?.subscriberName,
          email: data?.email_id,
          servicetype: data?.service_type,
          hardwareList:  <div >
                <Button  onClick={() => {
                  setOpen(true);
                  setpopuplist(data?.inventoryDetails);
                }}
                >Hardware List</Button>
              </div>,
          billingType: data?.billing_type,
          planamt: amountdetail(data?.inventoryDetails),
          noHardware: hardwareCount(data?.inventoryDetails),

        });
        listForExport.push({
          "Si No": (page - 1) * 10 + index + 1,
          "Subscriber Name": data?.subscriberName,
          "Email": data?.email_id,
          "Service Type": data?.service_type,
          "Billing Type": data?.billing_type,
          "No of hardware List": data?.inventoryDetails?.length,
     
        });
      });
    }

    setEnqList(list);
    setHardwareListExport(listForExport);
  }, [hardwareData]);


  useComponentDidUpdate(() => {
    let list = [];

    if (hardwareData?.data?.length > 0) {
      hardwareData?.data.map((data, index) => {
        data?.inventoryDetails.map((data, index) => {
          list.push({
            custName: data?.cust_name,
            custEmail: data?.cust_email_id,
            type: data?.type,
            amt: data?.plan_amount,
            packCat: data?.package_category,
            billingType: data?.billing_type
          });
        });
      });
    }
    setHardList(list);
  }, [hardwareData]);


  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    let query = queryGenerator({ type: "search", state: search });
    dispatch(HardwareList(query));
  };

  const handleChange = (value) => {
    let query = queryGenerator({ type: "offset", state: value });
    dispatch(HardwareList(query));
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  return (
    <div>
      <div className="HardwareList-container">
        
        
        <Grid container spacing={2}>
        <Grid item xs={8}>
        <div className="planHeading">Hardware List</div>
        </Grid>
        <Grid item xs={12} md={4} className="flex-end">
          <div className="employeelist-title">
          <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              secondaryHeading="Inventory List"
              handleSecondaryClick={() => handleBreadCrumbRedirect("inventery-list")}
              activeHeading="Hardware List"
              handleActiveClick={() => handleBreadCrumbRedirect("hardware-list")}
            />
          </div>

        </Grid>
      </Grid>

        <div className="ticket-list-table-cntr">

          <div className="search-btns-padding">
            <SearchWithButtons
              secondryExtraButton = {{
                title: "Export",
                type: "outlined",
                color: COLORS.primary,
                filename:"Hardware list",
                data: hardwareListExport,
              }}  

              handleChange={handleSearch}
            />
          </div>
          <div style={{ fontWeight: 'bold', fontSize: '15px',paddingBottom: '10px'}}>Showing entries { (page - 1) * 10 + 1 } - {Math.ceil(hardwareData?.totalCount / 10)===page?hardwareData?.totalCount:page+"0"} of {hardwareData?.totalCount}</div>  
          <TableView rows={tableRows} data={enqList} color={COLORS.primary} />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className='hardwareTable'>
              <TableContainer component={Paper}>
                <Table  aria-label="simple table">
                  <TableHead>
                    <TableRow>

                      <TableCell align="right">Customer Name</TableCell>
                      <TableCell align="right">Customer Email</TableCell>
                      <TableCell align="right">Type</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell align="right">Package Category</TableCell>
                      <TableCell align="right">Billing Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {popuplist.map((row) => (
                      <TableRow
                        key={row?.custName}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row?.cust_name}
                        </TableCell>
                        <TableCell align="right">{row?.cust_email_id}</TableCell>
                        <TableCell align="right">{row?.type}</TableCell>
                        <TableCell align="right">{row?.plan_amount}</TableCell>
                        <TableCell align="right">{row?.package_category}</TableCell>
                        <TableCell align="right">{row?.billing_type}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Modal>
          <div style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}>
            <Pagination
              count={Math.ceil(hardwareData?.totalCount / 10) || 0}
              defaultPage={1}
              page={page}
              onChange={(event, value) => {
                setPage(value);
                handleChange(value);
              }}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
