import { useEffect } from "react";
import PermanentDrawerLeft from "@components/sidebar/index";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector, useDispatch } from "react-redux";
import { showSideBar } from "@reducers/settings";
import Topbar from "@components/topbar/index";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptIcon from "@mui/icons-material/Receipt";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import InventoryIcon from "@mui/icons-material/Inventory";
import PaymentsIcon from '@mui/icons-material/Payments';
import "./styles.scss";

const arrayOfObjects = [
  { title: "Dashboard",value:"dashboard", icon: <ViewCompactIcon />, path: "/distributor/dashboard", role: "distributor" },
  { title: "Employee",value:"employee", icon: <PersonIcon />, path: "/distributor/employee-list" },
  {
    title: "CRM",
    value:"crm",
    icon: <GroupsIcon />,
    path: "",
    subitem: [
      {
        title: "Subscriber",
        icon: <PersonIcon />,
        path: "/distributor/subscriber-list",
      },
      {
        title: "Subscriber Plan",
        icon: <PersonIcon />,
        path: "/distributor/subscriber-plan",
      },     
      {
        title: "Assign Subscriber",
        icon: <PersonIcon />,
        path: "/distributor/assign-subscriber-list",
      },
      // {
      //   title: "Ready To Pay",
      //   icon: <PersonIcon />,
      //   path: "/distributor/ready-to-pay-list",
      // },
    ],
  },
  { title: "Ticketing",value:"ticketing", icon: <ConfirmationNumberIcon />, path: "/distributor/ticket-list" },
  { title: "Enquiry", value:"enquiry",icon: <DescriptionIcon />, path: "/distributor/enquiry-list" },
  { title: "Expenses",value:"expenses", icon: <FindInPageIcon />, path: "/distributor/expenses-list" },
  { title: "Notification",value:"notification", icon: <NotificationsOutlinedIcon />, path: "/distributor/notification" },
  {
    title: "Billing",
    value:"billing",
    icon: <ReceiptIcon />,
    path: "",
    subitem: [
      {
        title: "Collection list",
        icon: <PersonIcon />,
        path: "/distributor/collection-list",
      },
      {
        title: "Receipt list",
        icon: <PersonIcon />,
        path: "/distributor/receipt-list",
      },
      {
        title: "Outstanding list",
        icon: <PaymentsIcon />,
        path: "/distributor/outstanding-list",
      },
    ],
  },
  { title: "Inventory",value:"inventory", icon: <InventoryIcon />, path: "/distributor/inventery-list" },
  {
    title: "Settings",
    value:"settings",
    icon: <SettingsIcon />,
    path: "/distributor/settings",
  },
];



export default function DistributorLayout(props) {
  
  const SideBar = useSelector((state) => state.settings.showSideBar);
  const matches = useMediaQuery("(max-width:875px)");
  const dispatch = useDispatch();

  const handleSidebar = () => {
    dispatch(showSideBar(!SideBar));
  };

  let user = JSON.parse(localStorage.getItem("user"));
  let array2 = user?.permission || [];


  const founddata = arrayOfObjects.filter((val, index) => {
    return array2.includes(val.value)
  })

  useEffect(() => {
    if (matches) {
      dispatch(showSideBar(false));
    } else if (!matches) {
      dispatch(showSideBar(true));
    }
  }, [matches, dispatch]);

  return (
    <div>
      <div>
        <Topbar handleSidebar={handleSidebar} />
      </div>
      <div className={!SideBar ? "main-layout-left-none" : "main-layout-left"}>{props.children}</div>
      <div>
        <PermanentDrawerLeft status={SideBar} sidebarData={user?.role === "support"?founddata:arrayOfObjects} />
      </div>
    </div>
  );
}
