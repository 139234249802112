import "./styles.scss";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import DownloadIcon from "@mui/icons-material/Download";
import PaymentModal from "../Modals/makePayment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  paymentSummaryDownload,
} from "@reducers/api";
import { useComponentDidUpdate } from "@utils/useEffectHooks";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/subscriber";
import { useDispatch, useSelector } from "react-redux";

export default function PaymentSummary(props) {
  let user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { payNowAdded, paymentSummaryDownloadData } = useSelector((state) => state.subscriber);


  const date = { todate: moment().format("YYYY-MM-DDTHH:mm:ss"), fromdate: moment().startOf("month").format("YYYY-MM-DDTHH:mm:ss") };
  const todayDate = moment().format("YYYY-MM-DDTHH:mm:ss");
  const yesterdayDate = moment().add(-1, "days").format("YYYY-MM-DDTHH:mm:ss");
  const tomorrowDate = moment().add(1, "days").format("YYYY-MM-DDTHH:mm:ss");
  const fivedaysDate = moment().add(5, "days").format("YYYY-MM-DDTHH:mm:ss");
  const tendaysDate = moment().add(10, "days").format("YYYY-MM-DDTHH:mm:ss");




  const onShowPopUp = () => {
    setShow(!show);
    dispatch(reset());
  };

  const handleRedirect = () => {
    navigate("/distributor/generate-bill");
  };

  useComponentDidUpdate(() => {
    if (payNowAdded) {
      enqueueSnackbar("Payment Success !", {
        variant: "success",
      });
      dispatch(reset());
      setShow(false);
    }
  }, [payNowAdded]);



  const detailsTile = (valOne, valTwo, valThree, valFour, valFive) => {
    return (
      <Grid container columns={{ xs: 12, sm: 8, md: 12 }} className="border-bottom-summary " style={{ color: "#707070", padding: 4 }}>
        <Grid item xs={3} sm={6} md={3} className="text-size-sub spacingContent">
          {valOne}
        </Grid>
        <Grid item xs={3} sm={6} md={3} className="text-size-sub right-align">
          ₹ {valTwo}
        </Grid>
        <Grid item xs={2} sm={6} md={2} className="text-size-sub">
          {valThree}
        </Grid>
        <Grid item xs={2} sm={6} md={2} className="text-size-sub right-align">
          {valFour}
        </Grid>
        <Grid item xs={2} sm={6} md={2} className="text-size-sub flex-center justify-center downloadHide">
          {
            valFive ? <DownloadIcon className="downloadPayment" onClick={() => {
              props?.unPaidSummaryExcel();
            }} /> : ""
          }
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <div className="payment-summary-cnt">
        <PaymentModal show={show} handleClose={onShowPopUp} showAccountId={true} showDashboard={true} />
        <Grid container columns={{ xs: 12, sm: 8, md: 12 }} className="payment-summary-header">
          <Grid item xs={3} sm={6} md={3} className="text-size">
            Total bill amount
          </Grid>
          <Grid item xs={3} sm={6} md={3} className="text-size right-align ">
            ₹ {props?.data?.total_billing_amount}
          </Grid>
          <Grid item xs={2} sm={6} md={2} className="text-size ">
            Bill count
          </Grid>
          <Grid item xs={2} sm={6} md={2} className="text-size right-align">
            {props?.data?.total_billing_count}
          </Grid>
          <Grid item xs={2} sm={6} md={2} className="text-size flex-center justify-center downloadFirst downloadHide">
            <DownloadIcon className="downloadPayment" onClick={() => {
              props?.paymentSummaryExcel();
            }} />
          </Grid>
        </Grid>
        <div className="border-summary">
          <Grid container columns={{ xs: 12, sm: 8, md: 12 }} className="border-bottom-summary" style={{ color: "#707070", padding: 4 }}>
            <Grid item xs={3} sm={6} md={3} className="text-size-sub spacingContent">
              Paid amount
            </Grid>
            <Grid item xs={3} sm={6} md={3} className="text-size-sub right-align">
              ₹ {props?.data?.total_transaction_amount}
            </Grid>
            <Grid item xs={2} sm={6} md={2} className="text-size-sub ">
              Paid
            </Grid>
            <Grid item xs={2} sm={6} md={2} className="text-size-sub right-align">
              {props?.data?.total_transaction_count}
            </Grid>
            <Grid item xs={2} sm={6} md={2} className="text-size-sub flex-center justify-center downloadHide">
              <DownloadIcon className="downloadPayment " onClick={() => {
                props?.paidSummaryExcel();
              }} />
            </Grid>
          </Grid>

          {detailsTile("Online Payment", props?.data?.total_transaction_online_amount, "Paid", props?.data?.total_transaction_online_count)}
          {detailsTile("Card Payment", props?.data?.total_transaction_card_amount, "Card", props?.data?.total_transaction_card_count)}
          {detailsTile("Cash Payment", props?.data?.total_transaction_cash_amount, "Cash", props?.data?.total_transaction_cash_count)}
          {detailsTile("Free Payment", props?.data?.total_transaction_free_amount, "Free", props?.data?.total_transaction_free_count)}
        </div>
      </div>
      <div className="payment-summary-cnt">
        <div className="border-summary" style={{ marginTop: 13 }}>
          {detailsTile("Not Paid", props?.data?.total_transaction_unpaid_amount, "Not Paid", props?.data?.total_transaction_unpaid_count, "icondownload")}
        </div>
      </div>
      {
        user?.role !== "admin" &&
        <div style={{ marginTop: 13 }}>
          <Stack spacing={2} direction="row" className="flex-end">
            <Button style={{ textTransform: "none", backgroundColor: "#00249b", color: "white" }} onClick={onShowPopUp} variant={"outlined"} size="small">
              Make Payment
            </Button>
            <Button style={{ textTransform: "none", backgroundColor: "white", color: "#00249b", border: `1px solid${"#00249b"}` }} onClick={handleRedirect} variant={"outlined"} size="small">
              Generate Bill
            </Button>
          </Stack>
        </div>}
    </>
  );
}
