////Admin Routes

import Dashboard from "@routes/admin/dashboard";
import AddPlan from "@routes/admin/addPlans";
import DistributionList from "@routes/admin/distributorList";
import PlanList from "@routes/admin/planList";
import CreateDistributor from "@routes/admin/CreateDistributor";
import AddEmployeeForm from "@components/AddEmployeeForm";
import Profile from "@routes/admin/profile";
import ListViewExpiry from "@routes/admin/list-view";
import ChangePassword from "@routes/admin/changePassword";




////Distibutor Routes
import DistributorDashboard from "@routes/distributor/dashboard";
import EmployeeList from "@routes/distributor/employeeList";
import TicketList from "@routes/distributor/ticket-list";
import TicketView from "@routes/distributor/ticket-view";
import Enquiry from "@routes/distributor/enquiry";
import Expenses from "@routes/distributor/expenses";
import AddTicket from "@routes/distributor/add-ticket";
import BulkSms from "@routes/distributor/Bulk-sms";
import AddEnquiry from "@routes/distributor/add-enquiry";
import SubscriberDetails from "@routes/distributor/subscriber-userDetails";
import SubscriberList from "@routes/distributor/subscriber-list";
import SubscriberEdit from "@routes/distributor/subscriber-edit";
import SubscriberAdd from "@routes/distributor/add-subscriber";
import PlanListSubscriber from "@routes/distributor/plan-list";
import CollectionList from "@routes/distributor/billing-collection-list";
import InvoiceList from "@routes/distributor/invoice-list";
import PaidList from "@routes/distributor/paid-list";
import UnPaidList from "@routes/distributor/unpaid-list";
import SubscriberPlan from "@routes/distributor/subscriber-plan";
import AddSubscriberPlan from "@routes/distributor/subscriber-add-plan";
import ReceiptList from "@routes/distributor/receipt-list";
import GenerateBill from "@routes/distributor/generate-bill";
import SettingsTab from "@routes/distributor/settings-tab-pages";
import GenerateBillAdmin from "@routes/admin/generateBill";
import AddEmployee from "@routes/distributor/add-employee";
import ExpensesList from "@routes/distributor/expensesList";
import AssignSubscriberList from "@routes/distributor/assignSubscriberList";
import ReadyToPayList from "@routes/distributor/readyToPay";
import DeleteSubscriberList from "@routes/distributor/deleteSubscriberList";
import DeleteRestoreSubscriberList from "@routes/distributor/deleteRestoreSubscriberList";
import InventeryList from "@routes/distributor/inventeryList";
import HardwareList from "@routes/distributor/hardwareList";
import Notification from "@routes/distributor/notification";
import CollectionAgentList from "@routes/distributor/collectionAgent-list";
import OutStandingList from "@routes/distributor/outstanding-list";

///customer Routes

import CustomerDashboard from "@routes/customer/dashboard";

///home Routes

import AdminHome from "@routes/admin/admin-home";
import DistHome from "@routes/distributor/distributor-home";
import CustomerHome from "@routes/customer-home";
import PrivacyPolicy from "@routes/privacy-policy";

const adminRoutes = [
  {
    path: "dashboard",
    component: Dashboard,
  },
  {
    path: "add-plan",
    component: AddPlan,
  },
  {
    path: "plan-list",
    component: PlanList,
  },
  {
    path: "distributor-list",
    component: DistributionList,
  },
  {
    path: "add-distributor",
    component: CreateDistributor,
  },
  {
    path: "generate-bill",
    component: GenerateBillAdmin,
  },
  {
    path: "profile",
    component: Profile,
  },
  {
    path: "distributor-expiry-list",
    component: ListViewExpiry,
  },
  {
    path: "employee-list",
    component: EmployeeList,
  },
  {
    path: "subscriber-list",
    component: SubscriberList,
  },
  {
    path: "change-password",
    component: ChangePassword,
  },
  {
    path: "add-dist-employee",
    component: AddEmployee,
  },
 
];

const distributorRoutes = [
  {
    path: "dashboard",
    component: DistributorDashboard,
  },
  {
    path: "add-employee",
    component: AddEmployeeForm,
  },
  {
    path: "employee-list",
    component: EmployeeList,
  },
  {
    path: "ticket-list",
    component: TicketList,
  },
  {
    path: "enquiry-list",
    component: Enquiry,
  },
  {
    path: "create-expense",
    component: Expenses,
  },
  {
    path: "add-ticket",
    component: AddTicket,
  },
  {
    path: "add-enquiry",
    component: AddEnquiry,
  },
  {
    path: "subscriber-profile",
    component: SubscriberDetails,
  },
  {
    path: "subscriber-list",
    component: SubscriberList,
  },
  {
    path: "add-subscriber",
    component: SubscriberAdd,
  },
  {
    path: "subscriber-plan",
    component: SubscriberPlan,
  },
  {
    path: "subscriber-plan-list",
    component: PlanListSubscriber,
  },
  {
    path: "add-subscriber-plan",
    component: AddSubscriberPlan,
  },
  {
    path: "collection-list",
    component: CollectionList,
  },
  {
    path: "paid-list",
    component: PaidList,
  },
  {
    path: "paid-list",
    component: PaidList,
  },
  {
    path: "unpaid-list",
    component: UnPaidList,
  },

  {
    path: "invoice-list",
    component: InvoiceList,
  },
  {
    path: "receipt-list",
    component: ReceiptList,
  },
  {
    path: "generate-bill",
    component: GenerateBill,
  },
  {
    path: "settings",
    component: SettingsTab,
  },
  {
    path: "add-dist-employee",
    component: AddEmployee,
  },
  {
    path: "expenses-list",
    component: ExpensesList,
  },
  {
    path: "assign-subscriber-list",
    component: AssignSubscriberList,
  },
  {
    path: "ready-to-pay-list",
    component:ReadyToPayList,
  },
  {
    path: "inventery-list",
    component: InventeryList,
  },
  {
    path: "ticket-view",
    component: TicketView,
  },
  {
    path: "subscriber-edit",
    component: SubscriberEdit,
  },
  {
    path: "hardware-list",
    component: HardwareList,
  },

  {
    path: "delete-subscriber",
    component: DeleteSubscriberList,
  },
  {
    path: "delete-restore-subscriber",
    component: DeleteRestoreSubscriberList,
  },
  {
    path: "profile",
    component: Profile,
  },
  {
    path: "change-password",
    component: ChangePassword,
  },
  {
    path: "notification",
    component: Notification,
  },
  {
    path: "bulk-sms",
    component: BulkSms,
  },
  {
    path: "collection-agent-list",
    component: CollectionAgentList,
  },
  {
    path: "outstanding-list",
    component: OutStandingList,
  },
  
];

const customerRoutes = [
  {
    path: "dashboard",
    component: CustomerDashboard,
  },
  {
    path: "change-password",
    component: ChangePassword,
  },
];

const subadminRoutes = [
  {
    path: "dashboard",
    component: Dashboard,
  },
  {
    path: "add-plan",
    component: AddPlan,
  },
  {
    path: "plan-list",
    component: PlanList,
  },
  {
    path: "distributor-list",
    component: DistributionList,
  },
  {
    path: "add-distributor",
    component: CreateDistributor,
  },
  {
    path: "generate-bill",
    component: GenerateBillAdmin,
  },
  {
    path: "profile",
    component: Profile,
  },
  {
    path: "distributor-expiry-list",
    component: ListViewExpiry,
  },
  {
    path: "employee-list",
    component: EmployeeList,
  },
  {
    path: "subscriber-list",
    component: SubscriberList,
  },
  {
    path: "change-password",
    component: ChangePassword,
  },
];

const homeRoutes = [
  {
    path: "admin-home",
    component: AdminHome,
  },
  {
    path: "distributor-home",
    component: DistHome,
  },
  {
    path: "customer-home",
    component: CustomerHome,
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicy,
  },
];

export { adminRoutes, distributorRoutes, customerRoutes, subadminRoutes, homeRoutes  };
