import "./styles.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchWithButtons from "@components/SearchWithButtons";
import { useNavigate } from "react-router-dom";
import { useComponentDidMount, useComponentDidUpdate } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { billingList, deleteRceipt, lastPaid, currentServiceList } from "@reducers/api";
import { useSnackbar } from "notistack";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import { useState } from "react";
import BreadCrumb from "@components/Breadcrumb";
import Grid from "@mui/material/Grid";
import TicketFilter from "@components/TicketFilter";
import { reset } from "@reducers/billing";
import { Subreset } from "@reducers/subscriber";
import PaymentModal from "@components/Modals/reportPayNow";
import Button from "@mui/material/Button";



export default function ReceiptList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { billingListData, invoiceDeleted } = useSelector(
    (state) => state.billing
  );
  const { lastPaidData, payNowAdded } = useSelector(
    (state) => state.subscriber
  );
  const [billList, setbillList] = useState([]);
  const [customerData, setCustData] = useState([]);
  const [dateFrom, setDateFromQuery] = useState("");
  const [dateTo, setDateToQuery] = useState("");
  const [filterOne, setFirstQuery] = useState("");
  const [filtertwo, setSecondQuery] = useState("");
  const [filterthree, setThirdQuery] = useState("");

  const onShowPopUp = (data) => {
    setShow(!show);
    dispatch(reset());
    setCustData(data)
    let listid = data?.customerData?._id;
    let paidquery = `cust_ref_id=${listid}`;
    dispatch(lastPaid(paidquery));
   

    if (data?.service_type === "internet") {
      let query1 = `${listid}&is_active=1`;
      dispatch(currentServiceList(query1));
    }
  };

  useComponentDidUpdate(() => {
    if (payNowAdded) {
      enqueueSnackbar("Payment Success !", {
        variant: "success",
      });
      setShow(!show);
      dispatch(Subreset())
    }
  }, [payNowAdded]);


  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&billing_status=open`;
    if (value?.type === "search") {
      query += `&limit=10&offset=0&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };

  const [searchQuery, setSearchQuery] = useState("");

  const [page, setPage] = useState(1);

  useComponentDidMount(() => {
    let query = queryGenerator();
    dispatch(billingList(query));
  }, []);

  const serviceType = [
    { label: "Cable", value: "cable" },
    { label: "Internet", value: "internet" },
  ];

  const billingType = [
    { label: "Pre Paid", value: "pre paid" },
    { label: "Post Paid", value: "post paid" },
  ];

  const statusOption = [
    { label: "Open", value: "open" },
    { label: "Success", value: "success" },
  ];

  useComponentDidUpdate(() => {
    if (payNowAdded) {
      let query = queryGenerator();
      dispatch(billingList(query));
      onShowPopUp()
    }
  }, [payNowAdded]);


  useComponentDidUpdate(() => {
    let list = [];
    billingListData?.data?.map((data, index) => {

      list.push({
        sNo: (page - 1) * 10 + index + 1,
        customerName: data?.customerData.name,
        accountid: data?.customerData.account_id,
        msqid: data?.customerData?.mso_id,
        stbno: data?.stb_no || data?.customerData?.stb_no[0]?.stb_no || "-",
        customerEmail: data?.customerData?.email_id,
        billAmount: data?.bill_amount,
        collectionAmount: data?.collection_amount,
        dueAmount: data?.due_amount,
        discount: data?.discount_amount,
        paymentType: data?.payment_type,
        serviceType: data?.service_type,
        remarks: data?.remark,
        subarea: data?.customerData?.subarea || "-",
        flatno: data?.customerData?.flat_no || "-",
        paidBy: data?.customerData?.collected_by,
        completedDate: moment(data?.updated_at).format("MMMM Do YYYY, h:mm a"),
        createdDate: moment(data?.created_at).format("MMMM Do YYYY, h:mm a"),
        billing_status: data?.billing_status,
        payment_status: data?.payment_status,
        customerData: data?.customerData,
        makepay: (
          data?.billing_status === "open" ? (
            <Button
                variant="outlined"
                size="small"
                className=" spaceBtns"
                style={{
                  borderRadius: 15,
                  border: "1px solid #00249b",
                  color: "#00249b",
                  fontSize: "11px",
                }}
                // onClick={onShowPopUp}
                onClick={() => onShowPopUp(data)}
              >
                Pay Now
              </Button>
              
          ): ""
          
        ),
        
        action: (
          <div className="flex-row justify-center">
            <div
              className="cur-pointer"
              onClick={() =>
                dispatch(
                  deleteRceipt({
                    invoice_id: data?.order_id,
                  })
                )
              }
            >
              <DeleteIcon />
            </div>
          </div>
        ),
     
      });
    });

    setbillList(list);
  }, [billingListData]);

  useComponentDidUpdate(() => {
    if (invoiceDeleted) {
      enqueueSnackbar("Receipt Deleted !", {
        variant: "success",
      });
      let query = queryGenerator()
      dispatch(billingList(query));
      dispatch(reset());
    }
  }, [invoiceDeleted]);

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    let query = queryGenerator({ type: "search", state: search });
    dispatch(billingList(query));
  };




  const handleRedirect = () => {
    navigate("/distributor/generate-bill");
  };

  const status = (color, text) => {
    return (
      <div className="status-btn-table" style={{ backgroundColor: color }}>
        {text}
      </div>
    );
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

 


  const handleClickSearcch = (value, value1, priorityval, statusval, thirdValue) => {
    setPage(1);
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `&distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&billing_status=open`;
    let billingstatusquery = `&distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;
    if (value) {
      query += `&fromdate=${moment(value).format("YYYY-MM-DDTHH:mm:ss")}`
    }
    if (value1) {
      query += `&todate=${moment(value1).format("YYYY-MM-DDTHH:mm:ss")}`
    }
    if (priorityval) {
      query += `&service_type=${priorityval?.value}`
    }
    if (thirdValue) {
      query += `&billing_type=${thirdValue?.value}`
    }
    if (statusval) {
      billingstatusquery += `&billing_status=${statusval?.value}`
    }
    dispatch(billingList(`&value=${statusval?.value ? billingstatusquery : query}`));
    setDateFromQuery(value);
    setDateToQuery(value1);
    setFirstQuery(priorityval?.value);
    setThirdQuery(thirdValue?.value);
    setSecondQuery(statusval?.value);
  };

  const handleChange = (value) => {

    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(billingList(`distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=${value - 1}0${searchQuery ? `&value=${searchQuery}` : ""}${dateFrom? `&fromdate=${moment(dateFrom).format("YYYY-MM-DDTHH:mm:ss")}`: ""}${ dateTo ? `&todate=${moment(dateTo).format("YYYY-MM-DDTHH:mm:ss")}` : ""}${filterOne? `&service_type=${filterOne}`: ""}${filtertwo? `&billing_status=${filtertwo}`: `&billing_status=open`}${filterthree? `&billing_type=${filterthree}`: ""}`));
  };

  const handleReset = () => {
    let query = queryGenerator();
    dispatch(billingList(query));
    setDateFromQuery("");
    setDateToQuery("");
    setFirstQuery("");
    setThirdQuery("");
    setSecondQuery("");
    setPage(1);
  };

  return (
    <div className="receiptList-container ticketList-container">
      {/* <PaymentModal show={show} showAccountId={true} data ={billList} handleClose={onShowPopUp} /> */}
      <PaymentModal
          show={show}
          listData={customerData}
          handleClose={onShowPopUp}
          lastData={lastPaidData}
        />
      <Grid container spacing={2}>
        <Grid item xs={8}>

        </Grid>
        <Grid item xs={4} className="flex-end">
          <div className="receipt-title">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              activeHeading="Receipt List"
              handleActiveClick={() => handleBreadCrumbRedirect("receipt-list")}
            />
          </div>

        </Grid>
      </Grid>

      {/* <div className="receiptList-table-padding"> */}
      <div className="ticket-list-table-cntr">

        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={4} md={3}>
            <div className="receipt-title">Receipt List</div>
          </Grid>
          <Grid item xs={0} sm={4} md={6}></Grid>
          <Grid item xs={4} sm={4} md={3} className="flex-end">
            <div className="">
              <TicketFilter labelPrimary="Service Type"
                PrimaryOption={serviceType} thirdOption={"Billing Type"} thirdList={billingType} 
                handleFormValues={(data1, data2, data3, data4, data5) => handleClickSearcch(data1, data2, data3, data4, data5)} 
                handleResetClick = {handleReset} />
            </div>
          </Grid>
        </Grid>
        <div className="search-btns-padding">
          <SearchWithButtons

            primaryButton={{
              title: "Generate Bill",
              type: "contained",
              color: "white",
              bgColor: COLORS.primary,
            }}

            

            handlePrimaryClick={handleRedirect}
            handleChange={(e) => handleSearch(e)}
           
          />

        </div>
        <div style={{ fontWeight: 'bold', fontSize: '15px', paddingBottom: '10px' }}>Showing entries {(page - 1) * 10 + 1} - {Math.ceil(billingListData?.totalCount / 10) === page ? billingListData?.totalCount : page + "0"} of {billingListData?.totalCount}</div>

        <TableView rows={tableRows} data={billList} color={COLORS.primary} bilingTable={true}/>

        <div style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}>
          <Pagination
            count={Math.ceil(billingListData?.totalCount / 10) || 0}
            defaultPage={1}
            page={page}
            onChange={(event, value) => {
              setPage(value);
              handleChange(value);
            }}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
      {/* </div> */}
      <div className="copyrightsText">
        <p>© Copyrights CablePay Solutions India Pvt Ltd.2018.All rights reserved</p>
      </div>
    </div>
  );
}
