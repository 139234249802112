import "./styles.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchWithButtons from "@components/SearchWithButtons";
import { useNavigate } from "react-router-dom";
import { useComponentDidMount, useComponentDidUpdate, useComponentWillUnmount } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { outstandingList, deleteCollection, paidByList, collectionListNew,  outstandingListExport, lastPaid } from "@reducers/api";
import { useState } from "react";
import { useSnackbar } from "notistack";
import Pagination from "@mui/material/Pagination";
import TicketFilter from "@components/TicketFilter";
import Grid from "@mui/material/Grid";
import moment from "moment";
import TicketStatus from "@components/TicketStatus";
import BreadCrumb from "@components/Breadcrumb";
import { reset } from "@reducers/subscriber";
import { Billingreset } from "@reducers/billing";
import Button from "@mui/material/Button";
import PaymentModal from "@components/Modals/reportPayNow";
import { Subreset } from "@reducers/subscriber";

const serviceType = [
  { label: "Cable", value: "cable" },
  { label: "Internet", value: "internet" },
];

const ticketStatusData = [

  {
    id: 2,
    title: "Total Bill Count",
    count: 28,
    icon: "",
  },
  {
    id: 3,
    title: "Total Outstanding Amount",
    count: 13,
    icon: "",
  },
  
];

export default function OutstandingList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { outstandList,  outstandExport } = useSelector((state) => state.billing);
  const { payNowAdded } = useSelector((state) => state.subscriber);
  const [billList, setbillList] = useState([]);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [dateFrom, setDateFromQuery] = useState("");
  const [dateTo, setDateToQuery] = useState("");
  const [filterOne, setFirstQuery] = useState("");
  const [filtertwo, setSecondQuery] = useState("");
  const [filterthree, setThirdQuery] = useState("");
  const [filterFour, setFourQuery] = useState("");
  const [show, setShow] = useState(false);
  const [customerData, setCustData] = useState([]);

  useComponentDidUpdate(() => {
    if (outstandExport) {
      if (outstandExport.fileData) {
        window.location.href = `data:application/vnd.ms-excel;base64,${outstandExport.fileData}`
      }
      if (outstandExport?.msg == "no data found") {
        enqueueSnackbar("No data....!", {
          variant: "error",
        });
      }
      dispatch(reset())
    }
  }, [outstandExport]);

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;
    if (value?.type === "search") {
      query += `&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };



  const billingType = [
    { label: "Pre Paid", value: "pre paid" },
    { label: "Post Paid", value: "post paid" },
  ];


  useComponentDidMount(() => {
  
 
    let query = queryGenerator();
    dispatch(outstandingList(query));
  }, []);

 console.log(outstandList?.customers, "")

 const onShowPopUp = (data) => {
  setShow(!show);
  setCustData(data)
  let listid = data?._id;
  let paidquery = `cust_ref_id=${listid}`;
  dispatch(lastPaid(paidquery));
 
};

useComponentDidUpdate(() => {
  if (payNowAdded) {
    enqueueSnackbar("Payment Success !", {
      variant: "success",
    });
    setShow(!show);
    dispatch(Subreset())
    let query = queryGenerator();
    dispatch(outstandingList(query));
  }
}, [payNowAdded]);


 useComponentDidUpdate(() => {
    let list = [];
    outstandList?.customers?.map((data, index) => {
      console.log(data, "dataslist")
      list.push({
        sNo: (page - 1) * 10 + index + 1,
        customerName: data?.name,
        accountid: data?.account_id,
        msqid: data?.mso_id,
        stbno: data?.stb_no ||  "-",
        customerEmail: data?.email_id,
        area: data?.area|| "-",
        subarea: data?.subarea|| "-",
        flatno: data?.flat_no|| "-",
        billAmount: data?.bill_amount,
        billingType: data?.billing_type,
        collectionAmount: data?.collection_amount,
        dueAmount: data?.due_amount,
        discount: data?.discount_amount,
        advance: data?.advance_amount,
        paymentType: data?.payment_type,
        serviceType: data?.service_type,
        remarks: data?.remark,
        status: status("#73ca72", data?.billing_status),
        paidBy: data?.collected_by,
        createdDate: moment(data?.due_added_date).format("MMMM Do YYYY, h:mm a"),
        billing_status: data?.billing_status,
        makepay: (
        
            <Button
                variant="outlined"
                size="small"
                className=" spaceBtns"
                style={{
                  borderRadius: 15,
                  border: "1px solid #00249b",
                  color: "#00249b",
                  fontSize: "11px",
                }}
                onClick={() => onShowPopUp(data)}
              >
                Pay Due
              </Button>
              
        
          
        ),
        action: (
          <div className="flex-row justify-center">
            <div
              className="cur-pointer"
              onClick={() =>
                dispatch(
                  deleteCollection({
                    invoice_id: data?.order_id,
                  })
                )
              }
            >
              <DeleteIcon />
            </div>
          </div>
        ),
      });
   
    });

    setbillList(list);

  }, [outstandList]);



  useComponentWillUnmount(() => {
    dispatch(reset());
    dispatch(Billingreset());
  });

  const status = (color, text) => {
    return (
      <div className="status-btn-table" style={{ backgroundColor: color }}>
        {text}
      </div>
    );
  };

  const handleRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  const handleExtraExportClick = (path) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(outstandingListExport(`distributor_ref_id=${user?.distributor_ref_id}`));
  };

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    let query = queryGenerator({ type: "search", state: search });
    dispatch(outstandingList(query));
  };

  const handleChange = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(outstandingList(`distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=${value - 1}0${searchQuery ? `&value=${searchQuery}` : ""}${dateFrom? `&fromdate=${moment(dateFrom).format("YYYY-MM-DDTHH:mm:ss")}`: ""}${ dateTo ? `&todate=${moment(dateTo).format("YYYY-MM-DDTHH:mm:ss")}` : ""}${filterOne? `&service_type=${filterOne}`: ""}${filtertwo? `&paidBy=${filtertwo}`: ""}${filterthree? `&billing_type=${filterthree}`: ""}${filterFour? `&payment_type=${filterFour}`: ""}`));
  };

  const handleClickSearcch = (value, value1, priorityval, statusval, thirdValue, fourthValue) => {
    setPage(1);
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;
    if (value) {
      query += `&startDate=${moment(value).format("YYYY-MM-DDTHH:mm:ss")}`
    }
    if (value1) {
      query += `&endDate=${moment(value1).format("YYYY-MM-DDTHH:mm:ss")}`
    }
    if (priorityval) {
      query += `&service_type=${priorityval?.value}`
    }
    if (thirdValue) {
      query += `&billing_type=${thirdValue?.value}`
    }
    if (statusval) {
      query += `&paidBy=${statusval}`
    }
    if (fourthValue) {
      query += `&payment_type=${fourthValue?.value}`
    }
    dispatch(outstandingList(query))
    setDateFromQuery(value);
    setDateToQuery(value1);
    setFirstQuery(priorityval?.value);
    setThirdQuery(thirdValue?.value);
    setFourQuery(fourthValue?.value)
    setSecondQuery(statusval);
  };

  const handleReset = () => {
    let query = queryGenerator();
    dispatch(outstandingList(query));
    let user = JSON.parse(localStorage.getItem("user"));
    let distid = `distributor_ref_id=${user?.distributor_ref_id}`;
    dispatch(paidByList(distid));
    dispatch(collectionListNew(query))
    setPage(1);
    setDateFromQuery("");
    setDateToQuery("");
    setFirstQuery("");
    setThirdQuery("");
    setFourQuery("")
    setSecondQuery("");
  };

  return (
    <div className="outList-container ticketList-container">
       <PaymentModal
          show={show}
          listData={customerData}
          handleClose={onShowPopUp}
          isoutstand ={true}
        />
      <Grid container spacing={2}>
        <Grid item xs={6} md={8}>
        </Grid>
        <Grid item xs={6} md={4} className="flex-end">
          <div className="planHeading">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleRedirect("dashboard")}
              activeHeading="Outstand List"
              handleActiveClick={() => handleRedirect("outstanding-list")}
            />
          </div>
        </Grid>
      </Grid>

      <div className="ticket-list-table-cntr">

        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={4} md={3}>
            <div className="planHeading">Outstanding List</div>
          </Grid>
          <Grid item xs={0} sm={4} md={6}></Grid>
          <Grid item xs={4} sm={4} md={3} className="flex-end">
            <div className="">
              <TicketFilter labelPrimary="Service Type" 
                PrimaryOption={serviceType}  thirdOption={"Billing Type"}  thirdList={billingType} 
                handleFormValues={(data1, data2, data3, data4, data5, data6) => handleClickSearcch(data1, data2, data3, data4, data5, data6)}
                handleResetClick={handleReset} />
            </div>
          </Grid>
        </Grid>
        <div>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.4}
              style={{ paddingLeft: 0, paddingBottom: 0 }}
            >
              <TicketStatus
                className=" ticketCardGreen"
                title={ticketStatusData[0].title}
                count={outstandList?.totalCount || "0"}
                icon={ticketStatusData[0].icon}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.4}
              style={{ paddingLeft: 0, paddingBottom: 0 }}
            >
              <TicketStatus
                className=" ticketCardRed"
                title={ticketStatusData[1]?.title}
                count={outstandList?.totalDueAmount || "0"}
                icon={ticketStatusData[1]?.icon}
              />
            </Grid>
            
          
          </Grid>
        </div>
        <div className="search-btns-padding">
          <SearchWithButtons
           
            secondryExtraButtonExportData={{
              title: "Export",
              type: "contained",
              color: COLORS.primary,
              bgColor: COLORS.primary,
            }}
            handleExtraExportClick={() => handleExtraExportClick("export")}
            handlePrimaryClick={() => handleRedirect("paid-list")}
            handleSecondaryClick={() => handleRedirect("unpaid-list")}
            handleExtraClick={() => handleRedirect("invoice-list")}
            handleChange={handleSearch}

          />
        </div>
        <div style={{ fontWeight: 'bold', fontSize: '15px', paddingBottom: '10px' }}>Showing entries {(page - 1) * 10 + 1} - {Math.ceil(outstandList?.totalCount / 10) == page ? outstandList?.totalCount : page + "0"} of {outstandList?.totalCount}</div>
        <TableView rows={tableRows} data={billList} color={COLORS.primary} bilingTable={true}/>
        <div style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}>
          <Pagination
            count={Math.ceil(outstandList?.totalCount / 10) || 0}
            defaultPage={1}
            page={page}
            onChange={(event, value) => {
              setPage(value);
              handleChange(value);
            }}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
}
