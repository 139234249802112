import "./styles.scss";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { enquiryStatus } from "../../reducers/api";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useComponentDidUpdate,
} from "@utils/useEffectHooks";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";


const validation = Yup.object({
  userName: Yup.string().required("Required").nullable(),
  phoneNo: Yup.string().required("Required").max(10, 'Must be exactly 10 digits').matches(new RegExp('[0-9]{10}'), "Phone number is not valid"),
  enquiryStatus: Yup.object().required("Required").nullable(),
  enquiryType: Yup.object().required("Required").nullable(),
  assignedTo: Yup.object().required("Required").nullable(),
  address: Yup.string().required("Required").nullable(),
  userEmail: Yup.string()
    .required("Required")

    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    ),
});

export default function AddEnquiryForm(props) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });
  const navigate = useNavigate();
  const onSubmit = (data) => props?.handleFormValues(data);

  const enquiryType = [
    { label: "cable", id: 1994, value: "cable" },
    { label: "internet", id: 1972, value: "internet" },
    // { label: "both ", id: 1972, value: "both" },
  ];

  const enquiryStatus = [
    { label: "Open", value: "open" },
    { label: "Pending", value: "pending" },
    { label: "Inprogress", value: "inprogress" },
    { label: "Resolved", value: "resolved" },
    { label: "Closed", value: "closed" },
  ];

  const filterObject = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options.value.toString() === value.toString()
      );
    setValue(title, filtered[0]);
  };
  const filterObjectAssigned = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options.email_id.toString() === value.toString()
      );
    setValue(title, filtered[0]);
  };

  const filterArea = (title, options, value) => {
    console.log(options, "optionss")
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options?.toString() === value?.toString()
      );
    setValue(title, filtered && filtered[0]);
  };

  useComponentDidUpdate(() => {
    setValue("userName", props?.data?.name || "");
    setValue("userEmail", props?.data?.email_id || "");
    setValue("phoneNo", props?.data?.mobile_no || "");
    filterObject(
      "enquiryStatus",
      enquiryStatus,
      props?.data?.enquiry_status || ""
    );
    filterObject("enquiryType", enquiryType, props?.data?.enquiry_type || "");
    filterArea("area", props?.areaData?.area, props?.data?.area || "");
    filterArea("subArea", props?.areaData?.subarea , props?.data?.subarea || "");
    filterObjectAssigned(
      "assignedTo",
      props?.empDetails,
      props?.data?.assigned_to || ""
    );
    setValue("address", props?.data?.address || "");
    setValue("description", props?.data?.description || "");
  }, [props]);

  const handleRedirect = () => {
    navigate(`/distributor/enquiry-list`);
  };

console.log(props, "propss")

  return (
    <div className="addEnquiry-form-cnt">
      <div className="add-enquiry-title">Enquiry</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addenquiry">User Name</div>
            <Grid item xs={2} sm={4} md={10}>
              {/* <TextField style={{ width: "100%" }} id="outlined-basic" variant="outlined" /> */}

              <TextField
                {...register("userName")}
                size="small"
                className="txtwidth"
                variant="outlined"
                id="outlined-basic"
                placeholder="Enter user name"
              />
              <div className="field-error">{errors.userName?.message}</div>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addenquiry">User Email</div>
            <Grid item xs={2} sm={4} md={10}>
              <TextField
                {...register("userEmail")}
                size="small"
                className="txtwidth"
                variant="outlined"
                id="outlined-basic"
                placeholder="Enter user email"
              />
              <div className="field-error">{errors.userEmail?.message}</div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addenquiry">Enquiry Status</div>

            <Grid item xs={2} sm={4} md={10}>
              <Controller
                control={control}
                name="enquiryStatus"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || ""}
                    options={enquiryStatus || []}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => (
                      <TextField {...params} size="small" placeholder="Select enquiry status" />
                    )}
                  />
                )}
              />
              <div className="field-error">{errors.enquiryStatus?.message}</div>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addenquiry">Enquiry Type</div>

            <Grid item xs={2} sm={4} md={10}>
              {/* <Controller
                control={control}
                name="enquiryType"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || ""}
                    options={enquiryType || []}
                    isOptionEqualToValue={(option) => option.label || ""}
                      getOptionLabel={(item) => (item.label ? item.label : "")}
                    // isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                )}
              /> */}
              <Controller
                control={control}
                name="enquiryType"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || ""}
                    options={enquiryType}
                    isOptionEqualToValue={(option) => option.label == value?.label || ""}
                    getOptionLabel={(item) => (item.label ? item.label : "")}
                    getOptionSelected={(option, value) =>
                      value === undefined ||
                      value === "" ||
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField {...params} size="small" placeholder="Select enquiry type" />
                    )}
                  />
                )}
              />
              <div className="field-error">{errors.enquiryType?.message}</div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addenquiry">Assigned To</div>

            <Grid item xs={2} sm={4} md={10}>
              <Controller
                control={control}
                name="assignedTo"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || ""}
                    options={props?.empDetails || []}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    getOptionLabel={(item) =>
                      item.name ? `${item.name}` : ""
                    }
                    defaultValue={props?.data?.assigned_to}
                    renderInput={(params) => (
                      <TextField {...params} size="small" placeholder="Select assigned to" />
                    )}
                  />
                )}
              />
              <div className="field-error">{errors.assignedTo?.message}</div>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addenquiry">Address</div>

            <Grid item xs={2} sm={4} md={10}>
              <TextField
                {...register("address")}
                size="small"
                className="txtwidth"
                variant="outlined"
                id="outlined-basic"
                placeholder="Enter address"
              />
              <div className="field-error">{errors.address?.message}</div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addenquiry">Area</div>

            <Grid item xs={2} sm={4} md={10}>
              <Controller
                control={control}
                name="area"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || ""}
                    options={props?.areaData?.area || []}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Select area"
                      />
                    )}
                  />
                )}
              />
              <div className="field-error">{errors.assignedTo?.message}</div>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addenquiry">Sub Area</div>

            <Grid item xs={2} sm={4} md={10}>
              <Controller
                control={control}
                name="subArea"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || ""}
                    options={props?.areaData?.subarea || []}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Select Sub area"
                      />
                    )}
                  />
                )}
              />
              <div className="field-error">{errors.assignedTo?.message}</div>
            </Grid>
          </Grid>
        </Grid>

        <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addenquiry">Phone Number</div>
            <Grid item xs={2} sm={4} md={10}>
              <TextField
                {...register("phoneNo")}
                size="small"
                className="txtwidth"
                variant="outlined"
                id="outlined-basic"
                placeholder="Enter phone number"
              />
            </Grid>
            <div className="field-error">{errors.phoneNo?.message}</div>
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addenquiry">Description</div>

            <Grid item xs={2} sm={4} md={10}>
              <textarea
                style={{ width: "100%", height: "34px" }}
                className="textarea-add-enquiry txtwidth"
                rows="2"
                cols="50"
                {...register("description")}
                variant="outlined"
                id="outlined-basic"
                placeholder="Enter description"
              />
            </Grid>
          </Grid>


          <Grid item xs={2} sm={4} md={11}>
            <div className="padding-title-addenquiry"></div>
            <div className="btn-enquiry-form">


              <Stack direction="row" className="flex-end btns-stack" spacing={2}>
                <Button size="small" variant="outlined" className="btn-outlined" onClick={handleRedirect} style={{ textTransform: "none", color: "#00249b" }}>
                  Cancel
                </Button>
                <Button type="submit" size="small" variant="contained" className="bg-blue" style={{ textTransform: "none" }}>
                  {props?.data?._id ? "Update " : "Create "}
                </Button>
              </Stack>
            </div>
            <div className="padding-title-addenquiry"></div>
            <div className="padding-title-addenquiry"></div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
