import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./styles.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useForm, Controller } from "react-hook-form";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validityRenewal} from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import { useComponentDidUpdate, paramsQuery, useComponentDidMount} from "@utils/useEffectHooks";
import { useSnackbar } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const validation = Yup.object().shape({
  enquiryType: Yup.object().required("Required").nullable()
});



const validityOptions = [
  { label: "30 Days", value: 30 },
  { label: "90 Days", value: 90 },
  { label: "180 Days", value: 180 },
  { label: "365 Days", value: 365 },
  { label: "Unlimited", value: "" },
];
export default function ResetPasswordPopup(props) {
  const {
    values,
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });
 
  const onSubmit = (data) => updateSubmit(data);
  const dispatch = useDispatch();
 

  const handleClose = () => {
    props?.handleClose();
  };

  const handleCloses = () => {
    props?.handleClose();
  };

  const [duevalue, setdueValue] = useState(props?.disData?.validity_added_date);




  useComponentDidUpdate(() => {
    console.log(props?.disData,"props?.disData")
    if (props?.disData) {
      setValue("email", props?.disData?.account_id);
      setValue("dueDate", props?.disData?.validity_added_date );
      setValue("smsaccess", props?.disData?.sms_access );
      setValue("whatsappaccess", props?.disData?.whatsapp_access );
      filterObject("enquiryType", validityOptions, props?.disData?.distributor_validity || "");
      setdueValue(props?.disData?.validity_added_date)
    }
  }, [props?.disData]);

  const updateSubmit = (values) => {
    let user = JSON.parse(localStorage.getItem("user"));
    console.log(values,"values")
    let payload = {
      distributor_validity: values?.enquiryType?.value,
      validity_added_date: values?.dueDate || props?.disData?.validity_added_date,
      _id: props?.disData?._id,
      sms_access: values?.smsaccess,
      whatsapp_access: values?.whatsappaccess,
    };
    dispatch(validityRenewal(payload));
    handleClose()
  };



  const filterObject = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered = options && options.filter((options) => options.value.toString() === value.toString());
    setValue(title, filtered[0]);
  };

  return (

    <Modal
      open={props?.show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

      <Box sx={style} className="makePayment-cntr">
        <h2>Validity Renewal</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            className="padding-btw-tiles"
          >
            <Grid item xs={4} sm={8} md={12}>
              <div className="make-payment-form-title ">Account Id </div>
              <TextField
                style={{ width: "100%" }}
                {...register("email")}
                id="outlined-basic"
                disabled
                variant="outlined"
                size="small"
                defaultValue={props?.listData?.wallet_balance || "0"}
              />
            </Grid>
          </Grid>


          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            className="padding-btw-tiles"
          >
            <Grid item xs={4} sm={8} md={12}>
              <div className="make-payment-form-title ">Validity </div>
              <Controller
                control={control}
                name="enquiryType"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || ""}
                    options={validityOptions}
                    isOptionEqualToValue={(option) => option.label == value?.label || ""}
                    getOptionLabel={(item) => (item.label ? item.label : "")}
                    getOptionSelected={(option, value) => value === undefined || value === "" || option.id === value.id}
                    renderInput={(params) => <TextField {...params} size="small" placeholder="Validity" />}
                  />
                )}
              />
            </Grid>
            <div className="field-error">{errors?.enquiryType?.message}</div>
          </Grid>
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            className="padding-btw-tiles"
          >
            <Grid item xs={4} sm={8} md={12} className='date-picker-height'>
              <div className="make-payment-form-title ">Validity Date </div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={props.label}
                  value={ duevalue}
                  name="dueDate"
                  onChange={(newValue) => {
                    setValue("dueDate", newValue);
                    setdueValue(newValue);
                  }}
                  renderInput={(params) => <TextField {...register("dueDate")} {...params} style={{ width: "100%"}} />}
                />
              </LocalizationProvider>
            </Grid>
            <div className="field-error">{errors?.dueDate?.message}</div>
          </Grid>

          <Stack spacing={2} direction="row" className=" " style={{ justifyContent: 'right' }}>
            <Button
              type="submit"

              variant="contained"
              className="bg-blue"
            >
              Submit
            </Button>
            <Button
              size="small"
              variant="outlined"
              style={{
                textTransform: "capitalize",
                borderColor: "#00249b",
                color: "#00249b",
              }}
              onClick={handleCloses}
            >
              Cancel
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>

  );
}
