import { Grid } from "@mui/material";
import "./styles.scss";
import { useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div style={{ marginBottom: 10 }} role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Testpage(props) {
  const theme = useTheme();
  const [selected, setSelected] = useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const handleChangeIndex = (index) => {
    setSelected(index);
  };

  return (
    <>
      <Grid container columns={{ xs: 6, sm: 6, md: 6}}>
        {props?.data &&
          props?.data.map((e, index) => (
            <Grid item xs={12} sm={1} md={1} onClick={() => setSelected(index)}>
              <div className="prog-tile" style={{ backgroundColor: selected === index ? "#5E81F7" : "#00249C" }}></div>
              <div className="text-center">{e.title}</div>
            </Grid>
          ))}
      </Grid>
      <SwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={selected} onChangeIndex={handleChangeIndex}>
        {props.data &&
          props?.data?.map((list, index) => (
            <TabPanel value={selected} index={index} dir={theme.direction}>
              {list?.comp}
            </TabPanel>
          ))}
      </SwipeableViews>
    </>
  );
}
