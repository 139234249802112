import "./styles.scss";
import { Grid } from "@mui/material";
import { Fragment } from "react";

export default function CountAndStatusTile(props) {
  const activeAndInactiveTile = (title, color, value, icon) => {
    return (
      <Fragment>
        <Grid item xs={1} sm={7} md={5}>
          <div className="status-title">{title}</div>
        </Grid>
        <Grid item xs={2} sm={7} md={2} className='Status-icon'>
          <span style={{ color: color,  }}> {icon} </span>
        </Grid>
        <Grid item xs={1} sm={7} md={1}>
          <div className="status-title">{value}</div>
        </Grid>
      </Fragment>
    );
  };
  return (
    <Grid container columns={{ xs: 6, sm: 8, md: 12 }} className="count-status-tile-cnt">
      <Grid item xs={2} sm={12} md={4}>
        <Grid container className="cnt-tle-cnt" columns={{ xs: 1, sm: 8, md: 12 }}>
          <Grid item xs={1} sm={7} md={6}>
            <div className="cnt-title">{props?.mainTileData?.title}</div>
          </Grid>
          <Grid container item xs={1} sm={7} md={3} className="flex-center mainIcon">
            <span style={{ color: props?.mainTileData?.color, display: "flex", alignItems: "center" }}>{props?.mainTileData?.mainicon}</span>
          </Grid>
          <Grid item xs={1} sm={7} md={3}>
            <div className="cnt-title">{props?.mainTileData?.count}</div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} sm={12} md={8} className="flex-center">
        <Grid container justifyContent="center" alignItems="center" columns={{ xs: 5, sm: 8, md: 12 }}>
          {activeAndInactiveTile(props?.activeTileData?.title, props?.activeTileData?.color, props?.activeTileData?.count, props?.activeTileData?.subicon)}
          <div className="status-divder"></div>
          {activeAndInactiveTile(props?.inActiveTileData?.title, props?.inActiveTileData?.color, props?.inActiveTileData?.count, props?.inActiveTileData?.subicon)}
        </Grid>
      </Grid>
    </Grid>
  );
}
