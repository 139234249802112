import "./styles.scss";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Stack from "@mui/material/Stack";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Autocomplete from "@mui/material/Autocomplete";
import {
  useComponentDidMount,
  useComponentDidUpdate,
  useComponentWillUnmount,
} from "@utils/useEffectHooks";
import { Subreset } from "@reducers/subscriber";
import { useDispatch } from "react-redux";

const validationCable = Yup.object({

  userName: Yup.string().required("Required").nullable(),
  mobileNo: Yup.string()
    .required("Required")
    .max(10, "Must be exactly 10 digits")
    .matches(new RegExp("[0-9]{10}"), "Phone number is not valid"),

  city: Yup.string().required("Required").nullable(),
  state: Yup.string().required("Required").nullable(),

});

const validationInternet = Yup.object({

  userName: Yup.string().required("Required").nullable(),
  mobileNo: Yup.string()
    .required("Required")
    .max(10, "Must be exactly 10 digits")
    .matches(new RegExp("[0-9]{10}"), "Phone number is not valid"),


  city: Yup.string().required("Required").nullable(),
  state: Yup.string().required("Required").nullable(),

});

export default function SubViewCard(props) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(props?.data?.service_type === "cable" ? validationCable : validationInternet),
  });

  const [isShowArea, setShowArea] = useState(false);
  const [isShowSubArea, setisShowSubArea] = useState(false);
  const dispatch = useDispatch();

  const clickArea = () => {
    setShowArea(!isShowArea);
    setisShowSubArea(false);
  };

  const clickSubArea = () => {
    setShowArea(false);
    setisShowSubArea(!isShowSubArea);
  };

  const onSubmit = (data) => props?.handleFormValues(data);

  const discType = [
    { label: "One Time", value: "onetime" },
    { label: "Permanent", value: "permanent" },
  ];

  const taxApply = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  const autoBill = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ];

  const filterObjectAssigned = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options.name.toString() === value.toString()
      );
    setValue(title, filtered[0]);
  };

  const filterObjectBillAssigned = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options?.value?.toString() === value?.toString()
      );
    setValue(title, filtered && filtered[0]);
  };

  useComponentDidMount(() => {
    setValue("canidlist", props?.data?.can_id );
    setValue("userid", props?.data?.user_id)
    setValue("mobileNo", props?.data?.mobile_no || "");
    filterObjectBillAssigned("discountType", discType, props?.data?.discount_type);
    setValue("disoountCharge", props?.data?.discount_amount || "");
    setValue("flatNo", props?.data?.flat_no || "");
    setValue("floor", props?.data?.floor || "");
    setValue("area", props?.data?.area || "");
    setValue("subarea", props?.data?.subarea || "");
    setValue("city", props?.data?.city || "");
    setValue("state", props?.data?.state || "");
    setValue("landmark", props?.data?.landmark || "");
    setValue("pincode", props?.data?.pin_code || "");
    setValue("tax", props?.data?.tax || "");
    setValue("msoid", props?.data?.mso_id || "");
    setValue("lohinIp", props?.data?.login_ip)
    filterObjectAssigned(
      "assignTo",
      props?.employeeData,
      props?.data?.assign_to
    );
    filterObjectBillAssigned("bill", autoBill, props?.data?.auto_billing);
    setValue("email", props?.data?.email_id || "");
    setValue("userName", props?.data?.name || "");
  }, []);


  useComponentDidUpdate(() => {
    setValue("canidlist", props?.data?.can_id);
    setValue("userid", props?.data?.user_id)
    setValue("mobileNo", props?.data?.mobile_no || "");
    filterObjectBillAssigned("discountType", discType, props?.data?.discount_type);
    setValue("disoountCharge", props?.data?.discount_amount || "");
    setValue("flatNo", props?.data?.flat_no || "");
    setValue("floor", props?.data?.floor || "");
    setValue("area", props?.data?.area || "");
    setValue("subarea", props?.data?.subarea || "");
    setValue("city", props?.data?.city || "");
    setValue("state", props?.data?.state || "");
    setValue("landmark", props?.data?.landmark || "");
    setValue("pincode", props?.data?.pin_code || "");
    setValue("tax", props?.data?.tax || "");
    setValue("msoid", props?.data?.mso_id || "");
    setValue("lohinIp", props?.data?.login_ip)
    filterObjectAssigned(
      "assignTo",
      props?.employeeData,
      props?.data?.assign_to
    );
    filterObjectBillAssigned("bill", autoBill, props?.data?.auto_billing);
    setValue("email", props?.data?.email_id || "");
    setValue("userName", props?.data?.name || "");
  }, [props?.data]);

  useComponentDidUpdate(() => {
    setValue("canidlist", props?.data?.can_id);
    
  }, [props?.data?.can_id]);

  console.log(props?.data?.can_id , "areanre")


  return (
    <div className="sub-edit-card">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="padding-sub-edit">
          <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
            {
              props?.data?.service_type === "cable" ? (
                <Grid item xs={2} sm={4} md={2.4}>
                  <div className="title-text-box">Can ID</div>
                  <Grid item xs={2} sm={4} md={10}>
                    <TextField
                      disabled
                      {...register("canidlist")}
                      size="small"
                      className="txtwidth"
                      variant="outlined"
                      id="outlined-basic"
                    />
                  </Grid>
                </Grid>

              ) :
                (
                  <Grid item xs={2} sm={4} md={2.4}>
                    <div className="title-text-box">User ID</div>
                    <Grid item xs={2} sm={4} md={10}>
                      <TextField
                        disabled
                        {...register("userid")}
                        size="small"
                        className="txtwidth"
                        variant="outlined"
                        id="outlined-basic"
                      />
                    </Grid>
                  </Grid>
                )
            }

            <Grid item xs={2} sm={4} md={2.4}>
              <div className="title-text-box">User Name</div>
              <Grid item xs={2} sm={4} md={10}>
                <TextField
                  {...register("userName")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
                <div className="field-error">{errors.userName?.message}</div>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={4} md={2.4}>
              <div className="title-text-box">Mobile Number</div>
              <Grid item xs={2} sm={4} md={10}>
                <TextField
                  {...register("mobileNo")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                  type="number"
                />
                <div className="field-error">{errors.mobileNo?.message}</div>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={4} md={2.4}>
              <div className="title-text-box">User Email</div>
              <Grid item xs={2} sm={4} md={10}>
                <TextField
                  {...register("email")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
                <div className="field-error">{errors.email?.message}</div>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={4} md={2.4}>
              <div className="title-text-box">Discount Type</div>
              <Grid item xs={2} sm={4} md={10}>
                <Controller
                  control={control}
                  name="discountType"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value || ""}
                      options={discType || []}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Select Discount Type"
                        />
                      )}
                    />
                  )}
                />
                <div className="field-error">
                  {errors.discountType?.message}
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            columns={{ xs: 2, sm: 8, md: 12 }}
            className="subEditmargin"
          >
            <Grid item xs={2} sm={4} md={2.4}>
              <div className="title-text-box">Discount Charge</div>
              <Grid item xs={2} sm={4} md={10}>
                <TextField
                  {...register("disoountCharge")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
                <div className="field-error">
                  {errors.disoountCharge?.message}
                </div>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={4} md={2.4}>
              <div className="title-text-box">Flat No</div>
              <Grid item xs={2} sm={4} md={10}>
                <TextField
                  {...register("flatNo")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
              </Grid>
            </Grid>
            <Grid item xs={2} sm={4} md={2.4}>
              <div className="title-text-box">Floor</div>
              <Grid item xs={2} sm={4} md={10}>
                <TextField
                  {...register("floor")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
              </Grid>
            </Grid>
            <Grid item xs={1.5} sm={4} md={1.9}>
              <div className="title-text-box">Area </div>
              {
                watch("areanew") ? (
                  <Grid item xs={2} sm={4} md={10}>
                    <Controller
                      control={control}
                      name="area"
                      disabled
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, item) => {
                            onChange(item);
                          }}
                          value={value || ""}
                          options={props?.areaData?.area || []}
                          isOptionEqualToValue={(option, value) => option === value}
                          renderInput={(params) => (
                            <TextField
                              {...params}

                              size="small"
                              placeholder="Select area"
                            />
                          )}
                        />
                      )}
                    />
                    <div className="field-error">{errors.area?.message}</div>
                  </Grid>
                ) : (
                  <Grid item xs={2} sm={4} md={10}>
                    <Controller
                      control={control}
                      name="area"

                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, item) => {
                            onChange(item);
                          }}
                          value={value || ""}
                          options={props?.areaData?.area || []}
                          isOptionEqualToValue={(option, value) => option === value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              placeholder="Select area"
                            />
                          )}
                        />
                      )}
                    />
                    <div className="field-error">{errors.area?.message}</div>
                  </Grid>
                )


              }

            </Grid>
            <Grid item xs={0.5} sm={4} md={0.5} className='addIcon'>
              <AddCircleIcon
                style={{ fontSize: "2rem", fill: "#00249B", marginTop: 25 }}
                onClick={() => {
                  clickArea();
                }}
              />
            </Grid>
            <Grid item xs={1.5} sm={4} md={1.9}>
              <div className="title-text-box">Sub Area </div>
              <Grid item xs={2} sm={4} md={10}>
                <Controller
                  control={control}
                  name="subarea"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value || ""}
                      options={props?.areaData?.subarea || []}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Select Sub area"
                        />
                      )}
                    />
                  )}
                />
                <div className="field-error">{errors.subarea?.message}</div>
              </Grid>
            </Grid>
            <Grid item xs={0.5} sm={4} md={0.5}>
              <AddCircleIcon
                style={{ fontSize: "2rem", fill: "#00249B", marginTop: 25 }}
                onClick={() => {
                  clickSubArea();
                }}
              />
            </Grid>
          </Grid>

          {isShowArea && (
            <Grid
              container
              columns={{ xs: 1.5, sm: 8, md: 12 }}
              className="subEditmargin"
            >
              <Grid item xs={2} sm={4} md={2.4}>
                <Grid item xs={2} sm={4} md={10}></Grid>
              </Grid>
              <Grid item xs={2} sm={4} md={2.4}>
                <Grid item xs={2} sm={4} md={10}></Grid>
              </Grid>
              <Grid item xs={2} sm={4} md={2.4}>
                <Grid item xs={2} sm={4} md={10}></Grid>
              </Grid>
              {
                watch("area") ? (
                  <Grid item xs={1.5} sm={4} md={1.9}>
                    <Grid item xs={1.5} sm={4} md={10}>
                      <TextField
                        {...register("areanew")}
                        disabled={true}
                        size="small"
                        className="txtwidth"
                        variant="outlined"
                        id="outlined-basic"
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={1.5} sm={4} md={1.9}>
                    <Grid item xs={1.5} sm={4} md={10}>
                      <TextField
                        {...register("areanew")}
                        size="small"
                        className="txtwidth"
                        variant="outlined"
                        id="outlined-basic"
                      />
                    </Grid>
                  </Grid>
                )
              }

              <Grid item xs={0.5} sm={4} md={0.5}>
                <CancelIcon
                  style={{ fontSize: "2rem", fill: "#00249B" }}
                  onClick={() => {
                    clickArea();
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={1.9}></Grid>
              <Grid item xs={2} sm={4} md={0.5}></Grid>
            </Grid>
          )}

          {isShowSubArea && (
            <Grid
              container
              columns={{ xs: 1.5, sm: 8, md: 12 }}
              className="subEditmargin"
            >
              <Grid item xs={2} sm={4} md={2.4}>
                <Grid item xs={2} sm={4} md={10}></Grid>
              </Grid>
              <Grid item xs={2} sm={4} md={2.4}>
                <Grid item xs={2} sm={4} md={10}></Grid>
              </Grid>
              <Grid item xs={2} sm={4} md={2.4}>
                <Grid item xs={2} sm={4} md={10}></Grid>
              </Grid>
              <Grid item xs={2} sm={4} md={1.9}></Grid>
              <Grid item xs={2} sm={4} md={0.5}></Grid>
              <Grid item xs={2} sm={4} md={1.9}>
                <Grid item xs={2} sm={4} md={10}>

                  {
                    watch("subarea") ? (
                      <Grid item xs={1.5} sm={4} md={12}>

                        <TextField
                          {...register("subareanew")}
                          disabled={true}
                          size="small"
                          className="txtwidth"
                          variant="outlined"
                          id="outlined-basic"
                        />

                      </Grid>
                    ) : (
                      <Grid item xs={1.5} sm={4} md={12}>

                        <TextField
                          {...register("subareanew")}
                          size="small"
                          className="txtwidth"
                          variant="outlined"
                          id="outlined-basic"
                        />

                      </Grid>
                    )
                  }

                </Grid>
              </Grid>
              <Grid item xs={0.5} sm={4} md={0.5}>
                <CancelIcon
                  style={{ fontSize: "2rem", fill: "#00249B" }}
                  onClick={() => {
                    clickSubArea();
                  }}
                />
              </Grid>
            </Grid>
          )}

          <Grid
            container
            columns={{ xs: 2, sm: 8, md: 12 }}
            className="subEditmargin"
          >
            <Grid item xs={2} sm={4} md={2.4}>
              <div className="title-text-box">City</div>
              <Grid item xs={2} sm={4} md={10}>
                <TextField
                  {...register("city")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
                <div className="field-error">{errors.city?.message}</div>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={4} md={2.4}>
              <div className="title-text-box">State</div>
              <Grid item xs={2} sm={4} md={10}>
                <TextField
                  {...register("state")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
                <div className="field-error">{errors.state?.message}</div>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={4} md={2.4}>
              <div className="title-text-box">Landmark</div>
              <Grid item xs={2} sm={4} md={10}>
                <TextField
                  {...register("landmark")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
              </Grid>
            </Grid>
            <Grid item xs={2} sm={4} md={2.4}>
              <div className="title-text-box">Pincode</div>
              <Grid item xs={2} sm={4} md={10}>
                <TextField
                  {...register("pincode")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
                <div className="field-error">{errors.Pincode?.message}</div>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={4} md={2.4}>
              <div className="title-text-box">Tax Applied</div>
              <Grid item xs={2} sm={4} md={10}>
                <Controller
                  control={control}
                  name="tax"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value || ""}
                      options={taxApply || []}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Select Tax"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            columns={{ xs: 2, sm: 8, md: 12 }}
            className="subEditmargin"
          >
            <Grid item xs={2} sm={4} md={2.4}>
              <div className="title-text-box">MSO ID</div>
              <Grid item xs={2} sm={4} md={10}>
                <TextField
                  {...register("msoid")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
              </Grid>
            </Grid>


            <Grid item xs={2} sm={4} md={2.4}>
              <div className="title-text-box">Assigned to</div>
              <Grid item xs={2} sm={4} md={10}>
                <Controller
                  control={control}
                  name="assignTo"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value || ""}
                      options={props?.employeeData || []}
                      isOptionEqualToValue={(option, values) =>
                        option.name === values.name
                      }
                      getOptionLabel={(item) =>
                        item.name ? ` ${item.name}` : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Select Assign To"
                        />
                      )}
                    />
                  )}
                />
                <div className="field-error">{errors.assignTo?.message}</div>
              </Grid>
            </Grid>
            {
              props?.data?.service_type === "internet" &&
              (

                <Grid item xs={2} sm={4} md={2.4}>
                  <div className="title-text-box">Login IP</div>
                  <Grid item xs={2} sm={4} md={10}>
                    <TextField

                      {...register("lohinIp")}
                      size="small"
                      className="txtwidth"
                      variant="outlined"
                      id="outlined-basic"
                    />
                  </Grid>
                </Grid>



              )
            }




            <Grid item xs={2} sm={4} md={2.4}>
              <div className="title-text-box">Auto bill-generation</div>
              <Grid item xs={2} sm={4} md={10}>
                <Controller
                  control={control}
                  name="bill"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value || ""}
                      options={autoBill || []}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Select Bill"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
            <Grid item xs={12}>
              <Stack spacing={2} direction="row" className="btnright editUpdate">
                <Button
                  type="submit"
                  variant="contained"
                  className="bg-blue"
                  style={{ textTransform: "none" }}
                >
                  {"Update"}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  );
}
