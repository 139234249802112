import AddEmployee from "@components/AddEmployeeForm";
import Grid from "@mui/material/Grid";
import PersonIcon from "@mui/icons-material/Person";
import "./styles.scss";
import { distEmployeeList, addEmployee, updateEmployee } from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "@reducers/employee";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  useComponentDidMount,
  paramsQuery,
  useComponentDidUpdate,
  useComponentWillUnmount,
} from "@utils/useEffectHooks";
import BreadCrumb from "@components/Breadcrumb";


export default function AddEmployees(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { employeeAdded, distEmployeeListData, employeeUpdate } = useSelector(
    (state) => state.employee
  );
  let filtered =
    distEmployeeListData &&
    distEmployeeListData?.data?.filter(
      (data) => data._id === paramsQuery("id")
    );

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;

    return query;
  };


  useComponentDidMount(() => {
    let id = paramsQuery("id");
    let user = JSON.parse(localStorage.getItem("user"));

    if (id) return
    dispatch(distEmployeeList());
  }, []);

  useComponentDidUpdate(() => {
    let id = paramsQuery("id");
    let message = "";
    if (employeeAdded.msg === "Added Successfully") {

      message = "Employee Added";

      enqueueSnackbar(message, {
        variant: "success",
      });
      handleBreadCrumbRedirect("employee-list")
    }
    if (employeeAdded.msg === "Employee Id Already exist") {
      message = "Employee Id Already exist";
      enqueueSnackbar(message, {
        variant: "error",
      });

    }

    if (employeeUpdate.msg === "Updated Successfully") {
      message = "Employee Updated";
      enqueueSnackbar(message, {
        variant: "success",
      });
      handleBreadCrumbRedirect("employee-list")
    }

    if (employeeUpdate.msg === "Employee Id Already Exist") {
      message = "Employee Id Already exist";
      enqueueSnackbar(message, {
        variant: "error",
      });
    }

  }, [employeeAdded, employeeUpdate]);

  useComponentWillUnmount(() => {
    dispatch(reset());
  });

  const handleFormValues = (values, type, permissionvalue) => {
    let id = paramsQuery("id");
    let user = JSON.parse(localStorage.getItem("user"));
    let dist_id = user?.distributor_ref_id;
    let permision = {

    };

    let payload = {
      cust_access: values?.custAccess?.value ,
      designation: "",
      distributor_ref_id: dist_id,
      email_id: values.email,
      emp_id: values.employeeId,
      mobile_no: values.mobile,
      name: values.name,
      permission: {},
      role: "",
      sms_access : user?.sms_access,
      whatsapp_access: user?.whatsapp_access,
    };

    if (type === "admin") {
      payload["role"] = "distributor_access";
      payload["designation"] = "distributor_access";
      payload["discount_access"] = values?.discountAccess?.value;
      payload["permission"] = permision;
    } else if (type === "collectionagent") {
      payload["role"] = "payments";
      payload["designation"] = "payments";
      payload["discount_access"] = values?.discountAccess?.value;
    } else if (type === "SubAdmin") {
      payload["role"] = "support";
      payload["designation"] = "support";
      payload["permission"] = permissionvalue;
    } else if (type === "services") {
      payload["role"] = "services";
      payload["designation"] = "services";
      payload["discount_access"] = values?.serviceDiscount?.value;
    }
    if (id) {
      payload["_id"] = id;
      dispatch(updateEmployee(payload));
    } else {
      dispatch(addEmployee(payload));
    }
  };

  const handleBreadCrumbRedirect = (path) => {
    let user = JSON.parse(localStorage.getItem("user"));

    if (user.role === "admin") {
      navigate(`/admin/${path}`);
    }
    else {
      navigate(`/distributor/${path}`);
    }

  };

  return (
    <>
      <div className="ticketList-container">
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <div className="planHeading">
              <PersonIcon className="addEmployee-icon" />
              {paramsQuery("id") ? <span>Edit Employee</span> : <span>New Employee</span>}
            </div>
          </Grid>
          <Grid item xs={12} md={4} className="flex-end" style={{marginBottom:'1rem'}}>
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              secondaryHeading="Employee List"
              handleSecondaryClick={() => handleBreadCrumbRedirect("employee-list")}
              activeHeading={paramsQuery("id") ? "Edit Employee" : "Add Employee"}

            />
          </Grid>
        </Grid>
        <AddEmployee
          handleFormValues={(values, type, permission) =>
            handleFormValues(values, type, permission)
          }
          data={filtered && filtered[0]}
        />
      </div>
    </>
  );
}
