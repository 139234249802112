import "./styles.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchWithButtons from "@components/SearchWithButtons";
import { useComponentDidMount, useComponentDidUpdate } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { billingList, deleteRceipt } from "@reducers/api";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSnackbar } from "notistack";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import Grid from "@mui/material/Grid";
import BreadCrumb from "@components/Breadcrumb";
import { reset } from "@reducers/billing";


export default function InvoiceList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { billingListData, invoiceDeleted } = useSelector(
    (state) => state.billing
  );
  const [billList, setbillList] = useState([]);
  const [invoiceListExport, setInvoiceListExport] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&billing_status=open,success`;
    if (value?.type === "search") {
      query += `&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };

  const [searchQuery, setSearchQuery] = useState("");

  const [page, setPage] = useState(1);

  const status = (color, text) => {
    return (
      <div className="status-btn-table" style={{ backgroundColor: color }}>
        {text}
      </div>
    );
  };


  useComponentDidMount(() => {
    let query = queryGenerator();
    dispatch(billingList(query));
  }, []);


  useComponentDidUpdate(() => {
    let list = [];
    let listForExport = [];
    billingListData?.data?.map((data, index) => {
      list.push({
        sNo: (page - 1) * 10 + index + 1,
        customerName: data?.customerData.name,
        accountid: data?.customerData.account_id,
        msqid: data?.customerData?.mso_id,
        stbno: data?.stb_no || data?.customerData?.stb_no[0]?.stb_no,
        customerEmail: data?.customerData?.email_id,
        subarea: data?.customerData?.subarea || "-",
        flatno: data?.customerData?.flat_no || "-",
        billAmount: data?.bill_amount,
        collectionAmount: data?.collection_amount,
        dueAmount: data?.due_amount,
        discount: data?.discount_amount,
        paymentType: data?.payment_type,
        serviceType: data?.service_type,
        status: status("#73ca72", data?.billing_status),
        paidBy: data?.customerData?.collected_by,
        completedDate: moment(data?.updated_at).format("MMMM Do YYYY, h:mm a"),
        createdDate: moment(data?.created_at).format("MMMM Do YYYY, h:mm a"),
        billing_status: data?.billing_status,
        payment_status: data?.payment_status,
        action: (
          <div className="flex-row justify-center">
            <div
              className="cur-pointer"
              onClick={() =>
                dispatch(
                  deleteRceipt({
                    invoice_id: data?.order_id,
                  })
                )
              }
            >
              <DeleteIcon />
            </div>
          </div>
        ),
      });
      listForExport.push({
        sNo: (page - 1) * 10 + index + 1,
        customerName: data?.customerData.name,
        accountid: data?.customerData.account_id,
        msqid: data?.customerData?.mso_id,
        customerEmail: data?.customerData?.email_id,
        subarea: data?.customerData?.subarea || "-",
        flatno: data?.customerData?.flat_no || "-",
        billAmount: data?.bill_amount,
        collectionAmount: data?.collection_amount,
        dueAmount: data?.due_amount,
        discount: data?.discount_amount,
        paymentType: data?.payment_type,
        serviceType: data?.service_type,
        paidBy: data?.customerData?.collected_by,
        completedDate: moment(data?.updated_at).format("MMMM Do YYYY, h:mm a"),
        createdDate: moment(data?.created_at).format("MMMM Do YYYY, h:mm a"),
        billing_status: data?.billing_status,
        payment_status: data?.payment_status,

      });
    });

    setbillList(list);
    setInvoiceListExport(listForExport);
  }, [billingListData]);

  useComponentDidUpdate(() => {
    if (invoiceDeleted) {
      enqueueSnackbar("Invoice Deleted !", {
        variant: "success",
      });
      let query = queryGenerator();
      dispatch(billingList(query));
      dispatch(reset());
    }
  }, [invoiceDeleted]);


  const handleRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    let query = queryGenerator({ type: "search", state: search });
    dispatch(billingList(query));
  };

  const handleChange = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(billingList(`distributor_ref_id=${user?.distributor_ref_id}&billing_status=open,success&limit=10&offset=${value - 1}0${searchQuery ? `&value=${searchQuery}` : ""}`));
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  return (
    <div className="invoiceList-container ticketList-container">
      <Grid container spacing={2}>
        <Grid item xs={8}>
        </Grid>
        <Grid item xs={12} md={4} className="flex-end">
          <div className="invoiceList-title">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              secondaryHeading="Collection List"
              handleSecondaryClick={() => handleBreadCrumbRedirect("collection-list")}
              activeHeading="Invoice List"
              handleActiveClick={() => handleBreadCrumbRedirect("invoice-list")}
            />
          </div>

        </Grid>
      </Grid>
      <div className="ticket-list-table-cntr">
        <div className="invoiceList-title">Invoice List</div>
        <div className="search-btns-padding">
          <SearchWithButtons
            primaryButton={{
              title: "Paid Details",
              type: "contained",
              color: "white",
              bgColor: COLORS.primary,
            }}
            secondaryButton={{
              title: "Unpaid Details",
              type: "contained",
              color: "white",
              bgColor: COLORS.primary,
            }}
            secondryExtraButton={{
              title: "Export",
              type: "outlined",
              color: COLORS.primary,
              filename: "Invoice list",
              data: invoiceListExport,
            }}
            handlePrimaryClick={() => handleRedirect("paid-list")}
            handleSecondaryClick={() => handleRedirect("unpaid-list")}
            handleChange={(e) => handleSearch(e)}
          />
        </div>
        <div style={{ fontWeight: 'bold', fontSize: '15px', paddingBottom: '10px' }}>Showing entries {(page - 1) * 10 + 1} - {Math.ceil(billingListData?.totalCount / 10) === page ? billingListData?.totalCount : page + "0"} of {billingListData?.totalCount}</div>
        <TableView rows={tableRows} data={billList} color={COLORS.primary} bilingTable={true}/>
        <div style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}>
          <Pagination
            count={Math.ceil(billingListData?.totalCount / 10) || 0}
            defaultPage={1}
            page={page}
            onChange={(event, value) => {
              setPage(value);
              handleChange(value);
            }}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
}
