import "./styles.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ResetPasswordPopup from "@components/Modals/resetPasswordPopup";
import SearchWithButtons from "@components/SearchWithButtons";
import { useNavigate } from "react-router-dom";
import {
  useComponentDidMount,
  useComponentDidUpdate,
  useComponentWillUnmount,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import {
  distEmployeeList,
  deleteEmployee,
  employeeActive,
  getEmployeeCount,
  resetPwdPopup
} from "@reducers/api";
import { useState } from "react";
import Pagination from "@mui/material/Pagination";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/employee";

import Grid from "@mui/material/Grid";
import TicketStatus from "@components/TicketStatus";
import BreadCrumb from "@components/Breadcrumb";
import moment from "moment";

const ticketStatusData = [
  {
    id: 1,
    title: "Active",
    count: 30,
    icon: "",
  },
  {
    id: 2,
    title: "In Active",
    count: 28,
    icon: "",
  },
  {
    id: 3,
    title: "Total",
    count: 13,
    icon: "",
  },
];

export default function EmployeeList(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    distEmployeeListData,
    updatedMsg,
    employeeDeleted,
    getEmployeeCountData,
    resetPwdPopupData
  } = useSelector((state) => state.employee);
  const [empList, setEmpList] = useState([]);
  const [empListExport, setEmpListForExport] = useState([]);
  const [page, setPage] = useState(1);
  const [empListData, setEmpListData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showReset, setShowReset] = useState(false);

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = user?.distributor_ref_id ? `distributor_ref_id=${user?.distributor_ref_id}` : ""
 
    if (value?.type === "search") {
      query += `&limit=10&offset=0&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };

  const handleRedirect = () => {
    
    let userType = JSON.parse(localStorage.getItem("user"));
    let roleType = userType?.role
    if (roleType === "admin") {
      navigate(`/admin/add-dist-employee`);
    } else if (roleType === "distributor") {
      navigate(`/distributor/add-dist-employee`);
    } else if (roleType === "support"){
      navigate(`/distributor/add-dist-employee`);
    }
  };

  const handleEditRedirect = (data) => {
    
    let userType = JSON.parse(localStorage.getItem("user"));
    let roleType = userType?.role
    if (roleType === "admin") {
      navigate(`/admin/add-dist-employee?id=${data}`);
    } else if (roleType === "distributor") {
      navigate(`/distributor/add-dist-employee?id=${data}`);
    } else if (roleType === "support"){
      navigate(`/distributor/add-dist-employee?id=${data}`);
    }
  };

  const onShowResetPasswordPopUp = (data) => {
    setEmpListData(data);
    setShowReset(true);

  };

  const closepoup = () => {
    setShowReset(false);
  }

  useComponentDidMount(() => {
    let query = queryGenerator();
    dispatch(distEmployeeList(query));
    dispatch(getEmployeeCount(query));
  }, []);

  const updateSubmit = (values,props) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      email_id:props?.empData?.email_id,
      new_password: values?.newPassword,
      account_id: props?.empData?.account_id,
      emp_ref_id : props?.empData?._id,
      sms_access: user?.sms_access,
      whatsapp_access: user?.whatsapp_access,
    };
    dispatch(resetPwdPopup(payload));
  };

  useComponentDidUpdate(() => {
    let query = queryGenerator();
    if (updatedMsg) {
      enqueueSnackbar("Status Updated", {
        variant: "success",
      });
      dispatch(reset());
      dispatch(getEmployeeCount(query));
      dispatch(distEmployeeList(query));
    }
  }, [updatedMsg]);

  useComponentDidUpdate(() => {
    let query = queryGenerator();
    if (employeeDeleted) {
      enqueueSnackbar("Employee Deleted !", {
        variant: "success",
      });
      dispatch(reset());
      setPage(1);
      dispatch(getEmployeeCount(query));
      dispatch(distEmployeeList(query));
    }
  }, [employeeDeleted]);

  const updateStatus = (id, status) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      _id: id,
      is_active: status,
      sms_access : user?.sms_access,
      whatsapp_access: user?.whatsapp_access,
    };
    dispatch(employeeActive(payload));
  };

  useComponentWillUnmount(() => {
    dispatch(reset());
  });

  useComponentDidUpdate(() => {
    let list = [];
    let listForExport=[];
    distEmployeeListData?.data.map((data, index) => {
      list.push({
        sNo: (page - 1) * 10 + index + 1,
        employeeId: data?.emp_id,
        employeeName: data?.name,
        accountId: data?.account_id,
        created: moment(data?.created_at).format(" MMMM Do YYYY, h:mm a"),
        mobile: data?.mobile_no,
        email: data?.email_id,
        role: data?.role,
       
        status: data?.is_active ? (
          <div
            className="status-btn-table-active"
            onClick={() => updateStatus(data?._id, false)}
          >
            Active
          </div>
        ) : (
          <div
            className="status-btn-table-inactive"
            onClick={() => updateStatus(data?._id, true)}
          >
            InActive
          </div>
        ),
        action: (
          <div className="flex-row justify-center">
            <div
              onClick={() => handleEditRedirect(data._id)
                // navigate(`/distributor/add-dist-employee?id=${data._id}`)
              }
              className="cursor-pointer"
            >
              <EditIcon />
            </div>
            <div
              className="cursor-pointer"
              onClick={() =>
                dispatch(
                  deleteEmployee({
                    _id: data?._id,
                  })
                )
              }
            >
              <DeleteIcon />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => onShowResetPasswordPopUp(data)}
            >
              <RestartAltIcon />
            </div>
          </div>
        ),
      });


      listForExport.push({
        sNo: (page - 1) * 10 + index + 1,
        employeeId: data?.emp_id,
        employeeName: data?.name,
        accountId: data?.account_id,
        mobile: data?.mobile_no,
        email: data?.email_id,
        status: data?.is_active === true ? "Active" : "Inactive",
        role: data?.role,   

      });
      
    });

    setEmpList(list);
    setEmpListForExport(listForExport);
  }, [distEmployeeListData]);

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    let query = queryGenerator({ type: "search", state: search });
    dispatch(distEmployeeList(query));
  };

  const handleChange = (value) => {
    let query = queryGenerator({ type: "offset", state: value });
    dispatch(distEmployeeList(query));
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  useComponentDidUpdate(() => {
    if (resetPwdPopupData) {
      enqueueSnackbar("Reset Password Successfully", {
        variant: "success",
      });   
      closepoup();
      dispatch(reset());
    }
  }, [resetPwdPopupData]);

  return (
    <div className="employeeList-container ticketList-container">

      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <div className="employeelist-title">Employee</div>
        </Grid>
        <Grid item xs={12} md={4} className="flex-end">
          <div className="employeelist-title">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              activeHeading="Employee List"
             
            />
          </div>

        </Grid>
      </Grid>
      <div>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={2.4}
            style={{ paddingLeft: 0, paddingBottom: 0 }}
          >
            <TicketStatus
              className=" ticketCardGreen"
              title={ticketStatusData[0].title}
              count={getEmployeeCountData?.activeEmployee || "0"}
              icon={ticketStatusData[0].icon}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={2.4}
            style={{ paddingLeft: 0, paddingBottom: 0 }}
          >
            <TicketStatus
              className=" ticketCardRed"
              title={ticketStatusData[1].title}
              count={getEmployeeCountData?.inactiveEmployee || "0"}
              icon={ticketStatusData[1].icon}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={2.4}
            style={{ paddingLeft: 0, paddingBottom: 0 }}
          >
            <TicketStatus
              className=" ticketCardBlue"
              title={ticketStatusData[2].title}
              count={getEmployeeCountData?.totalEmployee || "0"}
              icon={ticketStatusData[2].icon}
            />
          </Grid>
        </Grid>
      </div>
      <div className="ticket-list-table-cntr">
        <div className="search-btns-padding">
          <SearchWithButtons
            primaryButton={{
              title: "Add Employee",
              type: "contained",
              color: "white",
              bgColor: COLORS.primary,
            }}
            secondaryButton={{
              title: "Export",
              type: "outlined",
              color: COLORS.primary,
              filename:"Employee",
              data: empListExport,
            }}
            handlePrimaryClick={handleRedirect}
            // handleSecondaryClick={}
            handleChange={handleSearch}
          />
        </div>
        <div style={{ fontWeight: 'bold', fontSize: '15px',paddingBottom: '10px'}}>Showing entries { (page - 1) * 10 + 1 } -         
        {Math.ceil(distEmployeeListData?.totalCount / 10)==page?distEmployeeListData?.totalCount:page+"0"} of {distEmployeeListData?.totalCount}</div>
        <TableView rows={tableRows} data={empList} color={COLORS.primary} />
        <ResetPasswordPopup show={showReset} handleClose={closepoup} listData={props?.data} empData={empListData} submitData={updateSubmit}  />
        <div
          style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}
        >
          <Pagination
            count={Math.ceil(distEmployeeListData?.totalCount / 10) || 0}
            defaultPage={1}
            page={page}
            onChange={(event, value) => {
              setPage(value);
              handleChange(value);
            }}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}
