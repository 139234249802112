import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "@reducers/api";
import { reset } from "@reducers/auth";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Grid, TextField } from "@mui/material";
import logo from "../../assets/images/logo_big.png";
import "./styles.scss";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { loginSuccess, statusMessage } = useSelector((state) => state.auth);
  const [userName, setUserName] = useState("");
  const [passWord, setPassword] = useState("");

  useEffect(() => {
    if (loginSuccess) {
      let user = JSON.parse(localStorage.getItem("user"));
      enqueueSnackbar("Login Sucessfull", {
        variant: "success",
      });

      setInterval(() => {
      }, 18000000);

      setTimeout(function () {
        localStorage.clear();
        navigate('/login')
      }, 18000000);

      if (user?.role === "admin") {
        navigate("/admin/dashboard");
      } else if (user?.role === "distributor") {
        navigate("/distributor/dashboard");
      } else if (user?.role === "support") {
        navigate("/distributor/dashboard");
      } else if (user?.role === "user") {
        navigate("/customer/dashboard");
      }
    }
    return () => {
      dispatch(reset());
    };
  }, [loginSuccess, dispatch, navigate]);

  useEffect(() => {
    if (statusMessage) {
      enqueueSnackbar("Invalid AccountId/Password", {
        variant: "error",
      });
    }
    return () => {
      dispatch(reset());
    };
  }, [statusMessage]);


  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid
      className="login-container"
      container
      columns={{ xs: 2, sm: 8, md: 12 }}
    >
      <Grid item xs={2} sm={4} md={6.5}>
        <div className="sub-image-cnt-login"></div>
      </Grid>
      <Grid item xs={2} sm={4} md={5.5} justifyContent="center" display="flex">
        <div className="login-form-cnt">
          <div>
            <div className="flex-justify-center">
              <img className="logo-login" src={logo} alt="cablepay Logo" />
            </div>
            <div className="title-login">Login</div>
            <div>
              <TextField
                label="Account ID"
                name="username"
                id="username"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                variant="outlined"
                fullWidth
                size="small"
                sx={{ width: 380 }}
              />
            </div>
            <div style={{ marginTop: 40 }}>
              {/* <TextField
                label="Password"
                name="passowrd"
                id="password"
                type="Password"
                value={passWord}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                fullWidth
                size="small"
                sx={{ width: 380 }}
              /> */}


              <FormControl variant="outlined">
                {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password || passWord}
                  onChange={(e) => {
                    handleChange('password')
                    setPassword(e.target.value)
                  }}
                  size="small"
                  sx={{ width: 380 }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="Enter your Password"
                />
              </FormControl>
            </div>
            <div className="forgot-login" onClick={() => navigate("/forgot-password")}>Forgot Password ?</div>
            <button
              id="loginbtn"
              className="login-btn"
              onClick={() =>
                dispatch(
                  loginUser({ account_id: userName, password: passWord })
                )
              }
            >
              Login
            </button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
