import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./styles.scss";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { Formik } from "formik";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { tableRowsReceipt } from "./data";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  currentServiceList,
  paymentAmount,
  SubscriberListAssigned,
  allEmpList,
  subscriberView,
  payNow,
  paymentTax,
  lastPaid,
  updateBalance,
} from "@reducers/api";
import {
  useComponentDidMount,
  paramsQuery,
  useComponentDidUpdate,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import moment from "moment";
import TableView from "@components/TableView";
import { COLORS } from "@constants";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const validation = Yup.object({
  customerName: Yup.string().required("Required"),
  amount: Yup.string().required("Required"),
  // ncf: Yup.string().required("Required"),
  // channelCount: Yup.string().required("Required"),
  // validity: Yup.string().required("Required"),
  // tax: Yup.string().required("Required"),
  // discount: Yup.string().required("Required"),
  mode: Yup.object().required("Required"),
  assignedTo: Yup.object().required("Required"),
  billAmount: Yup.number().required("Required").moreThan(0),
  // dueAmount: Yup.string().required("Required"),
  // collectionAmt: Yup.string().required("Required"),
  // collectedOn: Yup.string().required("Required"),
  // remark: Yup.string().required("Required"),
});

const internetValidation = Yup.object({
  customerName: Yup.string().required("Required"),
  amount: Yup.string().required("Required"),
  mode: Yup.object().required("Required"),
  assignedTo: Yup.object().required("Required"),
  billAmount: Yup.number().required("Required").moreThan(0),
});

export default function PaymentModal(props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const {
    PlanPaymentListData,
    paymentAmtData,
    payNowAdded,
    SubscriberListAssignedData,
    accountIDData,
    subviewData,
    paymentTaxData,
    lastPaidData,
  } = useSelector((state) => state.subscriber);
  const { empFullData } = useSelector((state) => state.employee);
  const [currentService, setcurrentService] = useState([]);
  const [AccountIDData, SetAccountIDData] = useState([]);
  const [SearchClose, SetSearchClose] = useState([]);
  const [value, setValueDate] = useState(new Date());
  const [checkValue, setCheckValueDate] = useState(new Date());

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}`;
    let distributorId = user?.distributor_ref_id;
    dispatch(paymentAmount(distributorId));
    dispatch(allEmpList(query));
    dispatch(paymentTax(query));
    setValue("customerName", props?.listData?.name);

    setValue("adjustBalance", props?.listData?.wallet_balance || 0);
  }, []);

  const type = props?.listData?.service_type || subviewData.service_type;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      type === "internet" ? internetValidation : validation
    ),
  });
  const onSubmit = (data) => {
    handleFormValues(data, filtered);
  };
  let filtered = currentService?.filter(
    (serviceList) => serviceList.checked === true
  );

  let amountAdditon = filtered.reduce(function (prev, current) {
    return prev + +current.plan_amount;
  }, 0);

  let broadCastAndAlacart = filtered?.filter(
    (serviceList) =>
      serviceList.package_category === "broadcaster" ||
      serviceList.package_category === "ala cart"
  );

  let channelCountAddition = broadCastAndAlacart.reduce(function (
    prev,
    current
  ) {
    return prev + +current.channel_count;
  },
    0);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    props?.handleClose();
    reset();
    setcurrentService([]);
  };



  useEffect(() => {
    setOpen(props?.show);
    setValue("customerName", props?.listData?.name);
  }, [props?.show]);

  const doubleInputs = (titleOne, titleTwo, inputOne, inputTwo) => {
    return (
      <Grid item xs={4} sm={4} md={5}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={4} md={5.5}>
            <div className="make-payment-form-title ">{titleOne}</div>
            {inputOne}
          </Grid>
          <Grid item xs={4} sm={4} md={1}></Grid>
          <Grid item xs={4} sm={4} md={5.5}>
            <div className="make-payment-form-title ">{titleTwo}</div>
            {inputTwo}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const mode = [
    { label: "Cash", id: 1, value: "cash" },
    { label: "Cheque", id: 2, value: "cheque" },
    { label: "Card", id: 3, value: "card" },
    { label: "Free", id: 3, value: "free" },
  ];



  const filterObject = (title, options, stb_no) => {
    if (stb_no === "" || stb_no === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options.stb_no.toString() === stb_no.toString()
      );
    setValue(title, filtered[0]);
  };

  useComponentDidUpdate(() => {

    setValue("discount", 0);
    filterObject("mode", mode, "");
    filterObject("assignedTo", props?.emplist, "");

    setValue("collectionAmt", 0);
    setValue("remark", "");
    setValue("collectedOn", value);
    setValue("amount", props?.listData?.due_amount);
    setValue("billAmount", props?.listData?.bill_amount || 0 || props?.listData?.due_amount);
    setValue("emailId", props?.listData?.email_id)

  }, [props]);

  useComponentDidUpdate(() => {
    setValue("customerName", props?.listData?.customerData?.name || props?.listData?.name);

  }, [props?.listData]);


  let Billing = watch("billAmount");



  if (isNaN(Billing)) {
    Billing = 0;
    setValue("billAmount", Billing);
  }


  useComponentDidUpdate(() => {
    let list = [];
    PlanPaymentListData &&
      PlanPaymentListData.map((data, index) => {
        list.push({
          plan_name: data?.plan_name,
          package_category: data?.package_category,
          channel_count:
            (data?.channels && data?.channels.length) ||
            data?.channel_count ||
            "0",
          plan_amount: data?.plan_amount,
          expired_date: moment(data?.expired_date).format("ll"),
          stb_no: data?.stb_no,
          activate_date: data?.activate_date,
          billing_cycle: data?.billing_cycle,
          billing_type: data?.billing_type,
          can_id: data?.can_id,
          channels: data?.channels,
          cust_email_id: data?.cust_email_id,
          cust_ref_id: data?.cust_ref_id,
          disabled: data?.disabled,
          distributor_ref_id: data?.distributor_ref_id,
          cust_name: data?.cust_name,
          is_active: data?.is_active,
          is_deleted: data?.is_deleted,
          mso: data?.mso,
          plan_ref_id: data?.plan_ref_id,
          plan_validity: data?.plan_validity,
          service_type: data?.service_type,
          stb_type: data?.stb_type,
          _id: data?._id,
          __v: data?.__v,
        });
      });

    setcurrentService(list);
  }, [PlanPaymentListData]);

  const handleCheckBox = (index, checked) => {
    const clonedDataService = [...currentService];
    if (index === 0 || index > 0) {
      clonedDataService[index].checked = clonedDataService[index].checked
        ? false
        : true;
    } else if (index === "all") {
      for (let services of clonedDataService) {
        if (checked) {
          services.checked = true;
        } else {
          services.checked = false;
        }
      }
    }

    setcurrentService(clonedDataService);

  };

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&value=${value}`;

    return query;
  };

  useComponentDidUpdate(() => {
    if (SubscriberListAssignedData) {
      SetAccountIDData(SubscriberListAssignedData);
    }
  }, [SubscriberListAssignedData]);

  useEffect(() => {
    if (accountIDData) {
      SetSearchClose(accountIDData);
    }
  }, [accountIDData]);


  const handleFormValues = (values, filteredarray) => {
    let filteredBroadcast = filteredarray?.filter(
      (serviceList) => serviceList.package_category === "broadcaster"
    );

    let id = paramsQuery("id") || subviewData?._id || props?.listData._id;
    let dist_id = JSON.parse(localStorage.getItem("user"));

    let billAmountCalc = values.billAmount - values.discount


    let dueAmtPayload = billAmountCalc > values.amount ? billAmountCalc - values.amount : 0;

    let advanceAmnt = billAmountCalc < values.amount ? billAmountCalc - values.amount : 0;

    let payload = {
      cust_plan_ref_id: props?.listData?.customerPlanData || [],
      bill_amount: values.billAmount,
      maintenance_amount: props?.listData?.maintenance_amount || 0,
      alacart_extra_amount: 1233,
      discount_amount: values.discount,
      stb_no: values?.stb?.stb_no || "",
      collected_by: values.assignedTo?.name,
      collection_amount: values.amount,
      collected_date: values?.collectedOn,
      due_amount: dueAmtPayload || 0,
      advance_amount: Math.abs(advanceAmnt),
      add_on_service_amount: 0,
      show_bill_amount: values.billAmount,
      tax: props?.listData?.tax,

      cust_ref_id: {
        _id: props?.listData?.customerData?._id,
        name: values.customerName,
        email_id: props?.listData?.customerData?.email_id,
      },
      checque_bank_name: values.bankName || "",
      checque_date: values.chequeDate || "",
      checque_no: values.chequeNo || "",
      bill_no: values.billNo,
      invoice_id: props?.listData?.order_id,
      transaction_id: props?.listData?.order_id,
      order_id: props?.listData?.order_id,
      payment_type: values?.mode?.value || "",
      distributor_ref_id: dist_id.distributor_ref_id,
      package_amount: props?.listData?.package_amount,
      broadcaster_amount: props?.listData?.broadcaster_amount,
      ala_cart_amount: props?.listData?.ala_cart_amount,
      billing_type: props?.listData?.billing_type,
      service_type: props?.listData?.service_type,
      payby_id: values.assignedTo?._id,
      remarks: values?.remark,
      primaryStbNo: props?.listData?.customerData?.stb_no[0]?.stb_no,
      sms_access: dist_id?.sms_access,
      whatsapp_access: dist_id?.whatsapp_access,
      paynow: true
    };


    console.log(dueAmtPayload, "dueeeee")
    let invoiceid = "#" + new Date().getTime();

    let payloadOutstand = {
      cust_plan_ref_id: [],
      bill_amount: values.billAmount,
      maintenance_amount: 0,
      alacart_extra_amount: 1233,
      discount_amount: 0,
      stb_no: "",
      collected_by: values.assignedTo?.name,
      collection_amount: values.amount,
      collected_date: values?.collectedOn,
      due_amount: dueAmtPayload || 0,
      advance_amount: Math.abs(advanceAmnt),
      add_on_service_amount: 0,
      show_bill_amount: values.billAmount,
      tax: 0,

      cust_ref_id: {
        _id: props?.listData?._id,
        name: values.customerName,
        email_id: props?.listData?.email_id,
      },
      checque_bank_name: values.bankName || "",
      checque_date: values.chequeDate || "",
      checque_no: values.chequeNo || "",
      bill_no: values.billNo,
      invoice_id: invoiceid,
      transaction_id: invoiceid,
      order_id: invoiceid,
      payment_type: values?.mode?.value || "",
      distributor_ref_id: dist_id.distributor_ref_id,
      package_amount: 0,
      broadcaster_amount: 0,
      ala_cart_amount: 0,
      billing_type: props?.listData?.billing_type,
      service_type: props?.listData?.service_type,
      payby_id: values.assignedTo?._id,
      remarks: values?.remark,
      primaryStbNo: "",
      sms_access: dist_id?.sms_access,
      whatsapp_access: dist_id?.whatsapp_access,
      paynow: true
    };

    props?.isoutstand ? (
      dispatch(payNow(payloadOutstand))
    ) : (
      dispatch(payNow(payload))
    )



    reset();
  };

  const handleChange = (newDate) => {
    setValueDate(newDate);
    setValue("collectedOn", newDate);
  };

  const handleChequeChange = (newValue) => {
    setCheckValueDate(newValue);
    setValue("chequeDate", newValue);
  };

  const totalAmount = () => {
    if (watch("validity") && watch("validity").value === 1) {
      let validationCalc = amountAdditon * 1;
      let maintananceCalc = paymentAmtData?.maintenance_amount * 1;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      if (channelCountAddition < 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount;
        let ncfCalculation = ncfAmoutAdd * 1;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 2;
        let ncfCalculation = ncfAmoutAdd * 2;
        setValue("ncf", ncfCalculation);
      }
      if (channelCountAddition > 50) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 3;
        let ncfCalculation = ncfAmoutAdd * 2;
        setValue("ncf", ncfCalculation);
      }
      if (channelCountAddition > 75) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 4;
        let ncfCalculation = ncfAmoutAdd * 2;
        setValue("ncf", ncfCalculation);
      }
      if (channelCountAddition > 100) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 5;
        let ncfCalculation = ncfAmoutAdd * 2;
        setValue("ncf", ncfCalculation);
      }
      setValue("amount", validationCalc);
      setValue("maint", maintananceCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 2) {
      let validationCalc = amountAdditon * 2;
      let maintananceCalc = paymentAmtData?.maintenance_amount * 2;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      if (channelCountAddition < 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount;
        let ncfCalculation = ncfAmoutAdd * 2;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 2;
        let ncfCalculation = ncfAmoutAdd * 2;
        setValue("ncf", ncfCalculation);
      }
      if (channelCountAddition > 50) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 3;
        let ncfCalculation = ncfAmoutAdd * 2;
        setValue("ncf", ncfCalculation);
      }
      if (channelCountAddition > 75) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 4;
        let ncfCalculation = ncfAmoutAdd * 2;
        setValue("ncf", ncfCalculation);
      }
      if (channelCountAddition > 100) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 5;
        let ncfCalculation = ncfAmoutAdd * 2;
        setValue("ncf", ncfCalculation);
      }
      setValue("amount", validationCalc);
      setValue("maint", maintananceCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 3) {
      let validationCalc = amountAdditon * 3;
      let maintananceCalc = paymentAmtData?.maintenance_amount * 3;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      if (channelCountAddition < 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount;
        let ncfCalculation = ncfAmoutAdd * 3;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 2;
        let ncfCalculation = ncfAmoutAdd * 3;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 50) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 3;
        let ncfCalculation = ncfAmoutAdd * 3;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 75) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 4;
        let ncfCalculation = ncfAmoutAdd * 3;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 100) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 5;
        let ncfCalculation = ncfAmoutAdd * 3;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 4) {
      let validationCalc = amountAdditon * 4;
      let maintananceCalc = paymentAmtData?.maintenance_amount * 4;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );
      let filteredPackage = filteredarray?.filter(
        (serviceList) => serviceList.package_category === "package"
      );

      if (channelCountAddition < 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount;
        let ncfCalculation = ncfAmoutAdd * 4;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 2;
        let ncfCalculation = ncfAmoutAdd * 4;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 50) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 3;
        let ncfCalculation = ncfAmoutAdd * 4;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 75) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 4;
        let ncfCalculation = ncfAmoutAdd * 4;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 100) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 5;
        let ncfCalculation = ncfAmoutAdd * 4;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 5) {
      let validationCalc = amountAdditon * 5;
      let maintananceCalc = paymentAmtData?.maintenance_amount * 5;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      if (channelCountAddition < 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount;
        let ncfCalculation = ncfAmoutAdd * 5;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 2;
        let ncfCalculation = ncfAmoutAdd * 5;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 50) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 3;
        let ncfCalculation = ncfAmoutAdd * 5;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 75) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 4;
        let ncfCalculation = ncfAmoutAdd * 5;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 100) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 5;
        let ncfCalculation = ncfAmoutAdd * 5;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 6) {
      let validationCalc = amountAdditon * 6;
      let maintananceCalc = paymentAmtData?.maintenance_amount * 6;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      if (channelCountAddition < 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount;
        let ncfCalculation = ncfAmoutAdd * 6;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 2;
        let ncfCalculation = ncfAmoutAdd * 6;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 50) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 3;
        let ncfCalculation = ncfAmoutAdd * 6;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 75) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 4;
        let ncfCalculation = ncfAmoutAdd * 6;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 100) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 5;
        let ncfCalculation = ncfAmoutAdd * 6;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 7) {
      let validationCalc = amountAdditon * 7;
      let maintananceCalc = paymentAmtData?.maintenance_amount * 7;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      if (channelCountAddition < 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount;
        let ncfCalculation = ncfAmoutAdd * 7;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 2;
        let ncfCalculation = ncfAmoutAdd * 7;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 50) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 3;
        let ncfCalculation = ncfAmoutAdd * 7;
        setValue("ncf", ncfCalculation);
      }
      if (channelCountAddition > 75) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 4;
        let ncfCalculation = ncfAmoutAdd * 7;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 100) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 5;
        let ncfCalculation = ncfAmoutAdd * 7;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 8) {
      let validationCalc = amountAdditon * 8;
      let maintananceCalc = paymentAmtData?.maintenance_amount * 8;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      if (channelCountAddition < 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount;
        let ncfCalculation = ncfAmoutAdd * 8;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 2;
        let ncfCalculation = ncfAmoutAdd * 8;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 50) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 3;
        let ncfCalculation = ncfAmoutAdd * 8;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 75) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 4;
        let ncfCalculation = ncfAmoutAdd * 8;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 100) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 5;
        let ncfCalculation = ncfAmoutAdd * 8;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 9) {
      let validationCalc = amountAdditon * 9;
      let maintananceCalc = paymentAmtData?.maintenance_amount * 9;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      if (channelCountAddition < 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount;
        let ncfCalculation = ncfAmoutAdd * 9;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 2;
        let ncfCalculation = ncfAmoutAdd * 9;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 50) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 3;
        let ncfCalculation = ncfAmoutAdd * 9;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 75) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 4;
        let ncfCalculation = ncfAmoutAdd * 9;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 100) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 5;
        let ncfCalculation = ncfAmoutAdd * 9;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 10) {
      let validationCalc = amountAdditon * 10;
      let maintananceCalc = paymentAmtData?.maintenance_amount * 10;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      if (channelCountAddition < 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount;
        let ncfCalculation = ncfAmoutAdd * 10;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 2;
        let ncfCalculation = ncfAmoutAdd * 10;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 50) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 3;
        let ncfCalculation = ncfAmoutAdd * 10;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 75) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 4;
        let ncfCalculation = ncfAmoutAdd * 10;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 100) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 5;
        let ncfCalculation = ncfAmoutAdd * 10;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 11) {
      let validationCalc = amountAdditon * 11;
      let maintananceCalc = paymentAmtData?.maintenance_amount * 11;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      if (channelCountAddition < 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount;
        let ncfCalculation = ncfAmoutAdd * 11;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 2;
        let ncfCalculation = ncfAmoutAdd * 11;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 50) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 3;
        let ncfCalculation = ncfAmoutAdd * 11;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 75) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 4;
        let ncfCalculation = ncfAmoutAdd * 11;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 100) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 5;
        let ncfCalculation = ncfAmoutAdd * 11;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 12) {
      let validationCalc = amountAdditon * 12;
      let maintananceCalc = paymentAmtData?.maintenance_amount * 12;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      if (channelCountAddition < 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount;
        let ncfCalculation = ncfAmoutAdd * 12;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 25) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 2;
        let ncfCalculation = ncfAmoutAdd * 12;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 50) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 3;
        let ncfCalculation = ncfAmoutAdd * 12;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 75) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 4;
        let ncfCalculation = ncfAmoutAdd * 12;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      if (channelCountAddition > 100) {
        let ncfAmoutAdd = paymentAmtData?.ncf_amount * 5;
        let ncfCalculation = ncfAmoutAdd * 12;
        setValue("ncf", ncfCalculation);
        if (
          filteredarray.length === 1 &&
          filteredarray[0]?.package_category === "package"
        ) {
          setValue("ncf", "0");
        }
      }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    let planAmount = Number(watch("amount"));
    let maintanceNum = Number(watch("maint")) || 0;
    let ncfNum = Number(watch("ncf")) || 0;
    let taxCalc =
      (planAmount + ncfNum) * (parseInt(paymentTaxData?.totalTax) / 100);
    let taxAmount = Math.round(taxCalc) || 0;
    let discountAmount = Number(watch("discount")) || 0;
    let adjustBalValue = Number(watch("adjustBalance"));
    let prevDueAmount = Number(watch("billAmount"));

    const finalcalc =
      parseInt(planAmount) +
      parseInt(maintanceNum) +
      parseInt(ncfNum) +
      parseInt(taxAmount);

    let discountCal = finalcalc - discountAmount;
    let previousAmtFromApi =
      lastPaidData?.total_due_amount || props?.lastData?.total_due_amount || 0;
    let prevoiusBillAmt = discountCal + parseInt(previousAmtFromApi);
    let adjustBalAmt = prevoiusBillAmt + adjustBalValue;
    let advanceMinus =
      adjustBalAmt -
      (lastPaidData?.advance_amount || props?.lastData?.advance_amount || 0);

    // setValue("billAmount", advanceMinus);
    setValue("tax", Math.round(taxCalc));
  };

  useComponentDidUpdate(() => {
    totalAmount();
  }, [
    watch("ncf"),
    watch("maint"),
    watch("discount"),
    watch("validity"),
    amountAdditon,
    channelCountAddition,
    watch("adjustBalance"),
  ]);


  console.log(props, "propsieee")

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="makePayment-cntr payment-scroll">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <div className="payment-header-title">Make Payment</div>
              </Grid>

            </Grid>
            <div className="divider-line"></div>
            {
              props?.isoutstand ? ("") : (
                <div className="displayFlex">
                  <div className="payPink">
                    <p>
                      Invoice No{" "}

                    </p>
                    <p>
                      {props?.listData?.order_id}
                    </p>
                  </div>
                  <div className="recpayGreen">
                    <p>
                      Previous Invoice No{" "}

                    </p>
                    <p>
                      {props?.listData?.pre_order_id}
                    </p>
                  </div>
                  <div className="rectanglemakepayReport">
                    <p>
                      Previous Invoice Amount{" "}

                    </p>
                    <p>
                      {props?.listData?.pre_bill_amount}
                    </p>
                  </div>
                  <div className="payRed">
                    <p>
                      Previous Balance Amount{" "}

                    </p>
                    <p>
                      {props?.listData?.pre_due_amount}
                    </p>
                  </div>
                  <div className="payPurple">
                    <p>
                      Previous Advance Amount{" "}

                    </p>
                    <p>
                      {props?.listData?.pre_advance_amount}
                    </p>
                  </div>
                </div>
              )
            }

            {
              props?.isoutstand ? ("") :

                props?.listData?.service_type === "cable" && (
                  <Grid
                    container
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    className="padding-btw-tiles"
                  >
                    <Grid item xs={4} sm={4} md={5} className="">
                      <div className="make-payment-form-title ">Select STB</div>
                      <Controller
                        control={control}
                        name="stb"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, item) => {
                              onChange(item);
                              let custId = props?.listData?.customerData?._id;
                              let query = `${custId}&stb_no=${item?.stb_no}&is_active=1`;
                              dispatch(currentServiceList(query));
                            }}
                            value={value || ""}
                            options={
                              props?.listData?.customerData?.stb_no
                            }
                            isOptionEqualToValue={(option) =>
                              option?.stb_no === option?.value || ""
                            }
                            getOptionLabel={(item) =>
                              item.stb_no ? `${item.stb_no}` : ""
                            }
                            renderInput={(params) => (
                              <TextField {...params} size="small" />
                            )}
                          />
                        )}
                      />

                    </Grid>

                    <Grid item xs={0} sm={4} md={2}></Grid>

                    <Grid item xs={4} sm={4} md={5}></Grid>
                  </Grid>
                )}




            <Grid className='tableView'>
              {currentService.length > 0 && (
                <TableView
                  rows={tableRowsReceipt}
                  data={currentService}
                  color={COLORS.primary}
                  handleCheckBoxChange={handleCheckBox}
                />
              )}

            </Grid>


            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="padding-btw-tiles"
            >
              <Grid item xs={4} sm={4} md={5}>
                <div className="make-payment-form-title payment-title-padding">
                  Customer Name
                </div>

                <TextField
                  {...register("customerName")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
                <div className="field-error">
                  {errors.customerName?.message}
                </div>
              </Grid>
              <Grid item xs={0} sm={4} md={2}></Grid>

              <Grid item xs={4} sm={4} md={5}>
                <div className="make-payment-form-title payment-title-padding">
                  Mode
                </div>
                <Controller
                  control={control}
                  name="mode"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value || ""}
                      options={mode}
                      isOptionEqualToValue={(option) =>
                        option?.label === option?.value || ""
                      }
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  )}
                />
                <div className="field-error">{errors.mode?.message}</div>
              </Grid>
            </Grid>

            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="padding-btw-tiles"
            >
              <Grid item xs={4} sm={4} md={5}>
                <div className="make-payment-form-title payment-title-padding">
                  Amount
                </div>

                <TextField
                  {...register("amount")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
                <div className="field-error">{errors.amount?.message}</div>
              </Grid>
              <Grid item xs={0} sm={4} md={2}></Grid>
              {
                props?.isoutstand ? (
                  <Grid item xs={4} sm={4} md={5}>
                    <div className="make-payment-form-title payment-title-padding">
                      Email Id
                    </div>
                    <TextField
                      {...register("emailId")}
                      size="small"
                      disabled
                      className="txtwidth"
                      variant="outlined"
                      id="outlined-basic"
                      type="text"
                    />
                  </Grid>) : (
                  <Grid item xs={4} sm={4} md={5}>
                    <div className="make-payment-form-title payment-title-padding">
                      Discount
                    </div>
                    <TextField
                      {...register("discount")}
                      size="small"
                      className="txtwidth"
                      variant="outlined"
                      id="outlined-basic"
                      type="text"
                    />
                  </Grid>
                )
              }


            </Grid>

            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="padding-btw-tiles"
            >
              <Grid item xs={4} sm={4} md={5}>
                <div className="make-payment-form-title payment-title-padding">
                  Bill Amount
                </div>

                <TextField
                  disabled
                  {...register("billAmount")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
                <div className="field-error">{errors.billAmount?.message}</div>
              </Grid>
              <Grid item xs={0} sm={4} md={2}></Grid>

              <Grid item xs={4} sm={4} md={5}>
                <div className="make-payment-form-title payment-title-padding">
                  Collected By
                </div>
                <Controller
                  control={control}
                  name="assignedTo"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value || ""}
                      options={empFullData || []}
                      isOptionEqualToValue={(option, value) =>
                        option.name === value.name
                      }
                      getOptionLabel={(item) =>
                        item.name ? `${item.name} ` : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Select Assign To"
                        />
                      )}
                    />
                  )}
                />
                <div className="field-error">{errors.assignedTo?.message}</div>
              </Grid>
            </Grid>

            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="padding-btw-tiles"
            >
              <Grid item xs={4} sm={4} md={5}>
                <div className="make-payment-form-title payment-title-padding">
                  Collected On
                </div>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    value={value}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...register("collectedOn")}
                        placeholder="Enter Date"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={0} sm={4} md={2}></Grid>

              <Grid item xs={4} sm={4} md={5}>
                {watch("mode") && watch("mode").value === "card" && (
                  <Grid>
                    <div className="make-payment-form-title ">{"Bill No"}</div>
                    <TextField
                      {...register("billNo")}
                      size="small"
                      className="txtwidth"
                      variant="outlined"
                      id="outlined-basic"
                    />
                  </Grid>
                )}
                {watch("mode") && watch("mode").value === "cheque" && (
                  <Grid>
                    <div className="make-payment-form-title ">
                      {"Bank Name"}
                    </div>
                    <TextField
                      {...register("bankName")}
                      size="small"
                      className="txtwidth"
                      variant="outlined"
                      id="outlined-basic"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            {watch("mode") && watch("mode").value === "cheque" && (
              <div>
                <Grid
                  container
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  className="padding-btw-tiles"
                >
                  {doubleInputs(
                    "Cheque no",
                    "Cheque Date",
                    <TextField
                      {...register("chequeNo")}
                      size="small"
                      className="txtwidth"
                      variant="outlined"
                      id="outlined-basic"
                    />,
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="MM/dd/yyyy"
                        value={checkValue}
                        onChange={handleChequeChange}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            size="small"
                            {...register("chequeDate")}
                            placeholder="Enter Date"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  <Grid item xs={0} sm={4} md={2}></Grid>

                  <Grid item xs={4} sm={4} md={5}></Grid>
                </Grid>
              </div>
            )}

            <Grid container className="padding-btw-tiles">
              <div className="make-payment-form-title ">Remarks</div>
              <TextField
                {...register("remark")}
                size="small"
                className="txtwidth"
                variant="outlined"
                id="outlined-basic"
              />
            </Grid>

            <Grid container className="padding-btw-tiles flex-end">
              <Stack
                direction="row"
                className="flex-end btns--pay-stack"
                spacing={2}
              >
                <Button
                  size="small"
                  variant="outlined"
                  className="btn-outlined"
                  onClick={handleClose}
                  style={{ textTransform: "none", color: "#00249b" }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className="bg-blue"
                  style={{ textTransform: "none" }}
                  size="small"
                >
                  Pay Now
                </Button>
              </Stack>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
