import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./styles.scss";
import Button from "@mui/material/Button";
import { PaymentHistoryList } from "./data";
import TableView from "@components/TableView";
import { COLORS } from "@constants";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import { useComponentDidMount, useComponentDidUpdate } from "@utils/useEffectHooks";
import { customerProfileDetails, paymentHistoryList, othersAllService, othersAllInternet, othersAll, paymentTax } from "@reducers/api";
import numToWords from "num-to-words";
import moment from "moment";
import logo from "@assets/images/logo.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import CancelIcon from '@mui/icons-material/Cancel';
import Pdf from "react-to-pdf";

const ref = React.createRef();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "55%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};


const options = {
  orientation: 'p',
  unit: 'mm',
  format: 'a4',
  putOnlyUsedFonts:true,
  floatPrecision: 10 // or "smart", default is 16
};

const doc = new jsPDF();


const datadue = [
  {
    plan_name: "KA PREMIUM",
    mrp: "15.00",
  },

];

export default function PaymentModal(props) {
  const [planList, setplanList] = useState([]);
  const dispatch = useDispatch();
  const [collectedby, setcollectedby] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [accountID, setaccountID] = useState("");
  const [mobileNumber, setmobileNumber] = useState("");
  const [emailID, setemailID] = useState("");
  const [name, setname] = useState("");
  const [area, setarea] = useState("");
  const [vcID, setvcID] = useState("");
  const [createdat, setcreatedat] = useState("");
  const { profileData, paymentHistoryListData } = useSelector((state) => state.customer);
  const {otherAllServiceData, otherAllInternetData, otherAllData, paymentTaxData} = useSelector((state) => state.subscriber);

  let user = JSON.parse(localStorage.getItem("user"));


  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}`;
    let distributorId = `${user?.distributor_ref_id}`;
    dispatch(othersAll(distributorId));
    dispatch(paymentTax(query));
 
  }, []);

  let filteredCable = otherAllData?.filter(
    (serviceList) => serviceList.service_type === "cable"
  );

  let filteredInternet = otherAllData?.filter(
    (serviceList) => serviceList.service_type === "internet"
  );

  console.log(paymentTaxData?.totalTax, "propsss")

  useComponentDidUpdate(() => {
    setcollectedby(profileData?.customerData?.collected_by);
    setaccountID(profileData?.customerData?.account_id);
    setmobileNumber(profileData?.customerData?.mobile_no);
    setemailID(profileData?.customerData?.email_id);
    setname(profileData?.customerData?.name);
    setarea(profileData?.customerData?.area);
    setcreatedat(profileData?.customerData?.created_at || props?.data?.created_at);
    setvcID(profileData?.customerData?.stb_no[0]?.stb_no);
  }, [profileData?.customerData]);

  useComponentDidUpdate(() => {
    let prepareDataForTable = [];
    let maintananceAmt = props?.data?.service_type === "cable" ? filteredCable[0]?.maintenance_amount : filteredInternet[0]?.maintenance_amount
    console.log(maintananceAmt, "maintttttt")
    var sum = 0;
    props?.data &&
      props?.data?.selectedPlanData?.map((data, index) => {
        console.log(data, "datsd")
        prepareDataForTable.push({
          productname: data?.plan_name || data?.type,
          mrp: data?.plan_name !== 'Due_Amount' && data?.plan_name !== undefined ? (data.plan_amount * 0.18 + Number(data.plan_amount)).toFixed(2) * (props?.data?.payment_validity ? props?.data?.payment_validity : 1) + (data?.package_category === "package" ? maintananceAmt : 0) : data.plan_amount,
        });
        sum += parseFloat(data.plan_amount);
      });
    setplanList(prepareDataForTable);

    setTotalAmount(sum);
  }, [props?.data]);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    props?.handleClose();
  };

  useEffect(() => {
    setOpen(props?.show);
  }, [props?.show]);

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 12, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("payment.pdf");
    });
  };

  console.log(props, "datasss")

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">

        <Box sx={style} className="makePayment-cntr channel-list history-cntr pdfRes">
          {/* <div
            id="divToPrint"
            className="mt4 "

          > */}
          <div ref={ref}  >
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="">
              <Grid item xs={4} sm={4} md={5}>
                <div className="make-payment-form-title ">
                  {" "}
                  <img alt="logo" src={logo}></img>
                </div>
              </Grid>
              <Grid item xs={0} sm={4} md={4}></Grid>
              <Grid item xs={4} sm={4} md={3}>
                {profileData?.customerData?.created_at ? (
                  <div className="make-payment-form-title flex-end">Date : {moment(profileData?.customerData?.created_at).format("DD/MM/YYYY")} </div>
                ) : (
                  <div className="make-payment-form-title flex-end">Date : {moment(props?.data?.created_at).format("DD/MM/YYYY")} </div>

                )

                }
              </Grid>
            </Grid>
            <Divider
              sx={{ bgcolor: (theme) => theme.palette.divider }}
              style={{
                border: "none",
                height: 2,
                margin: 0,
                background: COLORS.primary,
                color: COLORS.primary,
              }}
            />
            <div className="payment-header-title textaligncenter">{profileData?.associatedDistributorData?.name}</div>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="">
              <Grid item xs={6} sm={6} md={6}>
                <div className="make-payment-form-title paddingspace">PAN: &nbsp; {user?.PAN}</div>
              </Grid>
              <Grid item xs={0} sm={6} md={6}>
                <div className="make-payment-form-title ">GSTN: &nbsp; {user?.GSTN}</div>
              </Grid>

            </Grid>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="">
              <Grid item xs={4} sm={6} md={6}>
                <div className="make-payment-form-title paddingspace">Receipt Number: &nbsp;{props?.data?.invoice_id}</div>
              </Grid>
              <Grid item xs={4} sm={6} md={6}>
                <div className="make-payment-form-title ">LCO Phone No: &nbsp; {profileData?.associatedDistributorData?.mobile_no || user?.mobile_no}</div>
              </Grid>


            </Grid>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="">


              <Grid item xs={0} sm={6} md={6}>
                <div className="make-payment-form-title paddingspace">Plan Expiration: &nbsp; {props?.data?.cust_plan_ref_id && props?.data?.cust_plan_ref_id[0]?.expired_date && moment(props?.data?.cust_plan_ref_id[0]?.expired_date).format("DD/MM/YYYY") || props?.data?.planData && moment(props?.data?.planData[0]?.expired_date).format("DD/MM/YYYY")}</div>
              </Grid>

            </Grid>
            <Grid container className="borderboxpayment">
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="">
                <Grid item xs={6} sm={6} md={6}>
                  <div className="make-payment-form-title ">Account ID: {accountID || props?.data?.customerData?.account_id}</div>
                </Grid>
                <Grid item xs={0} sm={6} md={6}>
                  <div className="make-payment-form-title ">Mobile Number: {mobileNumber || props?.data?.customerData?.mobile_no}</div>
                </Grid>

              </Grid>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="">
                <Grid item xs={6} sm={6} md={6}>
                  <div className="make-payment-form-title ">Name: {name || props?.data?.customerData?.name}</div>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <div className="make-payment-form-title ">VC ID: {vcID || props?.data?.customerData && props?.data?.customerData?.stb_no[0]?.stb_no || props?.data?.customerData?.login_ip}</div>
                </Grid>


              </Grid>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="">

                <Grid item xs={0} sm={6} md={6}>
                  <div className="make-payment-form-title ">Address: {area || props?.data?.customerData?.area}</div>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <div className="make-payment-form-title ">Email id: {emailID || props?.data?.customerData?.email_id}</div>
                </Grid>
              </Grid>
            </Grid>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="margintop20px">
              <Grid item xs={12} sm={12} md={12} className='pdfTable'>
                <TableView
                  rows={PaymentHistoryList}
                  data={planList || datadue}
                  color={COLORS.primary}
                //handleCheckBoxChange={handleCheckBox}
                />
              </Grid>
            </Grid>
            <Grid container className="" style={{ marginTop: "1rem" }}>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="">
                <Grid item xs={4} sm={4} md={5}>
                  <div className="make-payment-form-title paddingspace">Payment mode: {props?.data?.payment_type}</div>
                </Grid>
                <Grid item xs={0} sm={4} md={2}></Grid>
                <Grid item xs={4} sm={4} md={5}>
                <div className="make-payment-form-title floatEnd">Paid Amount :  {props?.data?.payment_status == "open" ? props?.data?.bill_amount : props?.data?.collection_amount}</div>
                </Grid>
              </Grid>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="">
                <Grid item xs={4} sm={4} md={7}>
                  <div className="make-payment-form-title paddingspace">Amount in words : {numToWords(props?.data?.payment_status == "open" ? props?.data?.bill_amount : props?.data?.collection_amount)}</div>
                </Grid>
                <Grid item xs={0} sm={2} md={2}></Grid>
                <Grid item xs={4} sm={6} md={3} >
                <div className="make-payment-form-title floatEnd">Due Amount : {Math.abs(props?.data?.due_amount) || 0} </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="">
              <Grid item xs={4} sm={4} md={9}>
                <div className="make-payment-form-title paddingspace">Cheque credits subject to realisastion</div>
              </Grid>
              <Grid item xs={0} sm={4} md={3}>
                <div className="make-payment-form-title floatEnd">Discount : {props?.data?.discount_amount || 0} </div>
              </Grid>
            </Grid>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="">
              
              <Grid item xs={0} sm={12} md={12}>
                <div className="make-payment-form-title floatEnd">Advance Amount : {props?.data?.advance_amount} </div>
              </Grid>
            </Grid>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="">
              
              <Grid item xs={0} sm={12} md={12}>
                <div className="make-payment-form-title floatEnd">NCF : {(props?.data?.ncf_amount * (paymentTaxData?.totalTax / 100) + props?.data?.ncf_amount ) || 0} </div>
              </Grid>
            </Grid>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="">
              
              <Grid item xs={0} sm={12} md={12}>
                <div className="make-payment-form-title floatEnd">Total MRP : {props?.data?.bill_amount} </div>
              </Grid>
            </Grid>
          
            <Grid container className="borderboxpayment">
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="">
                <Grid item xs={4} sm={4} md={8}>
                  <div className="make-payment-form-title">Registered office: {profileData?.associatedDistributorData?.name || user?.username}</div>
                </Grid>
              </Grid>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="">
                <Grid item xs={4} sm={4} md={8}>
                  <div className="make-payment-form-title ">Branch office address: {profileData?.associatedDistributorData?.address || user?.address}</div>
                </Grid>
              </Grid>
            </Grid>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="">
              <Grid item xs={4} sm={4} md={4}></Grid>
              <Grid item xs={4} sm={4} md={4}></Grid>
              <Grid item xs={4} sm={4} md={4}>
                <div className="make-payment-form-title ">Authorized signature:{""}</div>
              </Grid>
            </Grid>
          </div>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="">
            <Grid item xs={4} sm={4} md={4}></Grid>
            <Grid item xs={4} sm={4} md={4}></Grid>
            <Grid item xs={4} sm={4} md={4}>
          
              <Pdf targetRef={ref} filename="Receipt.pdf"  options={options}   x={10}>
                {({ toPdf }) =>  <Button onClick={toPdf} className="bg-blue DownloadBtn" variant="contained">
                  Download
                </Button>}
              </Pdf>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
