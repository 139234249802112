import "./styles.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchWithButtons from "@components/SearchWithButtons";
import { useNavigate } from "react-router-dom";
import {
  useComponentDidMount,
  useComponentDidUpdate,
  useComponentWillUnmount,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import {
  SubscriberListAssigned,
  deleteAssignSubs,
  allEmpList,
  assignSubscriberSubmit,
  areaList,
  updateEmployee,
} from "@reducers/api";
import { useState } from "react";
import Pagination from "@mui/material/Pagination";
import AssignSubscriberModal from "../../../components/Modals/assignSubscriberPopup";
import { useSnackbar } from "notistack";
import moment from "moment";
import { reset } from "@reducers/subscriber";
import Grid from "@mui/material/Grid";
import AssignSubscriberFilter from "@components/AssignSubscriberFilter";
import BreadCrumb from "@components/Breadcrumb";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { useForm, Controller } from "react-hook-form";

export default function AssignSubscriberList(props) {
  const {
    control,
    setValue,
    formState: { errors },
  } = useForm({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    SubscriberListAssignedData,
    deleteRes,
    assignSubscriberSubmitData,
    areaListData,
  } = useSelector((state) => state.subscriber);
  const [expList, setexpList] = useState([]);
  const [AssList, setAssList] = useState([]);
  const [billgenValue, setbillgenValue] = useState(null);
  const [AssignTovalue, setAssignTovalue] = useState(null);
  const [assignSubExport, setAssignSubExport] = useState([]);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedCount, setselectedCount] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [areaQuery, setAreaQuery] = useState("");
  const [subareaQuery, setSubAreaQuery] = useState("");
  const [assignedQuery, setAssignedQuery] = useState("");
  const [billGenerateQry, setBillGenerate] = useState("");
  const [checkedVal, setChecked] = useState(false);
  const { empFullData, employeeAdded, employeeUpdate } = useSelector(
    (state) => state.employee
  );

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;

    if (value?.type === "search") {
      query += `&limit=10&offset=0&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };
  let user = JSON.parse(localStorage.getItem("user"));
  useComponentDidMount(() => {
    let empquery = `distributor_ref_id=${user?.distributor_ref_id}`;
    let query = queryGenerator();
    dispatch(allEmpList(empquery));
    dispatch(SubscriberListAssigned(query));
    dispatch(areaList(empquery));
  }, []);

  useComponentWillUnmount(() => {
    dispatch(reset());
  });

  useComponentDidUpdate(() => {
    let query = queryGenerator();
    if (deleteRes) {
      enqueueSnackbar("Deleted !", {
        variant: "success",
      });
      dispatch(reset(query));
      dispatch(SubscriberListAssigned(query));
    }
  }, [deleteRes]);

  useComponentDidUpdate(() => {
    let query = queryGenerator();
    if ((employeeAdded, employeeUpdate)) {
      enqueueSnackbar("Updated Successfully !", {
        variant: "success",
      });
      dispatch(reset());
      dispatch(SubscriberListAssigned(query));
    }
  }, [employeeAdded, employeeUpdate]);

  useComponentDidUpdate(() => {
    let query = queryGenerator();
    if (assignSubscriberSubmitData) {
      enqueueSnackbar("Assigned successfully !", {
        variant: "success",
      });
      dispatch(reset());
      dispatch(SubscriberListAssigned(query));
      setShow(!show);
    }
  }, [assignSubscriberSubmitData]);

  useComponentDidUpdate(() => {
    let list = [];
    let listForExport = [];
    SubscriberListAssignedData?.data.map((data, index) => {
      list.push({
        sNo: (page - 1) * 10 + index + 1,
        name: data?.name,
        id: data?._id,
        accNo: data?.account_no,
        canId: data?.can_id,
        stbNo: data?.stb_no[0]?.stb_no,
        serviceType: data?.service_type,
        billingType: data?.billing_type,
        assignedemp: data?.assign_to,
        email: data?.email_id,
        area: data?.area,
        subArea: data?.subarea,
        flat: data?.flat_no,
        floor: data?.floor,
        wings: data?.wings,
        city: data?.city,
        state: data?.state,
        autobill: data?.auto_billing === true ? "Yes" : "No",
        status: moment(data?.created_at).format(" MMMM Do YYYY, h:mm a"),
        action: (
          <div className="flex-row justify-center">
            <div
              className="cur-pointer"
              onClick={() =>
                dispatch(
                  deleteAssignSubs({
                    _id: data?._id,
                    whatsapp_access: user?.whatsapp_access,
                  })
                )
              }
            >
              <DeleteIcon />
            </div>
          </div>
        ),
      });
      listForExport.push({
        SNO: (page - 1) * 10 + index + 1,
        Name: data?.name,
        Id: data?._id,
        "Account No": data?.account_no,
        "Can Id": data?.can_id,
        "Stb No": data?.stb_no[0]?.stb_no,
        "Service Type": data?.service_type,
        "Billing Type": data?.billing_type,
        Email: data?.email_id,
        "Auto Bill": data?.auto_billing === true ? "Yes" : "No",
        "Assigned To": data?.assign_to,
        Area: data?.area,
        "Sub Area": data?.subarea,
        Flat: data?.flat_no,
        Floor: data?.floor,
        City: data?.city,
        State: data?.state,
        "Created Date": moment(data?.created_at).format("ll"),
      });
    });

    setexpList(list);
    setAssignSubExport(listForExport);
  }, [SubscriberListAssignedData]);

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    dispatch(
      SubscriberListAssigned(
        `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&value=${search}`
      )
    );
  };

  const onShowPopUp = () => {
    setShow(!show);
  };

  console.log(AssignTovalue, "assigneddd")

  const handleFormValues = (values) => {
    let users = JSON.parse(localStorage.getItem("user"));
    let filtered = expList.filter((options) => options.checked === true);

    if (filtered.length == 0) {
      enqueueSnackbar("Please select atlease one subscriber !", {
        variant: "error",
      });
      setShow(!show);
      return;
    }
    let preparedeletepay = [];
    filtered.map((data, index) => {
      preparedeletepay.push({
        _id: data.id,
        auto_billing: billgenValue?.value,
        assign_to: AssignTovalue?.label,
        emp_ref_id: AssignTovalue?.id,
        sms_access: users?.sms_access,
        whatsapp_access: users?.whatsapp_access,
      });
    });
    dispatch(assignSubscriberSubmit(preparedeletepay));
    setselectedCount(0);
    setbillgenValue("");
    setAssignTovalue("");
    setChecked(false);
    setValue("billgen", "");
    setValue("assignedemp", "");
  };

  const billGen = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const handleCheckBox = (index, checked) => {
    const clonedDataService = [...expList];
    if (index === 0 || index > 0) {
      clonedDataService[index].checked = clonedDataService[index].checked
        ? false
        : true;
    } else if (index === "all") {
      for (let services of clonedDataService) {
        if (checked) {
          services.checked = true;
        } else {
          services.checked = false;
        }
      }
    }
    setexpList(clonedDataService);
    let filtered = expList.filter((options) => options.checked === true);
    setselectedCount(filtered.length);
    setChecked(checked);
  };

  const handleExtraClick = () => {
    if (AssignTovalue !== null) {
      let payload = {
        _id: AssignTovalue.id,
        cust_access: true,
      };
      dispatch(updateEmployee(payload));
    } else {
      enqueueSnackbar("Please select an employee !", {
        variant: "error",
      });
    }
    setValue("billgen", "");
    setValue("assignedemp", "");
  };

  useComponentDidUpdate(() => {
    if (empFullData) {
      let filtered = empFullData;
      let preparedeletepay = [];
      filtered.map((data) => {
        console.log(data, "empdatassss")
        preparedeletepay.push({
          label: data.name,
          value: data.name,
          id: data._id,
          email_id: data?.email_id,
        });
      });
      setAssList(preparedeletepay);
    }
  }, [empFullData]);

  const billGenChange = (item) => {
    setbillgenValue(item);
  };

  const AssignedToChange = (item) => {
    setAssignTovalue(item);
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  const handleClickSearcch = (area, subArea, assignedTo, autoBillGenerate) => {
    setPage(1);
    let userdata = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${userdata?.distributor_ref_id}&limit=10&offset=0`;
    if (area) {
      query += `&filterByArea=1&area=${area}`;
    }
    if (subArea) {
      query += `&filterBySubArea=1&subarea=${subArea}`;
    }
    if (assignedTo) {
      query += `&employee_ref_id=${assignedTo?.id}`;
    }
    if (autoBillGenerate) {
      query += `&auto_billing=${autoBillGenerate?.value}`;
    }

    dispatch(SubscriberListAssigned(query));
    setAreaQuery(area);
    setSubAreaQuery(subArea);
    setAssignedQuery(assignedTo?.id);
    setBillGenerate(autoBillGenerate?.value);
  };

  const handleChange = (value) => {
    let userList = JSON.parse(localStorage.getItem("user"));
    dispatch(
      SubscriberListAssigned(
        `distributor_ref_id=${userList?.distributor_ref_id}&limit=10&offset=${
          value - 1
        }0${searchQuery ? `&value=${searchQuery}` : ""}${
          areaQuery ? `&filterByArea=1&area=${areaQuery}` : ""
        }${subareaQuery ? `&filterBySubArea=1&subarea=${subareaQuery}` : ""}${
          assignedQuery ? `&employee_ref_id=${assignedQuery}` : ""
        }${billGenerateQry ? `&auto_billing=${billGenerateQry}` : ""}${
          billGenerateQry === false ? `&auto_billing=false` : ""
        }`
      )
    );
  };

  const handleReset = () => {
    let empquery = `distributor_ref_id=${user?.distributor_ref_id}`;
    let query = queryGenerator();
    dispatch(allEmpList(empquery));
    dispatch(SubscriberListAssigned(query));
    dispatch(areaList(query));
    setAreaQuery("");
    setSubAreaQuery("");
    setAssignedQuery("");
    setBillGenerate("");
    setPage(1);
  };

  return (
    <div>
      <AssignSubscriberModal
        show={show}
        listData={props?.data}
        handleFormValues={(values) => handleFormValues(values)}
        planList={props.planList}
        handleClose={onShowPopUp}
      />
      <div className="subsList-container">
        <Grid container spacing={2} className="">
          <Grid item xs={12} md={8}>
            <div className="employeelist-title">Assign Subscriber</div>
          </Grid>
          <Grid item xs={12} md={4} className="flex-end">
            <div className="employeelist-title">
              <BreadCrumb
                defaultHeading="Home"
                handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
                activeHeading="Assign Subscriber"
                handleActiveClick={() =>
                  handleBreadCrumbRedirect("assign-subscriber-list")
                }
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={4} md={3} className=""></Grid>
        <div className="search-btns-padding">
          <SearchWithButtons
            secondryExtraButton={{
              title: "Export",
              type: "outlined",
              color: COLORS.primary,
              filename: "Assign Subscriber",
              data: assignSubExport,
            }}
            PrimaryOption={[]}
            isViewDropdown={true}
            dropBoolean={billGen}
            dropName={AssList}
            AssignedToChange={(values) => AssignedToChange(values)}
            billgen={(values) => billGenChange(values)}
            handlePrimaryClick={onShowPopUp}
            handleExtraClick={handleExtraClick}
            handleChange={handleSearch}
            count={selectedCount || 0}
          />
        </div>

        <Grid container>
          <Grid item xs={6} md={3}>
            <div>
              <Controller
                control={control}
                name="billgen"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="small"
                    disablePortal
                    onChange={(event, item) => {
                      onChange(item);
                      billGenChange(item);
                    }}
                    value={value || ""}
                    options={billGen}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Auto bill generate"
                      />
                    )}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3} className="paddingbox">
            <div>
              <Controller
                control={control}
                name="assignedemp"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="small"
                    disablePortal
                    onChange={(event, item) => {
                      onChange(item);
                      AssignedToChange(item);
                    }}
                    value={value || ""}
                    options={AssList}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Select Assign to"
                      />
                    )}
                  />
                )}
              />
            </div>
          </Grid>

          <Grid item xs={12} md={6} className="submitDefault displayFlex">
            <div className="">
              <Button
                size="small"
                variant="outlined"
                style={{
                  borderColor: "#00249b",
                  color: "#00249b",
                  marginRight: "1rem",
                  fontSize: "11px",
                  width: "1rem",
                }}
                onClick={onShowPopUp}
              >
                Submit
              </Button>
              <Button
                size="small"
                variant="outlined"
                style={{
                  borderColor: "#00249b",
                  width: "1rem",
                  fontSize: "11px",
                  color: "#00249b",
                }}
                onClick={handleExtraClick}
              >
                Default
              </Button>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="">
          <Grid item xs={8}>
            <div className="" style={{ marginTop: "5px" }}>
              Showing entries {(page - 1) * 10 + 1} -{" "}
              {Math.ceil(SubscriberListAssignedData?.totalCount / 10) == page
                ? SubscriberListAssignedData?.totalCount
                : page + "0"}{" "}
              of {SubscriberListAssignedData?.totalCount}{" "}
            </div>
          </Grid>
          <Grid item xs={4} className="flex-end">
            <div className="">
              <AssignSubscriberFilter
                area={"Area"}
                areaData={areaListData}
                empList={AssList}
                subArea={"Sub Area"}
                autoBill={"Auto Bill"}
                autoBillOption={billGen}
                handleResetClick={handleReset}
                handleFormValues={(data1, data2, data3, data4) =>
                  handleClickSearcch(data1, data2, data3, data4)
                }
              />
            </div>
          </Grid>
        </Grid>

        <TableView
          rows={tableRows}
          data={expList}
          color={COLORS.primary}
          handleCheckBoxChange={handleCheckBox}
          checkedVal={checkedVal}
        />
        <div
          style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}
        >
          <Pagination
            count={Math.ceil(SubscriberListAssignedData?.totalCount / 10) || 0}
            defaultPage={1}
            page={page}
            onChange={(event, value) => {
              setPage(value);
              handleChange(value);
            }}
            variant="outlined" shape="rounded" 
          />
        </div>
      </div>
    </div>
  );
}
