const tableRows = [
  {
    key: "checkbox",
    align: "left",
  },
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },

  {
    title: "Account ID",
    key: "accountid",
    align: "left",
  },
  {
    title: "MSO ID",
    key: "msqid",
    align: "left",
  },
  {
    title: "STB NO",
    key: "stbno",
    align: "left",
  },
  {
    title: "Customer Name",
    key: "customerName",
    align: "left",
  },
  {
    title: "Customer E-mail",
    key: "customerEmail",
    align: "left",
  },
  {
    title: "Sub Area",
    key: "subarea",
    align: "left",
  },
  {
    title: "Flat No",
    key: "flatno",
    align: "left",
  },
  {
    title: "Bill Amount",
    key: "billAmount",
    align: "left",
  },
  {
    title: "Service Type",
    key: "serviceType",
    align: "left",
  },
  {
    title: "Status",
    key: "status",
    align: "left",
  },
  {
    title: "Created Date",
    key: "createdDate",
    align: "left",
  },
  {
    title: "Completed Date",
    key: "completedDate",
    align: "left",
  },

 

];

export { tableRows };
