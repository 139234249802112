import React, { useState } from "react";
import "./styles.scss";
import { COLORS } from "@constants";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useComponentDidUpdate } from "@utils/useEffectHooks";

export default function PlanSelector(props) {
  const [active, setInActive] = useState("cable");
  const [state, setState] = useState({ postPaid: false, prePaid: false });
  const [mode, setmode] = useState("prepaid");

  useComponentDidUpdate(() => {
    let type = "";
    if (state.postPaid && !state.prePaid) {
      type = "post paid";
    } else if (state.prePaid && !state.postPaid) {
      type = "pre paid";
    } else {
      type = "";
    }
    props?.handleSelectedValue({ serviceType: active, billingType: type });
  }, [active, state]);

  const handleSelection = (value) => {
    setInActive(value);
  };

  const handleChange = (event, name) => {
    setState((prevState) => ({
      ...prevState,
      [name]: event.target.checked,
    }));
  };

  const { postPaid, prePaid } = state;

  return (
    <div className="pln-sltr-container">
      <div className="flex-row justify-space-between">
        <div className="pln-sltr-tle">Dashboard</div>
        <div>
          <div className="btn-switch-ctr">
            <button
              style={{
                backgroundColor: active === "cable" ? COLORS.primary : "white",
              }}
              name="postPaid"
              className={active === "cable" ? "btn-switch-active" : "btn-switch"}
              onClick={() => handleSelection("cable")}
            >
              Cable
            </button>
            <button
              style={{
                backgroundColor: active === "internet" ? COLORS.primary : "white",
              }}
              name="prePaid"
              onClick={() => handleSelection("internet")}
              className={active === "internet" ? "btn-switch-active" : "btn-switch"}
            >
              Internet
            </button>
          </div>
        </div>
      </div>
      <div className="flex-end">
        <FormControlLabel control={<Checkbox size="small" style={{ color: COLORS.primary }} checked={state.postPaid ? true : false} onChange={(event) => handleChange(event, "postPaid")} />} label="Postpaid" />
        <FormControlLabel control={<Checkbox size="small" style={{ color: COLORS.primary }} checked={state.prePaid ? true : false} onChange={(event) => handleChange(event, "prePaid")} />} label="Prepaid" />
      </div>
    </div>
  );
}
