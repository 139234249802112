import Grid from "@mui/material/Grid";
import TicketGenerate from "@components/TicketGenerate";

export default function SubscriberTicket(props) {
  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={12} sm={12} md={12}>
        <TicketGenerate />
      </Grid>     
    </Grid>
  );
}
