import "./style.scss";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useComponentDidMount, useComponentDidUpdate } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { deleteTicket ,notiPayment,notiPaymentUpdate} from "@reducers/api";
import { useState } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";
import { reset } from "@reducers/subscriber";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function TicketList(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notiPaymentData ,notiPaymentUpdateData} = useSelector((state) => state.subscriber);
  const [ticketingList, setticketList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(notiPayment(`distributor_ref_id=${user?.distributor_ref_id}`));
  }, []);

  useComponentDidUpdate(() => {
    let list = [];
    notiPaymentData?.data.map((data, index) => {
      list.push({
        sNo: index + 1,
        order_id:data?.order_id,
        name: data?.cust_ref_id?.name,
        team: data?.complaint_forward_to,
        staff: data?.staff_name,
        ticketType: data?.type,
        amount : data?.bill_amount,
        priority: data?.priority,  
        title:data?.title,
        daysInCount: data?.daysInCount,
        status: status("#73ca72", data?.complaint_status),
        dueDate:  moment(data?.due_date).format("ll"),
        createdDate:  moment(data?.created_at).format("ll"),
        action: (
          <div className="flex-row justify-center">
            <div onClick={() => navigate(`/distributor/add-ticket?id=${data._id}`)} className="cur-pointer">
              <EditIcon />
            </div>
            <div
              className="cur-pointer"
              onClick={() =>
                dispatch(
                  deleteTicket({
                    _id: data?._id,
                  })
                )
              }
            >
              <DeleteIcon />
            </div>
          </div>
        ),
      });
    });
    setticketList(list);
  }, [notiPaymentData]);

  const status = (color, text) => {
    let colorVal="#d352a5"
    if(text === "inprogress"){
      colorVal = "#73ca72"
    }else if(text === "pending"){
      colorVal = "#d35352"
    }else if(text === "resolved"){
      colorVal = "#47bc47"
    }else if(text === "open"){
      colorVal = "#00249B"
    }
    return (
      <div className="status-btn-table" style={{ backgroundColor: colorVal }}>
        {text}
      </div>
    );
  };



  useComponentDidUpdate(() => {
    if (notiPaymentUpdateData) {
      enqueueSnackbar("Updated Successfully !", {
        variant: "success",
      });
      let user = JSON.parse(localStorage.getItem("user"));
      dispatch(notiPayment(`distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`));
      dispatch(reset())     
    }    
  }, [notiPaymentUpdateData]);
 
  return (
    <div>
      <div className="ticketList-container padingnot">     

        <div className="ticket-list-table-cntr">

        <Box sx={{ minWidth: 275 }}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={2}>
        {ticketingList &&
          ticketingList.map((e, index) => (
           
            <Grid item xs={4} sm={4} md={4}>
            <Card variant="outlined">
              <CardContent>
               
                <Typography variant="h5" component="div">
                  {e.order_id}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {e.title}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {e.daysInCount} days
                </Typography>
                <Typography variant="body2">
                Name : <b>{e.name}</b>
             
                </Typography>
                <Typography variant="body2">
                 Amount :  <b>{e.amount}</b> 
             
                </Typography>
                
              </CardContent>
              <CardActions>
                <Button  onClick={() => 
                dispatch(
                  notiPaymentUpdate({
                    invoice_id: e?.order_id,
                  })
                )
              } size="small">Update</Button>
              </CardActions>
          </Card>
            </Grid>
          ))}
  
          </Grid>
          </Box>
        </div>
      </div>
    </div>
  );
}
