import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch } from "react-redux";
import "./styles.scss";
import Header from "@components/homeHeader";

export default function homeLayout(props) {
  return (
    <div>
     <Header/>
      <div className="main-layout-left-none">{props.children}</div>
    </div>
  );
}
