import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import "./styles.scss";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import { useForm, Controller } from "react-hook-form";
import { useComponentDidUpdate, paramsQuery, useComponentDidMount } from "@utils/useEffectHooks";
import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import InputBase from "@mui/material/InputBase";
import { COLORS } from "@constants";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { adminAllPlans, broadcastCreate, broadcastCreatemultiple, currentServiceList, ServiceListTable, addPlanList, getBroadcaster, customerPlanDetails, adminPackageOnly, subscriberView } from "@reducers/api";
import { broacasttable, basictable } from "./data";
import TableView from "@components/TableView";
import ChannelListPop from "@components/Modals/channelList";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CancelIcon from '@mui/icons-material/Cancel';
import { Subreset } from "@reducers/subscriber";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};


const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function AddPlanModal(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { adminAllPlansData, adminPackageData, adminBroadCatData, adminAlaCartData } = useSelector((state) => state.plan);
  const { broadcastAdded, broadcastAddedMultiple, broadcastAddedError } = useSelector((state) => state.subscriber);
  const { getBroadcasterData } = useSelector((state) => state.plan);
  const {
    register,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({});
  const [open, setOpen] = useState(false);
  const [accessType, setAccessType] = useState("basic");
  const [selectedChannelCount, setselectedChannelCount] = useState(0);
  const [value, setValueDate] = useState(new Date());
  const [stbbouqt, setValueSTBbouq] = useState(null);
  const [stbalacart, setValueAla] = useState(null);
  const [stbpackage, setValuePackage] = useState(null);
  const [alacartList, setAlaCartList] = useState([]);
  const [channelData, setchannelData] = useState([]);
  const [packageData, setPackageData] = useState(null);
  const [TotalAmountPlan, setTotalAmountPlan] = useState(0);
  const [bouqList, setbouqlist] = useState([]);
  const [basicList, setbasicList] = useState([]);
  const [basicListTest, setbasicListTest] = useState([]);
  const [channelList, setchannelList] = React.useState(false);
  const [searchList, setsearchList] = useState([]);
  const [searchListlacart, setsearchListAla] = useState([]);
  const [checkedVal, setChecked] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));

  const handleClose = () => {
    props?.handleClose();
    setAccessType("basic");
    reset();
  };

  const handleChange = (newValue) => {
    setValueDate(newValue);
    setValue("date", newValue);
  };

  useComponentDidMount(() => {
    dispatch(addPlanList());
    dispatch(getBroadcaster());
  }, []);

  const handleChannelclose = () => {
    setchannelList(false);
  };

  useEffect(() => {
    setOpen(props?.show);
  }, [props?.show]);

  const handleForm = (values) => {

    if (stbbouqt == null && props?.customerData?.service_type == "cable") {
      enqueueSnackbar("Please select a STB to add!", {
        variant: "error",
      });
      return;
    }
    if (packageData == null) {
      enqueueSnackbar("Please select atlease one plan!", {
        variant: "error",
      });
      return;
    }
    let users = JSON.parse(localStorage.getItem("user"));
    let payload = {
      can_id: props?.customerData?.can_id,
      plan_name: packageData.planname,
      plan_amount: packageData.packagecost,
      distributor_ref_id: users?.distributor_ref_id,
      plan_ref_id: packageData._id,
      package_category: "package",
      plan_validity: packageData.plan_validity,
      billing_type: packageData.billing_type,
      mso: props?.customerData?.mso,
      service_type: props?.customerData?.service_type,
      stb_no: stbbouqt?.stb_no,
      expired_date: value,
      cust_ref_id: props?.customerData?._id,
      cust_name: props?.customerData?.name,
      cust_email_id: props?.customerData?.email_id,
      disabled: false,
    };
    let preparedeletepay = [payload];
    dispatch(broadcastCreate(preparedeletepay));
  };



  useComponentDidUpdate(() => {
    let filteredforbasc = props?.adminPackageData && props?.adminPackageData?.filter((plan) => plan.billing_type === props?.customerData?.billing_type && plan?.distributor_ref_id === props?.customerData?.distributor_ref_id && plan.disabled == false && plan.package_category == "package");
    let list = [];
    filteredforbasc &&
      filteredforbasc?.map((data, index) => {
        list.push({
          checked: false,
          sNo: index + 1,
          _id: data?._id,
          planname: data?.plan_name,
          packagecost: data?.plan_amount,
          packagecategory: data?.package_category,
          stbno: data?.stb_no,
          amount: data?.plan_amount,
          category: data?.package_category,
          updated_at: data?.updated_at,
          billing_cycle: data?.billing_cycle,
          channel_type: data?.channel_type,
          channelcount: data?.channel_count,
          plan_ref_id: data?._id,
          service_type: data?.service_type,
          plan_validity: data?.plan_validity,
          billing_type: data?.billing_type,
          channels: data?.channels,
        });
      });
    setbasicList(list);
    setbasicListTest(list)

  }, [props?.adminPackageData, props?.customerData]);

  useComponentDidUpdate(() => {
    console.log(broadcastAddedError, "broadcst")
    if (broadcastAdded) {
      enqueueSnackbar("Package Added!", {
        variant: "success",
      });
      setOpen(false);
      setAccessType("basic");
      apiCallPackage()
      let id = paramsQuery("id");
      dispatch(subscriberView(id));
    }

    if (broadcastAddedError) {
      enqueueSnackbar("Plan Name Already Exist on Same Subscriber", {
        variant: "error",
      });
      dispatch(Subreset());
        let id = paramsQuery("id");
      dispatch(subscriberView(id));
    }
    let custId = paramsQuery("id");
    dispatch(currentServiceList(custId));
    dispatch(ServiceListTable(custId))
    if (user?.role == "user") {
      let custRefId = user?.cust_ref_id;
      let payload = {
        cust_ref_id: custRefId,
      };
      dispatch(customerPlanDetails(payload));

    }

    reset();
    dispatch(Subreset());
  }, [broadcastAdded, broadcastAddedError]);

  console.log(broadcastAdded, "broadcastAddedMultiple")

  useComponentDidUpdate(() => {
    if (broadcastAddedMultiple) {
      // enqueueSnackbar("Package Added!", {
      //   variant: "success",
      // });
      setOpen(false);
    }
    let custId = paramsQuery("id");
    if (user?.role == "user") {
      let custRefId = user?.cust_ref_id;
      let payload = {
        cust_ref_id: custRefId,
      };
      dispatch(customerPlanDetails(payload));
    }
    dispatch(currentServiceList(custId));
    dispatch(ServiceListTable(custId))
    reset();
    setAccessType("basic");
    apiCallPackage()
    dispatch(Subreset());
  }, [broadcastAddedMultiple]);

  useComponentDidUpdate(() => {
    let filteredforalacart = adminAlaCartData && adminAlaCartData?.filter((plan) => plan.billing_type === props?.customerData?.billing_type && plan.disabled == false);

    if (filteredforalacart) {
      let preparedeletepay = [];
      filteredforalacart.map((data) => {
        preparedeletepay.push({
          amount: data.plan_amount,
          name: data.plan_name,
          checked: false,
          _id: data._id,
          billing_type: data.billing_type,
          channel_count: data.channel_count,
          channel_type: data.channel_type,
          created_by: "admin",
          disabled: false,
          package_category: data.package_category,
          plan_validity: data.plan_validity,
          service_type: data.service_type,
          selected: true,
          plan_ref_id: data._id,
        });
      });
      setAlaCartList(preparedeletepay);
      setsearchListAla(preparedeletepay)


    }
    preparedataforbouqt()

  }, [adminAlaCartData, props?.customerData]);




  const apiCallBroadCast = () => {
    dispatch(adminAllPlans(`package_category=broadcaster&service_type=${props?.customerData?.service_type}`));

  };

  const apiCallPackage = () => {
    dispatch(adminPackageOnly(`distributor_ref_id=${user?.distributor_ref_id}&service_type=${props?.customerData?.service_type}&billing_type=${props?.customerData?.billing_type}`));

  };

  const apiCallAlaCart = () => {
    dispatch(adminAllPlans(`package_category=ala cart&service_type=${props?.customerData?.service_type}`));
  };

  const handleCheckBox = (index, checked) => {
    const clonedDataService = [...bouqList];
    if (index === 0 || index > 0) {
      clonedDataService[index].checked = clonedDataService[index].checked ? false : true;
    } else if (index === "all") {
      for (let services of clonedDataService) {
        if (checked) {
          services.checked = true;
        } else {
          services.checked = false;
        }
      }
    }

    setbouqlist(clonedDataService);
    setChecked(checked);
  };


  const broadcasterChange = (item) => {
    dispatch(adminAllPlans(`package_category=broadcaster${item ? `&broadcaster=${item}` : `&service_type=${props?.customerData?.service_type}&billing_type=${props?.customerData?.billing_type}`}`));
  };

  const preparedataforbouqt = () => {
    let list = [];
    let filteredforbroadcaster = adminBroadCatData && adminBroadCatData?.filter((plan) => plan.billing_type === props?.customerData?.billing_type && plan.disabled == false);
    filteredforbroadcaster &&
      filteredforbroadcaster?.map((data, index) => {
        list.push({
          checked: false,
          sNo: index + 1,
          _id: data?._id,
          planname: data?.plan_name,
          packagecategory: data?.package_category,
          stbno: data?.stb_no,
          amount: data?.plan_amount,
          category: data?.package_category,
          updated_at: data?.updated_at,
          billing_cycle: data?.billing_cycle,
          channel_type: data?.channel_type,
          channelcount: data?.channel_count,
          plan_ref_id: data?._id,
          service_type: data?.service_type,
          plan_validity: data?.plan_validity,
          billing_type: data?.billing_type,
          channels: data?.channels,
          action: (
            <div className="flex-row justify-center">
              <div className="cursor-pointer" onClick={() => channelPopup(data)}>
                view channel
              </div>
            </div>
          ),
        });
      });
    setbouqlist(list);
    setsearchList(list)

  };

  const channelPopup = (data) => {
    setchannelList(true);
    setchannelData(data?.channels);
  };

  const saveAlaCart = () => {
    if (stbbouqt == null) {
      enqueueSnackbar("Please select a STB to add!", {
        variant: "error",
      });
      return;
    }
    let filteredalacart = searchListlacart.filter((options) => options.checked === true);
    if (filteredalacart.length == 0) {
      enqueueSnackbar("Please select atlease one plan!", {
        variant: "error",
      });
      return;
    }
    let preparedeletepay = [];
    filteredalacart.map((data) => {
      preparedeletepay.push({
        add_type: "manual_plan",
        billing_type: data?.billing_type,
        channel_count: data?.channel_type === "HD" ? 2 : 1,
        channel_type: data?.channel_type,
        created_by: "admin",
        disabled: false,
        package_category: "ala cart",
        plan_amount: data?.amount,
        plan_name: data?.name,
        plan_validity: data?.plan_validity,
        service_type: data?.service_type,
        selected: true,
        cust_ref_id: props?.customerData?._id,
        cust_name: props?.customerData?.name,
        cust_email_id: props?.customerData?.email_id,
        expired_date: new Date(),
        stb_no: stbbouqt?.stb_no,
        plan_ref_id: data?._id,
      });
    });
    dispatch(broadcastCreate(preparedeletepay));
  };

  let filteredbouq = searchList.filter((options) => options.checked === true);

  const savebouqplan = () => {
    if (stbbouqt == null) {
      enqueueSnackbar("Please select a STB to add!", {
        variant: "error",
      });
      return;
    }
    let filteredbouqlist = searchList.filter((options) => options.checked === true);
    if (filteredbouqlist.length == 0) {
      enqueueSnackbar("Please select atlease one plan!", {
        variant: "error",
      });
      return;
    }
    let preparedeletepay = [];
    filteredbouqlist.map((data, index) => {
      preparedeletepay.push({
        can_id: props?.customerData?.can_id,
        plan_name: data?.planname,
        plan_amount: data?.amount,
        distributor_ref_id: user?.distributor_ref_id,
        plan_ref_id: data?.plan_ref_id,
        package_category: data?.packagecategory,
        plan_validity: data?.plan_validity,
        billing_type: data?.billing_type,
        mso: "Hathway",
        service_type: props?.customerData?.service_type,
        channel_count: data?.channels?.length,
        stb_no: stbbouqt?.stb_no,
        expired_date: value,
        cust_ref_id: props?.customerData?._id,
        cust_name: props?.customerData?.name,
        cust_email_id: props?.customerData?.email_id,
        disabled: false,
        channels: data?.channels,
      });
    });
    dispatch(broadcastCreatemultiple(preparedeletepay));
  };
  const handleCheckBoxAlacart = (index, checked) => {
    const clonedDataService = [...alacartList];
    if (index === 0 || index > 0) {
      clonedDataService[index].checked = clonedDataService[index].checked ? false : true;
    } else if (index === "all") {
      for (let services of clonedDataService) {
        if (checked) {
          services.checked = true;
        } else {
          services.checked = false;
        }
      }
    }
    setChecked(checked);
    setAlaCartList(clonedDataService);
    let filteredalacart = searchListlacart.filter((options) => options.checked === true);
    let totalAmount = filteredalacart.reduce(function (prev, current) {
      return prev + +current.amount;
    }, 0);
    setselectedChannelCount(filteredalacart.length);
    setTotalAmountPlan(totalAmount);
  };

  const handleRadio = (value) => {
    setPackageData(value);

  };

  const handleSearchAlacart = (search) => {
    // let searchListalacart = searchListlacart.filter(function (data) {
    //   return data.name.toLowerCase().match(search);
    // });
    dispatch(adminAllPlans(`package_category=ala cart&service_type=${props?.customerData?.service_type}&value=${search}`));

    // setAlaCartList(searchListalacart)
  };

  const handleSearchBroadcast = (search) => {
    // let searchListBroad = searchList.filter(function (data) {
    //   return data.planname.toLowerCase().match(search);
    // });

    // setbouqlist(searchListBroad)
    dispatch(adminAllPlans(`package_category=broadcaster&service_type=${props?.customerData?.service_type}&value=${search}`));

  };

  const handleSearchPackage = (search) => {
    props?.handleSearchPackage(search)
  };

  let packageAllow = props?.currentServiceTable && props?.currentServiceTable?.filter((options) => options?.packagecategory === "package" || options?.package_category === "package");

  let STBPackage = props?.currentServiceTable ? props?.currentServiceTable?.filter((options) => options?.stbno  === stbpackage?.stb_no) : ""


  let STBPackageCustomer = props?.currentServiceTable ? props?.currentServiceTable?.filter((options) => (
    console.log(options?.stb_no === stbpackage?.stb_no, "option"),
    options?.stb_no === stbpackage?.stb_no
  ) 
  ) : ""

  let filterPackage = STBPackageCustomer ? STBPackageCustomer?.filter((options) => (
    options?.package_category === "package"
  ) 
  ) : ""

  let filterPackageDist = STBPackage ? STBPackage?.filter((options) => (
    options?.packagecategory === "package"
  ) 
  ) : ""

  console.log(filterPackageDist, "currentserv")
  
  return (
    <div>
      <div className="channelpopup">
        <ChannelListPop show={channelList} channelData={channelData} handleClose={handleChannelclose} />
      </div>
      <form >
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
          <Box sx={style} className="makePayment-cntr addPlanPopRes">
            <div className=" addplan-container">


              <div className="header-raise-title justify-space-between flex-row " >
                <div className="payment-header-title">Add new plan</div>
                <div onClick={handleClose} className="cursor-pointer">{<CancelIcon />}</div>
              </div>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles ">
                <Grid item xs={3} sm={3} md={1} className="typeValu">
                  <div className="text-plan">Type:</div>
                </Grid>
                <Grid item xs={4} sm={4} md={10} className='currentPlan'>
                  <FormControl component="fieldset">
                    <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
                      <FormControlLabel
                        {...register("basic")}
                        name="basic"
                        value="Basic"
                        control={
                          <Radio
                            size="small"
                            checked={accessType == "basic"}
                            onClick={() => {
                              reset();
                              setAccessType("basic");
                              setPackageData([])
                              setValueSTBbouq(null)
                              setAlaCartList([])
                              setbouqlist([])
                              apiCallPackage()
                              setselectedChannelCount(0)
                              setTotalAmountPlan(0);
                            }}
                          />
                        }
                        label="Package"
                      />

                      {props?.customerData?.service_type == "cable" && (
                        <FormControlLabel
                          {...register("broadcast")}
                          name="broadcast"
                          value="broadcaste"
                          control={
                            <Radio
                              size="small"
                              checked={accessType == "broadcast"}
                              onClick={() => {
                                reset();
                                setAccessType("broadcast");
                                setPackageData([])
                                setbasicList([])
                                setAlaCartList([])
                                setbouqlist([])
                                setValueSTBbouq(null)
                                apiCallBroadCast()
                                setTotalAmountPlan(0);
                                setselectedChannelCount(0)

                              }}
                            />
                          }
                          label="Broadcaste Bouquet"
                        />
                      )}

                      {props?.customerData?.service_type == "cable" && (
                        <FormControlLabel
                          {...register("alacart")}
                          name="alacart"
                          value="alacarte"
                          control={
                            <Radio
                              size="small"
                              checked={accessType == "alacart"}
                              style={{ fontSize: "13px" }}
                              onClick={() => {
                                reset();
                                setAccessType("alacart");
                                setPackageData([])
                                setbasicList([])
                                setAlaCartList([])
                                setbouqlist([])
                                setValueSTBbouq(null)
                                apiCallAlaCart()
                                setselectedChannelCount(0)
                              }}
                            />
                          }
                          label="A-La carte"
                        />
                      )}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              {accessType === "alacart" && (
                <div>
                  <Grid container spacing={2}>
                    {props?.customerData?.service_type == "cable" && (
                      <Grid item xs={3} md={6}>
                        <Controller
                          control={control}
                          name="addStb"
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              onChange={(event, item) => {
                                onChange(item);
                                setValueSTBbouq(item);
                                setValueAla(item)
                              }}
                              value={value || ""}
                              options={props?.customerData?.stb_no || []}
                              isOptionEqualToValue={(option, value) => option.stb_no === value.stb_no}
                              getOptionLabel={(item) => (item.stb_no ? item.stb_no : "")}
                              renderInput={(params) => <TextField {...params} size="small" placeholder="Select STB" />}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item xs={2} md={2}>
                      <Button variant="contained" component="span" className="bg-blue" size="small" sx={{ fontSize: 13, textTransform: "capitalize" }} onClick={saveAlaCart}>
                        Save
                      </Button>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <InputBase
                        className="search-input"
                        size="small"
                        onChange={(e) => {
                          handleSearchAlacart(e.target.value);
                        }}
                        placeholder="Search By"
                        // endAdornment={
                        //   <InputAdornment position="end">
                        //     <SearchIcon style={{ color: COLORS.primary }} />
                        //   </InputAdornment>
                        // }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          style: { color: COLORS.primary },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles" style={{ marginTop: "25px" }}>
                    <Grid item xs={2} sm={2} md={2}>
                      <div className="text-plan">Plan Details:</div>
                    </Grid>
                    <Grid item xs={5} sm={5} md={4}>
                      <div className="text-plan">
                        Selected Channels Count: <b>{selectedChannelCount}</b>
                      </div>
                    </Grid>
                    <Grid item xs={5} sm={5} md={5}>
                      <div className="text-plan">
                        Total:<b>{TotalAmountPlan}</b>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles">
                    <Grid item xs={4} sm={4} md={12}>
                      <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="alacarRes" style={{ height: "200px", overflow: "scroll" }}>
                        {alacartList?.map((prod, i) => (
                          <Grid item xs={4} sm={4} md={4}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e) => {
                                      handleCheckBoxAlacart(i);
                                    }}
                                    sx={{
                                      "&.Mui-checked": {
                                        color: COLORS.primary,
                                      },
                                    }}
                                    {...label}
                                    checked={prod?.checked}
                                    size="small"
                                  />
                                }
                                label={prod.name}
                              />
                              <div className="marginLeftAddplan">Rs:{prod.amount}</div>
                            </FormGroup>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              )}

              {accessType === "basic" && (
                <div className="broadcasttable">
                  <Grid item xs={5} sm={5} md={5}>
                    <InputBase
                      className="search-input"
                      size="small"
                      onChange={(e) => {
                        handleSearchPackage(e.target.value);
                      }}
                      placeholder="Search By"
                      // endAdornment={
                      //   <InputAdornment position="end">
                      //     <SearchIcon style={{ color: COLORS.primary }} />
                      //   </InputAdornment>
                      // }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        style: { color: COLORS.primary },
                      }}
                    />
                  </Grid>
                  <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles">
                    {props?.customerData?.service_type == "cable" && (
                      <Grid item xs={5} sm={5} md={5}>
                        <Controller
                          control={control}
                          name="addStb"
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              onChange={(event, item) => {
                                onChange(item);
                                setValueSTBbouq(item);
                                setValuePackage(item)
                              }}
                              value={value || ""}
                              options={props?.customerData?.stb_no || []}
                              isOptionEqualToValue={(option, value) => option.stb_no === value.stb_no}
                              getOptionLabel={(item) => (item.stb_no ? item.stb_no : "")}
                              renderInput={(params) => <TextField {...params} size="small" placeholder="Select STB" />}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item xs={0.5} sm={0.5} md={0.5}></Grid>
                    <Grid item xs={4} sm={4} md={4}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker inputFormat="MM/dd/yyyy" value={value} onChange={handleChange} renderInput={(params) => <TextField fullWidth size="small" {...register("date")} placeholder="Enter Expiry Date" {...params} />} />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}></Grid>
                    <Grid item xs={12} sm={1} md={1} className=''>
                      <Stack spacing={2} direction="row" className="btnright">
                        {
                          props?.customerData?.service_type == "cable" && (

                            filterPackageDist[0]?.packagecategory === "package" || filterPackage[0]?.package_category === "package"  ? (
                              <Button type="button" variant="contained" size="small" style={{ textTransform: "none", background: "grey", cursor: 'arrow', marginTop: "5px" }}>
                                {"Save"}
                              </Button>

                            ) : (
                              <Button type="button" onClick={handleForm} variant="contained" className="bg-blue" size="small" style={{ textTransform: "none", marginTop: "5px" }}>
                                {"Save"}
                              </Button>
                            )

                          )
                        }

                        {
                          props?.customerData?.service_type == "internet" && (

                            packageAllow?.length > 0 ? (
                              <Button type="button" variant="contained" size="small" style={{ textTransform: "none", background: "grey", cursor: 'arrow', marginTop: "5px" }}>
                                {"Save"}
                              </Button>

                            ) : (
                              <Button type="button" onClick={handleForm} variant="contained" className="bg-blue" size="small" style={{ textTransform: "none", marginTop: "5px" }}>
                                {"Save"}
                              </Button>
                            )

                          )
                        }


                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid className='basicRes'>
                  <TableView rows={basictable} data={basicList} color={COLORS.primary} addPlan={true} handleCheckRadio={handleRadio} />
                  </Grid>
                </div>
              )}

              {accessType === "broadcast" && (
                <div className="">
                  <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles" spacing={1}>
                    <Grid item xs={4.5} sm={4.5} md={4.5}>
                      <InputBase
                        className="search-input"
                        size="small"
                        onChange={(e) => {
                          handleSearchBroadcast(e.target.value);
                        }}
                        placeholder="Search By"
                        // endAdornment={
                        //   <InputAdornment position="end">
                        //     <SearchIcon style={{ color: COLORS.primary }} />
                        //   </InputAdornment>
                        // }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          style: { color: COLORS.primary },
                        }}
                      />
                    </Grid>
                    <Grid item xs={0.5} sm={0.5} md={0.5}></Grid>
                    <Grid item xs={5} sm={5} md={5}>
                      <Controller
                        control={control}
                        name="broadcaster"
                        render={({ field: { onChange, value } }) => (

                          <Autocomplete
                            onChange={(event, item) => {
                              onChange(item);
                              broadcasterChange(item);
                            }}
                            value={value || ""}
                            options={getBroadcasterData?.broadcasterList || []}
                            isOptionEqualToValue={(option, value) => option === value}
                            getOptionLabel={(item) => (item ? item : "")}
                            renderInput={(params) => <TextField {...params} size="small" placeholder="Select Broadcaster" />}
                          />
                        )}
                      />
                    </Grid>{" "}
                  </Grid>
                  <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles">
                    {props?.customerData?.service_type == "cable" && (
                      <Grid item xs={4.5} sm={4.5} md={4.5}>
                        <Controller
                          control={control}
                          name="addStb"
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              onChange={(event, item) => {
                                onChange(item);
                                setValueSTBbouq(item);
                              }}
                              value={value || ""}
                              options={props?.customerData?.stb_no || []}
                              isOptionEqualToValue={(option, value) => option.stb_no === value.stb_no}
                              getOptionLabel={(item) => (item.stb_no ? item.stb_no : "")}
                              renderInput={(params) => <TextField {...params} size="small" placeholder="Select STB" />}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item xs={0.5} sm={0.5} md={0.5}></Grid>
                    <Grid item xs={5} sm={5} md={5}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker inputFormat="MM/dd/yyyy" value={value} onChange={handleChange} renderInput={(params) => <TextField fullWidth size="small" {...register("date")} placeholder="Enter Expiry Date" {...params} />} />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}></Grid>
                    <Grid item xs={12} sm={1} md={1}>
                      <Stack spacing={0} direction="row" className="btnright">
                        <Button type="button" onClick={savebouqplan} variant="contained" className="bg-blue" size="small" style={{ textTransform: "none", marginTop: "5px" }}>
                          {"Save"}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles" style={{ marginTop: "5px" }}>

                    <Grid item xs={5} sm={5} md={4}>
                      <div className="text-plan">
                        Selected Channels Count: <b>{filteredbouq.length}</b>
                      </div>
                    </Grid>

                  </Grid>
                  <div className = "brodTab">
                  <TableView rows={broacasttable}  data={bouqList} color={COLORS.primary} handleCheckBoxChange={handleCheckBox} checkedVal={checkedVal} />
                </div>
                </div>
              )}
            </div>
          </Box>
        </Modal>
      </form>
    </div>
  );
}
