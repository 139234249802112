import "./style.scss";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import { useForm, Controller } from "react-hook-form";
import { useComponentDidUpdate,useComponentDidMount } from "@utils/useEffectHooks";
import { reset } from "../../reducers/subscriber";
import {  useSelector,useDispatch } from "react-redux";




export default function TicketGenerate(props) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm();



  
  let user = JSON.parse(localStorage.getItem("user"));


  useComponentDidUpdate(() => {
    if (props?.resetForm) return reset();
  }, [props?.resetForm]);

  const onSubmit = (data) => {
    let formData = [
      {
        type: data?.option1?.items,
        value: data?.value1,
        quantity: data?.option1?.quantity,
        _id: data?.option1?._id,
        inventoryId: data?.option1?._id
      },
      // {
      //   type: data?.option2?.items,
      //   value: data?.value2,
      //   quantity: data?.option2?.quantity,
      //   _id: data?.option2?._id
      // },
      // {
      //   type: data?.option3?.value,
      //   value: data?.value3,
      // },
      // {
      //   type: data?.option4?.value,
      //   value: data?.value4,
      // },
      // {
      //   type: data?.option5?.value,
      //   value: data?.value5,
      // },
    ];

    props?.handleFormData(formData);
  };

  const layoutInput = (input) => {
    return (
      <Grid item xs={4} sm={4} md={6}>
        <div className="sub-bouqut-form-title"></div>
        {input}
      </Grid>
    );
  };

  const handleSetValue = (item) => {
    setValue("value1", item.price);
  };

  const handleSetValue2 = (item) => {
    setValue("value2", item.price);
  };

  return (
    <div className="sub-bouqut-form-cnt addonService">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 5 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={4} md={6}>
            <div className="title-container">Add on Services</div>
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <div className="title-container">Charges</div>
          </Grid>
        </Grid>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 5 }} columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles ticketDetails">
          {layoutInput(
            <Controller
              control={control}
              name="option1"
              render={({ field: { onChange, value } }) => (
                  <Autocomplete                 
                  onChange={(event, item) => {
                    onChange(item);
                    handleSetValue(item);

                  }}
                  value={value || ""}
                  options={props?.getInventoryListData}
                  isOptionEqualToValue={(option, value) => option.items === value.items}
                  getOptionLabel={(item) =>
                    item.items ? `${item.items} (${item.quantity !== null && item.quantity !== undefined ? item.quantity : 0 })` : ""
                  }
                  getOptionSelected={(option, value) => value === undefined || value === "" || option._id === value._id}
                  renderInput={(params) => <TextField {...params} size="small" placeholder="Select" />}
                  
                />
              )}
            />
          )}
          {layoutInput(
            <TextField
              style={{ width: "100%" }}
              size="small"
              className="rupee-style"
              id="outlined-basic"
              placeholder="Enter Charges"
              variant="outlined"
              {...register("value1")}
              InputProps={{
                startAdornment: <InputAdornment position="start" >RS</InputAdornment>,
              }}
            />
          )}

          {/* {layoutInput(
            <Controller
              control={control}
              name="option2"
              render={({ field: { onChange, value } }) => (
                <Autocomplete                 
                onChange={(event, item) => {
                  onChange(item);
                  handleSetValue2(item);

                }}
                value={value || ""}
                options={props?.getInventoryListData}
                isOptionEqualToValue={(option, value) => option.items === value.items}
               
                getOptionLabel={(item) =>
                  item.items ? `${item.items} (${item.quantity !== null && item.quantity !== undefined ? item.quantity : 0 })` : ""
                }
                getOptionSelected={(option, value) => value === undefined || value === "" || option._id === value._id}
                renderInput={(params) => <TextField {...params} size="small" placeholder="Select" />}
                
              />
              )}
            />
          )} */}
          {/* {layoutInput(
            <TextField
              style={{ width: "100%" }}
              {...register("value2")}
              id="outlined-basic"
              placeholder="Enter Charges"
              variant="outlined"
              size="small"
              className="rupee-style"
              InputProps={{
                startAdornment: <InputAdornment position="start">RS</InputAdornment>,
              }}
            />
          )} */}
          {/* {layoutInput(
            <Controller
              control={control}
              name="option3"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={addonServiceType}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => <TextField {...params} size="small" placeholder="Select" />}
                />
              )}
            />
          )}
          {layoutInput(
            <TextField
              style={{ width: "100%" }}
              {...register("value3")}
              id="outlined-basic"
              placeholder="Enter Charges"
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: <InputAdornment position="start">RS</InputAdornment>,
              }}
            />
          )}
          {layoutInput(
            <Controller
              control={control}
              name="option4"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={addonServiceType}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => <TextField {...params} size="small" placeholder="Select" />}
                />
              )}
            />
          )}
          {layoutInput(
            <TextField
              style={{ width: "100%" }}
              {...register("value4")}
              id="outlined-basic"
              placeholder="Enter Charges"
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: <InputAdornment position="start">RS</InputAdornment>,
              }}
            />
          )}
          {layoutInput(
            <Controller
              control={control}
              name="option5"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={addonServiceType}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => <TextField {...params} size="small" placeholder="Select" />}
                />
              )}
            />
          )}
          {layoutInput(
            <TextField
              size="small"
              {...register("value5")}
              style={{ width: "100%" }}
              id="outlined-basic"
              placeholder="Enter Charges"
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">RS</InputAdornment>,
              }}
            />
          )} */}
        </Grid>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="flex-end" style={{ marginTop: 20 }}>
          <Stack spacing={2} direction="row">
            <Button type="submit" variant="contained" className="bg-blue" size="small">
              Submit
            </Button>
          </Stack>
        </Grid>
      </form>
    </div>
  );
}
