import CreatePlanForm from "@components/CreatePlanForm";
import React, { useState } from "react";
import "./style.scss";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { updatePlan, planList, addPlan, alaCartList, broadcasterAdmin } from "@reducers/api";
import { reset } from "@reducers/plan";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useComponentDidMount, paramsQuery, useComponentDidUpdate, useComponentWillUnmount } from "@utils/useEffectHooks";
import BreadCrumb from "@components/Breadcrumb";
import { Grid } from "@mui/material";

const broadCasterBoquet = [
  { id: 1, label: "Discovery", value: "discovery" },
  { id: 2, label: "Disney", value: "disney" },
  { id: 3, label: "ETPL", value: "etpl" },
  { id: 4, label: "JAYA", value: "jaya" },
  { id: 5, label: "SUN", value: "sun" },
  { id: 6, label: "ZEE", value: "zee" },
];

export default function AddPlanForms(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [BroadCastPacks, setBroadCastPacks] = useState([]);

  const { planListData, planAdded, alaCartListData, broadcasterAdminData } = useSelector((state) => state.plan);
  let filtered = planListData?.data?.length && planListData?.data?.filter((data) => data._id === paramsQuery("id"));


  useComponentDidMount(() => {
    let id = paramsQuery("id")
    let query = `&id=${id}`
    dispatch(planList(query));
    dispatch(alaCartList());
    dispatch(broadcasterAdmin());
  }, []);

  useComponentDidUpdate(() => {
    let message = "";
    if (planAdded.msg === "New plan created successfully") {
     
        message = "Plan Added";
      
      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/admin/plan-list`);
    }
    if(planAdded.msg === "The Plan Name Already Exists"){
      message = "The Plan Name Already Exists";
      enqueueSnackbar(message, {
        variant: "error",
      });

    }

    if(planAdded.msg === "Plan updated successfully"){
      message = "Plan Updated";
      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/admin/plan-list`);

    }
  }, [planAdded]);

  useComponentDidUpdate(() => {
    let filteredArray = [];
    for (let options of broadCasterBoquet) {
      let filtered = alaCartListData.filter(function (word) {
        return word?.plan_name?.toLowerCase()?.includes(options.label.toLowerCase());
      });
      if (filtered.length > 0) {
        filteredArray.push({ packName: options.label, selected: false, data: filtered });
      }
    }
    setBroadCastPacks(filteredArray);
  }, [alaCartListData]);

  useComponentWillUnmount(() => {
    dispatch(reset());
  });



  let alaCartListFilter = alaCartListData?.filter(
    (serviceList) =>
      serviceList.package_category === "ala cart"
  );


  const handleFormValues = (values, broadcastTypes) => {
    console.log(broadcastTypes, "channal list")
    let id = paramsQuery("id");
    let selectedChannels = [];
    let payload = {
      billing_type: values?.billingType?.value,
      broadcaster: values?.broadcaster || values?.broadcasterTextField,
      channel_count: 1,
      channel_type: values?.channelType?.value,
      created_by: "admin",
      disabled: values?.disablePlan?.value,
      package_category: values?.packageCategory?.value,
      plan_amount: Number(values?.planAmount),
      plan_name: values?.planName,
      plan_validity: values?.packageValidity?.value,
      service_type: values?.serviceType?.value,
    };
    if (values?.packageCategory?.value === "broadcaster") {

      for (let broadcastTypesList of broadcastTypes) {
        selectedChannels.push(broadcastTypesList);
      }


      payload["channels"] = broadcastTypes || planListData && planListData?.data[0]?.channels;
      payload.channel_count = broadcastTypes.length;
      payload.channel_type = values?.channelType?.value;
    }
    if (id) {
      payload["_id"] = id;
      dispatch(updatePlan(payload));
    } else {
      dispatch(addPlan(payload));
    }
  };

  const addBroadCastitems = (data, index) => {
    const broadCast = [...BroadCastPacks];
    const keyIndex = broadCast.findIndex((packs) => packs.packName === data?.packName);
    broadCast[keyIndex].selected = broadCast[keyIndex].selected ? false : true;
    setBroadCastPacks(broadCast);
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/admin/${path}`);
  };


  return (
    <div className="addPlan-container">

      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <div className="planHeading">
            <ContentCopyIcon />
            {paramsQuery("id") ? "Update Plan" : "New Plan"}
          </div>
        </Grid>
        <Grid item xs={12} md={4} className="flex-end" style={{marginBottom: '1rem'}}>
          <div className='breadcrumbPadding'>
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              secondaryHeading="Plan List"
              handleSecondaryClick={() => handleBreadCrumbRedirect("plan-list")}
              activeHeading= {paramsQuery("id") ? "Edit Plan" : "Add Plan"}
             
            />
          </div>

        </Grid>
      </Grid>
      <CreatePlanForm data={planListData?.data && planListData?.data[0]} broadCast={BroadCastPacks} alaCartListFilter={alaCartListFilter} broadcastList={broadcasterAdminData} addBroadCastitems={addBroadCastitems} handleFormValues={(values, broadcastTypes) => handleFormValues(values, broadcastTypes)} />
    </div>
  );
}
