import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import logo from "@assets/images/logo.png";
import "./style.scss";
import { profileView } from "@reducers/api";
import {
  useComponentDidMount,
  useComponentDidUpdate,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Avatar from "@mui/material/Avatar";

export default function MenuAppBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { profileViewData } = useSelector((state) => state.auth);
  const { profileData } = useSelector((state) => state.customer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fileData, setfileData] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleprofileClick = () => {
    handleClose();
    if (name()?.role === "admin") {
      navigate(`/admin/profile`);
    } else if (name()?.role === "distributor") {
      navigate(`/distributor/profile`);
    }
  };

  const handleChangepswClick = () => {
    handleClose();
    if (name()?.role === "admin") {
      navigate(`/admin/change-password`);
    } else if (name()?.role === "distributor") {
      navigate(`/distributor/change-password`);
    }else if (name()?.role === "user") {
      navigate(`/customer/change-password`);
    }
  };

  const handleChangeNotificationClick = () => {
    handleClose();
    if (name()?.role === "admin") {
      navigate(`/admin/notification`);
    } else if (name()?.role === "distributor") {
      navigate(`/distributor/notification`);
    }
  };

  const logOutmethod = () => {
    localStorage.clear();
    navigate(`/login`);
  };

  const name = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    return user;
    
  };

  useComponentDidUpdate(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    if(user?.role == "distributor")
    { 
      setfileData(profileViewData?.profileFileData||null)
    }
    if(user?.role == "admin")
    { 
      setfileData(profileViewData?.profileFileData||null)
    }
    if(user?.role == "user")
    { 
      setfileData(profileData?.customerData?.fileData||null)
    }
    if(user?.role == "support")
    { 
      setfileData(profileViewData?.profileFileData||null)
    }    
  }, [profileViewData,profileData]);

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let id = user?._id;
    let payload = {
      _id: id,
    };
    dispatch(profileView(payload));
  }, []);

  const open = Boolean(anchorEl);
console.log(name()?.role, "roleeee")
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="topcolor">
        <Toolbar>
          <IconButton
            backgroundColor="black"
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={props?.handleSidebar}
          >
            <MenuIcon   style={{ color:  "black" }}/>
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img alt="logo" src={logo}></img>
          </Typography>

          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              className="topImage"
              style={{ cursor: "arrow" }}
            >

              {fileData!==null ? <img className="topBarUpload" src={`data:image/png;base64,${fileData}`} alt={"image"} />:
              <AccountCircle className="account-circle" /> }          
              

             
            </IconButton>
            

            <Button
              id="demo-customized-button"
              aria-controls="demo-customized-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {profileViewData && profileViewData?.username}
            </Button>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {name()?.role !== "user" && name()?.role !== "support"  &&
              <MenuItem onClick={handleprofileClick} disableRipple>
                <PersonOutlineIcon />
                Profile
              </MenuItem> }
              {
                name()?.role !== "support" && 
                <MenuItem onClick={handleChangepswClick} disableRipple>
                <LockOutlinedIcon />
                Change Password
              </MenuItem>
              }
             
              {/* {name().role !== "admin" &&
              <MenuItem onClick={handleChangeNotificationClick} disableRipple>
                <NotificationsOutlinedIcon />
                Notification
              </MenuItem>
              } */}
              <MenuItem onClick={logOutmethod} disableRipple>
                <PowerSettingsNewOutlinedIcon />
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
