import { useState } from "react";
import AddEnquiryForm from "@components/AddEnquiryForm";
import Grid from "@mui/material/Grid";
import TicketStatus from "@components/TicketStatus";
import "./styles.scss";
import {
  enquiryStatus,
  addEnquiry,
  enquiryList,
  updateEnquiry,
  allEmpList,
  areaList,
} from "@reducers/api";
import { reset } from "@reducers/enquiry";
import { useNavigate } from "react-router-dom";
import {
  useComponentDidMount,
  paramsQuery,
  useComponentDidUpdate,
  useComponentWillUnmount,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import BreadCrumb from "@components/Breadcrumb";

const ticketStatusData = [
  {
    id: 1,
    title: "Open",
    count: 4,
    icon: "",
  },
  {
    id: 2,
    title: "Resolved",
    count: 2,
    icon: "",
  },
  {
    id: 3,
    title: "Pending",
    count: 5,
    icon: "",
  },
  {
    id: 4,
    title: "In-Progress",
    count: 9,
    icon: "",
  },
  {
    id: 5,
    title: "Closed",
    count: 0,
    icon: "",
  },
];

export default function AddEnquiry(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { enquirystatusData, enquiryAdded, enquiryListData } = useSelector(
    (state) => state.enquiry
  );
  const { areaListData } = useSelector(
    (state) => state.subscriber
  );
  const { empFullData } = useSelector((state) => state.employee);
  let filtered =
    enquiryListData &&
    enquiryListData?.data?.filter((data) => data._id === paramsQuery("id"));

  const defaultPlanQuery = {
    serviceType: "cable",
    billingType: "",
  };

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;

    return query;
  };

  useComponentDidMount(() => {
    let id = paramsQuery("id");
    let user = JSON.parse(localStorage.getItem("user"));
    let distquery = `distributor_ref_id=${user?.distributor_ref_id}`;
    defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
    let query = queryGenerator();
    dispatch(allEmpList(query));
    dispatch(enquiryStatus(defaultPlanQuery));
    dispatch(areaList(distquery));
    if (id) return
    dispatch(enquiryList());
   
  }, []);

  useComponentDidUpdate(() => {
    let id = paramsQuery("id");
    let message = "";
    if (enquiryAdded) {
      if (id) {
        message = "Enquiry Updated";
      } else {
        message = "Enquiry Added";
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/distributor/enquiry-list`);
      dispatch(reset());


    }
  }, [enquiryAdded]);

  // useComponentWillUnmount(() => {
  //   dispatch(reset());
  // });

  const handleFormValues = (values) => {
    console.log(values, "asssignedd")
    let user = JSON.parse(localStorage.getItem("user"));
    let distributor_id = user?.distributor_ref_id;
    let id = paramsQuery("id");
    let payload = {
      name: values.userName,
      email_id: values.userEmail,
      area: values?.area ,
      subarea: values?.subArea ,
      mobile_no: values.phoneNo,
      enquiry_status: values.enquiryStatus?.value,
      enquiry_type: values.enquiryType?.value,
      assigned_to: values.assignedTo?.email_id,
      description: values.description,
      address: values.address,
      emp_ref_id: values.assignedTo?._id,
      staff_email: values.assignedTo?.email_id,
      staff_mobile_no: values.assignedTo?.mobile_no,
      staff_name: values.assignedTo?.name,
      complaint_forward_to: "services",
      distributor_ref_id: distributor_id,
      enquiry_taken_by: "AJP ANTHONY CABLE NETWORK",
      sms_access : user?.sms_access,
      whatsapp_access: user?.whatsapp_access,
    };
    if (id) {
      payload["_id"] = id;
      dispatch(updateEnquiry(payload));
    } else {
      dispatch(addEnquiry(payload));
    }
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  return (
    <div className="ticketList-container">

      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <div className="planHeading">Enquiry</div>
        </Grid>
        <Grid item xs={12} md={4} className="flex-end">
          <BreadCrumb
            defaultHeading="Home"
            handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
            secondaryHeading="Enquiry List"
            handleSecondaryClick={() => handleBreadCrumbRedirect("enquiry-list")}
            activeHeading={paramsQuery("id") ? "Edit Enquiry" : "Add Enquiry"}
          />
        </Grid>
      </Grid>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <TicketStatus
              className=" ticketCardBlue"
              title={ticketStatusData[0].title}
              count={
                enquirystatusData.openEnquiryCount
                  ? enquirystatusData?.openEnquiryCount
                  : 0
              }
              icon={ticketStatusData[0].icon}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <TicketStatus
              className=" ticketCardRed"
              title={ticketStatusData[2].title}
              count={
                enquirystatusData.pendingEnquiryCount
                  ? enquirystatusData?.pendingEnquiryCount
                  : 0
              }
              icon={ticketStatusData[2].icon}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <TicketStatus
              className=" ticketCardPink"
              title={ticketStatusData[3].title}
              count={
                enquirystatusData.inprogressEquiryCount
                  ? enquirystatusData?.inprogressEquiryCount
                  : 0
              }
              icon={ticketStatusData[3].icon}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <TicketStatus
              className=" ticketCardGreen"
              title={ticketStatusData[1].title}
              count={
                enquirystatusData.resolvedEnquiryCount
                  ? enquirystatusData?.resolvedEnquiryCount
                  : 0
              }
              icon={ticketStatusData[1].icon}
            />
          </Grid>


          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <TicketStatus
              className=" ticketCardPurple"
              title={ticketStatusData[4].title}
              count={
                enquirystatusData.closedenquiryCount
                  ? enquirystatusData?.closedenquiryCount
                  : 0
              }
              icon={ticketStatusData[4].icon}
            />
          </Grid>
        </Grid>
      </div>

      <div className="addEnquiry-container">
        <AddEnquiryForm
          data={filtered && filtered[0]}
          empDetails={empFullData}
          areaData={areaListData}
          handleFormValues={(values) => handleFormValues(values)}
        />
      </div>
    </div>
  );
}
