import moment from "moment";

const transactionRows = [
  {
    title: "Service Type",
    key: "service_type",
    align: "left",
  },

  {
    title: "Bill Amount",
    key: "bill_amount",
    align: "left",
  },
  {
    title: "Paid Amount",
    key: "collection_amount",
    align: "left",
  },
  {
    title: "Collected By",
    key: "collected_by",
    align: "left",
  },
  {
    title: "Collected Date",
    key: "collected_date", 
    align: "left",
  },
  {
    title: "Expiry Date",
    key: "expiry_date", 
    align: "left",
  },

  {
    title: "Due Amount",
    key: "due_amount",
    align: "left",
  },
  {
    title: "Remarks",
    key: "remarks",
    align: "left",
  },
];

export {
  transactionRows,
};
