const InvoiceReceiptRows = [
  {
    title: "Transaction ID",
    key: "transaction_id",
    align: "left",
  },
  {
    title: "Bill Amount",
    key: "bill_amount",
    align: "left",
  },
  {
    title: "Received By",
    key: "payment_received_by",
    align: "left",
  },
  {
    title: "Collection Amount",
    key: "collection_amount",
    align: "left",
  },
  {
    title: "Discount",
    key: "discount_amount",
    align: "left",
  },
  {
    title: "Due Amount",
    key: "due_amount",
    align: "left",
  },
  {
    title: "Advance Amount",
    key: "advance_amount",
    align: "left",
  },
  {
    title: "Payment Status",
    key: "payment_status",
    align: "left",
    justify: "center",
  },
  {
    title: "Generated Date",
    key: "created_date",
    align: "left",
  },
  {
    title: "Payment Date",
    key: "payment_date",
    align: "left",
  },
  {
    title: "Collection Date",
    key: "collected_date",
    align: "left",
  },
  {
    title: "Remarks",
    key: "remarks",
    align: "left",
  },
  {
    title: "Action",
    key: "action",
    align: "left",
  },
];

export { InvoiceReceiptRows };
