import { createSlice } from "@reduxjs/toolkit";
import { deleteEmployee, distEmployeeList, addEmployee, updateEmployee, employeeActive, allEmpList,getEmployeeCount ,resetPwdPopup} from "./api";

const initialState = {
  distEmployeeListData: [],
  empFullData: [],
  employeeDeleted: false,
  employeeAdded: false,
  employeeUpdate: false,
  updatedMsg: null,
  allEmpData: [],
  resetPwdPopupData: [],
  getEmpCountData:null,
  resetPwdPopupData:false
};

export const planSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    reset: (state) => {
      state.employeeDeleted = false;
      state.employeeAdded = false;
      state.employeeUpdate = false;
      state.updatedMsg = null;
      state.resetPwdPopupData=false;
      return state;
    },
  },
  extraReducers: {
    [distEmployeeList.pending]: (state) => {},
    [distEmployeeList.fulfilled]: (state, { payload }) => {
      state.distEmployeeListData = payload.data.data;
    },
    [distEmployeeList.rejected]: (state, { payload }) => {},

    [allEmpList.pending]: (state) => {},
    [allEmpList.fulfilled]: (state, { payload }) => {
      state.empFullData = payload.data.data;
    },
    [allEmpList.rejected]: (state, { payload }) => {},

    [deleteEmployee.pending]: (state) => {},
    [deleteEmployee.fulfilled]: (state, { payload }) => {
      state.employeeDeleted = payload.data.status;
    },
    [deleteEmployee.rejected]: (state, { payload }) => {},

    [addEmployee.pending]: (state) => {},
    [addEmployee.fulfilled]: (state, { payload }) => {
      state.employeeAdded = payload.data;
    },
    [addEmployee.rejected]: (state, { payload }) => {},

    [updateEmployee.pending]: (state) => {},
    [updateEmployee.fulfilled]: (state, { payload }) => {
      state.employeeUpdate = payload.data;
    },
    [updateEmployee.rejected]: (state, { payload }) => {},

    [employeeActive.pending]: (state) => {},
    [employeeActive.fulfilled]: (state, { payload }) => {
      state.employeeAdded = payload.data.status;
      state.updatedMsg = payload.data.status;
    },
    [employeeActive.rejected]: (state, { payload }) => {},

    [getEmployeeCount.pending]: (state) => {},
    [getEmployeeCount.fulfilled]: (state, { payload }) => {
      state.getEmployeeCountData = payload.data.data;
    },
    [getEmployeeCount.rejected]: (state, { payload }) => {},

    [resetPwdPopup.pending]: (state) => {},
    [resetPwdPopup.fulfilled]: (state, { payload }) => {     
      state.resetPwdPopupData = payload.data.status;
    },
    [resetPwdPopup.rejected]: (state, { payload }) => {},
  },
});
export const { reset } = planSlice.actions;
export default planSlice.reducer;
