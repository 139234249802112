import "./styles.scss";
import Avatar from "@mui/material/Avatar";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";



export default function SubViewCard(props) {
  const navigate = useNavigate();
  const lableAndValue = (title, value) => {
    return (
      <div className="spacing-between-tiles">
        <div className="sub-card-dark-text">{title}</div>
        <div className="secondarytext-sub">{value}</div>
      </div>
    );
  };
  const lableAndValuethree = (title, value, valuetwo) => {
    return (
      <div className="spacing-between-tiles">
        <div className="sub-card-dark-text">{title}</div>
        <div className="secondarytext-sub overflowSub" title={value}>{value}  {valuetwo}</div>
      </div>
    );
  };

  return (
    <div className="sub-edit-card">
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={4} md={2} className="avatarsubedit">
          <Avatar alt={props?.data?.name} src={`data:image/png;base64,${props?.data?.fileData}`} sx={{ width: 150, height: 150, margin: "auto" }} />
          <div className="secondarytext-sub" style={{ marginTop: 10 }}>
          </div> 
        </Grid>
        <Grid item xs={4} sm={4} md={10}>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={4} md={2.4}>
              {lableAndValue("Name", props?.data?.name)}
            </Grid>
            <Grid item xs={4} sm={4} md={2.4}>
              {lableAndValue("Mobile Number", props?.data?.mobile_no)}
            </Grid>
            <Grid item xs={4} sm={4} md={2.4}>
              {lableAndValuethree("Email", props?.data?.email_id)}
            </Grid>
            <Grid item xs={4} sm={4} md={2.4}>
              {lableAndValuethree("Account number", props?.data?.account_no)}
            </Grid>  
            <Grid item xs={4} sm={4} md={2.4}>
              {lableAndValuethree("Account Id", props?.data?.account_id)}
            </Grid>         
          </Grid>         
        </Grid>
      </Grid>
    </div>
  );
}
