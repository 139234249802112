import TicketStatus from "@components/TicketStatus";
import "./style.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchWithButtons from "@components/SearchWithButtons";
import Grid from "@mui/material/Grid";
import TicketFilter from "@components/TicketFilter";
import { useNavigate } from "react-router-dom";
import {
  useComponentDidMount,
  useComponentDidUpdate,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { ticketOpenStatus, ticketList, deleteTicket } from "@reducers/api";
import { useState } from "react";
import { useSnackbar } from "notistack";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import { reset } from "@reducers/ticketing";
import BreadCrumb from "@components/Breadcrumb";


const ticketStatusData = [
  {
    id: 1,
    title: "Open Tickets",
    count: 30,
    icon: "",
  },
  {
    id: 2,
    title: "Closed Tickets",
    count: 28,
    icon: "",
  },
  {
    id: 3,
    title: "Pending Tickets",
    count: 13,
    icon: "",
  },
  {
    id: 4,
    title: "InProgress Tickets",
    count: 8,
    icon: "",
  },
  {
    id: 5,
    title: "Resolved Tickets",
    count: 8,
    icon: "",
  },
];

const priority = [
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
];

const statusdrop = [
  { label: "Open", value: "open" },
  { label: "Pending", value: "pending" },
  { label: "Resolved", value: "resolved" },
  { label: "Closed", value: "closed" },
  { label: "In Progress", value: "inprogress" },
];

export default function TicketList(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ticketStatusOpenData, ticketListData, ticketDeleted } = useSelector(
    (state) => state.ticketing
  );
  const [ticketingList, setticketList] = useState([]);
  const [ticketListExport, setTicketListExport] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [dateFrom, setDateFromQuery] = useState("");
  const [dateTo, setDateToQuery] = useState("");
  const [filterOne, setFirstQuery] = useState("");
  const [filtertwo, setSecondQuery] = useState("");
  const defaultPlanQuery = {
    serviceType: "cable",
    billingType: "",
  };

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;
    if (value?.type === "search") {
      query += `&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
    let query = queryGenerator();
    dispatch(ticketOpenStatus(defaultPlanQuery));
    dispatch(ticketList(query));
  }, []);

  useComponentDidUpdate(() => {
    let list = [];
    let listForExport = [];
    ticketListData?.data.map((data, index) => {
      list.push({
        sNo: (page - 1) * 10 + index + 1,
        ticketId: (
          <div
            onClick={() =>
              navigate(
                `/distributor/ticket-view?id=${data._id}&custRefId=${data.cust_ref_id}`
              )
            }
            className="cur-pointer"
          >
            {data?.ticket_id}
          </div>
        ),
        name: data?.user_name,
        team: data?.complaint_forward_to,
        staff: data?.staff_name,
        title: data?.title,
        ticketType: data?.type,
        priority: data?.priority,
        status: status("#73ca72", data?.complaint_status),
        dueDate: moment(data?.due_date).format("MMMM Do YYYY, h:mm a"),
        createdDate: moment(data?.created_at).format("MMMM Do YYYY, h:mm a"),
        action: (
          <div className="flex-row justify-center">
            <div
              onClick={() => navigate(`/distributor/add-ticket?id=${data._id}`)}
              className="cur-pointer"
            >
              <EditIcon />
            </div>
            <div
              className="cur-pointer"
              onClick={() =>
                dispatch(
                  deleteTicket({
                    _id: data?._id,
                  })
                )
              }
            >
              <DeleteIcon />
            </div>
          </div>
        ),
      });

      listForExport.push({
        sNo: (page - 1) * 10 + index + 1,
        ticketId: data?.ticket_id,
        name: data?.user_name,
        team: data?.complaint_forward_to,
        staff: data?.staff_name,
        title: data?.title,
        ticketType: data?.type,
        priority: data?.priority,
        status:  data?.complaint_status,
        dueDate: moment(data?.due_date).format("MMMM Do YYYY, h:mm a"),
        createdDate: moment(data?.created_at).format(" MMMM Do YYYY, h:mm a"),
      });
    });

    setticketList(list);
    setTicketListExport(listForExport);
  }, [ticketListData]);

  const status = (color, text) => {

    let colorVal = "#73ca72";
    if (text === "inprogress") {
      colorVal = "#d352a5";
    } else if (text === "pending") {
      colorVal = "#d35352";
    } else if (text === "resolved") {
      colorVal = "#7152d3";
    } else if (text === "open") {
      colorVal = "#00249B";
    } else if (text === "closed") {
      colorVal = "#47bc47";
    }
    return (
      <div className="status-btn-table" style={{ backgroundColor: colorVal }}>
        {text}
      </div>
    );
  };

  const handleRedirect = () => {
    navigate("/distributor/add-ticket");
  };

  useComponentDidUpdate(() => {
    if (ticketDeleted) {
      enqueueSnackbar("Ticket Deleted !", {
        variant: "success",
      });
      dispatch(reset());
      let user = JSON.parse(localStorage.getItem("user"));
      defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
      let query = queryGenerator();
      dispatch(ticketOpenStatus(defaultPlanQuery));
      dispatch(ticketList(query));
    }
  }, [ticketDeleted]);

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    let query = queryGenerator({ type: "search", state: search });
    dispatch(ticketList(query));
  };

  const handleChange = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(
      ticketList(
        `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=${value - 1}0${searchQuery ? `&value=${searchQuery}` : ""}${dateFrom? `&startDate=${moment(dateFrom).format("YYYY-MM-DDTHH:mm:ss")}`: ""}${ dateTo ? `&endDate=${moment(dateTo).format("YYYY-MM-DDTHH:mm:ss")}` : ""}${filterOne? `&priority=${filterOne}`: ""}${filtertwo? `&complaint_status=${filtertwo}`: ""} `
      )
    );
  };

  const handleClickSearcch = (value, value1, priorityval, statusval) => {
    let user = JSON.parse(localStorage.getItem("user"));
    setPage(1);
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;
    if (value) {
      query += `&startDate=${moment(value).format("YYYY-MM-DDTHH:mm:ss")}`;
    }
    if (value1) {
      query += `&endDate=${moment(value1).format("YYYY-MM-DDTHH:mm:ss")}`;
    }
    if (priorityval) {
      query += `&priority=${priorityval?.value}`;
    }
    if (statusval) {
      query += `&complaint_status=${statusval?.value}`;
    }
    dispatch(ticketList(`${query}`));
    setDateFromQuery(value);
    setDateToQuery(value1);
    setFirstQuery(priorityval?.value);
    setSecondQuery(statusval?.value);
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  const handleReset = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
    let query = queryGenerator();
    dispatch(ticketOpenStatus(defaultPlanQuery));
    dispatch(ticketList(query));
    setDateFromQuery("");
    setDateToQuery("");
    setFirstQuery("");
    setSecondQuery("");
    setPage(1);
  };

  return (
    <div>
      <div className="ticketList-container">
        <Grid container spacing={2}>
          <Grid item xs={6} md={8}>
            <div className="planHeading">Ticket Status</div>
          </Grid>
          <Grid item xs={6} md={4} className="flex-end">
            <div className="employeelist-title">
              <BreadCrumb
                defaultHeading="Home"
                handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
                activeHeading="Ticket List"
                handleActiveClick={() =>
                  handleBreadCrumbRedirect("ticket-list")
                }
              />
            </div>
          </Grid>
        </Grid>
        <div>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.4}
              style={{ paddingLeft: 0, paddingBottom: 0 }}
            >
              <TicketStatus
                className=" ticketCardBlue"
                title={ticketStatusData[0].title}
                count={
                  ticketStatusOpenData.openTicketCount
                    ? ticketStatusOpenData?.openTicketCount
                    : 0
                }
                icon={ticketStatusData[0].icon}
              />
            </Grid>
           
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.4}
              style={{ paddingLeft: 0, paddingBottom: 0 }}
            >
              <TicketStatus
                className=" ticketCardRed"
                title={ticketStatusData[2].title}
                count={
                  ticketStatusOpenData.pendingTicketCount
                    ? ticketStatusOpenData?.pendingTicketCount
                    : 0
                }
                icon={ticketStatusData[2].icon}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.4}
              style={{ paddingLeft: 0, paddingBottom: 0 }}
            >
              <TicketStatus
                className=" ticketCardPink"
                title={ticketStatusData[3].title}
                count={
                  ticketStatusOpenData?.inprogressTicketCount
                    ? ticketStatusOpenData?.inprogressTicketCount
                    : 0
                }
                icon={ticketStatusData[3].icon}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.4}
              style={{ paddingLeft: 0, paddingBottom: 0 }}
            >
              <TicketStatus
                className=" ticketCardPurple"
                title={ticketStatusData[4].title}
                count={
                  ticketStatusOpenData?.resolvedTicketCount
                    ? ticketStatusOpenData?.resolvedTicketCount
                    : 0
                }
                icon={ticketStatusData[4].icon}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.4}
              style={{ paddingLeft: 0, paddingBottom: 0 }}
            >
              <TicketStatus
                className=" ticketCardGreen"
                title={ticketStatusData[1].title}
                count={
                  ticketStatusOpenData.closedTicketCount
                    ? ticketStatusOpenData?.closedTicketCount
                    : 0
                }
                icon={ticketStatusData[1].icon}
              />
            </Grid>
          </Grid>
        </div>
        <div className="ticket-list-table-cntr">
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={4} md={3}>
              <div className="planHeading">Ticket List</div>
            </Grid>
            <Grid item xs={0} sm={4} md={6}></Grid>
            <Grid item xs={4} sm={4} md={3} className="flex-end">
              <div className="">
                <TicketFilter
                  labelPrimary={"Priority"}
                  PrimaryOption={priority}
                  SecondaryOption={statusdrop}
                  labelSecondary={"Status"}
                  handleFormValues={(data1, data2, data3, data4) =>
                    handleClickSearcch(data1, data2, data3, data4)
                  }
                  handleResetClick = {handleReset}
                />
              </div>
            </Grid>
          </Grid>
          <div className="search-btns-padding">
            <SearchWithButtons
              primaryButton={{
                title: "Add Ticket",
                type: "contained",
                color: "white",
                bgColor: COLORS.primary,
              }}
              secondaryButton={{
                title: "Export",
                type: "outlined",
                color: COLORS.primary,
                filename: "Ticket list",
                data: ticketListExport,
              }}
              handlePrimaryClick={handleRedirect}
              // handleSecondaryClick={}
              handleChange={handleSearch}
            />
          </div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "15px",
              paddingBottom: "10px",
            }}
          >
            Showing entries {(page - 1) * 10 + 1} -{" "}
            {Math.ceil(ticketListData?.totalCount / 10) === page
              ? ticketListData?.totalCount
              : page + "0"}{" "}
            of {ticketListData?.totalCount}
          </div>

          <TableView
            rows={tableRows}
            data={ticketingList}
            color={COLORS.primary}
          />
          <div
            style={{
              marginTop: 20,
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Pagination
              count={Math.ceil(ticketListData?.totalCount / 10) || 0}
              defaultPage={1}
              page={page}
              onChange={(event, value) => {
                setPage(value);
                handleChange(value);
              }}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
