import "./styles.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchWithButtons from "@components/SearchWithButtons";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { useComponentDidMount, useComponentDidUpdate, useComponentWillUnmount } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { deletePlan, planList, planListsExport, broadcasterAdmin, PlanMultipleDelete } from "@reducers/api";
import { reset } from "@reducers/plan";
import { useState } from "react";
import { useSnackbar } from "notistack";
import BreadCrumb from "@components/Breadcrumb";
import { Grid } from "@mui/material";
import PlanListFilter from "@components/PlanListFilter";
import BroadCastBulk from "@components/broadcast-bulkupload/bulkUpload";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 800,
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

const category = [
  { label: "Ala Cart", value: "ala cart" },
  { label: "Broadcaster", value: "broadcaster" },

];

export default function PlanList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [plansList, setPlanList] = useState([]);
  const [planListExport, setPlanListExport] = useState([]);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [broadQuery, setBroadQuery] = useState("");
  const [filterOne, setFirstQuery] = useState("");
  const [filtertwo, setSecondQuery] = useState("");
  const [filterthree, setThirdQuery] = useState("");
  const [checkedVal, setChecked] = useState(false);
  const { planListData, planDeleted, planListsExportData, broadcasterAdminData, bulkUploadBroadCast, planMultipleData } = useSelector((state) => state.plan);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRedirect = () => {
    navigate("/admin/add-plan");
  };

  useComponentDidMount(() => {
    dispatch(planList("package_category=ala cart,broadcaster&limit=10&offset=0"));
    dispatch(broadcasterAdmin());
  }, []);

  useComponentWillUnmount(() => {
    dispatch(reset());
  });

  const handleClickSearcch = (priorityval, statusval, categoryval, broadcastCat) => {
    let packageQuery = "";
    setPage(1);
    let query = `&package_category=ala cart,broadcaster`;

    if (priorityval) {
      query += `&service_type=${priorityval?.value}`
    }
    if (statusval) {
      query += `&billing_type=${statusval?.value}`
    }
    if (categoryval) {

      packageQuery += `&package_category=${categoryval?.value}`
    }
    if (broadcastCat) {
      query += `&broadcaster=${broadcastCat}`
    }

    setFirstQuery(priorityval?.value);
    setSecondQuery(statusval?.value);
    setThirdQuery(categoryval?.value);
    setBroadQuery(broadcastCat)
    dispatch(planList(`limit=10&offset=0${categoryval?.value ? `&package_category=${categoryval?.value}${broadcastCat ? `&broadcaster=${broadcastCat}` : ""}${statusval?.value ? `&billing_type=${statusval?.value}` : ""}` : `${query}`}`));
  };

  const handleReset = () => {
    dispatch(planList("package_category=ala cart,broadcaster&limit=10&offset=0"));
    setFirstQuery("");
    setSecondQuery("");
    setThirdQuery("");
    setBroadQuery("")
    setPage(1);
  };

  const handleChange = (value) => {
    dispatch(planList(`${filterthree ? `&package_category=${filterthree}${filterOne ? `&service_type=${filterOne}` : ""}${filtertwo ? `&billing_type=${filtertwo}` : ""}` : `package_category=ala cart,broadcaster`}&limit=10&offset=${value - 1}0${searchQuery ? `&value=${searchQuery}${filterOne ? `&service_type=${filterOne}` : ""}${filtertwo ? `&billing_type=${filtertwo}` : ""}${broadQuery ? `&broadcaster=${broadQuery}` : ""}` : ""}`));
  };

  useComponentDidUpdate(() => {
    let list = [];
    let listForExport = [];
    if (planListData?.data?.length > 0) {
      planListData?.data &&
        planListData?.data?.map((data, index) => {

          list.push({
            sNo: (page - 1) * 10 + index + 1,
            planName: data?.plan_name,
            amount: data?.plan_amount,
            serviceType: data?.service_type,
            quality: data?.channel_type,
            validity: data?.plan_validity,
            billingType: data?.billing_type,
            category: data?.package_category,
            channelcount: data?.channels?.length || 0,
            broadcstcategory: data?.broadcaster,
            id : data?._id,
            action: (
              <div className="flex-row justify-center">
                <div onClick={() => navigate(`/admin/add-plan?id=${data._id}`)} className="cursor-pointer">
                  <EditIcon />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    dispatch(
                      deletePlan({
                        _id: data?._id,
                      })
                    )
                  }
                >
                  <DeleteIcon />
                </div>
              </div>
            ),
          });
          listForExport.push({
            sNo: (page - 1) * 10 + index + 1,
            planName: data?.plan_name,
            amount: data?.plan_amount,
            serviceType: data?.service_type,
            quality: data?.channel_type,
            validity: data?.plan_validity,
            billingType: data?.billing_type,
            category: data?.package_category,

          });
        });
    }

    setPlanList(list);
    setPlanListExport(listForExport);
  }, [planListData]);

  useComponentDidUpdate(() => {
    if (planDeleted) {
      enqueueSnackbar("Plan Deleted !", {
        variant: "success",
      });
      setSearchQuery("");
      dispatch(reset());
      dispatch(planList("package_category=ala cart,broadcaster&limit=10&offset=0"));
      setPage(1);
    }
  }, [planDeleted]);

  useComponentDidUpdate(() => {
    if (bulkUploadBroadCast) {
      enqueueSnackbar("File Uploaded Successfully !", {
        variant: "success",
      });
      handleClose()
      dispatch(reset());
      dispatch(planList("package_category=ala cart,broadcaster&limit=10&offset=0"));
    }
  }, [bulkUploadBroadCast]);

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    dispatch(planList(`package_category=ala cart,broadcaster&limit=10&offset=0&value=${search}`));

  };



  const handleBreadCrumbRedirect = (path) => {
    navigate(`/admin/${path}`);
  };

  const handleExtraExportClick = () => {
    dispatch(planListsExport(`admin=1&package_category=ala cart,broadcaster`));
  };

  useComponentDidUpdate(() => {
    if (planListsExportData) {
      if (planListsExportData.fileData) {
        window.location.href = `data:application/vnd.ms-excel;base64,${planListsExportData.fileData}`
      }
      if (planListsExportData?.msg == "no data found") {
        enqueueSnackbar("No data....!", {
          variant: "error",
        });
      }
      dispatch(reset())
    }
  }, [planListsExportData]);

  const handleCheckBox = (index, checked) => {
    const clonedDataService = [...plansList];
    if (index === 0 || index > 0) {
      clonedDataService[index].checked = clonedDataService[index].checked
        ? false
        : true;
    } else if (index === "all") {
      for (let services of clonedDataService) {
        if (checked) {
          services.checked = true;
        } else {
          services.checked = false;
        }
      }
    }
    setPlanList(clonedDataService);
    setChecked(checked)
  };

  const handleMultipleDelete = () => {
    let filtered = plansList.filter((options) => options.checked === true);

    if (filtered.length == 0) {
      enqueueSnackbar("Please select atlease one to delete!", {
        variant: "error",
      });
   
      return;
    }
    let preparedeletepay = [];
    console.log(filtered, "filter")
    filtered.map((data, index) => {
      preparedeletepay.push(data.id);
    });
    dispatch(PlanMultipleDelete(preparedeletepay));
  };

  useComponentDidUpdate(() => {
    if (planMultipleData) {
      enqueueSnackbar("Deleted !", {
        variant: "success",
      });
      dispatch(planList("package_category=ala cart,broadcaster&limit=10&offset=0"));
      setPage(1);
      setChecked(false)
    }
  }, [planMultipleData]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="broadcastMultiple">
          <BroadCastBulk handleClose={handleClose} />
        </Box>

      </Modal>

      <div className="planList-container">
        <Grid container spacing={2}>
          <Grid item xs={6} md={8}>
            <div className="planlist-title">Plan List</div>
          </Grid>
          <Grid item xs={6} md={4} className="flex-end">
            <div className="planlist-title">
              <BreadCrumb
                defaultHeading="Home"
                handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
                activeHeading="Plan List"
                handleActiveClick={() => handleBreadCrumbRedirect("plan-list")}
              />
            </div>

          </Grid>
        </Grid>
        <div className="planList-table-padding">
          <div className="search-btns-padding">
            <SearchWithButtons
              primaryButton={{
                title: "Add Plan",
                type: "contained",
                color: "white",
                bgColor: COLORS.primary,

              }}
              secondryExtraButtonExportData={{
                title: "Export",
                type: "contained",
                color: COLORS.primary,
                bgColor: COLORS.primary,
              }}
              extraButton={{
                title: "Broadcaster upload",
                type: "contained",
                color: "white",
                bgColor: COLORS.primary,
              }}
              secondaryButton={{
                title: "Multiple Delete",
                type: "contained",
                color: "white",

                bgColor: COLORS.primary,
              }}
              handleExtraExportClick={() => handleExtraExportClick("export")}
              handlePrimaryClick={handleRedirect}
              handleChange={handleSearch}
              handleExtraClick={() => handleOpen()}
              handleSecondaryClick={handleMultipleDelete}
            />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <div style={{ fontWeight: 'bold', fontSize: '15px', paddingBottom: '10px' }}>Showing entries {(page - 1) * 10 + 1} - {Math.ceil(planListData?.totalCount / 10) == page ? planListData?.totalCount : page + "0"} of {planListData?.totalCount}</div>
            </Grid>
            <Grid item xs={4} className="flex-end">
              <div className="employeelist-title">
                <PlanListFilter
                  broadcast={"Broadcast Category"}
                  broadCastOption={broadcasterAdminData}
                  labelThird={"Package Category"}
                  thirdOption={category}
                  handleResetClick={handleReset}
                  handleFormValues={(data1, data2, data3, data4) => handleClickSearcch(data1, data2, data3, data4)} />
              </div>

            </Grid>
          </Grid>

          <TableView rows={tableRows} data={plansList} color={COLORS.primary}
            handleCheckBoxChange={handleCheckBox}
            checkedVal={checkedVal}
          />
          <div style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}>
            <Pagination
              count={Math.ceil(planListData?.totalCount / 10)}
              defaultPage={1}
              page={page}
              onChange={(event, value) => {
                setPage(value);
                handleChange(value);
              }}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
