import "./styles.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import EditIcon from "@mui/icons-material/Edit";
import RestoreIcon from '@mui/icons-material/Restore';
import SearchWithButtons from "@components/SearchWithButtons";
import { useNavigate } from "react-router-dom";
import { useComponentDidMount, useComponentDidUpdate } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { SubscriberListAssigned, deleteAssignSubs, SubscriberListMultipleDelete, SubscriberListMultiplePermanentDelete } from "@reducers/api";
import { useState } from "react";
import Pagination from "@mui/material/Pagination";
import AssignSubscriberModal from "../../../components/Modals/assignSubscriberPopup";
import { useSnackbar } from "notistack";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { set } from "date-fns/esm";
import Grid from "@mui/material/Grid";
import BreadCrumb from "@components/Breadcrumb";


export default function EmployeeList(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { SubscriberListAssignedData, SubscriberListMultipleDeleteData, deleteRes, permanentDelData, SubscriberRestoreData, restoreSubs } = useSelector((state) => state.subscriber);
  const [expList, setexpList] = useState([]);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [openAll, setopenAll] = useState(false);  
  const [restoreopen, setRestoreOpen] = useState(false);
  const [currentService, setcurrentService] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [checkedVal, setChecked] = useState(false);

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&is_deleted=true`;
    if (value?.type === "search") {
      query += `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };

  useComponentDidMount(() => {
    let query = queryGenerator();
    dispatch(SubscriberListAssigned(query));
  }, []);

  useComponentDidUpdate(() => {
    if (SubscriberRestoreData?.data?.msg === "Updated Subscribers details Successfully") {
      enqueueSnackbar("Restored successfully !", {
        variant: "success",
      });
      let query = queryGenerator();
      dispatch(SubscriberListAssigned(query));
    }
    if (SubscriberRestoreData?.data?.msg === "Subscriber limit count exceed") {
      enqueueSnackbar("Subscriber limit count exceed!", {
        variant: "error",
      });
      let query = queryGenerator();
      dispatch(SubscriberListAssigned(query));
    }
  }, [SubscriberListMultipleDeleteData, SubscriberRestoreData]);

  useComponentDidUpdate(() => {
   
    if (restoreSubs?.data?.msg === "Updated Subscribers details Successfully") {
      enqueueSnackbar("Restored successfully !", {
        variant: "success",
      });
      let query = queryGenerator();
      dispatch(SubscriberListAssigned(query));
    }
    if (restoreSubs?.data?.msg === "Subscriber limit count exceed") {
      enqueueSnackbar("Subscriber limit count exceed!", {
        variant: "error",
      });
      let query = queryGenerator();
      dispatch(SubscriberListAssigned(query));
    }
  }, [deleteRes, restoreSubs]);

  useComponentDidUpdate(() => {
    if (permanentDelData) {
      enqueueSnackbar("Data permanently deleted !", {
        variant: "success",
      });
      let query = queryGenerator();
     
      dispatch(SubscriberListAssigned(query));
      setopenAll(!openAll);
    }
  }, [permanentDelData]);




  useComponentDidUpdate(() => {
    let list = [];
    SubscriberListAssignedData?.data.map((data, index) => {
      list.push({
        checked: false,
        open: false,
        id: data?._id,
        sNo: (page - 1) * 10 + index + 1,
        name: data?.name,
        accNo: data?.account_id,
        canId: data?.can_id,
        stbNo: data?.stb_no[0]?.stb_no,
        serviceType: data?.service_type,
        billingType: data?.billing_type,
        mobileno: data?.mobile_no,
        billType: data?.billing_type,
        paidBy: "",
        email: data?.email_id,
        area: data?.area,
        flat: data?.flat_no,
        floor: data?.floor,
        wings: data?.wings,
        city: data?.city,
        state: data?.state,
        status: moment(data?.created_at).format("ll"),
        action: (
          <div className="flex-row justify-center">
            <div
              className="cursor-pointer"
              onClick={() =>
                dispatch(
                  SubscriberListMultipleDelete([{
                    "is_deleted": false,
                    "_id": data._id,
                    "restore":1,
                  }])
                )
              }
            >
              <RestoreIcon />
            </div>
          </div>
        ),
      });
    });
    setexpList(list);
  }, [SubscriberListAssignedData]);



  const handleSearch = (search) => {
    let user = JSON.parse(localStorage.getItem("user"));
    setPage(1);
    setSearchQuery(search);
    dispatch(SubscriberListAssigned(`distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&value=${search}&is_deleted=true`));
  };

  const handleChange = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(SubscriberListAssigned(`distributor_ref_id=${user?.distributor_ref_id}&is_deleted=true&limit=10&offset=${value - 1}0${searchQuery ? `&value=${searchQuery}` : ""}`));
  };


  const handleClickCancel = () => {
    setOpen(!open)
  }

  const handleClickRestoreCancel = () => {
    setRestoreOpen(!restoreopen)
  }

  const handleClickRestore = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let distid = user?.distributor_ref_id;
    let filtered = expList.filter((options) => options.checked === true);
    let preparedeletepay = []
    filtered.map((data, index) => {
      preparedeletepay.push({
        "is_deleted": false,
        "_id": data.id,
        "restore": 1,
        "distributor_ref_id": distid
      });
    });
    dispatch(SubscriberListMultipleDelete(preparedeletepay))
    setChecked(false)
    handleClickRestoreCancel()
    
  }

  const handleClickCancelAll = () => {
    setopenAll(!openAll);
  };

  const handleAllDelete = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let distid = user?.distributor_ref_id; 
    dispatch(
      SubscriberListMultiplePermanentDelete({
        "sms_access": user?.sms_access,
        "whatsapp_access": user?.whatsapp_access,
        "distributor_id": distid
      })
    )
     
  };

const handleClickPermanent = () => {
    let filtered = expList.filter((options) => options.checked === true);
    let preparedeletepay = []
    filtered.map((data, index) => {
      preparedeletepay.push( data.id);
    });
    dispatch(SubscriberListMultiplePermanentDelete(preparedeletepay))
    handleClickCancel()
    setChecked(false)
  }

  const handleCheckBox = (index, checked) => {
    const clonedDataService = [...expList];
    if (index === 0 || index > 0) {
      clonedDataService[index].checked = clonedDataService[index].checked
        ? false
        : true;
    } else if (index === "all") {
      for (let services of clonedDataService) {
        if (checked) {
          services.checked = true;
        } else {
          services.checked = false;
        }
      }
    }
    setexpList(clonedDataService);
    setChecked(checked)

  };

  const handleMultipleDelete = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let distid = user?.distributor_ref_id;
    let filtered = expList.filter((options) => options.checked === true);
    if (filtered.length == 10) {
      setRestoreOpen(!restoreopen)
      return;
    }
    if (filtered.length == 0) {
      enqueueSnackbar("Please select atlease one to restore!", {
        variant: "error",
      });
      setShow(!show);
      return;
    }
    let preparedeletepay = []
    filtered.map((data, index) => {
      preparedeletepay.push({
        "is_deleted": false,
        "_id": data.id,
        "restore": 1,
        "distributor_ref_id": distid
      });
    });
    dispatch(SubscriberListMultipleDelete(preparedeletepay))
  }

  const handleMultiplePermanentDelete = () => {
    let filtered = expList.filter((options) => options.checked === true);
    if (filtered.length == 10) {
      setOpen(!open)
      return;
    }
    if (filtered.length == 0) {
      enqueueSnackbar("Please select atlease one to delete!", {
        variant: "error",
      });
      setShow(!show);
      return;
    }
    let preparedeletepay = []
    filtered.map((data, index) => {
      preparedeletepay.push(data.id);
    });
    dispatch(SubscriberListMultiplePermanentDelete(preparedeletepay))
  }

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };


  const handleAllSubscribersDelete = () => { 
    setopenAll(!openAll);
  }

  return (
    <div>

      <div className="deleteSubsList-container">

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <div className="employeelist-title">Restore Subscriber</div>
          </Grid>
          <Grid item xs={4} className="flex-end">
            <div className="employeelist-title">
              <BreadCrumb
                defaultHeading="Home"
                handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
                secondaryHeading="Delete Subscriber"
                handleSecondaryClick={() => handleBreadCrumbRedirect("delete-subscriber")}
                activeHeading="Restore Subscriber"
                handleActiveClick={() => handleBreadCrumbRedirect("delete-restore-subscriber")}
              />
            </div>

          </Grid>
        </Grid>

        <div className="search-btns-padding">
          <SearchWithButtons
            primaryButton={{
              title: "Restore",
              type: "delete",
              color: "white",
              bgColor: COLORS.primary,
            }}
            secondaryButton={{
              title: "Permanent delete",
              type: "delete",
              color: "white",
              bgColor: COLORS.primary,
            }}
            teritorybutton={{
              title: "Permanent delete All",
              type: "delete",
              color: "white",
              bgColor: COLORS.primary,
            }}
            handleTeritoryClick={handleAllSubscribersDelete}
            handleSecondaryClick={handleMultiplePermanentDelete}
            handlePrimaryClick={handleMultipleDelete}
            handleChange={handleSearch}
          />
        </div>
        <div style={{ fontWeight: 'bold', fontSize: '15px', paddingBottom: '10px' }}>Showing entries {(page - 1) * 10 + 1} - {Math.ceil(SubscriberListAssignedData?.totalCount / 10) == page ? SubscriberListAssignedData?.totalCount : page + "0"} of {SubscriberListAssignedData?.totalCount}</div>

        <TableView
          rows={tableRows}
          data={expList}
          color={COLORS.primary}
          handleCheckBoxChange={handleCheckBox}
          checkedVal={checkedVal}
        />
        <SweetAlert
          warning
          btnSize="sm"
          custom
          show={open}
          //mnncustomIcon={<InfoOutlinedIcon fontSize={'20px'}/>}
          customButtons={
            <div className="dips" style={{ display: 'flex' }}

            >
              {

                <Stack spacing={2} direction="row" className=" ">
                  <Button
                    type="submit"
                    onClick={handleClickPermanent}
                    variant="contained"
                    className="bg-blue"
                  >
                    Submit
                  </Button>
                </Stack>

              }
              &nbsp;&nbsp;
              {

                <Stack spacing={2} direction="row" className=" ">
                  <Button
                    type="submit"
                    onClick={handleClickCancel}
                    variant="contained"
                    className="bg-blue"
                  >
                    Cancel
                  </Button>
                </Stack>

              }
            </div>
          }
          title={
            <span className={"confirmationTitle"}>
              <span>Are you sure want to Delete?</span>
              {/* <IntlMessages id="confirm_delete" /> */}
            </span>
          }
        >
          {/* <IntlMessages id="delete_information_message" /> */}

        </SweetAlert>

        <SweetAlert
          warning
          btnSize="sm"
          custom
          show={restoreopen}
          //mnncustomIcon={<InfoOutlinedIcon fontSize={'20px'}/>}
          customButtons={
            <div className="dips" style={{ display: 'flex' }}

            >
              {

                <Stack spacing={2} direction="row" className=" ">
                  <Button
                    type="submit"
                    onClick={handleClickRestore}
                    variant="contained"
                    className="bg-blue"
                  >
                    Submit
                  </Button>
                </Stack>

              }
              &nbsp;&nbsp;
              {

                <Stack spacing={2} direction="row" className=" ">
                  <Button
                    type="submit"
                    onClick={handleClickRestoreCancel}
                    variant="contained"
                    className="bg-blue"
                  >
                    Cancel
                  </Button>
                </Stack>

              }
            </div>
          }
          title={
            <span className={"confirmationTitle"}>
              <span>Are you sure want to Restore?</span>
              {/* <IntlMessages id="confirm_delete" /> */}
            </span>
          }
        >
          {/* <IntlMessages id="delete_information_message" /> */}

        </SweetAlert>
        <SweetAlert
          warning
          btnSize="sm"
          custom
          show={openAll}
          //mnncustomIcon={<InfoOutlinedIcon fontSize={'20px'}/>}
          customButtons={
            <div className="dips" style={{ display: "flex" }}>
              {
                <Stack spacing={2} direction="row" className=" ">
                  <Button
                    type="submit"
                    onClick={handleAllDelete}
                    variant="contained"
                    className="bg-blue"
                  >
                    Delete All
                  </Button>
                </Stack>
              }
              &nbsp;&nbsp;
              {
                <Stack spacing={2} direction="row" className=" ">
                  <Button
                    type="submit"
                    onClick={handleClickCancelAll}
                    variant="contained"
                    className="bg-blue"
                  >
                    Cancel
                  </Button>
                </Stack>
              }
            </div>
          }
          title={
            <span className={"confirmationTitle"}>
              <span>Are you sure want to delete all the subscribers?</span>
            </span>
          }
        >

        </SweetAlert>
        <div style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}>
          <Pagination
            count={Math.ceil(SubscriberListAssignedData?.totalCount / 10) || 0}
            defaultPage={1}
            page={page}
            onChange={(event, value) => {
              setPage(value);
              handleChange(value);
            }}
            variant="outlined"
            shape="rounded"
          />
        </div>

      </div>
    </div>
  );
}
