import { useEffect } from "react";
import PermanentDrawerLeft from "@components/sidebar/index";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector, useDispatch } from "react-redux";
import { showSideBar } from "@reducers/settings";
import Topbar from "@components/topbar/index";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import LanguageIcon from "@mui/icons-material/Language";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import "./styles.scss";

const arrayOfObjects = [
  { title: "Dashboard", icon: <ViewCompactIcon />, path: "/admin/dashboard" },
  { title: "Distributor", icon: <LanguageIcon />, path: "/admin/distributor-list" },
  { title: "Plans", icon: <FileCopyIcon />, path: "/admin/plan-list" },
];

export default function AdminLayout(props) {
  const SideBar = useSelector((state) => state.settings.showSideBar);
  const matches = useMediaQuery("(max-width:875px)");
  const dispatch = useDispatch();

  const handleSidebar = () => {
    dispatch(showSideBar(!SideBar));
  };

  useEffect(() => {
    if (matches) {
      dispatch(showSideBar(false));
    } else if (!matches) {
      dispatch(showSideBar(true));
    }
  }, [matches, dispatch]);

  return (
    <div>
      <div>
        <Topbar handleSidebar={handleSidebar} />
      </div>
      <div className={!SideBar ? "main-layout-left-none" : "main-layout-left"}>{props.children}</div>
      <div>
        <PermanentDrawerLeft status={SideBar} sidebarData={arrayOfObjects} />
      </div>
    </div>
  );
}
