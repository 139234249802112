import React from "react";
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "./style.scss";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

const validation = Yup.object({
  oldPassword: Yup.string().required("Old Password is required"),
  newPassword: Yup.string().required('New Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
});


export default function ChangePasswordForm(props) {

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });
  const onSubmit = (data) => {
    props?.handleFormValues(data);
    reset();
    setValue("oldPassword", "")
    setValue("newPassword", "")
    setValue("confirmPassword", "")
    setValues({
      amount: '',
      password: '',
      weight: '',
      weightRange: '',
      showPassword: false,
    });
    setNewValues({
      amount: '',
      password: '',
      weight: '',
      weightRange: '',
      showPassword: false,
    });
    setConfirmValues({
      amount: '',
      password: '',
      weight: '',
      weightRange: '',
      showPassword: false,
    });
  }
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const [newPassword, setNewValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const [confirmPsw, setConfirmValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleNewChange = (prop) => (event) => {
    setNewValues({ ...newPassword, [prop]: event.target.value });
  };

  const handleConfimChange = (prop) => (event) => {
    setConfirmValues({ ...confirmPsw, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickNewShowPassword = () => {
    setNewValues({
      ...newPassword,
      showPassword: !newPassword.showPassword,
    });
  };

  const handleClickConfirmShowPassword = () => {
    setConfirmValues({
      ...confirmPsw,
      showPassword: !confirmPsw.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="changepswForm-container">
      <div class="center expenseForm">
        <form onSubmit={handleSubmit(onSubmit)}>

          <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput createSearchForm">
            <label>Old Password</label>
            <FormControl  variant="outlined" className="txtwidth">
              <OutlinedInput
                id="outlined-adornment-password"
                {...register("oldPassword")}
                size="small"
                 variant="outlined" 
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Enter Your Old Password"
              />
            </FormControl>


            <div className="field-error">{errors.oldPassword?.message}</div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
            <label>New Password</label>
            <FormControl  variant="outlined" className="txtwidth">
              <OutlinedInput
                id="outlined-adornment-password"
                {...register("newPassword")} 
                size="small"
                type={newPassword.showPassword ? 'text' : 'password'}
                value={newPassword.password}
                onChange={handleNewChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickNewShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {newPassword.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Enter New Password"
              />
            </FormControl>


            <div className="field-error">{errors.newPassword?.message}</div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
            <label>Confirm Password</label>
            <FormControl  variant="outlined" className="txtwidth">
              <OutlinedInput
                id="outlined-adornment-password"
                {...register("confirmPassword")}
                size="small"
                type={confirmPsw.showPassword ? 'text' : 'password'}
                value={confirmPsw.password}
                onChange={handleConfimChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickConfirmShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {confirmPsw.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Enter Confirm Password"
              />
            </FormControl>

            <div className="field-error">{errors.confirmPassword?.message}</div>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2} direction="row" className="btnright createPswBtn">
              <Button type="submit" variant="contained" className="bg-blue">
                Submit
              </Button>
            </Stack>

          </Grid>
        </form>
      </div>

      <div className="copyrightsText">
        <p>© Copyrights CablePay Solutions India Pvt Ltd.2018.All rights reserved</p>
      </div>
    </div>
  );
}
