import "./style.scss";
import { useState } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const validation = Yup.object({
  userName: Yup.string().required("Required").nullable(),
  activationDate: Yup.string().required("Required").nullable(),
  gender: Yup.object().required("Required").nullable(),
  // area: Yup.string().required("Required").nullable(),
  // subArea: Yup.string().required("Required").nullable(),
  flat: Yup.string().required("Required").nullable(),
  city: Yup.string().required("Required").nullable(),
  state: Yup.string().required("Required").nullable(),
  pinCode: Yup.string().required("Required").nullable(),
  // landline: Yup.string().required("Required").nullable(),
  mobileNo: Yup.string().required("Required").max(10, 'Must be exactly 10 digits').matches(new RegExp('[0-9]{10}'), "Phone number is not valid"),

  email: Yup.string()
    .required("Required")

    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    ),
});

export default function AddSubscriberForm(props) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });
  const [value, setValueDate] = useState(new Date());
  setValue("activationDate", value);
  const navigate = useNavigate();

  const onSubmit = (data) => props?.handleFormValues(data);

  const handleChange = (newValue) => {
    setValueDate(newValue);
    setValue("activationDate", newValue);
  };

  const handleClickCancel = () => {
    navigate(`/distributor/subscriber-list`);

  }

  const genderOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const layoutInput = (title, input, error) => {
    return (
      <Grid item xs={4} sm={4} md={6}>
        <div className="add-subscriber-form-title">{title}</div>
        {input}
        <div className="field-error">{error}</div>
      </Grid>
    );
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          rowSpacing={4}
          columnSpacing={{ xs: 1, sm: 2, md: 7 }}
          sx={{ marginTop: 4 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          className="ticketDetails"
        >
          {layoutInput(
            "User Name",
            <TextField
              {...register("userName")}
              size="small"
              id="outlined-basic"
              type="text"
              className="txtwidth"
              placeholder="Enter User Name"
            />,
            errors.userName?.message
          )}
          {layoutInput(
            "Email",
            <TextField
              {...register("email")}
              size="small"
              id="outlined-basic"
              type="text"
              className="txtwidth"
              placeholder="Enter Email"
            />,
            errors.email?.message
          )}
          {layoutInput(
            "Activation Date",
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="MM/dd/yyyy"
                value={value}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Enter Expiry Date"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>,
            errors.activationDate?.message
          )}
          {layoutInput(
            "Gender",
            <Controller
              control={control}
              name="gender"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={genderOptions}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select Gender"
                    />
                  )}
                />
              )}
            />,
            errors.gender?.message
          )}
          {layoutInput(
            "Area",
            <Controller
              control={control}
              name="area"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={props?.areaData?.area || []}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select area"
                    />
                  )}
                />
              )}
            />,
            errors.area?.message
          )}
          {layoutInput(
            "Sub Area",
            <Controller
              control={control}
              name="subArea"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={props?.areaData?.subarea || []}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select Sub area"
                    />
                  )}
                />
              )}
            />,
            errors.subArea?.message
          )}
            {layoutInput(
            
            <TextField
              {...register("areaText")}
              size="small"
              id="outlined-basic"
              type="text"
              className="txtwidth"
              placeholder="Enter Area"
            />
           
          )}
            
            {layoutInput(
            
            <TextField
              {...register("subAreaText")}
              size="small"
              id="outlined-basic"
              type="text"
              className="txtwidth"
              placeholder="Enter Sub Area"
            />
          )}
          {layoutInput(
            "Flat/Room/Office No",
            <TextField
              {...register("flat")}
              size="small"
              id="outlined-basic"
              type="text"
              className="txtwidth"
              placeholder="Flat / Room / Office No"
            />,
            errors.flat?.message
          )}
          {layoutInput(
            "City",
            <TextField
              {...register("city")}
              size="small"
              id="outlined-basic"
              type="text"
              className="txtwidth"
              placeholder="Enter City"
            />,
            errors.city?.message
          )}
          {layoutInput(
            "State",
            <TextField
              {...register("state")}
              size="small"
              id="outlined-basic"
              type="text"
              className="txtwidth"
              placeholder="Enter State"
            />,
            errors.state?.message
          )}
          {layoutInput(
            "Pincode",
            <TextField
              {...register("pinCode")}
              size="small"
              id="outlined-basic"
              type="number"
              className="txtwidth"
              placeholder="Enter Pincode"
            />,
            errors.pinCode?.message
          )}
          {layoutInput(
            "Mobile No",
            <TextField
              {...register("mobileNo")}
              size="small"
              id="outlined-basic"
              type="number"
              className="txtwidth"
              placeholder="Enter Mobile No"
            />,
            errors.mobileNo?.message
          )}
          {layoutInput(
            "Landline",
            <TextField
              {...register("landline")}
              size="small"
              id="outlined-basic"
              type="text"
              className="txtwidth"
              placeholder="Enter Landline"
            />
          
          )}
        </Grid>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ marginTop: 3 }}
          className="flex-end padding-btw-tiles"
        >
          <Stack spacing={2} direction="row">
            <Button
              size="small"
              variant="contained"
              className="bg-blue"
              onClick={handleClickCancel}
              style={{marginRight : "1rem" }}
            >
              Cancel
            </Button>
          </Stack>
          <Stack spacing={2} direction="row">
            <Button
              size="small"
              variant="contained"
              className="bg-blue"
              type="submit"
            >
              Next
            </Button>
          </Stack>
        </Grid>
      </form>
    </div>
  );
}
