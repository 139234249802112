const tableRows = [
  {
    title: "Payment",
    key: "payment",
    align: "left",
  },
  {
    title: "Type",
    key: "billNumber",
    align: "left",
  },

  {
    title: "Total",
    key: "total",
    align: "left",
  },
  {
    title: "Paid",
    key: "paid",
    align: "left",
  },
  {
    title: "Not Paid",
    key: "notPaid",
    align: "left",
  },
];

const distributorRow = [
  {
    title: "Distributor Name",
    key: "distributorName",
    align: "left",
  },
  {
    title: "Validity",
    key: "validity",
    align: "left",
  },
  {
    title: "Limit",
    key: "limit",
    align: "left",
  },
  {
    title: "Expiration",
    key: "expiration",
    align: "left",
  },
];

const monthlyPayment = [
  {
    title: "Month",
    key: "yearMonth",
    align: "left",
  },
  {
    title: "Amount To Pay",
    key: "amounttopay",
    align: "left",
  },

  {
    title: "Paid",
    key: "monthpaid",
    align: "left",
  },

  {
    title: "Not Paid",
    key: "monthnotPaid",
    align: "left",
  },
];

const month = { JANUARY: 0, FEBRUARY: 1, MARCH: 2, APRIL: 3, MAY: 4, JUNE: 5, JULY: 6, AUGUST: 7, SEPTEMBER: 8, OCTOBER: 9, NOVEMBER: 10, DECEMBER: 11 };
export { tableRows, distributorRow, monthlyPayment, month };
