import TicketStatus from "@components/TicketStatus";
import "./style.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchWithButtons from "@components/SearchWithButtons";
import Grid from "@mui/material/Grid";
import TicketFilter from "@components/CollectionAgentFilter";
import { useNavigate } from "react-router-dom";
import {
  useComponentDidMount,
  useComponentDidUpdate,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { CollectionStatus, ticketList, deleteTicket , CollectionAgentList} from "@reducers/api";
import { useState } from "react";
import { useSnackbar } from "notistack";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import { reset } from "@reducers/ticketing";
import BreadCrumb from "@components/Breadcrumb";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CollectionAgentListPopup from "@components/Modals/collectionAgentList";


const priority = [
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
];

const statusdrop = [
  { label: "Open", value: "open" },
  { label: "Pending", value: "pending" },
  { label: "Resolved", value: "resolved" },
  { label: "Closed", value: "closed" },
  { label: "In Progress", value: "inprogress" },
];

export default function CollectionEmployeeList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();  
  const { CollectionAgentListData } = useSelector(
    (state) => state.collection
  );
  const [agentList, setagentList] = useState([]);
  const [agentListExport, setagentExport] = useState([]);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [channelList, setchannelList] = useState(false);
  const [collectionData, setcollectionData] = useState([]);

  

  const [dateFrom, setDateFromQuery] = useState("");
  const [dateTo, setDateToQuery] = useState("");
  const [value, setValues] = useState(null);
  const [value1, setValue1] = useState(null);

  const defaultPlanQuery = {
    serviceType: "cable",
    billingType: "",
  };

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}`;
    if (value?.type === "search") {
      query += `&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };

  const handleChannelclose = () => {
    setchannelList(false);
  };


  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
    let query = queryGenerator();
    dispatch(CollectionAgentList(query));
  }, []);

  useComponentDidUpdate(() => {
    let list = [];
    let listForExport = [];
    CollectionAgentListData && CollectionAgentListData?.agentSummary?.map((data, index) => {
      list.push({
        sNo: (page - 1) * 10 + index + 1,
        name: data?.empName,
        collectedAmt: data?.transaction[0]?.totalCollectedAmount || 0,
        collectedCount: data?.transaction[0]?.count || 0,
        newConnection: data?.newCustCount || 0,
        totalConnection : data?.totalCustCount || 0,
        action: (
          <div className="flex-row justify-center">
            <div className="cursor-pointer" onClick={() => channelPopup(data)}>
              view
            </div>
          </div>
        ),
     
      });

      listForExport.push({
        sNo: (page - 1) * 10 + index + 1,
        name: data?.empName,
        collectedAmt: data?.transaction[0]?.totalCollectedAmount || 0,
        collectedCount: data?.transaction[0]?.count || 0,
        newConnection: data?.newCustCount || 0,
        totalConnection : data?.totalCustCount || 0,
       
      });
    });

    setagentList(list);
    setagentExport(listForExport);
  }, [CollectionAgentListData]);

  const handleClickSearcch = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    setPage(1);
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;
    if (value) {
      query += `&startDate=${moment(value).format("YYYY-MM-DDTHH:mm:ss")}`;
    }
    if (value1) {
      query += `&endDate=${moment(value1).format("YYYY-MM-DDTHH:mm:ss")}`;
    }

    dispatch(CollectionAgentList(`${query}`));
    setDateFromQuery(value);
    setDateToQuery(value1);

  };


  const handleRedirect = () => {
    navigate("/distributor/add-ticket");
  };

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    let query = queryGenerator({ type: "search", state: search });
    dispatch(CollectionAgentList(query));
  };

  const handleReset = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
    let query = queryGenerator();
    dispatch(CollectionAgentList(query));
    setDateFromQuery("");
    setDateToQuery("");
    setPage(1);
  };


  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  const channelPopup = (data) => {
    console.log(data,"D")
    setchannelList(true);
    setcollectionData(data?.custDetail);
    //api call
  };


  console.log(CollectionAgentListData?.totalConnection, "collect")
  return (
    <div>
      <div className="agentList-container">
      <div className="channelpopup">
        <CollectionAgentListPopup show={channelList} channelData={collectionData} handleClose={handleChannelclose} />
      </div>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <div className="planHeading">Collection Agent Status</div>
          </Grid>
          <Grid item xs={4} className="flex-end">
            <div className="employeelist-title">
              <BreadCrumb
                defaultHeading="Home"
                handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
                activeHeading="Collection Agent List"
                handleActiveClick={() =>
                  handleBreadCrumbRedirect("collection-agent-list")
                }
              />
            </div>
          </Grid>
        </Grid>
        <div>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.4}
              style={{ paddingLeft: 0, paddingBottom: 0 }}
            >
              <TicketStatus
                className=" ticketCardBlue"
                title={"Total Bill Amount"}
                count={
                  CollectionAgentListData?.totalBillAmount
                    ? CollectionAgentListData?.totalBillAmount
                    : 0
                }
         
              />
            </Grid>
           
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.4}
              style={{ paddingLeft: 0, paddingBottom: 0 }}
            >
              <TicketStatus
                className=" ticketCardRed"
                title={"Collected Amount"}
                count={
                  CollectionAgentListData?.totalCollectedAmount
                    ? CollectionAgentListData?.totalCollectedAmount
                    : 0
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.4}
              style={{ paddingLeft: 0, paddingBottom: 0 }}
            >
              <TicketStatus
                className=" ticketCardPink"
                title={"Total Bill Count"}
                count={
                  CollectionAgentListData?.totalBillCount
                    ? CollectionAgentListData?.totalBillCount
                    : 0
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.4}
              style={{ paddingLeft: 0, paddingBottom: 0 }}
            >
              <TicketStatus
                className=" ticketCardPurple"
                title={"New Connection"}
                count={
                  CollectionAgentListData?.totalNewConection
                    ? CollectionAgentListData?.totalNewConection
                    : 0
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.4}
              style={{ paddingLeft: 0, paddingBottom: 0 }}
            >
              <TicketStatus
                className=" ticketCardGreen"
                title={"Total Connection"}
                count={
                  CollectionAgentListData?.totalConnection
                    ? CollectionAgentListData?.totalConnection
                    : 0
                }
              />
            </Grid>
          </Grid>
        </div>
        <div className="agent-list-table-cntr">
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={4} md={3}>
              <div className="planHeading">Collection Agent List</div>
            </Grid>
            <Grid item xs={0} sm={4} md={3}></Grid>
            <Grid item xs={4} sm={4} md={6} className="flex-end">
            <Grid item xs={6} sm={6} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                    slotProps={{ textField: { size: 'small' } }}
                      renderInput={(props) => (
                        <TextField
                          variant="standard"
                          className="filterHeightDate"
                          {...props}
                                     
                          
                        />
                      )}
                      label="From Date"
                      value={value}
                      name="fromdate"                    
                    
                      onChange={(newValue) => {
                        setValues(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                &nbsp;&nbsp;
                <Grid item xs={6} sm={6} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField
                          variant="standard"
                          className="filterHeightDate"
                          {...props}
                        />
                      )}
                      label="To Date"
                      value={value1}
                      name="To "
                      onChange={(newValue) => {
                        setValue1(newValue);
                      }}
                    />
                  </LocalizationProvider>

                </Grid>&nbsp;&nbsp;
                <Stack direction="row" className="flex-end " spacing={2}>

                <Button
                type="button"
                variant="contained"
                className="bg-blue"
                style={{ textTransform: "none",height:'30px',marginTop:'19px' }}
                size="small"
                onClick={handleClickSearcch}
              >
                Search
              </Button>
              </Stack>
              {/* <div className="">
                <TicketFilter
                  labelPrimary={"Priority"}
                  PrimaryOption={priority}
                  SecondaryOption={statusdrop}
                  labelSecondary={"Status"}
                  handleFormValues={(data1, data2, data3, data4) =>
                    handleClickSearcch(data1, data2, data3, data4)
                  }
                  handleResetClick = {handleReset}
                />
              </div> */}
            </Grid>
           
            
          </Grid>
          <div className="search-btns-padding">
            <SearchWithButtons
           
              secondaryButton={{
                title: "Export",
                type: "outlined",
                color: COLORS.primary,
                filename: "Collection Agent list",
                data: agentListExport,
              }}
              handlePrimaryClick={handleRedirect}
              handleChange={handleSearch}
            />
          </div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "15px",
              paddingBottom: "10px",
            }}
          >
            Showing entries {CollectionAgentListData?.totalAgentCount}
          </div>

          <TableView
            rows={tableRows}
            data={agentList}
            color={COLORS.primary}
          />
        
        </div>
      </div>
    </div>
  );
}
