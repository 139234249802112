import { createSlice } from "@reduxjs/toolkit";
import { billingList, deleteCollection, deleteRceipt, generateBillList, paidByList, outstandingListExport, generateBillAdd,collectionListNew , outstandingList} from "./api";


const initialState = {
  billingListData: [],
  billingDeleted: false,
  invoiceDeleted: false,
  generateBillData: [],
  paidByListData: [],
  generateBillAdded: false,
  collectionListNewData: [],
  outstandList : [],
  outstandExport:false,
};

export const billSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    reset: (state) => {
      state.billingDeleted = false;
      state.invoiceDeleted = false;
      state.generateBillAdded = false;
      return state;
    },
    Billingreset: (state) => {
      state.billingDeleted = false;
      state.generateBillAdded = false;
      return state;
    },
  },
  extraReducers: {
    [billingList.pending]: (state) => {},
    [billingList.fulfilled]: (state, { payload }) => {
      state.billingListData = payload.data.data;
    },
    [billingList.rejected]: (state, { payload }) => {},  

    [deleteCollection.pending]: (state) => {},
    [deleteCollection.fulfilled]: (state, { payload }) => {
      state.billingDeleted = payload.status;
    },
    [deleteCollection.rejected]: (state, { payload }) => {},

    [deleteRceipt.pending]: (state) => {},
    [deleteRceipt.fulfilled]: (state, { payload }) => {
      state.invoiceDeleted = payload.status;
    },
    [deleteRceipt.rejected]: (state, { payload }) => {},

    [generateBillList.pending]: (state) => {},
    [generateBillList.fulfilled]: (state, { payload }) => {
      state.generateBillData = payload.data;
    },
    [generateBillList.rejected]: (state, { payload }) => {}, 
    
    [paidByList.pending]: (state) => {},
    [paidByList.fulfilled]: (state, { payload }) => {
      state.paidByListData = payload.data.data;
    },
    [paidByList.rejected]: (state, { payload }) => {},  

    [generateBillAdd.pending]: (state) => {},
    [generateBillAdd.fulfilled]: (state, { payload }) => {
      state.generateBillAdded = payload.data.status;
    },
    [generateBillAdd.rejected]: (state, { payload }) => {},

    [collectionListNew.pending]: (state) => {},
    [collectionListNew.fulfilled]: (state, { payload }) => {
      state.collectionListNewData = payload.data.data;
    },
    [collectionListNew.rejected]: (state, { payload }) => {},

    [outstandingList.pending]: (state) => {},
    [outstandingList.fulfilled]: (state, { payload }) => {
      state.outstandList = payload.data.data;
    },
    [outstandingList.rejected]: (state, { payload }) => {},  

    [outstandingListExport.pending]: (state) => { },
    [outstandingListExport.fulfilled]: (state, { payload }) => {
      state.outstandExport = payload.data;
    },
    [outstandingListExport.rejected]: (state, { payload }) => {},


  },
});

export const { reset, Billingreset } = billSlice.actions;

export default billSlice.reducer;