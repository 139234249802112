import "./style.scss";
import * as React from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import { useForm, Controller } from "react-hook-form";
import {
  allEmpList,
  adminAllPlans,
  bulkFileUpload,
  bulkFileAddOnUpload,
  bulkFilePaymentUpload,
  distributorPlanExpiry
} from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  useComponentDidMount,
} from "@utils/useEffectHooks";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TableView from "@components/TableView";
import { COLORS } from "@constants";
import { CSVLink } from "react-csv";


const Input = styled("input")({
  display: "none",
});

const status = (text) => {

  return (


    <Button variant="contained" size="small" className="bg-blue ">

      <CSVLink data={text} className="exportstatement">
        Export
      </CSVLink>
    </Button>
  );
};

const cableSampleData = [{
  "name": "test1",
  "email_id": "sree@yopmail.com",
  "mobile_no": "9090909090",
  "state": "karnataka",
  "city": "bangalore",
  "area": "kothnur",
  "subarea": "kuvempu Layout",
  "flat_no": "123",
  "can_id": "test01",
  "stb_no": "9877666660",
  "tax": "yes",
  "plan_name": "ajp test channels one",
  "plan_validity": "1 months",
  "plan_amount": "300",
  "package_category": "package",
  "service_type": "cable",
  "auto_billing": "yes",
  "billing_type": "pre paid"
}, {
  "name": "test2",
  "email_id": "test@yomail.com",
  "mobile_no": "8080808090",
  "state": "karnataka",
  "city": "bangalore",
  "area": "kothnur",
  "subarea": "kuvempu Layout",
  "flat_no": "321",
  "can_id": "test02",
  "stb_no": "9988888",
  "tax": "yes",
  "plan_name": "ajp test two",
  "plan_validity": "3 months",
  "plan_amount": "300",
  "package_category": "package",
  "service_type": "cable",
  "auto_billing": "no",
  "billing_type": "pre paid"
},
{
  "name": "test3",
  "email_id": "test@yomail.com",
  "mobile_no": "8080808090",
  "state": "karnataka",
  "city": "bangalore",
  "area": "kothnur",
  "subarea": "kuvempu Layout",
  "flat_no": "321",
  "can_id": "test03",
  "stb_no": "9988888",
  "tax": "yes",
  "plan_name": "ajp test three",
  "plan_validity": "6 months",
  "plan_amount": "300",
  "package_category": "package",
  "service_type": "cable",
  "auto_billing": "no",
  "billing_type": "pre paid"
},
{
  "name": "test4",
  "email_id": "test@yomail.com",
  "mobile_no": "8080808090",
  "state": "karnataka",
  "city": "bangalore",
  "area": "kothnur",
  "subarea": "kuvempu Layout",
  "flat_no": "321",
  "can_id": "test04",
  "stb_no": "9988888",
  "tax": "yes",
  "plan_name": "ajp test four",
  "plan_validity": "1 year",
  "plan_amount": "300",
  "package_category": "package",
  "service_type": "cable",
  "auto_billing": "no",
  "billing_type": "pre paid"
}
]

let internetSampleData = [{
  "name": "demo_user",
  "email_id": "demo@cablepay.com",
  "mobile_no": "9090909090",
  "state": "karnataka",
  "city": "bangalore",
  "area": "kothnur",
  "flat_no": "123",
  "user_id": "1234",
  "login_ip": "IP/MAC: 192.168.60.176 / 2c:30:33:5e:b1:28",
  "tax": "yes",
  "isp": "tacitine",
  "plan_name": "internetPostpaid",
  "plan_validity": "1 months",
  "plan_amount": "318",
  "package_category": "package",
  "service_type": "internet",
  "billing_type": "post paid",
  "auto_billing": "yes"
}, {
  "name": "test_user",
  "email_id": "test@cablepay.com",
  "mobile_no": "8080808090",
  "state": "karnataka",
  "city": "bangalore",
  "area": "kothnur",
  "flat_no": "321",
  "floor": "second",
  "landmark": "mathina fancy",
  "user_id": "4321",
  "login_ip": "IP/MAC: 192.168.40.190 / 6c:72:20:7c:b0:de",
  "tax": "no",
  "isp": "tacitine",
  "plan_name": "internetPrepaid",
  "plan_validity": "3 months",
  "plan_amount": "305",
  "package_category": "package",
  "service_type": "internet",
  "billing_type": "pre paid",
  "auto_billing": "no"
},
{
  "name": "test_internet",
  "email_id": "test@cablepay.com",
  "mobile_no": "8080808090",
  "state": "karnataka",
  "city": "bangalore",
  "area": "kothnur",
  "flat_no": "321",
  "floor": "second",
  "landmark": "mathina fancy",
  "user_id": "43213",
  "login_ip": "IP/MAC: 192.168.40.190 / 6c:72:20:7c:b0:de",
  "tax": "no",
  "isp": "tacitine",
  "plan_name": "internetPrepaid",
  "plan_validity": "6 months",
  "plan_amount": "305",
  "package_category": "package",
  "service_type": "internet",
  "billing_type": "pre paid",
  "auto_billing": "no"
},
{
  "name": "test_demo_int",
  "email_id": "test@cablepay.com",
  "mobile_no": "8080808090",
  "state": "karnataka",
  "city": "bangalore",
  "area": "kothnur",
  "flat_no": "321",
  "floor": "second",
  "landmark": "mathina fancy",
  "user_id": "43621",
  "login_ip": "IP/MAC: 192.168.40.190 / 6c:72:20:7c:b0:de",
  "tax": "no",
  "isp": "tacitine",
  "plan_name": "internetPrepaid",
  "plan_validity": "1 year",
  "plan_amount": "305",
  "package_category": "package",
  "service_type": "internet",
  "billing_type": "pre paid",
  "auto_billing": "no"
}
]

let addonSampleData = [{
  "billing_type": "pre paid",
  "service_type": "cable",
  "plan_name": "cablePrepaid",
  "stb_no": "98769",
  "account_no": "79024118",
  "stb_payment_category": "free",
  "price": "3"
}, {
  "billing_type": "post paid",
  "service_type": "cable",
  "plan_name": "cablePostpaid",
  "stb_no": "987990",
  "account_no": "79024118",
  "stb_payment_category": "paid",
  "price": "3"
}
]

let paymentSampleData = [{
  "collection_amount": 100,
  "bill_amount": 100,
  "discount_amount": 0,
  "due_amount": 0,
  "payment_type": "cash",
  "account_no": 85069856,
  "collected_by": "Prasanth"
}, {
  "collection_amount": 100,
  "bill_amount": 100,
  "discount_amount": 0,
  "due_amount": 0,
  "payment_type": "cash",
  "account_no": 85069856,
  "collected_by": "Prasanth"
}
]

const tableRows = [
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },
  {
    title: "Sample Files for Bulk upload",
    key: "fileTitle",
    align: "left",
  },
  {
    title: "Download Sample File",
    key: "download",
    align: "left",
  },

];

const tableRowsdata = [
  {
    sNo: "1",
    fileTitle: "Digital Cable Customer Upload",
    download: status(cableSampleData),
  },
  {
    sNo: "2",
    fileTitle: "Internet Customer Upload",
    download: status(internetSampleData),
  },
  {
    sNo: "3",
    fileTitle: "Add on TV Customer Upload",
    download: status(addonSampleData),
  },
  {
    sNo: "4",
    fileTitle: "Bulk Payment Upload",
    download: status(paymentSampleData),
  },

];




const top100Films = [
  {
    label: "Digital Cable Customer Upload",
    value: "digital cable customer upload",
  },
  { label: "Internet Customer Upload", value: "internet customer upload" },
  { label: "Add on Tv Customer Upload", value: "add on tv customer upload" },
  { label: "Bulk Payment Upload", value: "bulk payment upload" },
];

export default function BulkUpload(props) {
  const dispatch = useDispatch();
  const {
    validityCount,
  } = useSelector((state) => state.dashboard);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showFileName, setFileName] = useState(null);

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}`;
    let plansQuery = `package_category=package,broadcaster&distributor_ref_id=${user?.distributor_ref_id}`;
    dispatch(allEmpList(query));
    dispatch(adminAllPlans(plansQuery));
    dispatch(distributorPlanExpiry(`distributor_ref_id=${user?.distributor_ref_id}`));
  }, []);

  const {
    control,
    watch,
  } = useForm();

  const handleImgChange = async (event) => {
    let user = JSON.parse(localStorage.getItem("user"));
    var reader = new FileReader();
    reader.readAsDataURL(selectedImage);

    const formData = new FormData();
    formData.append("file", selectedImage);

    let payloads = {
      file: formData,
      distributor_ref_id: user?.distributor_ref_id,
      flag: "customer",
      sms_access: user?.sms_access,
      whatsapp_access: user?.whatsapp_access,
    };

    const fileUploadedUrl = await dispatch(bulkFileUpload(payloads));
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };


  const handleBulkChange = async (event) => {
    let user = JSON.parse(localStorage.getItem("user"));
    var reader = new FileReader();
    reader.readAsDataURL(selectedImage);

    const formData = new FormData();
    formData.append("file", selectedImage);

    let payloads = {
      file: formData,
      distributor_ref_id: user?.distributor_ref_id,
      flag: "customer",
    };

    const fileUploadedUrl = await dispatch(bulkFilePaymentUpload(payloads));
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleAddOnTvImgChange = async (event) => {
    let user = JSON.parse(localStorage.getItem("user"));
    var reader = new FileReader();
    reader.readAsDataURL(selectedImage);

    const formData = new FormData();
    formData.append("file", selectedImage);

    let payloads = {
      file: formData,
      distributor_ref_id: user?.distributor_ref_id,
      flag: "customer",
    };

    const fileUploadedUrl = await dispatch(bulkFileAddOnUpload(payloads));
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  return (
    <div className="settingsBluk-container">
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={4} md={5}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            spacing={4}
            className="createHeader headerpayment"
          >
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <div className="settingsbulk-title">Bulk upload</div>
              </Grid>
            </Grid>
          </Grid>
          <div className="border-sts-pay">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              spacing={4}
              className="paddingInput createSearchForm"
            >
              <div className="bulkuploadimport">Import</div>
              <div className="padding-title-addticket">
                What kind of entity would you like to import?
              </div>
              <Controller
                control={control}
                name="blukupload"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || ""}
                    options={top100Films}
                    isOptionEqualToValue={(option) =>
                      option.label === value?.label || ""
                    }
                    getOptionLabel={(item) => (item.label ? item.label : "")}
                    getOptionSelected={(option, value) =>
                      value === undefined ||
                      value === "" ||
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Option"
                        size="small"
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={2} direction="row" className="uploadBtn">
                {watch("blukupload") &&
                  watch("blukupload").value === "internet customer upload" && (
                    <Grid item xs={12}>
                      {
                        validityCount[0]?.custTotalCount < validityCount[0]?.subscriber_limit_count ? (
                          <Stack spacing={2} direction="row" className="uploadBtn">
                            <label htmlFor="contained-button-file">
                              <Input
                                id="contained-button-file"
                                multiple
                                type="file"
                                accept=".csv"
                                onChange={(event) => {
                                  setSelectedImage(event.target.files[0]);
                                  setFileName(event.target.files[0]?.name)
                                  // handleImgChange(event);
                                }}
                              />
                              <Button
                                className="bg-blue"
                                size="small"
                                variant="contained"
                                component="span"
                              >
                                choose file
                              </Button>
                            </label>
                            <div className="fileNameStyle">{showFileName}</div>
                            {
                              selectedImage && (
                                <Button
                                  className="bg-blue bulkUpload-btn"
                                  size="small"
                                  variant="contained"
                                  component="span"
                                  onClick={() => {
                                    handleImgChange(selectedImage);
                                  }}
                                >
                                  Upload
                                  <CloudUploadIcon />
                                </Button>
                              )
                            }
                          </Stack>


                        ) : (
                          <Stack spacing={2} direction="row" className="uploadBtn">
                            <div>Subscriber Limit Reached</div>
                          </Stack>
                        )
                      }


                    </Grid>
                  )}

                {watch("blukupload") &&
                  watch("blukupload").value === "bulk payment upload" && (
                    <Grid item xs={12}>
                      <Stack spacing={2} direction="row" className="uploadBtn">
                        <label htmlFor="contained-button-file">
                          <Input
                            id="contained-button-file"
                            multiple
                            type="file"
                            accept=".csv"
                            onChange={(event) => {
                              setSelectedImage(event.target.files[0]);
                              setFileName(event.target.files[0]?.name)
                              // handleBulkChange(event);
                            }}
                          />
                          <Button
                            className="bg-blue"
                            size="small"
                            variant="contained"
                            component="span"
                          >
                            choose file
                            <CloudUploadIcon />
                          </Button>
                        </label>
                        <div className="fileNameStyle">{showFileName}</div>
                        {
                          selectedImage && (
                            <Button
                              className="bg-blue bulkUpload-btn"
                              size="small"
                              variant="contained"
                              component="span"
                              onClick={() => {
                                handleBulkChange(selectedImage);
                              }}
                            >
                              Upload
                              <CloudUploadIcon />
                            </Button>
                          )
                        }
                      </Stack>

                    </Grid>
                  )}


                {watch("blukupload") &&
                  watch("blukupload").value ===
                  "digital cable customer upload" && (
                    <Grid item xs={12}>
                      {
                        validityCount[0]?.custTotalCount < validityCount[0]?.subscriber_limit_count ? (
                          <Stack spacing={2} direction="row" className="uploadBtn">
                            <label htmlFor="contained-button-file">
                              <Input
                                id="contained-button-file"
                                multiple
                                type="file"
                                accept=".csv"
                                onChange={(event) => {
                                  setSelectedImage(event.target.files[0]);
                                  setFileName(event.target.files[0]?.name)
                                  // handleImgChange(event);
                                }}
                              />
                              <Button
                                className="bg-blue"
                                size="small"
                                variant="contained"
                                component="span"
                              >
                                choose file
                                <CloudUploadIcon />
                              </Button>
                            </label>
                            <div className="fileNameStyle">{showFileName}</div>
                            {
                              selectedImage && (
                                <Button
                                  className="bg-blue bulkUpload-btn"
                                  size="small"
                                  variant="contained"
                                  component="span"
                                  onClick={() => {
                                    handleImgChange(selectedImage);
                                  }}
                                >
                                  Upload
                                  <CloudUploadIcon />
                                </Button>
                              )
                            }
                          </Stack>


                        ) : (
                          <Stack spacing={2} direction="row" className="uploadBtn">
                            <div>Subscriber Limit Reached</div>
                          </Stack>
                        )
                      }



                    </Grid>
                  )}

                {watch("blukupload") &&
                  watch("blukupload").value === "add on tv customer upload" && (
                    <Grid item xs={12}>

                      <Stack spacing={2} direction="row" className="uploadBtn">
                        <label htmlFor="contained-button-file">
                          <Input
                            id="contained-button-file"
                            multiple
                            type="file"
                            accept=".csv"
                            onChange={(event) => {
                              setSelectedImage(event.target.files[0]);
                              setFileName(event.target.files[0]?.name)
                              // handleAddOnTvImgChange(event);
                            }}
                          />
                          <Button
                            className="bg-blue"
                            size="small"
                            variant="contained"
                            component="span"
                          >
                            choose file
                            <CloudUploadIcon />
                          </Button>
                        </label>
                        <div className="fileNameStyle">{showFileName}</div>
                        {
                          selectedImage && (
                            <Button
                              className="bg-blue bulkUpload-btn"
                              size="small"
                              variant="contained"
                              component="span"
                              onClick={() => {
                                handleAddOnTvImgChange(selectedImage);
                              }}
                            >
                              Upload
                              <CloudUploadIcon />
                            </Button>
                          )
                        }
                      </Stack>


                    </Grid>
                  )}
              </Stack>
            </Grid>

          </div>
        </Grid>
      </Grid>
      <div className="paddingTable">
        <TableView rows={tableRows} data={tableRowsdata} color={COLORS.primary} />
      </div>

    </div>
  );
}
