import "./styles.scss";
import { Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";

export default function CustomerDetailsView(props) {
  const infoTile = (title, value) => {
    return (
      <Grid item xs={4} sm={6} md={6}>
        <div className="title-heading">{title}</div>
        <div className="sub-value">{value}</div>
      </Grid>
    );
  };
  return (
    <Grid container justifyContent="center" className="custer-info-card-cnt">
      {
        props?.data?.customerData?.fileData ? <Avatar sx={{ height: 100, width: 100 }} alt="Remy Sharp" src={`data:image/png;base64,${props?.data?.customerData?.fileData}`} /> : <Avatar sx={{ height: 100, width: 100 }} alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
      }      
      <div style={{ marginTop: 30 }}>
        <Grid container rowSpacing={7} columns={{ xs: 4, sm: 8, md: 12 }}>
          {infoTile("Customer Name", props?.data?.customerData?.name)}
          {infoTile("VC ID/VM/MAC", props?.data?.customerData?.stb_no[0]?.stb_no)}
          {infoTile("Mobile No.", props?.data?.customerData?.mobile_no)}
          {infoTile("Network Name", props?.data?.associatedDistributorData?.name)}
          {infoTile("Account ID", props?.data?.customerData?.account_id)}
          {infoTile("Technician Number", props?.data?.associatedDistributorData?.technicianNumber)}
        </Grid>
      </div>
    </Grid>
  );
}
