import Grid from "@mui/material/Grid";
import CurrentServiceTable from "@components/CurrentServiceTable";

export default function SubscriberCurrrent(props) {
  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={12} sm={  12} md={12}>
        <CurrentServiceTable customerData={props?.customerData}/>
      </Grid>
     
    </Grid>
  );
}
