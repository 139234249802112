import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch } from "react-redux";
import Topbar from "@components/topbar/index";
import "./styles.scss";

export default function CustomerLayout(props) {
  return (
    <div>
      <div>
        <Topbar />
      </div>
      <div className="main-layout-left-none">{props.children}</div>
    </div>
  );
}
