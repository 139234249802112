import { createSlice } from "@reduxjs/toolkit";
import { updatePlan, deletePlan, planList, addPlan, alaCartList, allPlans, adminAllPlans, PlanMultipleDelete, adminPackageOnly, bulkFileBroadcastUpload, customerPlanList, broadcasterAdmin, planListsExport, SubscriberplanListsExport, getBroadcaster} from "./api";

const initialState = {
  planListData: [],
  planAdded: [],
  planDeleted: false,
  alaCartListData: [],
  allPlansData: [],
  adminAllPlansData: [],
  adminPackageData: [],
  adminBroadCatData: [],
  adminAlaCartData: [],
  customerPlanListData: [],
  broadcasterAdminData: [],
  planListsExportData:false,
  SubscriberplanListsExportData: false,
  getBroadcasterData:[],
  packageOnlyData:[],
  bulkUploadBroadCast: false,
  planMultipleData:null,
};

export const planSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    reset: (state) => {
      state.planAdded = [];
      state.planDeleted = false;
      state.planListsExportData=false;
      state.SubscriberplanListsExportData= false;
      state.getBroadcasterData=[]
      state.bulkUploadBroadCast = false;
      return state;
    },
  },
  extraReducers: {
    [planList.pending]: (state) => {},
    [planList.fulfilled]: (state, { payload }) => {
      state.planListData = payload.data.data;
    },
    [planList.rejected]: (state, { payload }) => {},

    [deletePlan.pending]: (state) => {},
    [deletePlan.fulfilled]: (state, { payload }) => {
      state.planDeleted = payload.status;
    },
    [deletePlan.rejected]: (state, { payload }) => {},
    [addPlan.pending]: (state) => {},
    [addPlan.fulfilled]: (state, { payload }) => {
      state.planAdded = payload.data;
    },
    [addPlan.rejected]: (state, { payload }) => {},
    [updatePlan.pending]: (state) => {},
    [updatePlan.fulfilled]: (state, { payload }) => {
      state.planAdded = payload.data;
    },
    [updatePlan.rejected]: (state, { payload }) => {},
    [alaCartList.pending]: (state) => {},
    [alaCartList.fulfilled]: (state, { payload }) => {
      state.alaCartListData = payload.data.data;
    },
    [alaCartList.rejected]: (state, { payload }) => {},
    [allPlans.pending]: (state) => {},
    [allPlans.fulfilled]: (state, { payload }) => {
      state.allPlansData = payload.data.data;
    },
    [allPlans.rejected]: (state, { payload }) => {},
    [adminAllPlans.pending]: (state) => {},
    [adminAllPlans.fulfilled]: (state, { payload }) => {
      state.adminAllPlansData = payload.data.data;
      state.adminPackageData = payload.data.data;
      state.adminBroadCatData = payload.data.data;
      state.adminAlaCartData = payload.data.data;
   
    },
    [adminAllPlans.rejected]: (state, { payload }) => {},

    [adminPackageOnly.pending]: (state) => {},
    [adminPackageOnly.fulfilled]: (state, { payload }) => {
      state.packageOnlyData = payload.data.data; 
    },
    [adminPackageOnly.rejected]: (state, { payload }) => {},

    [customerPlanList.pending]: (state) => {},
    [customerPlanList.fulfilled]: (state, { payload }) => {
      state.customerPlanListData = payload.data.data;
    },
    [customerPlanList.rejected]: (state, { payload }) => {},

    [broadcasterAdmin.pending]: (state) => {},
    [broadcasterAdmin.fulfilled]: (state, { payload }) => {
      state.broadcasterAdminData = payload.data.data;
    },
    [broadcasterAdmin.rejected]: (state, { payload }) => {},

    [planListsExport.pending]: (state) => {},
    [planListsExport.fulfilled]: (state, { payload }) => {
      state.planListsExportData = payload.data;
    },
    [planListsExport.rejected]: (state, { payload }) => {},

    [SubscriberplanListsExport.pending]: (state) => {},
    [SubscriberplanListsExport.fulfilled]: (state, { payload }) => {
      state.SubscriberplanListsExportData = payload.data;
    },
    [SubscriberplanListsExport.rejected]: (state, { payload }) => {},

    [getBroadcaster.pending]: (state) => {},
    [getBroadcaster.fulfilled]: (state, { payload }) => {
      state.getBroadcasterData = payload.data.data;
    },
    [getBroadcaster.rejected]: (state, { payload }) => {},

    [bulkFileBroadcastUpload.pending]: (state) => { },
    [bulkFileBroadcastUpload.fulfilled]: (state, { payload }) => {
      state.bulkUploadBroadCast = payload.data.status;
    },
    [bulkFileBroadcastUpload.rejected]: (state, { payload }) => {},

    [PlanMultipleDelete.pending]: (state) => {},
    [PlanMultipleDelete.fulfilled]: (state, { payload }) => {
      state.planMultipleData = payload.data;

    },
    [PlanMultipleDelete.rejected]: (state, { payload }) => {},

  },
});
export const { reset } = planSlice.actions;

export default planSlice.reducer;
