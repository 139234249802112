import React from "react";
import ChangePswForm from "@components/ChangePswForm";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import {
  useComponentDidUpdate,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "@reducers/api";
import BreadCrumb from "@components/Breadcrumb";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";

export default function ChangePassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { changepswAdded} = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  useComponentDidUpdate(() => {
    if (changepswAdded.msg === "password Updated Successfully") {
      enqueueSnackbar("Password Updated !", {
        variant: "success",
      });
    }
    if (changepswAdded.msg === "Invalid Data") {
      enqueueSnackbar("Invalid Data !", {
        variant: "error",
      });
    }
  }, [changepswAdded]);

  const handleFormValues = (values) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      "oldPassword": values?.oldPassword,
      "newPassword": values?.newPassword,
      "confirmPassword": values?.confirmPassword,
      "_id": user?._id,
      "sms_access": user?.sms_access,
      "whatsapp_access": user?.whatsapp_access,

    };

    dispatch(changePassword(payload));
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };


  return (
    <div className="changepsw-container">
     
      <Grid container spacing={2} className=''>
        <Grid item xs={12} md={8}>
        <div className="changepsw-title">Change Password</div>
        </Grid>
        <Grid item xs={12} md={4} className="flex-end">
          <div className="changepsw-title" >
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("change-password")}
              activeHeading="Change Password"
            
            />
          </div>

        </Grid>
      </Grid>
      <ChangePswForm handleFormValues={(values) => handleFormValues(values)} />
    </div>
  );
}
