import React, { useState, useEffect } from "react";
import "./style.scss";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Stack from "@mui/material/Stack";
import { addTicket } from "@reducers/api";
import { accountID } from "@reducers/api";
import { assignTo,subscriberList,allEmpList } from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import { useForm, Controller } from "react-hook-form";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useComponentDidMount,
  useComponentDidUpdate,
  paramsQuery,
  useComponentWillUnmount
} from "@utils/useEffectHooks";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { reset } from "@reducers/ticketing";

const priority = [
  { label: "Low", value: "low" },
  { label: "Medium", value: "medium" },
  { label: "High", value: "high" },
];

const type = [
  { label: "Question", id: 1994 },
  { label: "Incident", id: 1972 },
  { label: "Problem", id: 1994 },
  { label: "Feature Request", id: 1972 },
  { label: "Services", id: 1994 },
  { label: "Billing & Payment", id: 1972 },
  { label: "Shifting", id: 1994 },
];

const complaint_status = [
  { label: "Open", id: 1994 },
  { label: "Pending", id: 1972 },
  { label: "InProgress", id: 1972 },
  { label: "Resolved", id: 1994 },
  { label: "Closed", id: 1994 },
];

const validation = Yup.object({
  type: Yup.object().required("Required").nullable(),
  title: Yup.string().required("Required").nullable(),
  priority: Yup.object().required("Required").nullable(),
  assignTo: Yup.object().required("Required").nullable(),
  complaint_status: Yup.object().required("Required").nullable(),
  //due_date: Yup.string().required("Required").nullable(),
  description: Yup.string().required("Required").nullable(),
});

export default function TicketGenerate() {
  const { empFullData } = useSelector((state) => state.employee);
  const { ticketAdded } = useSelector((state) => state.ticketing);

  const { accountIDData, assignToData, subscriberListData,subviewData } = useSelector((state) => state.subscriber);
  let user = JSON.parse(localStorage.getItem("user"));
  const [SearchText, SetSearchText] = useState(null);
  const [value, setValueDate] = useState(new Date());
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });
  const onSubmit = (data) => handleFormValues(data);

  useComponentDidUpdate(() => {
    SetSearchText(subviewData?.account_id);
  }, [subviewData]);



  const handleChange = (newValue) => {
    setValueDate(newValue);
    setValue("duedate", newValue);
  };

  useComponentDidUpdate(() => {
    let id = paramsQuery("id");
    let message = "";
    if (ticketAdded) {    
      message = "Ticket Added";
      enqueueSnackbar(message, {
        variant: "success",
      });
     //navigate(`/distributor/ticket-list`);
    }
  }, [ticketAdded]);

console.log(subviewData?.service_type, "subviewwww")

  useComponentDidMount(() => {
    let id = paramsQuery("id");
    let query = queryGenerator();
    let empquery = `distributor_ref_id=${user?.distributor_ref_id}`;
    dispatch(allEmpList(empquery));
    dispatch(subscriberList(query));
  }, []);

  const dispatch = useDispatch();
  const handleFormValues = (values) => {   
    let payload = {
      "title": values?.title,
      "priority": values?.priority?.value,
      "service_type": subviewData?.service_type,
      "type": values?.type?.label,
      "assign_to":values?.assignTo?.email_id,
      "complaint_status": values?.complaint_status.label,
      "due_date": values?.duedate,
      "description": values?.description,
      "distributor_ref_id": user?.distributor_ref_id,
      "created_by": "distributor",
      "email_id": values?.assignTo?.email_id,
      "cust_ref_id":paramsQuery("id"),
      "user_name": subviewData?.name,
      "user_mobile_no": subviewData?.mobile_no,
      "user_address": subviewData?.area,
      "emp_ref_id": values?.assignTo?._id,
      "staff_email": values?.assignTo?.email_id,
      "staff_name": values?.assignTo?.name,
      "staff_mobile_no": values.assignTo?.mobile_no,
      "complaint_forward_to": "distributor_access",
      "sms_access": user?.sms_access,
      "whatsapp_access": user?.whatsapp_access,
    };
    dispatch(addTicket(payload));
    reset();
    reset({ title: '' });
    reset({ description: '' });
  };
  

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&value=${value}`;
    return query;
  };

  const layoutInput = (input,title, error) => {
    return (
      <Grid item xs={4} sm={4} md={6}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={4} md={10}>
            <div className="sub-bouqut-form-title"></div>
            {input}
            {title}
            {error}
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const layoutAuto = (title, input, error) => {
    return (
      <Grid item xs={4} sm={4} md={12}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={4} md={11}>
            <div className="sub-bouqut-form-title">{title}</div>
            {input}
            {error}
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <div className="sub-bouqut-form-cnt AssignSeachContainer paddingticketgen">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          className="padding-btw-tiles"
        >
          {layoutInput("Account ID",
            <TextField
              disabled
              id="outlined-basic"
              placeholder="AccountID"
              type="text"
              size="small"
              value={SearchText}
              onChange={(e) => {
                SetSearchText(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className="txtwidth "
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} spacing={4}>
          <div className="title-container">Ticket Details</div>
        </Grid>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          className="padding-btw-tiles "
        >
          {layoutInput("Ticket Title",
            <TextField
              {...register("title")}
              id="outlined-basic"
              placeholdedr="Ticket Title"
              type="text"
              size="small"
              placeholder="Ticket Title"
              InputLabelProps={{
                shrink: true,
              }}
              className="txtwidth"
            />,
            <div className="field-error">{errors.title?.message}</div>
          )}

          {layoutInput("Ticket Type",
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={type}                  
                  isOptionEqualToValue={(option, value) => option.label === value.label}                
                  renderInput={(params) => <TextField {...params} size="small" placeholder="Select Ticket type" />}
                />
              )}
            />,

            <div className="field-error">{errors.type?.message}</div>
          )}
        </Grid>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          className="padding-btw-tiles "
        >
          {layoutInput("Priority",            
            <Controller
            control={control}
            name="priority"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, item) => {
                  onChange(item);
                }}
                value={value || ""}
                options={priority}                  
                isOptionEqualToValue={(option, value) => option.label === value.label}                
                renderInput={(params) => <TextField {...params} size="small" placeholder="Select priority" />}
              />
            )}
          />
            ,

            <div className="field-error">{errors.priority?.message}</div>
          )}

          {layoutInput(
                "Assigned To",
                <Controller
                  control={control}
                  name="assignTo"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value || ""}
                      options={empFullData || []}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      getOptionLabel={(item) => (item.name ? `${item.name}` : "")}
                      renderInput={(params) => <TextField {...params} size="small" placeholder="Select Assign To" />}
                    />
                  )}
                />,
                <div className="field-error">{errors.assignTo?.message}</div>
              ) }


        </Grid>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          className="padding-btw-tiles"
        >

         {layoutInput(
            "Activation Date",
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker inputFormat="MM/dd/yyyy" value={value} onChange={handleChange} renderInput={(params) => <TextField fullWidth size="small" placeholder="Enter Expiry Date" {...params} />} />
            </LocalizationProvider>,
            errors.duedate?.message
          )}

          {layoutInput("Status",
            <Controller
              control={control}
              name="complaint_status"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={complaint_status}
                  isOptionEqualToValue={(option) => option.label == value?.label || ""}
                  getOptionLabel={(item) => (item.label ? item.label : "")}
                  getOptionSelected={(option, value) =>
                    value === undefined ||
                    value === "" ||
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField {...params} size="small" placeholder="Select Status" />
                  )}
                />
              )}
            />,

            <div className="field-error">{errors.complaint_status?.message}</div>
          )}
        </Grid>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          className="padding-btw-tiles"
        >
          {layoutAuto(
            "Description",
            <TextareaAutosize
              {...register("description")}
              aria-label="minimum height"
              minRows={6}
              placeholder="Type Something.."
              className="txtwidth"
            />,
            <div className="field-error">{errors.description?.message}</div>
          )}
        </Grid>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 8 }}
          className="flex-end padding-btw-tiles btn-ticket"
        >
          <Stack spacing={2} direction="row" className=" ">
            <Button type="submit" variant="contained" className="bg-blue" size="small">
              Submit
            </Button>
          </Stack>
        </Grid>
      </form>
    </div>
  );
}
