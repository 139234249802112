import "./styles.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import SearchWithButtons from "@components/SearchWithButtons";
import {
  useComponentDidMount,
  useComponentDidUpdate,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { billingList, RemoveBill, RemoveBillUpdatecall, collectionListNew, paidByList, billingListExport } from "@reducers/api";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/subscriber";
import TicketStatus from "@components/TicketStatus";
import Grid from "@mui/material/Grid";
import BreadCrumb from "@components/Breadcrumb";
import TicketFilter from "@components/TicketFilter";

const ticketStatusData = [
  {
    id: 1,
    title: "Total Bill Amount",
    count: 30,
    icon: "",
  },
  {
    id: 2,
    title: "Total Bill Count",
    count: 28,
    icon: "",
  },

];

export default function UnPaidList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [unPaidList, setUnPaidList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterOne, setfirstfilter] = useState("");
  const [checkedVal, setChecked] = useState(false);

  const { billingListData, collectionListNewData } = useSelector(
    (state) => state.billing
  );
  const { billingListExportData } = useSelector((state) => state.subscriber);
  const { RemoveBillData } = useSelector(
    (state) => state.subscriber
  );

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&billing_status=open&limit=10&offset=0`;
    if (value?.type === "search") {
      query += `&limit=10&offset=0&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = queryGenerator();
    dispatch(billingList(query));
    let distid = `distributor_ref_id=${user?.distributor_ref_id}`;
    dispatch(paidByList(distid));
    dispatch(
      collectionListNew(
        `billing_status=open&limit=10&offset=0&distributor_ref_id=${user?.distributor_ref_id}${searchQuery ? `&value=${searchQuery}` : ""
        }`
      )
    );
  }, []);

  const serviceType = [
    { label: "Cable", value: "cable" },
    { label: "Internet", value: "internet" },
  ];

  const billingType = [
    { label: "Pre Paid", value: "pre paid" },
    { label: "Post Paid", value: "post paid" },
  ];

  useComponentDidUpdate(() => {
    let message = "";
    if (RemoveBillData) {
      message = "Removed...!";
      enqueueSnackbar(message, {
        variant: "success",
      });
      dispatch(reset());
      let query = queryGenerator();
      dispatch(billingList(query));
      let user = JSON.parse(localStorage.getItem("user"));
      let distid = `distributor_ref_id=${user?.distributor_ref_id}`;
      dispatch(paidByList(distid));
      dispatch(
        collectionListNew(
          `billing_status=open&limit=10&offset=0&distributor_ref_id=${user?.distributor_ref_id}${searchQuery ? `&value=${searchQuery}` : ""
          }`
        )
      );
    }
  }, [RemoveBillData]);

  const status = (color, text) => {
    return (
      <div className="status-btn-table" style={{ backgroundColor: color }}>
        {text}
      </div>
    );
  };

  useComponentDidUpdate(() => {
    let list = [];
    let listForExport = [];
    let filtered = billingListData?.data?.filter(
      (bill) => bill.payment_status === "open"
    );
    filtered.map((data, index) => {
      list.push({
        checked: false,
        invoiceid: data?.order_id,
        sNo: (page - 1) * 10 + index + 1,
        customerId: data?.customerData?._id,
        customerName: data?.customerData?.name,
        accountid: data?.customerData?.account_id,
        msqid: data?.customerData?.mso_id,
        stbno: data?.stb_no || data?.customerData?.stb_no[0]?.stb_no || "-",
        customerEmail: data?.customerData?.email_id,
        subarea: data?.customerData?.subarea || "-",
        flatno: data?.customerData?.flat_no || "-",
        billAmount: data?.bill_amount,
        collectionAmount: data?.collection_amount,
        dueAmount: data?.due_amount,
        discount: data?.discount_amount,
        paymentType: data?.payment_type,
        serviceType: data?.service_type,
        status: status("#73ca72", data?.billing_status),
        paidBy: data?.collected_by,
        completedDate: moment(data?.updated_at).format("MMMM Do YYYY, h:mm a"),
        createdDate: moment(data?.created_at).format("MMMM Do YYYY, h:mm a"),

      });
      listForExport.push({
        checked: false,
        invoiceid: data?.order_id,
        sNo: (page - 1) * 10 + index + 1,
        customerId: data?.customerData?._id,
        customerName: data?.customerData?.name,
        accountid: data?.customerData?.account_id,
        msqid: data?.customerData?.mso_id,
        customerEmail: data?.customerData?.email_id,
        subarea: data?.customerData?.subarea || "-",
        flatno: data?.customerData?.flat_no || "-",
        billAmount: data?.bill_amount,
        collectionAmount: data?.collection_amount,
        dueAmount: data?.due_amount,
        discount: data?.discount_amount,
        paymentType: data?.payment_type,
        serviceType: data?.service_type,
        paidBy: data?.collected_by,
        completedDate: moment(data?.updated_at).format("MMMM Do YYYY, h:mm a"),
        createdDate: moment(data?.created_at).format("MMMM Do YYYY, h:mm a"),

      });
    });
    setUnPaidList(list);
  }, [billingListData]);

  const handleRedirect = () => {
    navigate("/distributor/paid-list");
  };

  const handleRedirectInvoice = () => {
    navigate("/distributor/invoice-list");
  };



  const handleCheckBox = (index, checked) => {
    const clonedDataService = [...unPaidList];
    if (index === 0 || index > 0) {
      clonedDataService[index].checked = clonedDataService[index].checked
        ? false
        : true;
    } else if (index === "all") {
      for (let services of clonedDataService) {
        if (checked) {
          services.checked = true;
        } else {
          services.checked = false;
        }
      }
    }
    setUnPaidList(clonedDataService);
    setChecked(checked)
  };

  const handleMultipleDelete = () => {
    let filtered = unPaidList.filter((options) => options.checked === true);
    if (filtered.length === 0) {
      enqueueSnackbar("Please select atlease one to remove!", {
        variant: "error",
      });
      return;
    }
    let preparedeletepay = []
    filtered.map((data, index) => {
      preparedeletepay.push({
        "data": data.customerId
      });
    });
    let user = JSON.parse(localStorage.getItem("user"));
    var invoiceobject = filtered.reduce(
      (obj, item) => Object.assign(obj, { [item.invoiceid]: true }), {});
    const payload = {
      "distributor_ref_id": user?.distributor_ref_id,
      "data": invoiceobject
    }
    dispatch(RemoveBill(payload))
    dispatch(RemoveBillUpdatecall(preparedeletepay))
  }

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    let query = queryGenerator({ type: "search", state: search });
    dispatch(billingList(query));
  };

  const handleChange = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(
      billingList(
        `billing_status=open&distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=${value - 1}0${searchQuery ? `&value=${searchQuery}` : ""}${filterOne ? `&service_type=${filterOne}` : ""}`
      )
    );
  };

  const handleClickSearcch = (value, value1, priorityval, statusval, thirdValue) => {
    setPage(1);
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&billing_status=open`;
    if (value) {
      query += `&fromdate=${moment(value).format("YYYY-MM-DDTHH:mm:ss")}`
    }
    if (value1) {
      query += `&todate=${moment(value1).format("YYYY-MM-DDTHH:mm:ss")}`
    }
    if (priorityval) {
      query += `&service_type=${priorityval?.value}`
    }
    if (thirdValue) {
      query += `&billing_type=${thirdValue?.value}`
    }
    if (statusval) {
      query += `&paidBy=${statusval}`
    }
    setfirstfilter(priorityval?.value)

    dispatch(billingList(`limit=10&offset=0&${query}`));
    dispatch(
      collectionListNew(
        `&${query}${searchQuery ? `&value=${searchQuery}` : ""
        }`
      )
    );
  };

  const handleExtraExportClick = (path) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(billingListExport(`distributor_ref_id=${user?.distributor_ref_id}&billing_status=open`));
  };

  useComponentDidUpdate(() => {
    if (billingListExportData) {
      if (billingListExportData.fileData) {
        window.location.href = `data:application/vnd.ms-excel;base64,${billingListExportData.fileData}`
      }
      if (billingListExportData?.msg === "no data found") {
        enqueueSnackbar("No data....!", {
          variant: "error",
        });
      }
      dispatch(reset())
    }
  }, [billingListExportData]);

  const handleReset = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = queryGenerator();
    dispatch(billingList(query));
    let distid = `distributor_ref_id=${user?.distributor_ref_id}`;
    dispatch(paidByList(distid));
    dispatch(
      collectionListNew(
        `billing_status=open&limit=10&offset=0&distributor_ref_id=${user?.distributor_ref_id}${searchQuery ? `&value=${searchQuery}` : ""
        }`
      )
    );
    setfirstfilter("");
    setPage(1);
  };


  return (
    <div className="unpaidList-container ticketList-container">

      <Grid container spacing={2}>
        <Grid item xs={8}>

        </Grid>
        <Grid item xs={12} md={4} className="flex-end">
          <div className="unpaidList-title">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              secondaryHeading="Collection List"
              handleSecondaryClick={() => handleBreadCrumbRedirect("collection-list")}
              activeHeading="UnPaid List"
              handleActiveClick={() => handleBreadCrumbRedirect("paid-list")}
            />
          </div>

        </Grid>
      </Grid>
      <div className="ticket-list-table-cntr">
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={4} md={3}>
            <div className="unpaidList-title">UnPaid List</div>
          </Grid>
          <Grid item xs={0} sm={4} md={6}></Grid>
          <Grid item xs={4} sm={4} md={3} className="flex-end">
            <div className="">
              <TicketFilter labelPrimary="Service Type"
                PrimaryOption={serviceType} thirdOption={"Billing Type"} thirdList={billingType}
                handleFormValues={(data1, data2, data3, data4, data5) => handleClickSearcch(data1, data2, data3, data4, data5)}
                handleResetClick={handleReset} />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={2.4}
            style={{ paddingLeft: 0, paddingBottom: 0 }}
          >
            <TicketStatus
              className=" ticketCardGreen"
              title={ticketStatusData[0].title}
              count={collectionListNewData?.TotalBillAmount || "0"}
              icon={ticketStatusData[0].icon}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={2.4}
            style={{ paddingLeft: 0, paddingBottom: 0 }}
          >
            <TicketStatus
              className=" ticketCardRed"
              title={ticketStatusData[1].title}
              count={collectionListNewData?.TotalBillCount || "0"}
              icon={ticketStatusData[1].icon}
            />
          </Grid>


        </Grid>



        <div className="search-btns-padding">
          <SearchWithButtons
            primaryButton={{
              title: "Paid Details",
              type: "contained",
              color: "white",
              bgColor: COLORS.primary,
            }}
            secondaryButton={{
              title: "Invoice",
              type: "contained",
              color: "white",
              bgColor: COLORS.primary,
            }}
            secondryExtraButtonExportData={{
              title: "Export",
              type: "contained",
              color: COLORS.primary,
              bgColor: COLORS.primary,
            }}
            extraButton={{
              title: "Remove",
              type: "contained",
              color: "white",
              bgColor: COLORS.primary,
            }}
            handleExtraExportClick={() => handleExtraExportClick("export")}
            handlePrimaryClick={handleRedirect}
            handleChange={handleSearch}
            handleSecondaryClick={handleRedirectInvoice}
            handleExtraClick={() => handleMultipleDelete()}
          />
        </div>
        <div style={{ fontWeight: 'bold', fontSize: '15px', paddingBottom: '10px' }}>Showing entries {(page - 1) * 10 + 1} - {Math.ceil(billingListData?.totalCount / 10) === page ? billingListData?.totalCount : page + "0"} of {billingListData?.totalCount}</div>
        <TableView rows={tableRows} data={unPaidList} color={COLORS.primary} handleCheckBoxChange={handleCheckBox} bilingTable={true} checkedVal={checkedVal} />
        <div
          style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}
        >
          <Pagination
            count={Math.ceil(billingListData?.totalCount / 10) || 0}
            defaultPage={1}
            page={page}
            onChange={(event, value) => {
              setPage(value);
              handleChange(value);
            }}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
}
