import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { distEmployeeList, SubscriberListAssigned, areaList,getWhatsappList } from "@reducers/api";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { COLORS } from "@constants";
import {
  useComponentDidMount,
  useComponentDidUpdate,
  paramsQuery,
} from "@utils/useEffectHooks";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Pagination from "@mui/material/Pagination";
import TicketFilter from "@components/TicketFilter";

const validationAdd = Yup.object({
  accountId: Yup.object().required("Required").nullable(),
  ticketType: Yup.string().required("Required").nullable(),
});

export default function BulkSmsForm(props) {
  const { areaListData, SubscriberListAssignedData } =
    useSelector((state) => state.subscriber);
  const [page, setPage] = useState(1);
  const [accessType, setAccessType] = useState("sms");

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationAdd),
  });
  const navigate = useNavigate();
  const [SearchTextArea, SetSearchTextArea] = useState(null);
  const [expList, setexpList] = useState([]);
  const [tableView, setTableView] = useState(false);
  const [subAreaFilter, SetSubArea] = useState(null);
  const [filterOne, setFirstQuery] = useState("");
  const [filtertwo, setSecondQuery] = useState("");
  const [filterthree, setThirdQuery] = useState("");
  const [filterFour, setFourQuery] = useState("");

  const handleRedirect = () => {
    navigate(`/distributor/settings`);
  };

  console.log(SearchTextArea, "area")

  useComponentDidUpdate(() => {
    let list = [];
    SubscriberListAssignedData &&
      SubscriberListAssignedData?.data?.map((data, index) => {
        list.push({
          sNo: (page - 1) * 10 + index + 1,
          name: data?.name,
          id: data?._id,
          accNo: data?.account_no,
          canId: data?.can_id,
          stbNo: data?.stb_no[0]?.stb_no,
          serviceType: data?.service_type,
          billingType: data?.billing_type,
          assignedemp: data?.assign_to,
          email: data?.email_id,
          area: data?.area,
          subArea: data?.subarea,
          phone: data?.mobile_no,
          city: data?.city,

          autobill: data?.auto_billing === true ? "Yes" : "No",
          status: moment(data?.created_at).format("MMMM Do YYYY, h:mm a"),
        });
      });

    setexpList(list);
  }, [SubscriberListAssignedData]);

  const handleClickSearcch = (search) => {
    handleFormValues(SearchTextArea, subAreaFilter)
  };

  const handleFormValues = (SearchTextArea, subAreaFilter) => {
    setTableView(true);
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;

    if (SearchTextArea) {
      query += `&filterByArea=1&area=${SearchTextArea}`;
    }
    if (subAreaFilter) {
      query += `&filterBySubArea=1&subarea=${subAreaFilter}`;

    }
    dispatch(SubscriberListAssigned(`${query}`));
    setFirstQuery(SearchTextArea);
    setSecondQuery(subAreaFilter);
  };

  const handleChangeContant = (newValue) => {
    setValue("description", newValue?.description);
  };

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}`;
    let queryarea = `distributor_ref_id=${user?.distributor_ref_id}`;
    dispatch(distEmployeeList(query));
    dispatch(areaList(queryarea));
  }, []);

  const onSubmit = (data) => props?.handleFormValues(data, accessType);

  let usersms = JSON.parse(localStorage.getItem("user"));

  const handleChange = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(
      SubscriberListAssigned(
        `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=${value - 1}0${filterOne ? `&filterByArea=1&area=${filterOne}` : ""}${filtertwo ? `&filterBySubArea=1&subarea=${filtertwo}` : ""}${filterthree ? `&billing_type=${filterthree}` : ""}${filterFour ? `&service_type=${filterFour}` : ""} `
      )
    );
  };

  const serviceType = [
    { label: "Cable", value: "cable" },
    { label: "Internet", value: "internet" },
  ];

  const billingType = [
    { label: "Pre Paid", value: "pre paid" },
    { label: "Post Paid", value: "post paid" },
  ];

  const handleClickFilter = (value, value1, priorityval, statusval, thirdValue, fourthValue) => {
    setPage(1);
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0${filterOne ? `&filterByArea=1&area=${filterOne}` : ""}${filtertwo ? `&filterBySubArea=1&subarea=${filtertwo}` : ""}`;

    if (priorityval) {
      query += `&service_type=${priorityval?.value}`
    }
    if (thirdValue) {
      query += `&billing_type=${thirdValue?.value}`
    }

    dispatch(SubscriberListAssigned(query))
    setFourQuery(priorityval?.value);
    setThirdQuery(thirdValue?.value);

  };


  const handleReset = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0${filterOne ? `&filterByArea=1&area=${filterOne}` : ""}${filtertwo ? `&filterBySubArea=1&subarea=${filtertwo}` : ""}`;
    dispatch(SubscriberListAssigned(query))

    setPage(1);
    setThirdQuery("");
    setFourQuery("")
  };

  console.log(props,"prop-buulk")


  return (
    <div className="bulk-form-cnt BulkSeachContainer">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
          <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
            <FormControl className="flex-row">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="sms"
                name="radio-buttons-group"
              >
                <Grid>
                  <FormControlLabel
                    value="email"
                    size="small"
                    control={<Radio />}
                    onClick={() => {
                      setAccessType("mail");
                      setValue("accountId",[])
                      setValue("description",[])
                      setValue("subArea",[])
                      setValue("ticketType",[])
                      props?.handleChangeTemplates('email')
                    }}
                    label="Email"
                  />
                  {usersms?.sms_access === "enable" && (
                    <FormControlLabel
                      value="sms"
                      size="small"
                      control={<Radio />}
                      onClick={() => {
                        setAccessType("sms");
                        setValue("accountId",[])
                        setValue("description",[])
                        setValue("subArea",[])
                        setValue("ticketType",[])
                        props?.handleChangeTemplates('sms')                        
                      }}
                      label="SMS"
                    />
                  )
                  }
                  {usersms?.whatsapp_access === "enable" && (
                    <FormControlLabel
                      value="watsapp"
                      size="small"
                      control={<Radio />}
                      onClick={() => {
                        setAccessType("whatsapp");
                        setValue("accountId",[])
                        setValue("description",[])
                        setValue("subArea",[])
                        setValue("ticketType",[])
                        props?.handleChangeTemplates('whatsapp')
                      }}
                      label="WHATSAPP"
                    />
                  )
                  }

                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-bulkform">Select Type</div>

            <Grid item xs={2} sm={4} md={10}>
              {paramsQuery("id") ? (
                <TextField
                  size="small"
                  {...register("accountIdDis")}
                  disabled
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
              ) : (
                <Controller
                  control={control}
                  name="accountId"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      control={control}
                      name="accountId"
                      onChange={(event, item) => {
                        onChange(item);
                        handleChangeContant(item);
                      }}
                      value={value || ""}
                      options={props?.smsType || []}
                      isOptionEqualToValue={(option, value) =>
                        option.title === value.title
                      }
                      getOptionLabel={(item) => (item.title ? item.title : "")}
                      getOptionSelected={(option, value) =>
                        value === undefined ||
                        value === "" ||
                        option.title === value.title
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Select Template"
                        />
                      )}
                    />
                  )}
                />
              )}

              {<div className="field-error">{errors.accountId?.message}</div>}
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-bulkform">Area</div>
            <Grid item xs={2} sm={4} md={10}>
              <Controller
                control={control}
                name="ticketType"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    control={control}
                    name="ticketType"
                    onChange={(event, item) => {
                      console.log(item, "items")
                      onChange(item);
                      SetSearchTextArea(item)
                    }}
                    value={value || ""}
                    options={areaListData?.area || []}
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={(item) => (item ? item : "")}
                    getOptionSelected={(option, value) =>
                      value === undefined || value === "" || option === value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Select Area"
                      />
                    )}
                  />
                )}
              />

              {<div className="field-error">{errors.ticketType?.message}</div>}
            </Grid>
          </Grid>
        </Grid>

        <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-bulkform">Description</div>
            <Grid item xs={2} sm={4} md={10}>
              <textarea
                style={{ width: "100%", height: "80px", fontSize: "16px", border: "1px solid grey", borderRadius: "3px" }}
                className="textarea-add-bulk txtwidth"
                rows="2"
                cols="50"
                {...register("description")}
                variant="outlined"
                id="outlined-basic"
                placeholder="Description"
              />
            </Grid>
          </Grid>

          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-bulkform ">Sub Area</div>
            <Grid item xs={2} sm={4} md={10}>
              <Controller
                control={control}
                name="subArea"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    control={control}
                    name="subArea"
                    onChange={(event, item) => {
                      onChange(item);
                      SetSubArea(item)

                    }}
                    value={value || ""}
                    options={areaListData?.subarea || []}
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={(item) => (item ? item : "")}
                    getOptionSelected={(option, value) =>
                      value === undefined || value === "" || option === value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Select Area"
                      />
                    )}
                  />
                )}
              />

              {<div className="field-error">{errors.ticketType?.message}</div>}
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4} md={11}>

            <div className="btn-bulk-form">
              <Stack
                direction="row"
                className="flex-end "
                spacing={2}
              >
                <Button
                  size="small"
                  variant="outlined"
                  className="btn-outlined"
                  onClick={handleClickSearcch}
                  style={{ textTransform: "none", color: "#00249b" }}
                >
                  View Subscriber
                </Button>

              </Stack>
            </div>

          </Grid>

          <Grid item xs={2} sm={4} md={11}>
            <div className="padding-title-bulkform"></div>
            <div className="btn-bulk-form">
              <Stack
                direction="row"
                className="flex-end"
                spacing={2}
              >
                <Button
                  size="small"
                  type="submit"
                  variant="outlined"
                  className="btn-outlined"
                  onClick={handleRedirect}
                  style={{ textTransform: "none", color: "#00249b" }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  className="bg-blue"
                  style={{ textTransform: "none" }}
                >
                  Send
                </Button>
              </Stack>
            </div>
            <div className="padding-title-bulkform"></div>
            <div className="padding-title-bulkform"></div>
          </Grid>
          {tableView ? (
            <Grid item xs={11} sm={11} md={11}>
              <Grid item xs={12} sm={12} md={12} className="flex-end">
                <div className="">
                  <TicketFilter labelPrimary="Service Type"
                    PrimaryOption={serviceType} thirdOption={"Billing Type"} thirdList={billingType}
                    handleFormValues={(data1, data2, data3, data4, data5, data6) => handleClickFilter(data1, data2, data3, data4, data5, data6)}
                    handleResetClick={handleReset}
                    isAddOn={true} />
                </div>
              </Grid>
              <Grid className='bulksmsTable'>
                <TableView
                  rows={tableRows}
                  data={expList}
                  color={COLORS.primary}
                />
                <div
                  style={{
                    marginTop: 20,
                    justifyContent: "flex-end",
                    display: "flex",
                  }}
                >
                  <Pagination
                    count={Math.ceil(SubscriberListAssignedData?.totalCount / 10) || 0}
                    defaultPage={1}
                    page={page}
                    onChange={(event, value) => {
                      setPage(value);
                      handleChange(value);
                    }}
                    variant="outlined"
                    shape="rounded"
                  />
                </div>
              </Grid>
            </Grid>

          ) : (
            ""
          )}
        </Grid>
      </form>
    </div>
  );
}
