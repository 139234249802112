import "./style.scss";
import * as React from "react";
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import {
  bulkFileBroadcastUpload,
} from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TableView from "@components/TableView";
import { COLORS } from "@constants";
import { CSVLink } from "react-csv";

const Input = styled("input")({
  display: "none",
});

const status = (text) => {
  return (
    <Button variant="contained" size="small" className="bg-blue ">
      <CSVLink data={text} className="exportstatement">
        Export
      </CSVLink>
    </Button>
  );
};

const cableSampleData = [
  {
    "SL No": 1,
    broadcaster: "ABP",
    name: "BOUQUET 1",
    channels: "ABP Ananda,ABJ Majha",
    "service type": "cable",
    "billing type": "pre paid",
    "plan validity": "1 months",
    amount: 0.85,
  },
  {
    "SL No": 2,
    broadcaster: "Discovery",
    name: "HD Bouquet 1 – BASIC INFOTAINMENT HIGH DEFINITION (TAMIL) PACK",
    channels:
      "Discovery HD World,Animal Planet HD World,Discovery Kids,TLC HD World,Dsport,Discovery Jeet HD,Discovery Science,Discovery Turbo,Discovery Tamil",
    "service type": "cable",
    "billing type": "pre paid",
    "plan validity": "1 months",
    amount: 10,
  },
  {
    "SL No": 3,
    broadcaster: "Discovery",
    name: "HD Bouquet 2 – BASIC INFOTAINMENT HIGH DEFINITION PACK",
    channels:
      "Discovery HD World,Animal Planet HD World,Discovery Kids,TLC HD World,Dsport,Discovery Jeet HD,Discovery Science,Discovery Turbo",
    "service type": "cable",
    "billing type": "pre paid",
    "plan validity": "1 months",
    amount: "10.00",
  },
  {
    "SL No": 4,
    broadcaster: "Discovery",
    name: "HD Bouquet 3 – INFOTAINMENT + SPORTS HIGH DEFINITION PACK",
    channels:
      "Discovery HD World,Animal Planet HD World,TLC HD World,Discovery Kids,Dsport (w.e.f 6th Feb, 2017)",
    "service type": "cable",
    "billing type": "pre paid",
    "plan validity": "1 months",
    amount: "9.00",
  },
];

const tableRows = [
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },
  {
    title: "Sample Files for Bulk upload",
    key: "fileTitle",
    align: "left",
  },
  {
    title: "Download Sample File",
    key: "download",
    align: "left",
  },
];

const tableRowsdata = [
  {
    sNo: "1",
    fileTitle: "Sample Broadcaster File",
    download: status(cableSampleData),
  },
];

export default function BulkUploadBroadcast(props) {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileName, setFileName] = useState(null);

  const handleImgChange = async (selectedFile) => {
    var reader = new FileReader();
    reader.readAsDataURL(selectedFile);

    const formData = new FormData();
    formData.append("file", selectedFile);

    let payloads = {
      file: formData,
    };

    const fileUploadedUrl = await dispatch(bulkFileBroadcastUpload(payloads));
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  return (
    <div className="broadcastBluk-container">
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={4} md={7}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            spacing={4}
            className="createHeader headerpayment"
          >

            <Grid container spacing={2}>
              <Grid item xs={8}>
                <div className="settingsbulk-title">Bulk upload</div>
              </Grid>
            </Grid>
          </Grid>
          <div className="border-sts-pay">
            <Grid item xs={12}>
              <Stack spacing={2} direction="row" className="uploadBtn">
                <Grid item xs={12}>
                  <Stack spacing={2} direction="row" className="uploadBtn">
                    <label htmlFor="contained-button-file">
                      <Input
                        id="contained-button-file"
                        multiple
                        type="file"
                        accept=".csv"
                        onChange={(event) => {
                          setSelectedFile(event.target.files[0]);
                          setFileName(event.target.files[0]?.name)
                        }}
                      />
                      <Button
                        className="bg-blue"
                        size="small"
                        variant="contained"
                        component="span"
                      >
                        choose file

                      </Button>
                    </label>
                    <div className="fileNameStyle">{showFileName}</div>


                  </Stack>
                  {
                    selectedFile && (
                      <Button
                        className="bg-blue "
                        size="small"
                        variant="contained"
                        component="span"
                        onClick={() => {
                          handleImgChange(selectedFile);
                        }}
                      >
                        Upload
                        <CloudUploadIcon />
                      </Button>
                    )
                  }
                </Grid>
              </Stack>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <div className="paddingTable">
        <TableView
          rows={tableRows}
          data={tableRowsdata}
          color={COLORS.primary}
        />
      </div>
      <div>
        <Button
          className="bg-blue cancelBtn"
          size="small"
          variant="contained"
          component="span"
          onClick={() => {
            props?.handleClose()
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
