import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import "./styles.scss";
import { tableRowsPaymentHistory } from "./data";
import TableView from "@components/TableView";
import { COLORS } from "@constants";
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
};

export default function CustomerPaymentHistory(props) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    props?.handleClose();
  };

  useEffect(() => {
    setOpen(props?.show);
  }, [props?.show]);

  return (
    <div className="">
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style} className="currentPlan">
          <div className="header-raise-title justify-space-between flex-row " >
            <div></div>
            <div onClick={handleClose} className="cursor-pointer">{<CancelIcon />}</div>
          </div>
          <TableView
            rows={tableRowsPaymentHistory}
            data={props?.data}
            color={COLORS.primary}
          //handleCheckBoxChange={handleCheckBox}
          />
        </Box>
      </Modal>
    </div>
  );
}
