import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./styles.scss";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { stbUpdate,subscriberView } from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import { useComponentDidUpdate,paramsQuery,useComponentDidMount} from "@utils/useEffectHooks";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/subscriber";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getInventoryList } from "@reducers/api";

const validation = Yup.object({
  stb: Yup.object().required("Required").nullable(),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function BalaanceModal(props) {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });
  
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const { stbUpdateData,getInventoryListData } = useSelector( (state) => state.subscriber);


  console.log(props,"props")


  const handleClose = () => {
    props?.handleClose();
  };

  useComponentDidMount(() => {

  }, []);



  const handleFormValues = (data) => {
    console.log(data,"daa")
    let id = paramsQuery("id");
    let user = JSON.parse(localStorage.getItem("user"));
    let distid = user?.distributor_ref_id;
    let payload = {
      cust_ref_id: id,
      distributor_ref_id: distid,
      old_stb_no: data?.old_stb_no,
      stb_no: data?.stb?.stb_no,
      type: data?.type,
      _id: data?.id,
    };
    dispatch(stbUpdate(payload));
  };

  const onSubmit = (data) => handleFormValues(data);

  useComponentDidUpdate(() => {    
    if (props?.stbDetails) {
     setValue("type",props?.stbDetails?.type)
     setValue("id",props?.stbDetails?._id) 
     setValue("stb",props?.stbDetails) 
     setValue("old_stb_no",props?.stbDetails?.stb_no) 
    }
  }, [props?.stbDetails]);  

  useEffect(() => {
    setOpen(props?.show);
  }, [props?.show]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="makePayment-cntr">
      <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Edit STB</h2>
        <div className="divider-line"></div>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          className="padding-btw-tiles"
        >
          <Grid item xs={5} sm={5} md={5}>
            <div className="make-payment-form-title ">Type </div>
            <TextField
                disabled
                {...register("type")}
                id="outlined-basic"
                className="txtwidth"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                placeholder="Type"
              />
          </Grid>
          <Grid item xs={1} sm={1} md={1}></Grid>
          <Grid item xs={5} sm={5} md={5}>
            <div className="make-payment-form-title ">STB </div>
            {/* <TextField
                {...register("stb")}
                id="outlined-basic"
                className="txtwidth"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                placeholder="Enter STB"
              /> */}
            <Controller
              control={control}
              name="stb"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={props?.stbnumbers || []}
                  isOptionEqualToValue={(option, value) => option.stb_no === value.stb_no}
                  getOptionLabel={(item) => (item.stb_no ? item.stb_no : "")}
                  renderInput={(params) => <TextField {...params} size="small" placeholder="Select STB" />}
                />
              )}
            />
          </Grid>
        </Grid>      
        <Stack spacing={2} direction="row" className="btnright" style={{marginTop:'20px'}}>
            <Button
              size="small"
              variant="outlined" 
              className="btn-outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              className="bg-blue"
              type="submit"
            >
              Update
            </Button>
          </Stack>
          </form>
      </Box>
    </Modal>
  );
}
