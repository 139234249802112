import "./style.scss";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Checkbox from "@mui/material/Checkbox";
import React, { useState } from "react";
import {
  useComponentDidMount,
  useComponentDidUpdate,
} from "@utils/useEffectHooks";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { COLORS } from "@constants";
import { adminAllPlans } from "@reducers/api";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import InputBase from "@mui/material/InputBase";

const validation = Yup.object({
  serviceType: Yup.object().required("Required").nullable(),
  packageValidity: Yup.object().required("Required").nullable(),
  billingType: Yup.object().required("Required").nullable(),
  packageCategory: Yup.object().required("Required").nullable(),
  planName: Yup.string().required("Required").nullable(),
  planAmount: Yup.string().required("Required").nullable(),
  disablePlan: Yup.object().required("Required").nullable(),
});

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function AddSubscriberPlanForm(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRedirect = () => {
    navigate(`/distributor/subscriber-plan-list`);
  };
  const [checked, setChecked] = React.useState([0]);
  const [broadcastType, setBroadcastType] = useState("");
  const [alacartList, setAlaCartList] = useState([]);
  const [searchalacartList, setSearchAlaCartList] = useState([]);
  const { allPlansData, adminAllPlansData } = useSelector(
    (state) => state.plan
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });

  const onSubmit = (data) => {
    let filtered = alacartList.filter((options) => options.checked === true);
    props?.handleFormValues(data, filtered);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const serviceType = [
    { id: 1, label: "Cable", value: "cable" },
    { id: 1, label: "Internet", value: "internet" },
  ];

  const billingType = [
    { id: 1, label: "Pre Paid", value: "pre paid" },
    { id: 2, label: "Post Paid", value: "post paid" },
  ];

  const packageValidity = [
    { id: 1, label: "1 Month", value: "1 months" },
    { id: 2, label: "3 Month", value: "3 months" },
    { id: 3, label: "6 Month", value: "6 months" },
    { id: 4, label: "1 Year", value: "1 year" },
  ];

  const packageCategory = [{ id: 1, label: "Package ", value: "package" }];

  const disablePlan = [
    { id: 1, label: "Yes", value: "true" },
    { id: 2, label: "No", value: "false" },
  ];

  const internetThings = [
    { id: 1, label: "Tacitine", value: "tacitine" },
  ];

  const filterObject = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options.value.toString() === value.toString()
      );
    setValue(title, filtered[0]);
  };

  useComponentDidUpdate(() => {
    filterObject("serviceType", serviceType, props?.data?.service_type);
    filterObject("billingType", billingType, props?.data?.billing_type);
    filterObject(
      "packageValidity",
      packageValidity,
      props?.data?.plan_validity
    );
    filterObject(
      "packageCategory",
      packageCategory,
      props?.data?.package_category
    );
    filterObject("disablePlan", disablePlan, props?.data?.disabled);
    filterObject("isp", internetThings, props?.data?.isp);
    setValue("planAmount", props?.data?.plan_amount);
    setValue("planName", props?.data?.plan_name);
    setValue("mobile", props?.data?.mobile_no);
    setValue("email", props?.data?.email_id);
    setValue("data", props?.data?.data);
    setValue("speed", props?.data?.speed);
  }, [props]);


  useComponentDidUpdate(() => {

    if (adminAllPlansData) {
      let preparedeletepay = [];
      adminAllPlansData?.map((data, index) => {
        let filter = props?.data ? props?.data?.channels?.filter(
          (serviceList) => serviceList?.plan_name === data?.plan_name
        ) : ""

        if (filter?.length > 0) {
          filter.map((datachannel, index) => {
            preparedeletepay.push({
              add_type: data.add_type,
              billing_type: data.billing_type,
              channel_count: data.channel_count || "",
              channel_type: data.channel_type || "",
              created_by: "admin",
              disabled: false,
              package_category: "ala cart",
              plan_amount: data.plan_amount,
              plan_name: data.plan_name,
              plan_validity: data.plan_validity,
              service_type: data.service_type,
              selected: true,
              checked: true,
            });
          });

        } else {
          preparedeletepay.push({
            add_type: data.add_type,
            billing_type: data.billing_type,
            channel_count: data.channel_count || "",
            channel_type: data.channel_type || "",
            created_by: "admin",
            disabled: false,
            package_category: "ala cart",
            plan_amount: data.plan_amount,
            plan_name: data.plan_name,
            plan_validity: data.plan_validity,
            service_type: data.service_type,
            selected: true,
            checked: false,
          });
        }


      });
      setAlaCartList(preparedeletepay);
      setSearchAlaCartList(preparedeletepay)
    }
  }, [adminAllPlansData]);

  const handleCheckBoxAlacart = (index, checked) => {
    const clonedDataService = [...searchalacartList];
    if (index === 0 || index > 0) {
      clonedDataService[index].checked = clonedDataService[index].checked
        ? false
        : true;
    } else if (index === "all") {
      for (let services of clonedDataService) {
        if (checked) {
          services.checked = true;
        } else {
          services.checked = false;
        }
      }
    }
    setSearchAlaCartList(clonedDataService);
  };

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(adminAllPlans(`package_category=ala cart`));
  }, []);

  const handleSearchAlacart = (search) => {
    
    let _users = alacartList.filter(function (user) {
      return user?.plan_name.toLowerCase().match(search);
    });

    setSearchAlaCartList(_users)
    
  };

  let filteredarray = alacartList?.filter(
    (serviceList) => serviceList.checked === true
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12 }}
        justifyContent="center"
      >
        <Grid item xs={4} sm={4} md={8} className="add-sub-plan-cnt">
          <div>
            {props?.data?._id ? (
              <div className="title-card">Edit Plan</div>
            ) : (
              <div className="title-card">New Plan</div>
            )}
          </div>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={4} md={5.5} className="form-bdy-padding">
              <Controller
                control={control}
                name="serviceType"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || ""}
                    options={serviceType}
                    isOptionEqualToValue={(option) => option.label == value?.label || ""}
                    getOptionLabel={(item) => (item.label ? item.label : "")}
                    getOptionSelected={(option, value) =>
                      value === undefined ||
                      value === "" ||
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Service Type"
                      />
                    )}
                  />
                )}
              />
              <div className="field-error">{errors.serviceType?.message}</div>
            </Grid>
            <Grid item xs={0} sm={4} md={1}></Grid>
            <Grid item xs={4} sm={4} md={5.5} className="form-bdy-padding">
              <Controller
                control={control}
                name="packageValidity"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || ""}
                    options={packageValidity}
                    isOptionEqualToValue={(option) => option.label == value?.label || ""}
                    getOptionLabel={(item) => (item.label ? item.label : "")}
                    getOptionSelected={(option, value) =>
                      value === undefined ||
                      value === "" ||
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Package Validity"
                      />
                    )}
                  />
                )}
              />
              <div className="field-error">
                {errors.packageValidity?.message}
              </div>
            </Grid>
          </Grid>

          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={4} md={5.5} className="form-bdy-padding">
              <Controller
                control={control}
                name="billingType"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || ""}
                    options={billingType}
                    isOptionEqualToValue={(option) => option.label == value?.label || ""}
                    getOptionLabel={(item) => (item.label ? item.label : "")}
                    getOptionSelected={(option, value) =>
                      value === undefined ||
                      value === "" ||
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Billing Type"
                      />
                    )}
                  />
                )}
              />
              <div className="field-error">{errors.billingType?.message}</div>
            </Grid>
            <Grid item xs={0} sm={4} md={1}></Grid>
            <Grid item xs={4} sm={4} md={5.5} className="form-bdy-padding">
              <Controller
                control={control}
                name="packageCategory"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || ""}
                    options={packageCategory}
                    isOptionEqualToValue={(option) => option.label == value?.label || ""}
                    getOptionLabel={(item) => (item.label ? item.label : "")}
                    getOptionSelected={(option, value) =>
                      value === undefined ||
                      value === "" ||
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Package Category"
                      />
                    )}
                  />
                )}
              />
              <div className="field-error">
                {errors.packageCategory?.message}
              </div>
            </Grid>
          </Grid>

          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={4} md={5.5} className="form-bdy-padding">
              <TextField
                {...register("planName")}
                placeholder="Plan Name"
                size="small"
                className="txtwidth"
                variant="outlined"
                id="outlined-basic"
              />
              <div className="field-error">{errors.planName?.message}</div>
            </Grid>
            <Grid item xs={0} sm={4} md={1}></Grid>
            <Grid item xs={4} sm={4} md={5.5} className="form-bdy-padding">
              <TextField
                {...register("planAmount")}
                placeholder="Amount"
                size="small"
                className="txtwidth"
                variant="outlined"
                id="outlined-basic"
                type="number"
              />
              <div className="field-error">{errors.planAmount?.message}</div>
            </Grid>
          </Grid>
          {watch("serviceType") && watch("serviceType").value === "internet" && (
            <div >
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4} sm={4} md={5.5} className="form-bdy-padding">
                  <TextField
                    {...register("data")}
                    placeholder="Data"
                    size="small"
                    className="txtwidth"
                    variant="outlined"
                    id="outlined-basic"
                  />

                </Grid>
                <Grid item xs={0} sm={4} md={1}></Grid>
                <Grid item xs={4} sm={4} md={5.5} className="form-bdy-padding">
                  <TextField
                    {...register("speed")}
                    placeholder="Speed"
                    size="small"
                    className="txtwidth"
                    variant="outlined"
                    id="outlined-basic"
                    type="number"
                  />

                </Grid>
              </Grid>
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                className="form-bdy-padding"
              >
                <Grid item xs={4} sm={4} md={12}>
                  <Controller
                    control={control}
                    name="isp"
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, item) => {
                          onChange(item);
                        }}
                        value={value || ""}
                        options={internetThings}
                        isOptionEqualToValue={(option) => option.label == value?.label || ""}
                        getOptionLabel={(item) => (item.label ? item.label : "")}
                        getOptionSelected={(option, value) =>
                          value === undefined ||
                          value === "" ||
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            placeholder="ISP"
                          />
                        )}
                      />
                    )}
                  />

                </Grid>
              </Grid>

            </div>
          )}



          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            className="form-bdy-padding"
          >
            <Grid item xs={4} sm={4} md={12}>
              <Controller
                control={control}
                name="disablePlan"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || ""}
                    options={disablePlan}
                    isOptionEqualToValue={(option) => option.label == value?.label || ""}
                    getOptionLabel={(item) => (item.label ? item.label : "")}
                    getOptionSelected={(option, value) =>
                      value === undefined ||
                      value === "" ||
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Disable Plan"
                      />
                    )}
                  />
                )}
              />
              <div className="field-error">{errors.disablePlan?.message}</div>
            </Grid>
          </Grid>
          {watch("serviceType") && watch("serviceType").value === "cable" && (
            <div >
              <Grid>
              <Grid item xs={4} md={4}>
                <InputBase
                  className="search-input"
                  size="small"
                  onChange={(e) => {
                    handleSearchAlacart(e.target.value);
                  }}
                  placeholder="Search By"
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon style={{ color: COLORS.primary }} />
                    </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    style: { color: COLORS.primary },
                  }}
                />
              </Grid>
              <Grid item xs={4} md={4}>
             
                <p>Selected Count : {filteredarray?.length}</p>
             
              </Grid>
              </Grid>
             
              <div
                style={{ paddingLeft: 30, paddingRight: 30, marginBottom: 20, marginTop: 10 }}
              >

                <Grid
                  container
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  style={{ height: "300px", overflow: "scroll" }}
                >
                  {searchalacartList?.map((prod, i) => (
                    <Grid item xs={4} sm={4} md={4} key={i}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                handleCheckBoxAlacart(i);
                              }}
                              sx={{
                                "&.Mui-checked": {
                                  color: COLORS.primary,
                                },
                              }}
                              {...label}
                              checked={prod?.checked}
                              size="small"
                            />
                          }
                          label={prod.plan_name}
                        />
                        <div className="marginLeftAddplan">
                          Rs:{prod.plan_amount}
                        </div>
                      </FormGroup>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          )}
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 8 }}
            className="flex-end form-bdy-padding"
          >
            <Stack spacing={2} direction="row" className=" ">
              <Button
                variant="outlined"
                className=""
                size="small"
                style={{
                  textTransform: "capitalize",
                  borderColor: "#00249b",
                  color: "#00249b",
                }}
                onClick={handleRedirect}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="bg-blue"
                size="small"
                type="submit"
                style={{ textTransform: "capitalize" }}
              >
                {props?.data?._id ? "Update Plan" : "Create Plan"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
