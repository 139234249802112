import { useComponentDidMount, useComponentDidUpdate, monthConversion } from "@utils/useEffectHooks";
import { useState } from "react";
import PlanSelector from "@components/PlanSelector";
import ProgressBar from "@components/ProgressBar";
import TileAndIconTile from "@components/TitleAndIconTile";
import CountAndStatusTile from "@components/CountAndStatusTile";
import PaymentCollectionBar from "@components/PaymentCollectionBar";
import Overview from "@components/Overview";
import SubTile from "@components/SubTile";
import TableView from "@components/TableView";
import { Grid } from "@mui/material";
import { distributorRow, monthlyPayment, month, collectionRows } from "./data";
import { COLORS } from "@constants";
import PaymentSummary from "@components/Payment-summary";
import PersonIcon from "@mui/icons-material/Person";
import { reset } from "@reducers/subscriber";
import GroupsIcon from "@mui/icons-material/Groups";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import "./styles.scss";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  paymentSummaryDownload,
  employeeList,
  billingHistoryToday,
  subscriber,
  SubscriberCount,
  billingHistory,
  paymentSummary,
  subscriberPlan,
  subscriberPlanTomorrow,
  subscriberPlanToday,
  subscriberPlanTotalExpiration,
  subscriberPlanfiveExpiration,
  subscriberPlanCablePostpaid,
  billingHistoryYesterday,
  distributorPlanExpiry,
  paymentSummaryTranscDownload,
  CollectionAgentList
} from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

export default function DistributorDashboard() {
  const { enqueueSnackbar } = useSnackbar();
  const { paymentSummaryDownloadData, paymentSummaryTranscDownloadData } = useSelector((state) => state.subscriber);
  const [totalHistory, setTotalHistory] = useState([]);
  const [BillingType, setBillingType] = useState("");
  const [agentList, setagentList] = useState([]);
  const [ServiceType, setServiceType] = useState("cable");
  const date = { todate: moment().format("YYYY-MM-DDTHH:mm:ss"), fromdate: moment().startOf("month").format("YYYY-MM-DDTHH:mm:ss") };
  const todayDate = moment().format("YYYY-MM-DDTHH:mm:ss");
  const yesterdayDate = moment().add(-1, "days").format("YYYY-MM-DDTHH:mm:ss");
  const tomorrowDate = moment().add(1, "days").format("YYYY-MM-DDTHH:mm:ss");
  const fivedaysDate = moment().add(5, "days").format("YYYY-MM-DDTHH:mm:ss");
  const tendaysDate = moment().add(10, "days").format("YYYY-MM-DDTHH:mm:ss");
  const defaultPlanQuery = {
    serviceType: "cable",
    billingType: "",
    date: date,
    todayDate: todayDate,
    yesterdayDate: yesterdayDate,
    tomorrowDate: tomorrowDate,
    fivedaysDate: fivedaysDate,
    tendaysDate: tendaysDate,
  };
  const dispatch = useDispatch();

  const {
    employeeData,
    billingHistoryTodayData,
    billingHistoryYesterdayData,
    subscriberData,
    billingHistoryData,
    paymentSummaryData,
    subscriberPlanData,
    subscriberPlanTomorrowData,
    subscriberPlanTodayData,
    subscriberPlanfiveExpirationData,
    subCountData,
    distributorPlanExpiryData,
  } = useSelector((state) => state.dashboard);

  const { CollectionAgentListData } = useSelector(
    (state) => state.collection
  );
  const navigate = useNavigate();

  const downloadExcel = (value) => {
    console.log(value, "validss")
    let user = JSON.parse(localStorage.getItem("user"));
    if (value == "Today") {
      const defaultPlanQueryToday = {
        serviceType: ServiceType,
        billingType: BillingType,
        date: { todate: yesterdayDate, fromdate: todayDate },
        todayDate: todayDate,
        distributor_id: user?.distributor_ref_id,
      };
      dispatch(paymentSummaryTranscDownload(defaultPlanQueryToday));
    }
    if (value == "Tomorrow") {
      const defaultPlanQueryTmrw = {
        serviceType: ServiceType,
        billingType: BillingType,
        date: { todate: todayDate, fromdate: tomorrowDate },
        todayDate: todayDate,
        distributor_id: user?.distributor_ref_id,
      };
      dispatch(paymentSummaryTranscDownload(defaultPlanQueryTmrw));
    }
    if (value == "In 5-Days") {
      const defaultPlanQuery5dys = {
        serviceType: ServiceType,
        billingType: BillingType,
        date: { todate: todayDate, fromdate: fivedaysDate },
        todayDate: todayDate,
        distributor_id: user?.distributor_ref_id,
      };
      dispatch(paymentSummaryTranscDownload(defaultPlanQuery5dys));
    }
    if (value == "Total expiration") {
      const defaultPlanQueryTotal = {
        serviceType: ServiceType,
        billingType: BillingType,
        todayDate: todayDate,
        distributor_id: user?.distributor_ref_id,
        is_active : false,
        package_category: "package"
      };
      if (subscriberPlanfiveExpirationData) {
        if (subscriberPlanfiveExpirationData.fileData) {
          window.location.href = `data:application/vnd.ms-excel;base64,${subscriberPlanfiveExpirationData.fileData}`;
        }
        if (subscriberPlanfiveExpirationData?.msg == "no data found") {
          enqueueSnackbar("No data....!", {
            variant: "error",
          });
        }
        dispatch(subscriberPlanfiveExpiration(defaultPlanQueryTotal));
        dispatch(reset());
      }
      
      
    }
  };


  const handleSelectedValue = (values) => {
    defaultPlanQuery.billingType = values.billingType;
    defaultPlanQuery.serviceType = values.serviceType;
    setBillingType(values.billingType);
    setServiceType(values.serviceType);
    dashboardApis(values);
  };

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
    setTimeout(() => {
      dispatch(employeeList(defaultPlanQuery));
      dashboardApis(defaultPlanQuery);
    }, 300);
  }, []);

  useComponentDidUpdate(() => {
    if (paymentSummaryTranscDownloadData) {
      if (paymentSummaryTranscDownloadData.fileData) {
        window.location.href = `data:application/vnd.ms-excel;base64,${paymentSummaryTranscDownloadData.fileData}`;
      }
      if (paymentSummaryTranscDownloadData?.msg == "no data found") {
        enqueueSnackbar("No data....!", {
          variant: "error",
        });
      }
      dispatch(reset());
    }
  }, [paymentSummaryTranscDownloadData]);



  useComponentDidUpdate(() => {
    if (paymentSummaryDownloadData) {
      if (paymentSummaryDownloadData.fileData) {
        window.location.href = `data:application/vnd.ms-excel;base64,${paymentSummaryDownloadData.fileData}`;
      }
      if (paymentSummaryDownloadData?.msg == "no data found") {
        enqueueSnackbar("No data....!", {
          variant: "error",
        });
      }
      dispatch(reset());
    }
  }, [paymentSummaryDownloadData]);

  const paymentSummaryExcel = () => {
    let user = JSON.parse(localStorage.getItem("user"));
   
    const defaultPlanQueryPayment = {
      serviceType: ServiceType,
      billingType: BillingType,
      date: date,
      todayDate: todayDate,
      yesterdayDate: yesterdayDate,
      tomorrowDate: tomorrowDate,
      fivedaysDate: fivedaysDate,
      tendaysDate: tendaysDate,
      payment_status: "all",
      distributor_id: user?.distributor_ref_id,
    };
    dispatch(paymentSummaryDownload(defaultPlanQueryPayment));
  };

  const paidSummaryExcel = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    const defaultPlanQueryPayment = {
      serviceType: ServiceType,
      billingType: BillingType,
      date: date,
      todayDate: todayDate,
      yesterdayDate: yesterdayDate,
      tomorrowDate: tomorrowDate,
      fivedaysDate: fivedaysDate,
      tendaysDate: tendaysDate,
      payment_status: "success",
      distributor_id: user?.distributor_ref_id,
    };
    dispatch(paymentSummaryDownload(defaultPlanQueryPayment));
  };

  const unPaidSummaryExcel = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    const defaultPlanQueryPayment = {
      serviceType: ServiceType,
      billingType: BillingType,
      date: date,
      todayDate: todayDate,
      yesterdayDate: yesterdayDate,
      tomorrowDate: tomorrowDate,
      fivedaysDate: fivedaysDate,
      tendaysDate: tendaysDate,
      payment_status: "open",
      distributor_id: user?.distributor_ref_id,
    };
    dispatch(paymentSummaryDownload(defaultPlanQueryPayment));
  };

  useComponentDidUpdate(() => {
    let totalHistory = [];
    for (let data of billingHistoryData) {
      for (let bill of data.bills) {
        if (bill.year) {
          var transtemp = {
            year: bill.year,
            monthName: monthConversion(bill.month),
            month: bill.month,
            amounttopay: `₹ ${bill.total_bill_amount || 0}`,
            monthpaid: `₹ ${bill.total_coll_amount || 0}`,
            monthnotPaid: `₹ ${bill.unpaid_amount || 0}`,
            yearMonth: `${monthConversion(bill.month)} ${bill.year}`,
          };
          totalHistory.push(transtemp);
        }
      }
    }
    totalHistory.sort(function (a, b) {
      var MONTH = month;
      return a.year - b.year || MONTH[a.monthName.toUpperCase()] - MONTH[b.monthName.toUpperCase()];
    });

    setTotalHistory(totalHistory.reverse());
  }, [billingHistoryData]);

  const dashboardApis = (planQuery) => {
    let user = JSON.parse(localStorage.getItem("user"));
    planQuery["distributor_id"] = user?.distributor_ref_id;
    defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
    dispatch(SubscriberCount(`distributor_ref_id=${user?.distributor_ref_id}&billing_type=${planQuery.billingType}&service_type=${planQuery.serviceType}`));
    dispatch(billingHistoryToday(planQuery));
    dispatch(billingHistoryYesterday(planQuery));
    dispatch(billingHistory(planQuery));
    dispatch(subscriberPlan(defaultPlanQuery));
    dispatch(subscriberPlanTomorrow(defaultPlanQuery));
    dispatch(subscriberPlanToday(defaultPlanQuery));
    dispatch(subscriberPlanTotalExpiration(defaultPlanQuery));
    dispatch(subscriberPlanfiveExpiration(defaultPlanQuery));
    dispatch(subscriberPlanCablePostpaid(defaultPlanQuery));
    dispatch(paymentSummary(defaultPlanQuery));
    dispatch(subscriber(planQuery));
    dispatch(distributorPlanExpiry(`distributor_ref_id=${user?.distributor_ref_id}`));
    dispatch(CollectionAgentList(`distributor_ref_id=${user?.distributor_ref_id}`));
  };

  const prepaidSum = subscriberData.cusPrepaidActiveCount + subscriberData.cusPrepaidInActiveCount;
  const postpaidSum = subscriberData.cusPostpaidActiveCount + subscriberData.cusPostpaidInActiveCount;
  const totalSubs = prepaidSum + postpaidSum;

  const renderHeadingWithExtend = (title, path) => {
    return (
      <div className="heading-title-secondary">
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={8} className="heading-title-bold">
            {title}
          </Grid>
          <Grid item xs={2} sm={4} md={4} className="flex-end heading-title-blue flex-center" style={{ color: COLORS.primary }}>
            <div className="flex-center cursor-pointer" onClick={() => path && navigate(path)}>
              View Full Details <OpenInFullIcon style={{ marginLeft: 15, color: COLORS.primary }} />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  const calculateProgress = (value) => {
    const total = subCountData?.activeSubscriberCount + subCountData?.inActiveSubscriberCount + subCountData?.deletedSubscriberCount;
    const result = (value / total) * 100;
    return result.toFixed();
  };

  console.log(subscriberPlanfiveExpirationData, "collect")

  useComponentDidUpdate(() => {
    let list = [];

    CollectionAgentListData && CollectionAgentListData?.agentSummary?.map((data, index) => {
      list.push({
      
        empName: data?.empName,
        collectedAmt: data?.transaction[0]?.totalCollectedAmount || 0,
        collectedCount: data?.transaction[0]?.count || 0,
        newCustCount: data?.newCustCount || 0,
        totalConnection : data?.totalCustCount || 0,
     
      });

    });

    setagentList(list);
   
  }, [CollectionAgentListData]);

  return (
    <div className="dashboard-container">
      <PlanSelector handleSelectedValue={handleSelectedValue} />
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={4} md={5.5}>
          <div className="heading-title">Subscribers</div>
          <div className="flex-row justify-space-between" style={{ marginTop: 40 }}>
            <div style={{ width: "145px" }}>
              <ProgressBar color="#41DB70" title="Active" count={subCountData?.activeSubscriberCount} value={calculateProgress(subCountData?.activeSubscriberCount)} />
            </div>
            <div style={{ width: "145px" }}>
              <ProgressBar color="#FF4D4D" title="Inactive" count={subCountData?.inActiveSubscriberCount} value={calculateProgress(subCountData?.inActiveSubscriberCount)} />
            </div>
            <div style={{ width: "145px" }}>
              <ProgressBar color="#00BAFF" title="Deleted" count={subCountData?.deletedSubscriberCount} value={calculateProgress(subCountData?.deletedSubscriberCount)} />
            </div>
          </div>
        </Grid>
        <Grid item xs={4} sm={4} md={0.5}></Grid>
        <Grid item xs={4} sm={4} md={5}>
          <div className="heading-title" style={{ marginLeft: "1rem" }}>
            Payment Collection Summary
          </div>
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}  className="cursor-pointer">
            <PaymentCollectionBar data={billingHistoryTodayData && billingHistoryTodayData[0]} yesterdayData={billingHistoryYesterdayData && billingHistoryYesterdayData[0]} serviceType={ServiceType} billingType={BillingType} />
          </div>
        </Grid>
      </Grid>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={4}>
        <Grid item xs={4} sm={4} md={6}>
          <div className="heading-title">Subscriber Plan Expiration</div>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={{ xs: 1, md: 6 }}>
          <Grid item xs={12} sm={6} md={6}>
              <SubTile color={COLORS.secondary} paymentDownload={downloadExcel} data={{ titleOne: "Today", countOne: subscriberPlanTodayData, titleTwo: "Tomorrow", countTwo: subscriberPlanTomorrowData}} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <SubTile color={COLORS.primary} paymentDownload={downloadExcel} data={{ titleOne: "In 5-Days", countOne: subscriberPlanData, titleTwo: "Total expiration", countTwo: subscriberPlanfiveExpirationData }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          {renderHeadingWithExtend("Employees", "/distributor/employee-list")}
          <CountAndStatusTile
            mainTileData={{ title: "Employees", count: (employeeData && employeeData?.activeEmployee) + (employeeData && employeeData?.inactiveEmployee) || 0, color: COLORS.secondary, mainicon: <PersonIcon /> }}
            activeTileData={{ title: "Active", count: (employeeData && employeeData?.activeEmployee) || 0, color: "#00DB00", subicon: <PersonIcon /> }}
            inActiveTileData={{ title: "Inactive", count: (employeeData && employeeData?.inactiveEmployee) || 0, color: "#EF0000", subicon: <PersonIcon /> }}
          />
        </Grid>
      </Grid>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={4}>
        <Grid item xs={4} sm={4} md={6}>
          <div className="heading-title">Monthly Payment Summary</div>
          <TableView rows={monthlyPayment} data={totalHistory && totalHistory.slice(0, 4)} color={COLORS.primary} />
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          <div className="heading-title">Overview</div>
          <Overview data={paymentSummaryData} />
        </Grid>
      </Grid>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={4}>
        <Grid item xs={4} sm={4} md={6}>
          <div className="heading-title">Payment Summary</div>
          <PaymentSummary data={paymentSummaryData} color={COLORS.primary} paymentSummaryExcel={paymentSummaryExcel} paidSummaryExcel={paidSummaryExcel} unPaidSummaryExcel={unPaidSummaryExcel} />
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          {renderHeadingWithExtend("Subscriber", "/distributor/subscriber-list")}
          <TileAndIconTile color={COLORS.secondary} count={totalSubs} title="Total Subscribers" />
          <div className="space-btn-cnt-sts">
            <CountAndStatusTile
              mainTileData={{ title: "Prepaid", count: prepaidSum || 0, color: COLORS.secondary, mainicon: <GroupsIcon /> }}
              activeTileData={{ title: "Active", count: subscriberData.cusPrepaidActiveCount || 0, color: "#00DB00", subicon: <GroupsIcon /> }}
              inActiveTileData={{ title: "Inactive", count: subscriberData.cusPrepaidInActiveCount || 0, color: "#EF0000", subicon: <GroupsIcon /> }}
            />
          </div>
          <CountAndStatusTile
            mainTileData={{ title: "Postpaid", count: postpaidSum || 0, color: COLORS.secondary, mainicon: <GroupsIcon /> }}
            activeTileData={{ title: "Active", count: subscriberData.cusPostpaidActiveCount || 0, color: "#00DB00", subicon: <GroupsIcon /> }}
            inActiveTileData={{ title: "Inactive", count: subscriberData.cusPostpaidInActiveCount || 0, color: "#EF0000", subicon: <GroupsIcon /> }}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          <div className="heading-title">Distributor Expiration</div>
          <TableView rows={distributorRow} data={distributorPlanExpiryData && distributorPlanExpiryData.slice(0, 2)} color={COLORS.primary} />
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          {renderHeadingWithExtend("Collection Agent", "/distributor/collection-agent-list")}
          <TableView rows={collectionRows} data={agentList && agentList?.slice(0, 5)} color={COLORS.primary} />
        </Grid>
        
      </Grid>
    </div>
  );
}
