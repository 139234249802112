import SubViewCard from "@components/SubViewCard";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import {
  useComponentDidMount,
  useComponentDidUpdate,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { subscriberList, subscriberSearch, areaList, distributorPlanExpiry ,subExport, othersAllService, othersAllInternet} from "@reducers/api";
import { useState } from "react";
import SearchWithButtons from "@components/SearchWithButtons";
import { COLORS } from "@constants";
import Pagination from "@mui/material/Pagination";
import SubscriberFilter from "@components/SubscriberFilter";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/subscriber";
import BreadCrumb from "@components/Breadcrumb";
import moment from "moment";
import { Billingreset } from "@reducers/billing";

export default function SubscriberList(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { subscriberListData, deleteRes, userAccountStatusData, payNowAdded ,areaListData,subExportData, otherAllServiceData, otherAllInternetData } =
    useSelector((state) => state.subscriber);
  const { validityCount } = useSelector((state) => state.dashboard);
  const {  generateBillAdded } = useSelector((state) => state.billing);

  const [page, setPage] = useState(1);
  const [subListExport, setSubListExport] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [validityFilter, setValidityFilter] = useState("");
  const [serviceTypeFilter, setServiceTypeFilter] = useState("");
  const [billingTypeFilter, setBillingTypeFilter] = useState("");
  const [billGenFilter, setBillGenFilter] = useState("");
  const [paymentFilters, setPaymentFilter] = useState("");
  const [areaFilter, setAreaFilter] = useState("");
  const [subareaFilter, setSubareaFilter] = useState("");
  const [dateFrm, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [statusFilter, setStatusFromQuery] = useState("");



  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}`;
    if (value?.type === "search") {
      query += `&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };

  console.log(otherAllServiceData, "sublist")

  useComponentDidMount(() => {
    let query = queryGenerator();
    let user = JSON.parse(localStorage.getItem("user"));
    let distributorId = `${user?.distributor_ref_id}`;
    dispatch(subscriberList(query));
    dispatch(areaList(query));
    dispatch(distributorPlanExpiry(query));
    dispatch(othersAllService(distributorId));
    dispatch(othersAllInternet(distributorId));
  }, []);
  

  const handleRedirect = () => {
    navigate("/distributor/add-subscriber");
  };

  const handleRedirectDelete = () => {
    navigate("/distributor/delete-subscriber");
  };

  const handleExport = (data) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}${searchQuery ? `&value=${searchQuery}` : ""}${validityFilter ? `&filterBySubscriberValidity=1&subscriber_validity=${validityFilter}` : ""}${serviceTypeFilter ? `&filterBySerives=1&service_type=${serviceTypeFilter}` : ""}${billingTypeFilter ? `&filterByBillingType=1&billing_type=${billingTypeFilter}` : ""}${billGenFilter ? `&filterByBillingIntiated=1&billing_initiated=${billGenFilter}` : ""}${paymentFilters ? `&payment_validity=${paymentFilters}` : ""}${areaFilter ? `&filterByArea=1&area=${areaFilter}` : ""}${subareaFilter ? `&filterBySubArea=1&subarea=${subareaFilter}` : ""}${dateFrm ? `&startDate=${dateFrm}` : ""}${dateTo ? `&endDate=${dateTo}` : ""}${statusFilter ==='inactive' ? `&filterByInActive=1` : ""}${statusFilter ==='active' ? `&filterByActive=1` : ""}`;
    dispatch(subExport(query));
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  useComponentDidUpdate(() => {
    if (subExportData) {
      if(subExportData.fileData){
        window.location.href = `data:application/vnd.ms-excel;base64,${subExportData.fileData}`
      }
      if(subExportData?.msg==="no data found"){
        enqueueSnackbar("No data....!", {
          variant: "error",
        });
      }
      dispatch(reset())
    }
  }, [subExportData]);
  

  useComponentDidUpdate(() => {
    let query = queryGenerator();
    if (deleteRes) {
      enqueueSnackbar("Deleted !", {
        variant: "success",
      });
      dispatch(reset());
      dispatch(subscriberList(query));
      dispatch(distributorPlanExpiry(query));
      setPage(1)
    }
  }, [deleteRes]);

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    let query = queryGenerator({ type: "search", state: search });
    dispatch(subscriberList(query));
  };

  const handleFormValues = (value, value1, status, validity, serviceType, billingType, billgen, paymentValidity, area, subArea) => {
    setPage(1)
    let user = JSON.parse(localStorage.getItem("user"));
    let query=`distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;
    if(status?.value==='inactive'){
      query += `&filterByInActive=1`
    }
    if(status?.value==='active'){
      query += `&filterByActive=1`
    }
    if(validity){
      query += `&filterBySubscriberValidity=1&subscriber_validity=${validity?.value}`
     
    }
    if(serviceType){
      query += `&filterBySerives=1&service_type=${serviceType?.value}`
    }
    if(billingType){
      query += `&filterByBillingType=1&billing_type=${billingType?.value}`
    }
    if(billgen){
      query += `&filterByBillingIntiated=1&billing_initiated=${billgen?.value}`
    }   
    if(paymentValidity){
      query += `&payment_validity=${paymentValidity?.value}`
    }  
    if(area){
      query += `&filterByArea=1&area=${area}`
    }   
    if(subArea){
      query += `&filterBySubArea=1&subarea=${subArea}`
    }      
    if(value){
      query += `&startDate=${moment(value).format("YYYY-MM-DDTHH:mm:ss")}`
    }
    if(value1){
      query += `&endDate=${moment(value1).format("YYYY-MM-DDTHH:mm:ss")}`
    }
    dispatch(subscriberList(query));
    setValidityFilter(validity?.value)
    setServiceTypeFilter(serviceType?.value)
    setBillingTypeFilter(billingType?.value)
    setBillGenFilter(billgen?.value)
    setPaymentFilter(paymentValidity?.value)
    setAreaFilter(area)
    setSubareaFilter(subArea)
    setDateFrom(value)
    setDateTo(value1)
    setStatusFromQuery(status?.value)

  };

  const handleReset = () => {
    let query = queryGenerator();
    dispatch(subscriberList(query));
    dispatch(areaList(query));
    dispatch(distributorPlanExpiry(query));
    let user = JSON.parse(localStorage.getItem("user"));
    let distributorId = user?.distributor_ref_id;
    dispatch(othersAllService(distributorId));
    setValidityFilter("")
    setServiceTypeFilter("")
    setBillingTypeFilter("")
    setBillGenFilter("")
    setPaymentFilter("")
    setAreaFilter("")
    setSubareaFilter("")
    setDateFrom("")
    setDateTo("")
    setStatusFromQuery("")
    setPage(1);
  };


  const handleChange = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(subscriberList(`&distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=${value - 1}0${searchQuery ? `&value=${searchQuery}` : ""}${validityFilter ? `&filterBySubscriberValidity=1&subscriber_validity=${validityFilter}` : ""}${serviceTypeFilter ? `&filterBySerives=1&service_type=${serviceTypeFilter}` : ""}${billingTypeFilter ? `&filterByBillingType=1&billing_type=${billingTypeFilter}` : ""}${billGenFilter ? `&filterByBillingIntiated=1&billing_initiated=${billGenFilter}` : ""}${paymentFilters ? `&payment_validity=${paymentFilters}` : ""}${areaFilter ? `&filterByArea=1&area=${areaFilter}` : ""}${subareaFilter ? `&filterBySubArea=1&subarea=${subareaFilter}` : ""}${dateFrm ? `&startDate=${dateFrm}` : ""}${dateTo ? `&endDate=${dateTo}` : ""}${statusFilter ==='inactive' ? `&filterByInActive=1` : ""}${statusFilter ==='active' ? `&filterByActive=1` : ""}`));
  };

  useComponentDidUpdate(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}`;
    if (userAccountStatusData) {
      enqueueSnackbar("Status Updated", {
        variant: "success",
      });
      dispatch(reset());
      if(searchQuery){
        dispatch(subscriberList(`${query}&value=${searchQuery}`));
      }else{
        dispatch(subscriberList(query))
      }
      
    }
    if (payNowAdded) {
      enqueueSnackbar("Payment Success !", {
        variant: "success",
      });
      dispatch(reset());
      dispatch(subscriberList(query));
    }
    if (generateBillAdded) {
      enqueueSnackbar("Bill Generated !", {
        variant: "success",
      });
      dispatch(Billingreset());
      dispatch(subscriberList(query));
    }
  }, [userAccountStatusData, payNowAdded, generateBillAdded]);

  const billingType = [
    { label: "Prepaid", value: "pre paid" },
    { label: "Postpaid", value: "post paid" },
  ];

  const ServiceType = [
    { label: "Cable", value: "cable" },
    { label: "Internet", value: "internet" },
  ];

  const Status = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];

  const BillGen = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const Validity = [
    { label: "1 Month", value: "1 months" },
    { label: "3 Month", value: "3 months" },
    { label: "6 Month", value: "6 months" },
    { label: "1 Year", value: "1 year" },
  ];

  const paymentValid = [
    { label: "1 Month", value: 1 },
    { label: "2 Month", value: 2 },
    { label: "3 Month", value: 3 },
    { label: "4 Month", value: 4 },
    { label: "5 Month", value: 5 },
    { label: "6 Month", value: 6 },
    { label: "7 Month", value: 7 },
    { label: "8 Month", value: 8 },
    { label: "9 Month", value: 9 },
    { label: "10 Month", value: 10 },
    { label: "11 Month", value: 11 },
    { label: "12 Month", value: 12 },
  ];

  return (
    <div className="subscriberList-container">
      <Grid container spacing={2}>
        <Grid item xs={8}>
 
        </Grid>
        <Grid item xs={12} md={4}className="flex-end">
          <div className="employeelist-title">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              activeHeading="Subscriber List"
              handleActiveClick={() => handleBreadCrumbRedirect("subscriber-list")}
            />
          </div>

        </Grid>
      </Grid>

      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={4} md={3}>
          <div className="planHeading">Profile</div>
        </Grid>
        <Grid item xs={0} sm={4} md={6}></Grid>
        <Grid item xs={4} sm={4} md={3} className="flex-end">
          <div className="">
            <SubscriberFilter
              labelPrimary={"Status"}
              labelSecondary={"Subscriber validity"}
              PrimaryOption={Status}
              SecondaryOption={Validity}
              billingTypeOption={billingType}
              serviceTypeOption={ServiceType}
              billgenOption={BillGen}
              billingType={"Billing Type"}
              serviceType={"Service Type"}
              billgen={"Bill Generated"}
              paymentValidity={"Payment Validity"}
              area={"Area"}
              areaData={areaListData}
              subArea={"Sub Area"}
              payValidOption={paymentValid}
              handleFormValues={(value, value1, status, validity, serviceType, billingType, billgen, paymentValidity, area, subArea) => handleFormValues(value, value1, status, validity, serviceType, billingType, billgen, paymentValidity, area, subArea)}
              handleResetClick = {handleReset}
           />
          </div>
        </Grid>
      </Grid>

      <div className="search-btns-padding">
        {
          validityCount[0]?.custTotalCount  >= validityCount[0]?.subscriber_limit_count ? (
            <SearchWithButtons
            primaryButton={{
             title: "Delete Subscriber",
             type: "contained",
             color: "white",
             bgColor: COLORS.primary,
           }}
           extraButton={{
            title: "Export",
            type: "outlined",
            color: COLORS.primary,
            //filename:"Employee",
            data: subListExport,
          }}
           
     
           handleChange={handleSearch}
           handlePrimaryClick={handleRedirectDelete}
           handleExtraClick={handleExport}
 
         />
          )
          :
          (
            <SearchWithButtons
            primaryButton={{
             title: "Delete Subscriber",
             type: "contained",
             color: "white",
             bgColor: COLORS.primary,
           }}
           
           secondaryButton={{
             title: "Add Subscriber",
             type: "outlined",
             color: COLORS.primary,
           }}
           extraButton={{
            title: "Export",
            type: "outlined",
            color: COLORS.primary,
            //filename:"Employee",
            data: subListExport,
          }}
           handleSecondaryClick={handleRedirect}
           handleChange={handleSearch}
           handlePrimaryClick={handleRedirectDelete}
           handleExtraClick={handleExport}
 
         />
          )
        }
       
      </div>
      <div style={{ fontWeight: 'bold', fontSize: '15px',paddingBottom: '10px'}}>Showing entries { (page - 1) * 10 + 1 } - {Math.ceil(subscriberListData?.totalCount / 10)===page?subscriberListData?.totalCount:page+"0"} of {subscriberListData?.totalCount}</div>  
      <div className="staticoverflowheight">
      {subscriberListData &&
        subscriberListData?.customers?.map((subdata) => (
          <div style={{ paddingBottom: "20px" }}>
            <SubViewCard data={subdata} otherAllServiceData={otherAllServiceData} otherAllInternetData={otherAllInternetData}/>
          </div>
        ))}
</div>
      <div
        style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}
      >
        <Pagination
          count={Math.ceil(subscriberListData?.totalCount / 10) || 0}
          defaultPage={1}
          page={page}
          onChange={(event, value) => {
            setPage(value);
            handleChange(value);
          }}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );
}
