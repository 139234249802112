import { configureStore } from "@reduxjs/toolkit";
import authReducer from "@reducers/auth";
import settingsReducer from "@reducers/settings";
import dashboardReducer from "@reducers/dashboard";
import distributorReducer from "@reducers/distributor";
import planReducer from "@reducers/plan";
import employeeReducer from "@reducers/employee";
import enquiryReducer from "@reducers/enquiry";
import ticketReducer from "@reducers/ticketing";
import billingReducer from "@reducers/billing";
import expensesReducer from "@reducers/expenses";
import subscriberReducer from "@reducers/subscriber";
import customerReducer from "@reducers/customer";
import inventoryReducer from "@reducers/inventry";
import collectionReducer from "@reducers/collectionAgent";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    settings: settingsReducer,
    dashboard: dashboardReducer,
    distributor: distributorReducer,
    plan: planReducer,
    employee: employeeReducer,
    enquiry: enquiryReducer,
    ticketing: ticketReducer,
    billing: billingReducer,
    expenses: expensesReducer,
    subscriber: subscriberReducer,
    customer: customerReducer,
    inventory: inventoryReducer,
    collection : collectionReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
