import "./styles.scss";
import SettingsIcon from "@mui/icons-material/Settings";
import ProgressBar from "@components/Timeline-Bar";
import SmsTemplate from "./pages/settingsSms";
import SettingsPayment from "./pages/settingsPayment";
import SettingsTax from "./pages/settingsTax";
import { useComponentDidMount, useComponentDidUpdate } from "@utils/useEffectHooks";
import BulkUpload from "./pages/bulkUpload";
import AgentWorkTime from "./pages/agentWorkTime";
import SettingsOtherScreen from "./pages/settingsOtherScreen";
import { emailTemplates, smsTemplates } from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "@components/Breadcrumb";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { reset } from "@reducers/subscriber";
import { useSnackbar } from "notistack";

export default function SettingsTab() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    bulkFileUploadAdded,
    bulkFileAddOnUploadAdded,
    bulkUploadPayment
  } = useSelector((state) => state.subscriber);
  const { smsTempData } = useSelector((state) => state.settings);
  const data = [

    {
      title: "Templates",
      comp: <SmsTemplate data={smsTempData} />,
    },
    {
      title: "Payment",
      comp: <SettingsPayment />,
    },
    {
      title: "Bulk Upload",
      comp: <BulkUpload />,
    },
    {
      title: "AgentWork Time",
      comp: <AgentWorkTime />,
    },
    {
      title: "Tax",
      comp: <SettingsTax />,
    },
    {
      title: "Others",
      comp: <SettingsOtherScreen />,
    },

  ];

  useComponentDidUpdate(() => {
    if (bulkFileUploadAdded.msg === "File uploaded successfully") {
      enqueueSnackbar("File Uploaded Successfully !", {
        variant: "success",
      });

      dispatch(reset());
    }
    if (bulkFileUploadAdded.msg === "Subscriber limit count exceed") {
      enqueueSnackbar("Subscriber limit count exceed !", {
        variant: "error",
      });

      dispatch(reset());
    }
    if (bulkFileAddOnUploadAdded) {
      enqueueSnackbar("File Uploaded Successfully !", {
        variant: "success",
      });
      dispatch(reset());
    }
    if (bulkUploadPayment) {
      enqueueSnackbar("File Uploaded Successfully !", {
        variant: "success",
      });
      dispatch(reset());
    }
  }, [bulkFileUploadAdded, bulkFileAddOnUploadAdded, bulkUploadPayment]);

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `?distributor_ref_id=${user?.distributor_ref_id}`;
    dispatch(emailTemplates(query));
    dispatch(smsTemplates(query));
  }, []);

  const handleRedirect = (path) => {
    navigate(`/distributor${path}`);
  };

  return (
    <div className="settings-tab-cnt">


      <Grid container spacing={2}>
        <Grid item xs={8}>
          <div className="heading-title flex-center">
            <SettingsIcon />
            SETTINGS
          </div>
        </Grid>
        <Grid item xs={12} md={4}className="flex-end">
          <div className="employeelist-title">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleRedirect("dashboard")}
              activeHeading="Settings"

            />
          </div>

        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <ProgressBar data={data} />
      </Grid>
    </div>
  );
}
