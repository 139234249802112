const tableRows = [
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },
  {
    title: "Distributor Name",
    key: "distributorName",
    align: "left",
  },
  {
    title: "Account ID",
    key: "accountId",
    align: "left",
  },
  {
    title: "Validity",
    key: "validity",
    align: "left",
  },
  {
    title: "Contact Number",
    key: "contactNumber",
    align: "left",
  },
  {
    title: "E-mail",
    key: "email",
    align: "left",
  },
  {
    title: "Address",
    key: "address",
    align: "left",
  },
  {
    title: "City",
    key: "city",
    align: "left",
  },
  {
    title: "State",
    key: "state",
    align: "left",
  },
  {
    title: "Postal code",
    key: "postalCode",
    align: "left",
  },
  {
    title: "Created Date",
    key: "created",
    align: "left",
  },
  {
    title: "Status",
    key: "status",
    align: "left",
  },
  {
    title: "Action",
    key: "action",
    align: "left",
  },
];

export { tableRows };
