const tableRows = [
  {
    key: "checkbox",
    align: "left",
  },
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },
  {
    title: "Name",
    key: "name",
    align: "left",
  },
  {
    title: "Acc No ",
    key: "accNo",
    align: "left",
  },
  {
    title: "Email ID",
    key: "email",
    align: "left",
  },
  {
    title: "Mobile No",
    key: "mobileno",
    align: "left",
  },  
  {
    title: "Billing Type",
    key: "billType",
    align: "left",
  }, 
  {
    title: "Service Type",
    key: "serviceType",
    align: "left",
  },   
  {
    title: "Area",
    key: "area",
    align: "left",    
  },
  {
    title: "State",
    key: "state",
    align: "left",    
  },
  {
    title: "Action",
    key: "action",
    align: "left",
  },
];

export { tableRows };
