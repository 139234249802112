import "./style.scss";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import Arrow from "./arrow";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";

export default function SmsTemplate(props) {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);

  const handleRedirect = () => {
    navigate(`/distributor/bulk-sms`);
  };

  function sortByNum(a, b, order) {
    const diff = a.sNo - b.sNo;

    if (order === "up") {
      return diff;
    }

    return -1 * diff;
  }

  function sortByText(a, b, order, title) {
    const diff = a[title].toLowerCase().localeCompare(b[title].toLowerCase());

    if (order === "up") {
      return diff;
    }

    return -1 * diff;
  }

  const handleArrowClick = (value) => {
    const copy = [...data];
    if (value.title === "Title" || value.title === "Type") {
      copy.sort((a, b) => sortByText(a, b, value.position, value.title.toLowerCase()));
    } else if (value.title === "S.no") {
      copy.sort((a, b) => sortByNum(a, b, value.position));
    }
    setData(copy);
  };

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="email-temp-cnt">
      <Grid item xs={12} className="flex-end">
        <Stack direction="row" className="flex-end " spacing={2}>
          <Button size="small" variant="outlined" className="btn-outlined padBottom" onClick={handleRedirect} style={{ textTransform: "none", color: "#00249b" }}>
             Bulk Mail / SMS
          </Button>

        </Stack>
      </Grid>

      <Grid item xs={4} sm={4} md={12}>
        <div className="email-temp-header">Templates</div>
        <div className="email-temp-bdy">
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={4} style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Grid item xs={2} sm={4} md={2}>
              <Arrow handleArrowClick={handleArrowClick} data="S.no" justify="center" />
            </Grid>
            <Grid item xs={2} sm={4} md={2}>
              <Arrow handleArrowClick={handleArrowClick} data="Type" justify="center" />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Arrow handleArrowClick={handleArrowClick} data="Title" justify="center" />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Arrow data="Description" single={true} justify="center" />
            </Grid>
          </Grid>
          {data &&
            data.map((data, index) => (
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={4} style={{ paddingTop: 10, paddingBottom: 10 }}>
                <Grid item xs={2} sm={4} md={2}>
                  <div className={"outline-center"}>{1 + index}</div>
                </Grid>
                <Grid item xs={2} sm={4} md={2}>
                  <div className={"outline-center "}>{data?.type}</div>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <div className={"outline"}>{data?.title}</div>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <div className={"outline"}>{data?.description}</div>
                </Grid>
              </Grid>
            ))}
        </div>
      </Grid>
    </Grid>
  );
}
