import "./styles.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import SearchWithButtons from "@components/SearchWithButtons";
import { useNavigate } from "react-router-dom";
import { customerPlanList, subscriberPlanListNew, SubscriberplanListsExport } from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import { useComponentDidMount, useComponentDidUpdate } from "@utils/useEffectHooks";
import { useState } from "react";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import BreadCrumb from "@components/Breadcrumb";
import TicketStatus from "@components/TicketStatus";
import Grid from "@mui/material/Grid";
import { reset } from "@reducers/plan";
import { useSnackbar } from "notistack";
import PlanListFilter from "@components/PlanListFilter";

const ticketStatusData = [
  {
    id: 1,
    title: "Total plan",
    count: 30,
    icon: "",
  },
  {
    id: 2,
    title: "Total plan amount",
    count: 28,
    icon: "",
  },
];

const serviceType = [
  { label: "Cable", value: "cable" },
  { label: "Internet", value: "internet" },
];

const billingTypes = [
  { label: "Pre Paid", value: "pre paid" },
  { label: "Post Paid", value: "post paid" },

];

const category = [
  { label: "Package", value: "package" },
  { label: "Ala Cart", value: "ala cart" },
  { label: "Broadcaster", value: "broadcaster" },

];

const activeStatus = [
  { label: "Active", value: "true" },
  { label: "In Active", value: "false" },

];


export default function SubscriberPlan() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { customerPlanListData, SubscriberplanListsExportData, alaCartListData } = useSelector((state) => state.plan);
  const { subscriberPlanListNewData } = useSelector((state) => state.subscriber);

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [customerPlan, setCustomerPlanList] = useState([]);
  const [filterOne, setFirstQuery] = useState("");
  const [filtertwo, setSecondQuery] = useState("");
  const [filterthree, setThirdQuery] = useState("");
  const [broadQuery, setBroadQuery] = useState("");
  const [planQuery, setPlanQuery] = useState("");


  useComponentDidUpdate(() => {
    if (SubscriberplanListsExportData) {
      if (SubscriberplanListsExportData.fileData) {
        window.location.href = `data:application/vnd.ms-excel;base64,${SubscriberplanListsExportData.fileData}`
      }
      if (SubscriberplanListsExportData?.msg === "no data found") {
        enqueueSnackbar("No data....!", {
          variant: "error",
        });
      }
      dispatch(reset())
    }
  }, [SubscriberplanListsExportData]);

  const handleRedirect = () => {
    navigate("/distributor/subscriber-plan-list");
  };

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(customerPlanList(`distributor_ref_id=${user?.distributor_ref_id}`));
    dispatch(subscriberPlanListNew(`distributor_ref_id=${user?.distributor_ref_id}`));
  }, []);

  const status = (color, text) => {
    let colorVal = "#73ca72";
    if (text === "false") {
      colorVal = "#d35352";
    } else if (text === "true") {
      colorVal = "#73ca72";
    }
    return (
      <div className="status-btn-table" style={{ backgroundColor: colorVal }}>
        {text}
      </div>
    );
  };

  console.log(alaCartListData, "filter")

  useComponentDidUpdate(() => {
    let list = [];
    if (customerPlanListData?.data?.length > 0) {
      customerPlanListData?.data &&
        customerPlanListData?.data?.map((data, index) => {
          list.push({
            sNo: (page - 1) * 10 + index + 1,
            accountId: data?.customerData?.account_id,
            customerName: data?.customerData?.name,
            email: data?.customerData?.email_id,
            serviceType: data?.service_type,
            billingType: data?.billing_type,
            packageCategory: data?.package_category,
            planName: data?.plan_name,
            planAmount: data?.plan_amount,
            validity: data?.plan_validity,
            status: status("#73ca72", data?.is_active === true ? "Active" : "Inactive"),
            created: moment(data?.created_at).format(" MMMM Do YYYY, h:mm a"),
          });
        });
    }

    setCustomerPlanList(list);
  }, [customerPlanListData]);



  const handleChange = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(customerPlanList(`distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=${value - 1}0${searchQuery ? `&value=${searchQuery}` : ""}${filterOne ? `&service_type=${filterOne}` : ""}${filtertwo ? `&billing_type=${filtertwo}` : ""}${filterthree ? `&package_category=${filterthree}` : ""}${broadQuery ? `&is_active=${broadQuery}` : ""}${planQuery ? `&plan_name=${planQuery}` : ""}`));
  };

  const handleSearch = (search) => {
    let user = JSON.parse(localStorage.getItem("user"));
    setPage(1);
    setSearchQuery(search);
    dispatch(customerPlanList(`distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&value=${search}`));
    dispatch(subscriberPlanListNew(`distributor_ref_id=${user?.distributor_ref_id}&value=${search}`));
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  const handleExtraExportClick = (path) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(SubscriberplanListsExport(`distributor_ref_id=${user?.distributor_ref_id}`));
  };

  const handleClickSearcch = (priorityval, statusval, categoryval, broadcastCat, activeVal, planName) => {
    setPage(1)
    let user = JSON.parse(localStorage.getItem("user"));
    let distributorId = user?.distributor_ref_id;
    let query = "";
    console.log(planName, "planname")

    if (priorityval) {
      query += `&service_type=${priorityval?.value}`
    }
    if (statusval) {
      query += `&billing_type=${statusval?.value}`
    }
    if (categoryval) {
      query += `&package_category=${categoryval?.value}`
    }
    if (broadcastCat) {
      query += `&broadcaster=${broadcastCat}`
    }
    if (activeVal) {
      query += `&is_active=${activeVal?.value}`
    }
    if(planName){
      query += `&plan_name=${planName?.plan_name}`
    }

    dispatch(customerPlanList(`distributor_ref_id=${distributorId}${query}`));
    dispatch(subscriberPlanListNew(`distributor_ref_id=${user?.distributor_ref_id}${query}`));
    setFirstQuery(priorityval?.value);
    setSecondQuery(statusval?.value);
    setThirdQuery(categoryval?.value);
    setBroadQuery(activeVal?.value)
    setPlanQuery(planName?.plan_name)

  };

  const handleReset = () => {
    setFirstQuery("");
    setSecondQuery("");
    setThirdQuery("");
    setBroadQuery("")
    setPlanQuery("")
    setPage(1);
    let user = JSON.parse(localStorage.getItem("user"));
    let distributorId = user?.distributor_ref_id;
    dispatch(customerPlanList(`distributor_ref_id=${distributorId}`));
    dispatch(subscriberPlanListNew(`distributor_ref_id=${user?.distributor_ref_id}`));
  };


  return (
    <div className="subscriberList-container ticketList-container">
      <Grid container spacing={2}>
        <Grid item xs={8} md={8}>

        </Grid>
        <Grid item xs={12}  md={4}className="flex-end">
          <div className="employeelist-title">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              activeHeading="Subscriber Plan"
              handleActiveClick={() => handleBreadCrumbRedirect("subscriber-plan")}
            />
          </div>

        </Grid>
      </Grid>
      <div className="ticket-list-table-cntr">
        <div className="search-btns-padding">
          <div className="header-title">Subscriber Plan</div>
          <div>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={2.4}
                style={{ paddingLeft: 0, paddingBottom: 0 }}
              >
                <TicketStatus
                  className=" ticketCardGreen"
                  title={ticketStatusData[0].title}
                  count={subscriberPlanListNewData?.totalCustPlans || "0"}
                  icon={ticketStatusData[0].icon}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={2.4}
                style={{ paddingLeft: 0, paddingBottom: 0 }}
              >
                <TicketStatus
                  className=" ticketCardRed"
                  title={ticketStatusData[1].title}
                  count={subscriberPlanListNewData?.totalPlanAmount || "0"}
                  icon={ticketStatusData[1].icon}
                />
              </Grid>
            </Grid>
          </div>
          <SearchWithButtons
            primaryButton={{
              title: "Plan List",
              type: "contained",
              bgColor: COLORS.primary,
            }}
            secondryExtraButtonExportData={{
              title: "Export",
              type: "contained",
              color: COLORS.primary,
              bgColor: COLORS.primary,
            }}
            handleExtraExportClick={() => handleExtraExportClick("export")}
            handlePrimaryClick={handleRedirect}
            handleChange={handleSearch}
          // handleSecondaryClick={}
          />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <div style={{ fontWeight: 'bold', fontSize: '15px', paddingBottom: '10px' }}>Showing entries {(page - 1) * 10 + 1} - {Math.ceil(customerPlanListData?.totalCount / 10) === page ? customerPlanListData?.totalCount : page + "0"} of {customerPlanListData?.totalCount}</div>
          </Grid>
          <Grid item xs={4} className="flex-end">
            <div className="employeelist-title">
              <PlanListFilter
                labelPrimary={"Service Type"}
                PrimaryOption={serviceType}
                SecondaryOption={billingTypes}
                labelSecondary={"Billing Type"}
                labelActive={"Status"}
                activeOption={activeStatus}
                planName={"Plan Name"}
                planOption={alaCartListData}
                // broadcast={"Broadcast Category"}
                // broadCastOption={activeStatus}
                labelThird={"Package Category"}
                thirdOption={category}
                handleResetClick={handleReset}
                handleFormValues={(data1, data2, data3, data4, data5, data6) => handleClickSearcch(data1, data2, data3, data4, data5, data6)} />
            </div>

          </Grid>
        </Grid>

        <TableView rows={tableRows} data={customerPlan} color={COLORS.primary} />
        <div style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}>
          <Pagination
            count={Math.ceil(customerPlanListData?.totalCount / 10)}
            defaultPage={1}
            page={page}
            onChange={(event, value) => {
              setPage(value);
              handleChange(value);
            }}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
}
