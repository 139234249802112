import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./styles.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPwdPopup } from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import { useComponentDidUpdate, paramsQuery } from "@utils/useEffectHooks";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/subscriber";
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const validation = Yup.object().shape({
  newPassword: Yup.string()
    .required("Required")
    .min(8, "Password must contain atleast 8 characters")
    .matches(/[a-z]/, "Atleast one lowercase character")
    .matches(/[A-Z]/, "Atleast one uppercase character")
    .matches(/[0-9]/, "Atleast one number"),
});

export default function ResetPasswordPopup(props) {
  const {
    values,
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });
  const onSubmit = (data) => {
    props?.submitData(data, props);
    setValue("newPassword", "");
    reset()
    setNewValues("")
  }

  const { resetPwdPopupData } = useSelector((state) => state.employee);

  const handleClose = () => {
    setValue("newPassword", "");
    props?.handleClose();
    reset()
    setNewValues("")
  };

  const handleCloses = () => {
    setValue("newPassword", "");
    props?.handleClose();
    reset()
    setNewValues("")
  };


setValue("email", props?.empData?.email_id);

  useComponentDidUpdate(() => {
    if (resetPwdPopupData) {
      handleClose();
    }
  }, [resetPwdPopupData]);

  const [newPassword, setNewValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const handleNewChange = (prop) => (event) => {
    setNewValues({ ...newPassword, [prop]: event.target.value });
  };

  const handleClickNewShowPassword = () => {
    setNewValues({
      ...newPassword,
      showPassword: !newPassword.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  console.log(newPassword.password, "newpass")

  return (
    <Modal
      open={props?.show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="makePayment-cntr">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            className="padding-btw-tiles"
          >
            <Grid item xs={4} sm={8} md={12}>
              <div className="make-payment-form-title ">E-mail </div>
              <TextField
                style={{ width: "100%" }}
                {...register("email")}
                id="outlined-basic"
                disabled
                variant="outlined"
                size="small"
              />
            </Grid>
          </Grid>

          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            className="padding-btw-tiles"
          >
            <div className="make-payment-form-title ">Password</div>
          
            <TextField
              id="outlined-adornment-password"
              {...register("newPassword")}
              size="small"
              style={{ width: "100%" }}
              type={newPassword.showPassword ? 'text' : 'password'}
              // value={newPassword.password}
              onChange={handleNewChange('password')}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickNewShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {newPassword.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>,
              }}

              placeholder="Enter Password"
            />
            <div className="field-error">{errors?.newPassword?.message}</div>
          </Grid>

          <Stack spacing={2} direction="row" className=" ">
            <Button type="submit" variant="contained" className="bg-blue">
              Submit
            </Button>
            <Button
              size="small"
              variant="outlined"
              style={{
                textTransform: "capitalize",
                borderColor: "#00249b",
                color: "#00249b",
              }}
              onClick={handleCloses}
            >
              Cancel
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
}
