import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./styles.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { inventoryAdd, inventryList, inventoryUpdate } from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useComponentDidUpdate, paramsQuery, useComponentWillUnmount } from "@utils/useEffectHooks";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const options = [{ label: "Cable", value : "cable" }, { label: "Internet" , value : "internet"}];

const providerOptions = [
  { label: "MSO", value: "mso" },
  { label: "ISP", value: "isp" },
];

const options2 = [
  { label: "STB", value: "stb" },
  { label: "Hardware", value: "hardware" },
  { label: "Onu", value: "onu" },
  { label: "Ont", value: "ont" },
  { label: "Wifi Router", value: "wifi router" },
  { label: "Patch Card Blue", value: "patch card blue" },
  { label: "Patch Card Green", value: "patch card green" },
  { label: "Shifting Charges", value: "shifting charges" },
  { label: "Reconnection Charges", value: "reconnection charges" },
  { label: "Replacement Charges", value: "replacement charges" },
  { label: "STB Box Service Charges", value: "stb box service charges" },
  { label: "STB Box Replacement Charges", value: "stb box replacement charges" },
];

const paymentOption = [
  { label: "Free", value: "free" },
  { label: "Paid", value: "paid" },
];

const options1 = [{ label: "HD", value: "hd" }, { label: "SD", value: "sd"}];

const validation = Yup.object().shape({
  // channeltype: Yup.object().required("Required").nullable(),
  items: Yup.string().required("Required").nullable(),
 
  servicetype: Yup.object().required("Required").nullable(),
  hardware_type: Yup.object().required("Required").nullable(),
  price: Yup.string().required("Required").nullable(),

});

export default function InventeryAddPopup(props) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [inventoryType, setinventoryType] = useState(null);
  const [amount, setAmount] = useState(0);
  const { inventoryAddData, inventoryUpdateData, inventoryAddMultipleData } = useSelector(
    (state) => state.inventory
  );

  useComponentWillUnmount(() => {
    reset();
  });

  const handleClose = () => {
    props?.handleClose();
    reset();
  };

  const updateSubmit = (data) => {
    let user = JSON.parse(localStorage.getItem("user"));

    let payload = {
      items: data?.items,
      price: data?.price,
      quantity: data?.quantity,
      distributor_ref_id: user?.distributor_ref_id,
      service_type: data?.servicetype?.value,
      inventory_type: data?.channeltype?.label,
      hardware_type: data?.hardware_type?.value,
      stb_no: data?.stbno,
      companyName : data?.companyName,
      itemProviderName: data?.provider?.value,
      stb_payment_category: data?.paymentCategory?.value


    };
    if (data?.inventoryId) {
      payload["_id"] = data?.inventoryId;
      dispatch(inventoryUpdate(payload));
    } else {
      dispatch(inventoryAdd(payload));
      reset();
    }
  };

  const onSubmit = (data) => {
    updateSubmit(data);
  };

  const handleCloses = () => {
    props?.handleClose();
  };

  useComponentDidUpdate(() => {
    if (inventoryAddData?.status === true) {
      enqueueSnackbar("Inventory added", {
        variant: "success",
      });
      props?.handleClose();
      let user = JSON.parse(localStorage.getItem("user"));
      let query = `distributor_ref_id=${user?.distributor_ref_id}`;
      dispatch(inventryList(query));
    }

    if (inventoryAddData?.msg === "stb no already exist") {
      enqueueSnackbar("STB already exist", {
        variant: "error",
      });
    }

    if(inventoryAddMultipleData.message === "Data Added successfully"){
      let user = JSON.parse(localStorage.getItem("user"));
      let query = `distributor_ref_id=${user?.distributor_ref_id}`;
      dispatch(inventryList(query));
    }

  }, [inventoryAddData, inventoryAddMultipleData]);

  useComponentDidUpdate(() => {
    if (inventoryUpdateData?.status === true) {
      enqueueSnackbar("Inventory updated", {
        variant: "success",
      });
      props?.handleClose();
      reset();
      let user = JSON.parse(localStorage.getItem("user"));
      let query = `distributor_ref_id=${user?.distributor_ref_id}`;
      dispatch(inventryList(query));
    }

    if (inventoryUpdateData?.msg === "stb no already exist") {
      enqueueSnackbar("STB already exist", {
        variant: "error",
      });
    }
  }, [inventoryUpdateData]);

  useComponentDidUpdate(() => {
    if (props?.invData) {
      filterObject("hardware_type", options2, props?.invData?.hardware_type);
      setValue("stbno", props?.invData?.stb_no);
      setValue("inventoryId", props?.invData?._id);
      setValue("items", props?.invData?.items);
      setValue("quantity", props?.invData?.quantity);
      filterObject("servicetype", options, props?.invData?.service_type);
      filterLabel("channeltype", options1, props?.invData?.inventory_type);
      setValue("price", props?.invData?.price);
      filterObject("provider", providerOptions, props?.invData?.itemProviderName);
      setValue("companyName", props?.invData?.companyName);
      filterObject("paymentCategory", paymentOption, props?.invData?.stb_payment_category )
    }
  }, [props?.invData]);

  const filterObject = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options.value.toString() === value.toString()
      );
    setValue(title, filtered[0]);
  }

  const filterLabel = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options.label.toString() === value.toString()
      );
    setValue(title, filtered[0]);
  }

  const handleSetValue = (item) => {
    setValue("items", item?.label);


  };


  return (
    <Modal
      open={props?.show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="makePayment-cntr invent-add">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            className="padding-btw-tiles ticketDetails"
          >
            <div className="make-payment-form-title ">Provider</div>

            <Controller
              control={control}
              name="provider"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  id="combo-box-demo"
                  style={{ width: "100%" }}
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  disabled={props?.invData?.hardware_type === 'stb' ? true : false}
                  value={value || ""}
                  options={providerOptions}
                  isOptionEqualToValue={(option) =>
                    (option?.label === option?.label) == value?.label || ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      name="provider"
                      placeholder="Select option "
                    />
                  )}
                />
              )}
            />
            <div className="field-error">{errors?.provider?.message}</div>
          </Grid>

          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            className="padding-btw-tiles ticketDetails"
          >
            <div className="make-payment-form-title ">Type</div>

            <Controller
              control={control}
              name="hardware_type"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  id="combo-box-demo"
                  style={{ width: "100%" }}
                  onChange={(event, item) => {
                    onChange(item);
                    setinventoryType(item?.label);
                    handleSetValue(item);
                    
                  }}
                  disabled={props?.invData?._id ? true : false}
                  value={value || ""}
                  options={options2}
                  isOptionEqualToValue={(option) =>
                    (option?.label === option?.label) == value?.label || ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      name="hardware_type"
                      placeholder="Select option "
                    />
                  )}
                />
              )}
            />
            <div className="field-error">{errors?.hardware_type?.message}</div>
          </Grid>

          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            className="padding-btw-tiles"
          >
            <Grid item xs={4} sm={8} md={12}>
              <div className="make-payment-form-title ">Item </div>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                name="items"
                {...register("items")}
                variant="outlined"
                size="small"
                placeholder="Enter item"
              />
            </Grid>
            <div className="field-error">{errors?.items?.message}</div>
          </Grid>
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            className="padding-btw-tiles"
          >
            <Grid item xs={4} sm={8} md={12}>
              <div className="make-payment-form-title ">Company Name </div>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                name="companyName"
                {...register("companyName")}
                variant="outlined"
                size="small"
                placeholder="Enter item"
              />
            </Grid>
            <div className="field-error">{errors?.companyName?.message}</div>


          </Grid>
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            className="padding-btw-tiles ticketDetails"
          >
            <div className="make-payment-form-title ">Service type</div>

            <Controller
              control={control}
              name="servicetype"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  id="combo-box-demo"
                  style={{ width: "100%" }}
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  disabled={props?.invData?.hardware_type === 'stb' ? true : false}
                  value={value || ""}
                  options={options}
                  isOptionEqualToValue={(option) =>
                    (option?.label === option?.label) == value?.label ||
                    ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      name="channeltype"
                      placeholder="Select option "
                    />
                  )}
                />
              )}
            />
            <div className="field-error">
              {errors?.servicetype?.message}
            </div>
          </Grid>
          { watch("hardware_type") && watch("hardware_type").value === "stb"  && (
            <Grid>
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                className="padding-btw-tiles"
              >
                <Grid item xs={4} sm={8} md={12}>
                  <div className="make-payment-form-title ">Stb No </div>
                  <TextField
                    {...register("stbno")}
                    style={{ width: "100%" }}
                    id="outlined-basic"
                    name="stbno"
                    variant="outlined"
                    size="small"
                    placeholder="Enter stb no"
                  />
                </Grid>
                <div className="field-error">{errors?.stbno?.message}</div>
              </Grid>



              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                className="padding-btw-tiles ticketDetails"
              >
                <div className="make-payment-form-title ">Box type</div>

                <Controller
                  control={control}
                  name="channeltype"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      id="combo-box-demo"
                      style={{ width: "100%" }}
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value || ""}
                      options={options1}
                      isOptionEqualToValue={(option) =>
                        (option?.label === option?.label) == value?.label ||
                        ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          name="channeltype"
                          placeholder="Select option "
                        />
                      )}
                    />
                  )}
                />
                <div className="field-error">
                  {errors?.channeltype?.message}
                </div>
              </Grid>

              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                className="padding-btw-tiles ticketDetails"
              >
                <div className="make-payment-form-title ">Payment Category</div>

                <Controller
                  control={control}
                  name="paymentCategory"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      id="combo-box-demo"
                      style={{ width: "100%" }}
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value || ""}
                      options={paymentOption}
                      isOptionEqualToValue={(option) =>
                        (option?.label === option?.label) == value?.label ||
                        ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          name="channeltype"
                          placeholder="Select option "
                        />
                      )}
                    />
                  )}
                />
                <div className="field-error">
                  {errors?.paymentCategory?.message}
                </div>
              </Grid>

              
            </Grid>
          )}
          {watch("hardware_type") &&
            watch("hardware_type").value === "hardware" && (
              <Grid>
                <Grid
                  container
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  className="padding-btw-tiles"
                >
                  <Grid item xs={4} sm={8} md={12}>
                    <div className="make-payment-form-title ">Quantity </div>
                    <TextField
                      style={{ width: "100%" }}
                      id="outlined-basic"
                      name="quantity"
                      {...register("quantity")}
                      variant="outlined"
                      size="small"
                      placeholder="Enter quantity"
                    />
                  </Grid>
                  <div className="field-error">{errors?.quantity?.message}</div>
                </Grid>

                <Grid
                  container
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  className="padding-btw-tiles ticketDetails"
                >
                  <div className="make-payment-form-title ">Box type</div>

                  <Controller
                    control={control}
                    name="channeltype"
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        id="combo-box-demo"
                        style={{ width: "100%" }}
                        onChange={(event, item) => {
                          onChange(item);
                        }}
                        value={value || ""}
                        options={options1}
                        isOptionEqualToValue={(option) =>
                          (option?.label === option?.label) == value?.label ||
                          ""
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            name="channeltype"
                            placeholder="Select option "
                          />
                        )}
                      />
                    )}
                  />
                  <div className="field-error">
                    {errors?.channeltype?.message}
                  </div>
                </Grid>
              
              </Grid>
            )}

       





          {
            inventoryType == "Onu" || inventoryType == "Ont" || inventoryType == "Wifi Router" || inventoryType == "Patch Card Blue" ||
              inventoryType == "Patch Card Green" ? (
              <Grid>
                <Grid
                  container
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  className="padding-btw-tiles ticketDetails"
                >
                  <div className="make-payment-form-title ">Box type</div>

                  <Controller
                    control={control}
                    name="channeltype"
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        id="combo-box-demo"
                        style={{ width: "100%" }}
                        onChange={(event, item) => {
                          onChange(item);
                        }}
                        value={value || ""}
                        options={options1}
                        isOptionEqualToValue={(option) =>
                          (option?.label === option?.label) == value?.label ||
                          ""
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            name="channeltype"
                            placeholder="Select option "
                          />
                        )}
                      />
                    )}
                  />
                  <div className="field-error">
                    {errors?.channeltype?.message}
                  </div>
                </Grid>

                <Grid
                  container
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  className="padding-btw-tiles"
                >
                  <Grid item xs={4} sm={8} md={12}>
                    <div className="make-payment-form-title ">Quantity </div>
                    <TextField
                      style={{ width: "100%" }}
                      id="outlined-basic"
                      name="quantity"
                      {...register("quantity")}
                      variant="outlined"
                      size="small"
                      placeholder="Enter quantity"
                    />
                  </Grid>
                  <div className="field-error">{errors?.quantity?.message}</div>
                </Grid>



              </Grid>
            ) : (
              ""
            )
          }

          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            className="padding-btw-tiles"
          >
            <div className="make-payment-form-title ">Price</div>

            <OutlinedInput
              style={{ width: "100%" }}
              id="outlined-basic"
              variant="outlined"
              size="small"
              name="price"
              {...register("price")}
              //onChange={(e) => amountChange(e)}
              startAdornment={
                <InputAdornment data={amount} position="start">
                  ₹
                </InputAdornment>
              }
            />
            <div className="field-error">{errors?.price?.message}</div>
          </Grid>

          <Stack
            spacing={2}
            direction="row"
            className=""
            style={{ justifyContent: "right" }}
          >
            <Button
              type="submit"
              //onClick={updateSubmit}
              variant="contained"
              className="bg-blue"
            >
              Submit
            </Button>
            <Button
              size="small"
              variant="outlined"
              style={{
                textTransform: "capitalize",
                borderColor: "#00249b",
                color: "#00249b",
              }}
              onClick={handleCloses}
            >
              Cancel
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
}
