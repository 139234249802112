const tableRows = [
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },

  {
    title: "Account ID",
    key: "accountid",
    align: "left",
  },
  {
    title: "MSO ID",
    key: "msqid",
    align: "left",
  },
  {
    title: "STB NO",
    key: "stbno",
    align: "left",
  },
  {
    title: "Customer Name",
    key: "customerName",
    align: "left",
  },
  {
    title: "Customer E-mail",
    key: "customerEmail",
    align: "left",
  },
  {
    title: "Area",
    key: "area",
    align: "left",
  },

  {
    title: "Sub Area",
    key: "subarea",
    align: "left",
  },
  {
    title: "Flat No",
    key: "flatno",
    align: "left",
  },

  {
    title: "Outstanding Amount",
    key: "dueAmount",
    align: "left",
  },
  {
    title: "Make Payment",
    key: "makepay",
    align: "left",
  },
  
  {
    title: "Service Type",
    key: "serviceType",
    align: "left",
  },
  {
    title: "Billing Type",
    key: "billingType",
    align: "left",
  },

  {
    title: "Created Date",
    key: "createdDate",
    align: "left",
  },

 
];

export { tableRows };
