import { createSlice } from "@reduxjs/toolkit";
import {
  taxList,
  taxListUpdate,  
  settingsPayment,
  blukUpload,
  emailTemplates,
  smsTemplates,
  deleteAssignSubs,
  assignSubscriberSubmit,
  bulkUploadSave,
} from "./api";

const initialState = {
  showSideBar: true,  
  taxListData: [],
  taxListUpdateData: [],  
  paymentData: [],
  smsTempData: [],
  emailTempData: [],
  bulkUploadAdded: false,
  assignSubscriberSubmitData: [],
  assignDelete: false,
  bulkUploadSaveAdded: false,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    showSideBar: (state, action) => {
      state.showSideBar = action.payload;
    },
    reset: (state) => {
      state.assignDelete = false;
      return state;
    },
  },

  extraReducers: {
    [settingsPayment.pending]: (state) => {},
    [settingsPayment.fulfilled]: (state, { payload }) => {
      state.paymentData = payload.data.data;
    },
    [settingsPayment.rejected]: (state, { payload }) => {},



    [taxList.pending]: (state) => {},
    [taxList.fulfilled]: (state, { payload }) => {
      state.taxListData = payload.data.data;
    },
    [taxList.rejected]: (state, { payload }) => {},

    [taxListUpdate.pending]: (state) => {},
    [taxListUpdate.fulfilled]: (state, { payload }) => {
      state.taxListUpdateData = payload.data.data;
    },
    [taxListUpdate.rejected]: (state, { payload }) => {},
    [emailTemplates.pending]: (state) => {},
    [emailTemplates.fulfilled]: (state, { payload }) => {
      state.emailTempData = payload.data.data;
    },
    [emailTemplates.rejected]: (state, { payload }) => {},
    [smsTemplates.pending]: (state) => {},
    [smsTemplates.fulfilled]: (state, { payload }) => {
      state.smsTempData = payload.data.data;
    },
    [smsTemplates.rejected]: (state, { payload }) => {},
  },
  [taxListUpdate.rejected]: (state, { payload }) => {},



  [blukUpload.pending]: (state) => { },
  [blukUpload.fulfilled]: (state, { payload }) => {
    state.bulkUploadAdded = payload.data.status;
  },
  [blukUpload.rejected]: (state, { payload }) => {},

  [deleteAssignSubs.pending]: (state) => {},
  [deleteAssignSubs.fulfilled]: (state, { payload }) => {
    state.assignDelete = payload.data.status;
  },
  [deleteAssignSubs.rejected]: (state, { payload }) => {},

 




});

export const { showSideBar } = settingsSlice.actions;
export const { reset } = settingsSlice.actions;

export default settingsSlice.reducer;
