import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./styles.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { updateBalance, subscriberView,  lastPaid } from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import { useComponentDidUpdate, paramsQuery } from "@utils/useEffectHooks";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/subscriber";
import { useForm, Controller } from "react-hook-form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const options = [{ label: "Giving to Customer (+)" }, { label: "Giving to Customer (-)" }];

export default function BalaanceModal(props) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({});

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [prevbal, setPrevbal] = useState(null);
  const [type, setType] = useState(null);
  const [amount, setAmount] = useState(0);
  const [updatedBalance, setUpdatedBalance] = useState(null);
  const { updateBalanceData } = useSelector((state) => state.subscriber);

  const handleClose = () => {
    props?.handleClose();
  };

  const typeChange = (e, value) => {
    setType(value?.label);
  };

  const updateSubmit = (data) => {
    let payload = {
      _id: props?.listData?._id,
      wallet_balance: data?.updatedbalnce,
    };
    if (data?.updatedbalnce < 0) {
      enqueueSnackbar("Please enter a valid amount", {
        variant: "error",
      });
      return;
    } else {
      dispatch(updateBalance(payload));
      reset();
    }
  };

  const onSubmit = (data) => {
    updateSubmit(data);
  };

  const totalAmount = () => {
    let enteredamount = Number(watch("enteredamount")) || 0;
    let amountvalue = 0;
    if (type == "Giving to Customer (+)") {
      amountvalue = parseFloat(props?.listData?.wallet_balance || parseInt(0)) + parseFloat(enteredamount);
    } else if (type == "Giving to Customer (-)") {
      amountvalue = parseFloat(props?.listData?.wallet_balance || parseInt(0)) - parseFloat(enteredamount);
    }
    setValue("updatedbalnce", amountvalue);
  };

  const handleCloses = () => {
    props?.handleClose();
  };

  useComponentDidUpdate(() => {
    if (watch("enteredamount") > 0) {
      totalAmount();
    }
  }, [watch("enteredamount"), type]);

  useComponentDidUpdate(() => {
    let id = paramsQuery("id");
    if (updateBalanceData) {
      enqueueSnackbar("Updated", {
        variant: "success",
      });
      props?.handleClose();
      setAmount(0);
      dispatch(subscriberView(id));
      let paidquery = `cust_ref_id=${id}`;
      dispatch(lastPaid(paidquery));
    }
  }, [updateBalanceData]);

  return (
    <Modal open={props?.show} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style} className="makePayment-cntr">
        <div className="make-payment-form-title">
          Update Balance For Customer : <span className="colorBlue">{props?.listData?.name}</span>
        </div>
        <div className="divider-line"></div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles">
            <Grid item xs={4} sm={8} md={12}>
              <div className="make-payment-form-title ">Previous Balance </div>
              <TextField style={{ width: "100%" }} id="outlined-basic" disabled variant="outlined" size="small" defaultValue={props?.listData?.wallet_balance || "0"} />
            </Grid>
          </Grid>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles ticketDetails">
            <div className="make-payment-form-title ">Type</div>

            <Controller
              control={control}
              name="typeofcalc"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  id="combo-box-demo"
                  style={{ width: "100%" }}
                  onChange={(event, item) => {
                    onChange(item);
                    typeChange(event, item);
                  }}
                  value={value || ""}
                  options={options}
                  isOptionEqualToValue={(option) => option?.label === option?.value || ""}
                  renderInput={(params) => <TextField {...params} size="small" name="typeofcalc" placeholder="Select option " />}
                />
              )}
            />
          </Grid>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles">
            <div className="make-payment-form-title ">Amount</div>

            <OutlinedInput
              style={{ width: "100%" }}
              id="outlined-basic"
              variant="outlined"
              size="small"
              name="enteredamount"
              {...register("enteredamount")}
              //onChange={(e) => amountChange(e)}
              startAdornment={
                <InputAdornment data={amount} position="start">
                  ₹
                </InputAdornment>
              }
            />
          </Grid>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles">
            <div className="make-payment-form-title ">Updated Balance will be{""}</div>
            <TextField disabled style={{ width: "100%" }} id="outlined-basic" name="updatedbalnce" variant="outlined" {...register("updatedbalnce")} size="small" />
          </Grid>
          <Stack spacing={2} direction="row" className=" ">
            <Button
              type="submit"
              //onClick={updateSubmit}
              variant="contained"
              className="bg-blue"
              size="small"
              style={{
                textTransform: "capitalize",
              }}
            >
              Submit
            </Button>
            <Button
              size="small"
              variant="outlined"
              style={{
                textTransform: "capitalize",
                borderColor: "#00249b",
                color: "#00249b",
              }}
              onClick={handleCloses}
            >
              Cancel
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
}
