const tableRows = [
  {
    title: "S.No",
    key: "sino",
    align: "left",
  },
  {
    title: "Items",
    key: "item",
    align: "left",
  },
  {
    title: "Prices",
    key: "price",
    align: "left",
  },
  {
    title: "Quantity",
    key: "quantity",
    align: "left",
  },
  {
    title: "STB No",
    key: "stbno",
    align: "left",
  },
  {
    title: "Type",
    key: "type",
    align: "left",
  },
  {
    title: "Item Provider",
    key: "itemProvider",
    align: "left",
  },
  {
    title: "Box Type",
    key: "boxtype",
    align: "left",
  },
  {
    title: "Payment Category",
    key: "paymentCat",
    align: "left",
  },
  {
    title: "Service Type",
    key: "serviceType",
    align: "left",
  },
  {
    title: "STB Supplied",
    key: "stbsupplied",
    align: "left",
  },
  {
    title: "Created Date",
    key: "createddate",
    align: "left",
  },
  {
    title: "Action",
    key: "action",
    align: "left",
  },

];

export { tableRows };
