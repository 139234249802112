const tableRows = [
    {
      title: "Si No",
      key: "sno",
      align: "left",
    },
    {
      title: "Subscriber Name",
      key: "subsName",
      align: "left",
    },
    {
      title: "Email",
      key: "email",
      align: "left",
    },
    {
      title: "Hardware List",
      key: "hardwareList",
      align: "left",
    },
  
    {
      title: "Service Type",
      key: "servicetype",
      align: "left",
    },
    {
      title: "Amount",
      key: "planamt",
      align: "left",
    },
    {
      title: "No of Hardware",
      key: "noHardware",
      align: "left",
    },
    {
      title: "Billing Type",
      key: "billingType",
      align: "left",
    },
  
    
  ];
  
  export { tableRows };
  