import { createSlice } from "@reduxjs/toolkit";
import {
  employeeList,
  SubscriberCount,
  billingHistoryToday,
  distributorPlanExpiry,
  billingHistory,
  subscriber,
  paymentSummary,
  subscriberPlan,
  subscriberPlanTomorrow,
  subscriberPlanToday,
  subscriberPlanTotalExpiration,
  subscriberPlanfiveExpiration,
  subscriberPlanCablePostpaid,
  alaCartPackageCount,
  boqPackageCount,
  employeeCount,
  billingHistoryYesterday,
  
} from "./api";

const initialState = {
  value: 0,
  employeeData: [],
  billingHistoryTodayData: [],
  billingHistoryYesterdayData: [],
  billingHistoryData: [],
  subscriberData: [],
  paymentSummaryData: [],
  subscriberPlanData: [],
  subscriberPlanTomorrowData: [],
  subscriberPlanTodayData: [],
  subscriberPlanTotalExpirationData: [],
  subscriberPlanfiveExpirationData:[],
  subscriberPlanCablePostpaidData: [],
  subCountData: {},
  distributorPlanExpiryData: [],
  validityCount:[],
  alaCountData: {},
  boCountData: {},
  employeeCountData:{}, 
  
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: {
    [employeeList.pending]: (state) => {},
    [employeeList.fulfilled]: (state, { payload }) => {
      state.employeeData = payload.data.data;
    },
    [employeeList.rejected]: (state, { payload }) => {},

    [employeeCount.pending]: (state) => {},
    [employeeCount.fulfilled]: (state, { payload }) => {
      state.employeeCountData = payload.data.data;
    },
    [employeeCount.rejected]: (state, { payload }) => {},

    [SubscriberCount.pending]: (state) => {},
    [SubscriberCount.fulfilled]: (state, { payload }) => {
      state.subCountData = payload.data.data;
    },
    [SubscriberCount.rejected]: (state, { payload }) => {},
    [distributorPlanExpiry.pending]: (state) => {},
    [distributorPlanExpiry.fulfilled]: (state, { payload }) => {
      let objData = [];
      let user = JSON.parse(localStorage.getItem("user"));
      for (let data of payload?.data?.data) {
        let obj = {
          distributorName: data?.name,
          validity: `${data?.distributor_validity && data?.distributor_validity || "Unlimited"} `,
          limit: user?.role=="distributor" ? data?.custTotalCount+" / "+data?.subscriber_limit_count : data?.subscriber_limit_count,
          expiration: `${data?.expiringDays ? data?.expiringDays < 0 ? 'Expired ( '  + Math.abs(data?.expiringDays) +' )' : 'In '+data?.expiringDays+' days' : "Unlimited"} `,
        };
        objData.push(obj);
      }

      state.distributorPlanExpiryData = objData;
      state.validityCount = payload.data.data;
    },
    [distributorPlanExpiry.rejected]: (state, { payload }) => {},
    [billingHistoryToday.pending]: (state) => {},
    [billingHistoryToday.fulfilled]: (state, { payload }) => {
      state.billingHistoryTodayData = payload.data.data;
    },
    [billingHistoryToday.rejected]: (state, { payload }) => {},

    [billingHistoryYesterday.pending]: (state) => {},
    [billingHistoryYesterday.fulfilled]: (state, { payload }) => {
      state.billingHistoryYesterdayData = payload.data.data;
    },
    [billingHistoryYesterday.rejected]: (state, { payload }) => {},

    [billingHistory.pending]: (state) => {},
    [billingHistory.fulfilled]: (state, { payload }) => {
      state.billingHistoryData = payload.data.data;
    },
    [billingHistory.rejected]: (state, { payload }) => {},

    [subscriber.pending]: (state) => {},
    [subscriber.fulfilled]: (state, { payload }) => {
      state.subscriberData = payload.data.data;
    },
    [subscriber.rejected]: (state, { payload }) => {},

    [paymentSummary.pending]: (state) => {},
    [paymentSummary.fulfilled]: (state, { payload }) => {
      state.paymentSummaryData = payload.data.data;
    },
    [paymentSummary.rejected]: (state, { payload }) => {},

    [subscriberPlan.pending]: (state) => {},
    [subscriberPlan.fulfilled]: (state, { payload }) => {
      state.subscriberPlanData = payload.data.data;
    },
    [subscriberPlan.rejected]: (state, { payload }) => {},

    [subscriberPlanTomorrow.pending]: (state) => {},
    [subscriberPlanTomorrow.fulfilled]: (state, { payload }) => {
      state.subscriberPlanTomorrowData = payload.data;
    },
    [subscriberPlanTomorrow.rejected]: (state, { payload }) => {},

    [subscriberPlanToday.pending]: (state) => {},
    [subscriberPlanToday.fulfilled]: (state, { payload }) => {
      state.subscriberPlanTodayData = payload.data.data;
    },
    [subscriberPlanToday.rejected]: (state, { payload }) => {},

    [subscriberPlanTotalExpiration.pending]: (state) => {},
    [subscriberPlanTotalExpiration.fulfilled]: (state, { payload }) => {
      state.subscriberPlanTotalExpirationData = payload.data.data;
    },
    [subscriberPlanTotalExpiration.rejected]: (state, { payload }) => {},

    [subscriberPlanfiveExpiration.pending]: (state) => {},
    [subscriberPlanfiveExpiration.fulfilled]: (state, { payload }) => {
      state.subscriberPlanfiveExpirationData = payload.data;
    },
    [subscriberPlanfiveExpiration.rejected]: (state, { payload }) => {},

    [subscriberPlanCablePostpaid.pending]: (state) => {},
    [subscriberPlanCablePostpaid.fulfilled]: (state, { payload }) => {
      state.subscriberPlanCablePostpaidData = payload.data.data;
    },
    [subscriberPlanCablePostpaid.rejected]: (state, { payload }) => {},
    [alaCartPackageCount.pending]: (state) => {},
    [alaCartPackageCount.fulfilled]: (state, { payload }) => {
      state.alaCountData = payload.data.data;
    },
    [alaCartPackageCount.rejected]: (state, { payload }) => {},
    [boqPackageCount.pending]: (state) => {},
    [boqPackageCount.fulfilled]: (state, { payload }) => {
      state.boCountData = payload.data.data;
    },
    [boqPackageCount.rejected]: (state, { payload }) => {},
    
  },
});

export default dashboardSlice.reducer;
