const tableRows = [
  {
    key: "checkbox",
    align: "left",
  },
  {
    title: "Menu",
    key: "menu",
    align: "left",
  }, 
];

export { tableRows };
