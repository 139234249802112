const tableRows = [
  {
    key: "checkbox",
    align: "left",
  },
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },

  {
    title: "Account ID",
    key: "accountid",
    align: "left",
  },
  {
    title: "MSO ID",
    key: "msqid",
    align: "left",
  },
  {
    title: "Customer Name",
    key: "customerName",
    align: "left",
  },
  {
    title: "Customer E-mail",
    key: "customerEmail",
    align: "left",
  },
  {
    title: "Area",
    key: "area",
    align: "left",
  },
  {
    title: "Flat No",
    key: "flatNo",
    align: "left",
  },

  {
    title: "Status",
    key: "status",
    align: "left",
  },
];

export { tableRows };
