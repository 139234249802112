import "./style.scss";
import React, { useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function SubscriberTab(props) {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className="subscriberTab">
      <Box>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} indicatorColor="secondary" variant="fullWidth" aria-label="full width tabs example">
            {props.data && props?.data?.map((list) => <Tab label={list.title} />)}
          </Tabs>
        </AppBar>
        <SwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={value} onChangeIndex={handleChangeIndex}>
          {props.data &&
            props?.data?.map((list, index) => (
              <TabPanel value={value} index={index} dir={theme.direction}>
                {list?.comp}
              </TabPanel>
            ))}
        </SwipeableViews>
      </Box>
    </div>
  );
}
