import "./style.scss";
import Button from "@mui/material/Button";
import PersonIcon from "@mui/icons-material/Person";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import PaymentModal from "../Modals/makePayment";
import EmailModal from "../Modals/sendEmail";
import SmsModal from "../Modals/sendSms";
import SmsWhatsapp from "../Modals/sendWhatsapp";
import Stack from "@mui/material/Stack";
import BalanceModal from "../Modals/adjustBalance";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  profileimageUpload,
  currentServiceList,
  updateBalance,
  smsType,
  othersAll,
  getWhatsappList,
} from "@reducers/api";
import {
  paramsQuery,
  useComponentDidUpdate,
  useComponentDidMount,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/subscriber";
import Resizer from "react-image-file-resizer";

const Input = styled("input")({
  display: "none",
});

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export default function SubscriberProfile(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    payNowAdded,
    updateBalanceData,
    bulkemaildata,
    singlesmsData,
    otherAllData,whatsappMessageSendStatus
  } = useSelector((state) => state.subscriber);
  const { smsTypeData } = useSelector((state) => state.ticketing);
  const { getWhatsappListData } = useSelector((state) => state.distributor);
  

  const [selectedImage, setSelectedImage] = useState(null);
  const [show, setShow] = useState(false);
  const [showemail, setShowEmail] = useState(false);
  const [showsms, setShowSms] = useState(false);
  const [showbalance, setShowBalance] = useState(false);
  const [showwatsapp, setShowwatsapp] = useState(false);

  

  let imagePreview = null;
  if (selectedImage) {
    imagePreview = (
      <img
        className="img-previewUpload"
        src={URL.createObjectURL(selectedImage)}
        alt={"Not found"}
      />
    );
  } else {
    imagePreview = props?.data?.fileData ? (
      <img
        className="img-previewUpload"
        src={`data:image/png;base64,${props?.data?.fileData}`}
        alt={"Iam here"}
      />
    ) : (
      <PersonIcon
        style={{
          color: "grey",
          marginLeft: "10px",
          fontSize: "7rem",
          marginTop: "1rem",
        }}
      />
    );
  }

  const onShowPopUp = () => {
    setShow(!show);
    let user = JSON.parse(localStorage.getItem("user"));
    let distributorId = `${user?.distributor_ref_id}&service_type=${props?.data?.service_type}`;
    dispatch(othersAll(distributorId));
    if (props?.data?.service_type === "internet") {
      let listid = props?.data?._id;
      let query1 = `${listid}&is_active=1`;
      dispatch(currentServiceList(query1));
    }
    if (props?.lastData?.billing_status === "open") {
      enqueueSnackbar("Bill already generated for this customer ", {
        variant: "warning",
      });

    }
  };

  const onShowEmailPopUp = () => {
    setShowEmail(!showemail);
  };

  useComponentDidUpdate(() => {
    if (bulkemaildata) {
      enqueueSnackbar("Email Sended", {
        variant: "success",
      });
      setShowEmail(!showemail);
      dispatch(reset());
    }
  }, [bulkemaildata]);

  useComponentDidUpdate(() => {
    if (singlesmsData) {
      enqueueSnackbar("SMS Sended", {
        variant: "success",
      });
      dispatch(reset());
      setShowSms(!showsms);
    }
  }, [singlesmsData]);

  useComponentDidUpdate(() => {
    if (whatsappMessageSendStatus) {
      enqueueSnackbar("Message Sended", {
        variant: "success",
      });
      dispatch(reset());
      setShowwatsapp(!showwatsapp);
    }
  }, [whatsappMessageSendStatus]);

  const onShowSmsPopUp = () => {
    setShowSms(!showsms);
  };

  const onShowWhatsappPopUp = () => {
    setShowwatsapp(!showwatsapp);
  };

  const onCloseWhatsappPopUp = () => {
    setShowwatsapp(!showwatsapp);
  };

  const onShowBalancePopUp = () => {
    setShowBalance(true);
  };

  const closepoup = () => {
    setShowBalance(false);
  };

  useComponentDidUpdate(() => {
    if (payNowAdded) {
      enqueueSnackbar("Payment Success !", {
        variant: "success",
      });
      dispatch(reset());
      setShow(false);
    }
  }, [payNowAdded]);

  const handleClose = () => {
    setShow(!show);
  };


  console.log(props?.lastData, "fromlast")

  useComponentDidUpdate(() => {
    if (updateBalanceData) {
      setSelectedImage(null);
    }
  }, [updateBalanceData]);

  const handleImgChange = async (event) => {
    let custId = paramsQuery("id");
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    // let imgUrl = URL.createObjectURL(event.target.files[0])
    // setimageRender(imgUrl)

    reader.onload = async () => {
      const image = await resizeFile(event.target.files[0]);

      const dataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(",");
        const byteString =
          splitDataURI[0].indexOf("base64") >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++)
          ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
      };

      const newFile = dataURIToBlob(image);
      const formData = new FormData();
      formData.append("files", newFile);
      formData.append("id", custId);
      let payload = {
        files: formData,
        custId: custId,
      };

      const fileUploadedUrl = await dispatch(profileimageUpload(payload));
    };

    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&type=${`sms`}`;
    dispatch(smsType(query));
    dispatch(getWhatsappList(query));
  }, []);

  let usersms = JSON.parse(localStorage.getItem("user"));


  return (
    <div>
      <PaymentModal
        show={show}
        listData={props?.data}
        lastData={props?.lastData}
        paymentTax={props?.paymentTax}
        planList={props.planList}
        handleClose={handleClose}
        otherAllData={otherAllData}
      />
      <BalanceModal
        show={showbalance}
        handleClose={closepoup}
        listData={props?.data}
      />
      <EmailModal
        show={showemail}
        handleClose={onShowEmailPopUp}
        listData={props?.data}
      />
      <SmsModal
        show={showsms}
        handleClose={onShowSmsPopUp}
        listData={props?.data}
        smsType={smsTypeData}
      />
      <SmsWhatsapp  
        show={showwatsapp}
        handleClose={onCloseWhatsappPopUp}
        listData={props?.data}
        smsType={getWhatsappListData}/>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={2} xl={2}>
          <div className="">
            <div className="imgPreview">{imagePreview}</div>

            <label htmlFor="contained-button-file">
              <Input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                onChange={(event) => {
                  setSelectedImage(event.target.files[0]);
                  handleImgChange(event);
                }}
              />

              <Button
                variant="contained"
                component="span"
                className="bg-blue"
                size="small"
                sx={{ fontSize: 13, textTransform: "capitalize", width: 138 }}
              >
                <DriveFolderUploadIcon /> &nbsp; Start Upload
              </Button>
            </label>
            {props?.data?.fileData && (
              <Button
                variant="contained"
                component="span"
                className="bg-blue deleteProf"
                size="small"
                onClick={() =>
                  dispatch(
                    updateBalance({
                      _id: paramsQuery("id"),
                      profile_pic_delete: "success",
                    })
                  )
                }
                sx={{ fontSize: 13, textTransform: "capitalize", width: 138 }}
              >
                Delete Pic
              </Button>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={4}>
          <div>
            <div className="colorBlue">
              {props?.data?.name} ({props?.data?.account_id})
            </div>
            <p className="user-dts-title"> Area : {props?.data?.area} </p>
            <p className="user-dts-title"> SubArea : {props?.data?.subarea} </p>
            <p className="user-dts-title">
              {" "}
              Connection No : {props?.data?.stb_no?.length}
            </p>
            <p className="user-dts-title">
              Wallet Ballance : {props?.data?.wallet_balance || "0"}
              <Button
                variant="contained"
                component="span"
                className="bg-blue adjust-bal"
                size="small"
                sx={{ fontSize: 13, textTransform: "capitalize" }}
                onClick={onShowBalancePopUp}
              >
                Adjust Balance
              </Button>
            </p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={3}>
          <div>
            <div className="colorBlue">&nbsp;</div>
            <p className="user-dts-title">
              Address : {props?.data?.flat_no},{props?.data?.city},
              {props?.data?.state}{" "}
            </p>
            <p className="user-dts-title">
              {"Last Payment & Date"} : {props?.lastData?.collection_amount} &{" "}
              {moment(props?.lastData?.updated_at).format("DD/MM/YYYY")}
            </p>
            <p className="user-dts-title">
              Last Login Time :{" "}
              {moment(props?.lastData?.updated_at).format("HH:mm")}
            </p>
            <p className="user-dts-title">
              Credit Amount : {props?.lastData?.due_amount || "0"}
            </p>
            <p className="user-dts-title">
              Advance Amount : {props?.lastData?.advance_amount || "0"}
            </p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={3}>
          <Stack spacing={2} direction="row" className="flex-end">
            <Button
              variant="contained"
              onClick={() =>
                navigate(`/distributor/subscriber-edit?id=${props?.data._id}`)
              }
              component="span"
              className="bg-blue"
              size="small"
              sx={{ fontSize: 13, textTransform: "capitalize" }}
            >
              Edit Profile
            </Button>
            {props.data.billing_initiated === true && (
              <Button
                variant="contained"
                component="span"
                className="bg-blue"
                size="small"
                onClick={onShowPopUp}
                sx={{ fontSize: 13, textTransform: "capitalize" }}
              >
                Make Payment
              </Button>
            )}
          </Stack>
          <Stack spacing={2} direction="row" className="flex-end deleteProf">
            <Button
              variant="contained"
              component="span"
              className="bg-blue"
              size="small"
              onClick={onShowEmailPopUp}
              sx={{ fontSize: 13, textTransform: "capitalize", width: "87px" }}
            >
              Send Mail
            </Button>

            {usersms?.sms_access === "enable" && (
              <Button
                variant="contained"
                component="span"
                className="bg-blue"
                size="small"
                onClick={onShowSmsPopUp}
                sx={{
                  fontSize: 13,
                  textTransform: "capitalize",
                  width: "7rem",
                }}
              >
                Send SMS
              </Button>
            )}
            
          </Stack>
          <Stack spacing={1} direction="row" className="flex-end deleteProf">          

            {usersms?.whatsapp_access === "enable" && (
              <Button
                variant="contained"
                component="span"
                className="bg-blue"
                size="small"
                onClick={onShowWhatsappPopUp}
                sx={{
                  fontSize: 13,
                  textTransform: "capitalize",
                }}
              >
                Send Whatsapp
              </Button>
            )}
            
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
