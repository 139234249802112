const tableRows = [
  {
    key: "checkbox",
    align: "left",
  },
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },
  {
    title: "Plan Name",
    key: "planname",
    align: "left",
  },
  {
    title: "Package Category",
    key: "packagecategory",
    align: "left",
  },
  {
    title: "STB NO",
    key: "stbno",
    align: "left",
  },
  {
    title: "Amount",
    key: "amount",
    align: "left",
  },
  {
    title: "Created Date",
    key: "createddate",
    align: "left",
  },
  {
    title: "Expired Date",
    key: "expireddate",
    align: "left",
  },
  {
    title: "Status",
    key: "status",
    align: "left",
  },
  {
    title: "Action",
    key: "action",
    align: "left",
  },
];

export { tableRows };
