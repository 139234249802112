import axios from "axios";
import { BaseUrl } from "../constants/baseUrl";
import { createAsyncThunk } from "@reduxjs/toolkit";

let user = JSON.parse(localStorage.getItem("user"));
let payment = JSON?.parse(localStorage?.getItem("payment"));

export const loginUser = createAsyncThunk("users/login", async (payload) => await axios.post(`${BaseUrl}/login`, payload));

export const forgotPassword = createAsyncThunk("users/forgotPsw", async (payload) => await axios.post(`${BaseUrl}/forgotPassword`, payload));

export const employeeList = createAsyncThunk("dashboard/employee", async (payload) => await axios.get(`${BaseUrl}/employee/list/count?&limit=3&offset=0${payload?.distributor_id ? `&distributor_ref_id=${payload?.distributor_id}` : ""}`));

export const SubscriberCount = createAsyncThunk("dashboard/subscriber-count", async (query) => await axios.get(`${BaseUrl}/customer/count?${query ? query : ""}`));

export const alaCartPackageCount = createAsyncThunk("dashboard/ala-package-count", async (query) => await axios.get(`${BaseUrl}/admin/alacart/plan/count?package_category=ala cart`));

export const boqPackageCount = createAsyncThunk("dashboard/bo-package-count", async (query) => await axios.get(`${BaseUrl}/admin/alacart/plan/count?package_category=broadcaster`));

export const distributorPlanExpiry = createAsyncThunk("dashboard/distributor-plan-expiry", async (query) => await axios.get(`${BaseUrl}/distributor/expiring/validity?${query ? query : ""}`));

export const subscriberPlan = createAsyncThunk(
  "dashboard/subscriberPlan",
  async (payload) => await axios.get(`${BaseUrl}/plan/expireing/list?fromdate=${payload?.todayDate}&service_type=${payload?.serviceType}&billing_type=${payload?.billingType}&todate=${payload?.fivedaysDate}${payload?.distributor_id ? `&distributor_ref_id=${payload?.distributor_id}` : ""}`)
);

export const subscriberPlanTomorrow = createAsyncThunk(
  "dashboard/subscriberPlanTomorrow",
  async (payload) => await axios.get(`${BaseUrl}/plan/expireing/list?fromdate=${payload?.todayDate}&billing_type=${payload?.billingType}&service_type=${payload?.serviceType}&todate=${payload?.tomorrowDate}${payload?.distributor_id ? `&distributor_ref_id=${payload?.distributor_id}` : ""}`)
);

export const subscriberPlanToday = createAsyncThunk(
  "dashboard/subscriberPlanToday",
  async (payload) => await axios.get(`${BaseUrl}/plan/expireing/list?fromdate=${payload?.yesterdayDate}&billing_type=${payload?.billingType}&service_type=${payload?.serviceType}&todate=${payload?.todayDate}${payload?.distributor_id ? `&distributor_ref_id=${payload?.distributor_id}` : ""}`)
);

export const subscriberPlanTotalExpiration = createAsyncThunk(
  "dashboard/subscriberPlanTotalExpiration",
  async (payload) => await axios.get(`${BaseUrl}/plan/expireing/list?fromdate=${payload?.todayDate}&billing_type=${payload?.billingType}&service_type=${payload?.serviceType}&todate=${payload?.tendaysDate}${payload?.distributor_id ? `&distributor_ref_id=${payload?.distributor_id}` : ""}`)
);

export const subscriberPlanfiveExpiration = createAsyncThunk(
  "dashboard/totalExpiration",
  async (payload) => await axios.get(`${BaseUrl}/customer/plan/report/list?&package_category=package&is_active=false&billing_type=${payload?.billingType}&service_type=${payload?.serviceType}${payload?.distributor_id ? `&distributor_ref_id=${payload?.distributor_id}` : ""}`)
);

export const subscriberPlanCablePostpaid = createAsyncThunk(
  "dashboard/subscriberPlanCablePostpaid",
  async (payload) => await axios.get(`${BaseUrl}/plan/expireing/list?billing_type=post paid&filterBySerives=0&fromdate=${payload?.date.fromdate}&service_type=${payload?.serviceType}&todate=${payload?.date.todate}${payload?.distributor_id ? `&distributor_ref_id=${payload?.distributor_id}` : ""}`)
);

export const billingHistoryToday = createAsyncThunk(
  "dashboard/billingHistorytoday",
  async (payload) => await axios.get(`${BaseUrl}/dashboard/billing/history/today?filterBySerives=0&service_type=${payload?.serviceType}&billing_type=${payload.billingType}${payload?.distributor_id ? `&distributor_ref_id=${payload?.distributor_id}` : ""}`)
);

export const billingHistoryYesterday = createAsyncThunk(
  "dashboard/billingYesterday",
  async (payload) => await axios.get(`${BaseUrl}/dashboard/billing/history/previous?filterBySerives=0&service_type=${payload?.serviceType}&billing_type=${payload.billingType}${payload?.distributor_id ? `&distributor_ref_id=${payload?.distributor_id}` : ""}`)
);

export const billingHistory = createAsyncThunk(
  "dashboard/billingHistory",
  async (payload) => await axios.get(`${BaseUrl}/dashboard/billing/history?filterBySerives=0&service_type=${payload?.serviceType}&billing_type=${payload.billingType}${payload?.distributor_id ? `&distributor_ref_id=${payload?.distributor_id}` : ""}`)
);

export const subscriber = createAsyncThunk("dashboard/subscriber", async (payload) => await axios.get(`${BaseUrl}/dashboard/user/count?filterBySerives=0&service_type=${payload?.serviceType}${payload?.distributor_id ? `&distributor_ref_id=${payload?.distributor_id}` : ""}`));

export const paymentSummary = createAsyncThunk(
  "dashboard/payment/summary",
  async (payload) => await axios.get(`${BaseUrl}/transaction/dashboard/list?fromdate=${payload?.date.todate}&billing_type=${payload.billingType}&service_type=${payload?.serviceType}&todate=${payload?.date.fromdate}${payload?.distributor_id ? `&distributor_ref_id=${payload?.distributor_id}` : ""}`)
);

export const planList = createAsyncThunk("dashboard/subscriber", async (query) => await axios.get(`${BaseUrl}/admin/alacart/plan/list?${query}`));

export const enquiryStatus = createAsyncThunk("distributor/enquiry-list", async (payload) => await axios.get(`${BaseUrl}/enquiry/count?distributor_ref_id=${payload?.distributor_id}`));

export const distributorList = createAsyncThunk("distributor/list", async (query) => await axios.get(`${BaseUrl}/distributor/list?${query ? query : ""}`));

export const addEmployee = createAsyncThunk("distributor/add", async (payload) => await axios.post(`${BaseUrl}/employee/add`, payload));

export const distEmployeeList = createAsyncThunk("employee/list", async (query) => await axios.get(`${BaseUrl}/employee/list?${query ? query : ""}`));

export const ticketOpenStatus = createAsyncThunk("tickectStatus/open", async (payload) => await axios.get(`${BaseUrl}/ticket/count?distributor_ref_id=${payload?.distributor_id}`));

export const ticketList = createAsyncThunk("tickect/list", async (query) => await axios.get(`${BaseUrl}/ticket/list?${query ? query : ""}`));

export const InvoiceReceiptList = createAsyncThunk("user/transaction", async (query) => await axios.get(`${BaseUrl}/user/transaction?&${query}`));

export const updateTicket = createAsyncThunk("ticket/update", async (payload) => await axios.post(`${BaseUrl}/ticket/update`, payload));

export const addTicket = createAsyncThunk("ticket/add", async (payload) => await axios.post(`${BaseUrl}/ticket/add`, payload));

export const addDistributor = createAsyncThunk("distributor/add", async (payload) => await axios.post(`${BaseUrl}/distributor/add`, payload));

export const updateDistributor = createAsyncThunk("distributor/update", async (payload) => await axios.post(`${BaseUrl}/distributor/update`, payload));

export const deleteDistributor = createAsyncThunk("distributor/delete", async (payload) => await axios.delete(`${BaseUrl}/distributor/delete`, { data: payload }));

export const addPlan = createAsyncThunk("plan/add", async (payload) => await axios.post(`${BaseUrl}/plan/add`, payload));

export const updatePlan = createAsyncThunk("distributor/update/plan", async (payload) => await axios.post(`${BaseUrl}/plan/update`, payload));

export const deletePlan = createAsyncThunk("plan/delete", async (payload) => await axios.delete(`${BaseUrl}/plan/delete`, { data: payload }));

export const deleteEmployee = createAsyncThunk("employee/delete", async (payload) => await axios.delete(`${BaseUrl}/employee/delete`, { data: payload }));

export const deleteCurrentServicePlan = createAsyncThunk("user/delete/plan", async (payload) => await axios.delete(`${BaseUrl}/user/delete/plan`, { data: payload }));

export const deleteTicket = createAsyncThunk("ticket/delete", async (payload) => await axios.delete(`${BaseUrl}/ticket/delete`, { data: payload }));

export const enquiryList = createAsyncThunk("tickect/list", async (query) => await axios.get(`${BaseUrl}/enquiry/list?${query ? query : ""}`));

export const addEnquiry = createAsyncThunk("add/enquiry", async (payload) => await axios.post(`${BaseUrl}/enquiry/add`, payload));

export const updateEnquiry = createAsyncThunk("enquiry/update", async (payload) => await axios.post(`${BaseUrl}/enquiry/update`, payload));

export const deleteEnquiry = createAsyncThunk("enquiry/delete", async (payload) => await axios.delete(`${BaseUrl}/enquiry/delete`, { data: payload }));

export const profileView = createAsyncThunk("profile/view", async (payload) => await axios.post(`${BaseUrl}/profile`, payload));

export const alaCartList = createAsyncThunk("plan/broadcast", async (query) => await axios.get(`${BaseUrl}/admin/alacart?${query ? query : ""}`));

export const billingList = createAsyncThunk("billing/list", async (query) => await axios.get(`${BaseUrl}/transaction/report/list?${query ? query : ""}`));

export const deleteCollection = createAsyncThunk("collection/delete", async (payload) => await axios.delete(`${BaseUrl}/transaction/reopen`, { data: payload }));

export const expensesAdd = createAsyncThunk("expenses/add", async (payload) => await axios.post(`${BaseUrl}/expense/add`, payload));

export const subscriberList = createAsyncThunk("subscriber/list", async (query) => await axios.get(`${BaseUrl}/user/list/new?${query ? query : ""}`));

export const generateBillList = createAsyncThunk("generatebill/list", async (query) => await axios.get(`${BaseUrl}/user/list?${query ? query : ""}`));

export const employeeCount = createAsyncThunk("employee/count", async () => await axios.get(`${BaseUrl}/employee/list/count`));

export const subscriberSearch = createAsyncThunk("subscriber/search", async (query) => await axios.get(`${BaseUrl}/user/search?${query ? query : ""}`));

export const paidByList = createAsyncThunk("billing/paidby", async (distid) => await axios.get(`${BaseUrl}/transaction/collected/employee/list?${distid ? distid : ""}`));

export const lastPaid = createAsyncThunk("subscriber/paiddetail", async (query) => await axios.get(`${BaseUrl}/billing/user/last/paid?${query ? query : ""}`));

export const paymentAmount = createAsyncThunk("subscriber/makepayment", async (payload) => await axios.get(`${BaseUrl}/config/list?distributor_ref_id=${payload}&type=others`));

export const paymentTax = createAsyncThunk("subscriber/Tax", async (query) => await axios.get(`${BaseUrl}/tax/list?${query ? query : ""}&type=tax`));

export const payNow = createAsyncThunk("subscriber/payment", async (payload) => await axios.post(`${BaseUrl}/admin/plan/renewal`, payload));

export const resetPwd = createAsyncThunk("admin/changePassword/user", async (payload) => await axios.post(`${BaseUrl}/admin/changePassword/user`, payload));

export const resetPwdPopup = createAsyncThunk("admin/changePassword/employee", async (payload) => await axios.post(`${BaseUrl}/admin/changePassword/employee`, payload));

export const accountID = createAsyncThunk("custplan/search", async (query) => await axios.get(`${BaseUrl}/custplan/search?${query ? query : ""}`));

export const assignTo = createAsyncThunk("employee/search", async (query) => await axios.get(`${BaseUrl}/employee/search?${query ? query : ""}`));

export const paymentPlan = createAsyncThunk("subscriber/lastpaid", async () => await axios.get(`${BaseUrl}/custplan/list?cust_ref_id=5ca3a6a3975911425c56fb67`));

export const addOnServicesCreate = createAsyncThunk("subscriber/addOn-services-create", async (payload) => await axios.post(`${BaseUrl}/admin/addonservice/create`, payload));

export const channelList = createAsyncThunk("subscriber/channel", async () => await axios.get(`${BaseUrl}/admin/broadcaster`));

export const subscriberAddPlan = createAsyncThunk("subscriber/plan/add", async (payload) => await axios.post(`${BaseUrl}/plan/add`, payload));

export const subscriberPlanList = createAsyncThunk("plan/list", async (query) => await axios.get(`${BaseUrl}/plan/list?${query}`));

export const addSubscriber = createAsyncThunk("subscriber/add", async (payload) => await axios.post(`${BaseUrl}/user/add`, payload));

export const currentServiceList = createAsyncThunk("custplan/list", async (query) => await axios.get(`${BaseUrl}/custplan/list?cust_ref_id=${query}`));

export const ServiceListTable = createAsyncThunk("service/list", async (query) => await axios.get(`${BaseUrl}/custplan/list?cust_ref_id=${query}`));

export const currentServiceSTBList = createAsyncThunk("custserviceplan/list", async (query) => await axios.get(`${BaseUrl}/custplan/list?${query}`));

export const currentServiceActiveList = createAsyncThunk("admin/plan/update/status", async (payload) => await axios.post(`${BaseUrl}/admin/plan/update/status`, payload));

export const expenseList = createAsyncThunk("tickect/list", async (query) => await axios.get(`${BaseUrl}/expense/list?${query ? query : ""}`));

export const deleteExpense = createAsyncThunk("expense/delete", async (payload) => await axios.delete(`${BaseUrl}/expense/delete`, { data: payload }));

export const addOnServices = createAsyncThunk("subscriber/addOn-services", async (query) => await axios.get(`${BaseUrl}/admin/addonservice/list?${query ? query : ""}`));

export const deleteAddOnService = createAsyncThunk("subscriber/delete-addOn-service", async (payload) => await axios.delete(`${BaseUrl}/admin/addonservice/delete`, { data: payload }));

export const profileEdit = createAsyncThunk("admin/profile", async (payload) => await axios.patch(`${BaseUrl}/users`, payload));

export const profileEditDist = createAsyncThunk("admin/profile", async (payload) => await axios.post(`${BaseUrl}/distributor/update`, payload));

export const settingsPayment = createAsyncThunk("settings/payment", async (payload) => await axios.post(`${BaseUrl}/time/add/update`, payload));

export const updateBalance = createAsyncThunk("user/update", async (payload) => await axios.post(`${BaseUrl}/user/update`, payload));

export const agentWorkTime = createAsyncThunk("config/list", async (distributorId) => await axios.get(`${BaseUrl}/config/list?${distributorId}&type=time`));

export const agentWorkTimeUpdate = createAsyncThunk("time/add/update", async (payload) => await axios.post(`${BaseUrl}/time/add/update`, payload));

export const taxList = createAsyncThunk("tax/list", async (query) => await axios.get(`${BaseUrl}/tax/list?distributor_ref_id=${query ? query : ""}&type=tax`));

export const taxListUpdate = createAsyncThunk("time/add/update", async (payload) => await axios.post(`${BaseUrl}/time/add/update`, payload));

export const allPlans = createAsyncThunk("plans/all", async (query) => await axios.get(`${BaseUrl}/plan/list?${query ? query : ""}`));

export const addOnTv = createAsyncThunk("subscriber/add-on-tv", async (payload) => await axios.post(`${BaseUrl}/user/add/addontv`, payload));

export const emailTemplates = createAsyncThunk("settings/email-templates", async (query) => await axios.get(`${BaseUrl}/distributor/emailTemplate/list${query ? query : ""}`));

export const smsTemplates = createAsyncThunk("settings/sms-templates", async (query) => await axios.get(`${BaseUrl}/distributor/smsTemplate/list${query ? query : ""}`));

export const blukUpload = createAsyncThunk("settings/bulkupload", async (payload) => await axios.post(`${BaseUrl}/doc/upload/payment`, payload));

export const deleteAssignSubs = createAsyncThunk("expense/delete", async (payload) => await axios.delete(`${BaseUrl}/user/delete`, { data: payload }));

export const updateEmployee = createAsyncThunk("enquiry/update", async (payload) => await axios.post(`${BaseUrl}/employee/update`, payload));

export const assignSubscriberSubmit = createAsyncThunk("user/list/multiple/update", async (payload) => await axios.put(`${BaseUrl}/user/list/multiple/update`, payload));

export const ticketHistory = createAsyncThunk("ticket/history", async (query) => await axios.get(`${BaseUrl}/mb/user/ticket/list?${query ? query : ""}`));

export const customerPlanDetails = createAsyncThunk("customer/plan", async (payload) => await axios.post(`${BaseUrl}/mb/user/plan`, payload));

export const customerProfileDetails = createAsyncThunk("customer/details", async (payload) => await axios.post(`${BaseUrl}/mb/user/profile`, payload));

export const employeeActive = createAsyncThunk("distributor/active/employee", async (payload) => await axios.post(`${BaseUrl}/employee/activate/deactivate`, payload));

export const custProfileUpdate = createAsyncThunk("user/update", async (payload) => await axios.post(`${BaseUrl}/user/update`, payload));

export const addPlanList = createAsyncThunk("addPlan/customer", async (query) => await axios.get(`${BaseUrl}/mb/user/plan/list?${query ? query : ""}`));

export const imageUpload = createAsyncThunk("image/upload", async (payload) => await axios.post(`${BaseUrl}/mb/user/ticket/image/upload`, payload));

export const ticketRaiseAdd = createAsyncThunk("user/ticket/add", async (payload) => await axios.post(`${BaseUrl}/mb/user/ticket/add`, payload));

export const inventryList = createAsyncThunk("inventry/list", async (query) => await axios.get(`${BaseUrl}/inventory/list?${query ? query : ""}`));

export const deleteInventry = createAsyncThunk("inventryList/delete", async (payload) => await axios.delete(`${BaseUrl}/inventory/delete`, { data: payload }));

export const broadcastList = createAsyncThunk("addPlan/list", async (query) => await axios.get(`${BaseUrl}/mb/user/plan/list?cust_ref_id=61de8b8b7e4b3600124c7672&broadcaster=1${query ? query : ""}`));

export const areaList = createAsyncThunk("subscriber/area-list", async (query) => await axios.get(`${BaseUrl}/user/distinct/area?${query ? query : ""}`));

export const basicList = createAsyncThunk("addPlan/list", async (query) => await axios.get(`${BaseUrl}/mb/user/plan/list?cust_ref_id=5c9c56a00db8611eab84212a&basic=1${query ? query : ""}`));

export const subsPlanUpdate = createAsyncThunk("user/plan/update", async (payload) => await axios.post(`${BaseUrl}/plan/update`, payload));

export const profileimageUpload = createAsyncThunk("image/upload", async (payload) => await axios.post(`${BaseUrl}/user/upload/profile/pic?_id=${payload.custId}`, payload?.files));

export const HardwareList = createAsyncThunk("hardware/list", async (query) => await axios.get(`${BaseUrl}/inventory/user/list?${query ? query : ""}`));

export const adminAllPlans = createAsyncThunk("plans/admin-all-plans", async (query) => await axios.get(`${BaseUrl}/admin/alacart?${query ? query : ""}`));

export const customerPlanList = createAsyncThunk("plans/customer-plan-list", async (query) => await axios.get(`${BaseUrl}/customer/plan/list?${query ? query : ""}`));

export const SubscriberListAssigned = createAsyncThunk("assign/subscriber/list", async (query) => await axios.get(`${BaseUrl}/user/list?${query ? query : ""}`));

export const SubscriberListMultipleDelete = createAsyncThunk("subscriber/list/multiple/delete", async (payload) => await axios.put(`${BaseUrl}/user/list/multiple/update?sms_access=${user?.sms_access}&whatsapp_access=${user?.whatsapp_access},`, payload));

export const paymentHistoryList = createAsyncThunk("transaction/list", async (query) => await axios.get(`${BaseUrl}/transaction/list?${query ? query : ""}`));

export const paymentPlanList = createAsyncThunk("payment/plan", async () => await axios.get(`${BaseUrl}/custplan/list?cust_ref_id=5ca3a6a3975911425c56fb64`));

export const generateBillAdd = createAsyncThunk("bill/generate", async (payload) => await axios.post(`${BaseUrl}/generate/bulk/bill`, payload));

export const subscriberView = createAsyncThunk("view/list", async (id) => await axios.get(`${BaseUrl}/user/console?id=${id}`));

export const allEmpList = createAsyncThunk("distributor/all-emp", async (query) => await axios.get(`${BaseUrl}/employee/all/list?${query ? query : ""}`));

export const userAccountStatus = createAsyncThunk("user/account/activate", async (payload) => await axios.post(`${BaseUrl}/user/account/activate`, payload));

export const getExpenses = createAsyncThunk("/dashboard/expense/amount", async (query) => await axios.get(`${BaseUrl}/dashboard/expense/amount?${query ? query : ""}`));

export const getEmployeeCount = createAsyncThunk("/employee/list/count", async (query) => await axios.get(`${BaseUrl}/employee/list/count?${query ? query : ""}`));

export const broadcastCreate = createAsyncThunk("addpack/create", async (payload) => await axios.post(`${BaseUrl}/admin/addon/create`, payload));

export const broadcastCreatemultiple = createAsyncThunk("addpack/create/multiple", async (payload) => await axios.post(`${BaseUrl}/admin/addon/multiple/create`, payload));

export const bulkUploadSave = createAsyncThunk("save/bulkupload", async (payload) => await axios.post(`${BaseUrl}/user/multiple/add`, payload));

export const SubscriberListMultiplePermanentDelete = createAsyncThunk("subscriber/list/permanent/delete", async (payload) => await axios.delete(`${BaseUrl}/user/permanent/delete?sms_access=${payload?.sms_access}&whatsapp_access=${payload?.whatsapp_access}`, { data: payload }));

export const bulkUploadSaveValidation = createAsyncThunk("save/bulkuploadValidation", async (payload) => await axios.post(`${BaseUrl}/user/multiple/validation `, payload));

export const getDistributorCount = createAsyncThunk("/dis/list/count", async (query) => await axios.get(`${BaseUrl}/distributor/list/count?${query ? query : ""}`));

export const stbUpdate = createAsyncThunk("stbupdate/addon", async (payload) => await axios.post(`${BaseUrl}/user/update/stb_no`, payload));

export const deleteAddonTv = createAsyncThunk("Addon/delete", async (payload) => await axios.delete(`${BaseUrl}/user/stb_no/delete`, { data: payload }));

export const PayNowStatusChange = createAsyncThunk("user/update/paynowstatusChange", async (payload) => await axios.post(`${BaseUrl}/user/update`, payload));

export const disprofileimageUpload = createAsyncThunk("upload/distributor/photos", async (payload) => await axios.post(`${BaseUrl}/upload/distributor/photos?type=profile_url&id=${payload.disId}`, payload?.file));

export const getInventoryList = createAsyncThunk("/inventory/list", async (query) => await axios.get(`${BaseUrl}/inventory/all/list?${query ? query : ""}`));

export const RemoveBill = createAsyncThunk("user/remove/bill", async (payload) => await axios.post(`${BaseUrl}/generate/remove/bill`, payload));

export const RemoveBillUpdatecall = createAsyncThunk("user/update/bill", async (payload) => await axios.post(`${BaseUrl}/generate/bulk/bill/billing/state`, payload));

export const bulkUploadAddOnValid = createAsyncThunk("bulkupload/addOn", async (payload) => await axios.post(`${BaseUrl}/user/addontv/validation `, payload));

export const bulkUploadAddOnSave = createAsyncThunk("save/bulkuploadAddOn", async (payload) => await axios.post(`${BaseUrl}/user/add/multiple/addontv`, payload));

export const changePassword = createAsyncThunk("profile/password", async (payload) => await axios.post(`${BaseUrl}/changePassword`, payload));

export const inventoryAdd = createAsyncThunk("inventory/add", async (payload) => await axios.post(`${BaseUrl}/inventory/add`, payload));

export const inventoryUpdate = createAsyncThunk("inventory/update", async (payload) => await axios.put(`${BaseUrl}/inventory/update`, payload));

export const subscriberPlanListNew = createAsyncThunk("customer/plan/list/count", async (query) => await axios.get(`${BaseUrl}/customer/plan/list/count?${query}`));

export const collectionListNew = createAsyncThunk("transaction/report/list/count", async (query) => await axios.get(`${BaseUrl}/transaction/report/list/count?${query ? query : ""}`));

export const chatList = createAsyncThunk("chat/list", async (query) => await axios.get(`${BaseUrl}/chat/list?${query ? query : ""}`));

export const chatSave = createAsyncThunk("ticket/reply", async (payload) => await axios.post(`${BaseUrl}/ticket/reply`, payload));

export const notiPayment = createAsyncThunk("billing/plan/renwal/list", async (query) => await axios.get(`${BaseUrl}/billing/plan/renwal/list?${query ? query : ""}`));

export const expensesUpdate = createAsyncThunk("expenses/update", async (payload) => await axios.post(`${BaseUrl}/expense/update`, payload));

export const notiTicketUpdate = createAsyncThunk("ticket/update/notification", async (payload) => await axios.post(`${BaseUrl}/ticket/update`, payload));

export const notiPaymentUpdate = createAsyncThunk("billing/update/renewal/status", async (payload) => await axios.post(`${BaseUrl}/billing/update/renewal/status`, payload));

export const disprofileimageUploadAdmin = createAsyncThunk("upload/distributor/photos", async (payload) => await axios.post(`${BaseUrl}/upload/profile/pic?_id=${payload.id}`, payload?.file));

export const distributorHome = createAsyncThunk("/distributor/home", async (query) => await axios.get(`${BaseUrl}/admin/distributors/profile`));

export const resetPwdDist = createAsyncThunk("admin/changePassword/distributor", async (payload) => await axios.post(`${BaseUrl}/admin/changePassword/distributor`, payload));

export const CurrentServiceDelete = createAsyncThunk("plan/delete", async (payload) => await axios.delete(`${BaseUrl}/delete/user/plans`, { data: payload }));

export const invoicePdf = createAsyncThunk("invoice/pdf/", async (query) => await axios.get(`${BaseUrl}/transaction/cust/statement?${query ? query : ""}`));

export const bulkFileUpload = createAsyncThunk("image/upload/cable", async (payload) => await axios.post(`${BaseUrl}/doc/upload/customer?flag=customer&sms_access=${payload?.sms_access}&whatsapp_access=${payload?.whatsapp_access}&distributor_ref_id=${payload?.distributor_ref_id}`, payload?.file));

export const broadcasterAdmin = createAsyncThunk("broadcaster/admin/", async () => await axios.get(`${BaseUrl}/plan/distinct/broadcaster?package_category=broadcaster`));

export const inventoryMultipleUpdate = createAsyncThunk("inventory/multi/update", async (payload) => await axios.put(`${BaseUrl}/inventory/multiple/update`, payload));

export const paymentSummaryDownload = createAsyncThunk(
  "/transaction/report/",
  async (payload) =>
    await axios.get(
      `${BaseUrl}/transaction/report?fromdate=${payload?.date.todate}&billing_type=${payload.billingType}&service_type=${payload?.serviceType}&todate=${payload?.date.fromdate}&payment_status=${payload?.payment_status}${payload?.distributor_id ? `&distributor_ref_id=${payload?.distributor_id}` : ""}`
    )
);

export const paymentSummaryTranscDownload = createAsyncThunk(
  "/transaction/report/",
  async (payload) => await axios.get(`${BaseUrl}/plan/expireing/report?fromdate=${payload?.date.todate}&billing_type=${payload.billingType}&service_type=${payload?.serviceType}&todate=${payload?.date.fromdate}${payload?.distributor_id ? `&distributor_ref_id=${payload?.distributor_id}` : ""}`)
);

export const subExport = createAsyncThunk("user/all/report/list", async (query) => await axios.get(`${BaseUrl}/user/all/report/list?${query ? query : ""}`));

export const subDelExport = createAsyncThunk("user/all/del/list", async (query) => await axios.get(`${BaseUrl}/user/all/report/list?${query ? query : ""}`));


export const invoiceDownloadExport = createAsyncThunk("user/payment/invoice", async (query) => await axios.get(`${BaseUrl}/user/payment/invoice?_id=${query._id}`));

export const billingListExport = createAsyncThunk("transaction/all/report/list", async (query) => await axios.get(`${BaseUrl}/transaction/all/report/list?${query ? query : ""}`));

export const inventoryMultipleAdd = createAsyncThunk("inventory/multiple/add", async (payload) => await axios.post(`${BaseUrl}/inventory/multiple/add?multiStbAdd=1`, payload));

export const getBroadcaster = createAsyncThunk("plan/distinct/broadcaster", async (query) => await axios.get(`${BaseUrl}/plan/distinct/broadcaster`));

export const planListsExport = createAsyncThunk("admin/plan/report/list", async (query) => await axios.get(`${BaseUrl}/admin/plan/report/list?${query ? query : ""}`));

export const bulkFileAddOnUpload = createAsyncThunk("image/addon/upload/bulk", async (payload) => await axios.post(`${BaseUrl}/doc/upload/addon?flag=addon`, payload?.file));

export const bulkFilePaymentUpload = createAsyncThunk("image/payment/upload", async (payload) => await axios.post(`${BaseUrl}/doc/upload/payment?flag=payment`, payload?.file));

export const othersAll = createAsyncThunk("others/all", async (payload) => await axios.get(`${BaseUrl}/config/list/all?distributor_ref_id=${payload}&type=others`));

export const settingsPaymentGet = createAsyncThunk("payment/get", async (payload) => await axios.get(`${BaseUrl}/config/list?distributor_ref_id=${payload}&type=payment`));

export const ticketAllList = createAsyncThunk("tickect/all/list", async (query) => await axios.get(`${BaseUrl}/ticket/all/list?${query ? query : ""}&complaint_status=open`));

export const deleteRceipt = createAsyncThunk("receipt/delete", async (payload) => await axios.delete(`${BaseUrl}/transaction/delete`, { data: payload }));

export const SubscriberplanListsExport = createAsyncThunk("subscriber/plan/list/export", async (query) => await axios.get(`${BaseUrl}/customer/plan/report/list?${query ? query : ""}`));

export const validityRenewal = createAsyncThunk("validity/renewal", async (payload) => await axios.post(`${BaseUrl}/distributor/update?validity=1`, payload));

export const smsType = createAsyncThunk("sms/type/settings", async (query) => await axios.get(`${BaseUrl}/distributor/smsTemplate/list?${query ? query : ""}`));

export const smsArea = createAsyncThunk("settings/area/sms", async (query) => await axios.get(`${BaseUrl}/cust/area?${query ? query : ""}`));

export const bulkSend = createAsyncThunk("sms/send", async (payload) => await axios.post(`${BaseUrl}/send/bulkmail`, payload));

export const bulkEmail = createAsyncThunk("email/send", async (payload) => await axios.post(`${BaseUrl}/send/user/mail`, payload));

export const currentServiceUpdate = createAsyncThunk("plan/customer/update", async (payload) => await axios.post(`${BaseUrl}/customer/plan/update`, payload));

export const singleSms = createAsyncThunk("sms/single/send", async (payload) => await axios.post(`${BaseUrl}/send/user/sms`, payload));

export const adminPackageOnly = createAsyncThunk("plans/packageonly", async (query) => await axios.get(`${BaseUrl}/admin/alacart?package_category=package&${query ? query : ""}`));

export const CollectionAgentList = createAsyncThunk("collection/list", async (query) => await axios.get(`${BaseUrl}/dashboard/agent/connection/payment/history?${query ? query : ""}`));

export const CollectionStatus = createAsyncThunk("collection/status", async (payload) => await axios.get(`${BaseUrl}/ticket/count?distributor_ref_id=${payload?.distributor_id}`));

export const bulkFileBroadcastUpload = createAsyncThunk("broadcast/upload", async (payload) => await axios.post(`${BaseUrl}/doc/upload/broadcaster`, payload?.file));

export const othersAllService = createAsyncThunk("others/all/service", async (payload) => await axios.get(`${BaseUrl}/config/list/all?distributor_ref_id=${payload}&type=others&service_type=cable`));

export const othersAllInternet = createAsyncThunk("others/internet/service", async (payload) => await axios.get(`${BaseUrl}/config/list/all?distributor_ref_id=${payload}&type=others&service_type=internet`));

export const paymentGateway = createAsyncThunk("customer/payment", async (payload) => (
  await axios.post(`${BaseUrl}/subscriber/payment`, payload)
)
);

export const paymentGatewayRedirectApi = createAsyncThunk("customer/redirect", async (payload) => (
  await axios.post(`${BaseUrl}/subscriber/payment/status`, payload, {headers: {"clientid" : payment?.api_key, "secretid" : payment?.secret_key, "version": "2022-01-01" }})
)
);

export const payNowCustomer = createAsyncThunk("customer/paymentGateway", async (payload) => await axios.post(`${BaseUrl}/subscriber/plan/renewal`, payload));

export const outstandingList = createAsyncThunk("outstanding/list", async (query) => await axios.get(`${BaseUrl}/user/outstanding/list?${query ? query : ""}`));

export const outstandingListExport = createAsyncThunk("outstanding/list/export", async (query) => await axios.get(`${BaseUrl}/user/outstanding/list/report?${query ? query : ""}`));


export const PlanMultipleDelete = createAsyncThunk("plan/list/admin/delete", async (payload) => await axios.delete(`${BaseUrl}/plan/permanent/delete`, { data: payload }));


export const AllSubscribersDelete = createAsyncThunk("subscriber/delete/All", async (payload) => await axios.delete(`${BaseUrl}/user/bulkdelete`, { data: payload }));

export const getWhatsappList = createAsyncThunk("outstanding/list", async (query) => await axios.get(`${BaseUrl}/distributor/whatsappTemplate/list`));

export const whatsappMessageSend = createAsyncThunk("whatsapp/message", async (payload) => await axios.post(`${BaseUrl}/send/user/whatsapp`, payload));

export const whatsappMessageSendBulk = createAsyncThunk("customer/paymentGateway", async (payload) => await axios.post(`${BaseUrl}/send/bulkwhatsappmsg`, payload));

