import "./styles.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import SearchWithButtons from "@components/SearchWithButtons";
import { useNavigate } from "react-router-dom";
import { useComponentDidMount, useComponentDidUpdate, useComponentWillUnmount } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { generateBillList, generateBillAdd } from "@reducers/api";
import { useState } from "react";
import Pagination from "@mui/material/Pagination";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/billing";
import PaymentModal from "@components/Modals/makePayment";
import BreadCrumb from "@components/Breadcrumb";
import Grid from "@mui/material/Grid";

export default function GenerateBill() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { generateBillData, generateBillAdded } = useSelector((state) => state.billing);
  const { payNowAdded } = useSelector((state) => state.subscriber);
  const [generatebillList, setgeneratebillList] = useState([]);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [show, setShow] = useState(false);

  const onShowPopUp = () => {
    setShow(!show);
    dispatch(reset());
  };


  useComponentDidMount(() => {
    let query = queryGenerator();

    dispatch(generateBillList(query));
  }, []);

  useComponentDidUpdate(() => {
    let query = queryGenerator();

    if (generateBillAdded) {
      enqueueSnackbar("Bill Generated !", {
        variant: "success",
      });

      dispatch(generateBillList(query));
      dispatch(reset());
    }
  }, [generateBillAdded]);

  useComponentWillUnmount(() => {
    dispatch(reset());
  });

  useComponentDidUpdate(() => {
    let list = [];
    generateBillData?.data?.data && generateBillData?.data?.data.map((data, index) => {
      list.push({
        sNo: (page - 1) * 10 + index + 1,
        customerName: data?.name,
        accountid: data?.account_id,
        msqid: data?.mso_id,
        customerEmail: data?.email_id,
        area: data?.area,
        flatNo: data?.flat_no,
        status: <div className="status-btn-table-active"> Active </div>,
        billing_initiated: data?.billing_initiated,
        is_active: data?.is_active,
        id: data?._id
      });
    });

    setgeneratebillList(list);
  }, [generateBillData]);

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&billing_initiated=false&is_active=true`;
    if (value?.type === "search") {
      query += `&limit=10&offset=0&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };


  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    let query = queryGenerator({ type: "search", state: search });
    dispatch(generateBillList(query));
  };

  const handleChange = (value) => {
    let query = queryGenerator({ type: "offset", state: value });
    dispatch(generateBillList(query));
  };

  const handleCheckBox = (index, checked) => {
    const clonedDataService = [...generatebillList];
    if (index === 0 || index > 0) {
      clonedDataService[index].checked = clonedDataService[index].checked
        ? false
        : true;
    } else if (index === "all") {
      for (let services of clonedDataService) {
        if (checked) {
          services.checked = true;
        } else {
          services.checked = false;
        }
      }
    }

    setgeneratebillList(clonedDataService);
  };

  const handleBillGenerate = () => {

    let filtered = generatebillList?.filter((serviceList) => serviceList.checked === true);

    if(filtered.length==0){
      enqueueSnackbar("Please select atlease one to generate!", {
        variant: "error",
      }); 
      return;
    }

    let payload = {
      selected_cust: {}
    }

    for (let e of filtered) {
      Object.assign(payload.selected_cust, { [e.id]: true });
    }
    dispatch(generateBillAdd(payload));
  };

  useComponentDidUpdate(() => {
    if (payNowAdded) {
      enqueueSnackbar("Payment Success !", {
        variant: "success",
      });
      dispatch(reset());
      setShow(false);
    }
  }, [payNowAdded]);

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };


  return (
    <div className="generateBill-container ticketList-container">
      <PaymentModal show={show} showAccountId={true} handleClose={onShowPopUp} />
      <Grid container spacing={2}>
          <Grid item xs={2} md={8}>
            
          </Grid>
          <Grid item xs={10} md={4} className="flex-end">
            <div className='genrateBill-title'>
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              secondaryHeading="Receipt List"
              handleSecondaryClick={() => handleBreadCrumbRedirect("receipt-list")}
              activeHeading="Generate Bill"
              handleActiveClick={() => handleBreadCrumbRedirect("generate-bill")}
            />
            </div>
           
          </Grid>
        </Grid>
      <div className="ticket-list-table-cntr">
      <div className="genrateBill-title">Generate Bill</div>
      <div className="search-btns-padding">
        <SearchWithButtons
          primaryButton={{
            title: "Generate Bill",
            type: "outlined",
            color: COLORS.primary,
          }}
       
          handlePrimaryClick={handleBillGenerate}
          handleSecondaryClick={onShowPopUp}
          handleChange={handleSearch}
        />
      </div>
      <div style={{ fontWeight: 'bold', fontSize: '15px',paddingBottom: '10px'}}>Showing entries { (page - 1) * 10 + 1 } - {Math.ceil(generateBillData?.data?.totalCount / 10)==page?generateBillData?.data?.totalCount:page+"0"} of {generateBillData?.data?.totalCount}</div>  

      <TableView
        rows={tableRows}
        data={generatebillList}
        color={COLORS.primary}
        handleCheckBoxChange={handleCheckBox}
      />
      <div style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}>
        <Pagination
          count={Math.ceil(generateBillData?.data?.totalCount / 10) || 0}
          defaultPage={1}
          page={page}
          onChange={(event, value) => {
            setPage(value);
            handleChange(value);
          }}
          variant="outlined"
          shape="rounded"
        />
      </div>
      </div>
    </div>
  );
}
