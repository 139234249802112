import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import "./styles.scss";
import { styled } from "@mui/material/styles";

import Button from "@mui/material/Button";
import { useComponentDidUpdate, useComponentDidMount } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { customerProfileDetails, paymentGateway, currentServiceList, settingsPaymentGet, paymentTax, othersAll, lastPaid, payNowCustomer } from "@reducers/api";
import {
    paramsQuery
} from "@utils/useEffectHooks";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/customer";
import Resizer from "react-image-file-resizer";
import CancelIcon from '@mui/icons-material/Cancel';
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import Autocomplete from "@mui/material/Autocomplete";
import { useForm, Controller } from "react-hook-form";
import { tableRowsCustomer } from "./data";
import TableView from "@components/TableView";
import { COLORS } from "@constants";
import moment from "moment";


export default function CustomerPaymentPopup(props) {


    const dispatch = useDispatch();
    const [currentService, setcurrentService] = useState([]);
    const [payloadPlan, setpayloadPlan] = useState([]);
    const [checkedVal, setChecked] = useState(false);
    const [stbNumber, setStbNumber] = useState(false);


    const { enqueueSnackbar } = useSnackbar();
    const {
        subviewData,
        PlanPaymentListData,
        paymentTaxData,
        otherAllData,
        lastPaidData,
        settingsPaymentData,
    } = useSelector((state) => state.subscriber);

    const { profileData, paymentGatewayData, paymentGatewayRedirectData } = useSelector((state) => state.customer);

    console.log(paymentGatewayData, "profileeee")

    useComponentDidMount(() => {
        let user = JSON.parse(localStorage.getItem("user"));

        let paymentUrl = JSON.parse(localStorage.getItem("paymentGateway"));
        let query = `distributor_ref_id=${user?.distributor_ref_id}`;
        let othersQuery = `${user?.distributor_ref_id}&service_type=${profileData?.customerData?.service_type}`;
        dispatch(paymentTax(query));
        dispatch(othersAll(othersQuery));

        let paidquery = `cust_ref_id=${profileData?.customerData?._id}`;
        dispatch(lastPaid(paidquery));
        let distributorId = user?.distributor_ref_id;
        dispatch(settingsPaymentGet(distributorId));
        if (profileData?.customerData?.service_type === "internet") {

            let custIds = profileData?.customerData?._id;
            let querys = `${custIds}&is_active=1`;
            dispatch(currentServiceList(querys));
        }


    }, []);

    useComponentDidUpdate(() => {

        if (paymentGatewayData?.payment_link) {
            window.location.replace(paymentGatewayData?.payment_link)
        }


    }, [paymentGatewayData]);

    console.log(paymentGatewayData, "paymentLink")





    const [open, setOpen] = useState(false);

    const handleClose = () => {
        props?.handleClose();
    };

    useEffect(() => {
        setOpen(props?.show);
    }, [props?.show]);

    let user = JSON.parse(localStorage.getItem("user"));
    let custId = user?.cust_ref_id;

    const handleCheckBox = (index, checked) => {
        const clonedDataService = [...currentService];
        if (index === 0 || index > 0) {
            clonedDataService[index].checked = clonedDataService[index].checked
                ? false
                : true;
        } else if (index === "all") {
            for (let services of clonedDataService) {
                if (checked) {
                    services.checked = true;

                } else {
                    services.checked = false;

                }
            }
        }
        setChecked(checked)
        setcurrentService(clonedDataService);
    }



    let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
    );


    let sumAmount = filteredarray.reduce(function (sum, current) {
        return sum + current.plan_amount;
    }, 0);



    let checkedPlanList = payloadPlan.filter(o1 => filteredarray.some(o2 => o1.plan_name === o2.plan_name));

    let filteredBroadcast = checkedPlanList?.filter(
        (serviceList) => serviceList.package_category === "broadcaster"
    );

    let broadcastCount = filteredBroadcast.reduce(function (prev, current) {
        return prev + +current.plan_amount;
    }, 0);

    let filteredPackage = checkedPlanList?.filter(
        (serviceList) => serviceList.package_category === "package"
    );

    let packageCount = filteredPackage.reduce(function (prev, current) {
        return prev + +current.plan_amount;
    }, 0);

    let filteredAlaCart = checkedPlanList?.filter(
        (serviceList) => serviceList.package_category === "ala cart"
    );

    let alaCarCount = filteredAlaCart.reduce(function (prev, current) {
        return prev + +current.plan_amount;
    }, 0);

    let channalCount = checkedPlanList.reduce(function (prev, current) {
        return prev + +current.channel_count;
    }, 0);

    let PlanListTax = checkedPlanList.reduce(function (sum, current) {
        return sum + current.plan_amount;
    }, 0);

    let TaxValue = Math.round(PlanListTax * (paymentTaxData?.totalTax / 100))


    useComponentDidUpdate(() => {
        let list = [];
        let listPayload = [];
        PlanPaymentListData &&
            PlanPaymentListData.map((data, index) => {
                list.push({
                    plan_name: data?.plan_name,
                    package_category: data?.package_category,
                    channel_count: (data?.channels && data?.channels.length) || data?.channel_count || "0",
                    plan_amount: data?.package_category === "package" ? Math.round((data?.plan_amount * (paymentTaxData?.totalTax / 100) + data?.plan_amount) + (otherAllData[0]?.maintenance_amount)) : Math.round((data?.plan_amount * (paymentTaxData?.totalTax / 100) + data?.plan_amount)),
                    expired_date: moment(data?.expired_date).format("ll"),
                    stb_no: data?.stb_no,
                    activate_date: data?.activate_date,
                    billing_cycle: data?.billing_cycle,
                    billing_type: data?.billing_type,
                    can_id: data?.can_id,
                    channels: data?.channels,
                    cust_email_id: data?.cust_email_id,
                    cust_ref_id: data?.cust_ref_id,
                    disabled: data?.disabled,
                    distributor_ref_id: data?.distributor_ref_id,
                    cust_name: data?.cust_name,
                    is_active: data?.is_active,
                    is_deleted: data?.is_deleted,
                    mso: data?.mso,
                    plan_ref_id: data?.plan_ref_id,
                    plan_validity: data?.plan_validity,
                    service_type: data?.service_type,
                    stb_type: data?.stb_type,
                    _id: data?._id,
                    __v: data?.__v,
                });

                listPayload.push({
                    plan_name: data?.plan_name,
                    package_category: data?.package_category,
                    channel_count: (data?.channels && data?.channels.length) || data?.channel_count || "0",
                    plan_amount: data?.plan_amount,
                    expired_date: moment(data?.expired_date).format("ll"),
                    stb_no: data?.stb_no,
                    activate_date: data?.activate_date,
                    billing_cycle: data?.billing_cycle,
                    billing_type: data?.billing_type,
                    can_id: data?.can_id,
                    channels: data?.channels,
                    cust_email_id: data?.cust_email_id,
                    cust_ref_id: data?.cust_ref_id,
                    disabled: data?.disabled,
                    distributor_ref_id: data?.distributor_ref_id,
                    cust_name: data?.cust_name,
                    is_active: data?.is_active,
                    is_deleted: data?.is_deleted,
                    mso: data?.mso,
                    plan_ref_id: data?.plan_ref_id,
                    plan_validity: data?.plan_validity,
                    service_type: data?.service_type,
                    stb_type: data?.stb_type,
                    _id: data?._id,
                    __v: data?.__v,
                });
            });

        setcurrentService(list);
        setpayloadPlan(listPayload)
    }, [PlanPaymentListData, paymentTaxData, otherAllData]);


    const handleFormValues = (values) => {
        let id = paramsQuery("id");
        let user = JSON.parse(localStorage.getItem("user"));
        let orderId = "order_" + new Date().getTime();
        let payload = {
            "order_id": orderId,
            "distributor_ref_id": user?.distributor_ref_id,
            "order_amount": TotalAmount,
            "order_currency": "INR",
            "order_expiry_time": travelTime,
            "order_note": "Customer Payment",
            "customer_details": {
                "customer_id": profileData?.customerData?._id,
                "customer_name": profileData?.customerData?.name,
                "customer_email": profileData?.customerData?.email_id,
                "customer_phone": profileData?.customerData?.mobile_no
            },
            "order_meta": {
                "return_url": window.location.protocol + "//" + window.location.host + "/customer/dashboard?order_id={order_id}&order_token={order_token}"
            }
        };
        if (profileData?.customerData?.email_id || profileData?.customerData?.mobile_no) {
            dispatch(paymentGateway(payload));
        }

        let payloadpay = {
            cust_plan_ref_id: checkedPlanList,
            bill_amount: TotalAmount,
            total_plan_amount: sumAmount,
            maintenance_amount: otherAllData[0]?.maintenance_amount,
            channel_count: channalCount,
            discount_amount: 0,
            stb_no: stbNumber || "",
            collected_by: profileData?.associatedEmployeeData?.name,
            collection_amount: TotalAmount,
            collected_date: new Date(),
            due_amount: 0,
            advance_amount: 0,
            add_on_service_amount: 0,
            show_bill_amount: TotalAmount,
            // tax: { "GST 1": values.tax },

            cust_ref_id: {
                _id: profileData?.customerData?._id,
                name: profileData?.customerData?.name,
                email_id: profileData?.customerData?.email_id,
            },
            invoice_id: orderId,
            transaction_id: orderId,
            order_id: orderId,
            payment_type: "online",
            distributor_ref_id: profileData?.associatedDistributorData?._id,
            package_amount: packageCount,
            broadcaster_amount: broadcastCount,
            ala_cart_amount: alaCarCount,
            billing_type: profileData?.customerData?.billing_type,
            service_type: profileData?.customerData?.service_type,
            payby_id: profileData?.customerData?._id,
            remarks: "",
            ncf_amount: Math.round(ncfPay) || 0,
            sms_access: profileData?.associatedDistributorData?.sms_access,
            last_advance_amount: lastPaidData?.advance_amount || 0,
            last_due_amount: lastPaidData?.due_amount || 0,
            primaryStbNo: profileData?.customerData?.stb_no[0]?.stb_no,
            payment_status: "pending"
        };
        dispatch(payNowCustomer(payloadpay));


    };

    var travelTime = moment().add(2, 'hours').toISOString()

    let payloadNcf = filteredBroadcast?.length >= 1 || filteredAlaCart?.length >= 1 ? otherAllData && otherAllData[0]?.ncf_amount : 0


    let ncfAmount = filteredBroadcast?.length >= 1 || filteredAlaCart?.length >= 1 ? (otherAllData && otherAllData[0]?.ncf_amount + (otherAllData && otherAllData[0]?.ncf_amount * paymentTaxData?.totalTax / 100)) : 0

    let dueAmt = lastPaidData?.due_amount || 0

    let advAmt = lastPaidData?.advance_amount || 0

    let broadCastAndAlacartFilter = filteredarray?.filter(
        (serviceList) =>
            serviceList.package_category === "broadcaster" ||
            serviceList.package_category === "ala cart"
    );

    let channelCountAdditionNcf = broadCastAndAlacartFilter.reduce(function (
        prev,
        current
    ) {
        return prev + +current.channel_count;
    },
        0);

    let ncfCount = ncfAmount
    let ncfPay = payloadNcf

    if (channelCountAdditionNcf >= 25) {
        ncfCount = ncfAmount * 2;
        ncfPay = payloadNcf * 2;

    }

    if (channelCountAdditionNcf >= 50) {
        ncfCount = ncfAmount * 3;
        ncfPay = payloadNcf * 3;
    }

    if (channelCountAdditionNcf >= 75) {
        ncfCount = ncfAmount * 4;
        ncfPay = payloadNcf * 4;
    }

    if (channelCountAdditionNcf >= 100) {
        ncfCount = ncfAmount * 5;
        ncfPay = payloadNcf * 5;
    }
    if (channelCountAdditionNcf >= 125) {
        ncfCount = ncfAmount * 6;
        ncfPay = payloadNcf * 6;
    }

    if (channelCountAdditionNcf >= 150) {
        ncfCount = ncfAmount * 7;
        ncfPay = payloadNcf * 7;
    }

    let TotalAmount = (sumAmount + Math.round(ncfCount) + dueAmt) - (advAmt)

    console.log(window.location.protocol + "//" + window.location.host, "ncfbroad")

    return (
        <div>
            <div className="header-raise-title justify-space-between flex-row " >
                <div className="planHeading">Payment</div>
                <div onClick={handleClose} className="cursor-pointer">{<CancelIcon />}</div>
            </div>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles">
                {profileData?.customerData?.service_type === "cable" && (

                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <label htmlFor="lastName" className="createForm">
                                Select STB
                            </label>
                            <Autocomplete
                                style={{ marginTop: '8px' }}
                                onChange={(event, item) => {
                                    let query = `${custId}&stb_no=${item?.stb_no}&is_active=1`;
                                    dispatch(currentServiceList(query));
                                    setStbNumber(item?.stb_no)
                                }}
                                options={props?.stbNumber || []}
                                isOptionEqualToValue={(option, value) => option.stb_no == value.stb_no || ""}
                                getOptionLabel={(item) => (item.stb_no ? item.stb_no : "")}
                                getOptionSelected={(option, value) => value === undefined || value === "" || option.stb_no === value.stb_no}
                                renderInput={(params) => <TextField {...params} size="small" placeholder="Search by STB" />}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button size="small" variant="outlined" className="btn-outlined"
                                onClick={() => {
                                  
                                    let query = `${custId}&is_active=1`;
                                    dispatch(currentServiceList(query));
                                }}
                                style={{ textTransform: "none", color: "#00249b", marginTop: '2rem' }}>
                                Show All STB
                            </Button>
                        </Grid>

                    </Grid>


                )
                }
            </Grid>
            {currentService ? (
                <div style={{ marginTop: '1rem' }}>

                    <TableView
                        rows={tableRowsCustomer}
                        data={currentService}
                        color={COLORS.primary}
                        handleCheckBoxChange={handleCheckBox}
                        checkedVal={checkedVal}
                    />

                </div>
            ) : ""}

            < div style={{ marginTop: '1rem' }} className='floatEnd'>
                <div >
                    <p>Total Plan Amount : {sumAmount}</p>
                    <p>Ncf Amount : {Math.round(ncfCount) || 0}</p>
                    <p>Advance Amount : {lastPaidData?.advance_amount || 0}</p>
                    <p>Due Amount : {lastPaidData?.due_amount || 0}</p>
                    <p>Total Amount: {(sumAmount + Math.round(ncfCount) + dueAmt) - advAmt} </p>

                </div>
                <div style={{ textAlign: 'center' }}>
                    <Button
                        size="small"
                        variant="contained"
                        style={{ textTransform: "capitalize" }}
                        className="bg-blue"
                        onClick={() => handleFormValues()}
                    >
                        Pay Now
                    </Button>
                </div>
            </div>





        </div >
    );
}
