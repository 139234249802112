import AddTicketForm from "@components/AddTicketForm";
import Grid from "@mui/material/Grid";
import TicketStatus from "@components/TicketStatus";
import "./styles.scss";
import { ticketList, updateTicket, addTicket,ticketOpenStatus, allEmpList} from "@reducers/api";
import { reset } from "@reducers/ticketing";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useComponentDidMount, paramsQuery, useComponentDidUpdate, useComponentWillUnmount } from "@utils/useEffectHooks";
import BreadCrumb from "@components/Breadcrumb";

const ticketStatusData = [
  { id: 1, title: "Open Tickets", count: 0, icon:"" },
  { id: 2, title: "Closed Tickets", count: 2, icon: "" },
  { id: 3, title: "Pending Tickets", count: 2, icon: "" },
  { id: 4, title: "InProgress Tickets", count: 1, icon: "" },
  { id: 5, title: "Resolved Tickets", count: 5, icon: "" },
];

export default function AddTicket(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  let user = JSON.parse(localStorage.getItem("user"));
  let accountId = user?.account_id;
  const { ticketListData, ticketAdded,ticketStatusOpenData } = useSelector((state) => state.ticketing);
  const { empFullData } = useSelector((state) => state.employee);
  let filtered = ticketListData?.data && ticketListData?.data.filter((data) => data._id === paramsQuery("id"));
  const defaultPlanQuery = {
    serviceType: "cable",
    billingType: "",
  };

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;

    return query;
  };

  useComponentDidMount(() => {
    let id = paramsQuery("id");
    let user = JSON.parse(localStorage.getItem("user"));
    defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
    dispatch(ticketOpenStatus(defaultPlanQuery));
    let query = queryGenerator();
    dispatch(allEmpList(query));
    if (id) return     
    dispatch(ticketList());
  }, []);

  useComponentDidUpdate(() => {
    let id = paramsQuery("id");
    let message = "";
    if (ticketAdded) {
      if (id) {
        message = "Ticket Updated";
      } else {
        message = "Ticket Added";
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/distributor/ticket-list`);
    }
  }, [ticketAdded]);

  useComponentWillUnmount(() => {
    dispatch(reset());
  });  
  
 
  const handleFormValues = (values) => {
    let id = paramsQuery("id");
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      "complaint_track": [],
      "user_address":values?.accountDetails?.area,
      "service_type": values.accountId?.service_type,
      "user_mobile_no": values?.accountId?.mobile_no,
      "email_id": values?.accountId?.email_id,
      "user_name": values?.accountId?.name,
      "distributor_ref_id": user?.distributor_ref_id,
      "type": values?.ticketType?.label,
      "title": values.ticketTitle,
      "description": values.description,
      "cust_ref_id": values?.accountId?._id,
      "complaint_status": values?.status?.value,
      "staff_name": values?.assign_to?.name,
      "staff_mobile_no": values.assign_to?.mobile_no,
      "priority": values?.priority?.value,
      "complaint_forward_to": "payments",
      "staff_email": values?.assign_to?.email_id,
      "emp_ref_id":values?.assign_to?._id,
      "created_by": "distributor",
      "due_date": values.dueDate,
      "assign_to": values?.assign_to?.email_id,
      "sms_access" : user?.sms_access, 
      "whatsapp_access": user?.whatsapp_access,
    };
    if (id) {
      payload["_id"] = id;
      dispatch(updateTicket(payload));
    } else {
      dispatch(addTicket(payload));
    }
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  const totalOpenTicket = parseInt(ticketStatusOpenData.openTicketCount ? ticketStatusOpenData?.openTicketCount : 0) + parseInt(ticketStatusOpenData.closedTicketCount ? ticketStatusOpenData?.closedTicketCount : 0);
  const totalProgressTicket = parseInt(totalOpenTicket) + parseInt(ticketStatusOpenData.pendingTicketCount ? ticketStatusOpenData?.pendingTicketCount : 0);
  const totalTicket = parseInt(totalProgressTicket) + parseInt(ticketStatusOpenData?.inprogressTicketCount ? ticketStatusOpenData?.inprogressTicketCount : 0);

  return (
    <>
      <div className="ticketList-container">
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
          <div className="planHeading">Ticket Status</div>
          </Grid>
          <Grid item xs={12} md={4} className="flex-end">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              secondaryHeading="Ticket List"
              handleSecondaryClick={() => handleBreadCrumbRedirect("ticket-list")}
              activeHeading={paramsQuery("id") ? "Edit Ticket" : "Add Ticket"}
            />
          </Grid>
        </Grid>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <TicketStatus className=" ticketCardBlue" title={ticketStatusData[0].title} count={ticketStatusOpenData.openTicketCount ? ticketStatusOpenData?.openTicketCount : 0} icon={ticketStatusData[0].icon} />
            </Grid>
            
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <TicketStatus className=" ticketCardRed" title={ticketStatusData[2].title} count={ticketStatusOpenData.pendingTicketCount ? ticketStatusOpenData?.pendingTicketCount : 0} icon={ticketStatusData[2].icon} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <TicketStatus className=" ticketCardPink" title={ticketStatusData[3].title} count={ticketStatusOpenData?.inprogressTicketCount ? ticketStatusOpenData?.inprogressTicketCount : 0} icon={ticketStatusData[3].icon} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <TicketStatus className=" ticketCardPurple" title={ticketStatusData[4].title} count={ticketStatusOpenData?.resolvedTicketCount ? ticketStatusOpenData?.resolvedTicketCount : 0} icon={ticketStatusData[4].icon} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <TicketStatus className=" ticketCardGreen" title={ticketStatusData[1].title} count={ticketStatusOpenData.closedTicketCount ? ticketStatusOpenData?.closedTicketCount : 0} icon={ticketStatusData[1].icon} />
            </Grid>
          </Grid>
        </div>
        <div className="addTicket-container">
          <AddTicketForm data={filtered && filtered[0]} empDetails={empFullData} handleFormValues={(values) => handleFormValues(values)} />
        </div>
      </div>
    </>
  );
}
