import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { accountID } from "@reducers/api";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useDispatch, useSelector } from "react-redux";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { distEmployeeList, SubscriberListAssigned } from "@reducers/api";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Stack from "@mui/material/Stack";
import {
  useComponentDidMount,
  useComponentDidUpdate,
  paramsQuery,
} from "@utils/useEffectHooks";
import { useNavigate } from "react-router-dom";



const validationAdd = Yup.object({
  accountId: Yup.object().required("Required").nullable(),
  ticketType: Yup.object().required("Required").nullable(),
  ticketTitle: Yup.string().required("Required").nullable(),
  assign_to: Yup.object().required("Required").nullable(),
  priority: Yup.object().required("Required").nullable(),
  status: Yup.object().required("Required").nullable(),
  description: Yup.string().required("Required").nullable(),
});

const validationEdit = Yup.object({
  ticketType: Yup.object().required("Required").nullable(),
  ticketTitle: Yup.string().required("Required").nullable(),
  assign_to: Yup.object().required("Required").nullable(),
  priority: Yup.object().required("Required").nullable(),
  status: Yup.object().required("Required").nullable(),
  description: Yup.string().required("Required").nullable(),
});

export default function AddTicketForm(props) {
  const { accountIDData, subscriberListData, SubscriberListAssignedData } = useSelector((state) => state.subscriber);
  const { distEmployeeListData } = useSelector((state) => state.employee);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      paramsQuery('id') ? validationEdit : validationAdd
    ),
  });
  const navigate = useNavigate();

  let user = JSON.parse(localStorage.getItem("user"));
  let accountId = user?.account_id;
  const [SearchText, SetSearchText] = useState(null);
  const [SearchClose, SetSearchClose] = useState([]);
  const [AccountIDData, SetAccountIDData] = useState([]);
  const [AssignedToData, SetAssignedToData] = useState([]);

  const handleRedirect = () => {
    navigate(`/distributor/ticket-list`);
  };


  const priority = [
    { label: "Low", value: "low" },
    { label: "Medium", value: "medium" },
    { label: "High", value: "high" },
  ];

  const ticketType = [
    { label: "Question", value: "Question", },
    { label: "Incident", value: "Incident", },
    { label: "Problem", value: "Problem", },
    { label: "Feature Request", value: "Feature Request", },
    { label: "Services", value: "Services", },
    { label: "Billing & Payment", value: "Billing & Payment", },
    { label: "Shifting", value: "Shifting", },
  ];

  const status = [
    { label: "open", value: "open" },
    { label: "pending", value: "pending" },
    { label: "inProgress ", value: "inprogress" },
    { label: "resolved", value: "resolved" },
    { label: "closed", value: "closed" },
  ];

  const [duevalue, setdueValue] = useState(new Date());

  const handleAccountSearch = (value) => {
    let query = queryGenerator(value);
    dispatch(SubscriberListAssigned(query));
  };

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}`;
    dispatch(distEmployeeList(query));
  }, []);

  useComponentDidUpdate(() => {
    if (distEmployeeListData) {
      var disempData = distEmployeeListData?.data?.map(function (data) {
        return {
          label: data?.email_id,
          id: data?._id,
          name: data?.name,
          mobile: data?.mobile_no
        };
      });
      SetAssignedToData(disempData);
    }
  }, [distEmployeeListData]);

  useComponentDidUpdate(() => {
    if (props) {
      setValue("ticketType", props?.data?.type);
      setValue("ticketTitle", props?.data?.title)
      setValue("priority", props?.data?.priority)
      setValue("status", props?.data?.ticket_status)
      setValue("description", props?.data?.description)
      setValue("description", props?.data?.description)
      setValue("accountIdDis", props?.data?.email_id)
      setValue("assign_to", props?.data?.assign_to)
      filterObject("ticketType", ticketType, props?.data?.type);
      filterObject("status", status, props?.data?.complaint_status);
      filterObject("priority", priority, props?.data?.priority);
      filterObjectAssigned("assign_to", props?.empDetails, props?.data?.assign_to);
      filterObject("accountId", AccountIDData, props?.data?.email_id);
      setValue("dueDate", props?.data?.due_date || new Date());
      setdueValue(props?.data?.due_date)
    }
  }, [props]);

  console.log(props?.data?.assign_to, "assignto")

  useComponentDidUpdate(() => {
    if (SubscriberListAssignedData) {
      SetAccountIDData(SubscriberListAssignedData);
    }
  }, [SubscriberListAssignedData]);

  useEffect(() => {
    if (accountIDData) {
      SetSearchClose(accountIDData);
    }
  }, [accountIDData]);

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&value=${value}`;

    return query;
  };

  const assignToClose = (value) => {
    setValue("accountId", value?.email_id);
    setValue("accountDetails", value);
    //SetSearchText(value?.email_id);
    SetAccountIDData([])
  };

  const onSubmit = (data) => props?.handleFormValues(data);

  const filterObject = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options.value.toString() === value.toString()
      );
    setValue(title, filtered[0]);
  }

  const filterObjectAssigned = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options.email_id.toString() === value.toString()
      );
    setValue(title, filtered[0]);
  };


  return (
    <div className="addTicket-form-cnt AssignSeachContainer">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="add-ticket-title">Ticket</div>
        <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addticket">Account ID</div>
            <Grid item xs={2} sm={4} md={10}>
              {/* <TextField  {...register("accountId")} size="small" className="txtwidth" variant="outlined" id="outlined-basic"
              onChange={(e) => {
                //SetSearchText(e.target.value);
                handleAccountSearch(e.target.value);
                setValue("accountId", e.target.value);
              }} /> */}

              {
                paramsQuery("id") ?
                  <TextField size="small" {...register("accountIdDis")} disabled className="txtwidth" variant="outlined" id="outlined-basic" /> :
                  <Controller
                    control={control}
                    name="accountId"
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        control={control}
                        name="accountId"
                        onChange={(event, item) => {
                          onChange(item);
                        }}
                        value={value || ""}
                        options={AccountIDData?.data || []}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        getOptionLabel={(item) => (item.name ? item.name : "")}
                        getOptionSelected={(option, value) => value === undefined || value === "" || option.name === value.name}
                        renderInput={(params) => <TextField {...params} size="small" placeholder="Select"
                          onChange={(e, item) => {
                            handleAccountSearch(e.target.value);
                          }} />}

                      />
                    )}
                  />
              }


              {/* {AccountIDData && AccountIDData?.data?.length > 0 && (
            <div className={"AssignSearchResultsAssign"}>
              {AccountIDData?.data?.map((prod, i) => (
                <Grid key={i} className={"searchresult"} container onClick={() => assignToClose(prod)}>
                   {prod.email_id} <span>({prod.name})</span>
                </Grid>
              ))}
            </div>  )} */}

              {<div className="field-error">{errors.accountId?.message}</div>}
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addticket">Ticket Type</div>
            <Grid item xs={2} sm={4} md={10}>
              {/* <Controller
                  control={control}
                  name="ticketType"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      size="small"
                      disablePortal
                      onChange={(event, item) => {
                        onChange(item);
                        setValue("ticketType", item);
                      }}
                      defaultValue={props?.data?.type}
                      value={value || ""}
                      options={ticketType}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderInput={(params) => <TextField  {...params} size="small" placeholder="Select Ticket Type" />}
                    />
                  )}
                /> */}
              <Controller
                control={control}
                name="ticketType"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || ""}
                    options={ticketType}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    getOptionLabel={(item) =>
                      item.value ? `${item.value} (${item.value})` : ""
                    }
                    defaultValue={props?.data?.type}
                    renderInput={(params) => (
                      <TextField {...params} size="small" placeholder="Select Ticket Type" />
                    )}
                  />
                )}
              />
              {<div className="field-error">{errors.ticketType?.message}</div>}
            </Grid>
          </Grid>
        </Grid>
        <div className="padding-title-addticket">Ticket Details</div>
        <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addticket">Ticket Title</div>

            <Grid item xs={2} sm={4} md={10}>
              <TextField size="small" {...register("ticketTitle")} className="txtwidth" variant="outlined" id="outlined-basic" placeholder="Ticket Title" />
              {<div className="field-error">{errors.ticketTitle?.message}</div>}
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addticket">Assigned to</div>
            <Grid item xs={2} sm={4} md={10}>
              <Controller
                control={control}
                name="assign_to"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || ""}
                    options={props?.empDetails || []}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    getOptionLabel={(item) =>
                      item.name ? `${item.name} ` : ""
                    }
                    defaultValue={props?.data?.assigned_to}
                    renderInput={(params) => (
                      <TextField {...params} size="small" placeholder="Select assigned to"/>
                    )}
                  />
                )}
              />
              {<div className="field-error">{errors.assign_to?.message}</div>}
            </Grid>
          </Grid>
        </Grid>
        <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addticket">Priority</div>

            <Grid item xs={2} sm={4} md={10}>
              <Controller
                control={control}
                name="priority"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="small"
                    disablePortal
                    onChange={(event, item) => {
                      onChange(item);
                      setValue("priority", item);
                    }}
                    value={value || ""}
                    options={priority}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => <TextField {...params} size="small" placeholder="Select Priority" />}
                  />
                )}
              />
              {<div className="field-error">{errors.priority?.message}</div>}
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addticket">Status</div>

            <Grid item xs={2} sm={4} md={10}>
              <Controller
                control={control}
                name="status"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="small"
                    disablePortal
                    onChange={(event, item) => {
                      onChange(item);
                      setValue("status", item);
                    }}
                    value={value || ""}
                    options={status}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => <TextField {...params} size="small" placeholder="Select status" />}
                  />
                )}
              />
              {<div className="field-error">{errors.status?.message}</div>}
            </Grid>
          </Grid>
        </Grid>
        <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addticket">Due Date </div>
            <Grid item xs={2} sm={4} md={10} className="date-picker-height">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={props.label}
                  value={duevalue}
                  name="dueDate"
                  onChange={(newValue) => {
                    setValue("dueDate", newValue);
                    setdueValue(newValue);
                  }}
                  renderInput={(params) => <TextField {...register("dueDate")} {...params} style={{ width: "100%", height: "1px" }} />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <div className="padding-title-addticket">Description</div>
            <Grid item xs={2} sm={4} md={10}>
              <textarea style={{ width: "100%", height: "34px" }} className="textarea-add-enquiry txtwidth" rows="2" cols="50" {...register("description")} variant="outlined" id="outlined-basic" placeholder="Description" />
              {<div className="field-error">{errors.description?.message}</div>}
            </Grid>
          </Grid>

          <Grid item xs={2} sm={4} md={11}>
            <div className="padding-title-addticket"></div>
            <div className="btn-ticket-form">
              <Stack direction="row" className="flex-end btns-stack" spacing={2}>
                <Button size="small" variant="outlined" className="btn-outlined" onClick={handleRedirect} style={{ textTransform: "none", color: "#00249b" }}>
                  Cancel
                </Button>
                <Button type="submit" size="small" variant="contained" className="bg-blue" style={{ textTransform: "none" }}>
                  {props?.data?._id ? "Update " : "Create "}
                </Button>
              </Stack>
            </div>
            <div className="padding-title-addticket"></div>
            <div className="padding-title-addticket"></div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
