import AddSubscriberForm from "@components/AddSubscriberForm";
import AddSubscriberFormPayment from "@components/AddSubscriberPayments";
import { Grid } from "@mui/material";
import { useState } from "react";
import {
  areaList,
  allEmpList,
  adminAllPlans,
  addSubscriber,
  getInventoryList,
  inventoryUpdate,
  paymentTax,
  paymentAmount,
} from "@reducers/api";
import { reset } from "@reducers/subscriber";
import {
  useComponentDidMount,
  useComponentDidUpdate,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "@components/Breadcrumb";

const validityData = [
  { label: "1 Month", value: "1 months" },
  { label: "3 Month", value: "3 months" },
  { label: "6 Month", value: "6 months" },
  { label: "1 Year", value: "1 year" },
];

export default function AddSubscriber(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { empFullData } = useSelector((state) => state.employee);
  const { areaListData, subscriberAdded, getInventoryListData, paymentTaxData, paymentAmtData } = useSelector(
    (state) => state.subscriber
  );
  const { adminAllPlansData } = useSelector((state) => state.plan);

  const [defaultPage, setDefaultPage] = useState("info");
  const [infoValues, setInfoValues] = useState("");
  const [payloadGenerate, setPayload] = useState("");
  
  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}`;
    let stbFilter = `distributor_ref_id=${user?.distributor_ref_id}&hardware_type=stb&stb_supplied=false`;
    let plansQuery = `package_category=package&distributor_ref_id=${user?.distributor_ref_id}&disabled=false`;
    let distributorId = user?.distributor_ref_id;
    dispatch(areaList(query));
    dispatch(allEmpList(query));
    dispatch(adminAllPlans(plansQuery));
    dispatch(getInventoryList(stbFilter));
    dispatch(paymentTax(query));
    dispatch(paymentAmount(distributorId));
  }, []);

  useComponentDidUpdate(() => {
    if (subscriberAdded) {
      if (subscriberAdded.msg == "Can ID is already exists") {
        enqueueSnackbar("Can ID is already exists", {
          variant: "error",
        });
      }
      if (subscriberAdded.msg == "User ID is already exists") {
        enqueueSnackbar("User ID is already exists", {
          variant: "error",
        });
      }
      
      if(subscriberAdded.status === true) {
        enqueueSnackbar("Subscriber Added!", {
          variant: "success",
        });
        navigate(`/distributor/subscriber-list`);
        dispatch(reset());
      }

      if(subscriberAdded.status === true && subscriberAdded?.data?.service_type === "cable") {
        dispatch(inventoryUpdate(payloadGenerate));
      }

    }
  }, [subscriberAdded]);

  const handleFormValues = (values) => {
    setInfoValues(values);
    window.scrollTo(0, 0);
    setDefaultPage("payment");
  };

  const handlePaymentForm = (values, paymentType) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      area: infoValues?.area || infoValues?.areaText,
      subarea: infoValues?.subArea || infoValues?.subAreaText,
      dob: infoValues?.activationDate,
      gender: infoValues?.gender?.value,
      pin_code: infoValues?.pinCode,
      name: infoValues?.userName,
      email_id: infoValues?.email,
      state: infoValues?.state,
      city: infoValues?.city,
      flat_no: infoValues?.flat,
      mobile_no: infoValues?.mobileNo,
      billing_type: values?.billingType?.value,
      installation_amount: values?.cashAmount,
      stb: values?.stbNo?.stb_no,
      assign_to: values?.assignTo?.name,
      employee_ref_id: values?.assignTo?._id,
      discount_amount: values?.discount,
      tax: values?.tax?.value,
      discount_type: values?.discountType?.value,
      is_active: values?.active?.value,
      mso_id: values?.msoId,
      auto_billing: values?.bill?.value,
      selectePlan: {},
      mso: values?.mso?.value,
      emp_ref_id: values?.assignTo?._id,
      staff_email: values?.assignTo?.email_id,
      staff_name: values?.assignTo?.name,
      complaint_forward_to: "payments",
      distributor_ref_id: user?.distributor_ref_id,
      payment_type: paymentType,
      collected_by: user?.username,
      payby_id: user?.distributor_ref_id,
      stb_no: [{ stb_no: values?.stbNo?.stb_no, type: "primary" }],
      can_id: values?.canid,
      login_ip: values?.loginIp,
      user_id: values?.userId,
      checque_bank_name: values?.checque_bank_name,
      checque_date: values?.checque_date,
      checque_no: values?.checque_no,
      bill_no: values?.bill_no,
      selecteStb: values?.stbNo,
      sms_access: user?.sms_access,
      whatsapp_access: user?.whatsapp_access,
    };
    Object.assign(payload.selectePlan, values?.package);
    Object.assign(payload.selectePlan, {
      stb_no: values?.stbNo?.stb_no,
      type: "primary",
    });

    let payloadUpdate = {
      stb_supplied: true,
      _id: values?.stbNo?._id
    };

    dispatch(addSubscriber(payload));
    setPayload(payloadUpdate)

    // if (values?.stbNo) {
    //   dispatch(inventoryUpdate(payloadUpdate));
    // }


  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };


  return (
    <div className="addEnquiry-container">
      <Grid container spacing={2}>
        <Grid item xs={8}>

        </Grid>
        <Grid item xs={12} md={4}className="flex-end">
          <div className="title-container breadcrumb-padding">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              secondaryHeading="Subscriber List"
              handleSecondaryClick={() => handleBreadCrumbRedirect("subscriber-list")}
              activeHeading="Add Subscriber"
              handleActiveClick={() => handleBreadCrumbRedirect("add-subscriber")}
            />
          </div>

        </Grid>
      </Grid>
      <div className="add-subscriber-form-cnt">
        <Grid item xs={12} sm={12} md={12}>
          <div className="title-container">Add New Subscriber</div>
        </Grid>
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2, md: 7 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          className=""
        >
          <Grid item xs={4} sm={4} md={6} className="subscriberTab">
            <div
              className={
                defaultPage === "info"
                  ? "selectable-subcriber"
                  : "selectable-subcriber-right"
              }
            ></div>
            <div
              style={{
                color: defaultPage === "info" ? "#00249b" : "",
                fontWeight: "bold",
              }}
              className="text-align tabText"
            >
              Subscriber Details
            </div>
          </Grid>
          <Grid item xs={4} sm={4} md={6} className="subscriberTabRight">
            <div
              className={
                defaultPage === "payment"
                  ? "selectable-subcriber"
                  : "selectable-subcriber-right"
              }
            ></div>
            <div
              className="text-align tabText"
              style={{
                color: defaultPage === "payment" ? "#00249b" : "",
                fontWeight: "bold",
              }}
            >
              {"Package & Payment"}
            </div>
          </Grid>
        </Grid>
        <div style={{ display: defaultPage === "info" ? "" : "none" }}>
          <AddSubscriberForm
            areaData={areaListData}
            validityData={validityData}
            handleFormValues={handleFormValues}
          />
        </div>
        <div style={{ display: defaultPage === "payment" ? "" : "none" }}>
          <AddSubscriberFormPayment
            handlePrevious={() => {
              window.scrollTo(0, 0);
              setDefaultPage("info");
            }}
            employeeData={empFullData}
            plansData={adminAllPlansData}
            handleFormValues={handlePaymentForm}
            stbListData={getInventoryListData}
            tax = {paymentTaxData}
            maintanance = {paymentAmtData}
          />
        </div>
      </div>
    </div>
  );
}
