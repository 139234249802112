import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./styles.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { singleSms, subscriberView } from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import { useComponentDidUpdate, paramsQuery } from "@utils/useEffectHooks";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/subscriber";
import { useForm, Controller } from "react-hook-form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const options = [{ label: "Giving to Customer (+)" }, { label: "Giving to Customer (-)" }];

export default function BalaanceModal(props) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({});

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { updateBalanceData, singlesmsData } = useSelector((state) => state.subscriber);

  const handleClose = () => {
    props?.handleClose();
  };


  const updateSubmit = (data) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      "title": data?.smstype?.title,
      "mobile_no": props?.listData?.mobile_no,
      "type": "sms",
      "description": data?.description,
      "sms_access": user?.sms_access,
      "whatsapp_access": user?.whatsapp_access,
      
    };

    dispatch(singleSms(payload));
    reset();

  };

  const onSubmit = (data) => {
    updateSubmit(data);
  };

  const handleCloses = () => {
    props?.handleClose();
  };

  const handleChangeContant = (newValue) => {
    console.log(newValue, "newvalueee");

    setValue("description", newValue?.description);
  };



  console.log(props?.listData, "smstype")

  return (
    <Modal open={props?.show} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style} className="makePayment-cntr">
        <div className="make-payment-form-title">
          Send SMS
        </div>
        <div className="divider-line"></div>
        <form onSubmit={handleSubmit(onSubmit)}>
          

          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles">
            <Grid item xs={4} sm={8} md={12}>
              <div className="make-payment-form-title ">Select Type </div>
              <Controller
                control={control}
                name="smstype"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    control={control}
                    name="accountId"
                    onChange={(event, item) => {
                      onChange(item);
                      handleChangeContant(item);
                    }}
                    value={value || ""}
                    options={props?.smsType || []}
                    isOptionEqualToValue={(option, value) =>
                      option.title === value.title
                    }
                    getOptionLabel={(item) => (item.title ? item.title : "")}
                    getOptionSelected={(option, value) =>
                      value === undefined ||
                      value === "" ||
                      option.title === value.title
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Select Template"
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles">
            <Grid item xs={4} sm={8} md={12}>
              <div className="make-payment-form-title ">Description </div>
              <textarea
                style={{ width: "100%", height: "80px", fontSize: "16px", border : "1px solid grey", borderRadius: "3px"}}
                className="textarea-add-enquiry txtwidth"
                rows="2"
                cols="50"
                {...register("description")}
                variant="outlined"
                id="outlined-basic"
                placeholder="Description"
              />
            </Grid>
          </Grid>


          <Stack spacing={2} direction="row" className=" ">
            <Button
              type="submit"
              //onClick={updateSubmit}
              variant="contained"
              className="bg-blue"
              size="small"
              style={{
                textTransform: "capitalize",
              }}
            >
              Submit
            </Button>
            <Button
              size="small"
              variant="outlined"
              style={{
                textTransform: "capitalize",
                borderColor: "#00249b",
                color: "#00249b",
              }}
              onClick={handleCloses}
            >
              Cancel
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
}
