import "./styles.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchWithButtons from "@components/SearchWithButtons";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useComponentDidMount, useComponentDidUpdate, useComponentWillUnmount } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { distributorList, deleteDistributor, updateDistributor, getDistributorCount ,resetPwdDist} from "@reducers/api";
import { reset } from "@reducers/distributor";
import { useState } from "react";
import { useSnackbar } from "notistack";
import AdminTicketFilter from "@components/AdminTicketFilter";
import moment from "moment";
import { Grid } from "@mui/material";
import TicketStatus from "@components/TicketStatus";
import BreadCrumb from "@components/Breadcrumb";
import ResetPasswordPopup from "@components/Modals/resetPasswordPopup";
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import ValidityRenewal from "@components/Modals/validityRenewal";

const ticketStatusData = [
  {
    id: 1,
    title: "Active",
    count: 30,
    icon: "",
  },
  {
    id: 2,
    title: "Inactive",
    count: 28,
    icon: "",
  },
  {
    id: 3,
    title: "Total",
    count: 13,
    icon: "",
  },
];


const statusdrop = [
  { label: "Active" },
  { label: "Inactive" },

];

export default function DistributionList(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [distributorListExport, setDistributorListExport] = useState([]);
  const [distList, setDistList] = useState([]);
  const [distListData, setDistListData] = useState(null);
  const [page, setPage] = useState(1);
  const [showReset, setShowReset] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { distributorListData, distributorDeleted, updatedMsg, getDistributorCountData ,resetPwdDistData} = useSelector((state) => state.distributor);
  const [DisData, setData] = useState(null);
  const [statusFilter, setStatusFromQuery] = useState("");
 
  const [showValidityRenewal, setshowValidityRenewal] = useState(false);

  useComponentDidMount(() => {
    dispatch(distributorList(`limit=10&offset=0`));
    dispatch(getDistributorCount());
  }, []);

  useComponentDidUpdate(() => {
    let list = [];
    let listForExport=[];
    if (distributorListData?.data?.length) {
      distributorListData?.data?.map((data, index) => {
        list.push(objConversion(data, index));
        listForExport.push(objConversion1(data, index));
      });
    }

    setDistList(list);
    setDistributorListExport(listForExport);
  }, [distributorListData]);

 

  const onShowResetPasswordPopUp = (data) => {
    setDistListData(data);
    setShowReset(true);

  };

  const closepoup = () => {
    setShowReset(false);
    dispatch(reset());
    dispatch(distributorList(`limit=10&offset=0`));
    dispatch(getDistributorCount());
  }

  useComponentDidUpdate(() => {
    if (distributorDeleted) {
      enqueueSnackbar("Distributor Deleted !", {
        variant: "success",
      });
      dispatch(reset());
      dispatch(distributorList(`limit=10&offset=0`));
      dispatch(getDistributorCount());
    }
  }, [distributorDeleted]);

  useComponentDidUpdate(() => {
    if (updatedMsg) {
      enqueueSnackbar(updatedMsg, {
        variant: "success",
      });
      dispatch(reset());
      dispatch(distributorList(`limit=10&offset=0`));
      dispatch(getDistributorCount());
    }
  }, [updatedMsg]);

  const updateStatus = (id, status,sms,whatsapp) => {
    let payload = {
      _id: id,
      is_active: status,
      sms_access:sms,
      whatsapp_access:whatsapp
    };
    dispatch(updateDistributor(payload));
  };

  useComponentWillUnmount(() => {
    dispatch(reset());
  });

  

  
  const updateSubmit = (values,props) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      email_id:props?.empData?.email_id,
      newPassword: values?.newPassword,
      account_id: props?.empData?.account_id,
      sms_access: user?.sms_access,
      whatsapp_access: user?.whatsapp_access,
    };
    dispatch(resetPwdDist(payload));
  };


  const onShowPopuo = (data) => {
    setData(data);
    setshowValidityRenewal(true);
  };

  const objConversion = (data, index) => {
    let obj = {
      sNo: (page - 1) * 10 + index + 1,
      distributorName: data?.name,
      accountId: data?.account_id,
      contactNumber: data?.mobile_no,
      validity: data?.distributor_validity ? `${data?.distributor_validity} Days` : "Unlimited",
      created: moment(data?.created_at).format("MMMM Do YYYY, h:mm a"),
      email: data?.email_id,
      address: data?.address,
      city: data?.city,
      state: data?.state,
      postalCode: data?.pin_code,
      status: data?.is_active ? (
        <div className="status-btn-table-active" onClick={() => updateStatus(data?._id, false,data?.sms_access,data?.whatsapp_access)}>
          Active
        </div>
      ) : (
        <div className="status-btn-table-inactive" onClick={() => updateStatus(data?._id, true,data?.sms_access,data?.whatsapp_access)}>
          In Active
        </div>
      ),
      action: (
        <div className="flex-row justify-center">
          <div onClick={() => onShowPopuo(data)} title="Validity Renewal" className="cur-pointer">
            <LibraryAddCheckIcon />
          </div>
          <div onClick={() => navigate(`/admin/add-distributor?id=${data._id}`)} className="cur-pointer">
            <EditIcon />
          </div>
          <div
            className="cur-pointer"
            onClick={() => {
              let payload = {
                "_id": data?._id,
              };
              dispatch(deleteDistributor(payload));
            }}
          >
            <DeleteIcon />
          </div>
          <div
              className="cursor-pointer"
              onClick={() => onShowResetPasswordPopUp(data)}
            >
              <RestartAltIcon />
            </div>
        </div>
      ),
    };

    return obj;
  };

  
  const objConversion1 = (data, index) => {
    let obj = {
      sNo: (page - 1) * 10 + index + 1,
      distributorName: data?.name,
      accountId: data?.account_id,
      contactNumber: data?.mobile_no,
      validity: data?.distributor_validity ? `${data?.distributor_validity} Days` : "Unlimited",
      created: moment(data?.created_at).format("ll"),
      email: data?.email_id,
      address: data?.address,
      city: data?.city,
      state: data?.state,
      postalCode: data?.pin_code,
     
    };

    return obj;
  };

  const closepoupValidty = () => {
    setshowValidityRenewal(false);
  }

  const handleRedirect = () => {
    navigate("/admin/add-distributor");
  };

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    dispatch(distributorList(`limit=10&offset=0&value=${search}`));
  };

  const handleChange = (value) => {
    dispatch(distributorList(`limit=10&offset=${value - 1}0${searchQuery ? `&value=${searchQuery}` : ""}${statusFilter === true ? `&is_active=true` : "" }${statusFilter === false ? `&is_active=false` : "" }`));
  };

  const handleFormValues = ( statusname) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let id = user?.distributor_ref_id;
    setPage(1)
    if (statusname?.label === "Active") {
      dispatch(distributorList(`limit=10&offset=0&is_active=true`));
      
      setStatusFromQuery(true)
      
    }
    if (statusname?.label === "Inactive") {
      dispatch(distributorList(`limit=10&offset=0&is_active=false`));
      setStatusFromQuery(false)
    }
    if(statusname === null){
      dispatch(distributorList(`limit=10&offset=0`));
    }
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/admin/${path}`);
  };

  useComponentDidUpdate(() => {
    if (resetPwdDistData) {
      enqueueSnackbar("Reset Password Successfully", {
        variant: "success",
      });   
      closepoup();
      dispatch(reset()); 
      dispatch(distributorList(`limit=10&offset=0`));
    }
  }, [resetPwdDistData]);

  const handleReset = () => {
      dispatch(distributorList(`limit=10&offset=0`));
      setPage(1);
      setStatusFromQuery("")
  };

  return (
    <div className="distributionList-container employeeList-container ticketList-container">
      <ValidityRenewal show={showValidityRenewal} handleClose={closepoupValidty} disData={DisData} />
      <Grid container spacing={2}>
        <Grid item xs={8}>          
        </Grid>
        <Grid item xs={4} className="flex-end">
          <div className="employeelist-title">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              activeHeading="Distributor List"
              handleActiveClick={() => handleBreadCrumbRedirect("distributor-list")}
            />
          </div>

        </Grid>
      </Grid>
      <div>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={2.4}
            style={{ paddingLeft: 0, paddingBottom: 0 }}
          >
            <TicketStatus
              className=" ticketCardGreen"
              title={ticketStatusData[0].title}
              count={getDistributorCountData?.activeDistributor || "0"}
              icon={ticketStatusData[0].icon}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={2.4}
            style={{ paddingLeft: 0, paddingBottom: 0 }}
          >
            <TicketStatus
              className=" ticketCardRed"
              title={ticketStatusData[1].title}
              count={getDistributorCountData?.inactiveDistributor || "0"}
              icon={ticketStatusData[1].icon}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={2.4}
            style={{ paddingLeft: 0, paddingBottom: 0 }}
          >
            <TicketStatus
              className=" ticketCardBlue"
              title={ticketStatusData[2].title}
              count={getDistributorCountData?.totalDistributor || "0"}
              icon={ticketStatusData[2].icon}
            />
          </Grid>
        </Grid>
      </div>
      <div className="ticket-list-table-cntr">
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={12} sm={12} md={12} className="flex-end">
            <AdminTicketFilter
              SecondaryOption={statusdrop}
              labelSecondary={"Status"}
              handleResetClick = {handleReset}
              handleFormValues={(statusname) => handleFormValues( statusname)}
            />
          </Grid>
        </Grid>
        <div style={{ fontWeight: 'bold', fontSize: '15px',paddingBottom: '10px'}}>Showing entries { (page - 1) * 10 + 1 } - {Math.ceil(distributorListData?.totalCount / 10)==page?distributorListData?.totalCount:page+"0"} of {distributorListData?.totalCount}</div>   
        <div className="search-btns-padding">
          <SearchWithButtons
            primaryButton={{
              title: "New Distributor",
              type: "contained",
              color: "white",
              bgColor: COLORS.primary,
            }}
            secondaryButton={{
              title: "Export",
              type: "outlined",
              color: COLORS.primary,
              filename:"Distributor list",
              data: distributorListExport,
            }}
            handlePrimaryClick={handleRedirect}
            // handleSecondaryClick={}
            handleChange={handleSearch}
          />
        </div>
        <TableView rows={tableRows} data={distList} color={COLORS.primary} />
        <ResetPasswordPopup show={showReset} handleClose={closepoup} listData={props?.data} empData={distListData} submitData={updateSubmit} />
        <div style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}>
          <Pagination
            count={Math.ceil(distributorListData?.totalCount / 10) || 0}
            defaultPage={1}
            page={page}
            onChange={(event, value) => {
              setPage(value);
              handleChange(value);
            }}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
}
