import moment from "moment";

const tableRows = [
  {
    title: "checkBox",
    key: "checkbox",
    align: "left",
  },

  {
    title: "Plan Name",
    key: "plan_name",
    align: "left",
  },
  {
    title: "Package Category",
    key: "package_category",
    align: "left",
  },
  {
    title: "Channel Count",
    key: "channel_count",
    align: "left",
  },
  {
    title: "Plan Validity",
    key: "plan_validity",
    align: "left",
  },
  {
    title: "Amount",
    key: "plan_amount",
    align: "left",
  },

  {
    title: "Expired Date",
    key: "expired_date",
    align: "left",
  },
];

const tableRowsCustomer = [
  {
    title: "checkBox",
    key: "checkbox",
    align: "left",
  },

  {
    title: "Plan Name",
    key: "plan_name",
    align: "left",
  },
  {
    title: "Package Category",
    key: "package_category",
    align: "left",
  },

  {
    title: "Plan Validity",
    key: "plan_validity",
    align: "left",
  },
  {
    title: "Amount",
    key: "plan_amount",
    align: "left",
  },

];

const tableRowsReceipt = [
 

  {
    title: "Plan Name",
    key: "plan_name",
    align: "left",
  },
  {
    title: "Package Category",
    key: "package_category",
    align: "left",
  },
  {
    title: "Channel Count",
    key: "channel_count",
    align: "left",
  },
  {
    title: "Amount",
    key: "plan_amount",
    align: "left",
  },

  {
    title: "Expired Date",
    key: "expired_date",
    align: "left",
  },
];

const tableRowsChannelList = [
  {
    title: "Package Channel List",
    key: "plan_name",
    align: "left",
  },
  {
    title: "",
    key: "totalchannel",
    align: "left",
  },
];

const tableRowsPaymentHistory = [
  {
    title: "Transaction ID",
    key: "id",
    align: "left",
  },
  {
    title: "Bill Amount",
    key: "billAmount",
    align: "left",
  },
  {
    title: "Due Amount",
    key: "dueAmount",
    align: "left",
  },
  {
    title: "Payment Status",
    key: "paymentStatus",
    align: "left",
  },
  {
    title: "Payment Date",
    key: "date",
    align: "left",
  },
  {
    title: "Action",
    key: "action",
    align: "left",
  },
];

const PaymentHistoryList = [
  {
    title: "Product Name",
    key: "productname",
    align: "left",
  },
  {
    title: "MRP",
    key: "mrp",
    align: "end",
  },
];

const broacasttable = [
  {
    key: "checkbox",
    align: "left",
  },
  {
    title: "Plan Name",
    key: "planname",
    align: "left",
  },
  {
    title: "Plan Amount",
    key: "amount",
    align: "left",
  },
  {
    title: "Channel count",
    key: "channelcount",
    align: "left",
  },
  {
    title: "Action",
    key: "action",
    align: "left",
  },
];

const basictable = [
  {
    key: "radio",
    align: "left",
  },
  {
    title: "Plan Name",
    key: "planname",
    align: "left",
  },
  {
    title: "Package cost",
    key: "packagecost",
    align: "left",
  },
];

const transactionRows = [
  {
    title: "Service Type",
    key: "service_type",
    align: "left",
  },

  {
    title: "Bill Amount",
    key: "bill_amount",
    align: "left",
  },
  {
    title: "Paid Amount",
    key: "collection_amount",
    align: "left",
  },
  {
    title: "Collected By",
    key: "collected_by",
    align: "left",
  },
  {
    title: "Collected Date",
    key: "collected_date",
    align: "left",
  },

  {
    title: "Due Amount",
    key: "due_amount",
    align: "left",
  },
];

const tableforcollection = [  
  {
    title: "Name",
    key: "name",
    align: "left",
  },
  {
    title: "Email",
    key: "email_id",
    align: "left",
  },
  {
    title: "Phone Number",
    key: "mobile_no",
    align: "center",
  },
  {
    title: "Account Id",
    key: "account_id",
    align: "left",
  },
  {
    title: "Service Type",
    key: "service_type",
    align: "left",
  },
  {
    title: "STB",
    key: "stb",
    align: "left",
  },
];

export { tableRows,tableforcollection, broacasttable, tableRowsChannelList, tableRowsPaymentHistory, PaymentHistoryList, transactionRows, basictable, tableRowsReceipt, tableRowsCustomer};
