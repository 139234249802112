import "./style.scss";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import {  taxListUpdate,  othersAll } from "@reducers/api";
import { useForm } from "react-hook-form";
import { useComponentDidMount, useComponentDidUpdate } from "@utils/useEffectHooks";
import { useSnackbar } from "notistack";

export default function SettingsTax() {
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    setValue,
  } = useForm({
    //resolver: yupResolver(validation),
  });

  const dispatch = useDispatch();
  const { taxListUpdateData } = useSelector(
    (state) => state.settings

  );
  const {

    otherAllData,

} = useSelector((state) => state.subscriber);


  useComponentDidUpdate(() => {
    let message = "";
    if (taxListUpdateData) {
      message = "submited successfully"
      enqueueSnackbar(message, {
        variant: "success",
      });
      let user = JSON.parse(localStorage.getItem("user"));
      let distributorId = user?.distributor_ref_id;
      dispatch(othersAll(distributorId));
    }
  }, [taxListUpdateData]);


  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let distributorId = user?.distributor_ref_id;
    dispatch(othersAll(distributorId));
  });

  useComponentDidUpdate(() => {
    setValue("mso", otherAllData[0]?.mso);
    setValue("msourl", otherAllData[0]?.mso_url);
    setValue("maintanceNumber", otherAllData[0]?.maintenance_amount);
    setValue("ncfAmount", otherAllData[0]?.ncf_amount);
    setValue("msoId", otherAllData[0]?._id);

    setValue("ispId", otherAllData[1]?._id);
    setValue("isp", otherAllData[1]?.isp);
    setValue("ispurl", otherAllData[1]?.isp_url);
    setValue("ispMaintAmt", otherAllData[1]?.maintenance_amount);
    setValue("ispNcfNo", otherAllData[1]?.ncf_amount);




  }, [otherAllData]);

  const updateSubmit1 = (values) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let dist_id = user?.distributor_ref_id;
    let payload = {
      
     "_id": values?.msoId || "",
      "type": "others",
      "distributor_ref_id": dist_id,
      "ncf_amount": values?.ncfAmount,
      "maintenance_amount": values?.maintanceNumber,
      "mso_url": values?.msourl,
      "mso": values?.mso 
    };

    dispatch(taxListUpdate(payload));
  };

  const updateSubmit2 = (values) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let dist_id = user?.distributor_ref_id;
    let payload = {

      "_id": values?.ispId || "",
      "type": "others",
      "distributor_ref_id": dist_id,
      "ncf_amount": values?.ispNcfNo,
      "maintenance_amount": values?.ispMaintAmt,
      "isp_url": values?.ispurl,
      "isp": values?.isp

    };

    dispatch(taxListUpdate(payload));
  };

  const onSubmit1 = (values) => updateSubmit1(values);
  const onSubmit2 = (values) => updateSubmit2(values);

  return (
    <div className="settingstax-container">
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={4} md={5.5}>
          <form onSubmit={handleSubmit(onSubmit1)}>
            <Grid item xs={12} sm={12} md={12} spacing={4} className="header-tax">
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <div className="settingstax-title">MSO</div>
                </Grid>
              </Grid>
            </Grid>
            <div className="border-sts-pay">
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput createSearchForm">
                <div className="padding-title-addticket">Mso</div>

                <TextField
                  {...register("msoId")}
                  id="outlined-basic"

                  sx={{ display: 'none' }}
                  className="txtwidth"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
               
                <TextField
                  {...register("mso")}


                  id="outlined-basic"
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
                <div className="padding-title-addticket">MSO URL</div>
                <TextField
                  {...register("msourl")}
                  id="outlined-basic"
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
                <div className="padding-title-addticket">Maintenance Amount</div>
                <TextField
                  {...register("maintanceNumber")}
                  id="outlined-basic"
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
                <div className="padding-title-addticket">NCF Amount</div>
                <TextField
                  {...register("ncfAmount")}
                  id="outlined-basic"
                  className="txtwidth"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={2} direction="row" className="settingstaxbtn">
                  <Button type="submit" variant="contained" className="bg-blue setbtnborder">
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </div>
          </form>
        </Grid>
        <Grid item xs={4} sm={4} md={1}></Grid>
        <Grid item xs={4} sm={4} md={5.5}>
          <form onSubmit={handleSubmit(onSubmit2)}>
            <Grid item xs={12} sm={12} md={12} spacing={4} className="header-tax">
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <div className="settingstax-title">ISP</div>
                </Grid>
              </Grid>
            </Grid>
            <div className="border-sts-pay">
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput createSearchForm">
                <div className="padding-title-addticket">ISP</div>

                <TextField
                  {...register("ispId")}
                  id="outlined-basic"

                  sx={{ display: 'none' }}
                  className="txtwidth"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />


                <TextField
                  {...register("isp")}
                  sx={{ display: 'none' }}
                  id="outlined-basic"
                  className="txtwidth"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  {...register("isp")}
                  id="outlined-basic"
                  className="txtwidth"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
                <div className="padding-title-addticket">ISP URL</div>
                <TextField
                  {...register("ispurl")}
                  id="outlined-basic"
                  className="txtwidth"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
                <div className="padding-title-addticket">Maintenance Amount</div>
                <TextField
                  {...register("ispMaintAmt")}
                  id="outlined-basic"
                  className="txtwidth"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
                <div className="padding-title-addticket">NCF Amount</div>
                <TextField
                  {...register("ispNcfNo")}
                  id="outlined-basic"
                  className="txtwidth"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={2} direction="row" className="settingstaxbtn">
                  <Button type="submit" variant="contained" className="bg-blue setbtnborder">
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </div>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}
