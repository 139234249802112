import React from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./styles.scss";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { bulkEmail, currentServiceList } from "@reducers/api";
import { useDispatch } from "react-redux";
import { paramsQuery } from "@utils/useEffectHooks";
import { useForm } from "react-hook-form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function EmailModal(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const dispatch = useDispatch();

  const handleClose = () => {
    props?.handleClose();
  };


  const updateSubmit = (data) => {
    let payload = {
      "name": props?.listData?.name,
      "email_id": props?.listData?.email_id,
      "type": "mail",
      "description": data?.description,
      "_id": props?.listData?._id
    };

    dispatch(bulkEmail(payload));
    reset();
    let custId = paramsQuery("id");
    dispatch(currentServiceList(custId));
  };

  const onSubmit = (data) => {
    updateSubmit(data);
  };

  const handleCloses = () => {
    props?.handleClose();
  };



  return (
    <Modal open={props?.show} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style} className="makePayment-cntr">
        <div className="make-payment-form-title">
          Send Mail
        </div>
        <div className="divider-line"></div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles">
            <Grid item xs={4} sm={8} md={12}>
              <div className="make-payment-form-title ">Description </div>
              <TextField style={{ width: "100%" }} id="outlined-basic" variant="outlined" name="description" {...register("description")} size="small" />
            </Grid>
          </Grid>
          <Stack spacing={2} direction="row" className=" ">
            <Button
              type="submit"
              variant="contained"
              className="bg-blue"
              size="small"
              style={{
                textTransform: "capitalize",
              }}
            >
              Submit
            </Button>
            <Button
              size="small"
              variant="outlined"
              style={{
                textTransform: "capitalize",
                borderColor: "#00249b",
                color: "#00249b",
              }}
              onClick={handleCloses}
            >
              Cancel
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
}
