import "./style.scss";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useComponentDidMount, useComponentDidUpdate } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { ticketAllList, deleteTicket ,notiTicketUpdate} from "@reducers/api";
import { useState } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";
import { reset } from "@reducers/subscriber";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function TicketList(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ticketListData } = useSelector((state) => state.ticketing);
  const { ticketUpdate } = useSelector((state) => state.subscriber);
  const [ticketingList, setticketList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const defaultPlanQuery = {
    serviceType: "cable",
    billingType: "",
  };

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
    dispatch(ticketAllList(`distributor_ref_id=${user?.distributor_ref_id}`))
  }, []);

  useComponentDidUpdate(() => {
    let list = [];
    ticketListData?.data.map((data, index) => {
      list.push({
        sNo:  index + 1,
        ticketId: (<div onClick={() => navigate(`/distributor/ticket-view?id=${data._id}&custRefId=${data.cust_ref_id}`)} className="cur-pointer">
      { data?.ticket_id}
      </div>),
        name: data?.user_name,
        team: data?.complaint_forward_to,
        staff: data?.staff_name,
        ticketType: data?.type,
        priority: data?.priority,  
        title:data?.title,
        status: status("#73ca72", data?.complaint_status),
        dueDate:  moment(data?.due_date).format("ll"),
        createdDate:  moment(data?.created_at).format("ll"),
        action: (
          <div className="flex-row justify-center">
            <div onClick={() => navigate(`/distributor/add-ticket?id=${data._id}`)} className="cur-pointer">
              <EditIcon />
            </div>
            <div
              className="cur-pointer"
              onClick={() =>
                dispatch(
                  deleteTicket({
                    _id: data?._id,
                  })
                )
              }
            >
              <DeleteIcon />
            </div>
          </div>
        ),
      });
    });

    let filteredList = ticketListData?.data.filter((serviceList) => serviceList.ticket_status === "open"); 

    setticketList(filteredList);
  }, [ticketListData]);

  const status = (color, text) => {
    let colorVal="#d352a5"
    if(text === "inprogress"){
      colorVal = "#73ca72"
    }else if(text === "pending"){
      colorVal = "#d35352"
    }else if(text === "resolved"){
      colorVal = "#47bc47"
    }else if(text === "open"){
      colorVal = "#00249B"
    }
    return (
      <div className="status-btn-table" style={{ backgroundColor: colorVal }}>
        {text}
      </div>
    );
  };

  useComponentDidUpdate(() => {
    if (ticketUpdate) {
      enqueueSnackbar("Updated Successfully!", {
        variant: "success",
      });
      dispatch(reset());
      let user = JSON.parse(localStorage.getItem("user"));
      dispatch(ticketAllList(`distributor_ref_id=${user?.distributor_ref_id}`))
    }
  }, [ticketUpdate]);
 
  return (
    <div>
      <div className="ticketList-container padingnot">
        <div className="ticket-list-table-cntr">
        <Box sx={{ minWidth: 275 }}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={2}>
        {ticketingList &&
          ticketingList.map((e, index) => (
            <Grid item xs={4} sm={4} md={4}>
            <Card variant="outlined">
              <CardContent>
               
                <Typography variant="h5" component="div">
                  {e.ticket_id}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {e.title}
                </Typography>
                <Typography variant="body2">
                Name : <b>{e.user_name}</b>
             
                </Typography>
                <Typography variant="body2">
                  Assigned To :  <b>{e.assign_to}</b>              
                </Typography>          

                
              </CardContent>
              <CardActions>
                <Button  onClick={() =>
                dispatch(
                  notiTicketUpdate({
                    "_id":e?._id,
                    "complaint_status":"closed"
                  })
                )
              } size="small">Update</Button>
              </CardActions>
          </Card>
            </Grid>
          ))}
  
          </Grid>
          </Box>

        </div>
      </div>
    </div>
  );
}
