import "./styles.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchWithButtons from "@components/SearchWithButtons";
import { useNavigate } from "react-router-dom";
import {
  useComponentDidMount,
  useComponentDidUpdate,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import {
  SubscriberListAssigned,
  deleteAssignSubs,
  SubscriberListMultipleDelete,
  subDelExport,
  AllSubscribersDelete
} from "@reducers/api";
import { useState } from "react";
import Pagination from "@mui/material/Pagination";
import AssignSubscriberModal from "../../../components/Modals/assignSubscriberPopup";
import { useSnackbar } from "notistack";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { set } from "date-fns/esm";
import Grid from "@mui/material/Grid";
import BreadCrumb from "@components/Breadcrumb";
import { reset } from "@reducers/subscriber";


export default function EmployeeList(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    SubscriberListAssignedData,
    SubscriberListMultipleDeleteData,
    deleteRes,
    subDelExportData,
    SubscriberAllMultipleDeleteData
  } = useSelector((state) => state.subscriber);
  const [expList, setexpList] = useState([]);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [openAll, setopenAll] = useState(false);  
  const [checkedVal, setChecked] = useState(false);
  const [currentService, setcurrentService] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;
    if (value?.type === "search") {
      query += `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `distributor_ref_id=${
        user?.distributor_ref_id
      }&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };


  useComponentDidMount(() => {
    let query = queryGenerator();
   
    dispatch(SubscriberListAssigned(query));
    dispatch(subDelExport(query))
  }, []);

  useComponentDidUpdate(() => {
    if (SubscriberAllMultipleDeleteData) {
      enqueueSnackbar("Deleted !", {
        variant: "success",
      });
      let query = queryGenerator();
      // dispatch(subDelExport(query));
      dispatch(SubscriberListAssigned(query));
      dispatch(reset());
      handleClickCancelAll()
    }
  }, [SubscriberAllMultipleDeleteData]);

  useComponentDidUpdate(() => {
    if (SubscriberListMultipleDeleteData) {
      enqueueSnackbar("Deleted !", {
        variant: "success",
      });
      let query = queryGenerator();
      // dispatch(subDelExport(query));
      dispatch(SubscriberListAssigned(query));
    }
  }, [SubscriberListMultipleDeleteData]);

  useComponentDidUpdate(() => {
    if (deleteRes) {
      enqueueSnackbar("Deleted !", {
        variant: "success",
      });
      let query = queryGenerator();
      // dispatch(subDelExport(query));
      dispatch(SubscriberListAssigned(query));
    }
  }, [deleteRes]);

  useComponentDidUpdate(() => {
    let list = [];
    let user = JSON.parse(localStorage.getItem("user"));
    SubscriberListAssignedData && SubscriberListAssignedData?.data?.map((data, index) => {
      list.push({
        checked: false,
        open: false,
        id: data?._id,
        sNo: (page - 1) * 10 + index + 1,
        name: data?.name,
        accNo: data?.account_id,
        canId: data?.can_id,
        stbNo: data?.stb_no && data?.stb_no[0]?.stb_no,
        serviceType: data?.service_type,
        billingType: data?.billing_type,
        mobileno: data?.mobile_no,
        billType: data?.billing_type,
        paidBy: "",
        email: data?.email_id,
        area: data?.area,
        flat: data?.flat_no,
        floor: data?.floor,
        wings: data?.wings,
        city: data?.city,
        state: data?.state,
        status: moment(data?.created_at).format("ll"),
        action: (
          <div className="flex-row justify-center">
            <div
              className="cursor-pointer"
              onClick={() =>
                dispatch(
                  deleteAssignSubs({
                    _id: data?._id,
                    whatsapp_access: user?.whatsapp_access
                  })
                )
              }
            >
              <DeleteIcon />
            </div>
          </div>
        ),
      });
    });
    setexpList(list);
  }, [SubscriberListAssignedData]);

  const handleSearch = (search) => {
    let user = JSON.parse(localStorage.getItem("user"));
    setPage(1);
    setSearchQuery(search);
    dispatch(SubscriberListAssigned(`distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&value=${search}`));
  };

  const handleChange = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(
      SubscriberListAssigned(
        `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=${
          value - 1
        }0${searchQuery ? `&value=${searchQuery}` : ""}`
      )
    );
  };

  const handleClickCancel = () => {
    setOpen(!open);
  };

  const handleClickCancelAll = () => {
    setopenAll(!openAll);
  };

  const handleClickDelete = () => {
    let filtered = expList.filter((options) => options.checked === true);
    let preparedeletepay = [];
    filtered.map((data, index) => {
      preparedeletepay.push({
        is_deleted: true,
        _id: data.id,
      });
    });
    dispatch(SubscriberListMultipleDelete(preparedeletepay));
    setChecked(false)
    handleClickCancel()    
  };

  const handleAllDelete = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    const payload={
      "distributor_id": user?.distributor_ref_id,
      "sms_access": user?.sms_access,
      "whatsapp_access": user?.whatsapp_access,
    }
    dispatch(AllSubscribersDelete(payload));
     
  };

  const handleCheckBox = (index, checked) => {
    var table = document.getElementById("tableId");
    var row = table.rows[index];
    console.log(table.rows,"row");
    
    const clonedDataService = [...expList];
    console.log(expList,"expList")
    if (index === 0 || index > 0) {
      clonedDataService[index].checked = clonedDataService[index].checked
        ? false
        : true;
    } else if (index === "all") {
      for (let services of clonedDataService) {
        if (checked) {
          services.checked = true;
        } else {
          services.checked = false;
        }
      }
    }
    setexpList(clonedDataService);
    setChecked(checked)
  };

  const handleMultipleDelete = () => { 
    let filtered = expList.filter((options) => options.checked === true);
    if (filtered.length == 10) {
      setOpen(!open);
      return;
    }
    if (filtered.length == 0) {
      enqueueSnackbar("Please select atlease one to delete!", {
        variant: "error",
      });
      setShow(!show);
      return;
    }
    let preparedeletepay = [];
    filtered.map((data, index) => {
      preparedeletepay.push({
        is_deleted: true,
        _id: data.id,
      });
    });
    dispatch(SubscriberListMultipleDelete(preparedeletepay));   
  };

  const handleAllSubscribersDelete = () => { 
    setopenAll(!openAll);
  }

  const handleRedirect = () => {
    navigate("/distributor/delete-restore-subscriber");
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };



  return (
    <div>
      <div className="deleteSubsList-container">
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <div className="employeelist-title">Delete Subscriber</div>
          </Grid>
          <Grid item xs={4} className="flex-end">
            <div className="employeelist-title">
              <BreadCrumb
                defaultHeading="Home"
                handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
                secondaryHeading="Subscriber List"
                handleSecondaryClick={() =>
                  handleBreadCrumbRedirect("subscriber-list")
                }
                activeHeading="Delete Subscriber"
                handleActiveClick={() =>
                  handleBreadCrumbRedirect("delete-subscriber")
                }
              />
            </div>
          </Grid>
        </Grid>

        <div className="search-btns-padding">
          <SearchWithButtons
            primaryButton={{
              title: "Delete",
              type: "delete",
              color: "white",
              bgColor: COLORS.primary,
            }}
            secondaryButton={{
              title: "Restore Subscriber",
              type: "outlined",
              color: COLORS.primary,
            }}
            teritorybutton={{
              title: "Delete All",
              type: "delete",
              color: "white",
              bgColor: COLORS.primary,
            }}
            handleSecondaryClick={handleRedirect}
            handlePrimaryClick={handleMultipleDelete}
            handleTeritoryClick={handleAllSubscribersDelete}
            handleChange={handleSearch}
          />
        </div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: "15px",
            paddingBottom: "10px",
          }}
        >
          Showing entries {(page - 1) * 10 + 1} -{" "}
          {Math.ceil(SubscriberListAssignedData?.totalCount / 10) == page
            ? SubscriberListAssignedData?.totalCount
            : page + "0"}{" "}
          of {SubscriberListAssignedData?.totalCount}
        </div>

        <TableView
          rows={tableRows}
          data={expList}
          color={COLORS.primary}
          handleCheckBoxChange={handleCheckBox}
          checkedVal={checkedVal}
        />
         <SweetAlert
          warning
          btnSize="sm"
          custom
          show={openAll}
          //mnncustomIcon={<InfoOutlinedIcon fontSize={'20px'}/>}
          customButtons={
            <div className="dips" style={{ display: "flex" }}>
              {
                <Stack spacing={2} direction="row" className=" ">
                  <Button
                    type="submit"
                    onClick={handleAllDelete}
                    variant="contained"
                    className="bg-blue"
                  >
                    Delete All
                  </Button>
                </Stack>
              }
              &nbsp;&nbsp;
              {
                <Stack spacing={2} direction="row" className=" ">
                  <Button
                    type="submit"
                    onClick={handleClickCancelAll}
                    variant="contained"
                    className="bg-blue"
                  >
                    Cancel
                  </Button>
                </Stack>
              }
            </div>
          }
          title={
            <span className={"confirmationTitle"}>
              <span>Are you sure want to delete all the subscribers?</span>
            </span>
          }
        >

        </SweetAlert>
        <SweetAlert
          warning
          btnSize="sm"
          custom
          show={open}
          //mnncustomIcon={<InfoOutlinedIcon fontSize={'20px'}/>}
          customButtons={
            <div className="dips" style={{ display: "flex" }}>
              {
                <Stack spacing={2} direction="row" className=" ">
                  <Button
                    type="submit"
                    onClick={handleClickDelete}
                    variant="contained"
                    className="bg-blue"
                  >
                    Submit
                  </Button>
                </Stack>
              }
              &nbsp;&nbsp;
              {
                <Stack spacing={2} direction="row" className=" ">
                  <Button
                    type="submit"
                    onClick={handleClickCancel}
                    variant="contained"
                    className="bg-blue"
                  >
                    Cancel
                  </Button>
                </Stack>
              }
            </div>
          }
          title={
            <span className={"confirmationTitle"}>
              <span>Are you sure want to delete?</span>
            </span>
          }
        >

        </SweetAlert>
        <div
          style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}
        >
          <Pagination
            count={Math.ceil(SubscriberListAssignedData?.totalCount / 10) || 0}
            defaultPage={1}
            page={page}
            onChange={(event, value) => {
              setPage(value);
              handleChange(value);
            }}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
}
