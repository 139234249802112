import AddTicketForm from "@components/BulkSmsForm";
import Grid from "@mui/material/Grid";
import TicketStatus from "@components/TicketStatus";
import LanguageIcon from "@mui/icons-material/Language";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DescriptionIcon from "@mui/icons-material/Description";
import ComputerIcon from "@mui/icons-material/Computer";
import FolderIcon from "@mui/icons-material/Folder";
import "./styles.scss";
import { ticketList, updateTicket, bulkSend,ticketOpenStatus,smsType,smsArea,getWhatsappList} from "@reducers/api";
import { reset } from "@reducers/ticketing";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useComponentDidMount, paramsQuery, useComponentDidUpdate, useComponentWillUnmount } from "@utils/useEffectHooks";
import BreadCrumb from "@components/Breadcrumb";


export default function AddTicket(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  let user = JSON.parse(localStorage.getItem("user"));
  let accountId = user?.account_id;
  const [expList, setexpList] = useState([]);
    const [templateList, settemplateList] = useState([]);

  const { ticketListData, ticketAdded,ticketStatusOpenData , smsTypeData, smsAreaData, bulksmsSend } = useSelector((state) => state.ticketing);
  let filtered = ticketListData?.data && ticketListData?.data.filter((data) => data._id === paramsQuery("id"));
  const defaultPlanQuery = {
    serviceType: "cable",
    billingType: "",
  };

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&type=${`sms`}`;
    
    return query;
  };

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
    let query = queryGenerator();
    dispatch(smsType(query));
    dispatch(smsArea(query));
  }, []);

  

  useComponentDidUpdate(() => {
    let id = paramsQuery("id");
    let message = "";
    if (bulksmsSend) {
   
        message = "Message Sent Successfully";
     
      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/distributor/settings`);
    }
  }, [bulksmsSend]);

  useComponentWillUnmount(() => {
    dispatch(reset());
  });  

  
 
  const handleFormValues = (values, accessType) => {
    let id = paramsQuery("id");
    let user = JSON.parse(localStorage.getItem("user"));
    console.log(values, "valid")
    let payload = {
     

      "distributor_ref_id": user?.distributor_ref_id,
      "areas": values?.ticketType,
      "subArea": values?.subArea,
      "type": accessType,
      "title": values?.accountId?.title,
      "description": values.description,
      "_id": values?.accountId?._id,
    };
    {
      dispatch(bulkSend(payload));
    }
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  const handleChangeTemplates = (type) => {
      let user = JSON.parse(localStorage.getItem("user"));
      let query = `distributor_ref_id=${user?.distributor_ref_id}&type=${type}`;
      dispatch(smsType(query));
    // console.log(values, "valid")
    // let payload = {
     

    //   "distributor_ref_id": user?.distributor_ref_id,
    //   "areas": values?.ticketType,
    //   "subArea": values?.subArea,
    //   "type": accessType,
    //   "title": values?.accountId?.title,
    //   "description": values.description,
    //   "_id": values?.accountId?._id,
    // };
    // {
    //   dispatch(bulkSend(payload));
    // }
  };

  const totalOpenTicket = parseInt(ticketStatusOpenData.openTicketCount ? ticketStatusOpenData?.openTicketCount : 0) + parseInt(ticketStatusOpenData.closedTicketCount ? ticketStatusOpenData?.closedTicketCount : 0);
  const totalProgressTicket = parseInt(totalOpenTicket) + parseInt(ticketStatusOpenData.pendingTicketCount ? ticketStatusOpenData?.pendingTicketCount : 0);
  const totalTicket = parseInt(totalProgressTicket) + parseInt(ticketStatusOpenData?.inprogressTicketCount ? ticketStatusOpenData?.inprogressTicketCount : 0);



  return (
    <>
      <div className="ticketList-container bulksms-container">
        <Grid container spacing={2}>
          <Grid item xs={4} md={8}>
          <div className="planHeading">Template</div>
          </Grid>
          <Grid item xs={8} md={4} className="flex-end">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              secondaryHeading="Settings"
              handleSecondaryClick={() => handleBreadCrumbRedirect("settings")}
              activeHeading={ "Bulk Email/SMS/Whatsapp"}
            />
          </Grid>
        </Grid>
        
        <div className="addTicket-container">
          <AddTicketForm data={filtered && filtered[0]} templateList={templateList} handleChangeTemplates={handleChangeTemplates} smsType={smsTypeData} smsArea={smsAreaData} handleFormValues={(values, accessType) => handleFormValues(values, accessType)} />
        </div>
      </div>
    </>
  );
}
