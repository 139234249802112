import React from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import Stack from "@mui/material/Stack";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import Autocomplete from "@mui/material/Autocomplete";
import { useComponentDidUpdate, useComponentDidMount } from "@utils/useEffectHooks";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const validation = Yup.object({
  expense_name: Yup.string().required("Required").nullable(),
  expense_amount: Yup.string().required("Required").nullable(),
  expense_type: Yup.object().required("Required").nullable(),
  assigned_to: Yup.object().required("Required").nullable(),
  description: Yup.string().required("Required").nullable(),
});

const type = [
  {
    label: "TRANSPORT",
    value: "transport",
  },
  {
    label: "ADVANCE",
    value: "advance",
  },
  {
    label: "SPLICING",
    value: "splicing",
  },
  {
    label: "OTHERS",
    value: "others",
  },
];

export default function ExpensesForm(props) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });

  const onSubmit = (data) => props?.handleFormValues(data);
  const navigate = useNavigate();

  const filterObjectEmail = (options, value) => {
    let filtered = options && options.filter((options) => options?.email_id.toString() === value?.toString());
    return filtered[0] || "";
  };

  const filterObject = (options, value) => {
    let filtered = options && options.filter((options) => options?.value.toString() === value?.toString());
    return filtered[0] || "";
  };

  useComponentDidMount(() => {
    setValue("expense_name", props?.expenseListData?.expense_name);
    setValue("expense_amount", props?.expenseListData?.expense_amount);
    setValue("description", props?.expenseListData?.description);
    setValue("assigned_to",  filterObjectEmail(props?.employeeData, props?.expenseListData?.assigned_to));
    setValue("expense_type", props?.expenseListData?.expense_type ? filterObject(type, props?.expenseListData?.expense_type) : "");
  }, []);

  const handleRedirect = () => {
    navigate(`/distributor/expenses-list`);
  };


  return (
    <div className="expense-form-container">
      <div class="center expenseForm">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12} sm={12} md={12} spacing={4} className="createHeader">
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <div className="expense-title ">Expense</div>
              </Grid>
              <Grid item xs={4}>
                <div className="floatRight">
                  <DonutLargeIcon />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput createSearchForm">
            <TextField {...register("expense_name")} placeholder="Enter Expense Name" className="txtwidth" size="small" />
            <div className="field-error">{errors.expense_name?.message}</div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
            <Controller
              control={control}
              name="expense_type"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={type}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => <TextField {...params} size="small" placeholder="Select Expense Type" />}
                />
              )}
            />
            <div className="field-error">{errors?.expense_type?.message}</div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
            <TextField {...register("expense_amount")} type="number" placeholder="Enter Expense Amount" className="txtwidth" size="small" />
            <div className="field-error">{errors?.expense_amount?.message}</div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
            <Controller
              control={control}
              name="assigned_to"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={props?.employeeData || []}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  getOptionLabel={(item) => (item.name ? `${item.name} ` : "")}
                  renderInput={(params) => <TextField {...params} size="small" placeholder="Assigned to" />}
                />
              )}
            />
            <div className="field-error">{errors?.assigned_to?.message}</div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
            <TextField {...register("description")} placeholder="Enter Description" className="txtwidth" size="small" minRows={3} />
            <div className="field-error">{errors?.description?.message}</div>
          </Grid>
          <Grid item xs={12} className="paddingInput">
            <Stack spacing={2} direction="row" className="btnright">
            <Button
                  size="small"
                  variant="outlined"
                  className="btn-outlined"
                  onClick={handleRedirect}
                  style={{ textTransform: "none", color: "#00249b" }}
                >
                  Cancel
                </Button>
              <Button type="submit" variant="contained" size="small" className="bg-blue">
              {props?.expenseListData?._id ? "Update" : "Create"}
              </Button>
            </Stack>
          </Grid>
        </form>
      </div>
    </div>
  );
}
