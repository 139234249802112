import React, { useState } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { COLORS } from "@constants";
import { broadcast } from "./data";
import "./style.scss";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import Stack from "@mui/material/Stack";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useComponentDidUpdate } from "@utils/useEffectHooks";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import InputBase from "@mui/material/InputBase";
import { useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import { useDispatch, useSelector } from "react-redux";
import { alaCartList } from "@reducers/api";


const validation = Yup.object({
  planName: Yup.string().required("Required").nullable(),
  planAmount: Yup.string().required("Required").nullable(),
  serviceType: Yup.object().required("Required").nullable(),
  billingType: Yup.object().required("Required").nullable(),
  packageValidity: Yup.object().required("Required").nullable(),
  packageCategory: Yup.object().required("Required").nullable(),
  disablePlan: Yup.object().required("Required").nullable(),
  channelType: Yup.object().required("Required").nullable(),
});

const serviceType = [{ id: 1, label: "Cable", value: "cable" }];

const billingType = [{ id: 1, label: "Pre Paid", value: "pre paid" }];

const packageValidity = [{ id: 1, label: "1 Month", value: "1 months" }];

const packageCategory = [
  { id: 1, label: "ALA Cart", value: "ala cart" },
  { id: 2, label: "BROADCASTER", value: "broadcaster" },
];

const broadCasterBoquet = [
  { id: 1, label: "Broadcaster", value: "" },
  { id: 2, label: "Discovery", value: "discovery" },
  { id: 3, label: "Disney", value: "disney" },
  { id: 4, label: "ETPL", value: "etpl" },
  { id: 5, label: "JAYA", value: "jaya" },
  { id: 6, label: "SUN", value: "sun" },
  { id: 7, label: "ZEE", value: "zee" },
];

const channelType = [
  { id: 1, label: "HD", value: "HD" },
  { id: 2, label: "SD", value: "SD" },
];

const disablePlan = [
  { id: 1, label: "Yes", value: true },
  { id: 2, label: "No", value: false },
];



const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function CreatePlanForm(props) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });
  const onSubmit = (data, broadcastTypes) => props?.handleFormValues(data, broadcastType);
  const [broadcastType, setBroadcastType] = useState("");
  const navigate = useNavigate();
  const [alacartList, setAlaCartList] = useState([]);
  const [searchList, setsearchList] = useState([]);
  const [clonedList, setClonedList] = useState([]);
  const [checkedData, setCheckedList] = useState([]);

  const handleSearchAlacart = (search) => {
    console.log(alacartList, "props")
    let _users = alacartList.filter(function (user) {
      return user.plan_name.toLowerCase().match(search);
    });

    setsearchList(_users)
    
  };

  

  const onEditClick = (e, data, index) => {
    e.stopPropagation();
    props?.addBroadCastitems(data, index);
  };

  const filterObject = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered = options && options.filter((options) => options?.value?.toString() === value?.toString());
    setValue(title, filtered[0]);
  };

  useComponentDidUpdate(() => {
    filterObject("serviceType", serviceType, props?.data?.service_type);
    filterObject("billingType", billingType, props?.data?.billing_type);
    filterObject("packageValidity", packageValidity, props?.data?.plan_validity);
    filterObject("packageCategory", packageCategory, props?.data?.package_category);
    filterObject("disablePlan", disablePlan, props?.data?.disabled);
    filterObject("channelType", channelType, props?.data?.channel_type);
    setValue("planName", props?.data?.plan_name);
    setValue("planAmount", props?.data?.plan_amount);
    setValue("broadcaster", props?.data?.broadcaster);
  }, [props.data]);

  const handleRedirect = () => {
    navigate(`/admin/plan-list`);
  };

  const handleCheckBoxAlacart = (index, checked) => {
    const clonedDataService = [...searchList];
    if (index === 0 || index > 0) {
      clonedDataService[index].checked = clonedDataService[index]?.checked
        ? false
        : true;
    } else if (index === "all") {
      for (let services of clonedDataService) {
        if (checked) {
          services.checked = true;
        } else {
          services.checked = false;
        }
      }
    }

    setClonedList(clonedDataService);

    let filteredarrays = alacartList?.filter(
      (serviceList) => serviceList.checked === true
    );

    setBroadcastType(filteredarrays)

  };


  let filteredarray = alacartList?.filter(
    (serviceList) => serviceList.checked === true
  );

  useComponentDidUpdate(() => {

    let checkedList = [];
    let list = [];
    props?.alaCartListFilter &&
      props?.alaCartListFilter?.map((data, index) => {
        let filter = props?.data ? props?.data?.channels?.filter(
          (serviceList) => serviceList?.plan_name === data?.plan_name
        ) : ""
        if (filter?.length > 0) {
          filter.map((datachannel, index) => {
            checkedList.push({
              checked: true,
              plan_name: data?.plan_name,
              plan_amount: data?.plan_amount,
              channel_type: data?.channel_type,
              _id: data?._id,
            });
          });

        } else {
          checkedList.push({
            checked: false,
            plan_name: data?.plan_name,
            plan_amount: data?.plan_amount,
            channel_type: data?.channel_type,
            _id: data?._id,
          });
        }

      });


    setAlaCartList(checkedList);
    setsearchList(checkedList)

  }, [props?.alaCartListFilter, props?.data]);

  return (
    <Grid className="addPlanForm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 10 }} className="formCreatePlan">
          <Grid item xs={4} sm={6} md={6} className="autocomplete">
            <label>Service Type</label>
            <Controller
              control={control}
              name="serviceType"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={serviceType}
                  isOptionEqualToValue={(option) => option.label == value?.label || ""}
                  getOptionLabel={(item) => (item.label ? item.label : "")}
                  getOptionSelected={(option, value) => value === undefined || value === "" || option.id === value.id}
                  renderInput={(params) => <TextField {...params} size="small" placeholder="Select Service Type" />}
                />
              )}
            />
            <div className="field-error">{errors.serviceType?.message}</div>
          </Grid>
          <Grid item xs={4} sm={6} md={6} className="autocomplete">
            <label>Package Validity</label>
            <Controller
              control={control}
              name="packageValidity"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={packageValidity}
                  isOptionEqualToValue={(option) => option.label == value?.label || ""}
                  getOptionLabel={(item) => (item.label ? item.label : "")}
                  getOptionSelected={(option, value) => value === undefined || value === "" || option.id === value.id}
                  renderInput={(params) => <TextField {...params} size="small" placeholder="Select Package Validity" />}
                />
              )}
            />
            <div className="field-error">{errors.packageValidity?.message}</div>
          </Grid>
          <Grid item xs={4} sm={6} md={6} className="autocomplete">
            <label>Billing Type</label>
            <Controller
              control={control}
              name="billingType"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={billingType}
                  isOptionEqualToValue={(option) => option.label == value?.label || ""}
                  getOptionLabel={(item) => (item.label ? item.label : "")}
                  getOptionSelected={(option, value) => value === undefined || value === "" || option.id === value.id}
                  renderInput={(params) => <TextField {...params} size="small" placeholder="Select Billing Type" />}
                />
              )}
            />
            <div className="field-error">{errors.billingType?.message}</div>
          </Grid>
          <Grid item xs={4} sm={6} md={6} className="autocomplete">
            <label>Package Category</label>
            <Controller
              control={control}
              name="packageCategory"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={packageCategory}
                  isOptionEqualToValue={(option) => option.label == value?.label || ""}
                  getOptionLabel={(item) => (item.label ? item.label : "")}
                  getOptionSelected={(option, value) => value === undefined || value === "" || option.id === value.id}
                  renderInput={(params) => <TextField {...params} size="small" placeholder="Select Package Category" />}
                />
              )}
            />
            <div className="field-error">{errors.packageCategory?.message}</div>
          </Grid>
          <Grid item xs={4} sm={6} md={6}>
            <label>Plan Name</label>
            <TextField {...register("planName")} placeholder="Enter Plan Name" size="small" className="txtwidth" variant="outlined" id="outlined-basic" />
            <div className="field-error">{errors.planName?.message}</div>
          </Grid>
          <Grid item xs={4} sm={6} md={6}>
            <label>Amount</label>
            <TextField placeholder="Enter Plan Amount" size="small" {...register("planAmount")} type="number" className="txtwidth" variant="outlined" id="outlined-basic" />
            <div className="field-error">{errors.planAmount?.message}</div>
          </Grid>
          <Grid item xs={4} sm={6} md={6} className="autocomplete">
            <label>Disable Plan</label>
            <Controller
              control={control}
              name="disablePlan"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={disablePlan}
                  isOptionEqualToValue={(option) => option.label == value?.label || ""}
                  getOptionLabel={(item) => (item.label ? item.label : "")}
                  getOptionSelected={(option, value) => value === undefined || value === "" || option.id === value.id}
                  renderInput={(params) => <TextField {...params} size="small" placeholder="Select Disable Plan" />}
                />
              )}
            />
            <div className="field-error">{errors.disablePlan?.message}</div>
          </Grid>
          <Grid item xs={4} sm={6} md={6} className="autocomplete">
            <label>Channel Type</label>
            <Controller
              control={control}
              name="channelType"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={channelType}
                  isOptionEqualToValue={(option) => option.label == value?.label || ""}
                  getOptionLabel={(item) => (item.label ? item.label : "")}
                  getOptionSelected={(option, value) => value === undefined || value === "" || option.id === value.id}
                  renderInput={(params) => <TextField {...params} size="small" placeholder="Select Channel Type" />}
                />
              )}
            />
            <div className="field-error">{errors.channelType?.message}</div>
          </Grid>
          {watch("packageCategory") && watch("packageCategory").value === "broadcaster" && (
            <Grid item xs={4} sm={6} md={6} className="autocomplete">
              <label>Broadcaster</label>
              <Controller
                control={control}
                name="broadcaster"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || ""}
                    options={props?.broadcastList && props?.broadcastList?.broadcasterList}
                    isOptionEqualToValue={(option) => option == value || ""}
                    getOptionLabel={(item) => (item ? item : "")}
                    getOptionSelected={(option, value) => value === undefined || value === "" || option.id === value.id}
                    renderInput={(params) => <TextField {...params} size="small" placeholder="Select Channel Type" />}
                  />
                )}
              />


            </Grid>
          )}

        </Grid>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 10 }} className="formCreatePlan">
          {watch("packageCategory") && watch("packageCategory").value === "broadcaster" && (

            <Grid item xs={4} sm={6} md={6} className="autocomplete">

              {
                watch("broadcaster") ? (
                  <TextField disabled {...register("broadcasterTextField")} placeholder="Select broadcaster" size="small" className="txtwidth" variant="outlined" id="outlined-basic" />
                )
                  :
                  (
                    <TextField {...register("broadcasterTextField")} placeholder="Select broadcaster" size="small" className="txtwidth" variant="outlined" id="outlined-basic" />
                  )
              }

            </Grid>
          )}

        </Grid>

        {watch("packageCategory") && watch("packageCategory").value === "broadcaster" && (
          <>
            <Grid item xs={11} sm={4} md={4} style={{ paddingLeft: 30 }}>
              <InputBase
                className="search-input"
                size="small"
                onChange={(e) => {
                  handleSearchAlacart(e.target.value);
                }}
                placeholder="Search By"
                // endAdornment={
                //   <InputAdornment position="end">
                //     <SearchIcon style={{ color: COLORS.primary }} />
                //   </InputAdornment>
                // }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  style: { color: COLORS.primary },
                }}
              />
            </Grid>
            <div style={{ paddingLeft: 30 }}>
              <p>Selected Count : {filteredarray?.length}</p>
            </div>
            <div style={{ paddingLeft: 30, paddingRight: 30, marginBottom: 20 }}>


              <Grid item xs={12} sm={4} md={12}>
                <Grid
                  container
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  style={{ height: "300px", overflow: "scroll" }}
                >
                  {searchList?.map((prod, i) => (
                    <Grid item xs={4} sm={4} md={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                handleCheckBoxAlacart(i);
                              }}
                              sx={{
                                "&.Mui-checked": {
                                  color: COLORS.primary,
                                },
                              }}
                              {...label}
                              checked={prod?.checked}
                              size="small"
                            />
                          }
                          label={prod?.plan_name}
                        />
                        <div className="marginLeftAddplan">
                          Rs:{prod?.plan_amount}
                        </div>
                      </FormGroup>
                    </Grid>
                  ))}
                </Grid>
              </Grid>



            </div>
          </>
        )}
        <Stack direction="row" className="flex-end btns-stack" spacing={2}>
          <Button size="small" variant="outlined" className="btn-outlined" onClick={handleRedirect} style={{ textTransform: "none", color: "#00249b" }}>
            Cancel
          </Button>
          <Button type="submit" size="small" variant="contained" className="bg-blue" style={{ textTransform: "none" }}>
            {props?.data?._id ? "Update Plan" : "Create Plan"}
          </Button>
        </Stack>
      </form>
    </Grid>
  );
}
