import React from "react";
import { useState } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import CancelIcon from "@mui/icons-material/Cancel";
import PhoneIcon from "@mui/icons-material/Phone";
import logo from "@assets/images/avathar.png";
import "./style.scss";
import {
  useComponentDidMount,
  useComponentDidUpdate,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/auth";
import EditIcon from "@mui/icons-material/Edit";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  profileEdit,
  disprofileimageUpload,
  profileView,
  disprofileimageUploadAdmin,
  updateDistributor,
} from "@reducers/api";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { styled } from "@mui/material/styles";
import BreadCrumb from "@components/Breadcrumb";
import { useNavigate } from "react-router-dom";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import Resizer from "react-image-file-resizer";
import SmsIcon from '@mui/icons-material/Sms';
import { Autocomplete } from "@mui/material";
import { useForm, Controller } from "react-hook-form";

const Input = styled("input")({
  display: "none",
});

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const validation = Yup.object({
  name: Yup.string().required("Required"),
  mail: Yup.string()
    .required("Required")

    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    ),
  mobileNo: Yup.string()
    .required("Required")
    .max(10, "Must be exactly 10 digits")
    .matches(new RegExp("[0-9]{10}"), "Phone number is not valid"),
  technicianNumber: Yup.string()
    .required("Required")
    .max(10, "Must be exactly 10 digits")
    .matches(new RegExp("[0-9]{10}"), "Technician number is not valid"),
});

export default function Profile(props) {
  const navigate = useNavigate();

  const {
    values,
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });
  const onSubmit = (data) => handleFormValues(data);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleFormValues = (values) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let id = user?._id;

    let payload = {
      _id: id,
      username: values.name,
      email_id: values.mail,
      mobile_no: values.mobileNo,
      technicianNumber: values.technicianNumber,
      sms_access: values.smsaccess?.value,
      whatsapp_access: values.whatsappaccess?.value,
    };
    {
      dispatch(profileEdit(payload));
    }
  };

  const {
    profileViewData,
    profileAdded,
    disprofileimageUploadData,
    disprofileimageUploadAdminData,
  } = useSelector((state) => state.auth);
  const { distributorAdded } = useSelector((state) => state.distributor);

  const [isPreviewShown, setPreviewShown] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);

  let imagePreview = null;
  if (selectedImage) {
    imagePreview = (
      <img
        className="img-previewUpload"
        src={URL.createObjectURL(selectedImage)}
        alt={"Not found"}
      />
    );
  } else {
    imagePreview = profileViewData?.profileFileData ? (
      <img
        className="img-previewUpload"
        src={`data:image/png;base64,${profileViewData?.profileFileData}`}
        alt={"Iam here"}
      />
    ) : (
      <PersonIcon
        style={{
          color: "grey",
          marginLeft: "10px",
          fontSize: "7rem",
          marginTop: "1rem",
        }}
      />
    );
  }

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let id = user?._id;
    let payload = {
      _id: id,
    };
    dispatch(profileView(payload));
  }, []);

  const handlePreview = (e) => {
    setPreviewShown(true); // Here we change state
  };

  const closePic = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let payloadDis = {
      profile_pic_delete: "success",
      _id: user?.distributor_ref_id,
    };

    let payloadAdm = {
      profile_pic_delete: "success",
      _id: user?._id,
    };
    const fileUploadUrl = user?.distributor_ref_id
      ? dispatch(updateDistributor(payloadDis))
      : dispatch(profileEdit(payloadAdm));
  };

  const handleImgChange = async (event) => {
    let user = JSON.parse(localStorage.getItem("user"));
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = async () => {
      const image = await resizeFile(event.target.files[0]);

      const dataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(",");
        const byteString =
          splitDataURI[0].indexOf("base64") >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++)
          ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
      };

      const newFile = dataURIToBlob(image);

      const formData = new FormData();
      const formDataAdmin = new FormData();
      formDataAdmin.append("file", newFile);
      formDataAdmin.append("id", user?._id);

      if (user?.role == "distributor") {
        formData.append("id", user?.distributor_ref_id);
        formData.append("type", "profile_url");
        formData.append("file", newFile);
      }

      let payload = {
        file: formData,
        disId: user?.distributor_ref_id,
      };

      let payloadAdmin = {
        file: formDataAdmin,
        id: user?._id,
      };
      const fileUploadedUrl = user?.distributor_ref_id
        ? await dispatch(disprofileimageUpload(payload))
        : await dispatch(disprofileimageUploadAdmin(payloadAdmin));
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  useComponentDidUpdate(() => {
    console.log(profileViewData,"profileViewData")
    setValue("name", profileViewData.username);
    setValue("mail", profileViewData.email_id);
    setValue("mobileNo", profileViewData.mobile_no);
    filterObject("smsaccess", SmsOptions, profileViewData.sms_access);
    filterObject("whatsappacess", SmsOptions, profileViewData.whatsapp_access);
    setValue("technicianNumber", profileViewData.technicianNumber);
  }, [profileViewData]);

  const filterObject = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options.value.toString() === value.toString()
      );
    setValue(title, filtered[0]);
    console.log(title, filtered[0], "filteru")
  };

  useComponentDidUpdate(() => {
    if (profileAdded) {
      enqueueSnackbar("Profile Updated !", {
        variant: "success",
      });
      setPreviewShown(false);
      dispatch(reset());

      let user = JSON.parse(localStorage.getItem("user"));
      let id = user?._id;
      let payload = {
        _id: id,
      };
      dispatch(profileView(payload));
    }
  }, [profileAdded]);

  useComponentDidUpdate(() => {
    if (disprofileimageUploadData) {
      enqueueSnackbar("Profile Updated !", {
        variant: "success",
      });
      dispatch(reset());
      let user = JSON.parse(localStorage.getItem("user"));
      let id = user?._id;
      let payload = {
        _id: id,
      };
      dispatch(profileView(payload));
    }
  }, [disprofileimageUploadData]);

  useComponentDidUpdate(() => {
    if (distributorAdded) {
      enqueueSnackbar("Profile Deleted !", {
        variant: "success",
      });
      dispatch(reset());
      let user = JSON.parse(localStorage.getItem("user"));
      let id = user?._id;
      let payload = {
        _id: id,
      };
      dispatch(profileView(payload));
      window.location.reload();
    }
  }, [distributorAdded]);

  useComponentDidUpdate(() => {
    if (disprofileimageUploadAdminData) {
      enqueueSnackbar("Profile Updated !", {
        variant: "success",
      });
      dispatch(reset());
      let user = JSON.parse(localStorage.getItem("user"));
      let id = user?._id;
      let payload = {
        _id: id,
      };
      dispatch(profileView(payload));
    }
  }, [disprofileimageUploadAdminData]);

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  const SmsOptions = [
    { label: "Enable", value: 'enable' },
    { label: "Disable", value: 'disable' },
  ];

  return (
    <div className="addEmployee-container profile-container">
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} className="">
            <Grid item xs={12} sm={8}>
              <div className="planHeading displayFlex ">
                <PersonIcon style={{ marginTop: "1rem" }} />

                <p>Profile</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}className="flex-end">
              <div
                className="planHeading breadcrumbPadding"
                style={{ marginTop: "1rem" }}
              >
                <BreadCrumb
                  defaultHeading="Home"
                  handleDefaultClick={() =>
                    handleBreadCrumbRedirect("dashboard")
                  }
                  activeHeading="Profile"
                />
              </div>
            </Grid>
          </Grid>
          <div class="centerProfile expenseForm profile-list-table-cntr">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              spacing={4}
              className="paddingInput"
            >
              {/* <img className="profileIcon"src={logo}></img> */}
              <Grid container spacing={2} style={{ justifyContent: "center" }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={2}
                  xl={2}
                  style={{ position: "relative" }}
                >
                  <div className="ProfileImg">
                    <div className="imgPreview">{imagePreview}</div>
                    {isPreviewShown && (
                      <label htmlFor="contained-button-file">
                        <Input
                          accept="image/*"
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={(event) => {
                            console.log(event, "event")
                            setSelectedImage(event.target.files[0]);
                            handleImgChange(event);
                          }}
                        />
                        <Button
                          variant="contained"
                          component="span"
                          className="bg-blue"
                          size="small"
                          sx={{
                            fontSize: 13,
                            textTransform: "capitalize",
                            width: 138,
                          }}
                        >
                          <DriveFolderUploadIcon /> &nbsp; Start Upload
                        </Button>
                      </label>
                    )}
                    {isPreviewShown && (
                      <Button
                        variant="contained"
                        onClick={() => closePic()}
                        component="span"
                        className="bg-blue profileDelete"
                        size="small"
                        sx={{
                          fontSize: 13,
                          textTransform: "capitalize",
                          width: 138,
                        }}
                      >
                        Delete Pic
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="paddingInput text-align"
            >
              <div className="">
                {isPreviewShown === false && (
                  <div className="flex-justify-center textCenterProfile">
                    <h4 className="colorBlue">{profileViewData.username}</h4>
                    <EditIcon
                      onClick={handlePreview}
                      style={{
                        fill: "grey",
                        marginTop: "21px",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                )}
                {isPreviewShown && (
                  <div>
                    <TextField
                      {...register("name")}
                      size="small"
                      className=""
                      type="text"
                      variant="outlined"
                      id="outlined-basic"
                    />
                    <div className="field-error">{errors.name?.message}</div>
                  </div>
                )}
              </div>

              <div>
                <p>{capitalizeFirstLetter(profileViewData.role)}</p>
              </div>
            </Grid>
            <Grid item xs={12} md={6} className="marginAuto profileRes">
              <List className="labelWidth">
                <ListItem>
                  <ListItemIcon>
                    <PersonIcon style={{ fill: "grey" }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ width: "106px", flex: "0 0 auto" }}
                    className="labelText mm"
                    primary="Account ID"
                  />
                  <ListItemText primary={profileViewData.account_id} />
                </ListItem>
              </List>
              <List className="labelWidth">
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon style={{ fill: "grey" }} />
                  </ListItemIcon>
                  <ListItemText
                    className="labelText"
                    style={{ width: "110px", flex: "0 0 auto" }}
                    primary="Mail address"
                  />
                  {isPreviewShown === false && (
                    <ListItemText primary={profileViewData.email_id} />
                  )}
                  {isPreviewShown && (
                    <div>
                      <TextField
                        {...register("mail")}
                        size="small"
                        className=""
                        type="text"
                        variant="outlined"
                        id="outlined-basic"
                      />
                      <div className="field-error">{errors.mail?.message}</div>
                    </div>
                  )}
                </ListItem>
              </List>

              <List className="labelWidth">
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon style={{ fill: "grey" }} />
                  </ListItemIcon>
                  <ListItemText
                    className="labelText"
                    style={{ width: "110px", flex: "0 0 auto" }}
                    primary="Phone"
                  />
                  {isPreviewShown === false && (
                    <ListItemText primary={profileViewData.mobile_no} />
                  )}
                  {isPreviewShown && (
                    <div style={{ marginTop: "10px" }}>
                      <TextField
                        {...register("mobileNo")}
                        size="small"
                        className=""
                        type="number"
                        variant="outlined"
                        id="outlined-basic"
                      />
                      <div className="field-error">
                        {errors.mobileNo?.message}
                      </div>
                    </div>
                  )}
                </ListItem>
              </List>

              <List className="labelWidth">
                <ListItem>
                  <ListItemIcon>
                    <PermPhoneMsgIcon style={{ fill: "grey" }} />
                  </ListItemIcon>
                  <ListItemText
                    className="labelText"
                    style={{ width: "110px", flex: "0 0 auto" }}
                    primary="Technician number"
                  />
                  {isPreviewShown === false && (
                    <ListItemText primary={profileViewData.technicianNumber} />
                  )}
                  {isPreviewShown && (
                    <div>
                      <TextField
                        {...register("technicianNumber")}
                        size="small"
                        className=""
                        type="number"
                        variant="outlined"
                        id="outlined-basic"
                      />
                      <div className="field-error">
                        {errors.technicianNumber?.message}
                      </div>
                    </div>
                  )}
                </ListItem>
              </List>
              {
                profileViewData?.role === "admin" && (
                  <List className="labelWidth">
                    <ListItem>
                      <ListItemIcon>
                        <SmsIcon style={{ fill: "grey" }} />
                      </ListItemIcon>
                      <ListItemText
                        className="labelText"
                        style={{ width: "110px", flex: "0 0 auto" }}
                        primary="Sms Access"
                      />
                      {isPreviewShown === false && (
                        <ListItemText primary={profileViewData.sms_access} />
                      )}
                      {isPreviewShown && (
                        <div>
                          <Controller
                            control={control}
                            name="smsaccess"
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                onChange={(event, item) => {
                                  onChange(item);
                                }}
                                value={value || ""}
                                options={SmsOptions || []}
                                isOptionEqualToValue={(option, value) => option.label === value.label}
                                getOptionLabel={(item) => (item.label ? item.label : "")}
                                renderInput={(params) => <TextField {...params} size="small" style={{width: "178%"}} />}
                              />
                            )}
                          />
                          <div className="field-error">
                            {errors.smsaccess?.message}
                          </div>
                        </div>
                      )}
                    </ListItem>
                  </List>
                )

              }
               {
                profileViewData?.role === "admin" && (
                  <List className="labelWidth">
                    <ListItem>
                      <ListItemIcon>
                        <SmsIcon style={{ fill: "grey" }} />
                      </ListItemIcon>
                      <ListItemText
                        className="labelText"
                        style={{ width: "110px", flex: "0 0 auto" }}
                        primary="Whatsapp Access"
                      />
                      {isPreviewShown === false && (
                        <ListItemText primary={profileViewData.whatsapp_access} />
                      )}
                      {isPreviewShown && (
                        <div>
                          <Controller
                            control={control}
                            name="whatsappaccess"
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                onChange={(event, item) => {
                                  onChange(item);
                                }}
                                value={value || ""}
                                options={SmsOptions || []}
                                isOptionEqualToValue={(option, value) => option.label === value.label}
                                getOptionLabel={(item) => (item.label ? item.label : "")}
                                renderInput={(params) => <TextField {...params} size="small" style={{width: "178%"}} />}
                              />
                            )}
                          />
                          <div className="field-error">
                            {errors.whatsappaccess?.message}
                          </div>
                        </div>
                      )}
                    </ListItem>
                  </List>
                )

              }
              <div className="flex-end btn-padding paddingSave">
                {isPreviewShown && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{ backgroundColor: "#00249b" }}
                  >
                    Save
                  </Button>
                )}
              </div>
            </Grid>
          </div>
        </form>
      </div>
      {/* <div>
        <div className="planHeading displayFlex">
          <PersonIcon style={{ marginTop: "1rem" }} />
          <p>Profile</p>
        </div>
        <div>
          <img className="profileIcon " src={logo}></img>
        </div>
        <div>
          <h4 className="colorBlue flex-justify-center">{profileViewData.username}</h4>
        </div>
        <div>
          <p className='flex-justify-center'>{profileViewData.role}</p>
        </div>
        <div className=''>
          <Grid container spacing={2}>
          <Grid item xs={4}>
          
            </Grid>
            <Grid item xs={3}>
            <EmailIcon style={{ fill: "grey" }} />
              "right"
            </Grid>
            <Grid item xs={2}>
            "right"
            </Grid>
            
            <Grid item xs={4}>
            </Grid>

          </Grid>
        </div>
      </div> */}

      <div className="copyrightsText">
        <p>
          © Copyrights CablePay Solutions India Pvt Ltd.2018.All rights reserved
        </p>
      </div>
    </div>
  );
}
