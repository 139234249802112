import React from "react";
import { Carousel } from "react-responsive-carousel";
import home1 from "../../assets/images/home-first.png";
import home2 from "../../assets/images/home2.jpg";
import home3 from "../../assets/images/homeLatest.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import logo2 from "../../assets/images/cable-white.png";
import { Grid } from "@mui/material";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import PersonIcon from "@mui/icons-material/Person";
import PaymentsIcon from "@mui/icons-material/Payments";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./styles.scss";
import { distributorHome } from "@reducers/api";
import { useComponentDidMount } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import Footer from "@components/homeFooter";
import Avatar from '@mui/material/Avatar';


var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};



var singlesettings = {
  dots: true,
  infinite: false,
  speed: 500,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
};

export default function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { distributorHomeData } = useSelector((state) => state.distributor);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {


    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useComponentDidMount(() => {

    dispatch(distributorHome());
  }, []);


  const renderFeatureView = (icon, title, description) => {
    return (
      <Grid item xs={2} sm={4} md={3}>
        <div className="flex-justify-center">
          <div className="circle-features">{icon}</div>
        </div>
        <div className="features-sub-title">{title}</div>
        <div className="features-sub-description">{description}</div>
      </Grid>
    );
  };

  const handleAdminClick = () => {
    handleClose();
    navigate(`/home/admin-home`);
  }

  const handleDistClick = () => {
    handleClose();
    navigate(`/home/distributor-home`);
  }

  const handleCustomerClick = () => {
    handleClose();
    navigate(`/home/customer-home`);
  }

  return (
    <div className="home-container">
      <div className="div-relative">
        <Carousel
          className="carousel-wrapper"
          showThumbs={false}
          autoPlay
          showArrows={false}
          showStatus={false}
          infiniteLoop
          stopOnHover={false}
        >
          <div>
            <img src={home1} alt="" />
          </div>
          <div>
            <img src={home2} alt="" />
          </div>
          <div>
            <img src={home3} alt="carousel" />
          </div>
        </Carousel>
        {/* <Slider {...singlesettings} className="carousel-wrapper">
          <div>
            <img className='carHeight' src={home1} alt="" />
          </div>
          <div>
            <img className='carHeight' src={home2} alt="" />
          </div>
          <div>
            <img className='carHeight' src={home3} alt="" />
          </div>

        </Slider> */}
        <div className="div-absolute flex-row">
          <Grid
            container
            columns={{ xs: 2, sm: 8, md: 12 }}
            justifyContent="space-between"
          >
            <Grid item xs={2} sm={4} md={4}>
              <div

              >
                <img className="home-login" src={logo2} alt="home logo" />
              </div>
            </Grid>

            <Grid item xs={12} sm={4} md={5} alignSelf="center">
              <div
                className="flex-row homeTop"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div >
                  <a className='cursor-pointer' href=" #" style={{ color: "white", textDecoration: "none", fontWeight: "bold" }}>Home</a>
                </div>
                <div >
                  <a className='cursor-pointer' href=" #feature" style={{ color: "white", textDecoration: "none", fontWeight: "bold" }}>Features</a>
                </div>
                <div style={{ color: "white" }}>

                  <Stack direction="row" spacing={2}>

                    <div>
                      <div
                        ref={anchorRef}
                        id="composition-button"
                        aria-controls={open ? 'composition-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        style={{ color: "white", fontWeight: "bold", textTransform: "none", cursor: "pointer" }}
                      >
                        Our Services
                      </div>
                      <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                  autoFocusItem={open}
                                  id="composition-menu"
                                  aria-labelledby="composition-button"
                                  onKeyDown={handleListKeyDown}
                                >
                                  <MenuItem onClick={handleAdminClick}>Admin App</MenuItem>
                                  <MenuItem onClick={handleDistClick}>Distributor App</MenuItem>
                                  <MenuItem onClick={handleCustomerClick}>Customer App</MenuItem>
                                  <MenuItem>Collection Agent</MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </div>
                  </Stack>
                </div>
                <div>
                  <a href=" #footercontent" style={{ color: "white", textDecoration: "none", fontWeight: "bold" }}>Contact Us</a>
                </div>
                <div
                  className="login-btn-home cursor-pointer"
                  onClick={() => navigate("/login")}
                >
                  Login
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div>
        <div className="features-title" id="feature">Features of Cable Pay</div>
        <div style={{ padding: 25 }}>
          <Grid
            container
            columns={{ xs: 2, sm: 8, md: 12 }}
            className="dotted-border-features"
          >
            {renderFeatureView(
              <DesktopWindowsIcon style={{ fontSize: 60, color: "white" }} />,
              "Dashboard",
              "Dashboard features the sales percentage, total customers, monthly targets and bookings."
            )}
            {renderFeatureView(
              <PersonIcon style={{ fontSize: 60, color: "white" }} />,
              "Customer",
              "Online feedback, bill statement, usage policy, and agent details can be accessed."
            )}
            {renderFeatureView(
              <Inventory2Icon style={{ fontSize: 60, color: "white" }} />,
              "Packages",
              "User can choose their own packages based on their personal preferences."
            )}
            {renderFeatureView(
              <PaymentsIcon style={{ fontSize: 60, color: "white" }} />,
              "Payments",
              "Payments can be made via digital and utility payment portals on smart phones using Cable TV Billing Software."
            )}
          </Grid>
        </div>
      </div>
      <div className="features-title">Our Happy Clients</div>
      <div style={{ padding: "0px 100px 60px 100px" }}>

        <Slider {...settings}>

          {distributorHomeData?.map((data, index) => {
            return (
              <div>
                {
                  data?.profileFileData ? <img className="slider-images" src={`data:image/png;base64,${data?.profileFileData}`} alt={<Avatar>H</Avatar>} />
                    : (<Avatar className="slider-images" variant="square">
                      <p style={{ textAlign: 'center' }}>{data?.name}</p>
                    </Avatar>)
                }

              </div>
            );
          })}
        </Slider>
      </div>
      <div><Footer /></div>
    </div>
  );
}
