import React from "react";
import { Pie } from "react-chartjs-2";
import { Grid } from "@mui/material";
import "./style.scss";

export default function Overview(props) {
  const paidDetails = props.data?.total_transaction_amount;
  const data = {
    labels: ["Paid", "Not Paid", "Discounts"],
    datasets: [
      {
        label: "# of Votes",
        data: [paidDetails, props.data?.total_transaction_unpaid_amount, props.data?.total_transaction_disc_amount],
        backgroundColor: ["#8BD87C", "#E88A8A", "#69D4ED"],
      },
    ],
  };

  const config = {
    type: "pie",
    maintainAspectRatio: false,
    responsive: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  };

  const detailsTile = (title, color) => {
    return (
      <div className="flex-row">
        <div className="overview-details-box" style={{ backgroundColor: color }}></div>
        <div className="overview-chart-title">{title}</div>
      </div>
    );
  };

  return (
    <div className="overview-chart-cntr">
      <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
        <Grid container item xs={2} sm={4} md={7} justifyContent={"center"}>
          <div>
            <Pie data={data} options={config} width={145} height={145} />
            <div className="overview-title">Total Bill - ₹ {props.data?.total_billing_amount}</div>
          </div>
        </Grid>
        <Grid container item xs={2} sm={4} md={5} alignItems="flex-end">
          <div className="overview-chart-details">
            <div>
              {detailsTile("Paid", "#8BD87C")}
              {detailsTile("Not Paid", "#E88A8A")}
              {detailsTile("Discounts", "#69D4ED")}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
