import Grid from "@mui/material/Grid";
import AddonTv from "@components/AddonTvForm";
import { adminAllPlans, addOnTv, subscriberList, getInventoryList, subscriberView, deleteAddonTv , inventoryUpdate } from "@reducers/api";
import { useComponentDidMount, useComponentDidUpdate,paramsQuery } from "@utils/useEffectHooks";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "@reducers/subscriber";
import { addOnTvTable } from "../data";
import { COLORS } from "@constants";
import { useState } from "react";
import TableView from "@components/TableView";
import moment from "moment";
import { useSnackbar } from "notistack";
import AddOnTv from "@components/Modals/addOnTv";
import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

export default function SubscriberAddonTv(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = React.useState(false);
  const {adminAllPlansData } = useSelector((state) => state.plan);
  const { addOnTvAdded,subviewData,addOnTvAddedmsg,stbUpdateData,deleteAddonTvData, getInventoryListData } = useSelector((state) => state.subscriber);
  const [addOnTvData, setAddOntvData] = useState([]);
  const [stbdata, setStbdata] = useState({});

  let filtered = adminAllPlansData && adminAllPlansData?.filter((plan) => plan.service_type === "cable" && plan.package_category === "package" && plan.billing_type === props?.customerData?.billing_type && plan.disabled === false);
  let id = paramsQuery("id");
  
  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));    
    let stbFilter = `distributor_ref_id=${user?.distributor_ref_id}&hardware_type=stb&stb_supplied=false`;
    dispatch(adminAllPlans(`package_category=package&distributor_ref_id=${user?.distributor_ref_id}&disabled=false`));
    addOnTvDataFun();
    dispatch(subscriberView(id));
    dispatch(getInventoryList(stbFilter));
  }, []);

  const handleApis = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}`;
    dispatch(subscriberList(query));
  };


  useComponentDidUpdate(() => {
    if(addOnTvAddedmsg === "STB No is already exists"){
      enqueueSnackbar("STB No is already exists", {
        variant: "error",
      });
    };
  }, [addOnTvAddedmsg]);

  useComponentDidUpdate(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let stbFilter = `distributor_ref_id=${user?.distributor_ref_id}&hardware_type=stb&stb_supplied=false`;
    if (addOnTvAdded) {
      enqueueSnackbar("Add On Tv Added!", {
        variant: "success",
      });
      handleApis();
      addOnTvDataFun();
      dispatch(subscriberView(id));
      dispatch(getInventoryList(stbFilter));
    }
    if(addOnTvAddedmsg === "STB No is already exists"){
      enqueueSnackbar("STB No is already exists", {
        variant: "success",
      });
    }
    dispatch(reset());
  }, [addOnTvAdded]);

  useComponentDidUpdate(() => {
    addOnTvDataFun();
  }, [subviewData?.stb_no]);

  const addOnTvDataFun = () => {
    let list = [];
    if (subviewData?.stb_no?.length > 0) {
      subviewData?.stb_no &&
        subviewData?.stb_no?.map((data, index) => {
          list.push({
            sNo: index + 1,
            stbNo: data?.stb_no,
            type: data?.type,
            action: (
              <div className="flex-row justify-center">
                <div onClick={() => onShowPopUp(data)} className="cursor-pointer">
                  <EditIcon />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    dispatch(
                      deleteAddonTv({
                        "cust_ref_id":id,
                            "stb_no":{
                                "stb_no" : data?.stb_no,
                                "type":  data?.type
                        }
                      })
                      )
                    }
                  >
                  <DeleteIcon />
                </div>
              </div>
            ),
          });
        });
    }
    setAddOntvData(list);
  };

  const onShowPopUp = (data) => {
    let user = JSON.parse(localStorage.getItem("user"));    
    let stbFilter = `distributor_ref_id=${user?.distributor_ref_id}&hardware_type=stb&stb_supplied=false`;
    dispatch(getInventoryList(stbFilter));
    setShow(!show);
    setStbdata(data)
  };

  useComponentDidUpdate(() => {
    if (stbUpdateData) {
      enqueueSnackbar("STB updated", {
        variant: "success",
      });
      onShowPopUp();
      dispatch(reset());
      dispatch(subscriberView(id));      
    }
  }, [stbUpdateData]);

  useComponentDidUpdate(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let stbFilter = `distributor_ref_id=${user?.distributor_ref_id}&hardware_type=stb&stb_supplied=false`;
    if (deleteAddonTvData) {
      enqueueSnackbar("Deleted", {
        variant: "success",
      });
      reset();
      dispatch(subscriberView(id));   
      dispatch(getInventoryList(stbFilter));   
    }
  }, [deleteAddonTvData]);

  

  const handleForm = (values) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      can_id: values.canId,
      plan_name: values?.package?.plan_name,
      plan_amount: values?.package?.plan_amount,
      distributor_ref_id: user?.distributor_ref_id,
      plan_ref_id: values?.package._id,
      package_category: values?.package?.package_category,
      plan_validity: values?.package?.plan_validity,
      billing_type: values?.package?.billing_type,
      mso: values.mso,
      channels: values?.package?.channels,
      created_at: moment().format(),
      service_type: values?.package?.service_type,
      channel_count: values?.package?.channel_count,
      stb_no: values.addStb?.stb_no,
      expired_date: moment(values?.date).format(),
      cust_ref_id: props?.customerData?._id,
      cust_name: props?.customerData?.name,
      cust_email_id: props?.customerData?.email_id,
      stb_type: "addon_Tv",
      disabled: false,
      sms_access: user?.sms_access,
      whatsapp_access: user?.whatsapp_access,
    };
    

    let payloadUpdate = {
      stb_supplied: true,
      _id: values.addStb?._id,
    };

    dispatch(addOnTv(payload));

    if (values?.addStb?.stb_no) {
      dispatch(inventoryUpdate(payloadUpdate));
    }

  };


  return (   
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
       <AddOnTv stbnumbers={getInventoryListData} show={show} stbDetails={stbdata} handleClose={onShowPopUp}/>  
      <Grid item xs={12} sm={12} md={12}>
        <AddonTv planListData={filtered && filtered} customerData={props?.customerData} stbListData={getInventoryListData} resetForm={addOnTvAdded} handleFormValues={handleForm} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
        <TableView rows={addOnTvTable} data={addOnTvData} color={COLORS.primary} />
      </Grid>
    </Grid>
  );
}
