import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "@reducers/api";
import { reset } from "@reducers/auth";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Grid, TextField } from "@mui/material";
import logo from "../../assets/images/logo_big.png";
import "./styles.scss";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { pswReset, statusMsg } = useSelector((state) => state.auth);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (pswReset) {

      enqueueSnackbar("New password send to your mail", {
        variant: "success",
      });
      navigate("/login");
    }
    return () => {
      dispatch(reset());
    };
  }, [pswReset, dispatch, navigate]);

  useEffect(() => {
    if (statusMsg) {
      enqueueSnackbar(statusMsg, {
        variant: "error",
      });
    }
    return () => {
      dispatch(reset());
    };
  }, [statusMsg]);

  return (
    <Grid
      className="login-container"
      container
      columns={{ xs: 2, sm: 8, md: 12 }}
    >
      <Grid item xs={2} sm={4} md={6.5}>
        <div className="sub-image-cnt-login"></div>
      </Grid>
      <Grid item xs={2} sm={4} md={5.5} justifyContent="center" display="flex">
        <div className="login-form-cnt">
          <div>
            <div className="flex-justify-center">
              <img className="logo-login" src={logo} alt=""/>
            </div>
            <div className="title-login">Forgot Your Password?</div>
            <p>Enter your account id, your password will be reset</p>
            <div>
              <TextField
                label="Account ID"
                name="username"
                id="username"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                variant="outlined"
                fullWidth
                size="small"
                sx={{ width: 380 }}
              />
            </div>
            
            <button
              id="loginbtn"
              className="login-btn"
              onClick={() =>
                dispatch(
                  forgotPassword({ account_id: userName })
                )
              }
            >
              Send
            </button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
