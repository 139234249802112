import "./styles.scss";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import * as Yup from "yup";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  othersAll
} from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import {
  useComponentDidMount,
} from "@utils/useEffectHooks";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const taxApplied = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

const discountType = [
  { label: "One Time", value: "onetime" },
  { label: "Permanent", value: "permanent" },
];

const billingType = [
  { label: "Pre Paid", value: "pre paid" },
  { label: "Post Paid", value: "post paid" },
];

const msoOptions = [{ label: "Hathway", value: "hathway" }];

const ispOptions = [{ label: "Tacitine", value: "tacitine" }];

const validationCable = Yup.object({
  stbNo: Yup.object().required("Required").nullable(),
  canid: Yup.string().required("Required").nullable(),
  assignTo: Yup.object().required("Required").nullable(),
  tax: Yup.object().required("Required").nullable(),
  // discount: Yup.string().required("Required").nullable(),
  // discountType: Yup.object().required("Required").nullable(),
  billingType: Yup.object().required("Required").nullable(),
  active: Yup.object().required("Required").nullable(),
  // msoId: Yup.string().required("Required").nullable(),
  bill: Yup.object().required("Required").nullable(),
  mso: Yup.object().required("Required").nullable(),
  package: Yup.object().required("Required").nullable(),
  packagePrize: Yup.string().required("Required").nullable(),
  packageValidity: Yup.string().required("Required").nullable(),
  cashAmount: Yup.string().required("Required").nullable(),
});

const validationInternet = Yup.object({
  userId: Yup.string().required("Required").nullable(),
  //loginIp: Yup.string().required("Required").nullable(),
  tax: Yup.object().required("Required").nullable(),
  // discount: Yup.string().required("Required").nullable(),
  // discountType: Yup.object().required("Required").nullable(),
  billingType: Yup.object().required("Required").nullable(),
  active: Yup.object().required("Required").nullable(),
  //msoId: Yup.string().required("Required").nullable(),
  isp: Yup.object().required("Required").nullable(),
  package: Yup.object().required("Required").nullable(),
  packagePrize: Yup.string().required("Required").nullable(),
  packageValidity: Yup.string().required("Required").nullable(),
  cashAmount: Yup.string().required("Required").nullable(),
});

export default function AddSubscriberFormPayment(props) {
  const dispatch = useDispatch();
  const [type, setType] = useState("Cable");
  const [paymentType, setPaymentType] = useState("Cash");
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      type === "Cable" ? validationCable : validationInternet
    ),
  });

  const [value, setValueDate] = useState(new Date());
  const { otherAllData } = useSelector((state) => state.subscriber);

  setValue("checque_date", value);

  const planData =
    props?.plansData &&
    props?.plansData.filter((plan) =>
      watch("billingType")?.value
        ? plan?.service_type === type.toLowerCase()
        : null
    );

  const planFilterData =
    planData &&
    planData.filter((plan) =>
      watch("billingType")?.value
        ? plan?.billing_type === watch("billingType")?.value
        : null
    );

  const onSubmit = (data) => props?.handleFormValues(data, paymentType);

  const handleSetValue = (item) => {
    setValue("packagePrize", item?.plan_amount);
    setValue("packageValidity", item?.plan_validity);

  };


  const taxCalc = (watch("packagePrize") || 0) * props?.tax?.totalTax / 100 + (watch("packagePrize") || 0)
  let pricefield = watch("price") || 0
  const totalprice = taxCalc + pricefield + (otherAllData && otherAllData[0]?.maintenance_amount || 0)
  setValue("totalAmt", Math.round(totalprice));

  console.log(pricefield, "price")

  const handleSetPrice = (item) => {

    setValue("price", item?.stb_payment_category === "free" ? 0 : item.price);
  };

  const handleChange = (newValue) => {
    setValueDate(newValue);
    setValue("checque_date", newValue);
  };

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `${user?.distributor_ref_id}&service_type=cable`;
    dispatch(othersAll(query));
  }, []);

  const layoutInput = (title, input, error) => {
    return (
      <Grid item xs={4} sm={4} md={6}>
        <div className="add-subscriber-form-title">{title}</div>
        {input}
        <div className="field-error">{error}</div>
      </Grid>
    );
  };

  const autoCompleteInput = (fieldName, options, placeholder) => {
    return (
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            onChange={(event, item) => {
              onChange(item);
            }}
            value={value || ""}
            options={options || []}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder={placeholder} />
            )}
          />
        )}
      />
    );
  };

  console.log(otherAllData, "propssss")

  return (
    <div className="add-sub-payment">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          rowSpacing={4}
          columnSpacing={{ xs: 1, sm: 2, md: 7 }}
          sx={{ marginTop: 4 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={4} md={6}>
            <Checkbox
              {...label}
              sx={{
                "&.Mui-checked": {
                  color: "#00249b",
                },
              }}
              onClick={() => {
                setType("Cable");
                reset();
                let user = JSON.parse(localStorage.getItem("user"));
                let query = `${user?.distributor_ref_id}&service_type=cable`;
                dispatch(othersAll(query));
              }}
              checked={type === "Cable" ? true : false}
            />
            Cable
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <Checkbox
              {...label}
              sx={{
                "&.Mui-checked": {
                  color: "#00249b",
                },
              }}
              onClick={() => {
                setType("Internet");
                reset();
                let user = JSON.parse(localStorage.getItem("user"));
                let query = `${user?.distributor_ref_id}&service_type=internet`;
                dispatch(othersAll(query));
              }}
              checked={type === "Internet" ? true : false}
            />
            Internet
          </Grid>
          {type === "Cable"
            ? layoutInput(
              "STB No",

              <Controller
                control={control}
                name="stbNo"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                      handleSetPrice(item);
                    }}
                    value={value || ""}
                    options={props?.stbListData || []}
                    isOptionEqualToValue={(option, value) =>
                      option.stb_no === value.stb_no
                    }
                    getOptionLabel={(item) =>

                      item.stb_no ? `${item.stb_no} (${item.inventory_type}) (${item.price}) (${item.stb_payment_category})` : ""
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Enter STB No"
                      />
                    )}
                  />
                )}
              />
              ,
              errors.stbNo?.message
            )
            : layoutInput(
              "User ID ",
              <TextField
                {...register("userId")}
                size="small"
                type="text"
                className="txtwidth"
                placeholder="Enter User ID"
              />,
              errors.userId?.message
            )}

          {type === "Cable" &&
            layoutInput(
              "Can Id",
              <TextField
                {...register("canid")}
                size="small"
                type="text"
                className="txtwidth"
                placeholder="Enter can id"
              />,
              errors.canid?.message
            )}

          {type === "Cable"
            ? layoutInput(
              "Assign To",
              <Controller
                control={control}
                name="assignTo"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || ""}
                    options={props?.employeeData || []}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    getOptionLabel={(item) =>
                      item.name ? `${item.name}` : ""
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Select Assign To"
                      />
                    )}
                  />
                )}
              />,
              errors.assignTo?.message
            )
            : layoutInput(
              "Login IP ",
              <TextField
                {...register("loginIp")}
                size="small"
                type="text"
                className="txtwidth"
                placeholder="Enter Login IP"
              />,
              errors.loginIp?.message
            )}
          {layoutInput(
            "Tax Applied",
            autoCompleteInput("tax", taxApplied, "Select Tax Applied"),
            errors.tax?.message
          )}
          {layoutInput(
            "Discount Charge",
            <TextField
              {...register("discount")}
              size="small"
              type="text"
              className="txtwidth"
              placeholder="Enter Discount Charge"
            />,
            errors.discount?.message
          )}
          {layoutInput(
            "Discount Type",
            autoCompleteInput(
              "discountType",
              discountType,
              "Select Discount Type"
            ),
            errors.discountType?.message
          )}
          {layoutInput(
            "Billing Type",
            autoCompleteInput(
              "billingType",
              billingType,
              "Select Billing Type"
            ),
            errors.billingType?.message
          )}
          {layoutInput(
            "Active",
            autoCompleteInput("active", taxApplied, "Select Active"),
            errors.active?.message
          )}
          {layoutInput(
            "MSO ID",
            <TextField
              {...register("msoId")}
              size="small"
              type="text"
              className="txtwidth"
              placeholder="Enter MSO ID"
            />,
            errors.msoId?.message
          )}
          {type === "Cable" &&
            layoutInput(
              "Auto bill-generation",
              autoCompleteInput("bill", taxApplied, "Select"),
              errors.bill?.message
            )}
          {
            type === "Cable" &&
            layoutInput(
              "Price",
              <TextField
                {...register("price")}
                size="small"
                type="text"
                className="txtwidth"

              />

            )
          }


        </Grid>


        <div className="type-sub-title">{type}</div>

        <Grid
          container
          rowSpacing={4}
          columnSpacing={{ xs: 1, sm: 2, md: 7 }}
          sx={{ marginTop: 0.3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          className="ticketDetails"
        >
          {/* {layoutInput(
            type === "Cable" ? "MSO" : "ISP",
            autoCompleteInput(
              type === "Cable" ? "mso" : "isp",
              type === "Cable" ? otherAllData && otherAllData[0] : planFilterData,
              type === "Cable" ? "Select MSO" : "Select ISP"
            ),
            type === "Cable" ? errors.mso?.message : errors.isp?.message
          )} */}
           {layoutInput(
            type === "Cable" ? "MSO" : "ISP",
            <Controller
              control={control}
              name={type === "Cable" ? "mso" : "isp"}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || ""}
                  options={otherAllData || []}
                  isOptionEqualToValue={(option, value) => type === "Cable" ? option.mso === value.mso: option.isp === value.isp}
                  getOptionLabel={(item) => type === "Cable" ? item.mso ? item.mso : "" : item.isp ? item.isp : ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder={type === "Cable" ? "Select MSO" : "Select ISP"}
                    />
                  )}
                />
              )}
            />,
            type === "Cable" ? errors.mso?.message : errors.isp?.message
          )}
          {layoutInput(
            "Select Package",
            <Controller
              control={control}
              name="package"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                    handleSetValue(item);
                  }}
                  value={value || ""}
                  options={planFilterData || []}
                  isOptionEqualToValue={(option, value) =>
                    option.plan_name === value.plan_name
                  }
                  getOptionLabel={(item) =>
                    item.plan_name ? item.plan_name : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select Package"
                    />
                  )}
                />
              )}
            />,
            errors.package?.message
          )}
          {layoutInput(
            "Package Price",
            <TextField
              disabled={true}
              {...register("packagePrize")}
              size="small"
              type="text"
              className="txtwidth"
              placeholder="Enter Package Price"
            />,
            errors.packagePrize?.message
          )}
          {layoutInput(
            "Package Validity",
            <TextField
              disabled={true}
              {...register("packageValidity")}
              size="small"
              type="text"
              className="txtwidth"
              placeholder="Enter Package Validity"
            />,
            errors.packageValidity?.message
          )}
          {

            layoutInput(
              "Total Amount",
              <TextField
                {...register("totalAmt")}
                size="small"
                type="text"
                className="txtwidth"

              />

            )
          }
        </Grid>

        <Grid
          container
          sx={{ marginTop: 8 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={4} md={4}>
            <Checkbox
              {...label}
              sx={{
                "&.Mui-checked": {
                  color: "#00249b",
                },
              }}
              onClick={() => {
                setPaymentType("Cash");
              }}
              checked={paymentType === "Cash" ? true : false}
            />
            Cash
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Checkbox
              {...label}
              sx={{
                "&.Mui-checked": {
                  color: "#00249b",
                },
              }}
              onClick={() => {
                setPaymentType("Cheque");
              }}
              checked={paymentType === "Cheque" ? true : false}
            />
            Cheque
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Checkbox
              {...label}
              sx={{
                "&.Mui-checked": {
                  color: "#00249b",
                },
              }}
              onClick={() => {
                setPaymentType("Card");
              }}
              checked={paymentType === "Card" ? true : false}
            />
            Card
          </Grid>
        </Grid>

        <div className="type-sub-title">{paymentType}</div>

        {paymentType === "Cash" && (
          <Grid
            container
            rowSpacing={4}
            columnSpacing={{ xs: 1, sm: 2, md: 7 }}
            sx={{ marginTop: 0.3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            className="ticketDetails"
          >
            {layoutInput(
              "Amount",
              <TextField
                {...register("cashAmount")}
                size="small"
                type="text"
                className="txtwidth"
                placeholder="Enter Amount"
              />,
              errors.cashAmount?.message

            )}
          </Grid>
        )}
        {paymentType === "Cheque" && (
          <Grid
            container
            rowSpacing={4}
            columnSpacing={{ xs: 1, sm: 2, md: 7 }}
            sx={{ marginTop: 0.3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            className="ticketDetails"
          >
            {layoutInput(
              "Cheque no",
              <TextField
                size="small"
                {...register("checque_no")}
                type="text"
                className="txtwidth"
                placeholder="Enter Cheque no"
              />
            )}
            {layoutInput(
              "Cheque Date",
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={value}
                  onChange={handleChange}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Enter Cheque Date"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
            {layoutInput(
              "Bank Name",
              <TextField
                size="small"
                {...register("checque_bank_name")}
                type="text"
                className="txtwidth"
                placeholder="Enter Bank Name"
              />
            )}
            {layoutInput(
              "Amount",
              <TextField
                size="small"
                {...register("cashAmount")}
                type="text"
                className="txtwidth"
                placeholder="Enter Amount"
              />
            )}
          </Grid>
        )}
        {paymentType === "Card" && (
          <Grid
            container
            rowSpacing={4}
            columnSpacing={{ xs: 1, sm: 2, md: 7 }}
            sx={{ marginTop: 0.3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            className="ticketDetails"
          >
            {layoutInput(
              "Amount",
              <TextField
                size="small"
                type="text"
                {...register("cashAmount")}
                className="txtwidth"
                placeholder="Enter Amount"
              />,
              errors.cashAmount?.message
            )}
            {layoutInput(
              "Bill No",
              <TextField
                size="small"
                {...register("bill_no")}
                type="text"
                className="txtwidth"
                placeholder="Enter Bill No"
              />
            )}
          </Grid>
        )}
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ marginTop: 3 }}
          className="flex-end padding-btw-tiles"
        >
          <Stack spacing={2} direction="row">
            <Button
              size="small"
              variant="contained"
              className="bg-blue"
              onClick={props?.handlePrevious}
            >
              Previous
            </Button>
            <Button
              size="small"
              variant="contained"
              className="bg-blue"
              type="submit"
            >
              Submit
            </Button>
          </Stack>
        </Grid>
      </form>
    </div>
  );
}
