import "./styles.scss";
import { Grid } from "@mui/material";
import CustomerDetailsView from "@components/CustomerDetailsView";
import Modal from "@mui/material/Modal";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DownloadIcon from "@mui/icons-material/Download";
import { useComponentDidMount, useComponentDidUpdate, paramsQuery } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { ticketHistory, customerPlanDetails, customerProfileDetails, addPlanList, paymentGatewayRedirectApi, payNowCustomer, settingsPaymentGet, broadcastList, basicList, paymentHistoryList, adminPackageOnly, adminAllPlans } from "@reducers/api";
import CustomerPlanView from "@components/CustomerPlanView";
import CustomerPaymentHistory from "@components/Modals/customerPaymentHistory";
import AddNewPlanModal from "@components/Modals/addNewPlanPopup";
import Paymenthistory from "@components/Modals/paymentHistory";
import TicketRaise from "@components/Modals/ticketRaise";
import CustomerProfilePopup from "@components/Modals/customerProfilePopup";
import moment from "moment";
import { reset } from "@reducers/subscriber";
import { custreset } from "@reducers/customer";
import { useSnackbar } from "notistack";
import CancelIcon from '@mui/icons-material/Cancel';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData("Frozen yoghurt", 159, 6.0, 24, 4.0), createData("Ice cream sandwich", 237, 9.0, 37, 4.3), createData("Eclair", 262, 16.0, 24, 6.0), createData("Cupcake", 305, 3.7, 67, 4.3), createData("Gingerbread", 356, 16.0, 49, 3.9)];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
};

export default function CustomerDashboard() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [openPaymentHistory, setOpenPaymentHistory] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [paymentHistory, setpaymentHistory] = React.useState(false);
  const [ticketRaise, setticketRaise] = React.useState(false);
  const [channelList, setchannelList] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [paymentHistoryData, setPaymentHistoryData] = React.useState([]);
  const [paymentViewData, setPaymentHistoryViewData] = React.useState("");
  const { imgUpload , payNowCust} = useSelector((state) => state.subscriber);
  const { adminAllPlansData, adminPackageData, packageOnlyData } = useSelector((state) => state.plan);
  const { paymentGatewayRedirectData } = useSelector((state) => state.customer);


  const onShowPopUp = () => {
    setShow(!show);
  };
  const onshowChannelList = () => {
    setchannelList(!channelList);
  };
  const onShowHistory = () => {
    setOpenPaymentHistory(!openPaymentHistory);

  };
  const onShowTicketRaise = () => {
    setticketRaise(!ticketRaise);
  };
  const handleOpen = () => {
    setOpen(true);
    let user = JSON.parse(localStorage.getItem("user"));
    let custId = user?.cust_ref_id;
    dispatch(ticketHistory(`cust_ref_id=${custId}`));
  }
  const handleClose = () => setOpen(false);
  const { ticketHistoryData, profileData, planData, addPlanListData, broadCastListData, paymentHistoryListData, custProfileUpdateData, profileCustomer, custPayNowStatus } = useSelector((state) => state.customer);
  const { enqueueSnackbar } = useSnackbar();

  const queryGenerator = (value) => {
    let query = ``;
    if (value?.type === "search") {
      query += `&value=${value?.state}`;
    }
    return query;
  };

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let custId = user?.cust_ref_id;
    let payload = {
      cust_ref_id: custId,
    };
    let distributorId = user?.distributor_ref_id;
    dispatch(settingsPaymentGet(distributorId));
    dispatch(ticketHistory(`cust_ref_id=${custId}`));
    dispatch(paymentHistoryList(`cust_ref_id=${custId}&payment_status=success,pending,failed`));
    dispatch(customerPlanDetails(payload));
    dispatch(customerProfileDetails(payload));

  }, []);

  useComponentDidUpdate(() => {
    if (profileData?.customerData) {
      let user = JSON.parse(localStorage.getItem("user"));
      dispatch(adminPackageOnly(`distributor_ref_id=${user?.distributor_ref_id}&service_type=${profileData?.customerData?.service_type}&billing_type=${profileData?.customerData?.billing_type}`));
    }

    if (paramsQuery("order_id")) {
      if (profileData) {
        let payload = {
          "url": profileData?.customerData?.paymentUrl
        };
        dispatch(paymentGatewayRedirectApi(payload));
      }

    }

  }, [profileData?.customerData]);

  useComponentDidUpdate(() => {
    if (paymentGatewayRedirectData) {
      dispatch(paymentHistoryList(`order_id=${paramsQuery("order_id")}`));
    }

  }, [paymentGatewayRedirectData]);

  console.log(paymentGatewayRedirectData, "paymentsss")

  useComponentDidUpdate(() => {
    if (paramsQuery("order_id")) {
      if (paymentGatewayRedirectData) {
        if (paymentHistoryListData) {
          let payloadpay = {
            cust_plan_ref_id: paymentHistoryListData[0]?.planData,
            bill_amount: paymentHistoryListData[0]?.bill_amount,
            maintenance_amount: paymentHistoryListData[0]?.maintenance_amount,
            discount_amount: paymentHistoryListData[0]?.discount_amount,
            stb_no: paymentHistoryListData[0]?.stb_no,
            collected_by: paymentHistoryListData[0]?.collected_by,
            collection_amount: paymentHistoryListData[0]?.collection_amount,
            collected_date: paymentHistoryListData[0]?.collected_date,
            due_amount: paymentHistoryListData[0]?.due_amount,
            advance_amount: paymentHistoryListData[0]?.advance_amount,
            add_on_service_amount: paymentHistoryListData[0]?.add_on_service_amount,
            cust_ref_id: {
              _id: paymentHistoryListData[0]?.customerData?._id,
              name: paymentHistoryListData[0]?.customerData?.name,
              email_id: paymentHistoryListData[0]?.customerData?.email_id,
            },
            invoice_id: paymentHistoryListData[0]?.invoice_id,
            transaction_id: paymentHistoryListData[0]?.transaction_id,
            order_id: paymentHistoryListData[0]?.order_id,
            payment_type: "online",
            distributor_ref_id: paymentHistoryListData[0]?.distributor_ref_id,
            package_amount: paymentHistoryListData[0]?.package_amount,
            broadcaster_amount: paymentHistoryListData[0]?.broadcaster_amount,
            ala_cart_amount: paymentHistoryListData[0]?.ala_cart_amount,
            billing_type: paymentHistoryListData[0]?.billing_type,
            service_type: paymentHistoryListData[0]?.service_type,
            payby_id: paymentHistoryListData[0]?.payby_id,
            remarks: "",
            ncf_amount: paymentHistoryListData[0]?.ncf_amount,
            sms_access: profileData?.associatedDistributorData?.sms_access,
            last_advance_amount: paymentHistoryListData[0]?.last_advance_amount,
            last_due_amount: paymentHistoryListData[0]?.last_due_amount,
            primaryStbNo: paymentHistoryListData[0]?.customerData?.stb_no[0]?.stb_no,
            payment_status: paymentGatewayRedirectData[0]?.payment_status === "SUCCESS" ? "success" : paymentGatewayRedirectData[0]?.payment_status
          }; 
          dispatch(payNowCustomer(payloadpay));

        }
      }

    }

  }, [paymentGatewayRedirectData ]);

    useComponentDidUpdate(() => {
    if (payNowCust) {
      let user = JSON.parse(localStorage.getItem("user"));
      let custId = user?.cust_ref_id;
      let payload = {
        cust_ref_id: custId,
      };
      dispatch(customerPlanDetails(payload));
      dispatch(paymentHistoryList(`cust_ref_id=${custId}&payment_status=success,pending,failed`));
    }

  }, [payNowCust]);


  const handleOpenProfile = () => {
    setOpenProfile(true);
    let user = JSON.parse(localStorage.getItem("user"));
    let custId = user?.cust_ref_id;
    let payload = {
      cust_ref_id: custId,
    };
    dispatch(customerProfileDetails(payload));
    reset()
  }
  const handleCloseProfile = () => {
    setOpenProfile(false);
  };

  const handleClosePaymentHistory = () => {
    setOpenPaymentHistory(false);
  };

  const handleViewPaymentHistory = () => {
    setpaymentHistory(false);
  };

  const Buttons = [
    {
      title: "My profile",
      func: handleOpenProfile,
    },
    {
      title: "Add Plan",
      func: onShowPopUp,
    },
    {
      title: "Payment History",
      func: onShowHistory,
    },
    {
      title: "Ticket Raise",
      func: onShowTicketRaise,
    },
    {
      title: "Ticket History",
      func: handleOpen,
    },
  ];

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    let query = queryGenerator({ type: "search", state: search });
    dispatch(addPlanList(query));
  };

  useComponentDidUpdate(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let custId = user?.cust_ref_id;
    let payload = {
      cust_ref_id: custId,
    };
    let message = "";
    if (custPayNowStatus) {
      message = "Pay now updated";
      enqueueSnackbar(message, {
        variant: "success",
      });
      reset();
      dispatch(reset());
      dispatch(customerProfileDetails(payload));
    }
  }, [custPayNowStatus]);

  useComponentDidUpdate(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let custId = user?.cust_ref_id;
    let payload = {
      cust_ref_id: custId,
    };
    if (imgUpload) {
      enqueueSnackbar("Image Uploaded !", {
        variant: "success",
      });
      handleCloseProfile();
      dispatch(customerProfileDetails(payload));
      dispatch(reset());
    }
  }, [imgUpload]);

  useComponentDidUpdate(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let custId = user?.cust_ref_id;
    let payload = {
      cust_ref_id: custId,
    };
    let message = "";

    if (profileCustomer === 200) {
      message = "Profile updated";
      enqueueSnackbar(message, {
        variant: "success",
      });
      handleCloseProfile();
      dispatch(custreset());
      dispatch(customerProfileDetails(payload));
    }
  }, [profileCustomer,]);

  useComponentDidUpdate(() => {
    let list = [];
    paymentHistoryListData &&
      paymentHistoryListData?.map((data, index) => {
        list.push({
          id: data?.invoice_id,
          billAmount: data?.bill_amount,
          dueAmount: data?.due_amount,
          paymentStatus: data?.payment_status,
          date: moment(data?.collected_date).format("ll"),
          action: (
            <div className="flex-row justify-center">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setPaymentHistoryViewData(data);
                  setpaymentHistory(!paymentHistory);
                }}
              >
                <DownloadIcon />
              </div>
            </div>
          ),
        });
      });

    setPaymentHistoryData(list);
  }, [paymentHistoryListData]);

  const handleSearchPackage = (search) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(adminPackageOnly(`&value=${search}&distributor_ref_id=${user?.distributor_ref_id}&service_type=${profileData?.customerData?.service_type}&billing_type=${profileData?.customerData?.billing_type}`));
  };

  return (
    <div className="customer-dashboard-cnt">
      <CustomerPaymentHistory show={openPaymentHistory} data={paymentHistoryData} handleClose={handleClosePaymentHistory} />
      <CustomerProfilePopup show={openProfile} data={profileData} handleClose={handleCloseProfile} />
      <div className="customer-dash-heading">Customer Details</div>
      <AddNewPlanModal show={show} data={addPlanListData} currentServiceTable={planData?.primary_plan} adminPackageData={packageOnlyData} customerData={profileData?.customerData} broadcastData={broadCastListData} handleClose={onShowPopUp} handleChange={handleSearch} channelListPopup={onshowChannelList} handleSearchPackage={(search) => handleSearchPackage(search)} />
      <Paymenthistory data={paymentViewData} show={paymentHistory} handleClose={handleViewPaymentHistory} />
      <TicketRaise show={ticketRaise} handleClose={onShowTicketRaise} />
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={6} md={5}>
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="ticketHistory">
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2" className="ticket-header header-raise-title justify-space-between flex-row">
                Ticket History
                <div onClick={handleClose} className="cursor-pointer">{<CancelIcon />}</div>
              </Typography>

              <TableContainer component={Paper} style={{ height: "400px" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow style={{ color: "white" }}>
                      <TableCell>Registration Date</TableCell>
                      <TableCell align="right">Complaint</TableCell>
                      <TableCell align="right">Uploaded Image</TableCell>
                      <TableCell align="right">Ticket Status</TableCell>
                      <TableCell align="right">Remarks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ticketHistoryData?.tickets?.data?.map((row, index) => (
                      <TableRow key={row.created_at} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} style={index % 2 ? { background: "white" } : { background: "#F2F2F2" }}>
                        <TableCell component="th" scope="row">
                          {moment(row.created_at).format("ll")}
                        </TableCell>
                        <TableCell align="right">{row.title}</TableCell>
                        <TableCell align="right">
                          <img className="img-previewUpload" src={`data:image/png;base64,${row?.ticketImageData?.imageData}`} />;
                        </TableCell>
                        <TableCell align="right">{row.complaint_status}</TableCell>
                        <TableCell align="right">{row.description}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Modal>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {Buttons.map((btn) => (
              <div style={{ padding: 15 }}>
                <button className="btn-tile-cnt" onClick={btn.func}>
                  {btn.title}
                </button>
              </div>
            ))}
          </div>
          <div style={{ marginTop: 30 }}></div>
          <CustomerDetailsView data={profileData} />
        </Grid>
        <Grid item xs={4} sm={6} md={7}>
          <CustomerPlanView data={planData} customerData={profileData?.customerData} />
        </Grid>
      </Grid>
    </div>
  );
}
