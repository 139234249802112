const tableRows = [
  {
    key: "checkbox",
    align: "left",
  },
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },
  {
    title: "Plan Name",
    key: "planName",
    align: "left",
  },
  {
    title: "Amount",
    key: "amount",
    align: "left",
  },
  {
    title: "Service Type",
    key: "serviceType",
    align: "left",
  },
  {
    title: "SD/HD",
    key: "quality",
    align: "left",
  },
  {
    title: "Validity",
    key: "validity",
    align: "left",
  },
  {
    title: "Billing Type",
    key: "billingType",
    align: "left",
  },
  {
    title: "Package category",
    key: "category",
    align: "left",
  },
  {
    title: "Broadcast category",
    key: "broadcstcategory",
    align: "left",
  },
  {
    title: "Channel Count",
    key: "channelcount",
    align: "left",
  },
  {
    title: "Action",
    key: "action",
    align: "left",
  },
];

export { tableRows };
