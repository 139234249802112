import { createSlice } from "@reduxjs/toolkit";
import {
  ticketHistory,
  customerPlanDetails,
  customerProfileDetails,
  custProfileUpdate,
  addPlanList,
  imageUpload,
  ticketRaiseAdd,
  broadcastList,
  basicList,
  paymentHistoryList,
  PayNowStatusChange,
  paymentGateway,
  paymentGatewayRedirectApi
} from "./api";

const initialState = {
  ticketHistoryData: [],
  plandetailAdded: false,
  planData: [],
  profiledetailAdded: false,
  profileData: [],
  custProfileUpdateData: false,
  profileCustomer: false,
  addPlanListData: [],
  imageUploadData: [],
  ticketRaiseAddData: false,
  broadCastListData: [],
  basicListData: [],
  paymentHistoryListData: [],
  custPayNowStatus: false,
  paymentGatewayData: false,
  paymentGatewayRedirectData: false,
};

export const custSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    reset: (state) => {
      state.custProfileUpdateData = false;
      state.custPayNowStatus = false;
      state.ticketRaiseAddData = false;
      state.profileCustomer = false;
      state.paymentGatewayData= false;
      state.paymentGatewayRedirectData = false;
      return state;
    },
    custreset: (state) => {
      state.profileCustomer = false;
      return state;
    },
  },
  extraReducers: {
    [ticketHistory.pending]: (state) => { },
    [ticketHistory.fulfilled]: (state, { payload }) => {
      state.ticketHistoryData = payload?.data?.data;
    },
    [ticketHistory.rejected]: (state, { payload }) => { },

    [PayNowStatusChange.pending]: (state) => { },
    [PayNowStatusChange.fulfilled]: (state, { payload }) => {
      state.custPayNowStatus = payload?.data?.status;
    },
    [PayNowStatusChange.rejected]: (state, { payload }) => { },

    [custProfileUpdate.pending]: (state) => { },
    [custProfileUpdate.fulfilled]: (state, { payload }) => {
      state.custProfileUpdateData = payload?.data?.data;
      state.profileCustomer = payload.status
    },
    [custProfileUpdate.rejected]: (state, { payload }) => { },

    [customerPlanDetails.pending]: (state) => { },
    [customerPlanDetails.fulfilled]: (state, { payload }) => {
      state.plandetailAdded = payload.data.status;
      state.planData = payload?.data?.data;
    },
    [customerPlanDetails.rejected]: (state, { payload }) => { },

    [customerProfileDetails.pending]: (state) => { },
    [customerProfileDetails.fulfilled]: (state, { payload }) => {
      state.profiledetailAdded = payload.data.status;
      state.profileData = payload?.data?.data;
    },
    [customerProfileDetails.rejected]: (state, { payload }) => { },

    [addPlanList.pending]: (state) => { },
    [addPlanList.fulfilled]: (state, { payload }) => {
      state.addPlanListData = payload?.data?.data;
    },
    [addPlanList.rejected]: (state, { payload }) => { },

    [imageUpload.pending]: (state) => { },
    [imageUpload.fulfilled]: (state, { payload }) => {
      state.imageUploadData = payload?.data?.data;
    },
    [imageUpload.rejected]: (state, { payload }) => { },

    [ticketRaiseAdd.pending]: (state) => { },
    [ticketRaiseAdd.fulfilled]: (state, { payload }) => {
      state.ticketRaiseAddData = payload?.data?.data;
    },
    [ticketRaiseAdd.rejected]: (state, { payload }) => { },

    [broadcastList.pending]: (state) => { },
    [broadcastList.fulfilled]: (state, { payload }) => {
      state.broadCastListData = payload?.data?.data;
    },
    [broadcastList.rejected]: (state, { payload }) => { },

    [basicList.pending]: (state) => { },
    [basicList.fulfilled]: (state, { payload }) => {
      state.basicListData = payload?.data?.data;
    },
    [basicList.rejected]: (state, { payload }) => { },

    [paymentHistoryList.pending]: (state) => { },
    [paymentHistoryList.fulfilled]: (state, { payload }) => {
      state.paymentHistoryListData = payload?.data?.data;
    },
    [paymentHistoryList.rejected]: (state, { payload }) => { },
   
    [paymentGateway.pending]: (state) => { },
    [paymentGateway.fulfilled]: (state, { payload }) => {
      state.paymentGatewayData = payload?.data?.data;
      console.log(payload?.data?.data, "datasssspag")
    },
    [paymentGateway.rejected]: (state, { payload }) => { },

    [paymentGatewayRedirectApi.pending]: (state) => { },
    [paymentGatewayRedirectApi.fulfilled]: (state, { payload }) => {
      state.paymentGatewayRedirectData = payload?.data?.data;
    },
    [paymentGatewayRedirectApi.rejected]: (state, { payload }) => { },
  },
});

export const { reset, custreset } = custSlice.actions;


export default custSlice.reducer;
