import React from "react";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { COLORS } from "@constants";
import { useNavigate } from "react-router-dom";


import "./style.scss";

function handleClick(event) {
  event.preventDefault();
}

export default function BreadCrumb(props) {

  const navigate = useNavigate();
  const handleHomeBread = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user?.role === "admin") {
      navigate("/admin/dashboard");
    } else if (user?.role === "distributor") {
      navigate("/distributor/dashboard");
    } else if (user?.role === "support") {
      navigate("/distributor/dashboard");
    } else if (user?.role === "user") {
      navigate("/customer/dashboard");
    }
  };


  return (
    <div role="presentation" className='breadcrumb' >
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" onClick={() => handleHomeBread()}>
         {props?.defaultHeading}
        </Link>
        {
          props?.secondaryHeading && (
            <Link
            underline="hover"
            color="inherit"
            onClick={props?.handleSecondaryClick}
          >
            {props?.secondaryHeading}
          </Link>

          )
        }
       
        <Typography className='cursorArrow' color={COLORS.primary} onClick={props?.handleActiveClick}>{props?.activeHeading}</Typography>
      </Breadcrumbs>
    </div>
  );
}
