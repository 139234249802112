import React, { useState } from "react";
import "./styles.scss";
import LanguageIcon from "@mui/icons-material/Language";
import { useDispatch, useSelector } from "react-redux";
import { COLORS } from "@constants";
import TableView from "@components/TableView";
import { distributorRow } from "./data";
import { distributorPlanExpiry } from "@reducers/api";
import { useComponentDidMount, useComponentDidUpdate } from "@utils/useEffectHooks";

export default function ListViewExpiry(props) {
  const dispatch = useDispatch();

  const { distributorPlanExpiryData } = useSelector((state) => state.dashboard);
  const [expireList, setExpireList] = useState([]);

  useComponentDidMount(() => {
    dispatch(distributorPlanExpiry());
  }, []);

  useComponentDidUpdate(() => {
    let list = [];
    distributorPlanExpiryData && distributorPlanExpiryData.map((data, index) => {
      list.push({
        distributorName: data?.distributorName,
        validity: data?.validity,
        limit: data?.limit,
        expiration: data?.expiration,
      });
    });

    setExpireList(list);
  }, [distributorPlanExpiryData]);


  return (
    <div className="distributor-expiry-list-container">
      <div className="flex-center title-expiry">
        <LanguageIcon style={{ color: COLORS.primary }} />
        Distributor Expiration
      </div>
      <TableView color={COLORS.primary} data={expireList} rows={distributorRow} />
    </div>
  );
}
