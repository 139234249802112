import "./styles.scss";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import PersonIcon from "@mui/icons-material/Person";
import "react-circular-progressbar/dist/styles.css";

export default function ProgressBar(props) {
  return (
    <CircularProgressbarWithChildren
      styles={{
        root: { filter: "drop-shadow(1px 1px 2px lightgray)" },
        trail: { stroke: "white", strokeLinecap: "butt", strokeWidth: 5 },
        path: { strokeLinecap: "butt", stroke: props?.color, strokeWidth: 5 },
      }}
      value={props?.value}
    >
      <PersonIcon style={{ color: props?.color }} sx={{ fontSize: 27 }} />
      <div className="prog-ctr-tle">{props?.title}</div>
      <div className="prog-ctr-tle">Subscribers</div>
      <div className="prog-ctr-tle">{props?.count}</div>
    </CircularProgressbarWithChildren>
  );
}
