const tableRows = [
  {
    key: "checkbox",
    align: "left",
  },
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },
  {
    title: "Name",
    key: "name",
    align: "left",
  },
  {
    title: "Acc No ",
    key: "accNo",
    align: "left",
  },
  {
    title: "CAN ID",
    key: "canId",
    align: "left",
  },
  {
    title: "STB No",
    key: "stbNo",
    align: "left",
  },
  {
    title: "Service Type",
    key: "serviceType",
    align: "left",
  },
  {
    title: "Billing Type",
    key: "billingType",
    align: "left",
  },
  {
    title: "Email ID",
    key: "email",
    align: "left",
  },  
  {
    title: "City",
    key: "city",
    align: "left",
    
  },
  {
    title: "State",
    key: "state",
    align: "left",
    
  },
  {
    title: "Pay now added Date",
    key: "status",
    align: "left",
    justify: "center",
  }, 
];

export { tableRows };
