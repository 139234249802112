const tableRows = [
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },
  {
    title: "Employee Email",
    key: "employeeId",
    align: "left",
  },
  {
    title: "Employee Name",
    key: "employeeName",
    align: "left",
  },
  {
    title: "Expense Name",
    key: "accountId",
    align: "left",
  },
  {
    title: "Type",
    key: "type",
    align: "left",
  },
  {
    title: "Amount",
    key: "email",
    align: "left",
  },
  {
    title: "Description",
    key: "role",
    align: "left",
  },
  {
    title: "Created Date",
    key: "status",
    align: "left",
    justify: "center",
  },
  {
    title: "Action",
    key: "action",
    align: "left",
  },
];

export { tableRows };
