import { createSlice } from "@reduxjs/toolkit";
import { ticketOpenStatus, ticketList, deleteTicket, updateTicket, addTicket,chatSave,chatList, ticketAllList, smsType, smsArea, bulkSend} from "./api";

const initialState = {
  ticketStatusOpenData: [],
  ticketListData: [],
  ticketDeleted: false,
  ticketAdded: false,
  chatlistData:[],
  chatSaveData:false,
  smsTypeData: [],
  smsAreaData: [],
  bulksmsSend : false
};

export const ticketSlice = createSlice({
  name: "ticketing",
  initialState,
  reducers: {
    reset: (state) => {
      state.ticketAdded = false;
      state.ticketDeleted = false;
      state.chatlistData = [];
      state.chatSaveData = false;
      state.bulksmsSend = false;
      return state;
    },
  },
  extraReducers: {
    [ticketOpenStatus.pending]: (state) => {},
    [ticketOpenStatus.fulfilled]: (state, { payload }) => {
      state.ticketStatusOpenData = payload.data.data;
    },
    [ticketOpenStatus.rejected]: (state, { payload }) => {},

    [ticketList.pending]: (state) => {},
    [ticketList.fulfilled]: (state, { payload }) => {
      state.ticketListData = payload.data.data;
    },
    [ticketList.rejected]: (state, { payload }) => {},

    [ticketAllList.pending]: (state) => {},
    [ticketAllList.fulfilled]: (state, { payload }) => {
      state.ticketListData = payload.data.data;
    },
    [ticketAllList.rejected]: (state, { payload }) => {},

    [deleteTicket.pending]: (state) => {},
    [deleteTicket.fulfilled]: (state, { payload }) => {
      state.ticketDeleted = payload.status;
    },
    [deleteTicket.rejected]: (state, { payload }) => {},
    [addTicket.pending]: (state) => {},
    [addTicket.fulfilled]: (state, { payload }) => {
      state.ticketAdded = payload.data.status;
    },
    [addTicket.rejected]: (state, { payload }) => {},

    [updateTicket.pending]: (state) => {},
    [updateTicket.fulfilled]: (state, { payload }) => {
      state.ticketAdded = payload.data.status;
    },
    [updateTicket.rejected]: (state, { payload }) => {},

    [chatList.pending]: (state) => {},
    [chatList.fulfilled]: (state, { payload }) => {
      state.chatlistData = payload.data.status;
    },
    [chatList.rejected]: (state, { payload }) => {},

    [chatSave.pending]: (state) => {},
    [chatSave.fulfilled]: (state, { payload }) => {
      state.chatSaveData = payload.data.status;
    },
    [chatSave.rejected]: (state, { payload }) => {},

    [smsType.pending]: (state) => {},
    [smsType.fulfilled]: (state, { payload }) => {
      state.smsTypeData = payload.data.data;
    },
    [smsType.rejected]: (state, { payload }) => {},

    [smsArea.pending]: (state) => {},
    [smsArea.fulfilled]: (state, { payload }) => {
      state.smsAreaData = payload.data.data;
    },
    [smsArea.rejected]: (state, { payload }) => {},

    [bulkSend.pending]: (state) => {},
    [bulkSend.fulfilled]: (state, { payload }) => {
      state.bulksmsSend = payload.data.status;
    },
    [bulkSend.rejected]: (state, { payload }) => {},

  },
});

export const { reset } = ticketSlice.actions;

export default ticketSlice.reducer;
