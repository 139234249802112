import "./styles.scss";
import Avatar from "@mui/material/Avatar";
import { Grid } from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {
  deleteAssignSubs,
  userAccountStatus,
  subscriberList,
  currentServiceList,
  lastPaid,
  generateBillAdd,
  othersAll
} from "@reducers/api";
import {
  useComponentDidMount,
  useComponentDidUpdate,
  useComponentWillUnmount,
} from "@utils/useEffectHooks";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "@reducers/subscriber";
import PaymentModal from "../Modals/makePayment";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { transactionRows } from "./data";
import { COLORS } from "@constants";
import TableView from "@components/TableView";
import moment from "moment";

export default function SubViewCard(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { userAccountStatusData, payNowAdded, lastPaidData, otherAllData } = useSelector(
    (state) => state.subscriber
  );
  const [showTransaction, setShowTransaction] = useState(false);
  const [transaction, settransaction] = useState([]);
  const [show, setShow] = useState(false);

  const lableAndValue = (title, value) => {
    return (
      <div className="spacing-between-tiles">
        {props?.data?.is_active === true ? (
          <div className="sub-card-dark-text">{title}</div>
        ) : (
          <div className="sub-card-dark-text-inactive">{title}</div>
        )}
        {props?.data?.is_active === true ? (
          <div className="secondarytext-sub">{value}</div>
        ) : (
          <div className="secondarytext-sub-inactive">{value}</div>
        )}
      </div>
    );
  };
  const lableAndValuethree = (title, value, valuetwo) => {
    return (
      <div className="spacing-between-tiles">
        {props?.data?.is_active === true ? (
          <div>
            <div className="sub-card-dark-text">{title}</div>
            <div className="secondarytext-sub">
              {value} & {valuetwo}
            </div>
          </div>
        ) : (
          <div>
            <div className="sub-card-dark-text-inactive">{title}</div>
            <div className="secondarytext-sub-inactive">
              {value} & {valuetwo}
            </div>
          </div>
        )}
      </div>
    );
  };

  let user = JSON.parse(localStorage.getItem("user"));

  const updateStatus = (id, status) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      _id: id,
      is_active: status,
      sms_access: user?.sms_access,
      whatsapp_access: user?.whatsapp_access,
    };
    dispatch(userAccountStatus(payload));
  };

  useComponentWillUnmount(() => {
    dispatch(reset());
  });

  const onShowPopUp = () => {
    setShow(!show);
    let listid = props?.data?._id;
    dispatch(reset());
    let user = JSON.parse(localStorage.getItem("user"));
    let distributorId = `${user?.distributor_ref_id}&service_type=${props?.data?.service_type}`;
    dispatch(othersAll(distributorId));

    let paidquery = `cust_ref_id=${listid}`;
    dispatch(lastPaid(paidquery));
    if (props?.data?.service_type === "internet") {
      // let listid = props?.data?._id;
      let query1 = `${listid}&is_active=1`;
      dispatch(currentServiceList(query1));
      dispatch(reset());
    }

    if (lastPaidData?.billing_status === "open") {
      enqueueSnackbar("Bill already generated for this customer ", {
        variant: "warning",
      });

    }
  };

  const handleClose = () => {
    setShow(!show);
  };

  useComponentDidUpdate(() => {
    setShow(false);
    dispatch(reset());
  }, [payNowAdded]);

  useComponentDidUpdate(() => {
    let list = [];
    props?.data &&
      props?.data?.transactions?.map((data, index) => {
        list.push({
          service_type: data?.service_type,
          bill_amount: data?.bill_amount,
          collection_amount: data?.collection_amount,
          collected_by: data?.collected_by,
          due_amount: data?.due_amount || "0",
          remarks: data?.remark,
          collected_date: moment(data?.collected_date).format("ll"),
          expiry_date:
            data?.dataFrom === "hardware"
              ? "-"
              : moment(data?.expired_date).format("ll"),
        });
      });
    settransaction(list);
  }, [props?.data]);

  useComponentDidMount(() => {
    let list = [];
    props?.data &&
      props?.data?.transactions?.map((data, index) => {
        list.push({
          service_type: data?.service_type,
          bill_amount: data?.bill_amount,
          collection_amount: data?.collection_amount,
          collected_by: data?.collected_by,
          due_amount: data?.due_amount || "0",
          remarks: data?.remark,
          collected_date: moment(data?.collected_date).format("ll"),
          expiry_date:
            data?.dataFrom === "hardware"
              ? "-"
              : moment(data?.expired_date).format("ll"),
        });
      });
    settransaction(list);
  }, [props?.data]);

  console.log(props?.data, "remark")

  const handleBillGenerate = () => {
    let payload = {
      selected_cust: {},
    };

    Object.assign(payload.selected_cust, { [props?.data?._id]: true });

    dispatch(generateBillAdd(payload));
  };

  console.log(lastPaidData, "idssss")



  return (
    <>
      <div className="sub-view-card">
        <PaymentModal
          show={show}
          listData={props?.data}
          handleClose={handleClose}
          lastData={lastPaidData}
          otherAllData={otherAllData}
        />
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={4} md={3}>
            <Avatar
              alt={props?.data?.name}
              src={`data:image/png;base64,${props?.data?.fileData}`}
              sx={{ width: 150, height: 150, margin: "auto" }}
            />
            {props?.data?.is_active === true ? (
              <div className="secondarytext-sub" style={{ marginTop: 10 }}>
                {props?.data?.name}
              </div>
            ) : (
              <div
                className="secondarytext-sub-inactive"
                style={{ marginTop: 10 }}
              >
                {props?.data?.name}
              </div>
            )}

            <div
              className="text-align name-text-style displayFlex centerAlignment"
              style={{
                padding: 10,
              }}
            >

              {props?.data?.is_active === true ? (
                <div
                  onClick={() =>
                    navigate(`/distributor/subscriber-edit?id=${props?.data._id}`)
                  }
                >
                  <EditIcon className="cur-pointer" />
                </div>
              ) : (
                <div

                >
                  <EditIcon />
                </div>
              )}

              <div
                onClick={() =>
                  dispatch(
                    deleteAssignSubs({
                      _id: props?.data._id,
                      sms_access: user?.sms_access,
                      whatsapp_access: user?.whatsapp_access,
                    })
                  )
                }
              >
                <DeleteIcon className="cur-pointer" />
              </div>
            </div>
          </Grid>
          <Grid item xs={4} sm={4} md={9}>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={4} md={3}>
                {lableAndValue("Account Id", props?.data?.account_id)}
              </Grid>
              {props?.data?.service_type === "cable" ? (
                <Grid item xs={4} sm={4} md={3}>
                  {lableAndValue("STB Number", props?.data?.stb_no[0]?.stb_no)}
                </Grid>
              ) : (
                <Grid item xs={4} sm={4} md={3}>
                  {lableAndValue("IP-Address", props?.data?.login_ip)}
                </Grid>
              )}

              <Grid item xs={4} sm={4} md={3}>
                {lableAndValuethree(
                  "Service Type & Billing Type",
                  props?.data?.service_type,
                  props?.data?.billing_type
                )}
              </Grid>
              <Grid item xs={4} sm={4} md={3}>
                <div className="spacing-between-tiles">
                  {props?.data?.is_active === true ? (
                    <div className="flex-justify-center">
                      <Button
                        variant="outlined"
                        size="small"
                        style={{
                          borderRadius: 15,
                          border: "1px solid #00249b",
                          color: "#00249b",
                        }}
                        target="_blank"
                        href={props?.data?.service_type === "cable" ? props?.data?.configData?.mso_url : props?.data?.configData?.isp_url}


                      >
                        {props?.data?.service_type === "cable" ? props?.data?.configData?.mso : props?.data?.configData?.isp}
                      </Button>
                    </div>
                  ) : (
                    <div className="flex-justify-center">
                      <Button
                        variant="outlined"
                        size="small"
                        style={{
                          borderRadius: 15,
                          border: "1px solid red",
                          color: "red",
                          cursor: "auto"
                        }}
                      >
                        {props?.data?.service_type === "cable"
                          ? props?.data?.configData?.mso : props?.data?.configData?.isp}
                      </Button>
                    </div>
                  )}
                </div>
              </Grid>
              {props?.data?.service_type === "cable" ? (
                <Grid item xs={4} sm={4} md={3}>
                  {lableAndValue("Can Id", props?.data?.can_id)}
                </Grid>
              ) : (
                <Grid item xs={4} sm={4} md={3}>
                  {lableAndValue("User Id", props?.data?.user_id)}
                </Grid>
              )}

              <Grid item xs={4} sm={4} md={3}>
                {lableAndValue(
                  "Bill Amount",
                  props?.data?.lastPaid?.bill_amount || "0"
                )}
              </Grid>
              <Grid item xs={4} sm={4} md={3}>
                {lableAndValue(
                  "Plan validity",
                  props?.data?.payment_validity &&
                  props?.data?.payment_validity.concat(" ", "Month")
                )}
              </Grid>
              <Grid item xs={4} sm={4} md={3}>
                <div className="spacing-between-tiles">
                  {props?.data?.is_active === true ? (
                    <div
                      className="status-btn-table-active cursor-pointer"
                      onClick={() => updateStatus(props?.data?._id, false)}
                    >
                      Active
                    </div>
                  ) : (
                    <div
                      className="status-btn-table-inactive cursor-pointer"
                      onClick={() => updateStatus(props?.data?._id, true)}
                    >
                      {" "}
                      InActive{" "}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={4} sm={4} md={3}>
                {lableAndValue("Mobile Number", props?.data?.mobile_no)}
              </Grid>
              <Grid item xs={4} sm={4} md={3}>
                {lableAndValue("Flat No / Sub Area", props?.data?.flat_no + "/" + props?.data?.subarea)}
              </Grid>
              <Grid item xs={4} sm={4} md={3}>
                {lableAndValue("Area", props?.data?.area)}
              </Grid>
              <Grid item xs={4} sm={4} md={3}>
                {lableAndValue(
                  "Outstanding balance",
                  props?.data?.lastPaid?.due_amount || "0"
                )}
              </Grid>
            </Grid>

            {/* <div  className="expand" style={{ marginTop: 30 }}> Expand Here</div> */}
          </Grid>
        </Grid>

        {props?.data?.is_active === true ? (
          <Stack direction="row" className="btns-stack" spacing={2}>
            <Button
              variant="outlined"
              size="small"
              className=""
              style={{
                borderRadius: 15,
                border: "1px solid #00249b",
                color: "#00249b",
                fontSize: "11px",

              }}
              onClick={() =>
                navigate(
                  `/distributor/subscriber-profile?id=${props?.data._id}`
                )
              }
            >
              View User Detail
            </Button>
            <Button
              variant="outlined"
              size="small"
              className=" spaceBtns"
              style={{
                borderRadius: 15,
                border: "1px solid #00249b",
                color: "#00249b",
                fontSize: "11px",
              }}
              onClick={onShowPopUp}
            >
              Make Payment
            </Button>
            {props?.data?.billing_initiated === false && (
              <Button
                variant="outlined"
                size="small"
                className=" spaceBtns"
                style={{
                  borderRadius: 15,
                  border: "1px solid #00249b",
                  color: "#00249b",
                  fontSize: "11px",
                }}
                onClick={() => handleBillGenerate()}
              >
                Generate Bill
              </Button>
            )}
          </Stack>
        ) : (
          <Stack direction="row" className="btns-stack" spacing={2}>
            <Button
              variant="outlined"
              size="small"
              className=""
              style={{
                borderRadius: 15,
                border: "1px solid red",
                color: "red",
                fontSize: "11px",
                cursor: "auto"
              }}
            >
              View User Detail
            </Button>
            <Button
              variant="outlined"
              size="small"
              className=" spaceBtns"
              style={{
                borderRadius: 15,
                border: "1px solid red",
                color: "red",
                fontSize: "11px",
                cursor: "auto"
              }}
            >
              Make Payment
            </Button>
            {props?.data?.billing_initiated === false && (
              <Button
                variant="outlined"
                size="small"
                className=" spaceBtns"
                style={{
                  borderRadius: 15,
                  border: "1px solid red",
                  color: "red",
                  fontSize: "11px",
                  cursor: "auto"
                }}
              >
                Generate Bill
              </Button>
            )}
          </Stack>
        )}

        <Accordion className="subeditcardaccordian">
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="subeditcard"
            style={{ justifyContent: "right" }}
          >
            Expand Here
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <TableView
                rows={transactionRows}
                data={transaction || "nodata"}
                color={COLORS.primary}
              //handleCheckBoxChange={handleCheckBox}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}
