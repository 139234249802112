import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import "./styles.scss";
import { useState } from "react";
import Radio from "@mui/material/Radio";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function TableView(props) {
  const [mainCheckBox, setMainCheckBox] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: props?.color,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#F2F2F2",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  return (
    <TableContainer component={Paper}  className=  {` ${props?.addPlan ? 'addplan-res' : 'table-billing'}`} >
      <Table stickyHeader  aria-label="customized table" id="tableId">
        <TableHead>
          <TableRow>
            {props?.rows?.map((row) => (
              <StyledTableCell style={{ textAlign: row.align, backgroundColor: row.key === "checkbox" && "white" }}>
                {row.key === "checkbox" ? (
                  <Checkbox
                    onChange={(e) => {
                      setMainCheckBox(e.target.checked);
                      props?.handleCheckBoxChange("all", e.target.checked);
                    }}
                    sx={{
                      "&.Mui-checked": {
                        color: props?.color,
                      },
                    }}
                    {...label}
                    checked={props?.checkedVal === true && mainCheckBox }
                    size="small"
                  />
                )  : (
                  row.title
                ) }
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.data && props?.data?.map((data, index) => (           
            <StyledTableRow key={index}>
              {props?.rows?.map((row) => (                
                <StyledTableCell style={{ textAlign: row.align, backgroundColor: row.key === "checkbox" && "white" }} className={row.justify === "center" && "flex-justify-center"} component="th" scope="row">
                  {row.key === "checkbox" ? (
                    <Checkbox
                      onClick={() => props?.handleCheckBoxChange(index)}
                      sx={{
                        "&.Mui-checked": {
                          color: props?.color,
                        },
                      }}
                      {...label}
                      checked={data?.checked}
                      size="small"
                    />
                  ) : row.key=="radio" ? (
                    <Radio
                       checked={selectedValue == data?.planname}
                       name="radio-buttons"
                        size="small"
                        value={data?.planname}
                        onChange={(e) => {
                          handleChange(e);
                          props?.handleCheckRadio(data);
                        }}
                          />
                  ) : (
                    data[row.key]
                  )}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
