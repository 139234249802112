import React from "react";
import AdminBanner from "@assets/images/customerbanner.png";
import ComputerImage from "@assets/images/customerapp1.png";
import ComputerImageLeft from "@assets/images/customerapp2.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Grid } from "@mui/material";

import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./styles.scss";
import { distributorHome } from "@reducers/api";
import {
  useComponentDidMount,
  useComponentDidUpdate,
  paramsQuery,
  useComponentWillUnmount,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import Footer from "@components/homeFooter";




const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: true,
};



export default function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();





  useComponentDidMount(() => {

    dispatch(distributorHome());
  }, []);


  return (
    <div className="admin-home-container">

      <div className="main-image">
        <img className="adminBanner" src={AdminBanner} />

      </div>
      <div className="centerDiv">

    
      <div>
        <Grid container spacing={2}>
          <Grid item xs={6} className='flex-justify-center'>
          <div className="rectangle">
            <p>Customer Profile</p>
            <p>Add Plan</p>
            <p>Search By STB</p>
            <p>Pay Now</p>
            <p>Plan List</p>
          </div>
          </Grid>
          <Grid item xs={6} className='text-align'>
            <div className="computerImgdiv">
            <img className="computerImg" src={ComputerImage} />
            </div>
         
          </Grid>
          
        </Grid>
      </div>
      <div>
        <Grid container spacing={2}>
          
          <Grid item xs={6} className='text-align'>
            <div className="computerImgdiv">
            <img className="computerImg" src={ComputerImageLeft} />
            </div>
         
          </Grid>
          <Grid item xs={6} className='flex-justify-center'>
          <div className="rectangle">
          <p>Customer Details</p>
            <p>Payment History</p>
            <p>Ticket Raise</p>
            <p>Ticket History </p>
            <p>Pay Now</p>
            
          </div>
          </Grid>
          
        </Grid>
      </div>
      </div>
      <div id='footercontent'><Footer/></div>
    </div>
  );
}
