const distributorRow = [
  {
    title: "Distributor Name",
    key: "distributorName",
    align: "left",
  },
  {
    title: "Validity",
    key: "validity",
    align: "left",
  },
  {
    title: "Limit",
    key: "limit",
    align: "left",
  },
  {
    title: "Expiration",
    key: "expiration",
    align: "left",
  },
];

export { distributorRow };
