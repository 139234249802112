import React from "react";
import AdminBanner from "@assets/images/adminbanner.jpg";
import ComputerImage from "@assets/images/computerimage1.png";
import ComputerImageLeft from "@assets/images/computerimage2.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Grid } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.scss";
import Footer from "@components/homeFooter";


export default function Home() {
  return (
    <div className="admin-home-container">
     
      <div className="main-image">
        <img className="adminBanner" src={AdminBanner} />

      </div>
      <div className="centerDiv">

    
      <div>
        <Grid container spacing={2}>
          <Grid item xs={6} className='flex-justify-center'>
          <div className="rectangle">
            <p>Active Subscribers</p>
            <p>InActive Subscribers</p>
            <p>Payment Collection Summary</p>
            <p>Monthly Payment Summary</p>
            <p>Distributor Expiration</p>
          </div>
          </Grid>
          <Grid item xs={6} className='text-align'>
            <div className="computerImgdiv">
            <img className="computerImg" src={ComputerImage} />
            </div>
         
          </Grid>
          
        </Grid>
      </div>
      <div>
        <Grid container spacing={2}>
          
          <Grid item xs={6} className='text-align'>
            <div className="computerImgdiv">
            <img className="computerImg" src={ComputerImageLeft} />
            </div>
         
          </Grid>
          <Grid item xs={6} className='flex-justify-center'>
          <div className="rectangle">
            <p>Plan List</p>
            <p>Distributor List</p>
            <p>Employee List</p>
            <p>Add Distributor</p>
            <p>Add Plan</p>
          </div>
          </Grid>
          
        </Grid>
      </div>
      </div>
      
      <div id='footercontent'><Footer/></div>
    </div>
  );
}
