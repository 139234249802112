import SubEditCard from "@components/SubEditCard";
import SubEditSubCard from "@components/subEditSubCard";
import SubEditActivePlan from "@components/SubEditActivePlan";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useComponentDidMount, paramsQuery, useComponentDidUpdate, useComponentWillUnmount} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { subscriberList, updateBalance, areaList, currentServiceList, allEmpList, subscriberView} from "@reducers/api";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/subscriber";
import BreadCrumb from "@components/Breadcrumb";
import Grid from "@mui/material/Grid";
import { Subreset } from "@reducers/subscriber";


export default function SubscriberList(props) {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subscriberListData, updateBalanceData, areaListData, currentServiceListData, subviewData } = useSelector(
    (state) => state.subscriber
  );
  const { empFullData } = useSelector((state) => state.employee);
  let filtered = subscriberListData?.customers && subscriberListData?.customers.filter((data) => data._id === paramsQuery("id"));

  const [searchQuery, setSearchQuery] = useState("");

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}`;
    if (value?.type === "search") {
      query += `&searchText=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&searchText=${searchQuery}`;
    }
    return query;
  };

  useComponentDidMount(() => {
    let id = paramsQuery("id");
    let query = queryGenerator();
    dispatch(subscriberList(query));
    dispatch(areaList(query));
    dispatch(currentServiceList(id));
    dispatch(allEmpList(query));
    dispatch(subscriberView(id));

  }, []);

  useComponentWillUnmount(() => {
    dispatch(reset());
    dispatch(Subreset());
  });

  console.log(subviewData, "subcustomer")


  useComponentDidUpdate(() => {
    let id = paramsQuery("id");
    let message = "";
    if (updateBalanceData ) {
      if (id) {
        message = "Subscriber Details Updated";
      } 
      enqueueSnackbar(message, {
        variant: "success",
      });

      navigate(`/distributor/subscriber-list`);
    }
  }, [updateBalanceData]);


  const handleFormValues = (values) => {
    let id = paramsQuery("id");
    let user = JSON.parse(localStorage.getItem("user"));
    let dist_id = user?.distributor_ref_id;
    let payload = {
      assign_to: values?.assignTo?.name || "",
      area: values.area || values?.areanew,
      auto_billing: values?.bill?.value,
      billing_initiated: filtered && filtered[0]?.billing_initiated,
      emp_ref_id: values?.assignTo?._id || "",
      can_id: values.canid,
      city: values.city,
      distributor_ref_id: dist_id,
      email_id: values.email,
      flat_no: values.flatNo,
      floor: values.floor,
      is_active: filtered && filtered[0]?.is_active,
      is_deleted: filtered && filtered[0]?.is_deleted,
      mobile_no: values.mobileNo,
      mso_id: values.msoid,
      name: values.userName,
      pin_code: values.pincode,
      state: values.state,
      subarea: values?.subarea || values?.subareanew,
      tax: values.tax?.value,
      discount_amount: values.disoountCharge,
      discount_type: values.discountType?.value,
      landmark: values?.landmark,
      login_ip: values?.lohinIp,
      user_id: values?.userid,
      staff_email: values?.assignTo?.email_id || "",
      staff_name: values?.assignTo?.name || "",
    };

    if (id) {
      payload["_id"] = id;
      dispatch(updateBalance(payload));

    } else {
      dispatch((payload));
    }
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };


  return (
    <div className="subscriberList-container"> 
    <Grid container spacing={2}>
          <Grid item xs={8}>
        
          </Grid>
          <Grid item xs={12} md={4}className="flex-end">
            <div className="subEditMain">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              secondaryHeading="Subscriber List"
              handleSecondaryClick={() => handleBreadCrumbRedirect("subscriber-list")}
              activeHeading="Edit Subscriber"
              
            />
            </div>
            
          </Grid>
        </Grid>    
       <div className="subeditmainborder">
         <div style={{margin:15}}>
          <div className="subEditMain">User Details</div> 
          <div className="subEditCard">
          <SubEditCard data={subviewData}/>
          </div>       
          <div className="subcardEdit">
          <SubEditSubCard data={subviewData} handleFormValues={(values) => handleFormValues(values)} areaData={areaListData}  employeeData={empFullData} />
          </div>
          <div className="subcardEditActiveplan">
          <SubEditActivePlan activeplan={currentServiceListData}/>
          </div>
        </div>
      </div>
    </div>
  );
}
