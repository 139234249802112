import { Grid } from "@mui/material";
import "./styles.scss";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import InputBase from "@mui/material/InputBase";
import { COLORS } from "@constants";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CSVLink } from "react-csv";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";



export default function SearchWithButtons(props) {
  const [searchValue, setSearch] = useState("");

  const handleSearch = () => {
    props?.handleChange(searchValue);
  };

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={4} sm={4} md={3}>
      <Stack spacing={2} direction="row" className="flex-end searchWith-btn">

        <InputBase
          className="search-input"
          size="small"
          onChange={(e) => {
            setSearch(e.target.value)
            // props?.handleChange(e.target.value);
          }}
          placeholder="Search By"
          // endAdornment={
          //   <InputAdornment position="end">
          //     <SearchIcon style={{ color: COLORS.primary }}
          //      />
          //   </InputAdornment>
          // }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            style: { color: COLORS.primary },
          }}
        />
         <Button
              className="cursorArrow"
              style={{ textTransform: "none", backgroundColor: COLORS.primary, cursor: "pointer" }}
              variant="contained"
              size="small"
              onClick={handleSearch}
            >Search</Button> 
            </Stack>
      </Grid>

      <Grid item xs={0} sm={4} md={3} className= "emptyContainer">
            
      </Grid>

      
     
      <Grid item xs={4} sm={4} md={6} className= "serachBarButtons">
     
           
        {props.checkbox && (
          <Grid container className="flex-end">
            <Grid>
              <div className="flex-end ">
                <FormControlLabel value="end" control={<Checkbox />} label={props?.firstLabel} labelPlacement="end" />
              </div>
            </Grid>
            <Grid>
              <div className="flex-end ">
                <FormControlLabel value="end" control={<Checkbox />} label={props?.secondLabel} labelPlacement="end" />
              </div>
            </Grid>
          </Grid>
        )}

        <Stack spacing={2} direction="row" className="flex-end searchWith-btn">
          {
            props.isViewDropdown &&
            <>
              <Grid>
              <Button
              className="cursorArrow"
              style={{ textTransform: "none", backgroundColor: COLORS.primary, cursor: "auto" }}
              variant="contained"
              size="small"
            >
              {props?.count}
            </Button>              
              </Grid>
              
              
            </>
          }

          {
            props.filters &&
            <>
              <Grid>
                <div>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={props.dropBoolean}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} placeholder="validity" size="small" />} />
                </div>
              </Grid>
              <Button
              onClick={props?.handlePrimaryClick}
              style={{ textTransform: "none", backgroundColor: COLORS.primary }}
              variant="contained"
              size="small"
            >
              Filter
            </Button>
            </>
          }

          
        {props?.teritorybutton && (
            <Button
              onClick={props?.handleTeritoryClick}
              style={{ textTransform: "none", backgroundColor: props?.teritorybutton?.bgColor ? COLORS.primary : "white", color: props?.teritorybutton?.color, border: `1px solid${props?.primaryButton?.color}` }}
              variant={props?.teritorybutton?.type === "contained" ? "contained" : "outlined"}
              size="small"
            >
              {props?.teritorybutton?.title}
            </Button>
          )}
                        

          {props?.primaryButton && (
            <Button
              onClick={props?.handlePrimaryClick}
              style={{ textTransform: "none", backgroundColor: props?.primaryButton?.bgColor ? COLORS.primary : "white", color: props?.primaryButton?.color, border: `1px solid${props?.primaryButton?.color}` }}
              variant={props?.primaryButton?.type === "contained" ? "contained" : "outlined"}
              size="small"
            >
              {props?.primaryButton?.title}
            </Button>
          )}
          {props?.secondaryButton && props?.secondaryButton?.title == "Export" ? (
            props?.secondaryButton?.data?.length ? (
              <Button
                style={{ textTransform: "none", backgroundColor: props?.secondaryButton?.bgColor ? COLORS.primary : "white", color: props?.secondaryButton?.color, border: `1px solid${props?.secondaryButton?.color}` }}
                variant={props?.secondaryButton?.type === "contained" ? "contained" : "outlined"}
                size="small"
              >
                <CSVLink filename={props?.secondaryButton?.filename} style={{ color: props?.secondaryButton?.color }} data={props?.secondaryButton?.data}>
                  {props?.secondaryButton?.title}
                </CSVLink>
              </Button>
            ) : (
              ""
            )
          ) : (
            props?.secondaryButton &&
            <Button
              onClick={props?.handleSecondaryClick}
              style={{ textTransform: "none", backgroundColor: props?.secondaryButton?.bgColor ? COLORS.primary : "white", color: props?.secondaryButton?.color, border: `1px solid${props?.secondaryButton?.color}` }}
              variant={props?.secondaryButton?.type === "contained" ? "contained" : ""}
              size="small"
            >
              {props?.secondaryButton?.title}
            </Button>
          )}
          {props?.extraButton && (
            <Button
              onClick={props?.handleExtraClick}
              style={{ textTransform: "none", backgroundColor: props?.extraButton?.bgColor ? COLORS.primary : "white", color: props?.extraButton?.color, border: `1px solid${props?.extraButton?.color}` }}
              variant={props?.extraButton?.type === "contained" ? "contained" : "outlined"}
              size="small"
            >
              {props?.extraButton?.title}
            </Button>
          )}

        {props?.secondryExtraButton && (
               <Button
               style={{ textTransform: "none", backgroundColor: props?.secondryExtraButton?.bgColor ? COLORS.primary : "white", color: props?.secondryExtraButton?.color, border: `1px solid${props?.secondryExtraButton?.color}` }}
               variant={props?.secondryExtraButton?.type === "contained" ? "contained" : "outlined"}
               size="small"
             >
               <CSVLink filename={props?.secondryExtraButton?.filename} style={{ color: props?.secondryExtraButton?.color }} data={props?.secondryExtraButton?.data}>
                 {props?.secondryExtraButton?.title}
               </CSVLink>
             </Button>
          )}

        {props?.secondryExtraButtonExportData && (
             <Button
                onClick={props?.handleExtraExportClick}
                style={{ textTransform: "none", backgroundColor: "white", color: props?.secondryExtraButtonExportData?.color, border: `1px solid${props?.secondryExtraButtonExportData?.color}` }}
                variant={props?.secondryExtraButtonExportData?.type === "contained" ? "contained" : "outlined"}
                size="small"
              >
                {props?.secondryExtraButtonExportData?.title}
              </Button>
          
          )}

        </Stack>
      </Grid>
    </Grid>
  );
}
