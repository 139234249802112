import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./styles.scss";
import { styled } from "@mui/material/styles";

import Button from "@mui/material/Button";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Stack from "@mui/material/Stack";
import { useComponentDidUpdate } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { customerProfileDetails, custProfileUpdate, updateBalance , profileimageUpload} from "@reducers/api";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import PersonIcon from "@mui/icons-material/Person";
import {
  paramsQuery
} from "@utils/useEffectHooks";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/customer";
import Resizer from "react-image-file-resizer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};

const Input = styled("input")({
  display: "none",
});

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const validation = Yup.object({
  firstName: Yup.string().required("Required").nullable(),
  city: Yup.string().required("Required").nullable(),
  area: Yup.string().required("Required").nullable(),
  pincode: Yup.string().required("Required").nullable(),
  Mail: Yup.string()
    .required("Required")
    .email("Invalid email address"),
  phone: Yup.string().required("Required").max(10, 'Must be exactly 10 digits').matches(new RegExp('[0-9]{10}'), "Phone number is not valid"),
});

export default function CustomerProfilePopup(props) {

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const { profileData, custProfileUpdateData } = useSelector((state) => state.customer);







  let imagePreview = null;
  if (selectedImage) {
    imagePreview = <img className="img-previewUpload" src={URL.createObjectURL(selectedImage)} alt={"Not found"} />;
  } else {
    imagePreview = profileData?.customerData?.fileData !== null ? <img className="img-previewUpload" src={`data:image/png;base64,${profileData?.customerData?.fileData}`} alt={"I am here"} /> : <PersonIcon style={{ color: "grey", marginLeft: "10px", fontSize: "7rem", marginTop: "1rem" }} />
  }

  const handleImgChange = async (event) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let custId = user?.cust_ref_id;
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = async () => {
      const image = await resizeFile(event.target.files[0]);

      const dataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(",");
        const byteString =
          splitDataURI[0].indexOf("base64") >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++)
          ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
      };

      const newFile = dataURIToBlob(image);
      const formData = new FormData();
      formData.append(
        "files",
        newFile
      );
      formData.append(
        "id",
        custId
      );
      let payload = {
        files: formData,
        custId: custId
      }
      const fileUploadedUrl = await dispatch(profileimageUpload(payload));
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  useComponentDidUpdate(() => {
    setValue("firstName", profileData?.customerData?.name);
    setValue("phone", profileData?.customerData?.mobile_no);
    setValue("Mail", profileData?.customerData?.email_id);
    setValue("area", profileData?.customerData?.area);
    setValue("city", profileData?.customerData?.city)
    setValue("pincode", profileData?.customerData?.pin_code)
  }, [profileData?.customerData]);

  const handleFormValues = (values) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let custId = user?.cust_ref_id;
    let payload = {
      name: values?.firstName,
      mobile_no: values?.phone,
      email_id: values?.Mail,
      area: values?.area,
      pin_code: values?.pincode,
      city: values?.city,
      _id: custId
    };
    dispatch(custProfileUpdate(payload));
  }

  const onSubmit = (data) => handleFormValues(data);


  const [open, setOpen] = useState(false);

  const handleClose = () => {
    props?.handleClose();
  };

  useEffect(() => {
    setOpen(props?.show);
  }, [props?.show]);


  const InputWithLabel = (label, error, input) => {
    return (
      <Grid item xs={4} sm={6} md={6}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center">
          <Grid item xs={4} sm={6} md={4}>
            <div>{label}</div>
          </Grid>
          <Grid item xs={4} sm={6} md={8}>
            {input}
            <div className="field-error">{error}</div>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  let user = JSON.parse(localStorage.getItem("user"));
  let custId = user?.cust_ref_id;

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style} className="custr-profile-popup-cnt ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="header-profile-title justify-space-between flex-row">
              <div>My Profile</div>
              <div onClick={handleClose} className="cursor-pointer">Close</div>
            </div>
            <div style={{ paddingLeft: 40, paddingRight: 40 }}>
              <Grid container justifyContent="center" className="profile-image-padding">
                {/* <Avatar sx={{ height: 100, width: 100 }} alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                <Grid item xs={12} sm={6} md={2} xl={2}>
                  <div className="">
                    <div className="imgPreview">{profileData?.customerData?.fileData !== null ? imagePreview : <PersonIcon style={{ color: "grey", marginLeft: "10px", fontSize: "7rem", marginTop: "1rem" }} ></PersonIcon> }</div>
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(event) => {
                          setSelectedImage(event.target.files[0]);
                          handleImgChange(event)
                        }}
                      />
                      <Button variant="contained" component="span" className="bg-blue" size="small" sx={{ fontSize: 13, textTransform: "capitalize", width: 138 }}>
                        <DriveFolderUploadIcon /> &nbsp; Start Upload
                      </Button>

                    </label>
                    {profileData?.customerData?.fileData &&
                      <Button variant="contained" component="span" className="bg-blue deleteProf" size="small"
                        onClick={() =>
                          dispatch(
                            updateBalance({
                              _id: custId,
                              profile_pic_delete: "success"
                            })
                          )
                        }
                        sx={{ fontSize: 13, textTransform: "capitalize", width: 138 }}>
                        Delete Pic
                      </Button>
                    }
                  </div>
                </Grid>
              </Grid>
              <Grid container rowSpacing={6} columnSpacing={4} columns={{ xs: 4, sm: 8, md: 12 }}>
                {InputWithLabel("First Name", errors.firstName?.message, <TextField {...register("firstName")} fullWidth placeholder="Enter First Name" size="small" id="outlined-basic" />)}
                {InputWithLabel("Mail", errors.Mail?.message, <TextField {...register("Mail")} fullWidth placeholder="Enter Mail" size="small" id="outlined-basic" />)}
                {InputWithLabel("City", errors.city?.message, <TextField {...register("city")} fullWidth placeholder="Enter City" size="small" id="outlined-basic" />)}
                {InputWithLabel("Phone", errors.phone?.message, <TextField {...register("phone")} fullWidth placeholder="Enter Phone" size="small" id="outlined-basic" />)}
                {InputWithLabel("Pincode", errors.pincode?.message, <TextField {...register("pincode")} fullWidth placeholder="Enter pincode" size="small" id="outlined-basic" />)}
                {InputWithLabel("Address", errors.area?.message, <TextField {...register("area")} fullWidth placeholder="Enter Address" size="small" id="outlined-basic" />)}
              </Grid>
              <Stack direction="row" sx={{ marginTop: 3, marginBottom: 3 }} spacing={2} className="flex-end">
                <Button type="submit" size="small" variant="outlined" className="btn-outlined" style={{ textTransform: "none", backgroundColor: "#00249b", color: "white" }}>
                  Accept
                </Button>
                <Button onClick={handleClose} size="small" variant="outlined" style={{ textTransform: "none", color: "#00249b", borderColor: "#00249b" }}>
                  Reject
                </Button>
              </Stack>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
