import Grid from "@mui/material/Grid";
import InvoiceReceiptTable from "@components/InvoiceReceiptTable";

export default function SubscriberInvoiceReceipt(props) {
  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={12} sm={  12} md={12}>
        <InvoiceReceiptTable customerData={props?.customerData}/>
      </Grid>
     
    </Grid>
  );
}
