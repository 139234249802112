import React, { useState } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import { tableRows } from "./data";
import "./style.scss";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useComponentDidMount, useComponentDidUpdate } from "@utils/useEffectHooks";
import TableView from "@components/TableView";
import { COLORS } from "@constants";
import { useNavigate } from "react-router-dom";

const validation = Yup.object({
  name: Yup.string().required("Required").nullable(),
  employeeId: Yup.string().required("Required").nullable(),
  mobile: Yup.string().required("Required")
    .max(10, 'Must be exactly 10 digits').matches(new RegExp('[0-9]{10}'), "Phone number is not valid"),
  email: Yup.string()
    .required("Required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    ),
});

const subAdminData = [
  { id: 1, menu: "Dashboard", menuValue: "dashboard", checked: true },
  { id: 2, menu: "Employee", menuValue: "employee", checked: false },
  { id: 3, menu: "CRM", menuValue: "crm", checked: false },
  { id: 4, menu: "Ticketing", menuValue: "ticketing", checked: false },
  { id: 5, menu: "Enquiry", menuValue: "enquiry", checked: false },
  { id: 6, menu: "Expenses", menuValue: "expenses", checked: false },
  { id: 7, menu: "Blling", menuValue: "billing", checked: false },
  { id: 8, menu: "Inventory", menuValue: "inventory", checked: false },
  { id: 9, menu: "Settings", menuValue: "settings", checked: false },
];

export default function AddEmployeeForm(props) {
  const [accessType, setAccessType] = useState("admin");
  const [subadminlist, setsubadminlist] = useState(subAdminData)
  const [permissionlist, setpermission] = useState({})
  const [checkedVal, setChecked] = useState(false);
  const {
    values,
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });

  const custplan = [
    { id: 1, label: "Default", value: true },
    { id: 2, label: "Only Assigned Customer", value: false },
  ];

  const discountAccess = [
    { id: 1, label: "Enable", value: "enable" },
    { id: 2, label: "Disable", value: "disable" },
  ];

  const navigate = useNavigate();

  const handleRedirect = () => {

    let userType = JSON.parse(localStorage.getItem("user"));
    let roleType = userType?.role
    if (roleType === "admin") {
      navigate(`/admin/employee-list`);
    } else if (roleType === "distributor") {
      navigate(`/distributor/employee-list`);
    } else if (roleType === "support"){
      navigate(`/distributor/employee-list`);
    }
  };


  const handleCheckBox = (index, checked) => {
    const clonedDataService = [...subadminlist];
    if (index === 0 || index > 0) {
      clonedDataService[index].checked = clonedDataService[index].checked
        ? false
        : true;
    } else if (index === "all") {
      for (let services of clonedDataService) {
        if (checked) {
          services.checked = true;
        } else {
          services.checked = false;
        }
      }
    }
    setChecked(checked)
    setsubadminlist(clonedDataService);
  }

  const filterObject = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options.value.toString() === value.toString()
      );
    setValue(title, filtered[0]);
    console.log(title, filtered[0], "filteru")
  };

  console.log(props?.data, "datssss")
  useComponentDidMount(() => {
    setValue("name", props?.data?.name);
    setValue("employeeId", props?.data?.emp_id);
    setValue("mobile", props?.data?.mobile_no);
    setValue("email", props?.data?.email_id);
    filterObject("discountAccess", discountAccess, props?.data?.discount_access);
    filterObject("custAccess", custplan, props?.data?.cust_access);
    filterObject("serviceDiscount",discountAccess, props?.data?.discount_access )

    if (props?.data?.role == "distributor_access") {
      setAccessType("admin")
    }
    if (props?.data?.role == "payments") {
      setAccessType("collectionagent")
    }
    if (props?.data?.role == "support") {
      setAccessType("SubAdmin")
    }
    if (props?.data?.role == "services") {
      setAccessType("services")
    }
  }, [props]);



  const onSubmit = (data) => {
    let filtered = subadminlist.filter((options) => options.checked === true);
    var object = filtered.reduce(
      (obj, item) => Object.assign(obj, { [item.menuValue]: true }), {});
    props?.handleFormValues(data, accessType, object)
    console.log(data, "datasssyyy")
  }

  return (
    <div className="addTicket-form-cnt paddingemp">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className=" ">
          <Grid item xs={12} sm={12} md={12} spacing={4}>
          </Grid>
          <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="formCreatePlan">
            <Grid item xs={6}>
              <div className="addticket-form-title">{"Name"}</div>
              <TextField
                {...register("name")}
                id="outlined-basic"
                className="txtwidth"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                placeholder="Enter Name"
              />
              <div className="field-error">{errors.name?.message}</div>
            </Grid>
            <Grid item xs={6}>
              <div className="addticket-form-title">{"Employee ID"}</div>
              <TextField
                {...register("employeeId")}
                id="outlined-basic"
                className="txtwidth"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                placeholder="Enter Employee Id"
              />
              <div className="field-error">{errors.employeeId?.message}</div>
            </Grid>
            <Grid item xs={6}>
              <div className="addticket-form-title">{"Mobile Number"}</div>
              <TextField
                {...register("mobile")}
                id="outlined-basic"
                className="txtwidth"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                placeholder="Enter Mobile Number"
              />
              <div className="field-error">{errors.mobile?.message}</div>
            </Grid>
            <Grid item xs={6}>
              <div className="addticket-form-title">{"E-Mail"}</div>
              <TextField
                {...register("email")}
                id="outlined-basic"
                className="txtwidth"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                placeholder="Enter Email"
              />
              <div className="field-error">{errors.email?.message}</div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "#00249B",
                        },
                      }}
                      onClick={() => {
                        setAccessType("admin");
                      }}
                      checked={accessType === "admin" ? true : false}
                    />
                  }
                  label="Admin"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6} sm={3}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "#00249B",
                        },
                      }}
                      onClick={() => {
                        setAccessType("collectionagent");
                      }}
                      checked={accessType === "collectionagent" ? true : false}
                    />
                  }
                  label="Collection Agent"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6} sm={3}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "#00249B",
                        },
                      }}
                      onClick={() => {
                        setAccessType("SubAdmin");
                      }}
                      checked={accessType === "SubAdmin" ? true : false}
                    />
                  }
                  label="Sub Admin"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6} sm={3}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "#00249B",
                        },
                      }}
                      onClick={() => {
                        setAccessType("services");
                      }}
                      checked={accessType === "services" ? true : false}
                    />
                  }
                  label="Service Agent"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </div>
        {accessType === "collectionagent" && (
          <div>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="formCreatePlan ">
              <Grid item xs={6}>
                <label htmlFor="lastName" className="createForm">
                  Customer Access
                </label>
                <Controller
                  control={control}
                  name="custAccess"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value || ""}
                      options={custplan}
                      isOptionEqualToValue={(option) => option.label == value?.label || ""}
                      getOptionLabel={(item) => (item.label ? item.label : "")}
                      getOptionSelected={(option, value) => value === undefined || value === "" || option.id === value.id}
                      renderInput={(params) => <TextField {...params} size="small" placeholder="Only assigned customer" />}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <label htmlFor="lastName" className="createForm">
                  Discount Access
                </label>
                <Controller
                  control={control}
                  name="discountAccess"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value || ""}
                      options={discountAccess}
                      isOptionEqualToValue={(option) => option.label == value?.label || ""}
                      getOptionLabel={(item) => (item.label ? item.label : "")}
                      getOptionSelected={(option, value) => value === undefined || value === "" || option.id === value.id}
                      renderInput={(params) => <TextField {...params} size="small" placeholder="Discount Access" />}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {accessType === "services" && (
          <div>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="formCreatePlan ">
              <Grid item xs={4}>
                <label htmlFor="lastName" className="createForm">
                  Discount Access
                </label>
                <Controller
                  control={control}
                  name="serviceDiscount"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value || ""}
                      options={discountAccess}
                      isOptionEqualToValue={(option) => option.label == value?.label || ""}
                      getOptionLabel={(item) => (item.label ? item.label : "")}
                      getOptionSelected={(option, value) => value === undefined || value === "" || option.id === value.id}
                      renderInput={(params) => <TextField {...params} size="small" placeholder="Discount Access" />}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {accessType === "SubAdmin" && (
          <div>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="formCreatePlan ">
              <TableView
                rows={tableRows}
                data={subadminlist}
                color={COLORS.primary}
                handleCheckBoxChange={handleCheckBox}
                checkedVal={checkedVal}
              />
            </Grid>
          </div>
        )}
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="formCreatePlan ">
          <Grid item xs={12}>
            <Stack spacing={2} direction="row" className="btnright">
              <Button
                size="small"
                variant="outlined"
                style={{
                  textTransform: "capitalize",
                  borderColor: "#00249b",
                  color: "#00249b",
                }}
                onClick={handleRedirect}
              >
                Cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                style={{ textTransform: "capitalize" }}
                className="bg-blue"
                type="submit"
              >
                {props?.data?._id
                  ? "Update Employee"
                  : "Create Employee"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
      {/* <div className="copyrightsText">
        <p> © Copyrights CablePay Solutions India Pvt Ltd.2018.All rights reserved</p>
      </div> */}
    </div>
  );
}
