import "./styles.scss";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Stack from "@mui/material/Stack";

export default function SubViewCard(props) {

  return (
    <div className="sub-edit-card">
      <div className="mainTitle">Active plans</div>
      {props?.activeplan &&
        props?.activeplan?.map((row) => (
          <div className="padding-sub-edit">
            <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
              <Grid item xs={2} sm={4} md={6}>
                <List>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <FiberManualRecordIcon style={{ fill: "black" }} />
                      </ListItemIcon>
                      <ListItemText primary={row?.plan_name} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Grid>
            </Grid>

            <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
              {/* <Grid item xs={12}>
              <Stack spacing={2} direction="row" className="btnright">
                <Button type='submit' variant="contained" className="bg-blue" style={{ textTransform: "none" }}>
                  {"Add Package"}
                </Button>
              </Stack>
            </Grid> */}
            </Grid>
          </div>
        ))}
    </div>
  );
}
