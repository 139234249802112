import { createSlice } from "@reduxjs/toolkit";
import { enquiryStatus, enquiryList, addEnquiry, updateEnquiry, deleteEnquiry } from "./api";


const initialState = {
  enquirystatusData: [],
  enquiryListData: [],
  enquiryAdded: false,
  enquiryDeleted: false,

};

export const enquirySlice = createSlice({
  name: "enquiry",
  initialState,
  reducers: {
    reset: (state) => {
      state.enquiryAdded = false;
      state.enquiryDeleted = false;
      return state;
    },
  },

  extraReducers: {
    [enquiryStatus.pending]: (state) => { },
    [enquiryStatus.fulfilled]: (state, { payload }) => {
      state.enquirystatusData = payload.data.data;
    },
    [enquiryStatus.rejected]: (state, { payload }) => { },

    [enquiryList.pending]: (state) => { },
    [enquiryList.fulfilled]: (state, { payload }) => {
      state.enquiryListData = payload.data.data;
    },
    [enquiryList.rejected]: (state, { payload }) => { },

    [addEnquiry.pending]: (state) => { },
    [addEnquiry.fulfilled]: (state, { payload }) => {
      state.enquiryAdded = payload.data.status;
    },
    [addEnquiry.rejected]: (state, { payload }) => { },

    [updateEnquiry.pending]: (state) => { },
    [updateEnquiry.fulfilled]: (state, { payload }) => {
      state.enquiryAdded = payload.data.status;
    },
    [updateEnquiry.rejected]: (state, { payload }) => { },

    [deleteEnquiry.pending]: (state) => {},
    [deleteEnquiry.fulfilled]: (state, { payload }) => {
      state.enquiryDeleted = payload.data.status;
    },
    [deleteEnquiry.rejected]: (state, { payload }) => {},
  }



});
export const { reset } = enquirySlice.actions;

export default enquirySlice.reducer;