import { Grid } from "@mui/material";
import "./styles.scss";
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import logo from "@assets/images/logo.png";
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
import ClickAwayListener from '@mui/material/ClickAwayListener';

export default function HomeHeader(props) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {


    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);




  const renderFeatureView = (icon, title, description) => {
    return (
      <Grid item xs={2} sm={4} md={3}>
        <div className="flex-justify-center">
          <div className="circle-features">{icon}</div>
        </div>
        <div className="features-sub-title">{title}</div>
        <div className="features-sub-description">{description}</div>
      </Grid>
    );
  };

  const handleAdminClick = () => {
    handleClose();
    navigate(`/home/admin-home`);
  }

  const handleDistClick = () => {
    handleClose();
    navigate(`/home/distributor-home`);
  }

  const handleCustomerClick = () => {
    handleClose();
    navigate(`/home/customer-home`);
  }

  return (
    <>
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={{ background: "white" }}>
            <Toolbar>

              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                <img alt="logo" src={logo}></img>
              </Typography>
              <Button style={{ color: "black" }} onClick={() => navigate("/")}>Home</Button>
              <Stack direction="row" spacing={2}>

                <div>
                  <Button
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    style={{ color: "black" }}
                  >
                    Our Web Apps
                  </Button>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem onClick={handleAdminClick}>Admin App</MenuItem>
                              <MenuItem onClick={handleDistClick}>Distributor App</MenuItem>
                              <MenuItem onClick={handleCustomerClick}>Customer App</MenuItem>
                              <MenuItem>Collection Agent</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </Stack>
              <Button style={{ color: "black" }} >
              <a href= " #footercontent" style={{ color: "black" , textDecoration: "none" }}>Contact Us</a>
              </Button>
              <Button style={{ color: "black" }} onClick={() => navigate("/login")}>Login</Button>

            </Toolbar>
          </AppBar>
        </Box>
      </div>
    </>
  );
}
