import CreateDistributor from "@components/CreateDistributor";
import { useComponentDidMount, paramsQuery, useComponentDidUpdate, useComponentWillUnmount } from "@utils/useEffectHooks";
import React from "react";
import "./style.scss";
import LanguageIcon from "@mui/icons-material/Language";
import { distributorList, addDistributor, updateDistributor } from "@reducers/api";
import { reset } from "@reducers/distributor";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import moment from "moment";
import { Grid } from "@mui/material";
import BreadCrumb from "@components/Breadcrumb";

export default function CreateDistributors() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { distributorListData, distributorAdded } = useSelector((state) => state.distributor);
  let filtered = distributorListData?.data && distributorListData?.data.filter((data) => data._id === paramsQuery("id"));

  useComponentDidMount(() => {
    let id = paramsQuery("id");
    if (id) return dispatch(distributorList(`id=${id}`));
  }, []);

  useComponentDidUpdate(() => {
    let id = paramsQuery("id");
    let message = "";
    if (distributorAdded) {
      if (id) {
        message = "Distributor Updated";
      } else {
        message = "Distributor Added";
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/admin/distributor-list`);
    }
  }, [distributorAdded]);

  useComponentWillUnmount(() => {
    dispatch(reset());
  });

  const handleFormValues = (values, valuedatefield) => {
    let id = paramsQuery("id");
   console.log(values, valuedatefield, "value")
    let payload = {
      "name": values?.distributorName,
      "mobile_no": values?.mobileNumber,
      "distributor_validity": values?.validity?.value,
      "city": values?.city,
      "address": values?.address,
      "email_id": values?.distributorEmail,
      "state": values?.state,
      "pin_code": values?.pinCode,
      "subscriber_limit_count": values?.subscriber_limit_count,
      "validity_added_date": values?.dueDate,
      "GSTN": values?.gstn,
      "PAN": values?.pan,
      "sms_access": values?.sms?.value,
      "whatsapp_access": values?.whatsapp?.value
    };

    if (id) {
      payload["_id"] = id;
      dispatch(updateDistributor(payload));
      dispatch(reset());
    } else {

      dispatch(addDistributor(payload));
    }
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/admin/${path}`);
  };

  return (
    <div className="lang">

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <div className="distributorHeading">
            <LanguageIcon />
            {paramsQuery("id") ? "Update Distributor" : "Create New Distributor"}
          </div>
        </Grid>
        <Grid item xs={12} md={4} className="flex-end" style={{marginBottom: '1rem'}}>
          <div className='breadcrumbPadding'>
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              secondaryHeading="Distributor List"
              handleSecondaryClick={() => handleBreadCrumbRedirect("distributor-list")}
              activeHeading={paramsQuery("id") ? "Edit Distributor" : "Add Distributor"}

            />
          </div>

        </Grid>
      </Grid>
      <CreateDistributor data={distributorListData && distributorListData?.data && distributorListData?.data[0]} handleFormValues={(values, valuedatefield) => handleFormValues(values, valuedatefield)} />
    </div>
  );
}
