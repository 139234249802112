import { createSlice } from "@reduxjs/toolkit";
import { loginUser, profileView, profileEdit, profileEditDist, changePassword, forgotPassword ,disprofileimageUpload,disprofileimageUploadAdmin} from "./api";
import { useNavigate } from "react-router-dom";

const initialState = {
  value: 0,
  loginSuccess: false,
  statusMessage: false,
  profileViewData: [],
  disprofileimageUploadData: false,
  disprofileimageUploadAdminData: false,
  profileAdded: false,
  changepswAdded: false,
  pswReset: false,
  statusMsg: "",

};
export const authSlice = createSlice({
  
  name: "auth",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [loginUser.pending]: (state) => {},
    [loginUser.fulfilled]: (state, { payload }) => {
      localStorage.setItem("user", JSON.stringify(payload.data.data));
      state.loginSuccess = payload.data.status;
   
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.statusMessage = true;
    },

    [forgotPassword.pending]: (state) => {},
    [forgotPassword.fulfilled]: (state, { payload }) => {
     
      state.pswReset = payload.data.status;
      state.statusMsg = payload.data.msg;
    },
    [forgotPassword.rejected]: (state, { payload }) => {},

    [profileView.pending]: (state) => {},
    [profileView.fulfilled]: (state, { payload }) => {
      state.profileViewData = payload.data.data;
    },
    [profileView.rejected]: (state, { payload }) => {},

    [profileEdit.pending]: (state) => { },
    [profileEdit.fulfilled]: (state, { payload }) => {
      state.profileAdded = payload.data.status;
    },
    [profileEdit.rejected]: (state, { payload }) => { },

    [profileEditDist.pending]: (state) => { },
    [profileEditDist.fulfilled]: (state, { payload }) => {
      state.profileAdded = payload.data.status;
    },
    [profileEditDist.rejected]: (state, { payload }) => { },

    [changePassword.pending]: (state) => { },
    [changePassword.fulfilled]: (state, { payload }) => {
      state.changepswAdded = payload.data;
    },
    [changePassword.rejected]: (state, { payload }) => { },

    [disprofileimageUpload.pending]: (state) => { },
    [disprofileimageUpload.fulfilled]: (state, { payload }) => {
      state.disprofileimageUploadData = payload.data;
    },
    [disprofileimageUpload.rejected]: (state, { payload }) => { },

    [disprofileimageUploadAdmin.pending]: (state) => { },
    [disprofileimageUploadAdmin.fulfilled]: (state, { payload }) => {
      state.disprofileimageUploadAdminData = payload.data;
    },
    [disprofileimageUploadAdmin.rejected]: (state, { payload }) => { },

  },
});

export const { reset } = authSlice.actions;

export default authSlice.reducer;
