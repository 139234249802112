import TicketStatus from "@components/TicketStatus";
import "./style.scss";
import LanguageIcon from "@mui/icons-material/Language";
import Grid from "@mui/material/Grid";
import TicketFilter from "@components/TicketFilter";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchWithButtons from "@components/SearchWithButtons";
import ComputerIcon from "@mui/icons-material/Computer";
import { useNavigate } from "react-router-dom";
import {
  useComponentDidMount,
  useComponentDidUpdate,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { enquiryStatus, enquiryList, deleteEnquiry } from "@reducers/api";
import { useState } from "react";
import { useSnackbar } from "notistack";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import { reset } from "@reducers/enquiry";
import BreadCrumb from "@components/Breadcrumb";


const ticketStatusData = [
  {
    id: 1,
    title: "Open",
    count: 4,
    icon: "",
  },
  {
    id: 2,
    title: "Resolved",
    count: 2,
    icon: "",
  },
  {
    id: 3,
    title: "Pending",
    count: 5,
    icon: "",
  },
  {
    id: 4,
    title: "In-Progress",
    count: 9,
    icon: "",
  },
  {
    id: 5,
    title: "Closed",
    count: 0,
    icon: "",
  },
];

export default function Ticketing(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enquirystatusData, enquiryListData, enquiryDeleted } = useSelector(
    (state) => state.enquiry
  );
  const [enqList, setEnqList] = useState([]);
  const [enquiryListExport, setEnquiryListExport] = useState([]);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [dateFrom, setDateFromQuery] = useState("");
  const [dateTo, setDateToQuery] = useState("");
  const [filterOne, setFirstQuery] = useState("");
  const [filtertwo, setSecondQuery] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const defaultPlanQuery = {
    serviceType: "cable",
  };

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;
    if (value?.type === "search") {
      query += `&limit=10&offset=0&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
    let query = queryGenerator();
    dispatch(enquiryStatus(defaultPlanQuery));
    dispatch(enquiryList(query));
  }, []);

  useComponentDidUpdate(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = queryGenerator();
    if (enquiryDeleted) {
      enqueueSnackbar("Enquiry Deleted !", {
        variant: "success",
      });

      dispatch(reset());
      dispatch(enquiryList(query));
      defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
      dispatch(enquiryStatus(defaultPlanQuery));
    }
  }, [enquiryDeleted]);

  const status = (color, text) => {
    let colorVal = "#7152d3"
    if (text == "inprogress") {
      colorVal = "#d352a5"
    } else if (text == "pending") {
      colorVal = "#d35352"
    } else if (text == "resolved") {
      colorVal = "#47bc47"
    } else if (text == "open") {
      colorVal = "#00249B"
    }
    return (
      <div className="status-btn-table" style={{ backgroundColor: colorVal }}>
        {text}
      </div>
    );
  };

  const ticketTypes = (type) => {
    if (type == "cable") {
      return (
        <LanguageIcon
          style={{
            backgroundColor: "#45d5d7",
            fill: "white",
            borderRadius: "12px",
            padding: "5px",
          }}
        />
      );
    }

    if (type == "both") {
      return (
        <div>
          <LanguageIcon
            style={{
              backgroundColor: "#45d5d7",
              fill: "white",
              borderRadius: "12px",
              padding: "5px",
            }}
          />
          <ComputerIcon
            style={{
              backgroundColor: "#1a7ed5",
              fill: "white",
              borderRadius: "12px",
              padding: "5px",
            }}
          />
        </div>
      );
    }
    if (type == "internet" || "Internet") {
      return (
        <ComputerIcon
          style={{
            backgroundColor: "#1a7ed5",
            fill: "white",
            borderRadius: "12px",
            padding: "5px",
          }}
        />
      );
    }
  };

  const handleRedirect = () => {
    navigate("/distributor/add-enquiry");
  };

  useComponentDidUpdate(() => {
    let list = [];
    let listForExport = [];
    if (enquiryListData?.data?.length > 0) {
      enquiryListData.data.map((data, index) => {
        list.push({
          sNo: (page - 1) * 10 + index + 1,
          customername: data?.name,
          email: data?.email_id,
          team: data?.complaint_forward_to,
          staff: data?.staff_name,
          requestid: data?.request_id,
          ticketType: ticketTypes(data?.enquiry_type),
          priority: data?.enquiry_status,
          status: status("#73ca72", data?.enquiry_status),
          createdDate: moment(data?.created_at).format("MMMM Do YYYY, h:mm a"),
          action: (
            <div className="flex-row justify-center">
              <div
                onClick={() =>
                  navigate(`/distributor/add-enquiry?id=${data._id}`)
                }
                className="cursor-pointer"
              >
                <EditIcon />
              </div>
              <div
                className="cursor-pointer"
                onClick={() =>
                  dispatch(
                    deleteEnquiry({
                      _id: data?._id,
                    })
                  )
                }
              >
                <DeleteIcon />
              </div>
            </div>
          ),
        });
        listForExport.push({
          "SI:No": (page - 1) * 10 + index + 1,
          "Request Id": data?.request_id,
          "Customer Name": data?.name,
          "Customer Email": data?.email_id,
          "Assigned Team": data?.complaint_forward_to,
          "Assigned Staff": data?.staff_name,
          "Status": data?.enquiry_status,
          "Created Date": moment(data?.updated_at).format("ll"),
        });
      });
    }

    setEnqList(list);
    setEnquiryListExport(listForExport);
  }, [enquiryListData]);

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    let query = queryGenerator({ type: "search", state: search });
    dispatch(enquiryList(query));
  };

  const handleChange = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(enquiryList(`distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=${value - 1}0${searchQuery ? `&value=${searchQuery}` : ""}${dateFrom? `&startDate=${moment(dateFrom).format("YYYY-MM-DDTHH:mm:ss")}`: ""}${ dateTo ? `&endDate=${moment(dateTo).format("YYYY-MM-DDTHH:mm:ss")}` : ""}${filterOne? `&enquiry_type=${filterOne}`: ""}${filtertwo? `&enquiry_status=${filtertwo}`: ""}`));


  };

  const statusdrop = [
    { label: "open", year: 1994 },
    { label: "pending", year: 1972 },
    { label: "inprogress", year: 1972 },
    { label: "resolved", year: 1972 },
    { label: "closed", year: 1994 },
  ];

  const priority = [
    { label: "internet", year: 1994 },
    { label: "cable", year: 1972 },
    { label: "both", year: 1994 },
  ];

  const handleClickSearcch = (value, value1, priorityval, statusval) => {
    let user = JSON.parse(localStorage.getItem("user"));
    setPage(1);
    let query = `distributor_ref_id=${user?.distributor_ref_id}`;
    if (value) {
      query += `&startDate=${moment(value).format("YYYY-MM-DDTHH:mm:ss")}`;
    }
    if (value1) {
      query += `&endDate=${moment(value1).format("YYYY-MM-DDTHH:mm:ss")}`;
    }
    if (priorityval) {
      query += `&enquiry_type=${priorityval?.label}`;
    }
    if (statusval) {
      query += `&enquiry_status=${statusval?.label}`;
    }
    dispatch(enquiryList(`limit=10&offset=0&${query}&value=`));
    setDateFromQuery(value);
    setDateToQuery(value1);
    setFirstQuery(priorityval?.label);
    setSecondQuery(statusval?.label);
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  const handleReset = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
    let query = queryGenerator();
    dispatch(enquiryStatus(defaultPlanQuery));
    dispatch(enquiryList(query));
    setDateFromQuery("");
    setDateToQuery("");
    setFirstQuery("");
    setSecondQuery("");
    setPage(1);
  };

  return (
    <div>
      <div className="enquiryList-container">

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <div className="planHeading">Enquiry</div>
          </Grid>
          <Grid item xs={12} md={4} className="flex-end">
            <div className="employeelist-title">
              <BreadCrumb
                defaultHeading="Home"
                handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}

                activeHeading="Enquiry List"
                handleActiveClick={() => handleBreadCrumbRedirect("enquiry-list")}
              />
            </div>

          </Grid>
        </Grid>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <TicketStatus
                className=" ticketCardBlue"
                title={ticketStatusData[0].title}
                count={
                  enquirystatusData.openEnquiryCount
                    ? enquirystatusData?.openEnquiryCount
                    : 0
                }
                icon={ticketStatusData[0].icon}
              />
               </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <TicketStatus
                  className=" ticketCardRed"
                  title={ticketStatusData[2].title}
                  count={
                    enquirystatusData.pendingEnquiryCount
                      ? enquirystatusData?.pendingEnquiryCount
                      : 0
                  }
                  icon={ticketStatusData[2].icon}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <TicketStatus
                className=" ticketCardPink"
                title={ticketStatusData[3].title}
                count={
                  enquirystatusData.inprogressEquiryCount
                    ? enquirystatusData?.inprogressEquiryCount
                    : 0
                }
                icon={ticketStatusData[3].icon}
              />
            </Grid>
           
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <TicketStatus
                className=" ticketCardGreen"
                title={ticketStatusData[1].title}
                count={
                  enquirystatusData.resolvedEnquiryCount
                    ? enquirystatusData?.resolvedEnquiryCount
                    : 0
                }
                icon={ticketStatusData[1].icon}
              />
            </Grid>


            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <TicketStatus
                className=" ticketCardPurple"
                title={ticketStatusData[4].title}
                count={
                  enquirystatusData.closedenquiryCount
                    ? enquirystatusData?.closedenquiryCount
                    : 0
                }
                icon={ticketStatusData[4].icon}
              />
            </Grid>
          </Grid>
        </div>
        <div className="ticket-list-table-cntr">
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={4} md={3}>
              <div className="planHeading">Enquiry List</div>
            </Grid>
            <Grid item xs={0} sm={4} md={6}></Grid>
            <Grid item xs={4} sm={4} md={3} className="flex-end">
              <div className="">
                <TicketFilter
                  labelPrimary={"Enquiry Type"}
                  PrimaryOption={priority}
                  SecondaryOption={statusdrop}
                  labelSecondary={"Status"}
                  handleFormValues={(data1, data2, data3, data4) =>
                    handleClickSearcch(data1, data2, data3, data4)
                  }
                  handleResetClick = {handleReset}
                />
              </div>
            </Grid>
          </Grid>
          <div className="search-btns-padding">
            <SearchWithButtons
              primaryButton={{
                title: "Add Enquiry",
                type: "contained",
                color: "white",
                bgColor: COLORS.primary,
              }}
              secondaryButton={{
                title: "Export",
                type: "outlined",
                color: COLORS.primary,
                filename: "Enquiry list",
                data: enquiryListExport,
              }}
              handlePrimaryClick={handleRedirect}
              // handleSecondaryClick={}
              handleChange={handleSearch}
            />
          </div>
          <div style={{ fontWeight: 'bold', fontSize: '15px', paddingBottom: '10px' }}>Showing entries {(page - 1) * 10 + 1} - {Math.ceil(enquiryListData?.totalCount / 10) == page ? enquiryListData?.totalCount : page + "0"} of {enquiryListData?.totalCount}</div>
          <TableView rows={tableRows} data={enqList} color={COLORS.primary} />
          <div
            style={{
              marginTop: 20,
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Pagination
              count={Math.ceil(enquiryListData?.totalCount / 10) || 0}
              defaultPage={1}
              page={page}
              onChange={(event, value) => {
                setPage(value);
                handleChange(value);
              }}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
