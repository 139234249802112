import "./styles.scss";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useComponentDidUpdate } from "@utils/useEffectHooks";
import * as Yup from "yup";
import { useState } from "react";

const validation = Yup.object({
  //canId: Yup.string().nullable().required("Required"),
  //mso: Yup.string().nullable().required("Required"),
  package: Yup.object().nullable().required("Required"),
  //packageCost: Yup.string().nullable().required("Required"),
  //addStb: Yup.object().nullable().required("Required"),
  addStb: Yup.object().nullable().required("Required"),
  date: Yup.string().nullable().required("Required"),
});

export default function AddonForm(props) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });
  const [value, setValueDate] = useState(new Date());

  const onSubmit = (data) => props?.handleFormValues(data);

  useComponentDidUpdate(() => {
    if (props?.resetForm) return reset();
  }, [props?.resetForm]);

  const handleChange = (newValue) => {
    setValueDate(newValue);
    setValue("date", newValue);
  };

  setValue("canId", props.customerData?.can_id);
  setValue("date", value);

  const layoutInput = (title, error, input) => {
    return (
      <Grid item xs={4} sm={4} md={6}>
        <div className="sub-addontv-form-title">{title}</div>
        {input}
        <div className="field-error">{error}</div>
      </Grid>
    );
  };

  const handleSetValue = (item) => {
    setValue("mso", item?.mso);
    setValue("packageCost", item?.plan_amount);
  };

  return (
    <div className="sub-addontv-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 10 }} columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles">
          {layoutInput("CAN ID", errors.canId?.message, <TextField {...register("canId")} disabled="true" id="outlined-basic" placeholder="Enter CAN ID" type="text" size="small" fullWidth />)}
          {layoutInput("MSO", errors.mso?.message, <TextField {...register("mso")} disabled="true" id="outlined-basic" placeholder="Enter MSO" type="text" size="small" fullWidth />)}
          {layoutInput(
            "Package",
            errors.package?.message,
            <Controller
              control={control}
              name="package"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                    handleSetValue(item);
                  }}
                  value={value || ""}
                  options={props?.planListData || []}
                  isOptionEqualToValue={(option, value) => option.plan_name === value.plan_name}
                  getOptionLabel={(item) => (item.plan_name ? item.plan_name : "")}
                  renderInput={(params) => <TextField {...params} size="small" placeholder="Select Package Name" />}
                />
              )}
            />
          )}
          {layoutInput("Package Cost", errors.packageCost?.message, <TextField {...register("packageCost")} disabled="true" id="outlined-basic" placeholder="Enter Package Cost" type="text" size="small" fullWidth />)}
          {/* {layoutInput("Add STB", errors.addStb?.message, <TextField {...register("addStb")} id="outlined-basic" placeholder="Enter STB" type="text" size="small" fullWidth />)} */}
          {layoutInput(
            "Add STB",
            errors.addStb?.message,
            <Controller
                control={control}
                name="addStb"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                     
                    }}
                    value={value || ""}
                    options={props?.stbListData || []}
                    isOptionEqualToValue={(option, value) =>
                      option.stb_no === value.stb_no
                    }
                    getOptionLabel={(item) =>
                      item.stb_no ? `${item.stb_no} (${item.inventory_type}) (${item.price}) (${item.stb_payment_category})` : ""
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Enter STB No"
                      />
                    )}
                  />
                )}
              />
          )}
          {layoutInput(
            "Expiry Date",
            errors.date?.message,
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker inputFormat="MM/dd/yyyy" value={value} onChange={handleChange} renderInput={(params) => <TextField fullWidth size="small" {...register("date")} placeholder="Enter Expiry Date" {...params} />} />
            </LocalizationProvider>
          )}
        </Grid>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} sx={{ marginTop: 4 }} className="flex-end">
          <Stack spacing={2} direction="row">
            <Button sx={{ textTransform: "none" }} type="submit" size="small" variant="contained" className="bg-blue">
              Submit
            </Button>
          </Stack>
        </Grid>
      </form>
    </div>
  );
}
