const tableRows = [
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },

  {
    title: "Plan Name",
    key: "planName",
    align: "left",
  },
  {
    title: "Plan Amount",
    key: "planAmount",
    align: "left",
  },
  {
    title: "Service Type",
    key: "serviceType",
    align: "left",
  },
  {
    title: "SD/HD",
    key: "sdhd",
    align: "left",
  },
  {
    title: "Validity",
    key: "validity",
    align: "left",
  },
  {
    title: "Billing Type",
    key: "billingType",
    align: "left",
    
  },
  {
    title: "Category",
    key: "category",
    align: "left",
  },
  {
    title: "Action",
    key: "action",
    align: "left",
  },
];

export { tableRows };
