import SearchWithButtons from "@components/SearchWithButtons";

export default function generateBill() {
  return (
    <div className="generateBill-container">
      <div className="genrateBill-title">Generate Bill</div>
      <div className="search-btns-padding">
        <SearchWithButtons
          checkbox="true"
          firstLabel="Post Paid"
          secondLabel="Pre Paid"
        />
      </div>
      <div className="copyrightsText">
        <p>
          © Copyrights CablePay Solutions India Pvt Ltd.2018.All rights reserved
        </p>
      </div>
    </div>
  );
}
