import React from "react";
import "./style.scss";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { resetPwd } from "@reducers/api";
import { useComponentDidMount, paramsQuery, useComponentDidUpdate, useComponentWillUnmount } from "@utils/useEffectHooks";
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from "react-router-dom";


const validation = Yup.object().shape({
  newPassword: Yup.string()
    .required("Required")
    .min(8, "Password must contain atleast 8 characters")
    .matches(/[a-z]/, "Atleast one lowercase character")
    .matches(/[A-Z]/, "Atleast one uppercase character")
    .matches(/[0-9]/, "Atleast one number"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Both password need to be the same')
});

export default function ResetPasswordForm() {
  const {
    values,
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });
  
  const onSubmit = (data) => handleFormValues(data);
  const { enqueueSnackbar } = useSnackbar();
  const { resetPwdData } = useSelector((state) => state.subscriber);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleFormValues = (values) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let subscriber_id = user?.subscriber_id;
    let id = paramsQuery("id");
    let payload = {
      "newPassword": values.newPassword,
      "confirmPassword": values.confirmPassword,
      "_id": id,
      "sms_access": user?.sms_access,
      "whatsapp_access": user?.whatsapp_access,      
    };
    dispatch(resetPwd(payload));
  };



  useComponentDidUpdate(() => {
    let id = paramsQuery("id");
    let message = "";
    if (resetPwdData) {
      message = "Reset Password Successfully";
      enqueueSnackbar(message, {
        variant: "success",
      });
      setValue("newPassword", "");
      setValue("confirmPassword", "");
      window.location.reload();
    }
  }, [resetPwdData]);



  const [newPassword, setNewValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const [confirmPsw, setConfirmValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });



  const handleNewChange = (prop) => (event) => {
    setNewValues({ ...newPassword, [prop]: event.target.value });
  };

  const handleConfimChange = (prop) => (event) => {
    setConfirmValues({ ...confirmPsw, [prop]: event.target.value });
  };

  const handleClickNewShowPassword = () => {
    setNewValues({
      ...newPassword,
      showPassword: !newPassword.showPassword,
    });
  };

  const handleClickConfirmShowPassword = () => {
    setConfirmValues({
      ...confirmPsw,
      showPassword: !confirmPsw.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="reset-pass-sub-cnt">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center" alignItems="center">
          <Grid item xs={4} sm={4} md={5.5} className="reset-sub-form">
            <div className="reset-sub-header">Reset Password</div>
            <div className="bdy-padding">
              <div>
                <div className="bdy-title">Enter Password</div>

                {/* <TextField {...register("newPassword")} size="small" className="txtwidth" type="password" variant="outlined" id="outlined-basic"   placeholder="Enter your password"/> */}

                <FormControl variant="outlined" className="txtwidth">
                  {/* <InputLabel className="labelText" htmlFor="outlined-adornment-password">New Password</InputLabel> */}
                  <TextField
                    id="outlined-adornment-password"
                    {...register("newPassword")}
                    size="small"
                    type={newPassword.showPassword ? 'text' : 'password'}
                    value={newPassword.password}
                    onChange={handleNewChange('password')}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickNewShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {newPassword.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>,
                    }}
                   
                    placeholder="Enter Password"
                  />
                </FormControl>

                <div className="field-error">{errors.newPassword?.message}</div>
              </div>
              <div>
                <div className="bdy-title">Confirm Password</div>

                {/* <TextField {...register("confirmPassword")} size="small" className="txtwidth" type="password" variant="outlined" id="outlined-basic" placeholder="Confirm your password"/> */}
                <FormControl variant="outlined" className="txtwidth">
                  {/* <InputLabel htmlFor="outlined-adornment-password" className="labelText" InputLabelProps={{ shrink: false }} >Confirm Password</InputLabel> */}
                  <OutlinedInput
                    id="outlined-adornment-password"
                    {...register("confirmPassword")}
                    size="small"
                    type={confirmPsw.showPassword ? 'text' : 'password'}
                    value={confirmPsw.password}
                    onChange={handleConfimChange('password')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickConfirmShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {confirmPsw.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Enter Confirm Password"
                  />
                </FormControl>

                <div className="field-error">{errors.confirmPassword?.message}</div>
              </div>
              <div className="flex-end btn-padding">
                <Button type="submit" variant="contained" size="small" sx={{ backgroundColor: "#00249b" }}>
                  Save
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
