import { useState } from "react";
import * as React from "react";
import "./style.scss";
import { Grid } from "@mui/material";

import TableView from "@components/TableView";
import { InvoiceReceiptRows } from "./data";
import { COLORS } from "@constants";
import { useSnackbar } from "notistack";
import {
  useComponentDidMount,
  useComponentDidUpdate,
  paramsQuery,
  useComponentWillUnmount,
} from "@utils/useEffectHooks";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from "react-router-dom";
import { paymentHistoryList, invoicePdf,invoiceDownloadExport } from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { reset } from "@reducers/plan";
import jsPDF from "jspdf";
import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import "jspdf-autotable";
import Paymenthistory from "@components/Modals/paymentHistory";

export default function InvoiceReceiptTable(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { invoicePdfData ,invoiceDownloadExportData} = useSelector((state) => state.subscriber);
  const {  paymentHistoryListData  } = useSelector((state) => state.customer);

  const [receiptList, setReceiptList] = useState([]);
  const [ListExport, setListExport] = useState([]);
  const [openPaymentHistory, setOpenPaymentHistory] = React.useState(false);
  const [paymentHistory, setpaymentHistory] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [paymentViewData, setPaymentHistoryViewData] = React.useState("");
  const defaultPlanQuery = {
    serviceType: "cable",
    billingType: "",
  };

  const onShowPopUp = (data) => {
    
    setShow(!show);
    setPaymentHistoryViewData(data)
  };



  const onShowHistory = () => {
    setOpenPaymentHistory(!openPaymentHistory);
  };

  const handleViewPaymentHistory = () => {
    setpaymentHistory(false);
  };

  useComponentWillUnmount(() => {
    dispatch(reset());
  });
  
  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `cust_ref_id=${paramsQuery(
      "id"
    )}`
    defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
    dispatch(
      paymentHistoryList(
        `limit=10&offset=0&cust_ref_id=${paramsQuery(
          "id"
        )}&distributor_ref_id=${user?.distributor_ref_id}`
      )
    );
    dispatch(invoicePdf(query));
  }, []);

  useComponentDidUpdate(() => {
    
    if (invoiceDownloadExportData) {
      if(invoiceDownloadExportData.data)
      {
        var a = document.createElement("a");
        a.setAttribute("download", "invoice");
        a.setAttribute("href", `data:application/PDF;base64,${invoiceDownloadExportData.data}`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      if(invoiceDownloadExportData?.msg=="no data found"){
        enqueueSnackbar("No data....!", {
          variant: "error",
        });
      }
      dispatch(reset())
    }
  }, [invoiceDownloadExportData]);

  useComponentDidUpdate(() => {
    let list = [];
    let listForExport = [];
    paymentHistoryListData?.map((data, index) => {
      list.push({
        transaction_id: data?.transaction_id,
        bill_amount: data?.bill_amount,
        advance_amount: data?.advance_amount,
        payment_received_by: data?.payment_received_by,
        ticket_status: data?.ticket_status,
        collection_amount: data?.collection_amount,
        collected_date: moment(data?.collected_date).format("ll"),
        discount_amount: data?.discount_amount,
        due_amount: data?.due_amount,
        payment_status: data?.payment_status,
        remarks: data?.remark,
        payment_date: moment(data?.updated_at).format("ll"),
        created_date: moment(data?.updated_at).format("ll"),
        action: (
          <div className="flex-row justify-center">
           
            <div
              className="cursor-pointer"
              onClick={() => onShowPopUp(data)}
              
            >
              <DownloadIcon />
            </div>
            
          </div>
        ),
      });
      listForExport.push({
        transaction_id: data?.transaction_id,
        bill_amount: data?.bill_amount,
        advance_amount: data?.advance_amount,
        payment_received_by: data?.payment_received_by,
        ticket_status: data?.ticket_status,
        collection_amount: data?.collection_amount,
        collected_date: moment(data?.collected_date).format("ll"),
        discount_amount: data?.discount_amount,
        due_amount: data?.due_amount,
        payment_status: data?.payment_status,
        remarks: data?.remark,
        payment_date: moment(data?.updated_at).format("ll"),
        created_date: moment(data?.updated_at).format("ll"),
      });
    });

    setReceiptList(list);
    setListExport(listForExport);
  }, [paymentHistoryListData]);

  const printDocument = () => {
    const unit = "pt";
    const size = "A2"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Invoice/Receipt";
    const headers = [
      [
        "Transaction ID",
        "Bill Amount",
        "Received By",
        "Collection Amount",
        "Discount",
        "Due Amount",
        "Advance Amount",
        "Payment Status",
        "Generated Date",
        "Payment Date",
        "Collection Date",
        "Remarks",
      ],
    ];
    const data = ListExport.map((elt) => [
      elt.transaction_id,
      elt.bill_amount,
      elt.payment_received_by,
      elt.collection_amount,
      elt.discount_amount,
      elt.due_amount,
      elt.advance_amount,
      elt.payment_status,
      elt.created_date,
      elt.payment_date,
      elt.collected_date,
      elt.remarks,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("invoiceReceipt.pdf");
  };



  return (
    <div className="sub-addontv-form">
      <Paymenthistory data={paymentViewData} show={show} handleClose={onShowPopUp} />
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12 }}
        className="padding-btw-tiles"
      >
        <div className="printDownload">
          <Button
            onClick={printDocument}
            className="bg-blue DownloadBtn downloadPdf"
            size="small"
            variant="contained"
          >
            Pdf Download
          </Button>
          <Button
            className="bg-blue DownloadBtn downloadPdf"
            size="small"
            variant="contained"
          >
            
            <a className='detailstatement' download="pdfTitle" href={`data:text/html;base64,${invoicePdfData}`} title='Download pdf document' > Detail Statement </a>
          </Button>
          <Button variant="outlined" size="small" className="borderColor">
            {" "}
            <CSVLink data={ListExport} className="colorWhite">
              Export
            </CSVLink>
          </Button>
         

        </div>

        <div  >
          <TableView
            rows={InvoiceReceiptRows}
            data={receiptList}
            color={COLORS.primary}
          />
        </div>
      </Grid>
    </div>
  );
}
