import React, { useState } from "react";
import "./styles.scss";
import AddSubscriberPlanForm from "@components/AddSubscriberPlanForm";
import { subscriberAddPlan, channelList, subsPlanUpdate } from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  useComponentDidMount,
  paramsQuery,
  useComponentDidUpdate,
  useComponentWillUnmount,
} from "@utils/useEffectHooks";
import BreadCrumb from "@components/Breadcrumb";
import Grid from "@mui/material/Grid";
import { reset } from "@reducers/subscriber";

export default function AddSubscriberPlan() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { subPlanAdded, channelListData, subscriberPlanListData } = useSelector(
    (state) => state.subscriber
  );
  const [BroadCastPacks, setBroadCastPacks] = useState([]);
  let filtered =
    subscriberPlanListData &&
    subscriberPlanListData?.data?.filter(
      (data) => data._id === paramsQuery("id")
    );

  const broadCasterBoquet = [
    { id: 1, label: "Discovery", value: "discovery" },
    { id: 2, label: "Disney", value: "disney" },
    { id: 3, label: "ETPL", value: "etpl" },
    { id: 4, label: "JAYA", value: "jaya" },
    { id: 5, label: "SUN", value: "sun" },
    { id: 6, label: "ZEE", value: "zee" },
  ];

  useComponentWillUnmount(() => {
    dispatch(reset());
  });

  useComponentDidUpdate(() => {
    let message = "";
    if (subPlanAdded.msg === "New plan created successfully") {
      message = "Plan Added";

      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/distributor/subscriber-plan-list`);
    }

    if (subPlanAdded.msg === "The Plan Name Already Exists") {
      message = "The Plan Name Already Exists";

      enqueueSnackbar(message, {
        variant: "error",
      });
    }

    if (subPlanAdded.msg === "Plan updated successfully") {
      message = "Plan Updated";

      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/distributor/subscriber-plan-list`);
    }
    dispatch(reset());
  }, [subPlanAdded]);

  useComponentDidUpdate(() => {
    let filteredArray = [];
    for (let options of broadCasterBoquet) {
      let filtered = channelListData.filter(function (word) {
        return word?.plan_name
          .toLowerCase()
          ?.includes(options.label.toLowerCase());
      });
      if (filtered.length > 0) {
        filteredArray.push({
          packName: options.label,
          selected: false,
          data: filtered,
        });
      }
    }
    setBroadCastPacks(filteredArray);
  }, [channelListData]);

  useComponentDidMount(() => {
    dispatch(channelList());
  }, []);

  const handleFormValues = (values, channels) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let id = user?.distributor_ref_id;
    let planId = paramsQuery("id");
    let payload = {
      package_category: "package",
      service_type: values?.serviceType?.value,
      billing_type: values?.billingType?.value,
      plan_validity: values?.packageValidity?.value,
      mso: "Hathway",
      plan_amount: values?.planAmount,
      disabled: values?.disablePlan?.value,
      plan_name: values.planName,
      distributor_ref_id: id,
      channels: channels,
      channel_count: channels.length,
      speed: values?.speed,
      data: values?.data,
      isp: values?.isp?.value,
    };

    if (planId) {
      payload["_id"] = planId;
      dispatch(subsPlanUpdate(payload));
    } else {
      dispatch(subscriberAddPlan(payload));
    }
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  return (
    <div className="">
      <Grid container spacing={2} className="breadcrumbPlanContainer">
        <Grid item xs={8}></Grid>
        <Grid item xs={12} md={4} className="flex-end">
          <div className="">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              secondaryHeading="Plan List"
              handleSecondaryClick={() =>
                handleBreadCrumbRedirect("subscriber-plan-list")
              }
              activeHeading={
                paramsQuery("id")
                  ? "Edit Subscriber Plan"
                  : "Add Subscriber Plan"
              }
            />
          </div>
        </Grid>
      </Grid>

      <div className="sub-add-plan-container">
        <AddSubscriberPlanForm
          data={filtered && filtered[0]}
          handleFormValues={(values, channels) =>
            handleFormValues(values, channels)
          }
          channelList={channelListData}
          broadCast={BroadCastPacks}
        />
      </div>
    </div>
  );
}
