const tableRows = [
    {
      title: "S.No",
      key: "sNo",
      align: "left",
    },
    {
      title: "Request Id",
      key: "requestid",
      align: "left",
    },
    {
      title: "Customer Name",
      key: "customername",
      align: "left",
    },
    {
      title: "Customer Email",
      key: "email",
      align: "left",
    },
    {
      title: "Assigned Team",
      key: "team",
      align: "left",
    },
    {
      title: "Assigned Staff",
      key: "staff",
      align: "left",
    },
    {
      title: "Enquiry Type",
      key: "ticketType",
      align: "left",
    },
    {
      title: "Status",
      key: "status",
      align: "left",
      justify: "center",
    },
    {
      title: "Created Date",
      key: "createdDate",
      align: "left",
    },
    {
      title: "Action",
      key: "action",
      align: "left",
    },
  ];
  
  export { tableRows };
  