import "./styles.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import SearchWithButtons from "@components/SearchWithButtons";
import { useNavigate } from "react-router-dom";
import { useComponentDidMount, useComponentDidUpdate, useComponentWillUnmount } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { SubscriberListAssigned, allEmpList,SubscriberListMultipleDelete} from "@reducers/api";
import { useState } from "react";
import Pagination from "@mui/material/Pagination";
import TicketFilter from "@components/TicketFilter";
import { useSnackbar } from "notistack";
import moment from "moment";
import { reset } from "@reducers/subscriber";
import Grid from "@mui/material/Grid";
import BreadCrumb from "@components/Breadcrumb";

const serviceType = [
  { label: "Cable", value: "cable" },
  { label: "Internet", value: "internet" },
];

const billingTypes = [
  { label: "Pre Paid", value: "prepaid" },
  { label: "Post Paid", value: "postpaid" },

];


export default function EmployeeList(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { SubscriberListAssignedData, deleteRes,assignSubscriberSubmitData,SubscriberListMultipleDeleteData } = useSelector((state) => state.subscriber);
  const [expList, setexpList] = useState([]);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const { empFullData } = useSelector((state) => state.employee);
  const [dateFrom, setDateFromQuery] = useState("");
  const [dateTo, setDateToQuery] = useState("");
  const [filterOne, setFirstQuery] = useState("");
  const [filtertwo, setSecondQuery] = useState("");
  const [checkedVal, setChecked] = useState(false);

  const queryGenerator = (value) => {
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&pay_now=true`;
    if (value?.type === "search") {
      query += `&limit=10&offset=0&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };  
  let user = JSON.parse(localStorage.getItem("user"));
  useComponentDidMount(() => {   
    let empquery = `distributor_ref_id=${user?.distributor_ref_id}`;
    let query = queryGenerator();
    dispatch(allEmpList(empquery));
    dispatch(SubscriberListAssigned(query));
  }, []);

  useComponentWillUnmount(() => {
    dispatch(reset());
  });

  useComponentDidUpdate(() => {
    let query = queryGenerator();
    let message = "";
    if (SubscriberListMultipleDeleteData) {
      message="Pay now updated"
      enqueueSnackbar(message, {
        variant: "success",
      });  
      reset();
      dispatch(reset());
      dispatch(SubscriberListAssigned(query));
    }
  }, [SubscriberListMultipleDeleteData]);

  useComponentDidUpdate(() => {
    let query = queryGenerator();
    if (deleteRes) {
      enqueueSnackbar("Deleted !", {
        variant: "success",
      });
      dispatch(reset(query));
      dispatch(SubscriberListAssigned(query));
    }
  }, [deleteRes]);

  useComponentDidUpdate(() => {
    let query = queryGenerator();
    if (assignSubscriberSubmitData) {
      enqueueSnackbar("Assigned successfully !", {
        variant: "success",
      });      
      dispatch(reset());
      dispatch(SubscriberListAssigned(query));
      setShow(!show);
    }
  }, [assignSubscriberSubmitData]);
  

  useComponentDidUpdate(() => {
    let list = [];
    SubscriberListAssignedData?.data.map((data, index) => {
      list.push({
        sNo: (page - 1) * 10 + index + 1,
        name: data?.name,
        id:data?._id,
        accNo: data?.account_no,
        canId: data?.can_id,
        stbNo: data?.stb_no[0]?.stb_no,
        serviceType: data?.service_type,
        billingType: data?.billing_type,
        email: data?.email_id,
        area: data?.area,
        flat: data?.flat_no,
        floor: data?.floor,
        wings: data?.wings,
        city: data?.city,
        state: data?.state,
        status: moment(data?.pay_now_added_date).format("ll"),
      });
    });

    setexpList(list);
  }, [SubscriberListAssignedData]);

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    dispatch(SubscriberListAssigned(`distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&value=${search}&pay_now=true`));
  };



  const removePaid = () => {
    let filtered = expList.filter((options) => options.checked === true);    
    if(filtered.length===0){
      enqueueSnackbar("Please select atlease one from the list!", {
        variant: "error",
      }); 
      return;
    }
    let preparedeletepay=[]
    filtered.map((data, index) => {
      preparedeletepay.push({
        "pay_now":false,
        "pay_now_updated_date":new Date(),
        "_id":data.id
      });
    }); 
    dispatch(SubscriberListMultipleDelete(preparedeletepay))
    setChecked(false)
  };

  const handleCheckBox = (index, checked) => {
    const clonedDataService = [...expList];
    if (index === 0 || index > 0) {
      clonedDataService[index].checked = clonedDataService[index].checked
        ? false
        : true;
    } else if (index === "all") {
      for (let services of clonedDataService) {
        if (checked) {
          services.checked = true;
        } else {
          services.checked = false;
        }
      }
    }
    setexpList(clonedDataService);
    setChecked(checked)
  };

  useComponentDidUpdate(() => {
    if(empFullData){
      let filtered = empFullData;
      let preparedeletepay=[]
      filtered.map((data, index) => {
        preparedeletepay.push({
          "label": data.name,
          "value": data.name     
        });
      }); 
    }
  }, [empFullData]);


  const handleClickSearcch = (value,value1,priorityval,statusval) => {
    setPage(1);
    let query="pay_now=true";
    if(value){
      query += `&payNowStartDate=${moment(value).format("YYYY-MM-DDTHH:mm:ss")}`
    }
    if(value1){
      query += `&payNowEndDate=${moment(value1).format("YYYY-MM-DDTHH:mm:ss")}`
    }
    if(priorityval){
      query += `&service_type=${priorityval?.value}`
    }
    if(statusval){
      query += `&billing_type=${statusval?.value}`
    }
    dispatch(SubscriberListAssigned(`limit=10&offset=0&distributor_ref_id=${user?.distributor_ref_id}&${query}`));
    setDateFromQuery(value);
    setDateToQuery(value1);
    setFirstQuery(priorityval?.value);
    setSecondQuery(statusval?.value);
  };

  const handleChange = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(SubscriberListAssigned(`distributor_ref_id=${user?.distributor_ref_id}&limit=10&pay_now=true&offset=${value - 1}0${searchQuery ? `&value=${searchQuery}` : ""}${dateFrom? `&StartDate=${moment(dateFrom).format("YYYY-MM-DDTHH:mm:ss")}`: ""}${ dateTo ? `&endDate=${moment(dateTo).format("YYYY-MM-DDTHH:mm:ss")}` : ""}${filterOne? `&service_type=${filterOne}`: ""}${filtertwo? `&billing_type=${filtertwo}`: ""}`));
  }; 


  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  const handleReset = () => {
    let empquery = `distributor_ref_id=${user?.distributor_ref_id}`;
    let query = queryGenerator();
    dispatch(allEmpList(empquery));
    dispatch(SubscriberListAssigned(query));
    setDateFromQuery("");
    setDateToQuery("");
    setFirstQuery("");
    setSecondQuery("");
    setPage(1);
};

  return (
   
    <div className="subsList-container">
      
      <Grid container spacing={2} className=''>
        <Grid item xs={8}>
        <div className="employeelist-title">Ready To Pay</div>
        </Grid>
        <Grid item xs={4} className="flex-end">
          <div className="employeelist-title">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              activeHeading="Ready To Pay"
              handleActiveClick={() => handleBreadCrumbRedirect("ready-to-pay-list")}
            />
          </div>

        </Grid>
      </Grid>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={4} md={3}>
              <div className="planHeading"></div>
            </Grid>
            <Grid item xs={0} sm={4} md={6}></Grid>
            <Grid item xs={4} sm={4} md={3} className="flex-end">
              <div className="">
                <TicketFilter
                  labelPrimary={"Service Type"}
                  PrimaryOption={ serviceType} 
                  SecondaryOption={billingTypes}
                  labelSecondary={"Billing Type"}
                  handleResetClick = {handleReset}
                  handleFormValues={(data1,data2,data3,data4) => handleClickSearcch(data1,data2,data3,data4)}/>
              </div>
            </Grid>
          </Grid>        
          <div className="search-btns-padding">
            <SearchWithButtons
              primaryButton={{
                title: "Remove from paid",
                type: "contained",
                color: "white",
                bgColor: COLORS.primary,
              }}
            secondaryButton={{
                title: "Export",
                type: "outlined",
                color: COLORS.primary,
                filename:"Ready to pay",
                data: expList,
              }}
              handlePrimaryClick={removePaid}
              // handleSecondaryClick={}
              handleChange={handleSearch}
            />
          </div>
          <div style={{ fontWeight: 'bold', fontSize: '15px',paddingBottom: '10px'}}>Showing entries { (page - 1) * 10 + 1 } - {Math.ceil(SubscriberListAssignedData?.totalCount / 10)===page?SubscriberListAssignedData?.totalCount:page+"0"} of {SubscriberListAssignedData?.totalCount}</div>  
      <TableView
          rows={tableRows}
          data={expList}
          color={COLORS.primary}
          handleCheckBoxChange={handleCheckBox}
          checkedVal={checkedVal}
        />
      <div style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}>
        <Pagination
          count={Math.ceil(SubscriberListAssignedData?.totalCount / 10) || 0}
          defaultPage={1}
          page={page}
          onChange={(event, value) => {
            setPage(value);
            handleChange(value);
          }}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  
  );
}
