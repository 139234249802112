import { Grid } from "@mui/material";
import "./styles.scss";
import CablePayLogo from "@assets/images/logo.png";
import { useNavigate } from "react-router-dom";


export default function HomeFooter(props) {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <div className="footer-contents">
          <Grid container spacing={2} className="footerPadding">
            <Grid item xs={6} md={5}>
              <div>
                <img className="" src={CablePayLogo} />
                <p>
                  Effortless Payment Management with stronger ticketing system,
                </p>
                <span> tailored for your requirements </span>
              </div>
            </Grid>
            <Grid item xs={6} md={4}>
              <h4>Address</h4>
              <p>Tech Support: cablepaynetworks@gmail.com</p>
              <p>Address:   AJP Anthony Cable Network,</p>
              <p>Cablepay Solutions India Private Limited,</p>
              <p>1287 9th Cross R K Hegdenagar DR Shivarama Karnath Nagar Post</p>
              <p>Bangalore 560077</p>
              <p>Contact No:  7019327624,  7019627124</p>
            </Grid>
            <Grid item xs={12} md={3}>
              <h4>Quick Link</h4>

              <p className='cursor-pointer' >
                <a href=" #" style={{ textDecoration: "none", color: 'white' }}>Home</a>
              </p>

              <p className='cursor-pointer' onClick={() => navigate("/login")}>Login</p>

            </Grid>
          </Grid>
          <hr></hr>
          <Grid container spacing={2} className='footerPadding'>
            <Grid item xs={6}>
              <p>© 2022 Cablepay. All Rights Reserved.</p>
            </Grid>
            <Grid item xs={6}>
              <p className='floatText' onClick={() => navigate("/home/privacy-policy")} style={{ cursor: 'pointer' }}>Terms & Conditions | Privacy Policy | Terms of Use</p>
            </Grid>

          </Grid>
        </div>
      </div>
    </>
  );
}
