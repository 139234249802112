import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import "./style.scss";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Autocomplete } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

const validation = Yup.object({
  distributorName: Yup.string().required("Required"),
  distributorEmail: Yup.string()
    .required("Required")
    .email("Invalid email address"),
  mobileNumber: Yup.string()
    .required("Required")
    .max(10, "Must be exactly 10 digits")
    .matches(new RegExp("[0-9]{10}"), "Phone number is not valid"),
  state: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  pinCode: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  validity: Yup.object().required("Required").nullable(),
  sms: Yup.object().required("Required").nullable(),
  whatsapp: Yup.object().required("Required").nullable(),
});

const validityOptions = [
  { label: "30 Days", value: 30 },
  { label: "90 Days", value: 90 },
  { label: "180 Days", value: 180 },
  { label: "365 Days", value: 365 },
  { label: "Unlimited", value: "" },
];

const SmsOptions = [
  { label: "Enable", value: 'enable' },
  { label: "Disable", value: 'disable' },
];

export default function CreateDistributor(props) {
  const [duevalue, setdueValue] = useState(new Date());
  const [valuedate, setValue] = useState( new Date() );
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate(`/admin/distributor-list`);
  };

  const filterObject = () => {
    if (props?.data?.distributor_validity === undefined) return "";
    let filtered = validityOptions.filter(
      (options) =>
        options.value.toString() ===
        props?.data?.distributor_validity.toString()
    );
    return filtered[0];
  };

  const filterSmsObject = () => {
    if (props?.data?.sms_access === undefined) return "";
    let filtered = SmsOptions.filter(
      (options) =>
        options.value.toString() ===
        props?.data?.sms_access.toString()
    );
    return filtered[0];
  };

  const filterWhatsappObject = () => {
    if (props?.data?.whatsapp_access === undefined) return "";
    let filtered = SmsOptions.filter(
      (options) =>
        options.value.toString() ===
        props?.data?.whatsapp_access.toString()
    );
    return filtered[0];
  };


  return (
    <div className="distributorcard">
      <Formik
        initialValues={{
          distributorName: props?.data?.name || "",
          distributorEmail: props?.data?.email_id || "",
          mobileNumber: props?.data?.mobile_no || "",
          state: props?.data?.state || "",
          city: props?.data?.city || "",
          pinCode: props?.data?.pin_code || "",
          address: props?.data?.address || "",
          subscriber_limit_count: props?.data?.subscriber_limit_count || "",
          validity: filterObject() || "",
          dueDate: props?.data?.validity_added_date || duevalue,
          gstn: props?.data?.GSTN || "",
          pan: props?.data?.PAN || "",
          sms: filterSmsObject() || "",
          whatsapp:filterWhatsappObject()||""
        }}
        enableReinitialize
        validationSchema={validation}
        onSubmit={(values, valuedatefield) => {
          props?.handleFormValues(values, duevalue);
          setValue(new Date());
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit} className="formCreatePlan">
            <Grid container spacing={4}>
              <Grid item container spacing={5} className="martop">
                <Grid item xs={6}>
                  <label className="createForm">Distributor Name</label>
                  <TextField
                    {...formik.getFieldProps("distributorName")}
                    className="txtwidth"
                    placeholder="Enter a name"
                    variant="outlined"
                    size="small"
                  />
                  {formik.touched.distributorName &&
                    formik.errors.distributorName ? (
                    <div className="field-error">
                      {formik.errors.distributorName}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  <label className="createForm">Distributor Email</label>
                  <TextField
                    {...formik.getFieldProps("distributorEmail")}
                    className="txtwidth"
                    placeholder="Enter a valid email id"
                    variant="outlined"
                    size="small"
                  />
                  {formik.touched.distributorEmail &&
                    formik.errors.distributorEmail ? (
                    <div className="field-error">
                      {formik.errors.distributorEmail}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item container spacing={5}>
                <Grid item xs={6}>
                  <label htmlFor="lastName" className="createForm">
                    Mobile Number
                  </label>
                  <TextField
                    {...formik.getFieldProps("mobileNumber")}
                    className="txtwidth"
                    placeholder="Enter a mobile number"
                    variant="outlined"
                    size="small"
                    type="number"
                  />
                  {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                    <div className="field-error">
                      {formik.errors.mobileNumber}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  <label className="createForm">State</label>
                  <TextField
                    {...formik.getFieldProps("state")}
                    className="txtwidth"
                    placeholder="Enter state"
                    variant="outlined"
                    size="small"
                  />
                  {formik.touched.state && formik.errors.state ? (
                    <div className="field-error">{formik.errors.state}</div>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item container spacing={5}>
                <Grid item xs={6}>
                  <label className="createForm">City</label>
                  <TextField
                    {...formik.getFieldProps("city")}
                    className="txtwidth"
                    placeholder="Enter city "
                    variant="outlined"
                    size="small"
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div className="field-error"> {formik.errors.city}</div>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  <label className="createForm ">Pincode</label>
                  <TextField
                    {...formik.getFieldProps("pinCode")}
                    className="txtwidth"
                    placeholder="Enter a valid pincode"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={5}>
                {props?.data?._id ? (
                  <Grid item xs={6} className="autocomplete">
                    <label className="createForm">Validity</label>
                    <Autocomplete
                      size="small"
                      disabled
                      value={formik.values.validity}
                      isOptionEqualToValue={(option) =>
                        option.label == formik.values.validity?.label || ""
                      }
                      options={validityOptions}
                      onChange={(e, value) =>
                        formik.setFieldValue("validity", value || "")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...formik.getFieldProps("validity")}
                          placeholder="Select Validity"
                          {...params}
                        />
                      )}
                    />
                    {formik.touched.validity && formik.errors.validity ? (
                      <div className="field-error">
                        {formik.errors.validity}
                      </div>
                    ) : null}
                  </Grid>
                ) : (
                  <Grid item xs={6} className="autocomplete">
                    <label className="createForm">Validity</label>
                    <Autocomplete
                      size="small"
                      value={formik.values.validity}
                      isOptionEqualToValue={(option) =>
                        option.label == formik.values.validity?.label || ""
                      }
                      options={validityOptions}
                      onChange={(e, value) =>
                        formik.setFieldValue("validity", value || "")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...formik.getFieldProps("validity")}
                          placeholder="Select Validity"
                          {...params}
                        />
                      )}
                    />
                    {formik.touched.validity && formik.errors.validity ? (
                      <div className="field-error">
                        {formik.errors.validity}
                      </div>
                    ) : null}
                  </Grid>
                )}

                <Grid item xs={6}>
                  <label className="createForm ">Subscriber limit count</label>
                  <TextField
                    {...formik.getFieldProps("subscriber_limit_count")}
                    type="number"
                    className="txtwidth"
                    placeholder="Enter a limit"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={5}>
                {props?.data?._id ? (
                  <Grid item xs={6} className="date-picker-height">
                    <label className="createForm">Validity Date</label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disabled
                        value={props?.data?.validity_added_date || duevalue}
                        name="dueDate"
                        style={{ height: "2px" }}
                        onChange={(newValue) => {
                          setValue(newValue);
                          setdueValue(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...formik.getFieldProps("dueDate")}
                            {...params}
                            className="datafieldHeight"
                            style={{ width: "100%", height: "2px" }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                ) : (
                  <Grid item xs={6} className="date-picker-height">
                    <label className="createForm">Validity Date</label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={valuedate}
                        name="dueDate"
                        style={{ height: "2px" }}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...formik.getFieldProps("dueDate")}
                            {...params}
                            className="datafieldHeight"
                            style={{ width: "100%", height: "2px" }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                )}

                <Grid item xs={6}>
                  <label className="createForm">PAN</label>
                  <TextField
                    {...formik.getFieldProps("pan")}
                    type="text"
                    className="txtwidth"
                    placeholder="Enter pan "
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={5}>

                <Grid item xs={6}>
                  <label className="createForm">GSTN</label>
                  <TextField
                    {...formik.getFieldProps("gstn")}
                    type="text"
                    className="txtwidth"
                    placeholder="Enter gstn"
                    variant="outlined"
                    size="small"
                  />
                </Grid>


                <Grid item xs={3} className="autocomplete">
                  <label className="createForm">SMS</label>
                  <Autocomplete
                    size="small"
                    value={formik.values.sms}
                    isOptionEqualToValue={(option) =>
                      option.label == formik.values.sms?.label || ""
                    }
                    options={SmsOptions}
                    onChange={(e, value) =>
                      formik.setFieldValue("sms", value || "")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...formik.getFieldProps("sms")}
                        placeholder="Select Sms option"
                        {...params}
                      />
                    )}
                  />
                  {formik.touched.sms && formik.errors.sms ? (
                    <div className="field-error">
                      {formik.errors.sms}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={3} className="autocomplete">
                  <label className="createForm">Whatsapp</label>
                  <Autocomplete
                    size="small"
                    value={formik.values.whatsapp}
                    isOptionEqualToValue={(option) =>
                      option.label == formik.values.whatsapp?.label || ""
                    }
                    options={SmsOptions}
                    onChange={(e, value) =>
                      formik.setFieldValue("whatsapp", value || "")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...formik.getFieldProps("whatsapp")}
                        placeholder="Select option"
                        {...params}
                      />
                    )}
                  />
                  {formik.touched.whatsapp && formik.errors.whatsapp ? (
                    <div className="field-error">
                      {formik.errors.whatsapp}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item container spacing={4}>
                <Grid item xs={12}>
                  <label className="createForm ">Address</label>
                  <TextField
                    {...formik.getFieldProps("address")}
                    className="txtwidth"
                    placeholder="Enter address "
                    variant="outlined"
                    size="small"
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <div className="field-error">{formik.errors.address}</div>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item container spacing={4}>
                <Grid item xs={12}>
                  <Stack spacing={2} direction="row" className="btnright">
                    <Button
                      size="small"
                      variant="outlined"
                      style={{
                        textTransform: "capitalize",
                        borderColor: "#00249b",
                        color: "#00249b",
                      }}
                      onClick={handleRedirect}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      style={{ textTransform: "capitalize" }}
                      className="bg-blue"
                      type="submit"
                    >
                      {props?.data?._id
                        ? "Update Distributor"
                        : "New Distributor"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
}
