import "./styles.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchWithButtons from "@components/SearchWithButtons";
import DatePickerInput from "@components/DatePicker";
import { useNavigate } from "react-router-dom";
import { useComponentDidMount, useComponentDidUpdate, paramsQuery, useComponentWillUnmount } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { billingList, deleteCollection, paidByList, collectionListNew, billingListExport } from "@reducers/api";
import { useState } from "react";
import { useSnackbar } from "notistack";
import Pagination from "@mui/material/Pagination";
import TicketFilter from "@components/TicketFilter";
import Grid from "@mui/material/Grid";
import moment from "moment";
import TicketStatus from "@components/TicketStatus";
import BreadCrumb from "@components/Breadcrumb";
import { reset } from "@reducers/subscriber";
import { Billingreset } from "@reducers/billing";

const serviceType = [
  { label: "Cable", value: "cable" },
  { label: "Internet", value: "internet" },
];

const ticketStatusData = [
  {
    id: 1,
    title: "Total Bill Amount",
    count: 30,
    icon: "",
  },
  {
    id: 2,
    title: "Total Bill Count",
    count: 28,
    icon: "",
  },
  {
    id: 3,
    title: "Total Advance Amount",
    count: 13,
    icon: "",
  },
  {
    id: 4,
    title: "Total Collection Amount",
    count: 8,
    icon: "",
  },
  {
    id: 5,
    title: "Total Discount Amount",
    count: 8,
    icon: ""
  },
];

export default function BillingCollectionList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { billingListData, billingDeleted, paidByListData, collectionListNewData } = useSelector((state) => state.billing);
  const { billingListExportData } = useSelector((state) => state.subscriber);
  const [billList, setbillList] = useState([]);
  const [collectionListExport, setCollectionListExport] = useState([]);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [dateFrom, setDateFromQuery] = useState("");
  const [dateTo, setDateToQuery] = useState("");
  const [filterOne, setFirstQuery] = useState("");
  const [filtertwo, setSecondQuery] = useState("");
  const [filterthree, setThirdQuery] = useState("");
  const [filterFour, setFourQuery] = useState("");

  useComponentDidUpdate(() => {
    if (billingListExportData) {
      if (billingListExportData.fileData) {
        window.location.href = `data:application/vnd.ms-excel;base64,${billingListExportData.fileData}`
      }
      if (billingListExportData?.msg == "no data found") {
        enqueueSnackbar("No data....!", {
          variant: "error",
        });
      }
      dispatch(reset())
    }
  }, [billingListExportData]);

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&billing_status=success`;
    if (value?.type === "search") {
      query += `&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };

  const filterQuery = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;
    if (value?.service_type) {
      query += `&paidBy=${value?.state}`;
    }
    if (value?.secondary) {
      query += `&service_type=${searchQuery}`;
    }
    return query;
  };

  const billingType = [
    { label: "Pre Paid", value: "pre paid" },
    { label: "Post Paid", value: "post paid" },
  ];
  const payType = [
    { label: "Cash", value: "cash" },
    { label: "Card", value: "card" },
    { label: "Online", value: "online" },
    { label: "Free", value: "free" },
  ];

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let distid = `distributor_ref_id=${user?.distributor_ref_id}`;
    let query = queryGenerator();
    dispatch(billingList(query));
    dispatch(paidByList(distid));
    dispatch(collectionListNew(query))
  }, []);

  let collectionList = billingListData?.data?.filter((value) => value.billing_status == "success");

  useComponentDidUpdate(() => {
    let list = [];
    let listForExport = [];
    collectionList?.map((data, index) => {
      list.push({
        sNo: (page - 1) * 10 + index + 1,
        customerName: data?.customerData?.name,
        accountid: data?.customerData.account_id,
        msqid: data?.customerData?.mso_id,
        stbno: data?.stb_no || data?.customerData?.stb_no[0]?.stb_no || "-",
        customerEmail: data?.customerData?.email_id,
        subarea: data?.customerData?.subarea|| "-",
        flatno: data?.customerData?.flat_no|| "-",
        billAmount: data?.bill_amount,
        collectionAmount: data?.collection_amount,
        dueAmount: data?.due_amount,
        discount: data?.discount_amount,
        advance: data?.advance_amount,
        paymentType: data?.payment_type,
        serviceType: data?.service_type,
        remarks: data?.remark,
        status: status("#73ca72", data?.billing_status),
        paidBy: data?.collected_by,
        completedDate: moment(data?.updated_at).format("MMMM Do YYYY, h:mm a"),
        createdDate: moment(data?.created_at).format("MMMM Do YYYY, h:mm a"),
        billing_status: data?.billing_status,
        action: (
          <div className="flex-row justify-center">
            <div
              className="cur-pointer"
              onClick={() =>
                dispatch(
                  deleteCollection({
                    invoice_id: data?.order_id,
                  })
                )
              }
            >
              <DeleteIcon />
            </div>
          </div>
        ),
      });
      listForExport.push({
        sNo: (page - 1) * 10 + index + 1,
        customerName: data?.customerData?.name,
        accountid: data?.customerData.account_id,
        msqid: data?.customerData?.mso_id,
        customerEmail: data?.customerData?.email_id,
        subarea: data?.customerData?.subarea|| "-",
        flatno: data?.customerData?.flat_no|| "-",
        billAmount: data?.bill_amount,
        collectionAmount: data?.collection_amount,
        dueAmount: data?.due_amount,
        discount: data?.discount_amount,
        paymentType: data?.payment_type,
        serviceType: data?.service_type,
        remarks: data?.remark,
        paidBy: data?.collected_by,
        completedDate: moment(data?.updated_at).format("ll"),
        createdDate: moment(data?.created_at).format("ll"),
        billing_status: data?.billing_status,
      });
    });

    setbillList(list);
    setCollectionListExport(listForExport);
  }, [billingListData]);

  useComponentDidUpdate(() => {
    let query = queryGenerator();
    if (billingDeleted) {
      enqueueSnackbar("Collection List Deleted !", {
        variant: "success",
      });
    }
    dispatch(billingList(query));
    dispatch(Billingreset());

  }, [billingDeleted]);

  useComponentWillUnmount(() => {
    dispatch(reset());
    dispatch(Billingreset());
  });

  const status = (color, text) => {
    return (
      <div className="status-btn-table" style={{ backgroundColor: color }}>
        {text}
      </div>
    );
  };

  const handleRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  const handleExtraExportClick = (path) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(billingListExport(`distributor_ref_id=${user?.distributor_ref_id}&billing_status=success`));
  };

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    let query = queryGenerator({ type: "search", state: search });
    dispatch(billingList(query));
  };

  const handleChange = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(billingList(`distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=${value - 1}0&billing_status=success${searchQuery ? `&value=${searchQuery}` : ""}${dateFrom? `&fromdate=${moment(dateFrom).format("YYYY-MM-DDTHH:mm:ss")}`: ""}${ dateTo ? `&todate=${moment(dateTo).format("YYYY-MM-DDTHH:mm:ss")}` : ""}${filterOne? `&service_type=${filterOne}`: ""}${filtertwo? `&paidBy=${filtertwo}`: ""}${filterthree? `&billing_type=${filterthree}`: ""}${filterFour? `&payment_type=${filterFour}`: ""}`));
    dispatch(collectionListNew(`distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=${value - 1}0&billing_status=success${searchQuery ? `&value=${searchQuery}` : ""}${dateFrom? `&fromdate=${moment(dateFrom).format("YYYY-MM-DDTHH:mm:ss")}`: ""}${ dateTo ? `&todate=${moment(dateTo).format("YYYY-MM-DDTHH:mm:ss")}` : ""}${filterOne? `&service_type=${filterOne}`: ""}${filtertwo? `&paidBy=${filtertwo}`: ""}${filterthree? `&billing_type=${filterthree}`: ""}${filterFour? `&payment_type=${filterFour}`: ""}`));
  };

  const handleClickSearcch = (value, value1, priorityval, statusval, thirdValue, fourthValue) => {
    setPage(1);
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&billing_status=success`;
    if (value) {
      query += `&fromdate=${moment(value).format("YYYY-MM-DDTHH:mm:ss")}`
    }
    if (value1) {
      query += `&todate=${moment(value1).format("YYYY-MM-DDTHH:mm:ss")}`
    }
    if (priorityval) {
      query += `&service_type=${priorityval?.value}`
    }
    if (thirdValue) {
      query += `&billing_type=${thirdValue?.value}`
    }
    if (statusval) {
      query += `&paidBy=${statusval}`
    }
    if (fourthValue) {
      query += `&payment_type=${fourthValue?.value}`
    }
    dispatch(billingList(query))
    dispatch(collectionListNew(query))
    setDateFromQuery(value);
    setDateToQuery(value1);
    setFirstQuery(priorityval?.value);
    setThirdQuery(thirdValue?.value);
    setFourQuery(fourthValue?.value)
    setSecondQuery(statusval);
  };

  const handleReset = () => {
    let query = queryGenerator();
    dispatch(billingList(query));
    let user = JSON.parse(localStorage.getItem("user"));
    let distid = `distributor_ref_id=${user?.distributor_ref_id}`;
    dispatch(paidByList(distid));
    dispatch(collectionListNew(query))
    setPage(1);
    setDateFromQuery("");
    setDateToQuery("");
    setFirstQuery("");
    setThirdQuery("");
    setFourQuery("")
    setSecondQuery("");
  };


  return (
    <div className="collectionList-container ticketList-container">
      <Grid container spacing={2}>
        <Grid item xs={8}>
        </Grid>
        <Grid item xs={12} md={4} className="flex-end">
          <div className="planHeading">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleRedirect("dashboard")}
              activeHeading="Collection List"
              handleActiveClick={() => handleRedirect("collection-list")}
            />
          </div>
        </Grid>
      </Grid>

      <div className="ticket-list-table-cntr">

        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={4} md={3}>
            <div className="planHeading">Collection List</div>
          </Grid>
          <Grid item xs={0} sm={4} md={6}></Grid>
          <Grid item xs={4} sm={4} md={3} className="flex-end">
            <div className="">
              <TicketFilter labelPrimary="Service Type" labelSecondary="Paid By"
                PrimaryOption={serviceType} SecondaryOption={paidByListData} thirdOption={"Billing Type"}  thirdList={billingType} fourthOption={"Payment Type"}
                fourthList={payType}  handleFormValues={(data1, data2, data3, data4, data5, data6) => handleClickSearcch(data1, data2, data3, data4, data5, data6)}
                handleResetClick={handleReset} />
            </div>
          </Grid>
        </Grid>
        <div>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.4}
              style={{ paddingLeft: 0, paddingBottom: 0 }}
            >
              <TicketStatus
                className=" ticketCardGreen"
                title={ticketStatusData[0].title}
                count={collectionListNewData?.TotalBillAmount || "0"}
                icon={ticketStatusData[0].icon}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.4}
              style={{ paddingLeft: 0, paddingBottom: 0 }}
            >
              <TicketStatus
                className=" ticketCardRed"
                title={ticketStatusData[1].title}
                count={collectionListNewData?.TotalBillCount || "0"}
                icon={ticketStatusData[1].icon}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.4}
              style={{ paddingLeft: 0, paddingBottom: 0 }}
            >
              <TicketStatus
                className=" ticketCardBlue"
                title={ticketStatusData[2].title}
                count={collectionListNewData?.TotalAdvanceAmount || "0"}
                icon={ticketStatusData[2].icon}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.4} style={{ paddingLeft: 0, paddingBottom: 0 }}>
              <TicketStatus className=" ticketCardPink" title={ticketStatusData[3].title} count={collectionListNewData?.TotalCollectionAmount || "0"} icon={ticketStatusData[3].icon} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.4} style={{ paddingLeft: 0, paddingBottom: 0 }}>
              <TicketStatus className=" ticketCardPurple" title={ticketStatusData[4].title} count={collectionListNewData?.TotalDiscountAmount || "0"} icon={ticketStatusData[4].icon} />
            </Grid>
          </Grid>
        </div>
        <div className="search-btns-padding">
          <SearchWithButtons
            primaryButton={{
              title: "Paid Details",
              type: "contained",
              color: "white",
              bgColor: COLORS.primary,
            }}
            secondaryButton={{
              title: "Unpaid Details",
              type: "contained",
              color: "white",
              bgColor: COLORS.primary,
            }}
            extraButton={{
              title: "Invoice",
              type: "contained",
              color: "white",
              bgColor: COLORS.primary,
            }}
            secondryExtraButtonExportData={{
              title: "Export",
              type: "contained",
              color: COLORS.primary,
              bgColor: COLORS.primary,
            }}
            handleExtraExportClick={() => handleExtraExportClick("export")}
            handlePrimaryClick={() => handleRedirect("paid-list")}
            handleSecondaryClick={() => handleRedirect("unpaid-list")}
            handleExtraClick={() => handleRedirect("invoice-list")}
            handleChange={handleSearch}

          />
        </div>
        <div style={{ fontWeight: 'bold', fontSize: '15px', paddingBottom: '10px' }}>Showing entries {(page - 1) * 10 + 1} - {Math.ceil(billingListData?.totalCount / 10) == page ? billingListData?.totalCount : page + "0"} of {billingListData?.totalCount}</div>
        <TableView rows={tableRows} data={billList} color={COLORS.primary} bilingTable={true}/>
        <div style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}>
          <Pagination
            count={Math.ceil(billingListData?.totalCount / 10) || 0}
            defaultPage={1}
            page={page}
            onChange={(event, value) => {
              setPage(value);
              handleChange(value);
            }}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
}
