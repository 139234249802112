import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./styles.scss";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { inventoryMultipleAdd, bulkUploadAddOnSave } from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import { useComponentDidUpdate, paramsQuery } from "@utils/useEffectHooks";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/subscriber";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function BulkAddOnTvModal(props) {
  const [payload, setpayload] = useState([]);
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      test: [{ name: "",  }],
    },
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "test",
    }
  );

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValueDate] = useState(new Date());
  const [packagePrizing, setPackage] = useState();
  const { updateBalanceData } = useSelector((state) => state.subscriber);
  const { bulkUploadAddOnValidAdded, bulkUploadSaveAddOnAdded } = useSelector((state) => state.subscriber);
  const { inventoryAddMultipleData } = useSelector(
    (state) => state.inventory
  );
  const [amount, setAmount] = useState(0);

  const options1 = [{ label: "HD", value: "hd" }, { label: "SD", value: "sd" }];

  const paymentOption = [
    { label: "Free", value: "free" },
    { label: "Paid", value: "paid" },
  ];

  const handleClose = () => {
    props?.handleClose();
    reset()
  };


  useComponentDidUpdate(() => {
    if (inventoryAddMultipleData.message === "Data Added successfully") {
      enqueueSnackbar("STB Added", {
        variant: "success",

      });
      handleClose()
      reset()

    }

    if (inventoryAddMultipleData.status === false) {
      enqueueSnackbar(inventoryAddMultipleData?.msg, {
        variant: "error",
      });

      // handleClose()
      // reset()
    }


  }, [inventoryAddMultipleData]);

console.log(inventoryAddMultipleData, "dattttt")

  const updateSubmit = (data) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let id = user?.distributor_ref_id;

    let preparedPayload = []
    data?.test.map((data, index) => {
      if(data.stbNo === "" || data.boxType?.label === ""  || data.price === "" || data.payment?.value === "" ) return ;
      preparedPayload.push({
        "stb_no": data?.stbNo,
        "items": "stb",
        "price": data?.price,
        "distributor_ref_id": id,
        "quantity": data?.package?._id,
        "service_type": "cable",
        "inventory_type": data?.boxType?.label,
        "hardware_type": "stb",
        "itemProviderName": "mso",
        "stb_payment_category": data?.payment?.value,
      });
    });
    setpayload(preparedPayload);
    if(preparedPayload.length === 0) {
      enqueueSnackbar("Please enter all fields", {
        variant: "error",
      });
    }
    else{
      dispatch(inventoryMultipleAdd(preparedPayload));
    }
   
  };

  const onSubmit = (data) => {
    updateSubmit(data);
  };

  const handleSetValue = (item) => {
    setPackage(item)
    setValue("packagePrize", item.plan_amount);

  };

  const handleChange = (newValue) => {
    setValueDate(newValue);
    setValue("date", newValue);
  };

  useComponentDidUpdate(() => {
    let id = paramsQuery("id");
    if (updateBalanceData) {
      enqueueSnackbar("Updated", {
        variant: "success",
      });
      props?.handleClose();
    }
  }, [updateBalanceData]);


  return (
    <Modal
      open={props?.show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="bulkheight">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className= 'stb-head'>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">STB No </TableCell>
                  <TableCell align="center"> Box Type</TableCell>
                  <TableCell align="center">Payment Category </TableCell>
                  <TableCell align="center">Price</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {fields.map((row, i) => {
                  return (
                    <TableRow
                      key={""}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        {i == 0 ? (
                          <>
                            {" "}
                            <AddCircleIcon
                              style={{ fontSize: "2rem", fill: "#00249B" }}
                              onClick={() => prepend({ stbNo: "", boxType: "", payment: "", price: "", })}
                              className="cursor-pointer"
                            />
                            <DeleteIcon
                              style={{ fontSize: "2rem", fill: "#00249B" }}
                              onClick={() => remove(i)}
                              className="cursor-pointer"
                            />{" "}
                          </>
                        ) : (
                          <>
                            {" "}
                            <AddCircleIcon
                              style={{ fontSize: "2rem", fill: "#00249B" }}
                              onClick={() => prepend({ stbNo: "", boxType: "", payment: "",  })}
                              className="cursor-pointer"
                            />
                            <DeleteIcon
                              style={{ fontSize: "2rem", fill: "#00249B" }}
                              onClick={() => remove(i)}
                              className="cursor-pointer"
                            />{" "}
                          </>
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <TextField
                          {...register(`test[${i}].stbNo`)}
                          size="small"
                          style={{ width: "100%" }}
                          className="txtwidth"
                          variant="outlined"
                          id="outlined-basic"
                        />
                      </TableCell>

                      <TableCell align="right">
                        <Controller
                          control={control}
                          name={`test[${i}].boxType`}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              onChange={(event, item) => {
                                onChange(item);
                                handleSetValue(item);

                              }}
                              value={value || ""}
                              options={options1 || []}
                              isOptionEqualToValue={(option) =>
                                (option?.label === option?.label) == value?.label ||
                                ""
                              }
                              getOptionLabel={(item) => (item.label ? item.label : "")}
                              renderInput={(params) => <TextField {...params} size="small" />}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Controller
                          control={control}
                          name={`test[${i}].payment`}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              onChange={(event, item) => {
                                onChange(item);
                                handleSetValue(item);

                              }}
                              value={value || ""}
                              options={paymentOption || []}
                              isOptionEqualToValue={(option) =>
                                (option?.label === option?.label) == value?.label ||
                                ""
                              }
                              renderInput={(params) => <TextField {...params} size="small" />}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <OutlinedInput
                          style={{ width: "100%" }}
                          {...register(`test[${i}].price`)}
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          //onChange={(e) => amountChange(e)}
                          startAdornment={
                            <InputAdornment data={amount} position="start">
                              ₹
                            </InputAdornment>
                          }
                        />
                      </TableCell>


                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container className="padding-btw-tiles flex-end popupConfirm">
            <Stack
              direction="row"
              className="flex-end btns--pay-stack"
              spacing={2}
            >
              <Button
                size="small"
                variant="outlined"
                className="btn-outlined"
                onClick={handleClose}
                style={{ textTransform: "none", color: "#00249b" }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                className="bg-blue"
                style={{ textTransform: "none" }}
                size="small"
              >
                Submit
              </Button>
            </Stack>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}
