import * as React from "react";
import { useState } from "react";
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  useComponentDidMount,
  useComponentDidUpdate,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { agentWorkTime, agentWorkTimeUpdate } from "@reducers/api";
import "./style.scss";
import { useSnackbar } from "notistack";

function incrementHour(prevHour) {
  switch (prevHour) {
    case 23:
      return 0;
    case null:
      return 0;
    default:
      return parseInt(prevHour) + 1;
  }
}

function incrementMinute(prevMinute) {
  switch (prevMinute) {
    case 59:
      return 0;
    case null:
      return 0;
    default:
      return parseInt(prevMinute) + 1;
  }
}

function decrementHour(prevHour) {
  switch (prevHour) {
    case 0:
    case null:
      return 23;
    default:
      return parseInt(prevHour) - 1;
  }
}

function decrementMinute(prevMinute) {
  switch (prevMinute) {
    case 0:
    case null:
      return 59;
    default:
      return parseInt(prevMinute) - 1;
  }
}

function formatOutput(value) {
  if (value === null) {
    return "00";
  }

  const numStr =  value && value.toString();

  if ( numStr && numStr.length === 1) {
    return "0" + value;
  }

  return value;
}

function buildTime(hours, minutes) {
  return formatOutput(hours);
}
function buildEndTime(endhours, minutes) {
  return formatOutput(endhours);
}
function minuteTime(hours, minutes) {
  return formatOutput(minutes);
}
function minuteEndTime(endhours, endminutes) {
  return formatOutput(endminutes);
}

const TimePicker = ({ selected = null, onChange }) => {
  const dispatch = useDispatch();
  const { agentWorkTimeData,workTimeData } = useSelector((state) => state.subscriber);

  const [initialHours, initialMinutes] = selected
    ? selected?.split(":")
    : [null, null];
  const [hours, setHours] = useState(initialHours);
  const [minutes, setMinutes] = useState(initialMinutes);
  const time = buildTime(hours, minutes);
  const minutesField = minuteTime(hours, minutes);
  const [endhours, setEndHours] = useState(initialHours);
  const endtime = buildEndTime(endhours, minutes);
  const [endminutes, setEndMinutes] = useState(initialMinutes);
  const minutesend = minuteEndTime(endhours, endminutes);
  const [startTimezone, setStartZone] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const getInitialState = () => {
    const values = "AM";
    return values;
  };

  const [value, setValue] = useState(agentWorkTimeData?.start_time?.slice(13) === "PM " ? "PM" : "AM");
  const [endvalue, setEndValue] = useState(getInitialState);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  console.log(agentWorkTimeData?.start_time?.slice(13) , "agent")
  
  const handleEndChange = (e) => {
    setEndValue(e.target.value);
  };

  useComponentDidMount(() => {

    let user = JSON.parse(localStorage.getItem("user"));
    let distributorId = `distributor_ref_id=${user?.distributor_ref_id}`;
    dispatch(agentWorkTime(distributorId));
  });

  useComponentDidUpdate(() => {
    let message = "";
    if (workTimeData) {
        message = "Work time updated";
      enqueueSnackbar(message, {
        variant: "success",
      });      
    }
  }, [workTimeData]);


  useComponentDidUpdate(() => {
    const startTime = agentWorkTimeData?.start_time?.split('T')
    setHours(startTime ? startTime[0]?.split(':')[0] : 0)
    const startMin = agentWorkTimeData?.start_time?.split(':')
    setMinutes(startMin ? startMin[1]?.slice(0, -2) : 0)
    const endTime = agentWorkTimeData?.end_time?.split('T')
    setEndHours(endTime ? endTime[0]?.split(':')[0] : 0)
    const endMin = agentWorkTimeData?.end_time?.split(':')
    setEndMinutes(endMin ? endMin[1].slice(0, -2) : 0)

    const startTimeZone = startMin && startMin[1]?.slice(2) 
    setStartZone(startTimeZone)
  }, [agentWorkTimeData]);


  const updateSubmit = (data) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let distributor_ref_id = user?.distributor_ref_id;
    let payload = {
      start_time: `${time}:${minutesField} ${value}`,
      end_time: `${endtime}:${minutesend} ${endvalue}`,
      start_date: new Date(),
      end_date: new Date(),
      distributor_ref_id:distributor_ref_id,
      type:"time",
      _id: agentWorkTimeData?._id || "",
    };
    dispatch(agentWorkTimeUpdate(payload));
  };



  return (
    <div className="agentWork-container">
      <Grid item xs={12} sm={6} md={6} spacing={4} className="AgentForm">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          spacing={4}
          className="workTime-Header"
        >
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <div className="expense-title ">Agent Work Time</div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="timer-btn">
          <Grid item xs={5}>
            <Button variant="contained" className="bg-blue cursor-auto">
              Start Time
            </Button>
          </Grid>
          <Grid item xs={2}>
            <div className="text-align">
              <AccessTimeIcon style={{ fontSize: "2rem", fill: "#00249b" }} />
            </div>
          </Grid>
          <Grid item xs={5} className=" text-align-right">
            <Button variant="contained" className="bg-blue cursor-auto">
              End Time
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={3} className="timer-btn">
          <Grid item xs={5} className="displayFlex">
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <div className="text-align">
                  <ArrowDropUpIcon onClick={() => setHours(incrementHour(hours))} />
                </div>
                <div className="hours">{time}</div>
                <div className="text-align">
                  {" "}
                  <ArrowDropDownIcon onClick={() => setHours(decrementHour(hours))} />
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="text-align">
                  <ArrowDropUpIcon
                    onClick={() => setMinutes(incrementMinute(minutes))}
                  />
                </div>
                <div className="hours">{minutesField}</div>
                <div className="text-align">
                  <ArrowDropDownIcon
                    onClick={() => setMinutes(decrementMinute(minutes))}
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
              <div className="marginTopTime">
                  <select id="dropdown" onChange={handleChange}>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={2}></Grid>
          <Grid item xs={6} sm={5} className=" text-align-right">
            <Grid container spacing={3}>
              <Grid item xs={4} sm={4}>
                <div className="text-align">
                  <ArrowDropUpIcon onClick={() => setEndHours(incrementHour(endhours))} />
                </div>
                <div className="hours">{endtime}</div>
                <div className="text-align">
                  {" "}
                  <ArrowDropDownIcon
                    onClick={() => setEndHours(decrementHour(endhours))}
                  />
                </div>
              </Grid>
              <Grid item xs={4} sm={4}>
                <div className="text-align">
                  <ArrowDropUpIcon
                    onClick={() => setEndMinutes(incrementMinute(endminutes))}
                  />
                </div>
                <div className="hours">{minutesend}</div>
                <div className="text-align">
                  <ArrowDropDownIcon
                    onClick={() => setEndMinutes(decrementMinute(endminutes))}
                  />
                </div>
              </Grid>
              <Grid item xs={4} sm={4}>
                <div className="marginTopTime">
                  <select id="dropdown" onChange={handleEndChange}>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={2} direction="row" className="btnright uploadBtn tymbtn">
            <Button
              type="button"
              onClick={updateSubmit}
              variant="contained"
              className="bg-blue"
            >
              Submit
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default TimePicker;
