const tableRows = [
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },
  {
    title: "Account Id",
    key: "accountId",
    align: "left",
  },
  {
    title: "Customer Name",
    key: "customerName",
    align: "left",
  },
  {
    title: "Customer E-mail",
    key: "email",
    align: "left",
  },
  {
    title: "Service Type",
    key: "serviceType",
    align: "left",
  },
  {
    title: "Billing Type",
    key: "billingType",
    align: "left",
  },
  {
    title: "Package Category",
    key: "packageCategory",
    align: "left",
  },
  {
    title: "Status",
    key: "status",
    align: "left",
  },
  {
    title: "Plan Name",
    key: "planName",
    align: "left",
  },
  {
    title: "Plan Amount",
    key: "planAmount",
    align: "left",
  },
  {
    title: "Validity",
    key: "validity",
    align: "left",
  },
  {
    title: "Created",
    key: "created",
    align: "left",
  },
];

export { tableRows };
