import React from "react";
import DistributorBanner from "@assets/images/Distributorbanner.png";
import ComputerImage from "@assets/images/distDash.png";
import ComputerImageLeft from "@assets/images/distList.png";
import ComputerImageEmp from "@assets/images/distEmp.png";
import ComputerImageTicket from "@assets/images/distTicket.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import Footer from "@components/homeFooter";

export default function Home() {
  const navigate = useNavigate();

  return (
    <div className="admin-home-container">
      <div className="main-image">
        <img className="adminBanner" src={DistributorBanner} />
      </div>
      <div className="centerDiv">
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6} className="flex-justify-center">
              <div className="rectangle">
                <p>Subscribers</p>
                <p>Employees</p>
                <p>Payment Collection Summary</p>
                <p>Monthly Payment Summary</p>
                <p>Payment Summary</p>
              </div>
            </Grid>
            <Grid item xs={6} className="text-align">
              <div className="computerImgdiv">
                <img className="computerImg" src={ComputerImage} />
              </div>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6} className="text-align">
              <div className="computerImgdiv">
                <img className="computerImg" src={ComputerImageLeft} />
              </div>
            </Grid>
            <Grid item xs={6} className="flex-justify-center">
              <div className="rectangle">
                <p>Expenses List</p>
                <p>Add Expenses</p>
                <p>Delete Expenses</p>
                <p>Inventory List</p>
                <p>Add Inventory</p>
              </div>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6} className="flex-justify-center">
              <div className="rectangle">
                <p>Employee List</p>
                <p>Add Employee</p>
                <p>Edit Employee</p>
                <p>Delete Employee</p>
                <p>Employee Status</p>
              </div>
            </Grid>
            <Grid item xs={6} className="text-align">
              <div className="computerImgdiv">
                <img className="computerImg" src={ComputerImageEmp} />
              </div>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6} className="text-align">
              <div className="computerImgdiv">
                <img className="computerImg" src={ComputerImageTicket} />
              </div>
            </Grid>
            <Grid item xs={6} className="flex-justify-center">
              <div className="rectangle">
                <p>TicketList List</p>
                <p>Add Ticket</p>
                <p>Edit Ticket</p>
                <p>Delete Ticket</p>
                <p>Ticket Status</p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div id='footercontent'>
        <Footer />
      </div>
    </div>
  );
}
