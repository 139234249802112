import React from "react";
import "./style.scss";
import Grid from '@mui/material/Grid';

export default function TicketStatus(props) {
  return (
    <>
    {
      props.ticketView ?
      <Grid className={'ticketCard' + props.className} container columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={6} sm={6} md={9} className="">
         <span className="ticketDetails" style={{color:'#00249B'}}>{props.title}</span><br/>
         <span className="ticketDetails" style={{color:'#00249B'}}>{props.count}</span>
      </Grid>
      <Grid item xs={6} sm={6} md={3} className="textaligncenter">
         <span className='ticketIcon'>{props.icon} </span>
      </Grid>
    </Grid>
      :<Grid className={'ticketCard' + props.className}>
      {props.title}
        <span className="ticketCount">{props.count}</span>
        <span className='ticketIcon'>{props.icon} </span>
      </Grid>
    }
    </> 
  );
}
