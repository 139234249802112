import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./styles.scss";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useComponentDidUpdate } from "@utils/useEffectHooks";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import emptyplaceholder from "@assets/images/image_placeholder.png";
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch, useSelector } from "react-redux";
import { imageUpload, ticketRaiseAdd, ticketHistory } from "@reducers/api";
import { useSnackbar } from "notistack";
import Resizer from "react-image-file-resizer";

import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 0,
  borderRadius: 2,
};

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const custplan = [
  { id: 1, label: "Box not working", value: true },
  { id: 2, label: "Internet connection required", value: false },
  { id: 3, label: "New connection required", value: false },
  { id: 5, label: "Pack channel not activate", value: false },
  { id: 6, label: "Payment done but box inactive", value: false },
];

const validation = Yup.object({
  custAccess: Yup.object().required("Required").nullable(),
  description: Yup.string().required("Required").nullable(),
});


export default function TicketRaise(props) {
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const [selectedValue, setSelectedValue] = React.useState("");
  const [imageResponse, setimageResponse] = React.useState(null);
  const [imgRender, setimageRender] = React.useState(emptyplaceholder);

  const { enqueueSnackbar } = useSnackbar();


  const { profileData, ticketRaiseAddData } = useSelector((state) => state.customer);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });


  const [open, setOpen] = useState(false);

  const handleClose = () => {
    props?.handleClose();
    reset();
    setimageRender(emptyplaceholder)
  };

  useComponentDidUpdate(() => {
    let user = ""
    let message = "";
    if (ticketRaiseAddData) {
      {
        message = "Ticket raised";
        props?.handleClose();
        reset();
        setimageRender(emptyplaceholder)
        let custId = user?.cust_ref_id;
        dispatch(ticketHistory(`cust_ref_id=${custId}`));
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
    }
  }, [ticketRaiseAddData]);

  const handleFormValues = (values) => {


    let payload = {
      "title": values?.custAccess?.label,
      "type": "Incident",
      "priority": "high",
      "assign_to": profileData?.associatedEmployeeData?.email_id,
      "complaint_status": "open",
      "due_date": (moment().add(5, "days").format("YYYY-MM-DDTHH:mm:ss")),
      "description": values?.description,
      "email_id": profileData?.customerData?.email_id,
      "distributor_ref_id": profileData?.associatedDistributorData?._id,
      "created_by": "user",
      "cust_ref_id": profileData?.customerData?._id,
      "user_name": profileData?.customerData?.name,
      "user_mobile_no": profileData?.customerData?.mobile_no,
      "user_address": profileData?.customerData?.area,
      "service_type": profileData?.customerData?.service_type,
      "ticket_image_id": imageResponse,
      "emp_ref_id": profileData?.associatedEmployeeData?._id,
      "staff_email": profileData?.associatedEmployeeData?.email_id,
      "staff_name": profileData?.associatedEmployeeData?.name,
      "staff_mobile_no": profileData?.associatedEmployeeData?.mobile_no,
      "complaint_forward_to": "services"
    };
    dispatch(ticketRaiseAdd(payload));
    setimageResponse(null)
  }

  const onSubmit = (data) => handleFormValues(data);




  const handleImgChange = async (event) => {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = async () => {
      const image = await resizeFile(event.target.files[0]);
      setimageRender(image)
      const dataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(",");
        const byteString =
          splitDataURI[0].indexOf("base64") >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++)
          ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
      };

      const newFile = dataURIToBlob(image);
      const formData = new FormData();   
      formData.append("files", newFile);
      const fileUploadedUrl = await dispatch(imageUpload(formData));
      setimageResponse(fileUploadedUrl?.payload?.data?.data)
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    };

  }

    useEffect(() => {
      setOpen(props?.show);
    }, [props?.show]);

    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="ticketRaise raiseHeight">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="header-raise-title justify-space-between flex-row " >
                <div>Ticket Raise</div>
                <div onClick={handleClose} className="cursor-pointer">{<CancelIcon />}</div>
              </div>
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                className="padding1rem margin"
              >
                <Grid item xs={4} sm={4} md={1.5}>
                  <div className="make-payment-form-title ">Complaint</div>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <div className="make-payment-form-title ">
                    <Controller
                      control={control}
                      name="custAccess"
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, item) => {
                            onChange(item);
                          }}
                          value={value || ""}
                          options={custplan}
                          isOptionEqualToValue={(option, value) => option.label == value.label || ""}
                          getOptionLabel={(item) => (item.label ? item.label : "")}
                          getOptionSelected={(option, value) =>
                            value === undefined ||
                            value === "" ||
                            option.id === value.id
                          }
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Radio
                                checked={selectedValue === option.label}
                                value={option.label}
                                onChange={handleChange}
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                              />
                              {option.label}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              placeholder="Select option"
                            />
                          )}
                        />
                      )}
                    />
                     {<div className="field-error">{errors.custAccess?.message}</div>}
                  </div>
                </Grid>
                <Grid item xs={0} sm={0} md={0.5}></Grid>
                <Grid item xs={0} sm={4} md={2}>
                  Upload image
                </Grid>
                <Grid item xs={0} sm={4} md={4}>
                  <TextField
                    {...register("fileupload")}
                    type="file"
                    size="small"
                    className="txtwidth"
                    onChange={(e) => handleImgChange(e)}
                    style = {{cursor: 'pointer'}}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                className="padding1rem"
              >
                <Grid item xs={4} sm={4} md={1.5}>
                  <div className="make-payment-form-title ">Remarks</div>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <div className="make-payment-form-title ">
                    <TextareaAutosize
                      {...register("description")}
                      aria-label=""
                      minRows={3}
                      placeholder=""
                      style={{ width: 200 }}
                    />
                     {<div className="field-error">{errors.description?.message}</div>}
                  </div>
                </Grid>
                <Grid item xs={0} sm={0} md={0.5}></Grid>
                <Grid item xs={0} sm={4} md={2}>
                </Grid>
                <Grid item xs={0} sm={4} md={4}>
                  <img alt="logo" src={imgRender} width={'80%'} className='ticketraiseImg'  ></img>
                </Grid>
              </Grid>
              <Grid item container spacing={4} className="padding1rem">
                <Grid item xs={12}>
                  <Stack spacing={2} direction="row" className="btnright">
                    {/* <Button size="small" variant="outlined" style={{ textTransform: "capitalize", borderColor: "#00249b", color: "#00249b" }}>
                      Reject
                    </Button> */}
                    <Button size="small" variant="contained" style={{ textTransform: "capitalize" }} className="bg-blue" type="submit">
                      Accept
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
      </div>
    );
  }
