import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./styles.scss";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { Formik } from "formik";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { tableRows } from "./data";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  currentServiceList,
  othersAll,
  SubscriberListAssigned,
  allEmpList,
  subscriberView,
  payNow,
  paymentTax,
  lastPaid,
  updateBalance
} from "@reducers/api";
import {
  useComponentDidMount,
  paramsQuery,
  useComponentDidUpdate,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import moment from "moment";
import TableView from "@components/TableView";
import { COLORS } from "@constants";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "notistack";
import { Subreset } from "@reducers/subscriber";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const validation = Yup.object({
  customerName: Yup.string().required("Required"),
  // stb: Yup.object().required("Required").nullable(),
  // amount: Yup.string().required("Required"),
  // ncf: Yup.string().required("Required"),
  // channelCount: Yup.string().required("Required"),
  // validity: Yup.string().required("Required"),
  // tax: Yup.string().required("Required"),
  // discount: Yup.string().required("Required"),
  mode: Yup.object().required("Required"),
  assignedTo: Yup.object().required("Required"),
  billAmount: Yup.number().required("Required").moreThan(0),
  // dueAmount: Yup.string().required("Required"),
  // collectionAmt: Yup.string().required("Required"),
  // collectedOn: Yup.string().required("Required"),
  // remark: Yup.string().required("Required"),
});

const internetValidation = Yup.object({
  customerName: Yup.string().required("Required"),
  // amount: Yup.string().required("Required"),
  mode: Yup.object().required("Required"),
  assignedTo: Yup.object().required("Required"),
  billAmount: Yup.number().required("Required").moreThan(0),
});

export default function PaymentModal(props) {
  const dispatch = useDispatch();
  const {
    PlanPaymentListData,
    otherAllData,
    payNowAdded,
    SubscriberListAssignedData,
    accountIDData,
    subviewData,
    paymentTaxData,
    lastPaidData,
  } = useSelector((state) => state.subscriber);
  const { enqueueSnackbar } = useSnackbar();
  const { empFullData } = useSelector((state) => state.employee);
  const [currentService, setcurrentService] = useState([]);
  const [AccountIDData, SetAccountIDData] = useState([]);
  const [SearchClose, SetSearchClose] = useState([]);
  const [value, setValueDate] = useState(new Date());
  const [checkValue, setCheckValueDate] = useState(new Date());
  const [checkedVal, setChecked] = useState(false);

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}`;
    // let distributorId = user?.distributor_ref_id;
    // dispatch(paymentAmount(distributorId));
    dispatch(allEmpList(query));
    dispatch(paymentTax(query));
    setValue("customerName", props?.listData?.name);
    setValue("adjustBalance", props?.listData?.wallet_balance || 0)
  }, []);

  const type = props?.listData?.service_type || subviewData.service_type;

  const settingsOthers = props?.otherAllData && props?.otherAllData[0] || otherAllData && otherAllData[0];

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      type === "internet" ? internetValidation : validation
    ),
  });
  const onSubmit = (data) => {
    handleFormValues(data, filtered);
  };
  let filtered = currentService?.filter(
    (serviceList) => serviceList.checked === true
  );

  let amountAdditon = filtered.reduce(function (prev, current) {
    return prev + +current.plan_amount;
  }, 0);

  let broadCastAndAlacart = filtered?.filter(
    (serviceList) =>
      serviceList.package_category === "broadcaster" ||
      serviceList.package_category === "ala cart"
  );

  let channelCountAddition = broadCastAndAlacart.reduce(function (
    prev,
    current
  ) {
    return prev + +current.channel_count;
  },
    0);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    props?.handleClose();
    reset();
    setcurrentService([]);
  };

  useEffect(() => {
    setOpen(props?.show);
    setValue("customerName", props?.listData?.name);
  }, [props?.show]);

  const doubleInputs = (titleOne, titleTwo, inputOne, inputTwo) => {
    return (
      <Grid item xs={4} sm={4} md={5}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={4} md={5.5}>
            <div className="make-payment-form-title ">{titleOne}</div>
            {inputOne}
          </Grid>
          <Grid item xs={4} sm={4} md={1}></Grid>
          <Grid item xs={4} sm={4} md={5.5}>
            <div className="make-payment-form-title ">{titleTwo}</div>
            {inputTwo}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const mode = [
    { label: "Cash", id: 1, value: "cash" },
    { label: "Cheque", id: 2, value: "cheque" },
    { label: "Card", id: 3, value: "card" },
    { label: "Free", id: 3, value: "free" },
  ];

  const validityDrop = [
    { label: "1 Month", value: 1 },
    { label: "2 Month", value: 2 },
    { label: "3 Month", value: 3 },
    { label: "4 Month", value: 4 },
    { label: "5 Month", value: 5 },
    { label: "6 Month", value: 6 },
    { label: "7 Month", value: 7 },
    { label: "8 Month", value: 8 },
    { label: "9 Month", value: 9 },
    { label: "10 Month", value: 10 },
    { label: "11 Month", value: 11 },
    { label: "12 Month", value: 12 },
  ];

  const filterObject = (title, options, stb_no) => {
    if (stb_no === "" || stb_no === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options.stb_no.toString() === stb_no.toString()
      );
    setValue(title, filtered[0]);
  };





  useComponentDidUpdate(() => {

    //setValue("customerName", props?.listData?.name);
    // filterObject("stb",  props?.listData.stb_no, props?.listData.stb_no && props?.listData?.stb_no[0]?.stb_no);
    // setValue("ncf", paymentAmtData.ncf_amount || 0);

    filterObject("validity", validityDrop, "");
    setValue("maint", 0);
    setValue("discount", 0);
    filterObject("mode", mode, "");
    filterObject("assignedTo", props?.emplist, "");
    setValue("collectionAmt", 0);
    setValue("remark", "");
    setValue("collectedOn", value);
    setValue("billAmount", watch("customerName") && lastPaidData?.due_amount || props?.lastData?.due_amount || 0);
    setValue("dueAmount", lastPaidData?.total_due_amount || props?.lastData?.total_due_amount || 0);

  }, [props]);

  useComponentDidUpdate(() => {
    setValue("customerName", props?.listData?.name);
    setValue("adjustBalance", props?.listData?.wallet_balance || 0)

  }, [props?.listData,
  props?.lastData]);


  let DiscAmt = watch("billAmount") - watch("collectionAmt");
  let Billing = watch("billAmount");

  if (isNaN(DiscAmt)) DiscAmt = 0;
  setValue("dueAmount", DiscAmt);

  if (isNaN(Billing)) {
    Billing = 0;
    setValue("billAmount", Billing);
  }

  useComponentDidUpdate(() => {
    let list = [];
    PlanPaymentListData &&
      PlanPaymentListData.map((data, index) => {
        list.push({
          plan_name: data?.plan_name,
          package_category: data?.package_category,
          channel_count: (data?.channels && data?.channels.length) || data?.channel_count || "0",
          plan_amount: data?.plan_amount,
          expired_date: moment(data?.expired_date).format("ll"),
          stb_no: data?.stb_no,
          activate_date: data?.activate_date,
          billing_cycle: data?.billing_cycle,
          billing_type: data?.billing_type,
          can_id: data?.can_id,
          channels: data?.channels,
          cust_email_id: data?.cust_email_id,
          cust_ref_id: data?.cust_ref_id,
          disabled: data?.disabled,
          distributor_ref_id: data?.distributor_ref_id,
          cust_name: data?.cust_name,
          is_active: data?.is_active,
          is_deleted: data?.is_deleted,
          mso: data?.mso,
          plan_ref_id: data?.plan_ref_id,
          plan_validity: data?.plan_validity,
          service_type: data?.service_type,
          stb_type: data?.stb_type,
          _id: data?._id,
          __v: data?.__v,
        });
      });

    setcurrentService(list);
  }, [PlanPaymentListData]);

  const handleCheckBox = (index, checked) => {
    const clonedDataService = [...currentService];
    if (index === 0 || index > 0) {
      clonedDataService[index].checked = clonedDataService[index].checked
        ? false
        : true;
    } else if (index === "all") {
      for (let services of clonedDataService) {
        if (checked) {
          services.checked = true;
          setValue("maint", settingsOthers?.maintenance_amount || 0);
        } else {
          services.checked = false;
          setValue("ncf", 0);
          setValue("maint", 0);
        }
      }
    }
    setChecked(checked)
    setcurrentService(clonedDataService);

    console.log(currentService, "serviceeeee")

    let adjustValue = watch("billAmount");

    setValue("billAmount", adjustValue);

    let filteredarray = currentService?.filter(
      (serviceList) => serviceList.checked === true
    );

    if (filteredarray.length > 0) {
      const ncfVal = getValues("ncf") || 0;
      const maintenanceVal = getValues("maint");
      const discount = getValues("discount");
      const taxfrmApi = paymentTaxData?.totalTax;

      let amountSum = filteredarray.reduce(function (prev, current) {
        return prev + +current.plan_amount;
      }, 0);

      let broadCastAndAlacartFilter = filteredarray?.filter(
        (serviceList) =>
          serviceList.package_category === "broadcaster" ||
          serviceList.package_category === "ala cart"
      );

      let packageOnly = filteredarray?.filter(
        (serviceList) => serviceList.package_category === "package"
      );

      let channelCountAdditionNcf = broadCastAndAlacartFilter.reduce(function (
        prev,
        current
      ) {
        return prev + +current.channel_count;
      },
        0);

      if (packageOnly[0]?.package_category === "package") {
        setValue("ncf", "0");
      }

      console.log(packageOnly?.length, "packageonlylength")

      if (packageOnly?.length > 0) {
        let maintCount = settingsOthers?.maintenance_amount;
        setValue("maint", maintCount * packageOnly?.length);
      }

      if (packageOnly?.length <= 0) {
        setValue("maint", 0);
      }

      if (broadCastAndAlacartFilter.length > 0) {
        let ncfCount = settingsOthers?.ncf_amount;
        setValue("ncf", ncfCount);
      }

      if (channelCountAdditionNcf > 25) {
        let ncfCount = settingsOthers?.ncf_amount * 2;
        setValue("ncf", ncfCount);
      }

      if (channelCountAdditionNcf > 50) {
        let ncfCount = settingsOthers?.ncf_amount * 3;
        setValue("ncf", ncfCount);
      }

      if (channelCountAdditionNcf > 75) {
        let ncfCount = settingsOthers?.ncf_amount * 4;
        setValue("ncf", ncfCount);
      }

      if (channelCountAdditionNcf > 75) {
        let ncfCount = settingsOthers?.ncf_amount * 4;
        setValue("ncf", ncfCount);
      }

      if (channelCountAdditionNcf > 100) {
        let ncfCount = settingsOthers?.ncf_amount * 5;
        setValue("ncf", ncfCount);
      }
      if (channelCountAdditionNcf > 125) {
        let ncfCount = settingsOthers?.ncf_amount * 6;
        setValue("ncf", ncfCount);
      }

      if (channelCountAdditionNcf > 150) {
        let ncfCount = settingsOthers?.ncf_amount * 7;
        setValue("ncf", ncfCount);
      }

      let channelCountInfo = filteredarray.reduce(function (prev, current) {
        return prev + +current.channel_count;
      }, 0);

      const taxcalc = (amountSum + watch("ncf")) * (parseInt(taxfrmApi) / 100);


  


      const finalcalc =
        parseInt(amountSum) +
        parseInt(Math.round(taxcalc)) +
        parseInt(ncfVal) +
        parseInt(maintenanceVal) -
        parseInt(discount) ;

      let previousAmt = watch("billAmount") + finalcalc;

      console.log( previousAmt,  "taxcalc")



      setValue("amount", Math.round(amountSum));
      setValue("tax", Math.round(taxcalc));
      setValue("billAmount", previousAmt);
      setValue("dueAmount", previousAmt);
      setValue("channelCount", channelCountInfo);
    } else {
      setValue("amount", 0);
      setValue("tax", 0);
      setValue("billAmount", 0);
      setValue("dueAmount", 0);
      setValue("channelCount", 0);
    }
  };

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0&value=${value}`;

    return query;
  };

  const handleAccountSearch = (value) => {
    let query = queryGenerator(value);
    dispatch(SubscriberListAssigned(query));
  };

  useComponentDidUpdate(() => {
    if (SubscriberListAssignedData) {
      SetAccountIDData(SubscriberListAssignedData);
    }
  }, [SubscriberListAssignedData]);

  useEffect(() => {
    if (accountIDData) {
      SetSearchClose(accountIDData);
    }
  }, [accountIDData]);

  const assignToClose = (value) => {
    let otherDatas = value?.service_type
    let user = JSON.parse(localStorage.getItem("user"));
    let distributorId = `${user?.distributor_ref_id}&service_type=${otherDatas}`;
    dispatch(othersAll(distributorId));
    let id = value?._id;

    setValue("accountId", value?.email_id);
    setValue("accountDetails", value);
    SetAccountIDData([]);
    dispatch(subscriberView(id));
    let paidquery = `cust_ref_id=${id}`;

    dispatch(lastPaid(paidquery));

    if (lastPaidData?.billing_status === "open") {
      enqueueSnackbar("Bill already generated for this customer ", {
        variant: "warning",
      });

    }

    if (value?.service_type === "internet") {
      let query1 = `${id}&is_active=1`;
      dispatch(currentServiceList(query1));
    }

    setValue("customerName", value?.name);

    setValue("adjustBalance", value?.wallet_balance || 0)

  };

  const handleFormValues = (values, filteredarray) => {
    let filteredBroadcast = filteredarray?.filter(
      (serviceList) => serviceList.package_category === "broadcaster"
    );

    let broadcastCount = filteredBroadcast.reduce(function (prev, current) {
      return prev + +current.plan_amount;
    }, 0);

    let filteredPackage = filteredarray?.filter(
      (serviceList) => serviceList.package_category === "package"
    );

    let packageCount = filteredPackage.reduce(function (prev, current) {
      return prev + +current.plan_amount;
    }, 0);

    let filteredAlaCart = filteredarray?.filter(
      (serviceList) => serviceList.package_category === "ala cart"
    );

    let alaCarCount = filteredAlaCart.reduce(function (prev, current) {
      return prev + +current.plan_amount;
    }, 0);

    let id = paramsQuery("id") || subviewData?._id || props?.listData._id;
    let dist_id = JSON.parse(localStorage.getItem("user"));
    let invoiceid = "#" + new Date().getTime();


    let balancePayload = {
      _id: id,
      walletPaidAmount: values?.adjustBalance,
      wallet_payment: "success"
    };

    let advanceAmnt = values?.collectionAmt > values?.billAmount
    let advanceAmntPayload = advanceAmnt === true ? Math.abs(values.dueAmount) : 0
    let dueAmtPayload = advanceAmnt === false ? values.dueAmount : 0


    let payload = {
      cust_plan_ref_id: filteredarray,
      bill_amount: values.billAmount,
      total_plan_amount: values.amount,
      maintenance_amount: values.maint,
      channel_count: values.channelCount,
      alacart_extra_amount: 1233,
      discount_amount: values.discount,
      stb_no: values?.stb?.stb_no || "",
      collected_by: values.assignedTo?.name,
      collection_amount: values.collectionAmt,
      collected_date: values?.collectedOn,
      due_amount: dueAmtPayload,
      advance_amount: advanceAmntPayload || 0,
      add_on_service_amount: 0,
      show_bill_amount: values.billAmount,
      tax: { "GST 1": values.tax },

      cust_ref_id: {
        _id: props?.listData?._id,
        name: values.customerName,
        email_id: props?.listData?.email_id,
      },
      checque_bank_name: values.bankName || "",
      checque_date: values.chequeDate || "",
      checque_no: values.chequeNo || "",
      bill_no: values.billNo,
      invoice_id: invoiceid,
      transaction_id: invoiceid,
      order_id: invoiceid,
      payment_type: values?.mode?.value || "",
      distributor_ref_id: dist_id.distributor_ref_id,
      package_amount: packageCount,
      broadcaster_amount: broadcastCount,
      ala_cart_amount: alaCarCount,
      billing_type: props?.listData?.billing_type,
      service_type: props?.listData?.service_type,
      payby_id: values.assignedTo?._id,
      remarks: values?.remark,
      payment_validity: values?.validity?.value ? values?.validity?.value : undefined,
      ncf_amount: Number(values?.ncf),
      walletPaidAmount: values?.adjustBalance,
      sms_access: dist_id?.sms_access,
      whatsapp_access: dist_id?.whatsapp_access,
      last_advance_amount: props?.lastData?.due_amount,
      last_due_amount: props?.lastData?.advance_amount,
      primaryStbNo: props?.listData?.stb_no && props?.listData?.stb_no[0]?.stb_no,
    };
    let payloadDashBoard = {
      cust_plan_ref_id: filteredarray,
      bill_amount: values.billAmount,
      total_plan_amount: values.amount,
      maintenance_amount: values.maint,
      channel_count: values.channelCount,
      alacart_extra_amount: 1233,
      discount_amount: values.discount,
      stb_no: values?.stb?.stb_no || "",
      collected_by: values.assignedTo?.name,
      collection_amount: values.collectionAmt,
      collected_date: values?.collectedOn,
      due_amount: dueAmtPayload,
      advance_amount: advanceAmntPayload || 0,
      add_on_service_amount: 0,
      show_bill_amount: values.billAmount,
      tax: { "GST 1": values.tax },

      cust_ref_id: {
        _id: subviewData._id,
        name: values.customerName,
        email_id: subviewData.email_id,
      },
      checque_bank_name: values.bankName || "",
      checque_date: values.chequeDate || "",
      checque_no: values.chequeNo || "",
      bill_no: values.billNo,
      invoice_id: invoiceid,
      transaction_id: invoiceid,
      order_id: invoiceid,
      payment_type: values?.mode?.value || "",
      distributor_ref_id: dist_id.distributor_ref_id,
      package_amount: packageCount,
      broadcaster_amount: broadcastCount,
      ala_cart_amount: alaCarCount,
      billing_type: subviewData.billing_type,
      service_type: subviewData.service_type,
      payby_id: values.assignedTo?._id,
      remarks: values?.remark,
      payment_validity: values?.validity?.value ? values?.validity?.value : undefined,
      ncf_amount: Number(values?.ncf),
      walletPaidAmount: values?.adjustBalance,
      sms_access: dist_id?.sms_access,
      whatsapp_access: dist_id?.whatsapp_access,
      last_advance_amount: lastPaidData?.due_amount,
      last_due_amount: lastPaidData?.advance_amount,
      primaryStbNo: subviewData?.stb_no && subviewData.stb_no[0]?.stb_no,
    };
    if (props?.showDashboard) {
      dispatch(payNow(payloadDashBoard));
    } else {
      dispatch(payNow(payload));
    }


    if (values?.stb?.type === "primary") {
      balancePayload['payment_validity'] = values?.validity?.value ? values?.validity?.value : filteredPackage[0]?.plan_validity
    }
    dispatch(updateBalance(balancePayload));
    reset();
    dispatch(Subreset());
  };

  const handleChange = (newDate) => {
    setValueDate(newDate);
    setValue("collectedOn", newDate);
  };

  const handleChequeChange = (newValue) => {
    setCheckValueDate(newValue);
    setValue("chequeDate", newValue);
  };


  const totalAmount = () => {
    let filteredarray = currentService?.filter(
      (serviceList) => serviceList.checked === true
    );

    let packageOnly = filteredarray?.filter(
      (serviceList) => serviceList.package_category === "package"
    );
    if (watch("validity") && watch("validity").value === 1) {
      let validationCalc = amountAdditon * 1;
      let maintananceCalc = settingsOthers?.maintenance_amount * 1 * packageOnly?.length;   
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      // if (channelCountAddition < 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount;
      //   let ncfCalculation = ncfAmoutAdd * 1;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 2;
      //   let ncfCalculation = ncfAmoutAdd * 2;
      //   setValue("ncf", ncfCalculation);
      // }
      // if (channelCountAddition > 50) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 3;
      //   let ncfCalculation = ncfAmoutAdd * 1;
      //   setValue("ncf", ncfCalculation);
      // }
      // if (channelCountAddition > 75) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 4;
      //   let ncfCalculation = ncfAmoutAdd * 2;
      //   setValue("ncf", ncfCalculation);
      // }
      // if (channelCountAddition > 100) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 5;
      //   let ncfCalculation = ncfAmoutAdd * 2;
      //   setValue("ncf", ncfCalculation);
      // }
      setValue("amount", validationCalc);
      setValue("maint", maintananceCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 2) {
      let validationCalc = amountAdditon * 2;
      let maintananceCalc = settingsOthers?.maintenance_amount * 2 * packageOnly?.length;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      // if (channelCountAddition < 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount;
      //   let ncfCalculation = ncfAmoutAdd * 2;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 2;
      //   let ncfCalculation = ncfAmoutAdd * 2;
      //   setValue("ncf", ncfCalculation);
      // }
      // if (channelCountAddition > 50) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 3;
      //   let ncfCalculation = ncfAmoutAdd * 2;
      //   setValue("ncf", ncfCalculation);
      // }
      // if (channelCountAddition > 75) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 4;
      //   let ncfCalculation = ncfAmoutAdd * 2;
      //   setValue("ncf", ncfCalculation);
      // }
      // if (channelCountAddition > 100) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 5;
      //   let ncfCalculation = ncfAmoutAdd * 2;
      //   setValue("ncf", ncfCalculation);
      // }
      setValue("amount", validationCalc);
      setValue("maint", maintananceCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 3) {
      let validationCalc = amountAdditon * 3;
      let maintananceCalc = settingsOthers?.maintenance_amount * 3 * packageOnly?.length;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      // if (channelCountAddition < 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount;
      //   let ncfCalculation = ncfAmoutAdd * 3;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 2;
      //   let ncfCalculation = ncfAmoutAdd * 3;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 50) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 3;
      //   let ncfCalculation = ncfAmoutAdd * 3;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 75) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 4;
      //   let ncfCalculation = ncfAmoutAdd * 3;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 100) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 5;
      //   let ncfCalculation = ncfAmoutAdd * 3;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 4) {
      let validationCalc = amountAdditon * 4;
      let maintananceCalc = settingsOthers?.maintenance_amount * 4 * packageOnly?.length;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );
      let filteredPackage = filteredarray?.filter(
        (serviceList) => serviceList.package_category === "package"
      );

      // if (channelCountAddition < 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount;
      //   let ncfCalculation = ncfAmoutAdd * 4;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 2;
      //   let ncfCalculation = ncfAmoutAdd * 4;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 50) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 3;
      //   let ncfCalculation = ncfAmoutAdd * 4;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 75) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 4;
      //   let ncfCalculation = ncfAmoutAdd * 4;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 100) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 5;
      //   let ncfCalculation = ncfAmoutAdd * 4;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 5) {
      let validationCalc = amountAdditon * 5;
      let maintananceCalc = settingsOthers?.maintenance_amount * 5 * packageOnly?.length;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      // if (channelCountAddition < 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount;
      //   let ncfCalculation = ncfAmoutAdd * 5;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 2;
      //   let ncfCalculation = ncfAmoutAdd * 5;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 50) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 3;
      //   let ncfCalculation = ncfAmoutAdd * 5;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 75) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 4;
      //   let ncfCalculation = ncfAmoutAdd * 5;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 100) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 5;
      //   let ncfCalculation = ncfAmoutAdd * 5;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 6) {
      let validationCalc = amountAdditon * 6;
      let maintananceCalc = settingsOthers?.maintenance_amount * 6 * packageOnly?.length;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      // if (channelCountAddition < 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount;
      //   let ncfCalculation = ncfAmoutAdd * 6;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 2;
      //   let ncfCalculation = ncfAmoutAdd * 6;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 50) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 3;
      //   let ncfCalculation = ncfAmoutAdd * 6;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 75) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 4;
      //   let ncfCalculation = ncfAmoutAdd * 6;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 100) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 5;
      //   let ncfCalculation = ncfAmoutAdd * 6;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 7) {
      let validationCalc = amountAdditon * 7;
      let maintananceCalc = settingsOthers?.maintenance_amount * 7 * packageOnly?.length;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      // if (channelCountAddition < 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount;
      //   let ncfCalculation = ncfAmoutAdd * 7;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 2;
      //   let ncfCalculation = ncfAmoutAdd * 7;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 50) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 3;
      //   let ncfCalculation = ncfAmoutAdd * 7;
      //   setValue("ncf", ncfCalculation);
      // }
      // if (channelCountAddition > 75) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 4;
      //   let ncfCalculation = ncfAmoutAdd * 7;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 100) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 5;
      //   let ncfCalculation = ncfAmoutAdd * 7;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 8) {
      let validationCalc = amountAdditon * 8;
      let maintananceCalc = settingsOthers?.maintenance_amount * 8 * packageOnly?.length;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      // if (channelCountAddition < 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount;
      //   let ncfCalculation = ncfAmoutAdd * 8;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 2;
      //   let ncfCalculation = ncfAmoutAdd * 8;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 50) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 3;
      //   let ncfCalculation = ncfAmoutAdd * 8;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 75) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 4;
      //   let ncfCalculation = ncfAmoutAdd * 8;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 100) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 5;
      //   let ncfCalculation = ncfAmoutAdd * 8;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 9) {
      let validationCalc = amountAdditon * 9;
      let maintananceCalc = settingsOthers?.maintenance_amount * 9 * packageOnly?.length;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      // if (channelCountAddition < 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount;
      //   let ncfCalculation = ncfAmoutAdd * 9;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 2;
      //   let ncfCalculation = ncfAmoutAdd * 9;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 50) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 3;
      //   let ncfCalculation = ncfAmoutAdd * 9;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 75) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 4;
      //   let ncfCalculation = ncfAmoutAdd * 9;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 100) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 5;
      //   let ncfCalculation = ncfAmoutAdd * 9;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 10) {
      let validationCalc = amountAdditon * 10;
      let maintananceCalc = settingsOthers?.maintenance_amount * 10 * packageOnly?.length;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      // if (channelCountAddition < 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount;
      //   let ncfCalculation = ncfAmoutAdd * 10;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 2;
      //   let ncfCalculation = ncfAmoutAdd * 10;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 50) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 3;
      //   let ncfCalculation = ncfAmoutAdd * 10;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 75) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 4;
      //   let ncfCalculation = ncfAmoutAdd * 10;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 100) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 5;
      //   let ncfCalculation = ncfAmoutAdd * 10;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 11) {
      let validationCalc = amountAdditon * 11;
      let maintananceCalc = settingsOthers?.maintenance_amount * 11 * packageOnly?.length;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      // if (channelCountAddition < 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount;
      //   let ncfCalculation = ncfAmoutAdd * 11;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 2;
      //   let ncfCalculation = ncfAmoutAdd * 11;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 50) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 3;
      //   let ncfCalculation = ncfAmoutAdd * 11;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 75) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 4;
      //   let ncfCalculation = ncfAmoutAdd * 11;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 100) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 5;
      //   let ncfCalculation = ncfAmoutAdd * 11;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    if (watch("validity") && watch("validity").value === 12) {
      let validationCalc = amountAdditon * 12;
      let maintananceCalc = settingsOthers?.maintenance_amount * 12 * packageOnly?.length;
      let taxCalc =
        (watch("amount") + watch("ncf")) *
        (parseInt(paymentTaxData?.totalTax) / 100);
      let filteredarray = currentService?.filter(
        (serviceList) => serviceList.checked === true
      );

      // if (channelCountAddition < 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount;
      //   let ncfCalculation = ncfAmoutAdd * 12;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 25) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 2;
      //   let ncfCalculation = ncfAmoutAdd * 12;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 50) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 3;
      //   let ncfCalculation = ncfAmoutAdd * 12;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 75) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 4;
      //   let ncfCalculation = ncfAmoutAdd * 12;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      // if (channelCountAddition > 100) {
      //   let ncfAmoutAdd = settingsOthers?.ncf_amount * 5;
      //   let ncfCalculation = ncfAmoutAdd * 12;
      //   setValue("ncf", ncfCalculation);
      //   if (
      //     filteredarray.length === 1 &&
      //     filteredarray[0]?.package_category === "package"
      //   ) {
      //     setValue("ncf", "0");
      //   }
      // }
      setValue("maint", maintananceCalc);
      setValue("amount", validationCalc);
      setValue("tax", Math.round(taxCalc));
    }

    let planAmount = Math.round(Number(watch("amount")));
    let maintanceNum = Number(watch("maint")) || 0;
    let ncfNum = Number(watch("ncf")) || 0;
    let taxCalc = (planAmount + ncfNum) * (parseInt(paymentTaxData?.totalTax) / 100);
    let taxAmount = Math.round(taxCalc) || 0;
    let discountAmount = Number(watch("discount")) || 0;
    let adjustBalValue = Number(watch("adjustBalance"));
    let prevDueAmount = Number(watch("billAmount"));

    const finalcalc =
      parseInt(planAmount) +
      parseInt(maintanceNum) +
      parseInt(ncfNum) +
      parseInt(taxAmount);

    let discountCal = finalcalc - discountAmount;
    // let previousAmtFromApi = lastPaidData?.total_due_amount || props?.lastData?.total_due_amount || 0;
    let previousAmtFromApi = lastPaidData?.due_amount || props?.lastData?.due_amount || 0;
    let prevoiusBillAmt = discountCal + parseInt(previousAmtFromApi);
    // let prevoiusBillAmt = discountCal ;
    let adjustBalAmt = prevoiusBillAmt + adjustBalValue;
    let advanceMinus = adjustBalAmt - (lastPaidData?.advance_amount || props?.lastData?.advance_amount || 0);

    setValue("billAmount", advanceMinus);
    setValue("tax", Math.round(taxCalc));

    console.log(previousAmtFromApi, "lasypadi")

  };



  useComponentDidUpdate(() => {
    totalAmount();
  }, [
    watch("ncf"),
    watch("maint"),
    watch("discount"),
    watch("validity"),
    amountAdditon,
    channelCountAddition,
    watch("adjustBalance")
  ]);










  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="makePayment-cntr payment-scroll">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <div className="payment-header-title">Make Payment</div>
              </Grid>
              {/* <Grid item xs={4}>
              <div className="flex-end">
                <CancelIcon
                  onClick={handleClose}
                  className="cursor-pointer"
                  style={{ fill: "grey" }}
                />
              </div>
            </Grid> */}
            </Grid>
            <div className="divider-line"></div>
            <div className='displayFlex'>
              <div className="rectanglemakepay">
                <p>Credit Amount : {watch("customerName") && lastPaidData?.due_amount || props?.lastData?.due_amount || 0} </p>
              </div>
              <div className="rectanglemakepay">
                <p>Advance Amount : {lastPaidData?.advance_amount || props?.lastData?.advance_amount || 0}</p>
              </div>
            </div>

            {/* {props?.listData?.service_type === "cable" && (
              <Grid item xs={12} sm={4} md={5} style={{ marginTop: '1rem' }}>
                <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={4} sm={4} md={8}>
                    <div className="make-payment-form-title ">{"Select STB"}</div>
                    <Controller
                      control={control}
                      name="stb"
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, item) => {
                            onChange(item);
                            let custId = paramsQuery("id") || props?.listData._id;
                            let query = `${custId}&stb_no=${item?.stb_no}&is_active=1`;
                            dispatch(currentServiceList(query));
                          }}
                          value={value || ""}
                          options={
                            props?.listData?.stb_no && props?.listData.stb_no
                          }
                          isOptionEqualToValue={(option) =>
                            option?.stb_no === option?.value || ""
                          }
                          getOptionLabel={(item) =>
                            item.stb_no ? `${item.stb_no}` : ""
                          }
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                        />
                      )}
                    />
                    <div className="field-error">{errors.stb?.message}</div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={1}></Grid>
                  <Grid item xs={6} sm={4} md={3}>
                    <div className="make-payment-form-title ">{""}</div>
                    <Button size="small" variant="outlined" className="btn-outlined"
                      onClick={() => {
                        let custId = paramsQuery("id") || props?.listData._id;
                        let query = `${custId}&is_active=1`;
                        dispatch(currentServiceList(query));
                      }}
                      style={{ textTransform: "none", color: "#00249b", marginTop: '1.5rem' }}>
                      Show All STB
                    </Button>
                  </Grid>

                </Grid>
              </Grid>
            )} */}
            {props?.listData?.service_type === "cable" && (
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                className="padding-btw-tiles"
              >
                <Grid item xs={4} sm={4} md={5}>


                  <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={4} sm={4} md={8}>
                      <div className="make-payment-form-title ">{"Select STB"}</div>
                      <Controller
                        control={control}
                        name="stb"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, item) => {
                              onChange(item);
                              let custId = paramsQuery("id") || props?.listData._id;
                              let query = `${custId}&stb_no=${item?.stb_no}&is_active=1`;
                              dispatch(currentServiceList(query));
                            }}
                            value={value || ""}
                            options={
                              props?.listData?.stb_no && props?.listData.stb_no
                            }
                            isOptionEqualToValue={(option) =>
                              option?.stb_no === option?.value || ""
                            }
                            getOptionLabel={(item) =>
                              item.stb_no ? `${item.stb_no}` : ""
                            }
                            renderInput={(params) => (
                              <TextField {...params} size="small" />
                            )}
                          />
                        )}
                      />
                      <div className="field-error">{errors.stb?.message}</div>
                    </Grid>
                    <Grid item xs={2} sm={4} md={1}></Grid>
                    <Grid item xs={6} sm={4} md={3}>
                      <div className="make-payment-form-title ">{""}</div>
                      <Button size="small" variant="outlined" className="btn-outlined"
                        onClick={() => {
                          let custId = paramsQuery("id") || props?.listData._id;
                          let query = `${custId}&is_active=1`;
                          dispatch(currentServiceList(query));
                        }}
                        style={{ textTransform: "none", color: "#00249b", marginTop: '1.5rem' }}>
                        Show All STB
                      </Button>
                    </Grid>

                  </Grid>

                </Grid>
                <Grid item xs={0} sm={4} md={2}></Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <div className="make-payment-form-title ">Adjust Balance</div>
                  <TextField
                    {...register("adjustBalance")}
                    size="small"
                    className="txtwidth"
                    variant="outlined"
                    id="outlined-basic"
                  />
                </Grid>
              </Grid>
            )}
            {props?.listData?.service_type === "internet" && (
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                className="padding-btw-tiles"
              >



                <Grid item xs={4} sm={4} md={5}>
                  <div className="make-payment-form-title payment-title-padding">
                    Adjust Balance Amount
                  </div>
                  <TextField
                    {...register("adjustBalance")}
                    size="small"
                    className="txtwidth"
                    variant="outlined"
                    id="outlined-basic"
                  />
                </Grid>
              </Grid>
            )}

            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="padding-btw-tiles"
            >
              <Grid item xs={4} sm={4} md={5} className="">
                {props?.showAccountId && (
                  <div>
                    <div className="make-payment-form-title ">
                      Search By Account Id
                    </div>
                    <TextField
                      {...register("accountId")}
                      size="small"
                      className="txtwidth"
                      variant="outlined"
                      id="outlined-basic"
                      onChange={(e) => {
                        handleAccountSearch(e.target.value);
                        setValue("accountId", e.target.value);
                        reset({
                          stb: "",
                          amount: "",
                          billAmount: "",
                          dueAmount: "",
                          tax: "",
                          channelCount: "",
                        });
                        // reset()
                        setcurrentService([]);
                      }}
                    />
                    {AccountIDData && AccountIDData?.data?.length > 0 && (
                      <div className={"AssignSearchResultsAssign"}>
                        {AccountIDData?.data?.map((prod, i) => (
                          <Grid
                            key={i}
                            className={"searchresult"}
                            container
                            onClick={() => assignToClose(prod)}
                          >
                            {prod.email_id} <span>({prod.name})</span>
                          </Grid>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </Grid>


              <Grid item xs={0} sm={4} md={2}></Grid>

              {props?.showAccountId && (
                <Grid item xs={4} sm={4} md={5}>
                  <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={4} sm={4} md={5}>
                      {
                        subviewData?.service_type === "cable" ? (
                          <div className="make-payment-form-title ">Select STB</div>
                        )
                          :
                          (
                            <div className="make-payment-form-title "> </div>
                          )
                      }
                      {subviewData?.service_type === "cable" && (
                        <Grid>

                          <Controller
                            control={control}
                            name="stb"
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                onChange={(event, item) => {
                                  onChange(item);
                                  let custId = subviewData?._id;
                                  let query = `${custId}&stb_no=${item?.stb_no}&is_active=1`;
                                  dispatch(currentServiceList(query));
                                }}
                                value={value || ""}
                                options={subviewData?.stb_no}
                                isOptionEqualToValue={(option) =>
                                  option?.stb_no === option?.value || ""
                                }
                                getOptionLabel={(item) =>
                                  item.stb_no ? `${item.stb_no}` : ""
                                }
                                renderInput={(params) => (
                                  <TextField {...params} size="small" />
                                )}
                              />
                            )}
                          />
                        </Grid>

                      )
                      }

                    </Grid>
                    <Grid item xs={4} sm={4} md={0.5}></Grid>
                    <Grid item xs={4} sm={4} md={2.5}>
                      <div className="make-payment-form-title ">{""}</div>
                      {
                        subviewData?.service_type === "cable" && (
                          <Button size="small" variant="outlined" className="btn-outlined"
                            onClick={() => {
                              let custId = subviewData?._id;
                              let query = `${custId}&is_active=1`;
                              dispatch(currentServiceList(query));
                            }}
                            style={{ textTransform: "none", color: "#00249b", marginTop: '1.5rem' }}>
                            Show All
                          </Button>
                        )

                      }

                    </Grid>

                    <Grid item xs={4} sm={4} md={4}>
                      <div className="make-payment-form-title ">Adjust Balance</div>
                      <TextField
                        {...register("adjustBalance")}
                        size="small"
                        className="txtwidth"
                        variant="outlined"
                        id="outlined-basic"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>

            {currentService.length > 0 && (
              <TableView
                rows={tableRows}
                data={currentService}
                color={COLORS.primary}
                handleCheckBoxChange={handleCheckBox}
                checkedVal={checkedVal}
              />
            )}

            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="padding-btw-tiles"
            >
              <Grid item xs={4} sm={4} md={5}>
                <div className="make-payment-form-title payment-title-padding">
                  Customer Name
                </div>

                <TextField
                  {...register("customerName")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
                <div className="field-error">
                  {errors.customerName?.message}
                </div>
              </Grid>
              <Grid item xs={0} sm={4} md={2}></Grid>

              {
                currentService[0]?.plan_validity === "1 months" && (
                  <Grid item xs={4} sm={4} md={5}>
                    <div className="make-payment-form-title payment-title-padding">
                      Validity
                    </div>
                    <Controller
                      control={control}
                      name="validity"
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, item) => {
                            onChange(item);
                          }}
                          value={value || ""}
                          options={validityDrop}
                          isOptionEqualToValue={(option) =>
                            option?.label === option?.value || ""
                          }
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                        />
                      )}
                    />
                  </Grid>
                )
              }


            </Grid>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="padding-btw-tiles"
            >
              <Grid item xs={4} sm={4} md={5}>
                <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={4} sm={4} md={5.5}>
                    <div className="make-payment-form-title ">{"Amount"}</div>
                    <TextField
                      disabled
                      {...register("amount")}
                      size="small"
                      className="txtwidth"
                      variant="outlined"
                      id="outlined-basic"
                    />
                    <div className="field-error">{errors.amount?.message}</div>
                  </Grid>
                  <Grid item xs={4} sm={4} md={1}></Grid>
                  <Grid item xs={4} sm={4} md={5.5}>
                    <div className="make-payment-form-title ">{"NCF"}</div>
                    <TextField
                      {...register("ncf")}
                      type="number"
                      size="small"
                      className="txtwidth"
                      variant="outlined"
                      id="outlined-basic"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={0} sm={4} md={2}></Grid>

              <Grid item xs={4} sm={4} md={5}>
                <div className="make-payment-form-title">Channel Count</div>
                <TextField
                  {...register("channelCount")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
              </Grid>
            </Grid>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="padding-btw-tiles"
            >
              {doubleInputs(
                "Tax",
                "Discount",
                <TextField
                  disabled
                  {...register("tax")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                  type="number"
                />,
                <TextField
                  {...register("discount")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                  type="text"
                />
              )}
              <Grid item xs={0} sm={4} md={2}></Grid>

              <Grid item xs={4} sm={4} md={5}>
                <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={4} sm={4} md={5.5}>
                    <div className="make-payment-form-title ">{"Mode"}</div>
                    <Controller
                      control={control}
                      name="mode"
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, item) => {
                            onChange(item);
                          }}
                          value={value || ""}
                          options={mode}
                          isOptionEqualToValue={(option) =>
                            option?.label === option?.value || ""
                          }
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                        />
                      )}
                    />
                    <div className="field-error">{errors.mode?.message}</div>
                  </Grid>
                  <Grid item xs={4} sm={4} md={1}></Grid>
                  <Grid item xs={4} sm={4} md={5.5}>
                    <div className="make-payment-form-title ">
                      {"Collected By"}
                    </div>
                    <Controller
                      control={control}
                      name="assignedTo"
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, item) => {
                            onChange(item);
                          }}
                          value={value || ""}
                          options={empFullData || []}
                          isOptionEqualToValue={(option, value) =>
                            option.name === value.name
                          }
                          getOptionLabel={(item) =>
                            item.name ? `${item.name}` : ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              placeholder="Select Assign To"
                            />
                          )}
                        />
                      )}
                    />
                    <div className="field-error">
                      {errors.assignedTo?.message}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="padding-btw-tiles"
            >
              <Grid item xs={4} sm={4} md={5}>
                <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={4} sm={4} md={5.5}>
                    <div className="make-payment-form-title ">
                      {"Bill Amount"}
                    </div>
                    <TextField
                      disabled
                      {...register("billAmount")}
                      size="small"
                      className="txtwidth"
                      variant="outlined"
                      id="outlined-basic"
                    />
                    <div className="field-error">
                      {errors.billAmount?.message}
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4} md={1}></Grid>
                  <Grid item xs={4} sm={4} md={5.5}>
                    <div className="make-payment-form-title ">
                      {"Due Amount"}
                    </div>
                    <TextField
                      disabled
                      {...register("dueAmount")}
                      size="small"
                      className="txtwidth"
                      variant="outlined"
                      id="outlined-basic"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={0} sm={4} md={2}></Grid>
              {doubleInputs(
                "Collection Amount",
                "Collected On",
                <TextField
                  {...register("collectionAmt")}
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />,
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    value={value}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...register("collectedOn")}
                        placeholder="Enter Date"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            </Grid>

            {watch("mode") && watch("mode").value === "cheque" && (
              <div>
                <Grid
                  container
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  className="padding-btw-tiles"
                >
                  {doubleInputs(
                    "Cheque no",
                    "Cheque Date",
                    <TextField
                      {...register("chequeNo")}
                      size="small"
                      className="txtwidth"
                      variant="outlined"
                      id="outlined-basic"
                    />,
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="MM/dd/yyyy"
                        value={checkValue}
                        onChange={handleChequeChange}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            size="small"
                            {...register("chequeDate")}
                            placeholder="Enter Date"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  <Grid item xs={0} sm={4} md={2}></Grid>

                  <Grid item xs={4} sm={4} md={5}>
                    <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                      <Grid item xs={4} sm={4} md={5.5}>
                        <div className="make-payment-form-title ">
                          {"Bank Name"}
                        </div>
                        <TextField
                          {...register("bankName")}
                          size="small"
                          className="txtwidth"
                          variant="outlined"
                          id="outlined-basic"
                        />
                      </Grid>
                      <Grid item xs={4} sm={4} md={1}></Grid>
                      <Grid item xs={4} sm={4} md={5.5}></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}

            <Grid item xs={12} sm={4} md={5}>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4} sm={4} md={5.5}>
                  <div className="make-payment-form-title ">
                    {"Maintenance"}
                  </div>
                  <TextField
                    {...register("maint")}
                    size="small"
                    className="txtwidth"
                    variant="outlined"
                    id="outlined-basic"
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={1}></Grid>
                {watch("mode") && watch("mode").value === "card" && (
                  <Grid item xs={4} sm={4} md={5.5}>
                    <div className="make-payment-form-title ">{"Bill No"}</div>
                    <TextField
                      {...register("billNo")}
                      size="small"
                      className="txtwidth"
                      variant="outlined"
                      id="outlined-basic"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid container className="padding-btw-tiles">
              <div className="make-payment-form-title ">Remarks</div>
              <TextField
                {...register("remark")}
                size="small"
                className="txtwidth"
                variant="outlined"
                id="outlined-basic"
              />
            </Grid>

            <Grid container className="padding-btw-tiles flex-end">
              <Stack
                direction="row"
                className="flex-end btns--pay-stack"
                spacing={2}
              >
                <Button
                  size="small"
                  variant="outlined"
                  className="btn-outlined"
                  onClick={handleClose}
                  style={{ textTransform: "none", color: "#00249b" }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className="bg-blue"
                  style={{ textTransform: "none" }}
                  size="small"
                >
                  Pay Now
                </Button>
              </Stack>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
