import { useState } from "react";
import "./styles.scss";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useComponentDidMount, useComponentDidUpdate } from "@utils/useEffectHooks";
import SearchWithButtons from "@components/SearchWithButtons";
import { useNavigate } from "react-router-dom";
import { subscriberPlanList, deletePlan, planListsExport, broadcasterAdmin } from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import PlanListFilter from "@components/PlanListFilter";
import { Grid } from "@mui/material";
import BreadCrumb from "@components/Breadcrumb";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/plan";

const serviceType = [
  { label: "Cable", value: "cable" },
  { label: "Internet", value: "internet" },
];

const billingTypes = [
  { label: "Pre Paid", value: "pre paid" },
  { label: "Post Paid", value: "post paid" },

];

const category = [
  { label: "Package", value: "package" },
  { label: "Ala Cart", value: "ala cart" },
  { label: "Broadcaster", value: "broadcaster" },

];



export default function PlanListSubscriber() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subscriberPlanListData } = useSelector((state) => state.subscriber);
  const { planDeleted, planListsExportData, broadcasterAdminData } = useSelector((state) => state.plan);
  const [broadQuery, setBroadQuery] = useState("");
  const [subscriberList, setSubscriberList] = useState([]);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [filterOne, setFirstQuery] = useState("");
  const [filtertwo, setSecondQuery] = useState("");
  const [filterthree, setThirdQuery] = useState("");



  const handleRedirect = () => {
    navigate("/distributor/add-subscriber-plan");
  };

  useComponentDidUpdate(() => {        
    if (planListsExportData) {
      if(planListsExportData.fileData){
        window.location.href = `data:application/vnd.ms-excel;base64,${planListsExportData.fileData}`
      }
      if(planListsExportData?.msg==="no data found"){
        enqueueSnackbar("No data....!", {
          variant: "error",
        });
      }      
      dispatch(reset())
    }
  }, [planListsExportData]);


  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;
    if (value?.type === "search") {
      query += `&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };

  useComponentDidMount(() => {
    let query = queryGenerator();
    dispatch(subscriberPlanList(query));
    dispatch(broadcasterAdmin());
  });



  const handleClickSearcch = (priorityval, statusval, categoryval, broadcastCat) => {
    setPage(1)
    let user = JSON.parse(localStorage.getItem("user"));
    let distributorId = user?.distributor_ref_id;
    let query = "";

    if (priorityval) {
      query += `&service_type=${priorityval?.value}`
    }
    if (statusval) {
      query += `&billing_type=${statusval?.value}`
    }
    if (categoryval) {
      query += `&package_category=${categoryval?.value}`
    }
    if (broadcastCat) {
      query += `&broadcaster=${broadcastCat}`
    }
    dispatch(subscriberPlanList(`limit=10&offset=0&distributor_ref_id=${distributorId}${query}`));
    setFirstQuery(priorityval?.value);
    setSecondQuery(statusval?.value);
    setThirdQuery(categoryval?.value);
    setBroadQuery(broadcastCat)
    
  };

  const handleChange = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let distributorId = user?.distributor_ref_id;
    dispatch(subscriberPlanList(`distributor_ref_id=${distributorId}&limit=10&offset=${value - 1}0${searchQuery ? `&value=${searchQuery}` : ""}${filterOne ? `&service_type=${filterOne}` : ""}${filtertwo ? `&billing_type=${filtertwo}` : ""}${filterthree ? `&package_category=${filterthree}` : ""}${broadQuery ? `&broadcaster=${broadQuery}` : ""}`));
  };

  const handleReset = () => {
    let query = queryGenerator();
    dispatch(subscriberPlanList(query));
    setFirstQuery("");
    setSecondQuery("");
    setThirdQuery("");
    setBroadQuery("")
    setPage(1);
  };

  const deletePlanList = (value) => {

    dispatch(
      deletePlan({
        _id: value,
      })
    );
  };

  const handleExtraExportClick = (path) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(planListsExport(`distributor_ref_id=${user?.distributor_ref_id}&distributor=1`));
  };

  useComponentDidUpdate(() => {
    if (planDeleted) {
      enqueueSnackbar("Plan Deleted !", {
        variant: "success",
      });
      setSearchQuery("");
      dispatch(reset());
      let query = queryGenerator();
    dispatch(subscriberPlanList(query));
    }
  }, [planDeleted]);

  useComponentDidUpdate(() => {
    let list = [];
    let listForExport = [];
    subscriberPlanListData?.data.map((data, index) => {
      list.push({
        sNo: (page - 1) * 10 + index + 1,
        planName: data?.plan_name,
        planAmount: data?.plan_amount,
        serviceType: data?.service_type,
        sdhd: data?.channel_type,
        validity: data?.plan_validity,
        billingType: data?.billing_type,
        category: data?.package_category,
        status: data?.is_active ? <div className="status-btn-table-active"> Active </div> : <div className="status-btn-table-inactive"> InActive </div>,
        action: (
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={6} sm={6} md={6} className="textaligncenter ticketviewheader">
              {data?.package_category === 'package' &&
                <div onClick={() => navigate(`/distributor/add-subscriber-plan?id=${data._id}`)} className="cur-pointer">
                  <EditIcon />
                </div>}
            </Grid>
            <Grid item xs={6} sm={6} md={6} className="textaligninitial ticketviewheader">
              <div
                className="cur-pointer"
                onClick={() =>
                  deletePlanList(data?._id)

                }
              >
                <DeleteIcon />
              </div>
            </Grid>
          </Grid>
        ),
      });
      listForExport.push({
        sNo: (page - 1) * 10 + index + 1,
        planName: data?.plan_name,
        planAmount: data?.plan_amount,
        serviceType: data?.service_type,
        sdhd: data?.channel_type,
        validity: data?.plan_validity,
        billingType: data?.billing_type,
        category: data?.package_category,
      });
    });

    setSubscriberList(list);
    
  }, [subscriberPlanListData]);

  const handleSearch = (search) => {

    setSearchQuery(search);
    let query = queryGenerator({ type: "search", state: search });
    dispatch(subscriberPlanList(query));
  };


  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  return (
    <div className="planSubList-container ticketList-container">
      
      <Grid container spacing={2}>
        <Grid item xs={8}>

        </Grid>
        <Grid item xs={12} md={4} className="flex-end">
          <div className='planlist-title'>
            <BreadCrumb 
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              secondaryHeading="Subscriber Plan"
              handleSecondaryClick={() => handleBreadCrumbRedirect("subscriber-plan")}
              activeHeading="Plan List"
              handleActiveClick={() => handleBreadCrumbRedirect("subscriber-plan-list")}
            />
          </div>

        </Grid>
      </Grid>
      <div className="ticket-list-table-cntr">
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <div className="planlist-title">Plan List</div>
        </Grid>
        <Grid item xs={4} className="flex-end">
          <div className="employeelist-title">
          <PlanListFilter
              labelPrimary={"Service Type"}
              PrimaryOption={serviceType}
              SecondaryOption={billingTypes}
              labelSecondary={"Billing Type"}
              broadcast={"Broadcast Category"}
              broadCastOption={broadcasterAdminData}
              labelThird={"Package Category"}
              thirdOption={category}
              handleResetClick = {handleReset}
              handleFormValues={(data1, data2, data3, data4, data5) => handleClickSearcch(data1, data2, data3, data4, data5)} />
          </div>

        </Grid>
      </Grid>

        <div className="search-btns-padding">
          <SearchWithButtons
            primaryButton={{
              title: "Add Plan",
              type: "contained",
              color: "white",
              bgColor: COLORS.primary,
            }}
            secondryExtraButtonExportData={{
              title: "Export",
              type: "contained",
              color: COLORS.primary,
              bgColor: COLORS.primary,
            }}
            handleExtraExportClick={()=>handleExtraExportClick("export")}
            handlePrimaryClick={handleRedirect}
            // handleSecondaryClick={}
            handleChange={handleSearch}
          />
        </div>
        <div style={{ fontWeight: 'bold', fontSize: '15px', paddingBottom: '10px' }}>Showing entries {(page - 1) * 10 + 1} - {Math.ceil(subscriberPlanListData?.totalCount / 10) === page ? subscriberPlanListData?.totalCount : page + "0"} of {subscriberPlanListData?.totalCount}</div>
        <TableView rows={tableRows} data={subscriberList} color={COLORS.primary} />
        {/* </div> */}
        <div style={{ marginTop: 20, justifyContent: "flex-end", display: "flex" }}>
          <Pagination
            count={Math.ceil(subscriberPlanListData?.totalCount / 10) || 0}
            defaultPage={1}
            page={page}
            onChange={(event, value) => {
              setPage(value);
              handleChange(value);
            }}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
      <div className="copyrightsText">
        <p>© Copyrights CablePay Solutions India Pvt Ltd.2018.All rights reserved</p>
      </div>
    </div>
  );
}
