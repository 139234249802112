const tableRows = [
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },

  {
    title: "Account ID",
    key: "accountid",
    align: "left",
  },
  {
    title: "MSO ID",
    key: "msqid",
    align: "left",
  },
  {
    title: "STB NO",
    key: "stbno",
    align: "left",
  },
  {
    title: "Customer Name",
    key: "customerName",
    align: "left",
  },
  {
    title: "Customer E-mail",
    key: "customerEmail",
    align: "left",
  },

  {
    title: "Sub Area",
    key: "subarea",
    align: "left",
  },
  {
    title: "Flat No",
    key: "flatno",
    align: "left",
  },
  {
    title: "Collection Amount",
    key: "collectionAmount",
    align: "left",
  },
  {
    title: "Due Amount",
    key: "dueAmount",
    align: "left",
  },
  {
    title: "Discount",
    key: "discount",
    align: "left",
  },
  {
    title: "Advance Amount",
    key: "advance",
    align: "left",
  },
  {
    title: "Payment Type",
    key: "paymentType",
    align: "left",
  },
  {
    title: "Service Type",
    key: "serviceType",
    align: "left",
  },
  {
    title: "Status",
    key: "status",
    align: "left",
  },
  {
    title: "Paid By",
    key: "paidBy",
    align: "left",
  },
  {
    title: "Created Date",
    key: "createdDate",
    align: "left",
  },
  {
    title: "Completed Date",
    key: "completedDate",
    align: "left",
  },
  {
    title: "Remarks",
    key: "remarks",
    align: "left",
  },
  {
    title: "Action",
    key: "action",
    align: "left",
    justify: "center",
  },
];

export { tableRows };
