import { BrowserRouter, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Login from "@routes/login";
import ForgotPsw from "@routes/forgot-password";
import Home from "@routes/home";
import { adminRoutes, distributorRoutes, customerRoutes, subadminRoutes, homeRoutes } from "@services/routerService";
import AdminLayout from "@container/adminLayout";
import DistributorLayout from "@container/distributorLayout";
import CustomerLayout from "@container/customerLayout";
import HomeLayout from "@container/homeLayout";
import { useIdleTimer } from "react-idle-timer";

import Test from "./test";
import { Fragment, useEffect } from "react";

function AdminRoutes() {
  return (
    <Fragment>
      <Routes>
        <Route path="*" element={<div>Not Found</div>} />
        {adminRoutes &&
          adminRoutes.map((route, key) => (
            <Route
              path={route.path}
              element={
                <AdminLayout>
                  <route.component />
                </AdminLayout>
              }
              key={key}
            />
          ))}
      </Routes>
    </Fragment>
  );
}

function DistributorRoutes() {
  return (
    <Routes>
      <Route path="*" element={<div>Not Found</div>} />]
      {distributorRoutes &&
        distributorRoutes.map((route, key) => (
          <Route
            path={route.path}
            element={
              <DistributorLayout>
                <route.component />
              </DistributorLayout>
            }
            key={key}
          />
        ))}
    </Routes>
  );
}

function CustomerRoutes() {
  return (
    <Routes>
      <Route path="*" element={<div>Not Found</div>} />]
      {customerRoutes &&
        customerRoutes.map((route, key) => (
          <Route
            path={route.path}
            element={
              <CustomerLayout>
                <route.component />
              </CustomerLayout>
            }
            key={key}
          />
        ))}
    </Routes>
  );
}

function HomeRoutes() {
  return (
    <Routes>
      <Route path="*" element={<div>Not Found</div>} />]
      {homeRoutes &&
        homeRoutes.map((route, key) => (
          <Route
            path={route.path}
            element={
              <HomeLayout>
                <route.component />
              </HomeLayout>
            }
            key={key}
          />
        ))}
    </Routes>
  );
}
const AllRoutes = () => {
  const history = useLocation();
  const navigate = useNavigate();

  const handleOnIdle = (event) => {
    localStorage.clear();
    navigate(`/login`);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 900000,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    console.log(`You changed the page to:`);
  }, [history]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPsw />} />
      <Route path="/test" element={<Test />} />
      <Route path="admin/*" element={<AdminRoutes />} />
      <Route path="distributor/*" element={<DistributorRoutes />} />
      <Route path="customer/*" element={<CustomerRoutes />} />
      <Route path="home/*" element={<HomeRoutes />} />
      <Route path="*" element={<div>Not Found</div>} />
    </Routes>
  );
};

const MainApp = () => (
  <BrowserRouter>
    <AllRoutes />
  </BrowserRouter>
);

export default MainApp;
