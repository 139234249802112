import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./styles.scss";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { Formik } from "formik";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useComponentDidUpdate } from "@utils/useEffectHooks";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableRowsChannelList } from "./data";
import TableView from "@components/TableView";
import { COLORS } from "@constants";
import Stack from "@mui/material/Stack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

  const data = [
    {
      packagechannellist: "KA PREMIUM",
      totalchannel:"Total channel : 25"
    },
    {
      packagechannellist: "KA PREMIUM",
    },
    {
      packagechannellist: "KA PREMIUM",
    },
    {
      packagechannellist: "KA PREMIUM",
    },
    {
      packagechannellist: "KA PREMIUM",
    },
    {
      packagechannellist: "KA PREMIUM",
    },
    {
      packagechannellist: "KA PREMIUM",
    },
    {
      packagechannellist: "KA PREMIUM",
    },
    {
      packagechannellist: "KA PREMIUM",
    },
    {
      packagechannellist: "KA PREMIUM",
    },
  ];

export default function PaymentModal(props) {
  const [planList, setEnqList] = useState([]);
  const [currentService, setcurrentService] = useState(data);



  const [open, setOpen] = useState(false);

  const handleClose = () => {
    props?.handleClose();
  };

  useEffect(() => {
    setOpen(props?.show);
  }, [props?.show]);
  
  return (
    <div className="tableviewchannelsheight">      
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="makePayment-cntr channel-list listview">
            <TableView
              rows={tableRowsChannelList}
              data={props?.channelData}
              color={COLORS.primary}
              //handleCheckBoxChange={handleCheckBox}
            />
            <Stack style={{ display:'flex',justifyContent:'flex-end',marginTop:10}} spacing={2} direction="row" className=" ">
          <Button
            onClick={() => props?.handleClose()}
            type="submit"
            variant="contained"
            className="bg-blue"
          >
            OK
          </Button>
        </Stack>
          </Box>
        </Modal>
    </div>
  );
}
