export const broadcast = [
    {
        name: "ZEE THIRAI",
        price: "RS.5"
    },
    {
        name: "ZEE PICCHAR",
        price: "RS.3"
    },
    {
        name: "STAR GOLD 2",
        price: "RS.1"
    },
    {
        name: "STAR MAA MOVIES HD",
        price: "RS.19"
    },
    {
        name: "ZEE THIRAI",
        price: "RS.5"
    },
    {
        name: "ZEE PICCHAR",
        price: "RS.3"
    },
    {
        name: "STAR GOLD 2",
        price: "RS.1"
    },
    {
        name: "STAR MAA MOVIES HD",
        price: "RS.19"
    },
];
