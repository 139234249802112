import React from "react";
import ExpensesForm from "@components/ExpensesForm";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import "./style.scss";
import { useComponentDidMount, paramsQuery, useComponentDidUpdate } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { expensesAdd, allEmpList, expenseList, expensesUpdate} from "@reducers/api";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import BreadCrumb from "@components/Breadcrumb";

export default function Expenses(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { empFullData } = useSelector((state) => state.employee);
  const { expenseListData, expensesAddData } = useSelector((state) => state.expenses);
  let filtered = expenseListData?.data?.length && expenseListData?.data.filter((data) => data._id === paramsQuery("id"));

  // useComponentDidMount(() => {
  //   let user = JSON.parse(localStorage.getItem("user"));
  //   dispatch(allEmpList(`distributor_ref_id=${user?.distributor_ref_id}`));
  //   dispatch(expenseList(`distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`));
  // }, []);


  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;

    return query;
  };

    useComponentDidMount(() => {
    let id = paramsQuery("id");
  
    let query = queryGenerator();
    dispatch(allEmpList(query));
    if (id) return     
    dispatch(expenseList());
  }, []);

  useComponentDidUpdate(() => {
    let id = paramsQuery("id");
    let message = "";
    if (expensesAddData) {
      if (id) {
        message = "Enquiry Updated";
      } else {
        message = "Expenses Added";
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/distributor/expenses-list`);
    }
  }, [expensesAddData]);

  const handleFormValues = (values) => {
    let id = paramsQuery("id");
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      "assigned_to": values?.assigned_to?.email_id,
      "expense_name": values?.expense_name,
      "expense_amount": values?.expense_amount,
      "expense_type": values?.expense_type?.value,
      "description": values?.description,
      "emp_ref_id": values?.assigned_to?._id,
      "staff_email": values?.assigned_to?.email_id,
      "staff_name": values?.assigned_to?.name,
      "complaint_forward_to": "payments",
      "distributor_ref_id": user?.distributor_ref_id,
    };
    if (id) {
      payload["_id"] = id;
      dispatch(expensesUpdate(payload));
    } else {
      dispatch(expensesAdd(payload));
    }
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };


  return (

      
    <div className="expenses-container">
      
     
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
        <div className="expenseHeading ">
        
        {/* <PictureAsPdfIcon className="addEmployee-icon" /> */}
        <span>Expense</span>
        
      </div>
        </Grid>
        <Grid item xs={12} md={4} className="flex-end">
          <div className="employeelist-title">
          <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              secondaryHeading="Expense List"
              handleSecondaryClick={() => handleBreadCrumbRedirect("expenses-list")}
              activeHeading={paramsQuery("id") ? "Edit Expense" : "Add Expense"}
            />
          </div>

        </Grid>
      </Grid>
      <div className="expense-form-padding">
        <ExpensesForm handleFormValues={(values) => handleFormValues(values)} expenseListData={filtered && filtered[0]} employeeData={empFullData} />
      </div>
    </div>
  );
}
