import "./style.scss";
import Grid from "@mui/material/Grid";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import { COLORS } from "@constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddInventeryPopup from "@components/Modals/inventeryAddPopup";
import SearchWithButtons from "@components/SearchWithButtons";
import { useNavigate } from "react-router-dom";
import {
  useComponentDidMount,
  useComponentDidUpdate,
} from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { inventryList, deleteInventry } from "@reducers/api";
import { useState } from "react";
import { useSnackbar } from "notistack";
import Pagination from "@mui/material/Pagination";
import BreadCrumb from "@components/Breadcrumb";
import { reset } from "@reducers/inventry";
import moment from "moment";
import MultipleStb from "@components/Modals/bulkAddOnTv";
import SubscriberFilter from "@components/InventoryFilter";


const boxType = [
  { label: "HD", value: "HD" },
  { label: "SD", value: "SD" },
];

const ServiceType = [
  { label: "Cable", value: "cable" },
  { label: "Internet", value: "internet" },
];

const providerOptions = [
  { label: "MSO", value: "mso" },
  { label: "ISP", value: "isp" },
];

const options2 = [
  { label: "STB", value: "stb" },
  { label: "Hardware", value: "hardware" },
  { label: "Onu", value: "onu" },
  { label: "Ont", value: "ont" },
  { label: "Wifi Router", value: "wifi router" },
  { label: "Patch Card Blue", value: "patch card blue" },
  { label: "Patch Card Green", value: "patch card green" },
  { label: "Shifting Charges", value: "shifting charges" },
  { label: "Reconnection Charges", value: "reconnection charges" },
  { label: "Replacement Charges", value: "replacement charges" },
  { label: "STB Box Service Charges", value: "stb box service charges" },
  { label: "STB Box Replacement Charges", value: "stb box replacement charges" },
];

const Validity = [
  { label: "1 Month", value: "1 months" },
  { label: "3 Month", value: "3 months" },
  { label: "6 Month", value: "6 months" },
  { label: "1 Year", value: "1 year" },
];

const payOption = [
  { label: "Free", value: "free" },
  { label: "Paid", value: "paid" },

];

const stbOption = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },

];

export default function Ticketing(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { inventoryListData, inventoryDeleted } = useSelector(
    (state) => state.inventory
  );
  const [enqList, setEnqList] = useState([]);
  const [page, setPage] = useState(1);
  const [showAddInventory, setShowAddInventory] = useState(false);
  const [inventoryListExport, setInventoryListExport] = useState([]);
  const [invListData, setInvListData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemProvider, setItemQuery] = useState("");
  const [serviceQuery, setServiceQuery] = useState("");
  const [billingQuery, setBillingQuery] = useState("");
  const [typeQuery, setTypeQuery] = useState("");
  const [payQuery, setPayQuery] = useState("");
  const [stbSupplyQuery, setSupplyQuery] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [addOnopen, setAddOnOpen] = useState(false);
  const handleAddOnOpen = () => setAddOnOpen(true);
  const handleAddOnClose = () => setAddOnOpen(false);
  const defaultPlanQuery = {
    serviceType: "cable",
  };

  const handleFormValues = (status, validity, serviceType, billingType, billgen, paymentCat, stbsupply) => {
    setPage(1);
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=0`;
    if (status) {
      query += `&itemProviderName=${status?.value}`
    }

    if (serviceType) {
      query += `&service_type=${serviceType?.value}`
    }
    if (billingType) {
      query += `&hardware_type=${billingType?.value}`
    }
    if (billgen) {
      query += `&inventory_type=${billgen?.value}`
    }
    if (paymentCat) {
      query += `&stb_payment_category=${paymentCat?.value}`
    }
    if (stbsupply) {
      query += `&stb_supplied=${stbsupply?.value}`
    }

    dispatch(inventryList(query));
    setItemQuery(status?.value);
    setServiceQuery(serviceType?.value);
    setBillingQuery(billingType?.value)
    setTypeQuery(billgen?.value)
    setPayQuery(paymentCat?.value)
    setSupplyQuery(stbsupply?.value)
  };

  const handleReset = () => {
    let query = queryGenerator();
    dispatch(inventryList(query));
    setItemQuery("");
    setServiceQuery("");
    setBillingQuery("")
    setTypeQuery("")
    setPayQuery("")
    setSupplyQuery("")
    setPage(1);
  };

  const handleChange = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));

    dispatch(
      inventryList(
        `distributor_ref_id=${user?.distributor_ref_id}&limit=10&offset=${value - 1}0${searchQuery ? `&value=${searchQuery}` : ""}${itemProvider ? `&itemProviderName=${itemProvider}` : ""}${serviceQuery ? `&service_type=${serviceQuery}` : ""}${billingQuery ? `&hardware_type=${billingQuery}` : ""}${typeQuery ? `&inventory_type=${typeQuery}` : ""}${payQuery ? `&stb_payment_category=${payQuery}` : ""}${stbSupplyQuery ? `&stb_supplied=${stbSupplyQuery}` : ""} `
      )
    );
  };


  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}`;
    if (value?.type === "search") {
      query += `&limit=10&offset=0&value=${value?.state}`;
    }
    if (value?.type === "offset") {
      query += `&limit=10&offset=${value?.state - 1}0&value=${searchQuery}`;
    }
    return query;
  };

  const onShowInventeryAddPopUp = (data) => {
    setInvListData(data);
    setShowAddInventory(true);
  };

  const closepoup = () => {
    setShowAddInventory(false);
  }

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
    let query = queryGenerator();
    dispatch(inventryList(query));
  }, []);

  useComponentDidUpdate(() => {
    if (inventoryDeleted?.msg === "STB No Already Used by Subscriber") {
      enqueueSnackbar("STB No Already Used by Subscriber !", {
        variant: "error",
      });
      let user = JSON.parse(localStorage.getItem("user"));
      let query = `distributor_ref_id=${user?.distributor_ref_id}`;
      dispatch(inventryList(query));
      dispatch(reset())
    }

    if (inventoryDeleted?.msg === "Successfully deleted inventory Data") {
      enqueueSnackbar("Inventory Deleted !", {
        variant: "success",
      });
      let user = JSON.parse(localStorage.getItem("user"));
      let query = `distributor_ref_id=${user?.distributor_ref_id}`;
      dispatch(inventryList(query));
      dispatch(reset())
    }

  }, [inventoryDeleted]);

  const handleRedirect = (path) => {
    navigate(`/distributor${path}`);
  };

  const stbsupply = (type, text) => {

    let colorVal = "-";
    if (type === "stb") {
      if (text === true) {
        colorVal = "Yes";
      } else if (text === false) {
        colorVal = "No";
      }
    }

    return (
      <div className="" >
        {colorVal}
      </div>
    );
  };

  useComponentDidUpdate(() => {
    let list = [];
    let listForExport = [];
    if (inventoryListData?.data?.length > 0) {
      inventoryListData.data.map((data, index) => {
        list.push({
          sino: (page - 1) * 10 + index + 1,
          item: data?.items,
          price: data?.price,
          quantity: data?.quantity || "-",
          stbno: data?.stb_no || "-",
          type: data?.hardware_type,
          paymentCat: data?.stb_payment_category || "-",
          itemProvider: data?.itemProviderName || "-",
          serviceType: data?.service_type || "-",
          boxtype: data?.inventory_type || "-",
          createddate: moment(data?.created_at).format("MMMM Do YYYY, h:mm a"),
          stbsupplied: stbsupply(data?.hardware_type, data?.stb_supplied),
          action: (
            <div className="flex-row justify-center">
              <div
                onClick={() => onShowInventeryAddPopUp(data)}
                className="cur-pointer"
              >
                <EditIcon />
              </div>
              <div
                className="cursor-pointer"
                onClick={() =>
                  dispatch(
                    deleteInventry({
                      _id: data?._id,
                    })
                  )
                }
              >
                <DeleteIcon />
              </div>
            </div>
          ),
        });
        listForExport.push({
          "SI:NO": (page - 1) * 10 + index + 1,
          "Item": data?.items,
          "Price": data?.price,
          "Quantity": data?.quantity,
          "Stb No": data?.stb_no,
          "Type": data?.hardware_type,
          "Box Type": data?.inventory_type || "-",
          "Payment Category": data?.stb_payment_category,
          "Stb Supplied":  data?.hardware_type === "stb" ? data?.stb_supplied === true ? "Yes" : "No" : "-",
          "Item Provider": data?.itemProviderName || "-",
          "Service Type": data?.service_type || "-",
          "Created Date": moment(data?.created_at).format("ll"),

        });
      });
    }

    setEnqList(list);
    setInventoryListExport(listForExport);
  }, [inventoryListData]);

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    let query = queryGenerator({ type: "search", state: search });
    dispatch(inventryList(query));
  };




  return (
    <div>
      <AddInventeryPopup show={showAddInventory} handleClose={closepoup} listData={props?.data} invData={invListData} />
      <MultipleStb show={addOnopen} handleClose={handleAddOnClose} planListData={props?.data} />
      <div className="inventryList-container">
        <Grid container spacing={2}>
          <Grid item xs={6} md={8}>
            <div className="planHeading">Inventory List</div>
          </Grid>
          <Grid item xs={6} md={4} className="flex-end">
            <div className="employeelist-title">
              <BreadCrumb
                defaultHeading="Home"
                handleDefaultClick={() => handleRedirect("/dashboard")}
                activeHeading="Inventory List"
                handleActiveClick={() => handleRedirect("/inventery-list")}
              />
            </div>

          </Grid>
        </Grid>

        <div className="ticket-list-table-cntr">
          <div className="search-btns-padding">
            <SearchWithButtons
              extraButton={{
                title: "Multiple STB Add",
                type: "contained",
                color: "white",
                bgColor: COLORS.primary,
              }}
              secondaryButton={{
                title: "Add New",
                type: "contained",
                color: "white",
                bgColor: COLORS.primary,
              }}
              primaryButton={{
                title: "User Inventory List",
                type: "contained",
                color: "white",
                bgColor: COLORS.primary,
              }}
              secondryExtraButton={{
                title: "Export",
                type: "outlined",
                color: COLORS.primary,
                filename: "Inventory list",
                data: inventoryListExport,
              }}
              handlePrimaryClick={() => handleRedirect("/hardware-list")}
              handleSecondaryClick={() => onShowInventeryAddPopUp()}
              handleChange={handleSearch}
              handleExtraClick={() => handleAddOnOpen()}
            />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <div style={{ fontWeight: 'bold', fontSize: '15px', paddingBottom: '10px' }}>Showing entries {(page - 1) * 10 + 1} - {Math.ceil(inventoryListData?.totalCount / 10) === page ? inventoryListData?.totalCount : page + "0"} of {inventoryListData?.totalCount}</div>

            </Grid>
            <Grid item xs={4} className="flex-end">
              <div className="employeelist-title">
                <SubscriberFilter
                  labelPrimary={"Provider"}
                  labelSecondary={"Subscriber validity"}
                  PrimaryOption={providerOptions}
                  SecondaryOption={Validity}
                  billingTypeOption={options2}
                  serviceTypeOption={ServiceType}
                  billgenOption={boxType}
                  billingType={"Type"}
                  serviceType={"Service Type"}
                  billgen={"Box Type"}
                  paymentCategory={"Payment Category"}
                  paymentCategoryOption={payOption}
                  stbSuplied={"STB Supplied"}
                  stbSupplyOption={stbOption}
                  handleResetClick={handleReset}
                  handleFormValues={(status, validity, serviceType, billingType, billgen, paymentCat, stbsupply) => handleFormValues(status, validity, serviceType, billingType, billgen, paymentCat, stbsupply)}
                />
              </div>

            </Grid>
          </Grid>
          <TableView rows={tableRows} data={enqList} color={COLORS.primary} />
          <div
            style={{
              marginTop: 20,
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Pagination
              count={Math.ceil(inventoryListData?.totalCount / 10) || 0}
              defaultPage={1}
              page={page}
              onChange={(event, value) => {
                setPage(value);
                handleChange(value);
              }}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
