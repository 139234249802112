import { createSlice } from "@reduxjs/toolkit";
import { inventryList, deleteInventry, HardwareList ,inventoryAdd,inventoryUpdate, inventoryMultipleUpdate, inventoryMultipleAdd} from "./api";

const initialState = {
  inventoryListData: [],
  inventoryAddData: [],
  inventoryAddMultipleData: [],
  inventoryUpdateData: [],
  inventoryDeleted: false,
  hardwareData:[],
  inventoryMultipleUpdateData: false
};

export const inventrySlice = createSlice({
  name: "enquiry",
  initialState,
  reducers: {
    reset: (state) => {
      state.inventoryDeleted = false;
      return state;
    },
  },

  extraReducers: {
    [inventryList.pending]: (state) => {},
    [inventryList.fulfilled]: (state, { payload }) => {
      state.inventoryListData = payload.data.data;
    },
    [inventryList.rejected]: (state, { payload }) => {},
    
    [deleteInventry.pending]: (state) => {},
    [deleteInventry.fulfilled]: (state, { payload }) => {
      state.inventoryDeleted = payload.data;
    },
    [deleteInventry.rejected]: (state, { payload }) => {},

    [HardwareList.pending]: (state) => {},
    [HardwareList.fulfilled]: (state, { payload }) => {
      state.hardwareData = payload.data.data;
    },
    [HardwareList.rejected]: (state, { payload }) => {},

    [inventoryAdd.pending]: (state) => {},
    [inventoryAdd.fulfilled]: (state, { payload }) => {
      state.inventoryAddData = payload.data;
    },
    [inventoryAdd.rejected]: (state, { payload }) => {},

    [inventoryUpdate.pending]: (state) => {},
    [inventoryUpdate.fulfilled]: (state, { payload }) => {
      state.inventoryUpdateData = payload.data;
    },
    [inventoryUpdate.rejected]: (state, { payload }) => {},

    [inventoryMultipleUpdate.pending]: (state) => {},
    [inventoryMultipleUpdate.fulfilled]: (state, { payload }) => {
      state.inventoryMultipleUpdateData = payload.data.data;
    },
    [inventoryMultipleUpdate.rejected]: (state, { payload }) => {},

    [inventoryMultipleAdd.pending]: (state) => {},
    [inventoryMultipleAdd.fulfilled]: (state, { payload }) => {
      state.inventoryAddMultipleData = payload.data;
    },
    [inventoryMultipleAdd.rejected]: (state, { payload }) => {},
  },
  
});
export const { reset } = inventrySlice.actions;

export default inventrySlice.reducer;
