import "./style.scss";
import SubscriberProfile from "@components/SubscriberProfile";
import SubscriberTab from "@components/SubscriberTab";
import SubscriberTicket from "./pages/subscriberTicket";
import SubscriberAddon from "./pages/subscriberAddonService";
import SubscriberAddonTv from "./pages/subscriberAddonTv";
import SubscriberCurrent from "./pages/subscriberCurrentService";
import SubscriberInvoiceReceipt from "./pages/subscriberInvoiceReceipt";
import ResetPasswordForm from "@components/ResetPasswordForm";
import * as React from "react";
import { subscriberList, lastPaid, paidByList,  paymentTax, subscriberView } from "@reducers/api";
import { useNavigate } from "react-router-dom";
import { useComponentDidMount, paramsQuery, useComponentDidUpdate, useComponentWillUnmount } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/subscriber";
import BreadCrumb from "@components/Breadcrumb";
import { Grid } from "@mui/material";

export default function SubscriberDetails(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { subscriberListData,  lastPaidData, paymentTaxData, paymentPlanData, subviewData, imgUpload } = useSelector((state) => state.subscriber);
  let filtered = subscriberListData?.customers && subscriberListData?.customers.filter((data) => data._id === paramsQuery("id"));

  const tabData = [
    { id: 2, title: "Ticket Generate", comp: <SubscriberTicket /> },
    { id: 3, title: "Invoice/Receipt" ,comp:<SubscriberInvoiceReceipt/>},
    { id: 4, title: "Add on TV", comp: <SubscriberAddonTv customerData={subviewData} /> },
    // { id: 6, title: "Broadcast Bouquet", comp: <SubscriberBoquet customerData={subviewData}/> },
    { id: 7, title: "Add on service", comp: <SubscriberAddon customerData={subviewData} /> },
    { id: 8, title: "Current service", comp: <SubscriberCurrent customerData={subviewData}/> },
    { id: 9, title: "Reset password", comp: <ResetPasswordForm /> },
  ];

  const tabInternetData = [
    { id: 2, title: "Ticket Generate", comp: <SubscriberTicket /> },
    { id: 3, title: "Invoice/Receipt" ,comp:<SubscriberInvoiceReceipt/>},
    { id: 7, title: "Add on service", comp: <SubscriberAddon customerData={subviewData} /> },
    { id: 8, title: "Current service", comp: <SubscriberCurrent customerData={subviewData}/> },
    { id: 9, title: "Reset password", comp: <ResetPasswordForm /> },
  ];

  const queryGenerator = (value) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `distributor_ref_id=${user?.distributor_ref_id}`;
    if (value?.type === "search") {
      query += `&searchText=${value?.state}`;
    }

    return query;
  };

  useComponentDidMount(() => {
    window.scrollTo(0, 0);
    let id = paramsQuery("id");
    let query = queryGenerator();
    let user = JSON.parse(localStorage.getItem("user"));
    let distid = `distributor_ref_id=${user?.distributor_ref_id}`;
    let paidquery = `cust_ref_id=${id}`;
    dispatch(subscriberList(query));
    dispatch(subscriberView(id));
    dispatch(lastPaid(paidquery));
    dispatch(paidByList(distid));
    dispatch(paymentTax(query));
  }, []);

  console.log(subviewData, "subviewdata")

  useComponentDidUpdate(() => {
    if (imgUpload) {
      enqueueSnackbar("Profile Pic Updated !", {
        variant: "success",
      });
      let id = paramsQuery("id");
      dispatch(subscriberView(id));
      dispatch(reset());
    }
  }, [imgUpload]); 

  useComponentWillUnmount(() => {
    dispatch(reset());
  });

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

  console.log(filtered, "serviceeeee")

  return (
    <div className="subscriberDetails-container">      
      <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
          <div className="planHeading">Profile</div>
          </Grid>
          <Grid item xs={12} md={4} className="flex-end">
            <div className="planHeading">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              secondaryHeading="Subscriber List"
              handleSecondaryClick={() => handleBreadCrumbRedirect("subscriber-list")}
              activeHeading="View Subscriber"              
            />
            </div>            
          </Grid>
        </Grid>
      <div className="subscriberProfileCard">
        <SubscriberProfile
          data={subviewData}
          lastData={lastPaidData}         
          paymentTax={paymentTaxData}
          planList={paymentPlanData}
        />
      </div>
      <div>
        <SubscriberTab data={subviewData?.service_type === "cable" ? tabData : tabInternetData} />
      </div>
    </div>
  );
}
