import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { COLORS } from "@constants";
import "./styles.scss";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function AssignSubModal(props) {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
  });

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    props?.handleClose();
  };

  useEffect(() => {
    setOpen(props?.show);
  }, [props?.show]);

  const updateSubmit1 = (values) => {
    props?.handleFormValues(values)
  };
  const onSubmit1 = (values) => updateSubmit1(values);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="assignPopRes">
          <Grid
            container
            columns={{ xs: 12, sm: 12, md: 12 }}
            className="padding-btw-tiles"
          >
            <Grid item xs={10} sm={10} md={12}>
              <div className="text-plan">
                Are you sure to assign these Subscribers?
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            columns={{ xs: 12, sm: 8, md: 12 }}
            className="padding-btw-tiles"
          >
            <Grid item xs={6} sm={6} md={4}>
              <Button
              type="submit"
              onClick={handleSubmit(onSubmit1)}
                variant="contained"
                component="span"
                className="bg-blue"
                size="small"
                sx={{ fontSize: 13, textTransform: "capitalize" }}
              >
                Confirm
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <Button
                variant="contained"
                component="span"
                onClick={handleClose}
                className="bg-blue"
                size="small"
                // style={{
                //   backgroundColor: "white",
                //   color: COLORS.primary,
                //   border: " 1px solid blue",
                // }}
                sx={{ fontSize: 13, textTransform: "capitalize" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
