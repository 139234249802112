import Grid from "@mui/material/Grid";
import moment from "moment";
import AddonServices from "@components/AddonServices";
import { addOnServicesCreate, addOnServices, deleteAddOnService, getInventoryList } from "@reducers/api";
import { reset } from "@reducers/subscriber";
import { useComponentDidMount, useComponentDidUpdate } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { COLORS } from "@constants";
import { useState } from "react";
import { addOnserviceTable } from "../data";
import TableView from "@components/TableView";
import { useSnackbar } from "notistack";
import PayNowPop from "@components/Modals/inventoryPayNow";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TicketFilter from "@components/TicketFilter";
import Pagination from "@mui/material/Pagination";

export default function SubscriberAddonService(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { addOnServicesData, addOnServiceDeleted, addOnServiceAddded, payNowAdded, getInventoryListData } = useSelector((state) => state.subscriber);
  const [servicesData, setServicesData] = useState([]);
  const [show, setShow] = useState(false);
  const [rowData, setRowdata] = useState([]);
  const [page, setPage] = useState(1);
  const [filterquery, setFilterQuery] = useState("");

  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `cust_ref_id=${props?.customerData?._id}&limit=10&offset=0`;
   
    dispatch(addOnServices(query));
    dispatch(getInventoryList(`distributor_ref_id=${user?.distributor_ref_id}&quantityNotZero=1&all=1`));
  }, []);

  const handleChange = (value) => {
    dispatch(
      addOnServices(
        `cust_ref_id=${props?.customerData?._id}&limit=10&offset=${value - 1}0${filterquery ? `&payment=${filterquery}` : ""} `
      )
    );
  };

  const handleFormData = (values) => {
    let addOnArray = [];
    let quantityArray = [];
    for (let value of values) {
      if (value.type) {
        addOnArray.push({
          type: value?.type,
          plan_amount: value?.value,
          cust_ref_id: props?.customerData?._id,
          cust_name: props?.customerData?.name,
          cust_email_id: props?.customerData?.email_id,
          package_category: "add_on_service",
          billing_type: "pre paid",
          inventoryId : value?.inventoryId
        });

      }
      quantityArray.push({
        quantity: (value?.quantity),
        _id: value?._id
      })
    }

    dispatch(addOnServicesCreate(addOnArray));
    // dispatch(inventoryMultipleUpdate(quantityArray));
  
  };

  useComponentDidUpdate(() => {
    if (addOnServiceDeleted) {
      enqueueSnackbar("Add On Service Deleted !", {
        variant: "success",
      });
      let query = `cust_ref_id=${props?.customerData?._id}&limit=10&offset=0`;
   
      dispatch(addOnServices(query));
    }
    if (addOnServiceAddded) {
      enqueueSnackbar("Add On Service Added !", {
        variant: "success",
      });
      let query = `cust_ref_id=${props?.customerData?._id}&limit=10&offset=0`;
      let user = JSON.parse(localStorage.getItem("user"));
      dispatch(addOnServices(query));
      dispatch(getInventoryList(`distributor_ref_id=${user?.distributor_ref_id}&quantityNotZero=1&all=1`));
    }
    if (payNowAdded) {
      // enqueueSnackbar("Payment Success !", {
      //   variant: "success",
      // });
      setShow(false);
      let query = `cust_ref_id=${props?.customerData?._id}&limit=10&offset=0`;
   
      dispatch(addOnServices(query));
    }
    dispatch(reset());
  }, [addOnServiceDeleted, addOnServiceAddded, payNowAdded]);

  const onshowpopup = (data) => {
    setShow(!show);
    setRowdata(data)
  };


  useComponentDidUpdate(() => {
    let list = [];
    if (addOnServicesData?.data?.length > 0) {
      addOnServicesData &&
        addOnServicesData?.data?.map((data, index) => {
          list.push({
            sNo: (page - 1) * 10 + index + 1,
            serviceType: data?.type,
            service_Type: data?.service_type || "",
            stb_no: data?.stb_no,
            serviceAmount: data?.plan_amount,
            date: moment(data?.updated_at).format("ll"),
            status: data?.payment ? (
              <Stack spacing={2} direction="row">
                <Button sx={{ textTransform: "none" }} type="submit" size="small" variant="contained" className="bg-blue">
                  Paid
                </Button>
              </Stack>
            ) : (
              <Stack spacing={2} direction="row">
                <Button sx={{ textTransform: "none" }} type="submit" size="small" variant="contained" className="bg-blue" onClick={() => onshowpopup(data)}>
                  Pay now
                </Button>
              </Stack>
            ),
            action: data?.payment ? "" : (
              <div className="flex-row justify-center">
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    dispatch(
                      deleteAddOnService({
                        _id: data?._id,
                      })
                    )
                  }
                >
                  <DeleteIcon />
                </div>
              </div>
            ),
          });
        });
    }

    setServicesData(list);
  }, [addOnServicesData]);

  const Status = [
    { label: "Paid", value: "true" },
    { label: "Un Paid", value: "false" },
  ];

  const handleClickSearcch = (value, value1, priorityval, statusval) => {

    setPage(1);
    let query = `cust_ref_id=${props?.customerData?._id}&limit=10&offset=0`;
    if(value){
      query += `&startDate=${moment(value).format("YYYY-MM-DDTHH:mm:ss")}`
    }
    if(value1){
      query += `&endDate=${moment(value1).format("YYYY-MM-DDTHH:mm:ss")}`
    }
 
    if (priorityval) {
      query += `&payment=${priorityval?.value}`
    }
    if (statusval) {
      query += `&status=${statusval?.value}`
    }
    setFilterQuery(priorityval?.value);
    dispatch(addOnServices(`&${query}`));
  };

  const handleReset = () => {
    let query = `cust_ref_id=${props?.customerData?._id}&limit=10&offset=0`;
    dispatch(addOnServices(query));
    setFilterQuery("");
    setPage(1);
  };


  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="sub-add-on-cnt">
      <PayNowPop show={show} invData={rowData} handleClose={onshowpopup} customerData={props?.customerData} />

      <Grid item xs={12} sm={12} md={12}>
        <AddonServices handleFormData={(values) => handleFormData(values)} resetForm={addOnServiceAddded} getInventoryListData={getInventoryListData}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TicketFilter
          labelPrimary={"Payment"}
          PrimaryOption={Status}
          isAddOn={true}
          handleResetClick = {handleReset}
          handleFormValues={(data1, data2, data3, data4) => handleClickSearcch(data1, data2, data3, data4)} />
          
      </Grid>
      <Grid item xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
        <TableView rows={addOnserviceTable} data={servicesData} color={COLORS.primary} />
      </Grid>
      <div
            style={{
              marginTop: 20,
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Pagination
              count={Math.ceil(addOnServicesData?.totalCount / 10) || 0}
              defaultPage={1}
              page={page}
              onChange={(event, value) => {
                setPage(value);
                handleChange(value);
              }}
              variant="outlined"
              shape="rounded"
            />
          </div>
    </Grid>
  );
}
