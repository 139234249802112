const tableRows = [
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },

  {
    title: "Name",
    key: "name",
    align: "left",
  },

  {
    title: "Collected Amount",
    key: "collectedAmt",
    align: "center",
  },
  {
    title: "Collected Count",
    key: "collectedCount",
    align: "center",
  },
  {
    title: "New Connection",
    key: "newConnection",
    align: "center",
  },
  {
    title: "Total Connection",
    key: "totalConnection",
    align: "center",
    justify: "center",
  },  
  {
    title: "Action",
    key: "action",
    align: "center",
  },  
];

export { tableRows };
