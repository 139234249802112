import { createSlice } from "@reduxjs/toolkit";
import {
  subscriberList,
  subscriberSearch,
  lastPaid,
  paymentAmount,
  othersAll,
  othersAllService,
  othersAllInternet,
  paymentTax,
  payNow,
  payNowCustomer,
  resetPwd,
  accountID,
  assignTo,
  paymentPlan,
  channelList,
  broadcastCreate,
  subscriberAddPlan,
  subscriberPlanList,
  currentServiceList,
  currentServiceActiveList,
  addOnServicesCreate,
  addOnServices,
  deleteAddOnService,
  updateBalance,
  addOnTv,
  deleteCurrentServicePlan,
  areaList,
  subsPlanUpdate,
  addSubscriber,
  SubscriberListAssigned,
  SubscriberListMultipleDelete,
  deleteAssignSubs,
  agentWorkTime,
  agentWorkTimeUpdate,
  subscriberView,
  userAccountStatus,
  assignSubscriberSubmit,
  broadcastCreatemultiple,
  SubscriberListMultiplePermanentDelete,
  InvoiceReceiptList,
  stbUpdate,
  deleteAddonTv,
  bulkUploadSaveValidation,
  bulkUploadSave,
  getInventoryList,
  RemoveBill,
  RemoveBillUpdatecall,
  bulkUploadAddOnValid,
  bulkUploadAddOnSave,
  subscriberPlanListNew,
  notiPayment,
  notiTicketUpdate,
  notiPaymentUpdate,
  CurrentServiceDelete,
  currentServiceSTBList,
  invoicePdf,
  bulkFileUpload,
  paymentSummaryDownload,
  paymentSummaryTranscDownload,
  subExport,
  subDelExport,
  invoiceDownloadExport,
  billingListExport,
  bulkFileAddOnUpload,
  bulkFilePaymentUpload,
  profileimageUpload,
  settingsPaymentGet,
  bulkEmail,
  singleSms,
  currentServiceUpdate,
  ServiceListTable,
  AllSubscribersDelete
  ,whatsappMessageSend,whatsappMessageSendBulk
} from "./api";

const initialState = {
  subscriberListData: [],
  subscriberSearchData: [],
  settingsPaymentData: [],
  lastPaidData: [],
  otherAllData: [],
  paymentAmtData: [],
  paymentTaxData: [],
  paymentPlanData: [],
  payNowAdded: false,
  payNowCust : false,
  resetPwdData: [],
  accountIDData: [],
  assignToData: [],
  addOnServicesData: [],
  channelListData: [],
  broadcastAdded: false,
  broadcastAddedError: false,
  subPlanAdded: false,
  subscriberPlanListData: [],
  currentServiceListData: [],
  serviceTableList: [],
  currentServiceActiveListData: false,
  imgUpload: false,
  addOnServiceDeleted: false,
  addOnServiceAddded: false,
  updateBalanceData: null,
  deleteCurrentServicePlanData: false,
  addOnTvAdded: false,
  areaListData: [],
  SubscriberListAssignedData:[],
  SubscriberListMultipleDeleteData:null,
  SubscriberAllMultipleDeleteData:null,
  SubscriberRestoreData: null,
  deleteRes:false,
  subscriberAdded: false,
  PlanPaymentListData: [],
  workTimeData: [],
  agentWorkTimeData: [],
  subviewData: [],
  InvoiceReceiptListData: [],
  assignDelete: false,
  userAccountStatusData:false,
  assignSubscriberSubmitData: {},
  broadcastAddedMultiple:false,
  permanentDelData:false,
  addOnTvAddedmsg:false,
  stbUpdateData:false,
  deleteAddonTvData:false,
  bulkUploadSaveAddedValid: false,
  bulkUploadAddOnValidAdded: false,
  bulkUploadSaveAddOnAdded: false,
  bulkUploadPayment: false,
  bulkUploadSaveAdded: false,
  getInventoryListData:[],
  RemoveBillData:false,
  RemoveBillUpdatecallData:false,
  ticketUpdate:false,
  subscriberPlanListNewData:[],
  notiPaymentData:[],
  notiTicketUpdateData:false,
  notiPaymentUpdateData:false,
  CurrentServiceDeleteData:[],
  invoicePdfData:[],
  bulkFileUploadAdded : false,
  bulkFileAddOnUploadAdded : false,
  paymentSummaryDownloadData:{}, 
  paymentSummaryTranscDownloadData:false,
  subExportData:false,
  subDelExportData : false,
  invoiceDownloadExportData:[],
  billingListExport:false,
  bulkemaildata: false,
  singlesmsData: false,
  currentServiceUpdateData : false,
  otherAllServiceData: [],
  otherAllInternetData : [],
  whatsappMessageSendStatus:false,
  whatsappMessageSendBulkStatus:false
};

export const subscriberSlice = createSlice({
  name: "subscriber",
  initialState,
  reducers: {
    reset: (state) => {
      state.addOnServiceDeleted = false;
      state.bulkFileUploadAdded = false;
      state.subPlanAdded = false;
      state.imgUpload =false;
      state.ticketUpdate = false;
      state.broadcastAdded = false;
      state.broadcastAddedError = false;
      state.payNowAdded = false;
      state.payNowCust = false;
      state.addOnServiceAddded = false;
      state.otherAllServiceData = [];
      state.otherAllInternetData=[];
      state.addOnTvAdded = false;
      state.subscriberAdded = false;
      state.workTimeData=[];
      state.updateBalanceData=null
      state.deleteRes = false;
      state.userAccountStatusData = false;
      state.assignSubscriberSubmitData=null;
      state.broadcastAddedMultiple=false
      state.permanentDelData=false
      state.PlanPaymentListData=[];
      state.currentServiceListData=[];
      state.addOnTvAddedmsg=false;
      state.stbUpdateData=false;
      state.deleteAddonTvData=false;
      state.bulkUploadSaveAddedValid=false;
      state.bulkUploadSaveAdded=false;
      state.RemoveBillData=false;
      state.RemoveBillUpdatecallData=false;
      state.bulkUploadAddOnValidAdded= false;
      state.bulkUploadSaveAddOnAdded= false;
      state.CurrentServiceDeleteData=false;
      state.notiPaymentUpdateData=false;
      state.notiTicketUpdateData=false;
      state.paymentSummaryTranscDownloadData=false;
      state.subExportData=false;
      // state. subDelExportData = false,
      state.invoiceDownloadExportData=false;
      state.billingListExport=false;
      state.bulkemaildata= false;
      state.singlesmsData= false;
      state.currentServiceUpdateData = false;
      state.SubscriberAllMultipleDeleteData=false;
      state.whatsappMessageSendStatus=false;
      state.whatsappMessageSendBulkStatus=false
      return state;
    },
    Subreset: (state) => {
      state.payNowAdded = false;
      state.currentServiceUpdateData = false;
      state.broadcastAddedError = false;
      state.broadcastAdded = false;
      state.subviewData = []
      return state;
    },
  },
  extraReducers: {
    [subscriberList.pending]: (state) => {},
    [subscriberList.fulfilled]: (state, { payload }) => {
      state.subscriberListData = payload.data.data;
    },
    [subscriberList.rejected]: (state, { payload }) => {},

    [subscriberView.pending]: (state) => {},
    [subscriberView.fulfilled]: (state, { payload }) => {
      state.subviewData = payload.data.data;
    },
    [subscriberView.rejected]: (state, { payload }) => {},

    [subscriberSearch.pending]: (state) => {},
    [subscriberSearch.fulfilled]: (state, { payload }) => {
      state.subscriberListData = payload.data.data;
    },
    [subscriberSearch.rejected]: (state, { payload }) => {},

    [lastPaid.pending]: (state) => {},
    [lastPaid.fulfilled]: (state, { payload }) => {
      state.lastPaidData = payload.data.data;
    },
    [lastPaid.rejected]: (state, { payload }) => {},

    [paymentAmount.pending]: (state) => {},
    [paymentAmount.fulfilled]: (state, { payload }) => {
      state.paymentAmtData = payload.data.data;
      
    },
    [paymentAmount.rejected]: (state, { payload }) => {},

    [paymentTax.pending]: (state) => {},
    [paymentTax.fulfilled]: (state, { payload }) => {
      state.paymentTaxData = payload.data;
    },
    [paymentTax.rejected]: (state, { payload }) => {},

    [payNow.pending]: (state) => {},
    [payNow.fulfilled]: (state, { payload }) => {
      state.payNowAdded = payload.data.status;
    },
    [payNow.rejected]: (state, { payload }) => {},

    
    [payNowCustomer.pending]: (state) => {},
    [payNowCustomer.fulfilled]: (state, { payload }) => {
      state.payNowCust = payload.data.status;
    },
    [payNowCustomer.rejected]: (state, { payload }) => {},

    [resetPwd.pending]: (state) => {},
    [resetPwd.fulfilled]: (state, { payload }) => {     
      state.resetPwdData = payload.data;
    },
    [resetPwd.rejected]: (state, { payload }) => {},

    [accountID.pending]: (state) => {},
    [accountID.fulfilled]: (state, { payload }) => {
      state.accountIDData = payload.data.data;
    },
    [accountID.rejected]: (state, { payload }) => {},

    [assignTo.pending]: (state) => {},
    [assignTo.fulfilled]: (state, { payload }) => {
      state.assignToData = payload.data.data;
    },
    [assignTo.rejected]: (state, { payload }) => {},

    [paymentPlan.pending]: (state) => {},
    [paymentPlan.fulfilled]: (state, { payload }) => {
      state.paymentPlanData = payload.data.data;
    },
    [paymentPlan.rejected]: (state, { payload }) => {},

    [channelList.pending]: (state) => {},
    [channelList.fulfilled]: (state, { payload }) => {
      state.channelListData = payload.data.data;
    },
    [channelList.rejected]: (state, { payload }) => {},

    [broadcastCreate.pending]: (state) => {},
    [broadcastCreate.fulfilled]: (state, { payload }) => {
      state.broadcastAdded = payload?.data;
      
    },
    [broadcastCreate.rejected]: (state, { payload }) => {
      state.broadcastAddedError = true
    },

    [broadcastCreatemultiple.pending]: (state) => {},
    [broadcastCreatemultiple.fulfilled]: (state, { payload }) => {
      // state.broadcastAddedMultiple = payload?.data.status;
      state.broadcastAdded = payload?.data.status;
    },
    [broadcastCreatemultiple.rejected]: (state, { payload }) => {
      console.log(payload, "payload")
      state.broadcastAddedError = true
    },

    [subscriberAddPlan.pending]: (state) => { },
    [subscriberAddPlan.fulfilled]: (state, { payload }) => {
      state.subPlanAdded = payload?.data;
    },
    [subscriberAddPlan.rejected]: (state, { payload }) => {},

    [ServiceListTable.pending]: (state) => {},
    [ServiceListTable.fulfilled]: (state, { payload }) => {
      state.serviceTableList = payload.data.data;
    },
    [ServiceListTable.rejected]: (state, { payload }) => {},


    [subsPlanUpdate.pending]: (state) => {},
    [subsPlanUpdate.fulfilled]: (state, { payload }) => {
      state.subPlanAdded = payload?.data;
    },
    [subsPlanUpdate.rejected]: (state, { payload }) => {},

    [subscriberPlanList.pending]: (state) => {},
    [subscriberPlanList.fulfilled]: (state, { payload }) => {
      state.subscriberPlanListData = payload.data.data;
    },
    [subscriberPlanList.rejected]: (state, { payload }) => {},

    [currentServiceList.pending]: (state) => {},
    [currentServiceList.fulfilled]: (state, { payload }) => {
      state.currentServiceListData = payload.data.data;
      state.PlanPaymentListData = payload.data?.data;
    },
    [currentServiceList.rejected]: (state, { payload }) => {},



    [deleteCurrentServicePlan.pending]: (state) => {},
    [deleteCurrentServicePlan.fulfilled]: (state, { payload }) => {
      state.deleteCurrentServicePlanData = payload.data.data;
    },
    [deleteCurrentServicePlan.rejected]: (state, { payload }) => {},

    [currentServiceActiveList.pending]: (state) => {},
    [currentServiceActiveList.fulfilled]: (state, { payload }) => {
      state.currentServiceActiveListData = payload.data.data;
    },
    [currentServiceActiveList.rejected]: (state, { payload }) => {},

    [addOnServices.pending]: (state) => {},
    [addOnServices.fulfilled]: (state, { payload }) => {
      state.addOnServicesData = payload.data.data;
    },
    [addOnServices.rejected]: (state, { payload }) => {},
    [addOnServicesCreate.pending]: (state) => {},
    [addOnServicesCreate.fulfilled]: (state, { payload }) => {
      state.addOnServiceAddded = payload.data.status;
    },
    [addOnServicesCreate.rejected]: (state, { payload }) => {},
    [deleteAddOnService.pending]: (state) => {},
    [deleteAddOnService.fulfilled]: (state, { payload }) => {
      state.addOnServiceDeleted = payload.data.status;
    },
    [deleteAddOnService.rejected]: (state, { payload }) => {},

    [updateBalance.pending]: (state) => {},
    [updateBalance.fulfilled]: (state, { payload }) => {
      state.updateBalanceData = payload.data.data;
    },
    [updateBalance.rejected]: (state, { payload }) => {},
    [addOnTv.pending]: (state) => {},
    [addOnTv.fulfilled]: (state, { payload }) => {
      state.addOnTvAdded = payload.data.status;
      state.addOnTvAddedmsg = payload.data.msg;
    },
    [addOnTv.rejected]: (state, { payload }) => {},
    [areaList.pending]: (state) => {},
    [areaList.fulfilled]: (state, { payload }) => {
      state.areaListData = payload.data.data;
    },
    [areaList.rejected]: (state, { payload }) => {},
    [addSubscriber.pending]: (state) => {},
    [addSubscriber.fulfilled]: (state, { payload }) => {
      state.subscriberAdded = payload.data;
    },
    [addSubscriber.rejected]: (state, { payload }) => {},

    [SubscriberListAssigned.pending]: (state) => {},
    [SubscriberListAssigned.fulfilled]: (state, { payload }) => {
      state.SubscriberListAssignedData = payload.data.data;
      state.subscriberListData = payload.data.data;
    },
    [SubscriberListAssigned.rejected]: (state, { payload }) => {},

    [SubscriberListMultipleDelete.pending]: (state) => {},
    [SubscriberListMultipleDelete.fulfilled]: (state, { payload }) => {
      state.SubscriberListMultipleDeleteData = payload.data;
      state.SubscriberRestoreData = payload;
    },
    [SubscriberListMultipleDelete.rejected]: (state, { payload }) => {},

    [AllSubscribersDelete.pending]: (state) => {},
    [AllSubscribersDelete.fulfilled]: (state, { payload }) => {
      state.SubscriberAllMultipleDeleteData = true;
    },
    [AllSubscribersDelete.rejected]: (state, { payload }) => {},

    [deleteAssignSubs.pending]: (state) => {},
    [deleteAssignSubs.fulfilled]: (state, { payload }) => {
      state.deleteRes = payload.data;
      state.restoreSubs = payload.data;
    },
    [deleteAssignSubs.rejected]: (state, { payload }) => {},

    [bulkUploadSaveValidation.pending]: (state) => { },
    [bulkUploadSaveValidation.fulfilled]: (state, { payload }) => {
      state.bulkUploadSaveAddedValid = payload.data.status;
    },
    [bulkUploadSaveValidation.rejected]: (state, { payload }) => {},

    [bulkUploadAddOnValid.pending]: (state) => { },
    [bulkUploadAddOnValid.fulfilled]: (state, { payload }) => {
      state.bulkUploadAddOnValidAdded = payload.data;
    },
    [bulkUploadAddOnValid.rejected]: (state, { payload }) => {},

    [bulkUploadAddOnSave.pending]: (state) => { },
    [bulkUploadAddOnSave.fulfilled]: (state, { payload }) => {
      state.bulkUploadSaveAddOnAdded = payload.data.status;
    },
    [bulkUploadAddOnSave.rejected]: (state, { payload }) => {},

    [agentWorkTime.pending]: (state) => {},
    [agentWorkTime.fulfilled]: (state, { payload }) => {
      state.agentWorkTimeData = payload.data.data;
    },
    [agentWorkTime.rejected]: (state, { payload }) => {},

    [agentWorkTimeUpdate.pending]: (state) => {},
    [agentWorkTimeUpdate.fulfilled]: (state, { payload }) => {
      state.workTimeData = payload.data.data;
    },
    [agentWorkTimeUpdate.rejected]: (state, { payload }) => {},

    [userAccountStatus.pending]: (state) => {},
    [userAccountStatus.fulfilled]: (state, { payload }) => {
      state.userAccountStatusData = payload.data.status;
    },
    [userAccountStatus.rejected]: (state, { payload }) => {},

    [assignSubscriberSubmit.pending]: (state) => { },
    [assignSubscriberSubmit.fulfilled]: (state, { payload }) => {
      state.assignSubscriberSubmitData = payload.data;
    },
    [assignSubscriberSubmit.rejected]: (state, { payload }) => { },

    [SubscriberListMultiplePermanentDelete.pending]: (state) => { },
    [SubscriberListMultiplePermanentDelete.fulfilled]: (state, { payload }) => {
      state.permanentDelData = payload.data;
    },
    [SubscriberListMultiplePermanentDelete.rejected]: (state, { payload }) => { },

    [InvoiceReceiptList.pending]: (state) => { },
    [InvoiceReceiptList.fulfilled]: (state, { payload }) => {
      state.InvoiceReceiptListData = payload.data;
    },
    [InvoiceReceiptList.rejected]: (state, { payload }) => { },

    [stbUpdate.pending]: (state) => { },
    [stbUpdate.fulfilled]: (state, { payload }) => {
      state.stbUpdateData = payload.data.status;
    },
    [stbUpdate.rejected]: (state, { payload }) => { },

    [deleteAddonTv.pending]: (state) => { },
    [deleteAddonTv.fulfilled]: (state, { payload }) => {
      state.deleteAddonTvData = payload.data.status;
    },
    [deleteAddonTv.rejected]: (state, { payload }) => { },

    [bulkUploadSave.pending]: (state) => { },
    [bulkUploadSave.fulfilled]: (state, { payload }) => {
      state.bulkUploadSaveAdded = payload.data.status;
    },
    [bulkUploadSave.rejected]: (state, { payload }) => {},

    [getInventoryList.pending]: (state) => { },
    [getInventoryList.fulfilled]: (state, { payload }) => {
      state.getInventoryListData = payload.data.data;
    },
    [getInventoryList.rejected]: (state, { payload }) => {},

    [RemoveBill.pending]: (state) => { },
    [RemoveBill.fulfilled]: (state, { payload }) => {
      state.RemoveBillData = payload.data.status;
    },
    [RemoveBill.rejected]: (state, { payload }) => {},

    [RemoveBillUpdatecall.pending]: (state) => { },
    [RemoveBillUpdatecall.fulfilled]: (state, { payload }) => {
      state.RemoveBillUpdatecallData = payload.data.status;
    },
    [RemoveBillUpdatecall.rejected]: (state, { payload }) => {},

    [subscriberPlanListNew.pending]: (state) => { },
    [subscriberPlanListNew.fulfilled]: (state, { payload }) => {
      state.subscriberPlanListNewData = payload.data.data;
    },
    [subscriberPlanListNew.rejected]: (state, { payload }) => {},

    [notiPayment.pending]: (state) => { },
    [notiPayment.fulfilled]: (state, { payload }) => {
      state.notiPaymentData = payload.data.data;
    },
    [notiPayment.rejected]: (state, { payload }) => {},

    [notiTicketUpdate.pending]: (state) => { },
    [notiTicketUpdate.fulfilled]: (state, { payload }) => {
      state.notiTicketUpdateData = payload.data.status;
      state.ticketUpdate = payload.data.status;
    },
    [notiTicketUpdate.rejected]: (state, { payload }) => {},

    [notiPaymentUpdate.pending]: (state) => { },
    [notiPaymentUpdate.fulfilled]: (state, { payload }) => {
      state.notiPaymentUpdateData = payload.data.status;
    },
    [notiPaymentUpdate.rejected]: (state, { payload }) => {},

    [CurrentServiceDelete.pending]: (state) => { },
    [CurrentServiceDelete.fulfilled]: (state, { payload }) => {
      state.CurrentServiceDeleteData = payload.data.status;
    },
    [CurrentServiceDelete.rejected]: (state, { payload }) => {},

    [currentServiceSTBList.pending]: (state) => { },
    [currentServiceSTBList.fulfilled]: (state, { payload }) => {
      state.currentServiceListData = payload.data.data;
      state.serviceTableList = payload.data.data;
    },
    [currentServiceSTBList.rejected]: (state, { payload }) => {},

    [invoicePdf.pending]: (state) => { },
    [invoicePdf.fulfilled]: (state, { payload }) => {
      state.invoicePdfData = payload.data.data;
    },
    [invoicePdf.rejected]: (state, { payload }) => {},


    [bulkFileUpload.pending]: (state) => { },
    [bulkFileUpload.fulfilled]: (state, { payload }) => {
      state.bulkFileUploadAdded = payload.data
    },
    [bulkFileUpload.rejected]: (state, { payload }) => {},
    
    [paymentSummaryDownload.pending]: (state) => {},
    [paymentSummaryDownload.fulfilled]: (state, { payload }) => {
      state.paymentSummaryDownloadData = payload.data;
    },
    [paymentSummaryDownload.rejected]: (state, { payload }) => {},

    [paymentSummaryTranscDownload.pending]: (state) => {},
    [paymentSummaryTranscDownload.fulfilled]: (state, { payload }) => {
      state.paymentSummaryTranscDownloadData = payload.data;
    },
    [paymentSummaryTranscDownload.rejected]: (state, { payload }) => {},
    [subExport.pending]: (state) => { },
    [subExport.fulfilled]: (state, { payload }) => {
      state.subExportData = payload.data;
    },
    [subExport.rejected]: (state, { payload }) => {},

   

    [subDelExport.pending]: (state) => { },
    [subDelExport.fulfilled]: (state, { payload }) => {
      state.subDelExportData = payload.data;
    },
    [subDelExport.rejected]: (state, { payload }) => {},

    [invoiceDownloadExport.pending]: (state) => { },
    [invoiceDownloadExport.fulfilled]: (state, { payload }) => {
      state.invoiceDownloadExportData = payload.data;
    },
    [invoiceDownloadExport.rejected]: (state, { payload }) => {},

    [billingListExport.pending]: (state) => { },
    [billingListExport.fulfilled]: (state, { payload }) => {
      state.billingListExportData = payload.data;
    },
    [billingListExport.rejected]: (state, { payload }) => {},

    [bulkFileAddOnUpload.pending]: (state) => { },
    [bulkFileAddOnUpload.fulfilled]: (state, { payload }) => {
      state.bulkFileAddOnUploadAdded = payload.data.status;
    },
    [bulkFileAddOnUpload.rejected]: (state, { payload }) => {},

    [bulkFilePaymentUpload.pending]: (state) => { },
    [bulkFilePaymentUpload.fulfilled]: (state, { payload }) => {
      state.bulkUploadPayment = payload.data.status;
    },
    [bulkFilePaymentUpload.rejected]: (state, { payload }) => {},



    [othersAll.pending]: (state) => {},
    [othersAll.fulfilled]: (state, { payload }) => {
      state.otherAllData = payload.data.data;
      
    },
    [othersAll.rejected]: (state, { payload }) => {},

    [othersAllService.pending]: (state) => {},
    [othersAllService.fulfilled]: (state, { payload }) => {
      state.otherAllServiceData = payload.data.data;
      
    },
    [othersAllService.rejected]: (state, { payload }) => {},

    [othersAllInternet.pending]: (state) => {},
    [othersAllInternet.fulfilled]: (state, { payload }) => {
      state.otherAllInternetData = payload.data.data;
      
    },
    [othersAllInternet.rejected]: (state, { payload }) => {},

    [settingsPaymentGet.pending]: (state) => {},
    [settingsPaymentGet.fulfilled]: (state, { payload }) => {
      state.settingsPaymentData = payload.data.data;
      localStorage.setItem("payment", JSON.stringify(payload.data.data));
      
    },
    [settingsPaymentGet.rejected]: (state, { payload }) => {},

    [profileimageUpload.pending]: (state) => { },
    [profileimageUpload.fulfilled]: (state, { payload }) => {
      state.imgUpload = payload.data.status;
    },
    [profileimageUpload.rejected]: (state, { payload }) => {},

    [bulkEmail.pending]: (state) => {},
    [bulkEmail.fulfilled]: (state, { payload }) => {
      state.bulkemaildata = payload.data.status;
    },
    [bulkEmail.rejected]: (state, { payload }) => {},

    [currentServiceUpdate.pending]: (state) => {},
    [currentServiceUpdate.fulfilled]: (state, { payload }) => {
      state.currentServiceUpdateData = payload.data.status;
    },
    [currentServiceUpdate.rejected]: (state, { payload }) => {},

    [singleSms.pending]: (state) => {},
    [singleSms.fulfilled]: (state, { payload }) => {
      state.singlesmsData = payload.data.status;
    },
    [singleSms.rejected]: (state, { payload }) => {},

    [whatsappMessageSend.pending]: (state) => {},
    [whatsappMessageSend.fulfilled]: (state, { payload }) => {
      state.whatsappMessageSendStatus = true;
    },    
    [whatsappMessageSend.rejected]: (state, { payload }) => {},

    [whatsappMessageSendBulk.pending]: (state) => {},
    [whatsappMessageSendBulk.fulfilled]: (state, { payload }) => {
      state.whatsappMessageSendBulkStatus = true;
    },    
    [whatsappMessageSendBulk.rejected]: (state, { payload }) => {},

  },
});
export const { reset, Subreset } = subscriberSlice.actions;

export default subscriberSlice.reducer;
