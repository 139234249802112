import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./styles.scss";
import Button from "@mui/material/Button";
import { useForm, Controller } from "react-hook-form";
import { useComponentDidUpdate, paramsQuery, useComponentDidMount} from "@utils/useEffectHooks";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {  currentServiceList, addPlanList, getBroadcaster, customerPlanDetails, currentServiceUpdate, ServiceListTable } from "@reducers/api";
import ChannelListPop from "@components/Modals/channelList";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Subreset } from "@reducers/subscriber";

const style = {
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function EditPlanModal(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { adminAllPlansData, adminPackageData, adminBroadCatData, adminAlaCartData,  } = useSelector((state) => state.plan);
  const { broadcastAdded, broadcastAddedMultiple , currentServiceUpdateData} = useSelector((state) => state.subscriber);
  const { getBroadcasterData } = useSelector((state) => state.plan);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({});
  const [open, setOpen] = useState(false);
  const [accessType, setAccessType] = useState("");
  const [valueDate, setValueDate] = useState(props?.editData?.expired_date ? props?.editData?.expired_date : new Date());
  const [stbbouqt, setValueSTBbouq] = useState(null);
  const [stbalacart, setValueAla] = useState(null);
  const [alacartList, setAlaCartList] = useState([]);
  const [channelData, setchannelData] = useState([]);
  const [bouqList, setbouqlist] = useState([]);
  const [basicList, setbasicList] = useState([]);
  const [channelList, setchannelList] = React.useState(false);

  let user = JSON.parse(localStorage.getItem("user"));

  const onSubmit = (data) => {
    handleFormValues(data);
  }

  const handleClose = () => {
    props?.handleClose();
    setAccessType("basic");
    reset();
  };

  const handleFormValues = (values) => {
    console.log(valueDate, "valided")
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      "_id": props?.editData?._id,
      "cust_ref_id": props?.editData?.cust_ref_id,
      "distributor_ref_id": user?.distributor_ref_id,
      "can_id": props?.editData?.can_id,
      "stb_no": stbbouqt || props?.editData?.stb_no,
      "activate_date": props?.editData?.activate_date,
      "expired_date": valueDate || props?.editData?.expired_date,
      "plan_name": watch("PlanName")?.planname,
      "package_category": props?.editData?.package_category,
      "billing_type": props?.editData?.billing_type,
      "mso": props?.editData?.mso,
      "service_type": props?.editData?.service_type,
      "cust_name": props?.editData?.cust_name,
      "cust_email_id": props?.editData?.cust_email_id,
      "disabled": false,
      "plan_validity": watch("PlanName")?.plan_validity,
      "plan_amount": watch("PlanName")?.packagecost,
      "plan_ref_id": watch("PlanName")?._id

    };
    let broadpayload = {
      "_id": props?.editData?._id,
      "cust_ref_id": props?.editData?.cust_ref_id,
      "distributor_ref_id": user?.distributor_ref_id,
      "can_id": props?.editData?.can_id,
      "stb_no": stbbouqt || props?.editData?.stb_no,
      "activate_date": props?.editData?.activate_date,
      "expired_date": valueDate || props?.editData?.expired_date,
      "plan_name": watch("PlanNameBroad")?.plan_name,
      "package_category": props?.editData?.package_category,
      "billing_type": props?.editData?.billing_type,
      "mso": props?.editData?.mso,
      "service_type": props?.editData?.service_type,
      "cust_name": props?.editData?.cust_name,
      "cust_email_id": props?.editData?.cust_email_id,
      "disabled": false,
      "plan_validity": watch("PlanNameBroad")?.plan_validity ,
      "plan_amount": watch("PlanNameBroad")?.plan_amount ,
      "channel_count": watch("PlanNameBroad")?.channel_count ,
      "channels" : watch("PlanNameBroad")?.channels ,
      "plan_ref_id": watch("PlanNameBroad")?._id

    };

    let alacartpayload = {
      "_id": props?.editData?._id,
      "cust_ref_id": props?.editData?.cust_ref_id,
      "distributor_ref_id": user?.distributor_ref_id,
      "can_id": props?.editData?.can_id,
      "stb_no": stbbouqt || props?.editData?.stb_no,
      "activate_date": props?.editData?.activate_date,
      "expired_date": valueDate || props?.editData?.expired_date,
      "plan_name":  watch("PlanNamealacart")?.plan_name,
      "package_category": props?.editData?.package_category,
      "billing_type": props?.editData?.billing_type,
      "mso": props?.editData?.mso,
      "service_type": props?.editData?.service_type,
      "cust_name": props?.editData?.cust_name,
      "cust_email_id": props?.editData?.cust_email_id,
      "disabled": false,
      "plan_validity":  watch("PlanNamealacart")?.plan_validity,
      "plan_amount":  watch("PlanNamealacart")?.plan_amount,
      "channel_count":  watch("PlanNamealacart")?.channel_count,
      "channels" :  watch("PlanNamealacart")?.channels,
      "plan_ref_id": watch("PlanNamealacart")?._id

    };
    if(props?.editData?.package_category === "package")
    {
      dispatch(currentServiceUpdate(payload));

    }
    if(props?.editData?.package_category === "ala cart")
    {
      dispatch(currentServiceUpdate(alacartpayload));

    }
    if(props?.editData?.package_category === "broadcaster")
    {
      dispatch(currentServiceUpdate(broadpayload));

    }

    props?.handleClose();
    
  };

  const handleChange = (newValue) => {
    setValueDate(newValue);
    setValue("date", newValue);
  };

  const handlePlanChange = (item) => {
    setValue("price", item?.packagecost);
    setValue("validity", item?.plan_validity);
  };

  useComponentDidMount(() => {
    dispatch(addPlanList());
    dispatch(getBroadcaster());
  }, []);

  const handleChannelclose = () => {
    setchannelList(false);
  };

  useEffect(() => {
    setOpen(props?.show);
  }, [props?.show]);


  useComponentDidUpdate(() => {
    let filteredforbasc = props?.adminPackageData && props?.adminPackageData?.filter((plan) => plan.billing_type === props?.customerData?.billing_type && plan?.distributor_ref_id === props?.customerData?.distributor_ref_id && plan.disabled == false && plan.package_category == "package");
    let list = [];
    filteredforbasc &&
      filteredforbasc?.map((data, index) => {
        list.push({
          checked: false,
          sNo: index + 1,
          _id: data?._id,
          planname: data?.plan_name,
          packagecost: data?.plan_amount,
          packagecategory: data?.package_category,
          stbno: data?.stb_no,
          amount: data?.plan_amount,
          category: data?.package_category,
          updated_at: data?.updated_at,
          billing_cycle: data?.billing_cycle,
          channel_type: data?.channel_type,
          channelcount: data?.channel_count,
          plan_ref_id: data?._id,
          service_type: data?.service_type,
          plan_validity: data?.plan_validity,
          billing_type: data?.billing_type,
          channels: data?.channels,
        });


      });


    setbasicList(list);

  }, [props?.adminPackageData, props?.customerData]);

  useComponentDidUpdate(() => {
    if (currentServiceUpdateData) {
      enqueueSnackbar("Updated!", {
        variant: "success",
      });
      setOpen(false);
      dispatch(Subreset())

    }
    let custId = paramsQuery("id");
    dispatch(ServiceListTable(custId));
    if (user?.role == "user") {
      let custRefId = user?.cust_ref_id;
      let payload = {
        cust_ref_id: custRefId,
      };
      dispatch(customerPlanDetails(payload));

    }

    reset();
  }, [currentServiceUpdateData]);

  useComponentDidUpdate(() => {
    if (broadcastAddedMultiple) {
      enqueueSnackbar("Package Added!", {
        variant: "success",
      });
      setOpen(false);
    }
    let custId = paramsQuery("id");
    if (user?.role == "user") {
      let custRefId = user?.cust_ref_id;
      let payload = {
        cust_ref_id: custRefId,
      };
      dispatch(customerPlanDetails(payload));
    }
    dispatch(currentServiceList(custId));
    reset();
    setAccessType("basic");
  }, [broadcastAddedMultiple]);

  useComponentDidMount(() => {
    let filteredforalacart = props?.adminPackageData && props?.adminPackageData?.filter((plan) => plan.billing_type === props?.customerData?.billing_type && plan.disabled == false && plan.package_category == "ala cart");

    if (filteredforalacart) {
      let preparedeletepay = [];
      filteredforalacart.map((data, index) => {

        preparedeletepay.push({
          amount: data.plan_amount,
          name: data.plan_name,
          checked: false,
          _id: data._id,
          billing_type: data.billing_type,
          channel_count: data.channel_count,
          channel_type: data.channel_type,
          created_by: "admin",
          disabled: false,
          package_category: data.package_category,
          plan_validity: data.plan_validity,
          service_type: data.service_type,
          selected: true,
          plan_ref_id: data._id,
        });

      });
      setAlaCartList(preparedeletepay);


    }
    preparedataforbouqt()

  }, [adminAlaCartData, props?.customerData]);


  const preparedataforbouqt = (val) => {
    let list = [];
    let filteredforbroadcaster = props?.adminBroadCatData && props?.adminBroadCatData?.filter((plan) => plan.billing_type === props?.customerData?.billing_type && plan.disabled == false && plan.package_category == "broadcaster");
    filteredforbroadcaster &&
      filteredforbroadcaster?.map((data, index) => {

        list.push({
          checked: false,
          sNo: index + 1,
          _id: data?._id,
          planname: data?.plan_name,
          packagecategory: data?.package_category,
          stbno: data?.stb_no,
          amount: data?.plan_amount,
          category: data?.package_category,
          updated_at: data?.updated_at,
          billing_cycle: data?.billing_cycle,
          channel_type: data?.channel_type,
          channelcount: data?.channel_count,
          plan_ref_id: data?._id,
          service_type: data?.service_type,
          plan_validity: data?.plan_validity,
          billing_type: data?.billing_type,
          channels: data?.channels,
          action: (
            <div className="flex-row justify-center">
              <div className="cursor-pointer" onClick={() => channelPopup(data)}>
                view channel
              </div>
            </div>
          ),
        });


      });
    setbouqlist(list);

  };

  const channelPopup = (data) => {
    setchannelList(true);
    setchannelData(data?.channels);
  };



  const filterObjectApi = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options?.stb_no?.toString() === value?.toString()
      );
    setValue(title, filtered && filtered[0]);
  };

  const filterObjectPlan = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options?.planname?.toString() === value?.toString()
      );
    setValue(title, filtered && filtered[0]);

    console.log(title, options, value, "filteru")
  };

  const filterObjectBroad = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options?.plan_name?.toString() === value?.toString()
      );
    setValue(title, filtered && filtered[0]);

    console.log(title, options, value, "filteru")
  };

  useComponentDidUpdate(() => {
    if (props?.editData?.plan_name) {

      setValue("packageCategory", props?.editData?.package_category);
      setValue("canId", props?.editData?.can_id);
      filterObjectApi("stbNumber", props?.customerData?.stb_no, props?.editData?.stb_no);
      filterObjectPlan("PlanName", basicList, props?.editData?.plan_name);
      filterObjectBroad("PlanNameBroad", props?.adminBroadCatData, props?.editData?.plan_name);
      filterObjectBroad("PlanNamealacart", props?.alacartData, props?.editData?.plan_name)
      setValue("price", props?.editData?.plan_amount);
      setValue("validity", props?.editData?.plan_validity);
      setValueDate(props?.editData?.expired_date)
    }
  }, [props]);

  useComponentDidMount(() => {
    setValue("PlanName", basicList, props?.editData?.plan_name);
    setValue("PlanNameBroad", props?.adminBroadCatData, props?.editData?.plan_name);
    
  }, []);


  return (
    <div>
      <div className="channelpopup">
        <ChannelListPop show={channelList} channelData={channelData} handleClose={handleChannelclose} />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={style} className="makePayment-cntr ">
            <div className="payment-header-title">Edit plan</div>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <div className="make-payment-form-title payment-title-padding">
                  Package Category
                </div>
                <TextField
                  {...register("packageCategory")}
                  disabled
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />

              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <div className="make-payment-form-title payment-title-padding">
                  Can Id
                </div>
                <TextField
                  {...register("canId")}
                  disabled
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <div className="make-payment-form-title payment-title-padding">
                  STB Number
                </div>
                <Controller
                  control={control}
                  name="stbNumber"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                        setValueSTBbouq(item);
                        setValueAla(item)
                      }}
                      value={value || ""}
                      options={props?.customerData?.stb_no || []}
                      isOptionEqualToValue={(option, value) => option.stb_no === value.stb_no}
                      getOptionLabel={(item) => (item.stb_no ? item.stb_no : "")}
                      renderInput={(params) => <TextField {...params} size="small" placeholder="Select STB" />}
                    />
                  )}
                />
              </Grid>

            </Grid>

            <Grid container spacing={2}>
              {
                props?.editData?.package_category === "package" && (
                  <Grid item xs={6} md={6}>
                    <div className="make-payment-form-title payment-title-padding">
                      Package
                    </div>
                    <Controller
                      control={control}
                      name="PlanName"
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, item) => {
                            onChange(item);
                            handlePlanChange(item)
                          }}
                          value={value || ""}
                          options={basicList || []}
                          isOptionEqualToValue={(option, value) => option.planname === value.planname}
                          getOptionLabel={(item) => (item.planname ? item.planname : "")}
                          renderInput={(params) => <TextField {...params} size="small" placeholder="Select Plan" />}
                        />
                      )}
                    />
                  </Grid>
                )
              }
              {
                props?.editData?.package_category === "broadcaster" && (
                  <Grid item xs={6} md={6}>
                    <div className="make-payment-form-title payment-title-padding">
                      Package
                    </div>
                    <Controller
                      control={control}
                      name="PlanNameBroad"
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, item) => {
                            onChange(item);
                            handlePlanChange(item)
                          }}
                          value={value || ""}
                          options={props?.adminBroadCatData || []}
                          isOptionEqualToValue={(option, value) => option.plan_name === value.plan_name}
                          getOptionLabel={(item) => (item.plan_name ? item.plan_name : "")}
                          renderInput={(params) => <TextField {...params} size="small" placeholder="Select Plan" />}
                        />
                      )}
                    />
                  </Grid>
                )
              }

              {
                props?.editData?.package_category === "ala cart" && (
                  <Grid item xs={6} md={6}>
                    <div className="make-payment-form-title payment-title-padding">
                      Package
                    </div>
                    <Controller
                      control={control}
                      name="PlanNamealacart"
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, item) => {
                            onChange(item);
                            handlePlanChange(item)
                          }}
                          value={value || ""}
                          options={props?.alacartData || []}
                          isOptionEqualToValue={(option, value) => option.plan_name === value.plan_name}
                          getOptionLabel={(item) => (item.plan_name ? item.plan_name : "")}
                          renderInput={(params) => <TextField {...params} size="small" placeholder="Select Plan" />}
                        />
                      )}
                    />
                  </Grid>
                )
              }

              <Grid item xs={6} md={6}>
                <div className="make-payment-form-title payment-title-padding">
                  Price
                </div>
                <TextField
                  {...register("price")}
                  disabled
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
              </Grid>


            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <div className="make-payment-form-title payment-title-padding">
                  Validity
                </div>
                <TextField
                  {...register("validity")}
                  disabled
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  id="outlined-basic"
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <div className="make-payment-form-title payment-title-padding">
                  Expiry Date
                </div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker inputFormat="MM/dd/yyyy" value={valueDate} onChange={handleChange} renderInput={(params) => <TextField fullWidth size="small" {...register("date")} placeholder="Enter Expiry Date" {...params} />} />
                </LocalizationProvider>
              </Grid>


            </Grid>
            <Grid item xs={12} md={12}>
              <Stack direction="row" className="flex-end " spacing={2} style={{ marginTop: "1rem" }}>
                <Button size="small" variant="outlined" className="btn-outlined" onClick={handleClose} style={{ textTransform: "none", color: "#00249b" }}>
                  Cancel
                </Button>
                <Button type="submit" size="small" onClick={handleFormValues} variant="contained" className="bg-blue" style={{ textTransform: "none" }}>
                  Update
                </Button>
              </Stack>
            </Grid>
          </Box>
        </Modal>
      </form>
    </div>
  );
}
