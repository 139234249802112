import { useEffect, useRef } from "react";

export const useComponentDidMount = (handler) => {
  return useEffect(() => {
    return handler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useComponentDidUpdate = (handler, deps) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;

      return;
    }

    return handler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export const useComponentWillUnmount = (handler) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(() => handler, []);
};

export const monthConversion = (data) => {
  var months = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  var result = months[data];
  return result;
};

export const paramsQuery = (value) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const data = params.get(value);
  return data;
};
