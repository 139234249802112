import TicketStatus from "@components/TicketStatus";
import "./style.scss";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WifiIcon from '@mui/icons-material/Wifi';
import DescriptionIcon from "@mui/icons-material/Description";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useComponentDidMount, paramsQuery, useComponentDidUpdate } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { ticketOpenStatus, ticketList, chatSave,currentServiceList } from "@reducers/api";
import { useState } from "react";
import { useSnackbar } from "notistack";
import SendIcon from '@mui/icons-material/Send';
import moment from "moment";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { reset } from "@reducers/ticketing";
import BreadCrumb from "@components/Breadcrumb";


const ticketStatusData = [
  {
    id: 1,
    title: "Open Tickets",
    count: 30,
    icon: <DescriptionIcon style={{ fontSize: "2rem", fill: "#00249B" }} />,
  },
  {
    id: 2,
    title: "Solved Tickets",
    count: 28,
    icon: <WifiIcon style={{ fontSize: "2rem", fill: "#00249B" }} />,
  },
  {
    id: 3,
    title: "Pending Tickets",
    count: 13,
    icon: <AccessTimeIcon style={{ fontSize: "2rem", fill: "#00249B" }} />,
  },
  {
    id: 4,
    title: "Online Tickets",
    count: 8,
    icon: <AccessTimeIcon style={{ fontSize: "2rem", fill: "#00249B" }} />,
  }, 
];


let user = JSON.parse(localStorage.getItem("user"));
export default function TicketList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ticketListData, ticketDeleted,chatSaveData } = useSelector((state) => state.ticketing);
  const { currentServiceListData } = useSelector((state) => state.subscriber);
  const [typemsg, settypemsg] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  //View states
  const [currentService, setcurrentService] = useState([]);
  const [chatlistData, setchatlistData] = useState([]);
  const [ticketViewName, setTicketViewName] = useState("");
  const [ticketViewMobile, setTicketViewMobile] = useState("");
  const [ticketViewEmail, setTicketViewEmail] = useState("");
  const [ticketViewAddress, setTicketViewAddress] = useState("");
  const [ticketTitle, setTicketTitle] = useState("");
  const [ticketType, setTicketType] = useState("");
  const [ticketPriority, setTicketPriority] = useState("");
  const [ticketAssignedTo, setTicketAssignedTo] = useState("");
  const [ticketDueDate, setTicketDueDate] = useState("");
  const [ticketDescription, setTicketDescription] = useState("");

  const defaultPlanQuery = {
    serviceType: "cable",
    billingType: "",
  };

  useComponentDidMount(() => {    
    defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
    dispatch(ticketOpenStatus(defaultPlanQuery));
    dispatch(ticketList(`limit=10&offset=0`));
    let custId = paramsQuery("custRefId");
    dispatch(currentServiceList(custId));    
  }, []);

  useComponentDidUpdate(() => {
    let filtered = ticketListData?.data.filter(
      (ticketViewList) => ticketViewList._id === paramsQuery("id")
    );

    setTicketViewName(filtered[0]?.user_name)
    setTicketViewMobile(filtered[0]?.user_mobile_no)
    setTicketViewEmail(filtered[0]?.email_id)
    setTicketViewAddress(filtered[0]?.user_address)
    setTicketTitle(filtered[0]?.title)
    setTicketType(filtered[0]?.type)
    setTicketPriority(filtered[0]?.priority)
    setTicketAssignedTo(filtered[0]?.staff_name)
    setTicketDueDate(filtered[0]?.due_date)
    setTicketDescription(filtered[0]?.description)
    setchatlistData(filtered[0]?.complaint_track)
  }, [ticketListData]);

  useComponentDidUpdate(() => {
    let list = [];
    currentServiceListData &&
      currentServiceListData?.map((data, index) => {
        list.push({         
          sNo: index + 1,
          _id: data?._id,
          amount: data?.plan_amount,
          type: data?.service_type,  
          activationDate:moment(data?.activate_date).format("ll"),
          endDate:moment(data?.expired_date).format("ll"),
          planname:data?.plan_name
        });
      });

    setcurrentService(list);
  }, [currentServiceListData,chatlistData,chatSaveData]);

  useComponentDidUpdate(() => {
 
    if (chatSaveData) {
      enqueueSnackbar("Message sent successfully !", {
        variant: "success",
      });
      dispatch(reset());
      defaultPlanQuery["distributor_id"] = user?.distributor_ref_id;
      dispatch(ticketList(`limit=10&offset=0`));    
    }
  }, [chatSaveData]);


  useComponentDidUpdate(() => {
    if (ticketDeleted) {
      enqueueSnackbar("Ticket Deleted !", {
        variant: "success",
      });
    }
  }, [ticketDeleted]);


  const sendmessage = () => {
    settypemsg(null)
    dispatch(
      chatSave({
        _id:  paramsQuery("id"),
        description:typemsg,
        role:"distributor",
        posted_by:user?.username
      })
    );    
  };

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };

 
  return (
    <div>
      <div className="ticketList-container">
        

        <Grid container spacing={2}>
          <Grid item xs={8}>
          <div className="planHeading">Ticket Status</div>
          </Grid>
          <Grid item xs={4} className="flex-end">
            <div className="employeelist-title">
              <BreadCrumb
                 defaultHeading="Home"
                 handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
                 secondaryHeading="Ticket List"
                 handleSecondaryClick={() => handleBreadCrumbRedirect("ticket-list")}
                 activeHeading={"Ticket Status"}
                
              />
            </div>
          </Grid>
        </Grid>
        <div className="boxshadowticketview">
        <Grid item xs={12} sm={12} md={12} className="textaligncenter ticketviewheader">
              Plan details
            </Grid>
         <div className="planViewCust" style={{paddingRight:'35px'}}>   
            {currentService &&
                    currentService.map((e) => (               
                  <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={2}>            
                    <Grid item xs={12} sm={12} md={12} className="textaligncenter ticketviewheader">
                    <WifiIcon style={{ fontSize: "1rem", fill: "black" }} />{e.planname} 
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} className=" ticketViewCard">
                      <TicketStatus ticketView={true} className=" cardgrey" title={"Amount"} count={e.amount || 0} icon={ticketStatusData[1].icon} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} className=" ticketViewCard">
                      <TicketStatus ticketView={true} className=" cardgrey" title={"Type"} count={e.type || 0} icon={ticketStatusData[1].icon} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} className=" ticketViewCard">
                      <TicketStatus ticketView={true} className=" cardgrey" title={"Activation Date"} count={e.activationDate} icon={ticketStatusData[2].icon} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} className=" ticketViewCard">
                      <TicketStatus ticketView={true} className=" cardgrey" title={"End Date"} count={e.endDate} icon={ticketStatusData[3].icon} />
                    </Grid>           
                  </Grid>
            ))}
          </div>
        </div>
        <div className="">
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={4} md={4.1} className="ticket-view-table-cntr">
            <div className="header-ticket-title justify-space-between flex-row">
              <div>User detail</div>
            </div>
            <div className="padding5px">
               <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="ticket-view-content-user">
                <Grid item xs={6} sm={6} md={6} className="">
                    Name
                </Grid>
                <Grid item xs={6} sm={6} md={6} className="textaligncenter">
                   <span className="userheaderdetails">{ticketViewName}</span>
                </Grid>
              </Grid>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="ticket-view-content-user">
                <Grid item xs={6} sm={6} md={6} className="">
                    Mobile number
                </Grid>
                <Grid item xs={6} sm={6} md={6} className="textaligncenter">
                   <span className="userheaderdetails">{ticketViewMobile}</span>
                </Grid>
              </Grid>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="ticket-view-content-user">
                <Grid item xs={6} sm={6} md={6} className="">
                    Email
                </Grid>
                <Grid item xs={6} sm={6} md={6} className="textaligncenter">
                   <span className="userheaderdetails">{ticketViewEmail}</span>
                </Grid>
              </Grid>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="ticket-view-content-user h-120px">
                <Grid item xs={6} sm={6} md={6} className="">
                    Address
                </Grid>
                <Grid item xs={6} sm={6} md={6} className="textaligncenter">
                   <span className="userheaderdetails">{ticketViewAddress}</span>
                </Grid>
              </Grid>
              </div>
            </Grid>
            <Grid item xs={1} sm={1} md={0.2} className="">
            </Grid>
            <Grid item xs={0} sm={4} md={4.1} className="ticket-view-table-cntr">
            <div className="header-ticket-title justify-space-between flex-row">
              <div>Ticket details</div>
            </div>
              <div className="planHeading">
                  <div className="ticket-view-details">
                	<div className="ticket-view-content">
                    	<p>Ticket title <span className="">{ticketTitle}</span></p>
                    </div>
                    <div className="ticket-view-content">
                    	<p>Ticket type <span className="">{ticketType}</span></p>
                    </div>
                    <div className="ticket-view-content">
                    	<p>Priority <span className="">{ticketPriority}</span></p>
                    </div>
                    <div className="ticket-view-content">
                    	<p>Assigned to <span className="">{ticketAssignedTo}</span></p>
                    </div>
                    <div className="ticket-view-content">
                    	<p> Due Date <span className="">{ticketDueDate}</span></p>
                    </div>
                    <div className="ticket-view-content">
                    	<p>Description  <span className="">{ticketDescription}</span></p>
                    </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={1} sm={1} md={0.2} className="">
            </Grid>
            <Grid item xs={4} sm={4} md={3.4} className="ticket-view-table-cntr">
              <div className="header-ticket-title justify-space-between flex-row">
                <div>Comment</div>
              </div>
              <div class="mesgs messaging">
                    <div class="msg_history">
                    {chatlistData?.map((data, i) => (
                      <div class="incoming_msg ng-scope" style={{paddingLeft:'20px'}}>
                      <div class="incoming_msg_img" style={{paddingTop:'18px'}}>  <AccountCircle className="account-circle" /> </div>
                      <div class="received_msg">
                       <p class="ng-binding">{data?.posted_by}</p>
                       <div class="received_withd_msg">
                         <p class="ng-binding" style={{fontStyle:'italic'}}>{data?.description}.</p>                        
                            </div>
                          </div>
                      </div>
                      ))}  
                    </div>
                    <div class="type_msg" >
                        <div class="chatbox" style={{display:'flex'}}>
                         <TextField
                          placeholder="Type a message"
                          size="small"
                          className="txtwidth" 
                          variant="standard" 
                          id="outlined-basic"
                          value={typemsg !== null ? typemsg : ""}
                          onChange={(e) =>
                            settypemsg(e.target.value)
                          }
                        />                          
                        <div className="sendbtn" style={{padding:'15px',cursor:'pointer'}}   
                                    onClick={(e) =>
                                     sendmessage(e)
                                    }>
                          <SendIcon style={{ fontSize: "1rem", fill: "blue" }} />
                          </div>
                        </div>                       
                    </div>
                  </div>
            </Grid>
          </Grid>  
        </div>
      </div>
    </div>
  );
}
