const tableRows = [
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },
  {
    title: "Ticket ID",
    key: "ticketId",
    align: "left",
  },
  {
    title: "Name",
    key: "name",
    align: "left",
  },
  // {
  //   title: "Team",
  //   key: "team",
  //   align: "left",
  // },
  {
    title: "Staff",
    key: "staff",
    align: "left",
  },
  {
    title: "Title",
    key: "title",
    align: "left",
  },
  {
    title: "Ticket Type",
    key: "ticketType",
    align: "left",
  },
  {
    title: "Priority",
    key: "priority",
    align: "left",
  },
  {
    title: "Status",
    key: "status",
    align: "left",
    justify: "center",
  },
  {
    title: "Due Date",
    key: "dueDate",
    align: "left",
  },
  {
    title: "Created Date",
    key: "createdDate",
    align: "left",
  },
  {
    title: "Action",
    key: "action",
    align: "left",
  },
];

export { tableRows };
