import "./style.scss";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { taxList, taxListUpdate } from "@reducers/api";
import { useForm } from "react-hook-form";
import { useComponentDidMount, useComponentDidUpdate } from "@utils/useEffectHooks";
import { useSnackbar } from "notistack";

export default function SettingsTax() {
  const { enqueueSnackbar } = useSnackbar();

  const {

    register,
    handleSubmit,
    setValue,
   
  } = useForm({
    //resolver: yupResolver(validation),
  });

  const dispatch = useDispatch();
  const { taxListData, taxListUpdateData } = useSelector(
    (state) => state.settings

  );



  useComponentDidUpdate(() => {
    let message = "";
    if (taxListUpdateData) {
      message = "GST updated"
      enqueueSnackbar(message, {
        variant: "success",
      });
      let user = JSON.parse(localStorage.getItem("user"));
      let distributorId = user?.distributor_ref_id;
      dispatch(taxList(distributorId));
    }
  }, [taxListUpdateData]);


  useComponentDidMount(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let distributorId = user?.distributor_ref_id;
    dispatch(taxList(distributorId));
  });

  useComponentDidUpdate(() => {
    setValue("taxName", taxListData[0]?.tax_name);
    setValue("abbreviation", taxListData[0]?.abbreviation);
    setValue("taxNumber", taxListData[0]?.tax_number);
    setValue("taxValue", taxListData[0]?.tax_value);
    setValue("taxIDgst2", taxListData[1]?._id);
    setValue("taxName1", taxListData[1]?.tax_name);
    setValue("abbreviation1", taxListData[1]?.abbreviation);
    setValue("taxNumber1", taxListData[1]?.tax_number);
    setValue("taxValue1", taxListData[1]?.tax_value);
    setValue("taxIDgst1", taxListData[0]?._id);




  }, [taxListData]);

  const updateSubmit1 = (values) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let dist_id = user?.distributor_ref_id;
    let payload = {
      tax_type: "gst1",
      tax_name: values?.taxName,
      abbreviation: values?.abbreviation,
      tax_number: values?.taxNumber,
      tax_value: values?.taxValue,
      distributor_ref_id: dist_id,
      type: "tax",
      _id: values?.taxIDgst1 || ""
    };

    dispatch(taxListUpdate(payload));
  };

  const updateSubmit2 = (values) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let dist_id = user?.distributor_ref_id;
    let payload = {
      tax_type: "gst2",
      tax_name: values?.taxName1,
      abbreviation: values?.abbreviation1,
      tax_number: values?.taxNumber1,
      tax_value: values?.taxValue1,
      distributor_ref_id: dist_id,
      type: "tax",
      _id: values?.taxIDgst2 || ""
    };

    dispatch(taxListUpdate(payload));
  };

  const onSubmit1 = (values) => updateSubmit1(values);
  const onSubmit2 = (values) => updateSubmit2(values);

  return (
    <div className="settingstax-container">
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={4} md={5.5}>
          <form onSubmit={handleSubmit(onSubmit1)}>
            <Grid item xs={12} sm={12} md={12} spacing={4} className="header-tax">
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <div className="settingstax-title">GST1 Details</div>
                </Grid>
              </Grid>
            </Grid>
            <div className="border-sts-pay">
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput createSearchForm">
                <div className="padding-title-addticket">Tax Name</div>
                <TextField
                  {...register("taxIDgst1")}
                  id="outlined-basic"

                  sx={{ display: 'none' }}
                  className="txtwidth"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  {...register("taxName")}


                  id="outlined-basic"
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
                <div className="padding-title-addticket">Abbreviation</div>
                <TextField
                  {...register("abbreviation")}
                  id="outlined-basic"
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
                <div className="padding-title-addticket">Tax Number</div>
                <TextField
                  {...register("taxNumber")}
                  id="outlined-basic"
                  size="small"
                  className="txtwidth"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
                <div className="padding-title-addticket">Tax Value (%)</div>
                <TextField
                  {...register("taxValue")}
                  id="outlined-basic"
                  className="txtwidth"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={2} direction="row" className="settingstaxbtn">
                  <Button type="submit" variant="contained" className="bg-blue setbtnborder">
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </div>
          </form>
        </Grid>
        <Grid item xs={4} sm={4} md={1}></Grid>
        <Grid item xs={4} sm={4} md={5.5}>
          <form onSubmit={handleSubmit(onSubmit2)}>
            <Grid item xs={12} sm={12} md={12} spacing={4} className="header-tax">
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <div className="settingstax-title">GST2 Details</div>
                </Grid>
              </Grid>
            </Grid>
            <div className="border-sts-pay">
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput createSearchForm">
                <div className="padding-title-addticket">Tax Name</div>
                <TextField
                  {...register("taxIDgst2")}
                  sx={{ display: 'none' }}
                  id="outlined-basic"
                  className="txtwidth"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  {...register("taxName1")}
                  id="outlined-basic"
                  className="txtwidth"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
                <div className="padding-title-addticket">Abbreviation</div>
                <TextField
                  {...register("abbreviation1")}
                  id="outlined-basic"
                  className="txtwidth"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
                <div className="padding-title-addticket">Tax Number</div>
                <TextField
                  {...register("taxNumber1")}
                  id="outlined-basic"
                  className="txtwidth"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
                <div className="padding-title-addticket">Tax Value (%)</div>
                <TextField
                  {...register("taxValue1")}
                  id="outlined-basic"
                  className="txtwidth"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={2} direction="row" className="settingstaxbtn">
                  <Button type="submit" variant="contained" className="bg-blue setbtnborder">
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </div>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}
