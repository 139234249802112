import "./styles.scss";
import { useState } from "react";
import { Grid } from "@mui/material";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { customerPlanDetails} from "@reducers/api";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PayNowPopup from "../Modals/customerPayNowPopup";


export default function CustomerPlanView(props) {
  let user = JSON.parse(localStorage.getItem("user"));
  let custId = user?.cust_ref_id;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '70%',
    overflow: 'scroll',
  };

  return (
    <div className="cust-plan-cnt">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='paypop'>
          <PayNowPopup stbNumber={props?.customerData?.stb_no} data={props?.data?.primary_plan} handleClose={handleClose} customerDetails={props?.customerData}/>
        </Box>
      </Modal>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="formCreatePlan">
        <Grid item xs={2} sm={1.5} md={1.5}>  </Grid>
        <Grid item xs={4} sm={4} md={4} className="">
          <label htmlFor="lastName" className="createForm">
            Select STB
          </label>
          <Autocomplete
            style={{ marginTop: '8px' }}
            onChange={(event, item) => {
              dispatch(
                customerPlanDetails({
                  "stb_no": item?.stb_no ? item?.stb_no : "",
                  "cust_ref_id": custId
                })
              )
            }}
            //value={value || ""}
            options={props?.customerData?.stb_no || []}
            isOptionEqualToValue={(option, value) => option.stb_no == value.stb_no || ""}
            getOptionLabel={(item) => (item.stb_no ? item.stb_no : "")}
            getOptionSelected={(option, value) => value === undefined || value === "" || option.stb_no === value.stb_no}
            renderInput={(params) => <TextField {...params} size="small" placeholder="Search by STB" />}
          />
        </Grid>
        <Grid item xs={2} sm={0.5} md={0.5}>  </Grid>
        <Grid item xs={4} sm={4} md={4}>
          {
            <Stack spacing={2} direction="row" className="" style={{ marginTop: '2rem' }}>
              <Button
                size="small"
                variant="contained"
                style={{ textTransform: "capitalize" }}
                className="bg-blue"
                type="submit"
                onClick={(event, item) => {
                  handleOpen()
                  // dispatch(
                  //   PayNowStatusChange({
                  //     "pay_now":true,
                  //     "pay_now_added_date":new Date(),
                  //     "_id":custId
                  //   })
                  // )
                }}
              >
                Pay now
              </Button>
            </Stack>
          }

        </Grid>
      </Grid>

      <Grid container columns={{ xs: 4, sm: 8, md: 12 }} gap={5} justifyContent="center">
        <Grid item xs={4} sm={6} md={0.8} className="titleView-custPlan">
          <div>S.No</div>
        </Grid>
        <Grid container item xs={4} sm={6} md={7} className="titleView-custPlan justify-space-between flex-row">
          <Grid item xs={4} sm={6} md={3}>
            Plan name
          </Grid>
          <Grid item xs={4} sm={6} md={3}>
            Expiry
          </Grid>
        </Grid>
      </Grid>
      {props?.data?.primary_plan?.map((data, index) => {
        return (
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} gap={5} justifyContent="center" sx={{ marginTop: 2 }}>
            <Grid item xs={4} sm={6} md={0.8} className="titleView-custPlan-white">
              <div>{index + 1}</div>
            </Grid>
            <Grid container item xs={4} sm={6} md={7} className="titleView-custPlan-white justify-space-between flex-row">
              <Grid item xs={4} sm={6} md={3}>
                {data?.plan_name}
              </Grid>
              <Grid item xs={4} sm={6} md={3}>
                {moment(data?.expired_date).format("ll")}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
}
