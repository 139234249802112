import "./style.scss";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useComponentDidUpdate } from "@utils/useEffectHooks";
import { useDispatch, useSelector } from "react-redux";
import { ticketList } from "@reducers/api";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/ticketing";
import BreadCrumb from "@components/Breadcrumb";
import SubscriberTab from "@components/SubscriberTab";
import Notification from "./notification";
import Payment from "./payment";


const tabData = [
  { id: 2, title: "Ticket", comp: <Notification /> },
  { id: 3, title: "Payment" ,comp: <Payment/>},
];

export default function TicketList(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ticketDeleted } = useSelector((state) => state.ticketing);
  const { enqueueSnackbar } = useSnackbar();


  useComponentDidUpdate(() => {
    if (ticketDeleted) {
      enqueueSnackbar("Ticket Deleted !", {
        variant: "success",
      
      });
      dispatch(reset());
      dispatch(ticketList(`limit=10&offset=0`));
    }
  }, [ticketDeleted]);

  const handleBreadCrumbRedirect = (path) => {
    navigate(`/distributor/${path}`);
  };
 
  return (
    <div>
      <div className="ticketList-container">
        <Grid container spacing={2}>
        <Grid item xs={8}>
        <div className="planHeading">Notification</div>
        </Grid>
        <Grid item xs={12} md={4} className="flex-end">
          <div className="employeelist-title">
            <BreadCrumb
              defaultHeading="Home"
              handleDefaultClick={() => handleBreadCrumbRedirect("dashboard")}
              activeHeading="Notification"
              handleActiveClick={() => handleBreadCrumbRedirect("ticket-list")}
            />
          </div>
        </Grid>
      </Grid>

      <div className="notification">
        <SubscriberTab data={tabData} />
      </div>
      </div>
    </div>
  );
}
