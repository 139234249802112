const tableRows = [
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },
  {
    title: "Employee ID",
    key: "employeeId",
    align: "left",
  },
  {
    title: "Employee Name",
    key: "employeeName",
    align: "left",
  },
  {
    title: "Account ID",
    key: "accountId",
    align: "left",
  },
  {
    title: "Mobile Number",
    key: "mobile",
    align: "left",
  },
  {
    title: "E-mail",
    key: "email",
    align: "left",
  },
  {
    title: "Role",
    key: "role",
    align: "left",
  },
  {
    title: "Status",
    key: "status",
    align: "left",
    justify: "center",
  },
  {
    title: "Created Date",
    key: "created",
    align: "left",
  },
  {
    title: "Action",
    key: "action",
    align: "left",
  },
];

export { tableRows };
