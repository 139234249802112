import { createSlice } from "@reduxjs/toolkit";
import { distributorList, addDistributor, updateDistributor, validityRenewal, deleteDistributor,getDistributorCount, distributorHome ,resetPwdDist,getWhatsappList} from "./api";

const initialState = {
  distributorListData: [],
  resetPwdDistData: {},
  distributorAdded: false,
  distributorDeleted: false,
  getDistributorCountData:{},
  updatedMsg: null,
  distributorHomeData: [],
  getWhatsappListData:[],
};

export const distributorSlice = createSlice({
  name: "distributor",
  initialState,
  reducers: {
    reset: (state) => {
      state.distributorAdded = false;
      state.resetPwdDistData = false;
      state.distributorDeleted = false;
      state.updatedMsg = null;
      state.distributorListData = [];
      state.getWhatsappListData=[];
      return state;
    },
  },
  extraReducers: {
    [distributorList.pending]: (state) => {},
    [distributorList.fulfilled]: (state, { payload }) => {
      state.distributorListData = payload.data.data;
    },
    [distributorList.rejected]: (state, { payload }) => {},
    [addDistributor.pending]: (state) => {},
    [addDistributor.fulfilled]: (state, { payload }) => {
      state.distributorAdded = payload.data.status;
    },
    [addDistributor.rejected]: (state, { payload }) => {},
    [updateDistributor.pending]: (state) => {},
    [updateDistributor.fulfilled]: (state, { payload }) => {
      state.distributorAdded = payload.data.status;
      state.updatedMsg = payload.data.msg;
    },    
    [updateDistributor.rejected]: (state, { payload }) => {},

    [validityRenewal.pending]: (state) => {},
    [validityRenewal.fulfilled]: (state, { payload }) => {
      state.distributorAdded = payload.data.status;
      state.updatedMsg = payload.data.msg;
    },    
    [validityRenewal.rejected]: (state, { payload }) => {},


    [deleteDistributor.pending]: (state) => {},
    [deleteDistributor.fulfilled]: (state, { payload }) => {
      state.distributorDeleted = payload.data.status;
    },
    [deleteDistributor.rejected]: (state, { payload }) => {},

    [getDistributorCount.pending]: (state) => {},
    [getDistributorCount.fulfilled]: (state, { payload }) => {
      state.getDistributorCountData = payload.data.data;
    },    
    [getDistributorCount.rejected]: (state, { payload }) => {},

    [distributorHome.pending]: (state) => {},
    [distributorHome.fulfilled]: (state, { payload }) => {
      state.distributorHomeData = payload.data.data;
    },    
    [distributorHome.rejected]: (state, { payload }) => {},
    [resetPwdDist.pending]: (state) => {},
    [resetPwdDist.fulfilled]: (state, { payload }) => {
      state.resetPwdDistData = payload.data.status;
    },    
    [resetPwdDist.rejected]: (state, { payload }) => {},

    [getWhatsappList.pending]: (state) => {},
    [getWhatsappList.fulfilled]: (state, { payload }) => {
      state.getWhatsappListData = payload.data?.data;
    },    
    [getWhatsappList.rejected]: (state, { payload }) => {},

   
  },
});

export const { reset } = distributorSlice.actions;

export default distributorSlice.reducer;
