import { useState } from "react";
import * as React from "react";
import "./style.scss";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useForm, Controller } from "react-hook-form";
import TableView from "@components/TableView";
import { tableRows } from "./data";
import Autocomplete from "@mui/material/Autocomplete";
import { COLORS } from "@constants";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { useComponentDidMount, useComponentDidUpdate, paramsQuery, useComponentWillUnmount } from "@utils/useEffectHooks";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { currentServiceList, deleteCurrentServicePlan, currentServiceActiveList, addPlanList, CurrentServiceDelete, currentServiceSTBList, ServiceListTable, adminAllPlans, adminPackageOnly } from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { reset } from "@reducers/plan";
import AddNewPlanModal from "@components/Modals/addNewPlanPopup";
import EditNewPlanModal from "@components/Modals/editNewPlanPopup";

export default function CurrentServiceTable(props) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { currentServiceListData, currentServiceActiveListData, deleteCurrentServicePlanData, subviewData, CurrentServiceDeleteData, serviceTableList } = useSelector((state) => state.subscriber);
  const { addPlanListData } = useSelector((state) => state.customer);
  const { adminAllPlansData, adminPackageData, packageOnlyData } = useSelector((state) => state.plan);
  const [currentService, setcurrentService] = useState([]);
  const [show, setShow] = React.useState(false);
  const [editDatas, seteditShow] = React.useState([]);
  const [showReset, setShowReset] = useState(false);
  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [checkedVal, setChecked] = useState(false);
  const onShowPopUp = () => {
    setShow(!show);
  };

  useComponentWillUnmount(() => {
    dispatch(reset());
  });

  const onShowResetPasswordPopUp = (datas) => {
    
    seteditShow(datas);
    setShowReset(true);
  };

  const closepoup = () => {
    setShowReset(false);
  }

  const handleMultipleDelete = () => {
    let filtered = currentService.filter((options) => options.checked === true);
    if (filtered.length == 0) {
      enqueueSnackbar("Please select atlease one to delete!", {
        variant: "error",
      });
      return;
    }
    let preparedeletepay = [];
    filtered.map((data, index) => {
      preparedeletepay.push(data._id);
    });
    const payload = {
      ids: preparedeletepay,
    };
    dispatch(CurrentServiceDelete(payload));
  };

  const handleActivate = () => {
    let user = JSON.parse(localStorage.getItem("user"));

    let filtered = currentService?.filter((serviceList) => serviceList.checked === true);

    if (filtered.length <= 0) {
      let message = "Please select atleast one plan";
      enqueueSnackbar(message, {
        variant: "error",
      });
    }

    var service = filtered.map(function (data) {
      return {
        is_deleted: false,
        billing_cycle: data?.billing_cycle,
        _id: data._id,
        updated_at: data?.updated_at,
        activate_date: data?.activate_date,
        channel_type: data?.channel_type,
        channel_count: data?.channel_count,
        plan_ref_id: data?.plan_ref_id,
        stbno: data?.stb_no,
        expired_date: data?.expireddate,
        service_type: data?.service_type,
        plan_validity: data?.plan_validity,
        billing_type: data?.billing_type,
        package_category: data?.category,
        plan_name: data?.planname,
        plan_amount: data?.amount,
        distributor_ref_id: data?.distributor_ref_id,
        cust_email_id: data?.cust_email_id,
        cust_name: data?.cust_name,
        cust_ref_id: data?.cust_ref_id,
        disabled: false,
        __v: data?.__v,
        created_at: data?.createddate,

        is_active: true,
      };
    });

    let payload = { is_active: true };
    let distributorId = user?.cust_ref_id;
    dispatch(currentServiceActiveList(service));
  };

  const handleDeactivate = () => {
    let user = JSON.parse(localStorage.getItem("user"));

    let filtered = currentService?.filter((serviceList) => serviceList.checked === true);

    if (filtered.length <= 0) {
      let message = "Please select atleast one plan";
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
    var service = filtered.map(function (data) {
      return {
        is_deleted: false,
        billing_cycle: data?.billing_cycle,
        _id: data?._id,
        updated_at: data?.updated_at,
        activate_date: data?.activate_date,
        channel_type: data?.channel_type,
        channel_count: data?.channel_count,
        plan_ref_id: data?.plan_ref_id,
        stbno: data?.stb_no,
        expired_date: data?.expireddate,
        service_type: data?.service_type,
        plan_validity: data?.plan_validity,
        billing_type: data?.billing_type,
        package_category: data?.category,
        plan_name: data?.planname,
        plan_amount: data?.amount,
        distributor_ref_id: data?.distributor_ref_id,
        cust_email_id: data?.cust_email_id,
        cust_name: data?.cust_name,
        cust_ref_id: data?.cust_ref_id,
        disabled: false,
        __v: data?.__v,
        created_at: data?.createddate,

        is_active: false,
      };
    });
    let payload = { is_active: false };
    let distributorId = user?.cust_ref_id;
    dispatch(currentServiceActiveList(service));
  };

  useComponentDidMount(() => {
    let custId = paramsQuery("id");
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `cust_ref_id=${custId}&stb_no=${watch("addStb")?.stb_no || ""}`;
  

    // dispatch(currentServiceList(custId));
    dispatch(ServiceListTable(custId));
    dispatch(adminPackageOnly(`distributor_ref_id=${user?.distributor_ref_id}&service_type=${props?.customerData?.service_type}&billing_type=${props?.customerData?.billing_type}`));
    dispatch(adminAllPlans(`package_category=broadcaster,ala cart&service_type=${props?.customerData?.service_type}&billing_type=${props?.customerData?.billing_type}&disabled=false`));
    
  
  }, []);

  useComponentDidUpdate(() => {
  
    if (watch("addStb")) {
      let custId = paramsQuery("id");
      let query = `cust_ref_id=${custId}&stb_no=${watch("addStb")?.stb_no || ""}`;
      dispatch(currentServiceSTBList(query));
    }
  }, [watch("addStb")]);
  console.log(watch("addStb")?.stb_no, "adddstab")

  useComponentDidUpdate(() => {
    let message = "";
    if (currentServiceActiveListData) {
      if (currentServiceActiveListData?.is_active) {
        message = "Plan activated";
      } else {
        message = "Plan deactivated";
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      dispatch(reset());
      let user = JSON.parse(localStorage.getItem("user"));
      let custId = paramsQuery("id");
      dispatch(currentServiceList(custId));
      dispatch(ServiceListTable(custId));
    }
  }, [currentServiceActiveListData]);

  useComponentDidUpdate(() => {
    let message = "";
    if (CurrentServiceDeleteData) {
      message = "Data deleted";
      enqueueSnackbar(message, {
        variant: "success",
      });
      let custId = paramsQuery("id");
      dispatch(reset());
      dispatch(currentServiceList(custId));
      dispatch(ServiceListTable(custId));
    }
  }, [CurrentServiceDeleteData]);

  useComponentDidUpdate(() => {
    let message = "";
    if (deleteCurrentServicePlanData) {
      message = "Data deleted";
      enqueueSnackbar(message, {
        variant: "success",
      });
      let custId = paramsQuery("id");
      dispatch(reset());
      dispatch(currentServiceList(custId));
      dispatch(ServiceListTable(custId));
    }
  }, [deleteCurrentServicePlanData]);


  useComponentDidUpdate(() => {
    let list = [];
    serviceTableList &&
    serviceTableList?.map((data, index) => {
        list.push({
          checked: false,
          sNo: index + 1,
          _id: data?._id,
          planname: data?.plan_name,
          packagecategory: data?.package_category,
          stbno: data?.stb_no,
          amount: data?.plan_amount,
          createddate: moment(data?.created_at).format("ll"),
          expireddate: moment(data?.expired_date).format("ll"),
          category: data?.package_category,
          updated_at: data?.updated_at,
          billing_cycle: data?.billing_cycle,
          channel_type: data?.channel_type,
          channel_count: data?.channel_count,
          plan_ref_id: data?.plan_ref_id,
          service_type: data?.service_type,
          plan_validity: data?.plan_validity,
          billing_type: data?.billing_type,
          distributor_ref_id: data?.distributor_ref_id,
          cust_email_id: data?.cust_email_id,
          cust_name: data?.cust_name,
          cust_ref_id: data?.cust_ref_id,
          __v: data?.__v,
          status: data?.is_active ? <div className="status-btn-table-active"> Active </div> : <div className="status-btn-table-inactive"> InActive </div>,
          action: (
            <div className="flex-row justify-center">
              <div
              onClick={() => onShowResetPasswordPopUp(data)}
              className="cursor-pointer"
            >
              <EditIcon />
            </div>
              <div
                className="cursor-pointer"
                onClick={() =>
                  dispatch(
                    deleteCurrentServicePlan({
                      _id: data?._id,
                    })
                  )
                }
              >
                <DeleteIcon />
              </div>
            </div>
          ),
        });
      });

    setcurrentService(list);
  }, [serviceTableList]);

  const handleCheckBox = (index, checked) => {
    const clonedDataService = [...currentService];
    if (index === 0 || index > 0) {
      clonedDataService[index].checked = clonedDataService[index].checked ? false : true;
    } else if (index === "all") {
      for (let services of clonedDataService) {
        if (checked) {
          services.checked = true;
        } else {
          services.checked = false;
        }
      }
    }
    setChecked(checked)
    setcurrentService(clonedDataService);
  };

  const queryGenerator = (value) => {

    let query = ``;
    if (value?.type === "search") {
      query += `&value=${value?.state}`;
    }

    return query;
  };

  const handleSearch = (search) => {
    setPage(1);
    setSearchQuery(search);
    let query = queryGenerator({ type: "search", state: search });
    dispatch(addPlanList(query));
  };

  const stbFilter = (item) => {
    if (watch("addStb")?.stb_no) {
      let custId = paramsQuery("id");
      let query = `cust_ref_id=${custId}&stb_no=${item?.stb_no ? item?.stb_no : ""}`;
      dispatch(currentServiceSTBList(query));
    }else{
      let custId = paramsQuery("id");
      let query = `cust_ref_id=${custId}`;
      dispatch(currentServiceSTBList(query));
    }
  };
  
  let filteredforbroadcaster = adminPackageData && adminPackageData?.filter((plan) => plan.billing_type === props?.customerData?.billing_type && plan.disabled == false && plan.package_category == "broadcaster");
  let filteredforalacart = adminPackageData && adminPackageData?.filter((plan) => plan.billing_type === props?.customerData?.billing_type && plan.disabled == false && plan.package_category == "ala cart");

  const handleSearchPackage = (search) => {
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(adminPackageOnly(`&value=${search}&distributor_ref_id=${user?.distributor_ref_id}&service_type=${props?.customerData?.service_type}&billing_type=${props?.customerData?.billing_type}`));
  };

  const addPlanPopup = () => {
    onShowPopUp(true)
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(adminPackageOnly(`distributor_ref_id=${user?.distributor_ref_id}&service_type=${props?.customerData?.service_type}&billing_type=${props?.customerData?.billing_type}`));
  };


  useComponentDidMount(() => {

    filterObject(
      "addStb",
      props?.customerData?.stb_no,
      props?.customerData?.stb_no &&  props?.customerData?.stb_no[0]
    );
  }, []);

  const filterObject = (title, options, value) => {
    console.log(value, "optionss")
    if (value === "" || value === undefined) return;
    let filtered = options && options.filter(
        (options) => options?.stb_no?.toString() === value?.stb_no?.toString()
      );
    setValue(title, filtered[0]);
  };

  return (
    <div className="sub-addontv-form">
      <AddNewPlanModal show={show} data={addPlanListData} broadcastData={[]} customerData={props?.customerData} handleClose={onShowPopUp} handleChange={handleSearch} adminPackageData={packageOnlyData} currentServiceTable={currentService}  handleSearchPackage={(search) => handleSearchPackage(search)}/>
      <EditNewPlanModal show={showReset} handleClose={closepoup} editData={editDatas} data={addPlanListData} broadcastData={[]} customerData={props?.customerData} handleChange={handleSearch} adminPackageData={packageOnlyData} adminBroadCatData={filteredforbroadcaster} alacartData={filteredforalacart}/>
      <Grid container columns={{ xs: 4, sm: 8, md: 8 }} className="flex-end padding-btw-tiles paddingbottom">
        {props?.customerData?.service_type == "cable" && (
          <Grid item xs={1} md={1}>
            <Controller
              control={control}
              name="addStb"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, item) => {
                    onChange(item);
                    stbFilter(item);
                  }}
                  size="small"
                  value={value || ""}
                  options={props?.customerData?.stb_no || []}
                  defaultValue={props?.customerData?.stb_no[0]}
                  isOptionEqualToValue={(option, value) => option.stb_no === value.stb_no}
                  getOptionLabel={(item) => (item.stb_no ? item.stb_no : "")}
                  renderInput={(params) => <TextField {...params} size="small" placeholder="STB" />}
                />
              )}
            />
          </Grid>
        )}
        &nbsp;&nbsp;&nbsp;
        <Stack spacing={2} direction="row" className=" ">
          <Button variant="contained" sx={{ textTransform: "none" }} size="small" className="bg-blue" onClick={addPlanPopup}>
            Add plan
          </Button>

          <Button variant="contained" sx={{ textTransform: "none" }} size="small" className="bg-green" onClick={handleActivate}>
            Activate
          </Button>
          <Button variant="contained" sx={{ textTransform: "none" }} size="small" className="bg-red" onClick={handleDeactivate}>
            In Activate
          </Button>

          <Button variant="contained" sx={{ textTransform: "none" }} size="small" className="bg-blue" onClick={handleMultipleDelete}>
            Remove
          </Button>
        </Stack>
      </Grid>

      <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="padding-btw-tiles">
        <TableView rows={tableRows} data={currentService} color={COLORS.primary} handleCheckBoxChange={handleCheckBox} checkedVal={checkedVal} />
      </Grid>
    </div>
  );
}
