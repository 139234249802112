import "./styles.scss";
import { Grid } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";

export default function TileAndIconTile(props) {
  return (
    <div className="tle-icn-ctr">
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={3} sm={7} md={10} className="flex-center">
          <div className="tle-icn-title">{props?.title}</div>
        </Grid>
        <Grid item xs={1} sm={1} md={2} className="flex-center">
          <div className="flex-row">
            <GroupsIcon style={{ color: props?.color }} />
            <div className="tle-icn-count">{props?.count}</div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
