import "./style.scss";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { settingsPayment , settingsPaymentGet} from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useComponentDidMount, useComponentDidUpdate } from "@utils/useEffectHooks";





const validation = Yup.object().shape({});



export default function SettingsPayment(props) {
  const { enqueueSnackbar } = useSnackbar();

const { paymentData} = useSelector((state) => state.settings);
const { settingsPaymentData} = useSelector((state) => state.subscriber);

useComponentDidMount(() => {
  let user = JSON.parse(localStorage.getItem("user"));
  let distributorId = user?.distributor_ref_id;
  dispatch(settingsPaymentGet(distributorId));
});

useComponentDidUpdate(() => {

  let message = "";
  if (paymentData) {
      message = "Payment Success";
    
    enqueueSnackbar(message, {
      variant: "success",
    });
  }
}, [paymentData]);



  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(validation),
  });
  const onSubmit = (data) => handleFormValues(data);

  useComponentDidUpdate(() => {
    setValue("apiKey", settingsPaymentData?.api_key);
    setValue("secretKey", settingsPaymentData?.secret_key);
  
  }, [settingsPaymentData]);


  const handleFormValues = (values) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let id = user?.distributor_ref_id;

    let payload = {
      "api_key": values.apiKey,
      "secret_key": values.secretKey,
      "distributor_ref_id": id,
      "type": "payment",
      "_id": settingsPaymentData?._id || "",
    };
    
      dispatch(settingsPayment(payload));
    
  };


  return (
    <div className="settingspayment-container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={4} md={4.2}>
            <Grid item xs={12} sm={12} md={12} spacing={4} className="createHeader headerpayment">
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <div className="settingspayment-title">Payment</div>
                </Grid>
              </Grid>
            </Grid>
            <div className="border-sts-pay">
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput createSearchForm">
                <div className="padding-title-addticket">API Key</div>
                <TextField
                  {...register("apiKey")}
                  size="small"
                  className="txtwidth"
                  type="text"
                  variant="outlined"
                  id="outlined-basic"
                  placeholder="Enter api key"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={4} className="paddingInput">
                <div className="padding-title-addticket">Secret Key</div>
                <TextField
                  {...register("secretKey")}
                  size="small"
                  className="txtwidth"
                  type="text"
                  variant="outlined"
                  id="outlined-basic"
                  placeholder="Enter secret key"
                />
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={2} direction="row" className="settingspaymentbtn">
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    className="bg-blue setbtnborder"
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
