const tableRows = [
  {
    key: "checkbox",
    align: "left",
  },
  {
    title: "S.No",
    key: "sNo",
    align: "left",
  },
  {
    title: "Name",
    key: "name",
    align: "left",
  },
  {
    title: "Acc No ",
    key: "accNo",
    align: "left",
  },
  {
    title: "CAN ID",
    key: "canId",
    align: "left",
  },
  {
    title: "STB No",
    key: "stbNo",
    align: "left",
  },
  {
    title: "Service Type",
    key: "serviceType",
    align: "left",
  },
  {
    title: "Billing Type",
    key: "billingType",
    align: "left",
  },
  {
    title: "Auto Bill",
    key: "autobill",
    align: "left", 
  },
  {
    title: "Area",
    key: "area",
    align: "left",
  },
  {
    title: "Sub Area",
    key: "subArea",
    align: "left",
  },
  {
    title: "Assigned To",
    key: "assignedemp",
    align: "left",
  },
  {
    title: "Flat",
    key: "flat",
    align: "left",
  },
  {
    title: "Floor",
    key: "floor",
    align: "left",
  },
 
  {
    title: "City",
    key: "city",
    align: "left",
    
  },
  {
    title: "State",
    key: "state",
    align: "left",
    
  },

  {
    title: "Created Date",
    key: "status",
    align: "left",
    justify: "center",
  },
  {
    title: "Action",
    key: "action",
    align: "left",
  },
];

export { tableRows };
