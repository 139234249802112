import * as React from "react";
import { Grid } from "@mui/material";
import "./style.scss";
import SortIcon from "@mui/icons-material/Sort";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { alaCartList } from "@reducers/api";
import {  useComponentDidUpdate } from "@utils/useEffectHooks";
import Stack from "@mui/material/Stack";

const validation = Yup.object({
  primary: Yup.object().required("Required"),
});

export default function PlanListFilter(props) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });
  const onSubmit = (data) => {
    props?.handleFormValues(data);
  };
  const {  alaCartListData } = useSelector((state) => state.plan);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [priority, setPriority] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [broadcastCat, setBroadcast] = React.useState(null);
  const [planName, setplanName] = React.useState(null);
  const [category, setCategory] = React.useState(null);
  const [activeVal, setActive] = React.useState(null);
  const dispatch = useDispatch();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSearcch = (search) => {

    props?.handleFormValues(priority, status, category, broadcastCat, activeVal, planName)
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleResetForm = (search) => {
    props?.handleResetClick()
    setAnchorEl(null);
    reset();
    setPriority("")
    setStatus("")
    setBroadcast("")
    setplanName("")
    setCategory("")
    setActive("")
  };

  useComponentDidUpdate(() => {

    if (category?.value === "package") {
      let user = JSON.parse(localStorage.getItem("user"));
      dispatch(alaCartList(`distributor_ref_id=${user?.distributor_ref_id}&package_category=package`));

    }
    if (category?.value === "ala cart") {
      let user = JSON.parse(localStorage.getItem("user"));
      dispatch(alaCartList(`&package_category=ala cart`));

    }
    if (category?.value === "broadcaster") {
      let user = JSON.parse(localStorage.getItem("user"));
      dispatch(alaCartList(`&package_category=broadcaster`));

    }


  }, [category]);

  console.log(watch("primary"), "category")

  return (
    <div className="">

      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={4} md={1}>
          <Button
            id="demo-customized-button"
            aria-controls="demo-customized-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
          //endIcon={<KeyboardArrowDownIcon />}
          >
            {" "}
            <SortIcon className="sortcolor" />
            Filters
          </Button>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        {
          props?.isAddOn ? (
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              className="searchbyfilter"
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              Search by Filter

              <Grid item xs={12} className="filterHeight" style={{ padding: '15px' }}>
                <label htmlFor="lastName" className="createForm">
                  {props?.labelPrimary}
                </label>
                <Controller
                  control={control}
                  name="primarystate"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                        setPriority(item)
                      }}
                      value={value || ""}
                      options={props?.PrimaryOption}
                      isOptionEqualToValue={(option) => option.label == value?.label || ""}
                      getOptionLabel={(item) => (item.label ? item.label : "")}
                      getOptionSelected={(option, value) =>
                        value === undefined ||
                        value === "" ||
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Option"
                          size="small"
                        />
                      )}
                    />
                  )}
                />
                <div className="field-error">{errors.primary?.message}</div>
              </Grid>


              <Grid item xs={12} className=" txtright">
                <Button
                  type="button"
                  variant="contained"
                  className="bg-blue"
                  style={{ textTransform: "none" }}
                  size="small"
                  onClick={handleClickSearcch}
                >
                  Search
                </Button>
              </Grid>

            </Menu>
          )
            :
            (
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                className="searchbyfilter"
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >

                {
                  props?.labelPrimary && (
                    <Grid item xs={12} className="filterHeight" style={{ padding: '15px' }}>
                      <label htmlFor="lastName" className="createForm">
                        {props?.labelPrimary}
                      </label>
                      <Controller
                        control={control}
                        name="primary"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, item) => {
                              onChange(item);
                              setPriority(item)
                            }}
                            value={value || ""}
                            options={props?.PrimaryOption}
                            isOptionEqualToValue={(option) => option.label == value?.label || ""}
                            getOptionLabel={(item) => (item.label ? item.label : "")}
                            getOptionSelected={(option, value) =>
                              value === undefined ||
                              value === "" ||
                              option.id === value.id
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Option"
                                size="small"
                              />
                            )}
                          />
                        )}
                      />
                      <div className="field-error">{errors.primary?.message}</div>
                    </Grid>
                  )
                }


                {
                  props?.labelThird && (
                    <Grid item xs={12} className="filterHeight" style={{ padding: '15px' }}>
                      <label htmlFor="lastName" className="createForm">
                        {props?.labelThird}
                      </label>
                      <Controller
                        control={control}
                        name="third"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, item) => {
                              onChange(item);
                              setCategory(item)
                            }}
                            value={value || ""}
                            options={props?.thirdOption}
                            isOptionEqualToValue={(option) => option.label == value?.label || ""}
                            getOptionLabel={(item) => (item.label ? item.label : "")}
                            getOptionSelected={(option, value) =>
                              value === undefined ||
                              value === "" ||
                              option.id === value.id
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Option"
                                size="small"
                              />
                            )}
                          />
                        )}
                      />
                      <div className="field-error">{errors.primary?.message}</div>
                    </Grid>
                  )


                }
                {
                  props?.broadcast && watch("primary") && watch("primary")?.value === "cable" && (
                    <Grid item xs={12} className="filterHeight" style={{ padding: '15px' }}>
                      <label htmlFor="lastName" className="createForm">
                        {props?.broadcast}
                      </label>
                      <Controller
                        control={control}
                        name="broadcaster"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, item) => {
                              onChange(item);
                              setBroadcast(item)
                            }}
                            value={value || ""}
                            options={props?.broadCastOption && props?.broadCastOption?.broadcasterList}
                            isOptionEqualToValue={(option) => option == value || ""}
                            getOptionLabel={(item) => (item ? item : "")}
                            getOptionSelected={(option, value) => value === undefined || value === "" || option.id === value.id}
                            renderInput={(params) => (
                              <TextField {...params} size="small" placeholder="Select Option" />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  )

                }
                {
                  props?.SecondaryOption && (
                    <Grid item xs={12} className="filterHeight" style={{ padding: '15px' }}>
                      <label htmlFor="lastName" className="createForm">
                        {props?.labelSecondary}
                      </label>
                      <Controller
                        control={control}
                        name="secondary"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, item) => {
                              onChange(item);
                              setStatus(item)
                            }}
                            value={value || ""}
                            options={props?.SecondaryOption}
                            isOptionEqualToValue={(option) => option.label == value?.label || ""}
                            getOptionLabel={(item) => (item.label ? item.label : "")}
                            getOptionSelected={(option, value) =>
                              value === undefined ||
                              value === "" ||
                              option.id === value.id
                            }
                            renderInput={(params) => (
                              <TextField {...params} size="small" placeholder="Select Option" />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  )

                }

                {
                  props?.labelActive && (
                    <Grid item xs={12} className="filterHeight" style={{ padding: '15px' }}>
                      <label htmlFor="lastName" className="createForm">
                        {props?.labelActive}
                      </label>
                      <Controller
                        control={control}
                        name="activestate"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, item) => {
                              onChange(item);
                              setActive(item)
                            }}
                            value={value || ""}
                            options={props?.activeOption}
                            isOptionEqualToValue={(option) => option.label == value?.label || ""}
                            getOptionLabel={(item) => (item.label ? item.label : "")}
                            getOptionSelected={(option, value) =>
                              value === undefined ||
                              value === "" ||
                              option.id === value.id
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Option"
                                size="small"
                              />
                            )}
                          />
                        )}
                      />
                      <div className="field-error">{errors.primary?.message}</div>
                    </Grid>
                  )
                }
                   {
                  props?.planName && category &&(
                    <Grid item xs={12} className="filterHeight" style={{ padding: '15px' }}>
                      <label htmlFor="lastName" className="createForm">
                        {props?.planName}
                      </label>
                      <Controller
                        control={control}
                        name="secondary"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, item) => {
                              onChange(item);
                              setplanName(item)
                            }}
                            value={value || ""}
                            options={alaCartListData}
                            isOptionEqualToValue={(option) => option == value || ""}
                            getOptionLabel={(item) => (item?.plan_name ? item?.plan_name : "")}
                            getOptionSelected={(option, value) => value === undefined || value === "" || option.id === value.id}
                            renderInput={(params) => (
                              <TextField {...params} size="small" placeholder="Select Option" />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  )

                }

                <Grid item xs={12} className=" txtright">
                  <Stack direction="row" className="flex-end btns-stack" spacing={2}>
                    <Button
                      type="button"
                      variant="contained"
                      className="bg-blue"
                      style={{ textTransform: "none" }}
                      size="small"
                      onClick={handleClickSearcch}
                    >
                      Search
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      className="bg-blue"
                      style={{ textTransform: "none" }}
                      size="small"
                      onClick={handleResetForm}
                    >
                      Reset
                    </Button>
                  </Stack>
                </Grid>
              </Menu>
            )
        }

      </form>
    </div>
  );
}
