import * as React from "react";
import { Grid } from "@mui/material";
import "./style.scss";
import SortIcon from "@mui/icons-material/Sort";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ticketList } from "@reducers/api";
import Stack from "@mui/material/Stack";
import { SubscriberListAssigned } from "@reducers/api";
import moment from "moment";
import { useState } from "react";


const validation = Yup.object({
  primary : Yup.object().required("Required"),
});

export default function TicketFilter(props) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });
  
  const handleClickSearcch = (search) => {

    props?.handleFormValues(area, subArea,  assignedTo, autoBillGenerate)
    setAnchorEl(null);
  };

  const onSubmit = (data) => {
    props?.handleFormValues(data);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  
  
  const [area, setArea] = React.useState(null);
  const [subArea, setSubArea] = React.useState(null);
  const [assignedTo, setAssignedTo] = React.useState(null);
  const [autoBillGenerate, setAutoBill] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
   
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };  

  const handleResetForm = (search) => {
    props?.handleResetClick()
    setAnchorEl(null);
    reset();
    setArea("")
    setSubArea("")
    setAssignedTo("")
    setAutoBill("")
  };

  const open = Boolean(anchorEl);

  return (
    <div className="">
     
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={4} md={1}>
            <Button
              id="demo-customized-button"
              aria-controls="demo-customized-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              //endIcon={<KeyboardArrowDownIcon />}
            >
              {" "}
              <SortIcon className="sortcolor" />
              Filters
            </Button>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          className="searchbyfilter"
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem>Search by filter</MenuItem>

          
          
            
          
            <Grid item xs={12} className="filterHeight p-10">
              <label htmlFor="lastName" className="createForm">
                {props?.area}
              </label>
              <Controller
                control={control}
                name="area"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                      setArea(item)
                    }}
                    value={value || ""}
                    options={props?.areaData?.area || []}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Option"
                        size="small"
                      />
                    )}
                  />
                )}
              />
              <div className="field-error">{errors.primary?.message}</div>
            </Grid>
            <Grid item xs={12} className="filterHeight p-10">
              <label htmlFor="lastName" className="createForm">
                {props?.subArea}
              </label>
              <Controller
                control={control}
                name="subArea"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                      setSubArea(item)
                    }}
                    value={value || ""}
                    options={props?.areaData?.subarea || []}
                    isOptionEqualToValue={(option, value) => option?.label === value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Option"
                        size="small"
                      />
                    )}
                  />
                )}
              />
              <div className="field-error">{errors.primary?.message}</div>
            </Grid>


            <Grid item xs={12} className="filterHeight p-10">
              <label htmlFor="lastName" className="createForm">
                {'Select Assgined To'}
              </label>
              <Controller
                control={control}
                name="assignedTo"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                      setAssignedTo(item)
                    }}
                    value={value || ""}
                    options={props?.empList || []}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Assign to"
                        size="small"
                      />
                    )}
                  />
                )}
              />
              <div className="field-error">{errors.primary?.message}</div>
            </Grid>

            <Grid item xs={12} className="filterHeight p-10">
              <label htmlFor="lastName" className="createForm">
                {props?.autoBill}
              </label>
              <Controller
                control={control}
                name="autobill"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      onChange(item);
                      setAutoBill(item)
                    }}
                    value={value || ""}
                    options={props?.autoBillOption || []}
                    isOptionEqualToValue={(option, value) => option?.label === value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Option"
                        size="small"
                      />
                    )}
                  />
                )}
              />
              
            </Grid>

            <Grid item xs={12} className=" txtright">
              <Stack direction="row" className="flex-end btns-stack" spacing={2}>
              <Button
                type="button"
                variant="contained"
                className="bg-blue"
                style={{ textTransform: "none" }}
                size="small"
                onClick={handleClickSearcch}
              >
                Search
              </Button>
              <Button
                type="button"
                variant="contained"
                className="bg-blue"
                style={{ textTransform: "none" }}
                size="small"
                onClick={handleResetForm}
              >
                Reset
              </Button>
              </Stack>
            </Grid>
         
        </Menu>
      </form>
    </div>
  );
}
