import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { COLORS } from "@constants";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const drawerWidth = 200;
const drawerMarginTop = "64px";

export default function PermanentDrawerLeft(props) {
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  let currentPath = "";

  if (pathName === "/admin/dashboard") {
    currentPath = "Dashboard";
  } else if (pathName === "/admin/distributor-list" || pathName === "/admin/add-distributor" || pathName === "/admin/add-distributor/:id") {
    currentPath = "Distributor";
  } else if (pathName === "/admin/plan-list" || pathName === "/admin/add-plan") {
    currentPath = "Plans";
  } else if (pathName === "/distributor/dashboard") {
    currentPath = "Dashboard";
  } else if (pathName === "/distributor/employee-list" || pathName === "/distributor/add-dist-employee") {
    currentPath = "Employee";
  } else if (pathName === "/distributor/ticket-list" || pathName === "/distributor/add-ticket" || pathName === "/distributor/ticket-view") {
    currentPath = "Ticketing";
  } else if (pathName === "/distributor/enquiry-list" || pathName === "/distributor/add-enquiry") {
    currentPath = "Enquiry";
  } else if (pathName === "/distributor/create-expense" || pathName === "/distributor/expenses-list") {
    currentPath = "Expenses";
  } else if (pathName === "/distributor/notification") {
    currentPath = "Notification";
  } else if (pathName === "/distributor/subscriber-list" || pathName === "/distributor/subscriber-edit" || pathName === "/distributor/subscriber-profile") {
    currentPath = "Subscriber";
  } else if (pathName === "/distributor/inventery-list") {
    currentPath = "Inventory";
  } else if (pathName === "/distributor/subscriber-plan") {
    currentPath = "Subscriber Plan";
  } else if (pathName === "/distributor/delete-subscriber") {
    currentPath = "Delete Subscriber";
  } else if (pathName === "/distributor/delete-restore-subscriber") {
    currentPath = "Delete Restore Subscriber";
  } else if (pathName === "/distributor/assign-subscriber-list") {
    currentPath = "Assign Subscriber";
  } else if (pathName === "/distributor/ready-to-pay-list") {
    currentPath = "Ready To Pay";
  } else if (pathName === "/distributor/collection-list") {
    currentPath = "Collection list";
  } else if (pathName === "/distributor/receipt-list") {
    currentPath = "Receipt list";
  }else if (pathName === "/distributor/outstanding-list") {
    currentPath = "Outstanding list";
  }
   else if (
    pathName === "/distributor/paid-list" ||
    pathName === "/distributor/unpaid-list" ||
    pathName === "/distributor/invoice-list" ||
    pathName === "/distributor/generate-bill"
  ) {
    currentPath = "Billing";
  }   
  else if (pathName === "/distributor/settings") {
    currentPath = "Settings";
  } 

  const handleRedirect = (path, subitem, index) => {
    if (subitem && subitem.length > 0) return handleClick(index);
    navigate(path);
  };

  const [open, setOpen] = React.useState(null);

  const handleClick = (index) => {
    if (open === "") {
      setOpen(index);
    } else if (open !== index) {
      setOpen(index);
    } else {
      setOpen("");
    }
  };

  return (
    <Box className={props?.status ? "sidebarDrawerOpen" : "sidebarDrawerClose"}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          marginTop: drawerMarginTop,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            marginTop: drawerMarginTop,
            boxSizing: "border-box",
            backgroundColor: COLORS.primary,
          },
        }}
        variant="permanent"
        anchor="left"
        className={"sidebarDrawer rounded-left"}
      >
        <div style={{ paddingTop: '1.5rem' }}>
        <List sx={{overflowY: 'auto', height:'70vh'}}>
          {props.sidebarData.map(({ icon, title, path, subitem }, index) => (
            <div className="sidebarDisplay">
              <ListItem button key={icon} className="d-flex listItem" onClick={() => handleRedirect(path, subitem, index)}>
                <div className="sidebar-list-item" style={{ backgroundColor: currentPath === title ? "white" : COLORS.primary, boxShadow: currentPath === title ? "0px 3px 6px 0px rgba(0, 0, 0, 0.16)" : "" }}>
                  <ListItemIcon style={{ color: currentPath === title ? COLORS.primary : "white" }}>{icon}</ListItemIcon>
                  <ListItemText primary={title} style={{ color: currentPath === title ? COLORS.primary : "white" }} className="sidebarText" />
                  {subitem && subitem.length > 0 ? (
                    open === index ? (
                      <ExpandMore style={{ color: currentPath === title ? COLORS.primary : "white", marginLeft: "20px" }} sx={{ fontSize: 25 }} />
                    ) : (
                      <ArrowForwardIosIcon style={{ color: currentPath === title ? COLORS.primary : "white", marginLeft: "20px" }} sx={{ fontSize: 16 }} />
                    )
                  ) : null}
                </div>
              </ListItem>

              <Collapse in={open === index} timeout="auto" unmountOnExit>
                {subitem &&
                  subitem.map((subdata, subIndex) => (
                    <ListItem button key={icon} className="d-flex listItem sublist-item" onClick={() => handleRedirect(subdata?.path, "", subIndex)}>
                      <div className="sidebar-list-item" style={{ backgroundColor: currentPath === subdata?.title ? "white" : COLORS.primary, boxShadow: currentPath === subdata?.title ? "0px 3px 6px 0px rgba(0, 0, 0, 0.16)" : "" }}>
                        <ListItemIcon style={{ color: currentPath === subdata?.title ? COLORS.primary : "white" }}>{subdata?.icon}</ListItemIcon>
                        <ListItemText primary={subdata?.title} style={{ color: currentPath === subdata?.title ? COLORS.primary : "white" }} className="sidebarText" />
                      </div>
                    </ListItem>
                  ))}
              </Collapse>
            </div>
          ))}
        </List>
        </div>
       
        
        <List id= {pathName === "/admin/dashboard" ? "footerAdmin" : 'footer'} className="d-flex">
          <a className="mainNavLink" href="https://www.instagram.com/">
            <ListItemIcon>
              <InstagramIcon style={{ fill: "white" }} />
            </ListItemIcon>
          </a>

          <a className="mainNavLink" href="https://twitter.com/">
            <ListItemIcon>
              <TwitterIcon style={{ fill: "white" }} />
            </ListItemIcon>
          </a>

          <a className="mainNavLink" href="https://www.facebook.com/">
            <ListItemIcon>
              <FacebookIcon style={{ fill: "white" }} />
            </ListItemIcon>
          </a>
        </List>
      </Drawer>
    </Box>
  );
}
