import { createSlice } from "@reduxjs/toolkit";
import { CollectionAgentList, CollectionStatus} from "./api";

const initialState = {
  StatusData: [],
  CollectionAgentListData: [],
};

export const collectionSlice = createSlice({
  name: "collectionagent",
  initialState,

  extraReducers: {


    [CollectionAgentList.pending]: (state) => {},
    [CollectionAgentList.fulfilled]: (state, { payload }) => {
      state.CollectionAgentListData = payload.data.data;
    },
    [CollectionAgentList.rejected]: (state, { payload }) => {},


    [CollectionStatus.pending]: (state) => {},
    [CollectionStatus.fulfilled]: (state, { payload }) => {
      state.StatusData = payload.data.data;
    },
    [CollectionStatus.rejected]: (state, { payload }) => {},

 
  },
});

export const { reset } = collectionSlice.actions;

export default collectionSlice.reducer;
