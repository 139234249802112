import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./styles.scss";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { stbUpdate,subscriberView,payNow } from "@reducers/api";
import { useDispatch, useSelector } from "react-redux";
import { useComponentDidUpdate,paramsQuery} from "@utils/useEffectHooks";
import { useSnackbar } from "notistack";
import { reset } from "@reducers/subscriber";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function BalaanceModal(props) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
  });
  
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const { stbUpdateData } = useSelector( (state) => state.subscriber);

  const handleClose = () => {
    props?.handleClose();
  };

  const handleFormValues = (data) => {
    let id = paramsQuery("id");
    let user = JSON.parse(localStorage.getItem("user"));
    let distid = user?.distributor_ref_id;
    let distName = user?.username;
    let invoiceid = "#" + new Date().getTime();
    let payload = {
      "cust_plan_ref_id": [
        {
            "is_deleted": false,
            "_id": props?.invData?._id,
            "type": props?.invData?.type,
            "plan_amount":  props?.invData?.plan_amount,
            "cust_ref_id": props?.invData?.cust_ref_id,
            "cust_name": props?.invData?.cust_name,
            "cust_email_id": props?.invData?.cust_email_id,
            "package_category": "add_on_service",
            "billing_type": props?.invData?.billing_type,
            "checked": true
        }
      ],
      "stb_payment_category":props?.invData?.stb_payment_category,
      "stb_no":props?.invData?.stb_no,
      "bill_amount": props?.invData?.plan_amount,
      "total_plan_amount": props?.invData?.plan_amount,
      "maintenance_amount": 0,
      "channel_count": 0,
      "alacart_extra_amount": 0,
      "discount_amount": 0,
      "tax": {
          "GST 1": 0
      },
      "show_bill_amount": props?.invData?.plan_amount,
      "due_amount": 0,
      "add_on_service_amount": props?.invData?.plan_amount,
      // "collected_by": props?.customerData?.collected_by,
      "collection_amount":props?.invData?.plan_amount,
      "collected_date": new Date(),
      "advance_amount": 0,
      "cust_ref_id": {
        "_id": props?.invData?.cust_ref_id,
        "name": props?.invData?.cust_name,
        "email_id": props?.invData?.cust_email_id,
      },
      "invoice_id": invoiceid,
      "transaction_id":invoiceid,
      "order_id": invoiceid,
      "distributor_ref_id":distid,
      "package_amount": 0,
      "broadcaster_amount": 0,
      "ala_cart_amount": 0,
      "billing_type":props?.invData?.billing_type,
      "service_type": props?.customerData?.service_type,
      "payby_id": distid,
      "category": "hardware",
      "collected_by": distName,
     "sms_access": user?.sms_access,
    };
    dispatch(payNow(payload));
  };

  const onSubmit = (data) => handleFormValues(data);

  useEffect(() => {
    setOpen(props?.show);
  }, [props?.show]);

  return (
    <Modal
      open={props?.show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="makePayment-cntr">
      <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Are you sure you want to pay?</h2>
        <div className="divider-line"></div>       
        <Stack spacing={2} direction="row" className="btnright" style={{marginTop:'20px'}}>
            <Button
              size="small"
              variant="outlined" 
              className="btn-outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              className="bg-blue"
              type="submit"
            >
              Ok
            </Button>
          </Stack>
          </form>
      </Box>
    </Modal>
  );
}
